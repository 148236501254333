package native.notifications

class PushNotifications {
    companion object {
        private var instance: PushNotifications? = null
        fun shared(): PushNotifications {
            return if (instance != null) {
                instance as PushNotifications
            } else {
                instance = PushNotifications()
                instance as PushNotifications
            }
        }
    }

    init {
        instance = this
    }

    var requestPermission: () -> Unit = {}
    var onPermissionChange: (Boolean) -> Unit = {}
}