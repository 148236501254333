package presentation.screens.business.inventory.sub_category.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.productSubCategory.model.ProductSubCategory
import feature.productSubCategory.viewModel.ProductSubCategoryViewModel
import theme.ThemeColor

@Composable
fun SelectSubCategoryRow(
    selected: String?,
    categoryId: String?,
    onChange: (ProductSubCategory) -> Unit = {}
) {
    val viewModel = ProductSubCategoryViewModel()
    val allSubCategory = viewModel.allSubCategoryForCategory.collectAsState()
    viewModel.loadForCategoryStorage(categoryId ?: "")
    LaunchedEffect(key1 = Unit) {
        if (categoryId != null) {
            viewModel.fetchAll()
        }
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("Sub Category")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allSubCategory.value.count()) {
                HorizontalSubCategoryCard(
                    allSubCategory.value[it],
                    isSelected = selected == allSubCategory.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}