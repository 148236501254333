package component.navigation

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.rounded.ArrowBack
import androidx.compose.material.icons.rounded.Add
import androidx.compose.material.icons.rounded.Delete
import androidx.compose.material.icons.rounded.Edit
import androidx.compose.material.icons.rounded.Notifications
import androidx.compose.material.icons.rounded.ShoppingCart
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import feature.app.NavigationParam
import feature.cart.viewModel.CartViewModel
import navigation.AppNavigator
import navigation.containers.ScreenName
import theme.ThemeColor

@Composable
fun TopNavBar(
    title: String = "Title",
    param: NavigationParam?,
    showNotification: Boolean = false,
    onBackPress: (() -> Unit)? = null,
    onClickAdd: (() -> Unit)? = null,
    onClickEdit: (() -> Unit)? = null,
    onClickCart: (() -> Unit)? = null,
    onClickDelete: (() -> Unit)? = null,
) {
    val viewModel = CartViewModel()
    val cartItems = viewModel.cartItems.collectAsState()
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(54.dp)
    ) {
        Row(
            modifier = Modifier.fillMaxHeight().fillMaxWidth()
                .background(ThemeColor.shared().PrimaryColor),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (onBackPress != null) {
                IconButton(
                    onClick = {
                        onBackPress()
                    },
                    modifier = Modifier
                        .width(50.dp).height(50.dp)
                ) {
                    Icon(
                        Icons.AutoMirrored.Rounded.ArrowBack,
                        contentDescription = "add new items",
                        tint = ThemeColor.shared().White,
                    )
                }
            }
            Spacer(modifier = Modifier.weight(1f))
            Text(
                modifier = Modifier
                    .wrapContentWidth().height(50.dp).padding(top = 10.dp),
                text = title,
                color = ThemeColor.shared().White,
                fontSize = 18.sp,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Center,

                )
            Spacer(modifier = Modifier.weight(1f))
            if (onClickAdd != null) {
                IconButton(
                    onClick = {
                        onClickAdd()
                    },
                    modifier = Modifier
                        .width(50.dp).height(50.dp)
                ) {
                    Icon(
                        Icons.Rounded.Add,
                        contentDescription = "add new items",
                        tint = ThemeColor.shared().White,
                    )
                }
            }
            if (onClickEdit != null) {
                IconButton(
                    onClick = {
                        onClickEdit()
                    },
                    modifier = Modifier
                        .width(50.dp).height(50.dp)
                ) {
                    Icon(
                        Icons.Rounded.Edit,
                        contentDescription = "edit item",
                        tint = ThemeColor.shared().White,
                    )
                }
            }
            if (onClickCart != null) {
                Box(modifier = Modifier.width(50.dp).height(50.dp)) {
                    if (cartItems.value.isNotEmpty()) {
                        Box(
                            modifier = Modifier.width(24.dp).height(24.dp).zIndex(1f)
                                .background(
                                    ThemeColor.shared().Red.copy(alpha = 0.5f),
                                    shape = RoundedCornerShape(12.dp)
                                ),
                            contentAlignment = Alignment.Center
                        ) {
                            Text("${cartItems.value.count()}", color = Color.White)
                        }
                    }

                    Box(modifier = Modifier.width(50.dp).height(50.dp).zIndex(-1f)) {
                        IconButton(
                            onClick = {
                                onClickCart()
                            },
                            modifier = Modifier
                                .width(50.dp).height(50.dp).zIndex(-1f)
                        ) {
                            Icon(
                                Icons.Rounded.ShoppingCart,
                                contentDescription = "cart",
                                tint = ThemeColor.shared().White,
                            )
                        }
                    }

                }

            }
            if (showNotification) {
                Box(
                    modifier = Modifier
                        .width(50.dp).height(50.dp).padding(end = 10.dp)
                ) {
                    IconButton(
                        onClick = {
                            AppNavigator.shared().navigateTo(
                                ScreenName.BUSINESS_NOTIFICATION
                            )
                        },
                        modifier = Modifier
                            .width(50.dp).height(50.dp)
                    ) {
                        Icon(
                            Icons.Rounded.Notifications,
                            contentDescription = "Notification",
                            tint = ThemeColor.shared().White,
                        )
                    }

                }
            }
            if (onClickDelete != null) {
                IconButton(
                    onClick = {
                        onClickDelete()
                    },
                    modifier = Modifier
                        .width(50.dp).height(50.dp)
                ) {
                    Icon(
                        Icons.Rounded.Delete,
                        contentDescription = "delete item",
                        tint = ThemeColor.shared().White,
                    )
                }
            }
        }
    }
}