package feature.auth.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FriendlyProfile(
    @SerialName("_id") var Id: String = "",
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("EmployeeUserID") var EmployeeUserID: String? = null,
    @SerialName("BusinessID") var BusinessID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("Name") var Name: String? = null,
    @SerialName("Address") var Address: String? = null,
    @SerialName("GSTNumber") var GSTNumber: String? = null,
    @SerialName("Barcode") var Barcode: String? = null,
    @SerialName("Status") var Status: String? = null,
    @SerialName("EmailID") var EmailID: String? = null,
    @SerialName("MobileNumber") var MobileNumber: String? = null,
    @SerialName("WhatsApp") var WhatsApp: String? = null,
    @SerialName("DialCode") var DialCode: String? = null,
    @SerialName("Gender") var Gender: Int? = null,
    @SerialName("DeviceID") var DeviceID: String? = null,
    @SerialName("FCMToken") var FCMToken: String? = null,
    @SerialName("TotalSale") var TodalSale: Int? = null,
    @SerialName("TotalPayment") var TotalPayment: Int? = null,
    @SerialName("LastSeen") var LastSeen: String? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null,
    @SerialName("JobTypeId") var JobTypeId: String? = null,
    @SerialName("JobTypeName") var JobTypeName: String? = null
)
