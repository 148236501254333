package appConfig

import common.constants.KeyConstant
import common.constants.UserDefaults
import feature.auth.model.BusinessConstants
import feature.business.handler.BusinessHandler
import feature.business.model.Business
import feature.businessConfig.viewModel.BusinessConfigViewModel
import feature.mediaFile.viewModel.MediaFileViewModel
import graph.AppType
import graph.PlatformType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import theme.ThemeColor

class AppConfig {
    val varsion = "0.0.41"
    private val decoder = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }
    val scope = CoroutineScope(Job() + Dispatchers.Default)
    var appName = "Mandee"
    val countryCode = "IN"
    var currency = "INR"
    var currencySymbol = "₹"
    var business: Business? = null
    var appType = AppType.CUSTOMER
    var platformType = PlatformType.Mobile
    var platform = PlatformType.Android
    var domainUrl = "https://mandee.ai"

    init {
        instance = this
    }

    companion object {
        private var instance: AppConfig? = null
        fun shared(): AppConfig {
            if (instance != null) {
                return instance as AppConfig
            } else {
                return AppConfig()
            }
        }
    }

    fun setBusiness() {
        if (appType != AppType.BUSINESS && BusinessConstants().selectedBusiness.isNotBlank()) {
            business = decoder.decodeFromString(BusinessConstants().selectedBusiness)
            scope.launch {
                BusinessHandler.shared().repository.businessLiveData.emit(business!!)
                delay(500)
                BusinessConfigViewModel().fetchBusinessConfig()
            }
            ThemeColor.shared().applyCustomTheme()
            MediaFileViewModel().retrieve()
        } else {
            val selectedBusiness = UserDefaults.retrieve(KeyConstant.selectedBusiness)
            if (selectedBusiness.isNotEmpty()) {
                business = decoder.decodeFromString(selectedBusiness)
                CoroutineScope(Job() + Dispatchers.Default).launch {
                    BusinessHandler.shared().repository.businessLiveData.emit(business!!)
                    delay(500)
                    BusinessConfigViewModel().fetchBusinessConfig()
                }
            }
        }
    }
}
