package presentation.screens.business.inventory.product.edit

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.button.RadioButton
import component.button.SecondaryButton
import component.image.ImageView
import component.input.PriceInputField
import component.input.TaxInputField
import component.input.TextInputField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.businessConfig.viewModel.BusinessConfigViewModel
import feature.product.model.Product
import feature.product.viewModel.ProductViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.inventory.category.component.SelectCategoryRow
import presentation.screens.business.inventory.sub_category.component.SelectSubCategoryRow
import theme.ThemeColor

class EditProductScreen(override val screenName: ScreenName = ScreenName.BUSINESS_INVENTORY_EDIT_PRODUCT) :
    AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val businessConfigViewModel = BusinessConfigViewModel()
        val currency = businessConfigViewModel.selectedCurrency.collectAsState()
        val isProduct = remember { mutableStateOf(true) }
        val viewModel = ProductViewModel()
        val currentStep = viewModel.productFormIndex.collectAsState()
        val navigator = LocalNavigator.current
        val product = viewModel.editProduct.collectAsState()
        val name = remember { mutableStateOf(product.value.Name ?: "") }
        val categoryId =
            remember { mutableStateOf(product.value.CategoryID) }
        val subCategoryId =
            remember { mutableStateOf(product.value.SubCategoryID) }
        val costPrice = remember { mutableStateOf(product.value.ProductPrice.CostPrice ?: 0.0) }
        val mrpPrice = remember { mutableStateOf(product.value.ProductPrice.MRP ?: 0.0) }
        val discountPrice =
            remember { mutableStateOf(product.value.ProductPrice.Discount ?: 0.0) }
        val salesPrice = remember { mutableStateOf(product.value.ProductPrice.Price ?: 0.00) }
        val finalPrice = remember { mutableStateOf(product.value.ProductPrice.FinalPrice ?: 0.0) }
        val cgstTax = remember { mutableStateOf(product.value.ProductPrice.CGST ?: 0.0) }
        val sgstTax = remember { mutableStateOf(product.value.ProductPrice.SGST ?: 0.0) }
        val igstTax = remember { mutableStateOf(product.value.ProductPrice.IGST ?: 0.0) }
        val description = remember { mutableStateOf(product.value.Description ?: "") }
        fun onClickSave() {
            viewModel.updateProduct(
                _id = product.value.Id,
                isProduct = isProduct.value,
                name = name.value,
                description = description.value,
                categoryId = categoryId.value,
                subCategoryId = subCategoryId.value,
                costPrice = costPrice.value,
                mrp = mrpPrice.value,
                discount = discountPrice.value,
                salePrice = salesPrice.value,
                finalPrice = finalPrice.value,
                cgst = cgstTax.value,
                sgst = sgstTax.value,
                igst = igstTax.value,
                currency = currency.value
            ) {
                if (it) {
                    viewModel.setProductFormIndex(0)
                    viewModel.setNewProduct(Product())
                    navigator?.pop()
                } else {
                    AlertHandler.shared().showAlert(
                        localization().product.productsAlertTitle,
                        localization().product.productsAlertMessage/*"Oops!", "Something went wrong"*/
                    )
                }
            }
//            viewModel.createProduct {
//                if (it) {
//                    viewModel.setProductFormIndex(0)
//                    viewModel.setNewProduct(Product())
//                    navigator?.pop()
//                } else {
//                    AlertHandler.shared().showAlert(
//                        localization().product.productsAlertTitle,
//                        localization().product.productsAlertMessage/*"Oops!", "Something went wrong"*/
//                    )
//                }
//            }
        }
        Column(
            modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(title = "Update Product", navigationParam, onBackPress = {
                if (currentStep.value == 0) {
                    navigator?.pop()
                } else {
                    viewModel.setProductFormIndex(currentStep.value - 1)
                }
            })
            AppLoader(viewModel)
            LazyColumn(
                modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.84f)
                    .padding(start = 8.dp, end = 8.dp, bottom = 20.dp)
            ) {
                item {
                    Row(modifier = Modifier.fillMaxWidth().height(50.dp).padding(end = 90.dp)) {
                        Box(
                            modifier = Modifier.fillMaxHeight().fillMaxWidth().weight(0.5f)
                                .padding(
                                    start = 0.dp,
                                    top = 8.dp,
                                    end = 0.dp,
                                    bottom = 8.dp
                                )//.background(ThemeColor.shared().cardBackground)
                                .clickable { isProduct.value = true }
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth().fillMaxHeight(),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                RadioButton(isProduct.value, size = 24.dp)
                                Text("Product")
                            }
                        }
                        Box(
                            modifier = Modifier.fillMaxHeight().fillMaxWidth().weight(0.5f)
                                .padding(8.dp)//.background(ThemeColor.shared().cardBackground)
                                .clickable { isProduct.value = false }
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth().fillMaxHeight(),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                RadioButton(!isProduct.value, size = 24.dp)
                                Text("Service")
                            }
                        }
                    }
                }
                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        TextInputField(
                            title = "Product/Service",
                            "Name Of the Business/Store",
                            value = name.value
                        ) {
                            name.value = it
                        }

                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        Row(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                            Box(
                                modifier = Modifier.fillMaxWidth().height(90.dp).weight(0.5f)
                            ) {
                                PriceInputField(
                                    title = "Cost Price",
                                    "Cost Price",
                                    currency.value,
                                    costPrice.value
                                ) {
                                    costPrice.value = it
                                }
                            }
                            Box(
                                modifier = Modifier.fillMaxWidth().height(90.dp).weight(0.5f)
                            ) {
                                PriceInputField(
                                    title = "MRP",
                                    "Enter MRP",
                                    currency.value,
                                    mrpPrice.value
                                ) {
                                    mrpPrice.value = it
                                }
                            }
                        }
                        Row(modifier = Modifier.fillMaxWidth().height(90.dp)) {
                            Box(
                                modifier = Modifier.fillMaxWidth().wrapContentHeight().weight(0.5f)
                            ) {
                                PriceInputField(
                                    title = "Discount",
                                    "Discount",
                                    currency.value,
                                    discountPrice.value
                                ) {
                                    discountPrice.value = it
                                }
                            }
                            Box(
                                modifier = Modifier.fillMaxWidth().wrapContentHeight().weight(0.5f)
                            ) {
                                PriceInputField(
                                    title = "Sale Price",
                                    "Sale Price",
                                    currency.value,
                                    salesPrice.value
                                ) {
                                    salesPrice.value = it
                                }
                            }
                        }
                        PriceInputField(
                            title = "Final Price", "Final Price", currency.value, finalPrice
                                .value
                        ) {
                            finalPrice.value = it
                        }


                    }
                }
                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        TaxInputField(title = "CGST", "Enter CGST", cgstTax.value) {
                            cgstTax.value = it
                        }
                        TaxInputField(title = "SGST", "Enter SGST", sgstTax.value) {
                            sgstTax.value = it
                        }
                        TaxInputField(title = "IGST", "Enter IGST", igstTax.value) {
                            igstTax.value = it
                        }
                        SelectCategoryRow(categoryId.value) {
                            categoryId.value = it.Id
                        }
                        if (categoryId.value?.isNotEmpty() == true) {
                            SelectSubCategoryRow(subCategoryId.value, categoryId.value) {
                                subCategoryId.value = it.Id
                            }
                        }
                        Box(modifier = Modifier.fillMaxWidth().height(200.dp)) {
                            ImageView(product.value.Id, true)
                        }
                        TextInputField(
                            title = "Description",
                            "Enter Description",
                            description.value
                        ) {
                            description.value = it
                        }
                    }
                }
                //availability
//                item {
//                    Column {
//                        Text(
//                            "Available Days",
//                            color = ThemeColor.shared().businessTheme,
//                            fontWeight = FontWeight.Bold
//                        )
//                        Row(
//                            modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(4.dp),
//                            verticalAlignment = Alignment.CenterVertically
//                        ) {
//                            Text("From", textAlign = TextAlign.Center, fontSize = 16.sp)
//                            Box(
//                                modifier = Modifier.width(60.dp).height(35.dp).padding(8.dp)
//                                    .background(
//                                        color = Color.White,
//                                        shape = RoundedCornerShape(5.dp)
//                                    )
//                            ) {
//                                Text("11:30", textAlign = TextAlign.Center, fontSize = 16.sp)
//                            }
//                            Text("To", textAlign = TextAlign.Center, fontSize = 16.sp)
//                            Box(
//                                modifier = Modifier.width(60.dp).height(35.dp).padding(8.dp)
//                                    .background(
//                                        color = Color.White,
//                                        shape = RoundedCornerShape(5.dp)
//                                    )
//                            ) {
//                                Text("19:30", textAlign = TextAlign.Center, fontSize = 16.sp)
//                            }
//
//                        }
//                        Text(
//                            "Available Time",
//                            color = ThemeColor.shared().businessTheme,
//                            fontWeight = FontWeight.Bold
//                        )
//                    }
//                }
//                item {
//                    Row(
//                        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(4.dp),
//                        verticalAlignment = Alignment.CenterVertically
//                    ) {
//                        Text("From", textAlign = TextAlign.Center, fontSize = 16.sp)
//                        Box(
//                            modifier = Modifier.width(60.dp).height(35.dp).padding(8.dp)
//                                .background(color = Color.White, shape = RoundedCornerShape(5.dp))
//                        ) {
//                            Text("11:30", textAlign = TextAlign.Center, fontSize = 16.sp)
//                        }
////                    Text("To", textAlign = TextAlign.Center, fontSize = 16.sp)
//                        Box(
//                            modifier = Modifier.width(60.dp).height(35.dp).padding(8.dp)
//                                .background(color = Color.White, shape = RoundedCornerShape(5.dp))
//                        ) {
//                            Text("19:30", textAlign = TextAlign.Center, fontSize = 16.sp)
//                        }
//
//                    }
//                }
            }

            Row(modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.16f)) {
                SecondaryButton("Cancel", {
                    navigator?.pop()
                })
                DefaultButton(localization().button.saveButton/*"Next"*/, onClick = {
                    onClickSave()
                })
            }
        }
    }
}