package htmp_page

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class HtmlContent(
    @SerialName("h1") var h1: String? = null,
    @SerialName("h2") var h2: String? = null,
    @SerialName("p") var p: String? = null,
    @SerialName("br") var br: String? = null,
)