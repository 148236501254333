package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.brandTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class BrandTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    BrandID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8)
    )
  }

  public fun retrieve(BusinessID: String?): Query<BrandTable> = retrieve(BusinessID) { _id, BrandID,
      UserID, BusinessID_, IsDeleted, Name, Description, CreatedAt, UpdatedAt ->
    BrandTable(
      _id,
      BrandID,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveAll(mapper: (
    _id: String,
    BrandID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = Query(355_031_678, arrayOf("BrandTable"), driver, "BrandTable.sq",
      "retrieveAll",
      "SELECT BrandTable._id, BrandTable.BrandID, BrandTable.UserID, BrandTable.BusinessID, BrandTable.IsDeleted, BrandTable.Name, BrandTable.Description, BrandTable.CreatedAt, BrandTable.UpdatedAt FROM BrandTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8)
    )
  }

  public fun retrieveAll(): Query<BrandTable> = retrieveAll { _id, BrandID, UserID, BusinessID,
      IsDeleted, Name, Description, CreatedAt, UpdatedAt ->
    BrandTable(
      _id,
      BrandID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    BrandID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8)
    )
  }

  public fun find(_id: String): Query<BrandTable> = find(_id) { _id_, BrandID, UserID, BusinessID,
      IsDeleted, Name, Description, CreatedAt, UpdatedAt ->
    BrandTable(
      _id_,
      BrandID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    BrandID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(284_811_360, """
        |INSERT OR REPLACE INTO BrandTable( _id ,BrandID,UserID, BusinessID,IsDeleted,Name,Description,CreatedAt,UpdatedAt)
        |    VALUES(?, ?,?, ? ,?, ?, ?,?,?)
        """.trimMargin(), 9) {
          bindString(0, _id)
          bindLong(1, BrandID)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindBoolean(4, IsDeleted)
          bindString(5, Name)
          bindString(6, Description)
          bindString(7, CreatedAt)
          bindString(8, UpdatedAt)
        }.await()
    notifyQueries(284_811_360) { emit ->
      emit("BrandTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BrandTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BrandTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT BrandTable._id, BrandTable.BrandID, BrandTable.UserID, BrandTable.BusinessID, BrandTable.IsDeleted, BrandTable.Name, BrandTable.Description, BrandTable.CreatedAt, BrandTable.UpdatedAt FROM BrandTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "BrandTable.sq:retrieve"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BrandTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BrandTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(987_910_432,
        """SELECT BrandTable._id, BrandTable.BrandID, BrandTable.UserID, BrandTable.BusinessID, BrandTable.IsDeleted, BrandTable.Name, BrandTable.Description, BrandTable.CreatedAt, BrandTable.UpdatedAt FROM BrandTable WHERE _id = ?""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "BrandTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BrandTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BrandTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM BrandTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "BrandTable.sq:lastSyncDate"
  }
}
