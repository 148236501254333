package feature.employee.repository

import feature.auth.model.FriendlyProfile
import feature.employee.model.Employee
import feature.employee.model.EmployeeAttendance
import feature.employee.model.JobRole
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class EmployeeRepository {

    val searchQueryState: MutableStateFlow<String> = MutableStateFlow("")
    val searchQuery: StateFlow<String>
        get() = searchQueryState

    val allEmployeeLiveData: MutableStateFlow<ArrayList<Employee>> = MutableStateFlow(arrayListOf())
    val allEmployee: StateFlow<ArrayList<Employee>>
        get() = allEmployeeLiveData

    val employeeLiveData: MutableStateFlow<Employee?> = MutableStateFlow(Employee())
    val employee: StateFlow<Employee?>
        get() = employeeLiveData

    val newEmployeeLiveData: MutableStateFlow<Employee> = MutableStateFlow(Employee())
    val newEmployee: StateFlow<Employee>
        get() = newEmployeeLiveData

    val selectedEmployeeState: MutableStateFlow<Employee?> = MutableStateFlow(null)
    val selectedEmployee: StateFlow<Employee?>
        get() = selectedEmployeeState

    private val editEmployeeState: MutableStateFlow<Employee?> = MutableStateFlow(null)
    val editEmployee: StateFlow<Employee?>
        get() = editEmployeeState

    val profileLiveData: MutableStateFlow<FriendlyProfile?> = MutableStateFlow(FriendlyProfile())
    val profile: StateFlow<FriendlyProfile?>
        get() = profileLiveData

    //Attendance
    val employeeAttendanceLiveData: MutableStateFlow<EmployeeAttendance> =
        MutableStateFlow(EmployeeAttendance())
    val employeeAttendanceData: StateFlow<EmployeeAttendance>
        get() = employeeAttendanceLiveData

    val allEmployeeAttendanceLiveData: MutableStateFlow<ArrayList<EmployeeAttendance>> =
        MutableStateFlow(
            arrayListOf()
        )
    val allEmployeeAttendance: StateFlow<ArrayList<EmployeeAttendance>>
        get() = allEmployeeAttendanceLiveData

    val allJobRoleLiveData: MutableStateFlow<ArrayList<JobRole>> = MutableStateFlow(arrayListOf())
    val allJobRole: StateFlow<ArrayList<JobRole>>
        get() = allJobRoleLiveData

    fun setEditEmployee(employee: Employee?) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            editEmployeeState.emit(employee)
        }
    }

    fun selectEmployee(employee: Employee?) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            employeeLiveData.emit(employee)
        }
    }

}