package feature.app

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import navigation.containers.ScreenName

class AppRepository {
    val isLoadingState: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val isLoading: StateFlow<Boolean>
        get() = isLoadingState
    val navigationParamState: MutableStateFlow<MutableMap<ScreenName?, NavigationParam?>> =
        MutableStateFlow(mutableMapOf())
    val navigationParam: StateFlow<MutableMap<ScreenName?, NavigationParam?>> get() = navigationParamState

    val viewModelsState: MutableStateFlow<MutableMap<ScreenName?, AppViewModel?>> =
        MutableStateFlow(mutableMapOf())
    val viewModels: StateFlow<MutableMap<ScreenName?, AppViewModel?>> get() = viewModelsState

    val zipCodeState: MutableStateFlow<String> = MutableStateFlow("")
    val zipCode: StateFlow<String>
        get() = zipCodeState
}