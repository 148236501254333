package common.constants

import androidx.compose.ui.graphics.Color
import feature.business.handler.BusinessHandler
import feature.businessMenu.modal.BusinessMenu
import feature.businessMenu.modal.MenuType
import friendly.shared.generated.resources.Res
import friendly.shared.generated.resources.business_menu_icon
import friendly.shared.generated.resources.catalogue_menu_icon
import friendly.shared.generated.resources.customer_menu_icon
import friendly.shared.generated.resources.employee_menu_icon
import friendly.shared.generated.resources.invoice_menu_icon
import friendly.shared.generated.resources.orders_menu_icon
import friendly.shared.generated.resources.product_menu_icon
import friendly.shared.generated.resources.sale_menu_icon
import friendly.shared.generated.resources.store_menu_icon
import friendly.shared.generated.resources.sub_category_menu_icon
import locale.Localization
import navigation.containers.ScreenName


class BusinessMenuConfig {

    fun getMenu(localization: Localization?): List<BusinessMenu> {
        val firstMenu: ArrayList<BusinessMenu> = arrayListOf()
        firstMenu.add(
            BusinessMenu(
                "sale",
                MenuType.MENU,
                localization?.businessMenu?.menuLabelSale,
                Res.drawable.sale_menu_icon,
                ScreenName.BUSINESS_SALE,
                Color(0xFFF5F5D5)
            )
        )
        firstMenu.add(
            BusinessMenu(
                "invoice",
                MenuType.MENU,
                localization?.businessMenu?.invoice,
                //"Invoice",
                Res.drawable.invoice_menu_icon,
                ScreenName.BUSINESS_INVOICE_LIST,
                Color(0xFFE3F5E2)
            )
        )

        val secondMenu: ArrayList<BusinessMenu> = arrayListOf()
        secondMenu.add(
            BusinessMenu(
                "product",
                MenuType.MENU,
                localization?.businessMenu?.product,
                //"Product",
                Res.drawable.product_menu_icon,
                ScreenName.BUSINESS_INVENTORY_PRODUCT,
                Color(0xFFDEF4F8)
            )
        )
        secondMenu.add(
            BusinessMenu(
                "category",
                MenuType.MENU,
                localization?.businessMenu?.category, // "Category",
                Res.drawable.catalogue_menu_icon,
                ScreenName.BUSINESS_INVENTORY_CATEGORY,
                Color(0xFFE7E7E7)
            )
        )
        secondMenu.add(
            BusinessMenu(
                "sub_category",
                MenuType.MENU,
                localization?.businessMenu?.subCategory,
                //"Sub Category",
                Res.drawable.sub_category_menu_icon,
                ScreenName.BUSINESS_INVENTORY_SUB_CATEGORY,
                Color(0xFFF9E0F5)
            )
        )
        secondMenu.add(
            BusinessMenu(
                "stock",
                MenuType.MENU,
                localization?.businessMenu?.stock,
                //"Stock",
                Res.drawable.invoice_menu_icon,
                ScreenName.BUSINESS_INVENTORY_STOCK,
                Color(0xFFFEF7F3)
            )
        )


        val thirdMenu: ArrayList<BusinessMenu> = arrayListOf()
        if (!BusinessHandler.shared().repository.business.value.Name.isNullOrEmpty()) {
            thirdMenu.add(
                BusinessMenu(
                    "business",
                    MenuType.MENU,
                    BusinessHandler.shared().repository.business.value.Name,
                    Res.drawable.business_menu_icon,
                    ScreenName.MY_BUSINESS_PROFILE,
                    Color(0xFFF5F5D5)
                )
            )
        }
        thirdMenu.add(
            BusinessMenu(
                "business",
                MenuType.MENU,
                localization?.businessMenu?.storeLabel,
                //"Store",
                Res.drawable.business_menu_icon,
                ScreenName.MY_BUSINESS_STORE,
                Color(0xFFBCDBFF)
            )
        )
//        }

//        thirdMenu.add(
//            BusinessMenu(
//                "dashboard",
//                MenuType.MENU,
//                localization?.businessMenu?.dashboard,
//                //"Dashboard",
//                Res.drawable.dashboard_menu_icon,
//                "business_dashboard",
//                Color(0xFFE2F1E9)
//            )
//        )
        thirdMenu.add(
            BusinessMenu(
                "employee",
                MenuType.MENU,
                localization?.businessMenu?.employee,
                //"Employee",
                Res.drawable.employee_menu_icon,
                ScreenName.BUSINESS_EMPLOYEE,
                Color(0xFFFFF3E0)
            )
        )
        thirdMenu.add(
            BusinessMenu(
                "customer",
                MenuType.MENU,
                localization?.businessMenu?.customer,
                //"Customer",
                Res.drawable.customer_menu_icon,
                ScreenName.BUSINESS_CUSTOMERS,
                Color(0xFFFDE0DD)
            )
        )
        thirdMenu.add(
            BusinessMenu(
                "all_business",
                MenuType.MENU,
                localization?.businessMenu?.allBusiness,
                //"All Business",
                Res.drawable.business_menu_icon,
                ScreenName.SELF_BUSINESS_LIST,
                Color(0xFFE7E7E7)
            )
        )
        thirdMenu.add(
            BusinessMenu(
                "all_business",
                MenuType.MENU,
                "Orders",
                //"All Business",
                Res.drawable.orders_menu_icon,
                ScreenName.ORDER_LIST,
                Color(0xFFFDE0DD)
            )
        )
        val fourthMenu: ArrayList<BusinessMenu> = arrayListOf()
        fourthMenu.add(
            BusinessMenu(
                ScreenName.BUSINESS_DETAILS_CONNECTION.name,
                MenuType.HEADER,
                "Connection",
                Res.drawable.business_menu_icon,
                ScreenName.BUSINESS_DETAILS_CONNECTION,
                Color(0xFFBCDBFF)
            )
        )
        val allMenuItems = listOf(
            BusinessMenu(
                "sale_header",
                MenuType.HEADER,
                localization?.businessMenu?.sale,
                Res.drawable.store_menu_icon,
                ScreenName.BUSINESS_SALE,
                Color(0xFFF5F5D5)
            ),
            BusinessMenu(
                "sale",
                MenuType.MENU,
                "",
                Res.drawable.sale_menu_icon,
                ScreenName.BUSINESS_SALE,
                Color(0xFFffffff),
                firstMenu
            ),
            BusinessMenu(
                "inventory_header",
                MenuType.HEADER,
                localization?.businessMenu?.inventory,
                Res.drawable.store_menu_icon,
                ScreenName.INVENTORY_PRODUCT,
                Color(0xFFDEF4F8)
            ),
            BusinessMenu(
                "inventory",
                MenuType.MENU,
                "",
                Res.drawable.store_menu_icon,
                ScreenName.INVENTORY_PRODUCT,
                Color(0xFFffffff),
                secondMenu
            ),
            BusinessMenu(
                "store_header",
                MenuType.HEADER,
                localization?.businessMenu?.store,
                Res.drawable.store_menu_icon,
                ScreenName.INVENTORY_PRODUCT,
                Color(0xFFDEF4F8)
            ),
            BusinessMenu(
                "store",
                MenuType.MENU,
                "",
                Res.drawable.store_menu_icon,
                ScreenName.MY_BUSINESS_STORE,
                Color(0xFFffffff),
                thirdMenu
            ),
            BusinessMenu(
                "connection",
                MenuType.MENU,
                "Connection",
                Res.drawable.store_menu_icon,
                ScreenName.MY_BUSINESS_STORE,
                Color(0xFFffffff),
                fourthMenu
            )
        )
        return allMenuItems
    }
}