package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.addressTable.GetLatestMediaFileDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class AddressTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getAddressForUser(UserID: String?, mapper: (
    UniqueID: Long?,
    _id: String,
    UserID: String?,
    FeatureObjectID: String?,
    Name: String?,
    ZipCode: Long?,
    Country: String?,
    State: String?,
    City: String?,
    Area: String?,
    LandMark: String?,
    House: String?,
    FlatNumber: String?,
    Floor: String?,
    MobileNumber: String?,
    Type: String?,
    Location: String?,
    IsPrimary: Boolean,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = GetAddressForUserQuery(UserID) { cursor ->
    mapper(
      cursor.getLong(0),
      cursor.getString(1)!!,
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getLong(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getBoolean(17)!!,
      cursor.getBoolean(18)!!,
      cursor.getString(19),
      cursor.getString(20)
    )
  }

  public fun getAddressForUser(UserID: String?): Query<AddressTable> = getAddressForUser(UserID) {
      UniqueID, _id, UserID_, FeatureObjectID, Name, ZipCode, Country, State, City, Area, LandMark,
      House, FlatNumber, Floor, MobileNumber, Type, Location, IsPrimary, IsDeleted, CreatedAt,
      UpdatedAt ->
    AddressTable(
      UniqueID,
      _id,
      UserID_,
      FeatureObjectID,
      Name,
      ZipCode,
      Country,
      State,
      City,
      Area,
      LandMark,
      House,
      FlatNumber,
      Floor,
      MobileNumber,
      Type,
      Location,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getPrimaryAddressForUser(UserID: String?, mapper: (
    UniqueID: Long?,
    _id: String,
    UserID: String?,
    FeatureObjectID: String?,
    Name: String?,
    ZipCode: Long?,
    Country: String?,
    State: String?,
    City: String?,
    Area: String?,
    LandMark: String?,
    House: String?,
    FlatNumber: String?,
    Floor: String?,
    MobileNumber: String?,
    Type: String?,
    Location: String?,
    IsPrimary: Boolean,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = GetPrimaryAddressForUserQuery(UserID) { cursor ->
    mapper(
      cursor.getLong(0),
      cursor.getString(1)!!,
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getLong(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getBoolean(17)!!,
      cursor.getBoolean(18)!!,
      cursor.getString(19),
      cursor.getString(20)
    )
  }

  public fun getPrimaryAddressForUser(UserID: String?): Query<AddressTable> =
      getPrimaryAddressForUser(UserID) { UniqueID, _id, UserID_, FeatureObjectID, Name, ZipCode,
      Country, State, City, Area, LandMark, House, FlatNumber, Floor, MobileNumber, Type, Location,
      IsPrimary, IsDeleted, CreatedAt, UpdatedAt ->
    AddressTable(
      UniqueID,
      _id,
      UserID_,
      FeatureObjectID,
      Name,
      ZipCode,
      Country,
      State,
      City,
      Area,
      LandMark,
      House,
      FlatNumber,
      Floor,
      MobileNumber,
      Type,
      Location,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getAddressForFeature(FeatureObjectID: String?, mapper: (
    UniqueID: Long?,
    _id: String,
    UserID: String?,
    FeatureObjectID: String?,
    Name: String?,
    ZipCode: Long?,
    Country: String?,
    State: String?,
    City: String?,
    Area: String?,
    LandMark: String?,
    House: String?,
    FlatNumber: String?,
    Floor: String?,
    MobileNumber: String?,
    Type: String?,
    Location: String?,
    IsPrimary: Boolean,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = GetAddressForFeatureQuery(FeatureObjectID) { cursor ->
    mapper(
      cursor.getLong(0),
      cursor.getString(1)!!,
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getLong(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getBoolean(17)!!,
      cursor.getBoolean(18)!!,
      cursor.getString(19),
      cursor.getString(20)
    )
  }

  public fun getAddressForFeature(FeatureObjectID: String?): Query<AddressTable> =
      getAddressForFeature(FeatureObjectID) { UniqueID, _id, UserID, FeatureObjectID_, Name,
      ZipCode, Country, State, City, Area, LandMark, House, FlatNumber, Floor, MobileNumber, Type,
      Location, IsPrimary, IsDeleted, CreatedAt, UpdatedAt ->
    AddressTable(
      UniqueID,
      _id,
      UserID,
      FeatureObjectID_,
      Name,
      ZipCode,
      Country,
      State,
      City,
      Area,
      LandMark,
      House,
      FlatNumber,
      Floor,
      MobileNumber,
      Type,
      Location,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getLatestMediaFileDate(_id: String, mapper: (UpdatedAt: String?) -> T):
      Query<T> = GetLatestMediaFileDateQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun getLatestMediaFileDate(_id: String): Query<GetLatestMediaFileDate> =
      getLatestMediaFileDate(_id) { UpdatedAt ->
    GetLatestMediaFileDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    UniqueID: Long?,
    _id: String,
    UserID: String?,
    FeatureObjectID: String?,
    Name: String?,
    ZipCode: Long?,
    Country: String?,
    State: String?,
    City: String?,
    Area: String?,
    LandMark: String?,
    House: String?,
    FlatNumber: String?,
    Floor: String?,
    MobileNumber: String?,
    Type: String?,
    Location: String?,
    IsPrimary: Boolean,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(-1_472_175_149, """
        |INSERT OR REPLACE INTO AddressTable(UniqueID, _id, UserID, FeatureObjectID, Name,ZipCode,Country,State,City,Area,LandMark,House,FlatNumber,Floor,MobileNumber,Type,Location,IsPrimary,IsDeleted,CreatedAt,UpdatedAt)
        |    VALUES(?, ?, ?,  ? ,?, ?, ?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)
        """.trimMargin(), 21) {
          bindLong(0, UniqueID)
          bindString(1, _id)
          bindString(2, UserID)
          bindString(3, FeatureObjectID)
          bindString(4, Name)
          bindLong(5, ZipCode)
          bindString(6, Country)
          bindString(7, State)
          bindString(8, City)
          bindString(9, Area)
          bindString(10, LandMark)
          bindString(11, House)
          bindString(12, FlatNumber)
          bindString(13, Floor)
          bindString(14, MobileNumber)
          bindString(15, Type)
          bindString(16, Location)
          bindBoolean(17, IsPrimary)
          bindBoolean(18, IsDeleted)
          bindString(19, CreatedAt)
          bindString(20, UpdatedAt)
        }.await()
    notifyQueries(-1_472_175_149) { emit ->
      emit("AddressTable")
    }
  }

  private inner class GetAddressForUserQuery<out T : Any>(
    public val UserID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("AddressTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("AddressTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT AddressTable.UniqueID, AddressTable._id, AddressTable.UserID, AddressTable.FeatureObjectID, AddressTable.Name, AddressTable.ZipCode, AddressTable.Country, AddressTable.State, AddressTable.City, AddressTable.Area, AddressTable.LandMark, AddressTable.House, AddressTable.FlatNumber, AddressTable.Floor, AddressTable.MobileNumber, AddressTable.Type, AddressTable.Location, AddressTable.IsPrimary, AddressTable.IsDeleted, AddressTable.CreatedAt, AddressTable.UpdatedAt FROM AddressTable WHERE UserID ${ if (UserID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, UserID)
    }

    override fun toString(): String = "AddressTable.sq:getAddressForUser"
  }

  private inner class GetPrimaryAddressForUserQuery<out T : Any>(
    public val UserID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("AddressTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("AddressTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT AddressTable.UniqueID, AddressTable._id, AddressTable.UserID, AddressTable.FeatureObjectID, AddressTable.Name, AddressTable.ZipCode, AddressTable.Country, AddressTable.State, AddressTable.City, AddressTable.Area, AddressTable.LandMark, AddressTable.House, AddressTable.FlatNumber, AddressTable.Floor, AddressTable.MobileNumber, AddressTable.Type, AddressTable.Location, AddressTable.IsPrimary, AddressTable.IsDeleted, AddressTable.CreatedAt, AddressTable.UpdatedAt FROM AddressTable WHERE UserID ${ if (UserID == null) "IS" else "=" } ? AND IsPrimary = 1 LIMIT 1""",
        mapper, 1) {
      bindString(0, UserID)
    }

    override fun toString(): String = "AddressTable.sq:getPrimaryAddressForUser"
  }

  private inner class GetAddressForFeatureQuery<out T : Any>(
    public val FeatureObjectID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("AddressTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("AddressTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT AddressTable.UniqueID, AddressTable._id, AddressTable.UserID, AddressTable.FeatureObjectID, AddressTable.Name, AddressTable.ZipCode, AddressTable.Country, AddressTable.State, AddressTable.City, AddressTable.Area, AddressTable.LandMark, AddressTable.House, AddressTable.FlatNumber, AddressTable.Floor, AddressTable.MobileNumber, AddressTable.Type, AddressTable.Location, AddressTable.IsPrimary, AddressTable.IsDeleted, AddressTable.CreatedAt, AddressTable.UpdatedAt FROM AddressTable WHERE FeatureObjectID ${ if (FeatureObjectID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, FeatureObjectID)
    }

    override fun toString(): String = "AddressTable.sq:getAddressForFeature"
  }

  private inner class GetLatestMediaFileDateQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("AddressTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("AddressTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(2_004_437_227,
        """SELECT UpdatedAt FROM AddressTable WHERE _id = ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "AddressTable.sq:getLatestMediaFileDate"
  }
}
