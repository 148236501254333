package feature.businessConfig.handler

import feature.businessConfig.model.BusinessConfig
import feature.businessConfig.repository.BusinessConfigRepository

class BusinessConfigHandler {
    init {
        instance = this
    }

    companion object {
        private var instance: BusinessConfigHandler? = null
        fun shared(): BusinessConfigHandler {
            if (instance != null) {
                return instance as BusinessConfigHandler
            } else {
                instance = BusinessConfigHandler()
                return instance as BusinessConfigHandler
            }
        }
    }

    val repository = BusinessConfigRepository()
    var onCreateNewCustomer: ((customer: BusinessConfig) -> Unit)? = null
    val currencySymbol: String
        get() {
            val currentConfig = repository.businessConfig.value
            return currentConfig?.currencySymbol ?: ""
        }
}