package feature.business.event

enum class BusinessEvent(val value: String) {
    CREATE("CREATE_BUSINESS"),
    RETRIEVE("RETRIEVE_BUSINESS"),
    RETRIEVE_SINGLE("RETRIEVE_SINGLE_BUSINESS"),
    UPDATE("UPDATE_BUSINESS"),
    UPDATE_ADDRESS("UPDATE_BUSINESS_ADDRESS"),
    UPDATE_PROFILE_IMAGE("UPDATE_BUSINESS_PROFILE_IMAGE"),
    UPDATE_IMAGE("UPDATE_BUSINESS_IMAGE"),
    DELETE("DELETE_BUSINESS"),
    CREATE_BUSINESS_TYPE("CREATE_BUSINESS_TYPE"),
    RETRIVE_BUSINESS_TYPE("RETRIVE_BUSINESS_TYPE"),
    UPDATE_BUSINESS_TYPE("UPDATE_BUSINESS_TYPE"),
    DELETE_BUSINESS_TYPE("DELETE_BUSINESS_TYPE"),
    FIND_BUSINESS("FIND_BUSINESS"),
    ADD_BUSINESS_CONNECTION("ADD_BUSINESS_CONNECTION"),
    NEARBY_BUSINESS("NEARBY_BUSINESS")

}