package feature.employee.event

enum class EmployeeEvent(val value: String) {
    CREATE("CREATE_EMPLOYEE"),
    UPDATE("UPDATE_EMPLOYEE"),
    RETRIEVE("RETRIEVE_EMPLOYEE"),
    RETRIEVE_FOR_USER("RETRIEVE_FOR_USER"),
    DELETE("DELETE"),
    FIND_USER("FIND_USER"),
    CREATE_EMPLOYEE_CONNECTION_REQUEST("CREATE_EMPLOYEE_CONNECTION_REQUEST"),
    ACCEPT_EMPLOYEE_CONNECTION_REQUEST("ACCEPT_EMPLOYEE_CONNECTION_REQUEST"),
    DELETE_EMPLOYEE_CONNECTION_REQUEST("DELETE_EMPLOYEE_CONNECTION_REQUEST"),
}