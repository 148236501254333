package com.friendly.common.database

import kotlin.Boolean
import kotlin.Double
import kotlin.Long
import kotlin.String

public data class BusinessTable(
  public val _id: String,
  public val BusinessNumber: Long?,
  public val UserID: String?,
  public val BusinessTypeID: String?,
  public val Name: String?,
  public val Address: String?,
  public val Location: String?,
  public val Latitude: Double?,
  public val Longitude: Double?,
  public val DealerName: String?,
  public val ProductTypes: String?,
  public val GSTNumber: String?,
  public val PanNumber: String?,
  public val GSTVerified: String?,
  public val PanVerified: String?,
  public val BusinessVerified: String?,
  public val Status: String?,
  public val EmailID: String?,
  public val MobileNumber: String?,
  public val DialCode: String?,
  public val Gender: Long?,
  public val DeviceID: String?,
  public val FCMToken: String?,
  public val LastSeen: String?,
  public val IsPrimary: Boolean?,
  public val IsDeleted: Boolean?,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
)
