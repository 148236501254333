package locale

import feature.auth.handler.AuthHandler
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

fun localization(): Localization {
    return AuthHandler.shared().repository.localization.value
}

@Serializable
data class Localization(
    @SerialName("business_menu") var businessMenu: BusinessMenuLocalization = BusinessMenuLocalization(),
    @SerialName("product") var product: ProductLocalization = ProductLocalization(),
    @SerialName("button") var button: ButtonLocalization = ButtonLocalization(),
    @SerialName("category") var categoryMenu: CategoryLocalization = CategoryLocalization(),
    @SerialName("sub_category") var subCategory: SubCategoryLocalization = SubCategoryLocalization(),
    @SerialName("store_menu") var storeMenu: StoreMenuLocalization = StoreMenuLocalization(),
    @SerialName("employee") var employee: EmployeeLocalization = EmployeeLocalization(),
    @SerialName("customer") var customer: CustomerLocalization = CustomerLocalization(),
    @SerialName("accounts") var accounts: AccountsLocalization = AccountsLocalization(),
    @SerialName("address") var address: AddressLocalization = AddressLocalization(),
    @SerialName("account_authentication") var accountAuthentication: AccountsAuthenticationLocalization = AccountsAuthenticationLocalization(),
    @SerialName("business_list") var businessList: BusinessListLocalization = BusinessListLocalization(),
    @SerialName("stock_list") var stockList: StockListLocalization = StockListLocalization(),
    @SerialName("stock") var stock: StockLocalization = StockLocalization(),
    @SerialName("inventory") var inventory: InventoryListLocalization = InventoryListLocalization()
)

@Serializable
data class ProductLocalization(
    @SerialName("products_title") var productsTitle: String = "Products",
    @SerialName("products_search_placeholder") var productsSearchPlaceholder: String = "Search Product",
    @SerialName("products_total") var productsTotal: String = "Total ",
    @SerialName("products_product") var productsProduct: String = " Products",
    @SerialName("products_create_new") var productsCreateNew: String = "Create Product",

    @SerialName("products_label_name") var productsLabelName: String = "Name",
    @SerialName("products_name_placeholder") var productsNamePlaceholder: String = "Name Of The Product ",

    @SerialName("products_label_description") var productsLabelDescription: String = "Description",
    @SerialName("products_description_placeholder") var productsDescriptionPlaceholder: String = "Detail About Product",

    @SerialName("products_label_sale_price") var productsLabelSalePrice: String = "Sale Price",
    @SerialName("products_sale_price_placeholder") var productsSalePricePlaceholder: String = "Sale Price",

    @SerialName("products_save_button") var productsSaveButton: String = "Save",
    @SerialName("products_next_button") var productsNextButton: String = "Next",
    @SerialName("products_prev_button") var productsPrevButton: String = "Prev",

    @SerialName("products_alert_title") var productsAlertTitle: String = "Oops",
    @SerialName("products_alert_message") var productsAlertMessage: String = "Something Went Wrong",

    @SerialName("products_mrp") var productMRP: String = "MRP",
    @SerialName("products_mrp_placeholder") var productMRPPlaceholder: String = "MRP",

    @SerialName("products_cost_price") var productsCostPrice: String = "Cost Price",
    @SerialName("products_cost_price_placeholder") var productsCostPricePlaceholder: String = "Cost Price",

    @SerialName("products_sgst") var productsSGST: String = "SGST",
    @SerialName("products_sgst_placeholder") var productsSGSTPlaceholder: String = "SGST",

    @SerialName("products_cgst") var productsCGST: String = "CGST",
    @SerialName("products_cgst_placeholder") var productsCGSTPlaceholder: String = "CGST",

    @SerialName("products_igst") var productsIGST: String = "IGST",
    @SerialName("products_igst_placeholder") var productsIGSTPlaceholeder: String = "IGST",

    @SerialName("products_cess") var productsCESS: String = "CESS",
    @SerialName("products_cess_placeholder") var productsCESSPlaceholder: String = "CESS",

    @SerialName("products_vat") var productsVAT: String = "VAT",
    @SerialName("products_vat_placeholder") var productsVATPlaceholder: String = "VAT",

    @SerialName("products_other_tax") var productsOtherTax: String = "Other TAX",
    @SerialName("products_other_tax_placeholder") var productsOtherTaxPlaceholder: String = "Other TAX",
)

@Serializable
data class ButtonLocalization(
    @SerialName("done_button") var doneButton: String = "Done",
    @SerialName("save_button") var saveButton: String = "Save",
    @SerialName("next_button") var nextButton: String = "Next",
    @SerialName("prev_button") var prevButton: String = "Prev",
    @SerialName("update_button") var updateButton: String = "Update",
    @SerialName("continue_button") var continueButton: String = "Continue"
)

@Serializable
data class BusinessMenuLocalization(
    @SerialName("sales") var sale: String = "Sale",
    @SerialName("inventory") var inventory: String = "Inventory",
    @SerialName("store") var store: String = "Store",
    @SerialName("menu_label_sale") var menuLabelSale: String = "Sale",
    @SerialName("invoice") var invoice: String = "Invoice",
    @SerialName("product") var product: String = "Product",
    @SerialName("category") var category: String = "Category",
    @SerialName("sub_category") var subCategory: String = "Sub Category",
    @SerialName("stock") var stock: String = "Stock",
    @SerialName("store_label") var storeLabel: String = "Store",
    @SerialName("dashboard") var dashboard: String = "Dashboard",
    @SerialName("employee") var employee: String = "Employee",
    @SerialName("customer") var customer: String = "Customer",
    @SerialName("all_business") var allBusiness: String = "All Business",
)

@Serializable
data class CategoryLocalization(
    @SerialName("category_title") var categoryTitle: String = "Category",
    @SerialName("category_create") var categoryCreate: String = "Create Category",
    @SerialName("category_name") var categoryName: String = "Name",
    @SerialName("category_select") var categorySelect: String = "Tap To Select Category",
    @SerialName("category_name_placeholder") var categoryNamePlaceholder: String = "Category Name",
    @SerialName("category_update_title") var categoryUpdateTitle: String = "Update Category",
    @SerialName("category_update_name") var categoryUpdateName: String = "Name",
    @SerialName("category_update_name_placeholder") var categoryUpdateNamePlaceholder: String = "Category Name",
    @SerialName("category_update_alert_title") var categoryUpdateAlertTitle: String = "Error !!",
    @SerialName("category_update_alert_message") var categoryUpdateAlertMessage: String = "lease enter valid input",
)

@Serializable
data class SubCategoryLocalization(
    @SerialName("sub_category_title") var subCategoryTitle: String = "Sub Category",
    @SerialName("sub_category_create") var subCategoryCreate: String = "Create Sub Category",
    @SerialName("sub_category_name") var subCategoryName: String = "Sub Category Name",
    @SerialName("sub_category_select") var subCategorySelect: String = "Tap To Select Sub Category",
    @SerialName("sub_category_name_placeholder") var subCategoryNamePlaceholder: String = "Category Name",
    @SerialName("sub_category_update_title") var subCategoryUpdateTitle: String = "update sub category",
    @SerialName("sub_category_update_alert_title") var subCategoryUpdateAlertTitle: String = "Error !!",
    @SerialName("sub_category_update_alert_message") var subCategoryUpdateAlertMessage: String = "Please Enter Valid Input",
    @SerialName("sub_category_update_alert_no_sub") var subCategoryUpdateAlertNoSub: String = "No Sub Category To Update",
    @SerialName("sub_category_alert_second_message") var subCategoryAlertSecondMessage: String = "please Select Category"
// @SerialName("category_update_title") var categoryUpdateTitle: String = "Update Category",
)

@Serializable
data class StockListLocalization(
    @SerialName("stock_list_title") var stockLisTitle: String = "Stocks"
)

@Serializable
data class InventoryListLocalization(
    @SerialName("inventory_list_title") var inventoryLisTitle: String = "Inventory List"
)

@Serializable
data class StockLocalization(
    @SerialName("stocks_title") var stockTitle: String = "Stocks",
    @SerialName("stock_create") var stockCreate: String = "Create Stock",
    @SerialName("stock_update") var stockUpdate: String = "Update Stock",
)

@Serializable
data class StoreMenuLocalization(
    @SerialName("store_title") var storeTitle: String = "Store",
    @SerialName("finance") var finance: String = "Finance",
    @SerialName("marketing") var marketing: String = "Marketing",
    @SerialName("apps") var apps: String = "Apps",
    @SerialName("currency_inr") var currencyInr: String = "Currency INR",
    @SerialName("currency_title") var currencyTitle: String = "Currency",
    @SerialName("total_currency") var totalCurrency: String = "Total Currency",
    @SerialName("account") var account: String = "Account",
    @SerialName("banner") var banner: String = "Banner",
    @SerialName("notify_customer") var notifyCustomer: String = "Notify Customer",
    @SerialName("app") var app: String = "App",
    @SerialName("dashboard") var dashboard: String = "Dashboard",

    )

@Serializable
data class EmployeeLocalization(
    @SerialName("employee_title") var employeeTitle: String = "Employee",
    @SerialName("search_employee_placeholder") var searchEmployeePlaceholder: String = "Search Employee",

    @SerialName("employee_card_name") var employeeCardName: String = "Name:",
    @SerialName("employee_card_mobile") var employeeCardMobile: String = "Mobile: ",

    @SerialName("employee_alert_title_message") var employeeAlertTitleMessage: String = "Oops, There is nothing to show here, please come back later or try reloading ",

    @SerialName("add_new_employee") var addNewEmployee: String = "Add New Employee",
    @SerialName("employee_total") var employeeTotal: String = "Total",
    @SerialName("employee_employees") var employeeEmployees: String = "Employee",

    @SerialName("create_employee") var createEmployee: String = "Create Employee",
    @SerialName("employee_name") var employeeName: String = "Name ",
    @SerialName("employee_name_placeholder") var employeeNamePlaceholder: String = "Enter full Name",
    @SerialName("employee_email_id") var employeeEmailId: String = "Email Id",
    @SerialName("employee_email_placeholder") var employeeEmailPlaceholder: String = "eg: abc123@gmail.com",
    @SerialName("employee_mobile_number") var employeeMobileNumber: String = "Mobile No.",
    @SerialName("employee_barcode") var employeeBarcode: String = "Barcode",
    @SerialName("employee_barcode_placeholder") var employeeBarcodePlaceholder: String = "Barcode",
    @SerialName("create_save_button") var createSaveButton: String = "SAVE",
    @SerialName("create_success_title") var createSuccessTitle: String = "Success",
    @SerialName("create_success_message") var createSuccessMessage: String = "Customer Created",
    @SerialName("create_success_else_message") var createSuccessElseMessage: String = "Something Went Wrong!",
    @SerialName("create_alert_title") var createAlertTitle: String = "Oops!",
    @SerialName("create_alert_message") var createAlertMessage: String = "Please Enter Valid Input",
    @SerialName("create_done_button") var createDoneButton: String = "DONE!",
)

@Serializable
data class CustomerLocalization(
    @SerialName("customer_title") var customersTitle: String = "Customers",
    @SerialName("search_customer_placeholder") var searchCustomerPlaceholder: String = "Search Customer",
    //@SerialName("customer_alert_title") var customerAlertTitle:  String = "Oops",
    @SerialName("customer_alert_message") var customerAlertMessage: String = "There is nothing to show here, please come back later or try reloading ",
    @SerialName("customer_total") var customerTotal: String = "Total",
    @SerialName("customer_customers") var customerCustomers: String = "Customers",

    @SerialName("customer_card_name") var customerCardName: String = "Name:",
    @SerialName("customer_card_email_id") var customerCardEmailId: String = "Email ID:",
    @SerialName("customer_card_mobile_number") var customerCardMobileNumber: String = "Mobile Number:",
    @SerialName("customer_card_barcode") var customerCardBarcode: String = "Barcode:",

    @SerialName("create_customer_title") var createCustomerTitle: String = "Create Customer",
    @SerialName("create_customer_name") var createCustomerName: String = "Name",
    @SerialName("create_customer_email_id") var createCustomerEmailId: String = "Email ID",
    @SerialName("create_customer_mobile_number") var createCustomerMobileNumber: String = "Mobile Number",
    @SerialName("create_customer_barcode") var createCustomerBarcode: String = "Barcode",
    @SerialName("create_customer_name_placeholder") var createCustomerNamePlaceholder: String = "Enter Full Name",
    @SerialName("create_customer_email_placeholder") var createCustomerEmailPlaceholder: String = "eg: abcd123@gmail.com",
    @SerialName("create_customer_barcode_placeholder") var createCustomerBarcodePlaceholder: String = "Barcode",
    @SerialName("create_customer_save_button") var createCustomerSaveButton: String = "Save",
    @SerialName("create_success_title") var createSuccessTitle: String = "Success",
    @SerialName("create_success_message") var createSuccessMessage: String = "Customer Created",
    @SerialName("create_success_else_message") var createSuccessElseMessage: String = "Something Went Wrong!",
    @SerialName("create_customer_alert_title") var createCustomerAlertTitle: String = "Oops",
    @SerialName("create_customer_alert_message") var createCustomerAlertMessage: String = "Please Enter Valid Input",
    @SerialName("edit_customer_title") var editCustomerTitle: String = "Edit Customer",
    @SerialName("edit_customer_name") var editCustomerName: String = "Name",
    @SerialName("edit_customer_name_placeholder") var editCustomerNamePlaceholder: String = "Enter Full Name",
    @SerialName("edit_email_id") var editEmailId: String = "Email ID",
    @SerialName("edit_email_placeholder") var editEmailPlaceholder: String = "eg: abcd1234@gmail.com",
    @SerialName("edit_mobile_number") var editMobileNumber: String = "Mobile Number",
    @SerialName("edit_barcode") var editBarcode: String = "Barcode",

    @SerialName("edit_barcode_placeholder") var editBarcodePlaceholder: String = "Barcode",
    @SerialName("edit_continue_button") var editContinueButton: String = "Continue",
    @SerialName("create_edit_customer_alert_title") var createEditCustomerAlertTitle: String = "Oops!",
    @SerialName("create_edit_customer_alert_message") var createEditCustomerAlertMessage: String = "Please Enter Valid Input",

    )

@Serializable
data class AccountsLocalization(
    @SerialName("accounts_title") var accountsTitle: String = "Account",
    @SerialName("name") var name: String = "Name",
    @SerialName("name_placeholder") var namePlaceholder: String = "Your Full Name",
    @SerialName("contact") var contact: String = "Contact",
    @SerialName("address") var address: String = "Address",
    @SerialName("language") var language: String = "Language",
    @SerialName("language_title") var languageTitle: String = "Language",
    @SerialName("total_language") var totalLanguage: String = "Total Language",
    @SerialName("logout") var logout: String = "Logout",
    @SerialName("logout_alert_title") var logoutAlertTitle: String = "Successfully,",
    @SerialName("logout_alert_message") var logoutAlertMessage: String = "Logged Out",

    )

@Serializable
data class AddressLocalization(
    @SerialName("address_title") var addressTitle: String = "Address",
    @SerialName("create_new_address_button") var createNewAddressButton: String = "Create New Address",
    @SerialName("create_new_address") var createNewAddress: String = "Create New Address",

    @SerialName("house_number") var houseNumber: String = "House Number",
    @SerialName("block_area_village_locality") var blockAreaVillageLocality: String = "block Area Village Locality",
    @SerialName("landmark") var landmark: String = "Landmark",
    @SerialName("pincode_zipcode") var pincodeZipcode: String = "Pincode/Zipcode",
    @SerialName("city") var city: String = "City",
    @SerialName("state") var state: String = "State",
    @SerialName("save_button") var saveButton: String = "Save"
)

@Serializable
data class AccountsAuthenticationLocalization(
    @SerialName("accounts_title") var accountsTitle: String = "Accounts",
    @SerialName("login_register") var loginRegister: String = "Login/Register",
    @SerialName("sign_in") var signIn: String = "Sign In",
    @SerialName("mobile_number") var mobileNumber: String = "Mobile Number",
    @SerialName("continue_button") var continueButton: String = "Continue",
    @SerialName("otp_title") var otpTitle: String = "OTP",
    @SerialName("otp_placeholder") var otpPlaceholder: String = "OTP",
    @SerialName("verify_button") var verifyButton: String = "Verify",
    @SerialName("verify_pin") var verifyPin: String = "Verify Mpin",
    @SerialName("six_digit_pin") var sixDigitPin: String = "Six Digit Mpin",
    @SerialName("welcome_to") var welcomeTo: String = "Welcome To ",
    @SerialName("sign_in_screen_text") var signInScreenText: String = "Please provide your mobile number to continue",
    @SerialName("dont_remember_pin") var dontRememberPin: String = "Don't Remember MPin ?",
    @SerialName("login_with_otp") var loginWithOTP: String = "Login with OTP",
)

@Serializable
data class BusinessListLocalization(
    @SerialName("title") var title: String = "Business List",
    @SerialName("select_business") var selectBusiness: String = "Select Type of Store",
    @SerialName("name_of_the_business") var nameOfTheBusiness: String = "Name Of The Business",
    @SerialName("email_id") var emailId: String = "Email ID",
    @SerialName("mobile_number") var mobileNumber: String = "Mobile Number",
    @SerialName("enter_pin_code") var enterPinCode: String = "Enter Pin Code",
    @SerialName("enter_city") var enterCity: String = "Enter City",
    @SerialName("state") var state: String = "State",
    @SerialName("locality_area_street") var localityAreaStreet: String = "Locality/Area/Street",
    @SerialName("flatno_building_name") var flatnoBuildingName: String = "Flat no./Building Name",
    @SerialName("landmark") var landmark: String = "Landmark",
)




