package feature.cart.network

import common.constants.Server
import feature.cart.event.CartEvent
import feature.customer.event.CustomerEvent
import feature.customer.network.FetchCustomerRequest
import feature.customer.network.FetchCustomerResponse
import feature.customer.network.FindCustomerRequest
import feature.customer.network.FindCustomerResponse
import feature.customer.network.UpdateCustomerRequest
import feature.customer.network.UpdateCustomerResponse
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient

class CartNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    suspend fun findInvoice(
        request: FindCustomerRequest,
        onResult: (FindCustomerResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(CustomerEvent.FIND_BY_MOBILE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun createCustomerInvoice(
        request: CreateCustomerInvoiceRequest,
        onResult: (CreateCustomerInvoiceResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(CartEvent.GENERATE_CUSTOMER_INVOICE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t.message)
            onResult(null)
        }
    }

    suspend fun updateCustomer(
        request: UpdateCustomerRequest,
        onResult: (UpdateCustomerResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(CustomerEvent.UPDATE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun retrieveCustomer(
        request: FetchCustomerRequest,
        onResult: (FetchCustomerResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(CustomerEvent.RETRIEVE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }
}