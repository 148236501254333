package feature.businessType.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class BusinessType(
    @SerialName("_id") var id: String? = null,
    @SerialName("Name") var name: String? = null,
    @SerialName("Description") var description: String? = null,
    @SerialName("Image") var image: ArrayList<String> = arrayListOf(),
    @SerialName("BusinessTypeID") var businessTypeID: Long = 0,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)