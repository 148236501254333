package feature.inventory.network.inventory

import common.constants.Server
import feature.inventory.event.InventoryEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class InventoryNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: InventoryNetwork? = null
        fun shared(): InventoryNetwork {
            if (instance != null) {
                return instance as InventoryNetwork
            } else {
                return InventoryNetwork()
            }
        }
    }

    fun retrieve(
        request: RetrieveInventoryRequest,
        onResult: (RetrieveInventoryResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(InventoryEvent.RETRIEVE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }

    fun create(
        request: CreateInventoryRequest,
        onResult: (CreateInventoryResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(InventoryEvent.CREATE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }

    fun update(
        request: UpdateInventoryRequest,
        onResult: (UpdateInventoryResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(InventoryEvent.UPDATE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }

    fun delete(
        request: DeleteInventoryRequest,
        onResult: (DeleteInventoryResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(InventoryEvent.DELETE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }
}