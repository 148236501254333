package presentation.screens.business.store.accoun

import androidx.compose.foundation.layout.Column
import androidx.compose.runtime.Composable
import component.navigation.TopNavBar
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen

class MyBusinessAccountsScreens(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        Column {
            TopNavBar(
                "PaymentModes", null, onBackPress = {
                    AppNavigator.shared().pop()
                }
            )
        }
    }
}