import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.ComposeViewport
import appConfig.AppConfig
import datasource.DriverFactory
import graph.PlatformType
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import navigation.AppNavigator
import navigation.AppWindow
import org.jetbrains.skiko.wasm.onWasmReady
import payment.PaymentHandler
import storage.AppStorage

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    onWasmReady {
        AppNavigator.shared().onWindowLocationChange = { newLocation ->
            if (newLocation.isNotEmpty()) {
                window.location.assign(newLocation)
            } else {
                window.history.back()
            }
        }
        CoroutineScope(Job() + Dispatchers.Default).launch {
            AppStorage.shared().setup(DriverFactory().createDriver())
        }
        PaymentHandler().setup(window)
        AppNavigator.shared().checkDeployedDomainForWeb(window.location.href)
        AppConfig.shared().platformType = PlatformType.Web
        AppConfig.shared().platform = PlatformType.Web
        ComposeViewport(document.body!!) {
//            BrowserApp().getWindow(
//                window.location.href,
//                window.screen.width,
//                window.screen.height
//            )
            AppWindow()
            AppNavigator.shared().handleLink(window.location.href)
        }

    }
}

