package feature.order.viewModel

import feature.app.AppViewModel
import feature.app.NavigationParam
import feature.auth.handler.AuthHandler
import feature.cart.model.CartItem
import feature.cart.viewModel.CartViewModel
import feature.order.handler.OrderHandler
import feature.order.model.Order
import feature.order.network.CreateOrderRequest
import feature.order.network.OrderNetwork
import feature.order.network.RetrieveOrderRequest
import feature.order.repository.OrderRepository

import feature.product.model.ProductPrice
import kotlinx.coroutines.launch

class OrderViewModel : AppViewModel() {
    val repository: OrderRepository = OrderHandler.shared().repository
    val allOrders = repository.allOrder
    fun loadOrders(param: NavigationParam?) {
        val request = RetrieveOrderRequest(
            AuthHandler.shared().userId,
            param?.business?.Id,
            param?.selectedBusiness?.Id
        )
        startLoading()
        OrderNetwork.shared().retrieve(request) { response ->
            stopLoading()
            if (response?.payload?.isNotEmpty() == true) {
                scope.launch {
                    repository.allMutableOrder.emit(response.payload)
                }
            }
        }
    }

    fun create(
        param: NavigationParam?,
        cartViewModel: CartViewModel,
        items: List<CartItem>,
        callBack: (Boolean?, Order?) -> Unit
    ) {
        val pricing = ProductPrice()
        pricing.FinalPrice = cartViewModel.totalAmount.value
        val request = CreateOrderRequest(
            timeStamp,
            userId = AuthHandler.shared().userId,
            businessID = param?.business?.Id,
            selectedBusinessID = param?.selectedBusiness?.Id,
            addressID = param?.address?._id,
            AuthHandler.shared().deviceId,
            orderItems = items as ArrayList<CartItem>,
            pricing,
            deliveryType = if (param?.address?._id.isNullOrEmpty()) "TAKE_AWAY" else "DELIVERY"
        )
        startLoading()
        OrderNetwork.shared().create(request) { response ->
            stopLoading()
            if (response?.payload?._id.isNullOrEmpty()) {
                callBack(false, null)
            } else {
                callBack(true, response?.payload)
            }
        }

    }
//
//    fun retrieve() {
//        val user = FriendlyUser()
//        val request = JSONObject()
//        request.put(KeyConstant.userId, user._id)
//        if (BusinessHandler.shared().repository.business.value?.Id.isNullOrEmpty()) {
//            request.put(
//                KeyConstant.businessID,
//                BusinessHandler.shared().repository.business.value?.Id
//            )
//        }
//        OrderNetwork.shared().retrieve(request) { responseJson ->
//            try {
//                val payload = responseJson?.getJSONArray(KeyConstant.payload)
//                if (payload != null) {
//                    for (i in 0 until payload.length()) {
//                        val orderJsonObject = payload.getJSONObject(i)
//                        val order = Gson().fromJson(orderJsonObject.toString(), Order::class.java)
//                        insert(order)
//                    }
//                }
//                loadOrders()
//            } catch (e: Exception) {
//                e.printStackTrace()
//
//            }
//        }
//    }
//
//    fun insert(order: Order) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.orderDao()
//                .insert(order)
//        }
//    }

}