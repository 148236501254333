package presentation.screens.business.homepage

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import presentation.screens.business.homepage.component.ServingCard
import theme.ThemeColor

@Composable
fun ServingPage() {
    Column(
        modifier = Modifier
            .fillMaxWidth().fillMaxHeight().padding(top = 80.dp, bottom = 60.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            "BUSINESSES",
            fontSize = 20.sp,
            color = ThemeColor.shared().Grey
            , modifier = Modifier.padding(top = 25.dp)
        )
        Text(
            "Serving businesses and clients",
            fontSize = 40.sp,
            color = ThemeColor.shared().PrimaryColor,
            fontWeight = FontWeight.Bold
            , modifier = Modifier.padding(top = 25.dp)
        )
        Text(
            "Happy Businesses and clients",
            fontSize = 20.sp,
            color = ThemeColor.shared().Grey
            , modifier = Modifier.padding(top = 25.dp)
        )
        LazyRow(
            modifier = Modifier.fillMaxWidth()
                .height(500.dp).padding(40.dp), horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            item { ServingCard() }
            item { ServingCard() }

        }
//    Column(
//        modifier = Modifier
//            .fillMaxWidth().fillMaxHeight().padding(top = 80.dp, bottom = 60.dp),
//        horizontalAlignment = Alignment.CenterHorizontally,
//        verticalArrangement = Arrangement.SpaceBetween
//    )
        Text(
            "SOLUTE",
            fontSize = 20.sp,
            color = ThemeColor.shared().Grey, modifier = Modifier.padding(top = 25.dp)
        )
        Text(
            "Why Solute ?",
            fontSize = 40.sp,
            color = ThemeColor.shared().PrimaryColor,
            fontWeight = FontWeight.Bold, modifier = Modifier.padding(top = 25.dp)
        )
        Text(
            "Always Secured and Reliable",
            fontSize = 20.sp,
            color = ThemeColor.shared().Grey, modifier = Modifier.padding(top = 25.dp)
        )
    }
}


