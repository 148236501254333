package feature.inventory.handler

import feature.inventory.repository.InventoryRepository
import feature.inventory.viewModel.inventory.InventoryViewModel

class InventoryHandler {

    var viewModel: InventoryViewModel? = null
    val repository = InventoryRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: InventoryHandler? = null
        fun shared(): InventoryHandler {
            if (instance != null) {
                return instance as InventoryHandler
            } else {
                return InventoryHandler()
            }
        }
    }

    fun setup(model: InventoryViewModel) {
        viewModel = model
    }

}