package presentation.screens.business.inventory.product.detail

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.button.SecondaryButton
import component.image.ImageView
import component.loader.AppLoader
import component.navigation.TopNavBar
import component.text.TextRow
import feature.cart.viewModel.CartViewModel
import feature.inventory.model.Inventory
import feature.inventory.viewModel.inventory.InventoryViewModel
import feature.product.viewModel.ProductViewModel
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.inventory.product.detail.component.ColorCard
import presentation.screens.business.inventory.product.detail.component.SizeCard
import presentation.screens.business.inventory.product.detail.component.UOMCard
import presentation.screens.customer.product.component.FeedbackCard
import theme.ThemeColor

class ProductDetailScreen(override val screenName: ScreenName = ScreenName.PRODUCT_DETAILS) :
    AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val viewModel = ProductViewModel()
        val invViewModel = InventoryViewModel()
        val cartViewModel = CartViewModel()
        val product = navigationParam?.product
        val allInventory = invViewModel.allInventory.collectAsState()
        val filteredInventory: MutableState<List<Inventory>> = remember {
            mutableStateOf(
                arrayListOf()
            )
        }
        val selectedInventory: MutableState<Inventory?> = remember {
            mutableStateOf(
                null
            )
        }
        val navigator = LocalNavigator.current

        val colorId = remember<MutableState<String?>> { mutableStateOf(null) }
        val sizeId = remember<MutableState<String?>> { mutableStateOf(null) }
        val uomId = remember<MutableState<String?>> { mutableStateOf(null) }

        fun getDistinctIds(items: List<Inventory>, itemType: String): List<String?> {
            if(itemType == "color")
            {
                return items.map { it.ColorID }.distinct()
            }
            else if(itemType == "size"){
                return items.map { it.SizeID }.distinct()
            }
            else{
                return items.map { it.UOMID }.distinct()

            }
        }
        val dColors = getDistinctIds( allInventory.value, "color")
        val dSize = getDistinctIds( allInventory.value, "size")
        val dUoM = getDistinctIds( allInventory.value, "uom")

        LaunchedEffect(key1 = Unit) {
            invViewModel.loadInventory(navigationParam)
        }
        fun findById() {
            invViewModel.findById(
                uomId = uomId.value,
                colorId = colorId.value,
                sizeId = sizeId.value,
                param = navigationParam
            ) { data ->
                filteredInventory.value = data
            }
        }

        var minMRPPrice = 0.0
        var maxMRPPrice = 0.0
        var minFinalPrice = 0.0
        var maxFinalPrice = 0.0
        var lowestProduct: Inventory? = null
        var heighestProduct: Inventory? = null
        var lowestMRPProduct: Inventory? = null
        var heighestMRPProduct: Inventory? = null
        if(filteredInventory.value.isNullOrEmpty()){
            allInventory.value.forEach { inv ->
                if ((inv.ProductPrice.FinalPrice!! < minFinalPrice) || minFinalPrice == 0.0) {
                    minFinalPrice = inv.ProductPrice.FinalPrice!!
                    lowestProduct = inv
                }
                if (inv.ProductPrice.FinalPrice!! > maxFinalPrice) {
                    maxFinalPrice = inv.ProductPrice.FinalPrice!!
                    heighestProduct = inv
                }
                if ((inv.ProductPrice.MRP!! < minMRPPrice) || minMRPPrice == 0.0) {
                    minMRPPrice = inv.ProductPrice.MRP!!
                    lowestMRPProduct = inv
                }
                if (inv.ProductPrice.MRP!! > maxMRPPrice) {
                    maxMRPPrice = inv.ProductPrice.MRP!!
                    heighestMRPProduct = inv
                }
            }

        }else{
            filteredInventory.value.forEach { inv ->
                if ((inv.ProductPrice.FinalPrice!! < minFinalPrice) || minFinalPrice == 0.0) {
                    minFinalPrice = inv.ProductPrice.FinalPrice!!
                    lowestProduct = inv
                }
                if (inv.ProductPrice.FinalPrice!! > maxFinalPrice) {
                    maxFinalPrice = inv.ProductPrice.FinalPrice!!
                    heighestProduct = inv
                }
                if ((inv.ProductPrice.MRP!! < minMRPPrice) || minMRPPrice == 0.0) {
                    minMRPPrice = inv.ProductPrice.MRP!!
                    lowestMRPProduct = inv
                }
                if (inv.ProductPrice.MRP!! > maxMRPPrice) {
                    maxMRPPrice = inv.ProductPrice.MRP!!
                    heighestMRPProduct = inv
                }
            }
        }
        var productMRP = product?.ProductPrice?.MRP.toString()
        var productFinalPrice = product?.ProductPrice?.FinalPrice.toString()

        if(filteredInventory.value.size == 1){
            productMRP = minMRPPrice.toString()
            productFinalPrice = minFinalPrice.toString()
        }else if(lowestProduct != null && heighestProduct != null) {
            productMRP = "Range - ${lowestMRPProduct!!.ProductPrice.MRP} - ${heighestMRPProduct!!.ProductPrice.MRP}"
            productFinalPrice = "Range - ${lowestProduct!!.ProductPrice.FinalPrice} - ${heighestProduct!!.ProductPrice.FinalPrice}"
        }

        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = "Product", navigationParam, false, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            LazyColumn(modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)) {
                item {
                    Box(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(230.dp)
                    ) {
                        ImageView(product?.Id ?: "", true)
                    }
                }
                item {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(55.dp)
                            .padding(4.dp),
                        verticalAlignment = Alignment.Bottom
                    )
                    {
                        Text(
                            product?.Name ?: "",
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp,
                            modifier = Modifier
                                .weight(0.7f)
                        )
                        Text(
                            product?.ProductPrice?.FinalPrice.toString(),
                            fontSize = 24.sp,
                            fontWeight = FontWeight.Bold
                        )
                        Text(
                            product?.ProductPrice?.MRP.toString(),
                            fontSize = 16.sp,
                            color = Color.Gray,
                            modifier = Modifier
                                .weight(0.1f),
                            style = TextStyle(textDecoration = TextDecoration.LineThrough)
                        )
                    }
                    Text(
                        " ⭐ 4.5 • 100+ Reviews",
                        fontSize = 16.sp,
                        color = Color.Gray,
                        modifier = Modifier
                            .padding(bottom = 8.dp)
                    )
                    HorizontalDivider(
                        modifier = Modifier
                            .padding(4.dp, 8.dp)
                            .background(color = Color.Gray),
                        thickness = 2.dp
                    )
                    Text(
                        product?.Description ?: "",
                        fontWeight = FontWeight.Normal,
                        fontSize = 18.sp,
                        modifier = Modifier
                            .weight(0.7f)
                    )
                    HorizontalDivider(
                        modifier = Modifier
                            .padding(4.dp, 8.dp)
                            .background(color = Color.Gray),
                        thickness = 2.dp
                    )
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(4.dp)
                            .background(color = ThemeColor.shared().defaultBakground)
                    ) {
                        Text(
                            "Color",
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp,
                            modifier = Modifier
                                .weight(0.5f)
                        )
                        Text(
                            "▼",
                            fontSize = 18.sp,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier
                        )
                    }
                    LazyRow(modifier = Modifier.fillMaxWidth().height(68.dp)) {
                        items(
                            dColors.count()
                        ) {
                            ColorCard(dColors[it],colorId.value,filteredInventory.value) {cId->
                                if(colorId.value == cId){
                                    colorId.value = null
                                }else{
                                    colorId.value = cId
                                }
                                findById()
                            }
                        }
                    }

                    HorizontalDivider(
                        modifier = Modifier
                            .padding(4.dp, 8.dp)
                            .background(color = Color.Gray),
                        thickness = 2.dp
                    )
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(4.dp)
                            .background(color = ThemeColor.shared().defaultBakground)
                    ) {
                        Text(
                            "Select Size",
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp,
                            modifier = Modifier
                                .weight(0.5f)
                        )
                        Text(
                            "▼",
                            fontSize = 18.sp,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier
                        )
                    }
                    LazyRow(modifier = Modifier.fillMaxWidth().height(68.dp)) {
                        items(
                            dSize.count()
                        ) {
                            SizeCard(dSize[it],selectedInventory.value,filteredInventory.value) {sId->
                                if(sizeId.value == sId){
                                    sizeId.value = null
                                }else{
                                    sizeId.value = sId
                                }
                                findById()
                            }
                        }
                    }

                    HorizontalDivider(
                        modifier = Modifier
                            .padding(4.dp, 8.dp)
                            .background(color = Color.Gray),
                        thickness = 2.dp
                    )
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(4.dp)
                            .background(color = ThemeColor.shared().defaultBakground)
                    ) {
                        Text(
                            "Select Unit of Measurement",
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp,
                            modifier = Modifier
                                .weight(0.5f)
                        )
                        Text(
                            "▼",
                            fontSize = 18.sp,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier
                        )
                    }
                    LazyRow(modifier = Modifier.fillMaxWidth().height(68.dp)) {
                        items(
                            dUoM.count()
                        ) {
                            UOMCard(dUoM[it],selectedInventory.value,filteredInventory.value) {uId->
                                if(uomId.value == uId){
                                    uomId.value = null
                                }else{
                                    uomId.value = uId
                                }
                                findById()
                                // selectedInventory.value = inventory
                            }
                        }
                    }

                    HorizontalDivider(
                        modifier = Modifier
                            .padding(4.dp, 8.dp)
                            .background(color = Color.Gray),
                        thickness = 2.dp
                    )
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(4.dp)
                            .background(color = ThemeColor.shared().defaultBakground)
                    ) {
                        Text(
                            "Description",
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp,
                            modifier = Modifier
                                .weight(0.5f)
                        )
                        Text(
                            "▼",
                            fontSize = 18.sp,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier
                        )
                    }
                    Text(
                        product?.Description ?: "",
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                    HorizontalDivider(
                        modifier = Modifier
                            .padding(4.dp, 8.dp)
                            .background(color = Color.Gray),
                        thickness = 3.dp
                    )
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(4.dp)
                            .background(color = ThemeColor.shared().defaultBakground)
                    ) {
                        Text(
                            "Reviews & Ratings",
                            fontWeight = FontWeight.Bold,
                            fontSize = 18.sp,
                            modifier = Modifier
                                .weight(0.5f)
                        )
                        Text(
                            "▼",
                            fontSize = 18.sp,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier
                        )
                    }
                }
                item { FeedbackCard() }
                item { FeedbackCard() }
                item {

                    Column(
                        modifier = Modifier
                            .fillMaxWidth().fillMaxHeight()
                            .padding(4.dp)
                            .background(color = ThemeColor.shared().defaultBakground)
                    ) {
                        HorizontalDivider(
                            modifier = Modifier
                                .padding(4.dp, 8.dp)
                                .background(color = Color.Gray),
                            thickness = 3.dp
                        )
                        Row {
                            Text(
                                "Similar Products",
                                fontWeight = FontWeight.Bold,
                                fontSize = 18.sp,
                                modifier = Modifier
                                    .weight(0.5f)
                            )
                            Text(
                                "▼",
                                fontSize = 18.sp,
                                fontWeight = FontWeight.Bold,
                                modifier = Modifier
                            )
                        }
                    }
                }
                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        TextRow(
                            title = "Mrp",
                            message = productMRP
                        )
                        TextRow(
                            title = "Final Price",
                            message = productFinalPrice
                        )
                    }
                }
                item {
                    if (navigationParam?.selectedBusiness?.Id == navigationParam?.business?.Id) {
                        Row(modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.16f)) {
                            SecondaryButton("Delete", {
                                product?.let {
                                    viewModel.deleteProduct(it) {
                                        navigator?.pop()
                                    }
                                }
                            })
                            DefaultButton("Edit"/*"Next"*/, onClick = {
                                viewModel.setEditProduct(product)
                                navigateTo(ScreenName.BUSINESS_INVENTORY_EDIT_PRODUCT)
                                AppNavigator.shared()
                                    .navigateTo(
                                        ScreenName.BUSINESS_INVENTORY_EDIT_PRODUCT
                                    )
                            })
                        }
                        Row(modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.16f)) {
                            DefaultButton("Add to Cart", onClick = {
                                cartViewModel.addProductToCart(product, navigationParam)
                            })
                        }
                    }
                }
            }
        }
    }
}