package presentation.screens.business.inventory.stock.list

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import component.button.DefaultButton
import component.empty.EmptyData
import component.input.SearchBar
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.inventory.viewModel.inventory.InventoryViewModel
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.inventory.stock.component.InventoryCard
import presentation.screens.business.inventory.stock.component.ProductCard
import theme.ThemeColor

class InventoryListScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val viewModel = InventoryViewModel()
        val allInventory = viewModel.allInventory.collectAsState()
        val searchQuery = viewModel.searchQuery.collectAsState()
        viewModel.searchQuery(searchQuery.value, navigationParam)
        val product = navigationParam?.product
        LaunchedEffect(key1 = Unit) {
            viewModel.loadInventory(navigationParam)
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(
                title = localization().inventory.inventoryLisTitle,
                navigationParam,
                onBackPress = {
                    AppNavigator.shared().pop()
                },
                onClickAdd = {
                    navigateTo(
                        ScreenName.BUSINESS_INVENTORY_STOCK_CREATE, navigationParam?.update(
                            NavigationParam(product = product)
                        )
                    )
                })
            AppLoader(viewModel)
            SearchBar(
                viewModel,
                searchQuery.value,
                localization().product.productsSearchPlaceholder
            )
            ProductCard(product, viewModel)
            if (allInventory.value.isEmpty()) {
                EmptyData(viewModel = viewModel)
                DefaultButton(
                    "Create New Inventory",
                    onClick = {
                        navigateTo(
                            ScreenName.BUSINESS_INVENTORY_STOCK_CREATE, navigationParam?.update(
                                NavigationParam(product = product)
                            )
                        )
                    })
            } else {
                Box(
                    modifier = Modifier
                        .height(50.dp)
                        .padding(start = 8.dp, end = 8.dp)
                        .background(
                            ThemeColor.shared().cardBackground,
                            shape = RoundedCornerShape(10.dp)
                        ),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "${localization().product.productsTotal} ${allInventory.value.count()} ${localization().product.productsProduct}",
                        maxLines = 2,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Center,
                        color = ThemeColor.shared().PrimaryColor
                    )
                }
            }
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 400.dp),
                modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
            ) {
                items(count = allInventory.value.count()) {
                    InventoryCard(allInventory.value[it]) { inv ->
                        navigateTo(
                            ScreenName.BUSINESS_INVENTORY_STOCK_UPDATE, navigationParam?.update(
                                NavigationParam(product = product, inventory = inv)
                            )
                        )
                    }
                }
            }
        }
    }
}