package presentation.screens.customer.store

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.loader.AppLoader
import component.navigation.TopNavBar
import component.offers.OfferBannerList
import component.product.ProductCard
import feature.app.NavigationParam
import feature.auth.handler.AuthHandler
import feature.cart.viewModel.CartViewModel
import feature.offerBanner.viewModel.OfferBannerViewModel
import feature.product.viewModel.ProductViewModel
import feature.productCategory.viewModel.ProductCategoryViewModel
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.customer.store.component.CartFooter
import presentation.screens.customer.store.component.StoreCategory
import presentation.screens.customer.store.component.StoreHeader
import theme.ThemeColor


class StoreDetailScreen(override val screenName: ScreenName, val hideBack: Boolean = false) :
    AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val cartViewModel = CartViewModel()
        viewModel = cartViewModel
        val offerBannerViewModel = OfferBannerViewModel()
        val productViewModel = ProductViewModel()
        val categoryViewModel = ProductCategoryViewModel()

        val allBanners = offerBannerViewModel.allData.collectAsState()
        val products = productViewModel.allProduct.collectAsState()

        val allCategory = categoryViewModel.allCategory.collectAsState()
        val navigator = LocalNavigator.current
        val cartItems = cartViewModel.cartItems.collectAsState()
        val searchQuery = cartViewModel.searchQuery.collectAsState()
        LaunchedEffect(key1 = Unit) {
            offerBannerViewModel.retrieve { }
            if (navigationParam?.selectedBusiness?.Id != null) {
                productViewModel.loadProductForBusiness(navigationParam!!.selectedBusiness!!.Id)
                categoryViewModel.retrieveForBusiness(navigationParam!!.selectedBusiness!!.Id)
            }
        }
        Column(
            modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)
        ) {
            if (hideBack) {
                TopNavBar(title = "", navigationParam)
            } else {
                TopNavBar(title = "", navigationParam, onBackPress = {
                    navigator?.pop()
                })
            }
            AppLoader(productViewModel)
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 160.dp),
                modifier = Modifier.weight(1f).padding(
                    start = 0.dp,
                    end = 0.dp,
                    bottom = if (cartItems.value.isNotEmpty()) 0.dp else (60.dp)
                ),
            ) {
                item(span = { GridItemSpan(maxCurrentLineSpan) }) {
                    StoreHeader(navigationParam, cartViewModel, searchQuery.value)
                }
                item(span = { GridItemSpan(maxCurrentLineSpan) }) {
                    OfferBannerList(allBanners.value)
                }
                item(span = { GridItemSpan(maxCurrentLineSpan) }) {
                    StoreCategory(allCategory)
                }
                items(products.value.count()) { index ->
                    ProductCard(products.value[index], cartViewModel, navigationParam) { product ->
                        navigateTo(
                            ScreenName.PRODUCT_DETAILS,
                            navigationParam?.update(NavigationParam(product = product))
                        )
                    }
                }
            }
            CartFooter(navigationParam, viewModel = cartViewModel) {
                if (AuthHandler.shared().isLoggedIn()) {
                    navigateTo(ScreenName.SHOPPING_CART, navigationParam)
                } else {
                    navigateTo(ScreenName.LOGIN, navigationParam)
                }
            }
        }
    }
}



