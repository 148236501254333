package presentation.screens.business.inventory.stock.create

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.button.SecondaryButton
import component.image.ImageView
import component.input.PriceInputField
import component.input.QuantityInputField
import component.input.TaxInputField
import component.input.TextInputField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.businessConfig.viewModel.BusinessConfigViewModel
import feature.inventory.viewModel.inventory.InventoryViewModel
import feature.product.viewModel.ProductViewModel
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.inventory.category.component.SelectCategoryRow
import presentation.screens.business.inventory.component.SelectBrandRow
import presentation.screens.business.inventory.component.SelectCoupanRow
import presentation.screens.business.inventory.component.SelectDiscountRow
import presentation.screens.business.inventory.component.SelectProductColorRow
import presentation.screens.business.inventory.component.SelectSizeRow
import presentation.screens.business.inventory.component.SelectTagRow
import presentation.screens.business.inventory.component.SelectUOMRow
import presentation.screens.business.inventory.stock.component.ProductCard
import presentation.screens.business.inventory.sub_category.component.SelectSubCategoryRow
import theme.ThemeColor

class CreateStockScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val businessConfigViewModel = BusinessConfigViewModel()
        val currency = businessConfigViewModel.selectedCurrency.collectAsState()
        val viewModel = ProductViewModel()
        val invViewModel = InventoryViewModel()
        val product = navigationParam?.product
        val currentStep = viewModel.productFormIndex.collectAsState()
        val navigator = LocalNavigator.current
        val name = remember { mutableStateOf("") }
        val model = remember { mutableStateOf("") }
        val barcode = remember { mutableStateOf("") }
        val brandId = remember<MutableState<String?>> { mutableStateOf(null) }
        val tagId = remember<MutableState<String?>> { mutableStateOf(null) }
        val uomId = remember<MutableState<String?>> { mutableStateOf(null) }
        val qty = remember { mutableStateOf(0L) }
        val colorId = remember<MutableState<String?>> { mutableStateOf(null) }
        val sizeId = remember<MutableState<String?>> { mutableStateOf(null) }
        val discountId = remember<MutableState<String?>> { mutableStateOf(null) }
        val coupanId = remember<MutableState<String?>> { mutableStateOf(null) }
        val categoryId = remember<MutableState<String?>> { mutableStateOf(null) }
        val subCategoryId = remember<MutableState<String?>> { mutableStateOf(null) }
        val costPrice = remember { mutableStateOf(0.0) }
        val mrpPrice = remember { mutableStateOf(0.0) }
        val discountPrice = remember { mutableStateOf(0.0) }
        val salesPrice = remember { mutableStateOf(0.0) }
        val finalPrice = remember { mutableStateOf(0.0) }
        val cgstTax = remember { mutableStateOf(0.0) }
        val sgstTax = remember { mutableStateOf(0.0) }
        val igstTax = remember { mutableStateOf(0.0) }
        val description = remember { mutableStateOf("") }
        fun onClickSave() {
            invViewModel.createInventory(
                name = name.value,
                description = description.value,
                productId = product?.Id,
                categoryId = categoryId.value,
                subCategoryId = subCategoryId.value,
                model = model.value,
                barcode = barcode.value,
                brandId = brandId.value,
                tagId = tagId.value,
                uomId = uomId.value,
                colorId = colorId.value,
                qty = qty.value,
                sizeId = sizeId.value,
                discountId = discountId.value,
                coupanId = coupanId.value,
                costPrice = costPrice.value,
                mrp = mrpPrice.value,
                discount = discountPrice.value,
                salePrice = salesPrice.value,
                finalPrice = finalPrice.value,
                cgst = cgstTax.value,
                sgst = sgstTax.value,
                igst = igstTax.value,
                currency = currency.value
            ) {
                if (it) {
                    AppNavigator.shared().pop()
                } else {
                    AlertHandler.shared().showAlert(
                        localization().product.productsAlertTitle,
                        localization().product.productsAlertMessage/*"Oops!", "Something went wrong"*/
                    )
                }
            }
        }
        Column(
            modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(title = localization().stock.stockCreate, navigationParam, onBackPress = {
                if (currentStep.value == 0) {
                    navigator?.pop()
                } else {
                    viewModel.setProductFormIndex(currentStep.value - 1)
                }
            })
            AppLoader(viewModel)
            LazyColumn(
                modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.84f)
                    .padding(start = 8.dp, end = 8.dp, bottom = 20.dp)
            ) {
                item {
                    ProductCard(product, invViewModel)
                }
                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        TextInputField(
                            title = "Inventory Name",
                            "Inventory Name",
                            value = name.value
                        ) {
                            name.value = it
                        }
                    }
                }
                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        TextInputField(
                            title = "Model No.",
                            "Model Number",
                            value = model.value
                        ) {
                            model.value = it
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        TextInputField(
                            title = "Barcode No.",
                            "Barcode Number",
                            value = barcode.value
                        ) {
                            barcode.value = it
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        SelectBrandRow(brandId.value) {
                            brandId.value = it.Id
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        SelectTagRow(tagId.value) {
                            tagId.value = it.Id
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        SelectUOMRow(uomId.value) {
                            uomId.value = it.Id
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        QuantityInputField(
                            title = "Quantity",
                            "Enter Quantity",
                            value = qty.value
                        ) {
                            qty.value = it
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        SelectProductColorRow(colorId.value) {
                            colorId.value = it.Id
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        SelectSizeRow(sizeId.value) {
                            sizeId.value = it.Id
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        SelectDiscountRow(discountId.value) {
                            discountId.value = it.Id
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        SelectCoupanRow(coupanId.value) {
                            coupanId.value = it.Id
                        }
                    }
                }

                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        Row(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                            Box(
                                modifier = Modifier.fillMaxWidth().height(90.dp).weight(0.5f)
                            ) {
                                PriceInputField(
                                    title = "Cost Price",
                                    "Cost Price",
                                    currency.value,
                                    costPrice.value
                                ) {
                                    costPrice.value = it
                                }
                            }
                            Box(
                                modifier = Modifier.fillMaxWidth().height(90.dp).weight(0.5f)
                            ) {
                                PriceInputField(
                                    title = "MRP",
                                    "Enter MRP",
                                    currency.value,
                                    mrpPrice.value
                                ) {
                                    mrpPrice.value = it
                                }
                            }
                        }
                        Row(modifier = Modifier.fillMaxWidth().height(90.dp)) {
                            Box(
                                modifier = Modifier.fillMaxWidth().wrapContentHeight().weight(0.5f)
                            ) {
                                PriceInputField(
                                    title = "Discount",
                                    "Discount",
                                    currency.value,
                                    discountPrice.value
                                ) {
                                    discountPrice.value = it
                                }
                            }
                            Box(
                                modifier = Modifier.fillMaxWidth().wrapContentHeight().weight(0.5f)
                            ) {
                                PriceInputField(
                                    title = "Sale Price",
                                    "Sale Price",
                                    currency.value,
                                    salesPrice.value
                                ) {
                                    salesPrice.value = it
                                }
                            }
                        }
                        PriceInputField(
                            title = "Final Price", "Final Price", currency.value, finalPrice
                                .value
                        ) {
                            finalPrice.value = it
                        }


                    }
                }
                item {
                    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                        TaxInputField(title = "CGST", "Enter CGST", cgstTax.value) {
                            cgstTax.value = it
                        }
                        TaxInputField(title = "SGST", "Enter SGST", sgstTax.value) {
                            sgstTax.value = it
                        }
                        TaxInputField(title = "IGST", "Enter IGST", igstTax.value) {
                            igstTax.value = it
                        }
                        SelectCategoryRow(categoryId.value) {
                            categoryId.value = it.Id
                        }
                        if (categoryId.value?.isNotEmpty() == true) {
                            SelectSubCategoryRow(subCategoryId.value, categoryId.value) {
                                subCategoryId.value = it.Id
                            }
                        }
                        Box(modifier = Modifier.fillMaxWidth().height(200.dp)) {
                            ImageView("", true)
                        }
                        TextInputField(
                            title = "Description",
                            "Enter Description",
                            description.value
                        ) {
                            description.value = it
                        }
                    }
                }
            }

            Row(modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.16f)) {
                SecondaryButton("Cancel", {
                    navigator?.pop()
                })
                DefaultButton(localization().button.saveButton/*"Next"*/, onClick = {
                    onClickSave()
                })
            }
        }
    }
}