package presentation.screens.business.inventory.product.detail.component

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import appConfig.AppConfig
import feature.inventory.model.Inventory
import feature.inventory.model.product_color.ProductColor
import feature.inventory.model.size.Size
import feature.inventory.model.uom.UOM
import feature.inventory.viewModel.product_color.ProductColorViewModel
import feature.inventory.viewModel.size.SizeViewModel
import feature.inventory.viewModel.uom.UOMViewModel
import theme.ThemeColor
import theme.color
import theme.toColorLong

@Composable
fun ColorCard(cID: String?,selectedColorId: String?,filteredInventory:List<Inventory> ,onClick: (String?) -> Unit) {
    var color: ProductColor? by remember { mutableStateOf( null) }

    fun isColorPresentInInventory(fInv: List<Inventory>, cId: String?): Boolean {
        var isEnabledForClick = false
        fInv.forEach {item->
            if(!item.ColorID.isNullOrEmpty() && !cId.isNullOrEmpty() && item.ColorID == cID){
                isEnabledForClick = true
            }
        }
        return isEnabledForClick
    }
    val isEnabled = isColorPresentInInventory(filteredInventory, cID)

    LaunchedEffect(key1 = Unit) {
        ProductColorViewModel().getColorById(cID) { colorFromDb -> color = colorFromDb }
    }
    if(color != null && !color!!.Hex.isNullOrEmpty()){
        Row (modifier = Modifier.width(80.dp).height(60.dp).padding(4.dp), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center){
            Row(
                modifier = Modifier
                    .height(if(isEnabled || filteredInventory.isEmpty()) 60.dp else 40.dp).width(if(isEnabled || filteredInventory.isEmpty()) 80.dp else 60.dp)
                    .background(color!!.Hex!!.color(),
                        RoundedCornerShape(12.dp)
                    ).clip(RoundedCornerShape(12.dp))
//                    .border(2.dp,color=
//                    if(cID == selectedColorId) ThemeColor.shared().PrimaryColor.copy(alpha = 0.5f)
//                     else ThemeColor.shared().PrimaryColor
//                        ,shape = RoundedCornerShape(8.dp))
                    .zIndex(1f)
                    .clickable(enabled = isEnabled || filteredInventory.isEmpty()) {
                        onClick(cID)
                    },
            ) {

            }
        }
    }

}