package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.couponTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class CouponTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    CouponID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Code: String?,
    Type: Long?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getLong(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11)
    )
  }

  public fun retrieve(BusinessID: String?): Query<CouponTable> = retrieve(BusinessID) { _id,
      CouponID, UserID, BusinessID_, IsDeleted, Name, Code, Type, Value, Description, CreatedAt,
      UpdatedAt ->
    CouponTable(
      _id,
      CouponID,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      Code,
      Type,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveAll(mapper: (
    _id: String,
    CouponID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Code: String?,
    Type: Long?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = Query(-1_498_007_487, arrayOf("CouponTable"), driver, "CouponTable.sq",
      "retrieveAll",
      "SELECT CouponTable._id, CouponTable.CouponID, CouponTable.UserID, CouponTable.BusinessID, CouponTable.IsDeleted, CouponTable.Name, CouponTable.Code, CouponTable.Type, CouponTable.Value, CouponTable.Description, CouponTable.CreatedAt, CouponTable.UpdatedAt FROM CouponTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getLong(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11)
    )
  }

  public fun retrieveAll(): Query<CouponTable> = retrieveAll { _id, CouponID, UserID, BusinessID,
      IsDeleted, Name, Code, Type, Value, Description, CreatedAt, UpdatedAt ->
    CouponTable(
      _id,
      CouponID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Code,
      Type,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    CouponID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Code: String?,
    Type: Long?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getLong(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11)
    )
  }

  public fun find(_id: String): Query<CouponTable> = find(_id) { _id_, CouponID, UserID, BusinessID,
      IsDeleted, Name, Code, Type, Value, Description, CreatedAt, UpdatedAt ->
    CouponTable(
      _id_,
      CouponID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Code,
      Type,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    CouponID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Code: String?,
    Type: Long?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(-1_317_784_387, """
        |INSERT OR REPLACE INTO CouponTable( _id ,CouponID,UserID, BusinessID,IsDeleted,Name,Code,Type,Value,Description,CreatedAt,UpdatedAt)
        |    VALUES(?, ?,?, ? ,?, ?, ?,?,?,?,?,?)
        """.trimMargin(), 12) {
          bindString(0, _id)
          bindLong(1, CouponID)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindBoolean(4, IsDeleted)
          bindString(5, Name)
          bindString(6, Code)
          bindLong(7, Type)
          bindLong(8, Value)
          bindString(9, Description)
          bindString(10, CreatedAt)
          bindString(11, UpdatedAt)
        }.await()
    notifyQueries(-1_317_784_387) { emit ->
      emit("CouponTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CouponTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CouponTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CouponTable._id, CouponTable.CouponID, CouponTable.UserID, CouponTable.BusinessID, CouponTable.IsDeleted, CouponTable.Name, CouponTable.Code, CouponTable.Type, CouponTable.Value, CouponTable.Description, CouponTable.CreatedAt, CouponTable.UpdatedAt FROM CouponTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "CouponTable.sq:retrieve"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CouponTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CouponTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-676_325_187,
        """SELECT CouponTable._id, CouponTable.CouponID, CouponTable.UserID, CouponTable.BusinessID, CouponTable.IsDeleted, CouponTable.Name, CouponTable.Code, CouponTable.Type, CouponTable.Value, CouponTable.Description, CouponTable.CreatedAt, CouponTable.UpdatedAt FROM CouponTable WHERE _id = ?""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "CouponTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CouponTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CouponTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM CouponTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "CouponTable.sq:lastSyncDate"
  }
}
