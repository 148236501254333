package feature.inventory.repository

import feature.inventory.model.Inventory
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class InventoryRepository {
    val searchQueryState: MutableStateFlow<String> = MutableStateFlow("")
    val searchQuery: StateFlow<String>
        get() = searchQueryState

    val allInventoryState: MutableStateFlow<List<Inventory>> = MutableStateFlow(arrayListOf())
    val allInventory: StateFlow<List<Inventory>>
        get() = allInventoryState

    val lowestPriceProductState: MutableStateFlow<Inventory?> = MutableStateFlow(Inventory())
    val lowestPriceProduct: StateFlow<Inventory?>
        get() = lowestPriceProductState

    val highestPriceProductState: MutableStateFlow<Inventory?> = MutableStateFlow(Inventory())
    val highestPriceProduct: StateFlow<Inventory?>
        get() = highestPriceProductState

}