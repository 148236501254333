package presentation.screens.business.inventory.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.inventory.model.brand.Brand
import feature.inventory.viewModel.brand.BrandViewModel
import theme.ThemeColor

@Composable
fun SelectBrandRow(selected: String?, onChange: (Brand) -> Unit = {}) {
    val viewModel = BrandViewModel()
    val allBrand = viewModel.allBrand.collectAsState()
    LaunchedEffect(key1 = Unit) {
        viewModel.loadAllColor()
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("Brand")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allBrand.value.count()) {
                HorizontalBrandCard(
                    allBrand.value[it],
                    isSelected = selected == allBrand.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}