package feature.inventory.handler

import feature.inventory.repository.UOMRepository
import feature.inventory.viewModel.uom.UOMViewModel


class UOMHandler {

    var viewModel: UOMViewModel? = null
    val repository = UOMRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: UOMHandler? = null
        fun shared(): UOMHandler {
            if (instance != null) {
                return instance as UOMHandler
            } else {
                return UOMHandler()
            }
        }
    }

    fun setup(model: UOMViewModel) {
        viewModel = model
    }
}