package feature.businessConfig.viewModel

import appConfig.AppConfig
import feature.alert.handler.AlertHandler
import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.businessConfig.handler.BusinessConfigHandler
import feature.businessConfig.model.BusinessConfig
import feature.businessConfig.model.Currency
import feature.businessConfig.network.BusinessConfigNetwork
import feature.businessConfig.network.CreateBusinessConfigRequest
import feature.businessConfig.network.FetchAllCurrencyRequest
import feature.businessConfig.network.FetchBusinessConfigRequest
import feature.businessConfig.network.UpdateBusinessConfigRequest
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json

class BusinessConfigViewModel : AppViewModel() {

    val json = Json {
        ignoreUnknownKeys = true
    }

    val repository = BusinessConfigHandler.shared().repository
    val allBusinessConfig: StateFlow<ArrayList<BusinessConfig>>
        get() = repository.allBusinessConfig

    val allCurrency: StateFlow<ArrayList<Currency>>
        get() = repository.allCurrency

    val selectedCurrency: StateFlow<Currency>
        get() = repository.selectedCurrency

    val businessConfig: StateFlow<BusinessConfig?>
        get() = repository.businessConfig
    val editBusinessConfig: StateFlow<BusinessConfig?>
        get() = repository.editBusinessConfig
    val searchQuery: StateFlow<String>
        get() = repository.searchQuery

    fun updateCurrency(value: Currency?) {
        scope.launch {
            repository.updateCurrency(value)
            delay(500)
            updateBusinessConfig {
                if (it) {

                }
            }
        }
    }


    override fun changeSearchQuery(value: String) {
        scope.launch {
            repository.searchQueryState.emit(value)
        }
        performSearch(value)
    }

    override fun performSearch(value: String) {
        val searchQueryText = value.lowercase()
        scope.launch {
//            val filteredResult = repository.allUnFilteredBusinessConfig.value.filter { item ->
//                item.currency?.lowercase()
//                    ?.contains(searchQueryText) == true || item.MobileNumber?.lowercase()
//                    ?.contains(searchQueryText) == true || item.EmailID?.lowercase()?.contains(
//                    searchQueryText
//                ) == true || item.barcode?.contains(value) == true
//            } as ArrayList<BusinessConfig>
//            repository.allBusinessConfigState.emit(filteredResult)
        }
    }

    fun editBusinessConfig(value: BusinessConfig?) {
        repository.setEditBusinessConfig(value)
    }

//    fun editBusinessConfigName(value: String) {
//        repository.editBusinessConfig.value?.Name = value
//    }
//
//    fun editBusinessConfigMobile(value: String) {
//        repository.editBusinessConfig.value?.MobileNumber = value
//    }
//
//    fun editBusinessConfigDialCode(value: String) {
//        repository.editBusinessConfig.value?.DialCode = value
//    }
//
//    fun editBusinessConfigEmail(value: String) {
//        repository.editBusinessConfig.value?.EmailID = value
//    }
//
//    fun editBusinessConfigBarcode(value: String) {
//        repository.editBusinessConfig.value?.barcode = value
//    }


    fun fetchBusinessConfig() {
        startLoading()
        val request = FetchBusinessConfigRequest(
            BusinessHandler.shared().businessId,
            AuthHandler.shared().repository.authUser.value.id,
            null//repository.lastSyncDate.value
        )
        scope.launch {
            BusinessConfigNetwork().retrieveBusinessConfig(request) {
                stopLoading()
                if (it?.payload?.id?.isNotEmpty() == true) {
                    scope.launch {
                        repository.businessConfigState.emit(it.payload)
                        editBusinessConfig(it.payload)
                    }
                    fetchAllCurrency()
                } else {
//                    AlertHandler.shared().showAlert("no bysiness config", "")
                }
            }
        }
    }

    fun fetchAllCurrency() {
        startLoading()
        val request = FetchAllCurrencyRequest(
            BusinessHandler.shared().repository.business.value.Id,
            AuthHandler.shared().repository.authUser.value.id,
            null
        )
        scope.launch {
            BusinessConfigNetwork().retrieveAllCurrency(request) {
                stopLoading()
                if (it?.payload?.isNotEmpty() == true) {
                    scope.launch {
                        repository.allCurrencyState.emit(it.payload)
                        it.payload.forEach { currency ->
                            if (!businessConfig.value?.currencySymbol.isNullOrEmpty()) {
                                if (currency.symbol == businessConfig.value!!.currencySymbol) {
                                    repository.selectedCurrencyState.emit(currency)
                                }
                            } else {
                                if (currency.code == AppConfig.shared().countryCode) {
                                    repository.selectedCurrencyState.emit(currency)
                                }
                            }

                        }
                    }
                } else {
//                    AlertHandler.shared().showAlert("hi", "it ${it}")
                }
            }
        }
    }

    fun createNewBusinessConfig(
        name: String,
        dialCode: String,
        mobile: String,
        email: String,
        barcode: String,
        callBack: (Boolean) -> Unit
    ) {
        val request = CreateBusinessConfigRequest(
            AuthHandler.shared().repository.authUser.value.id,
            BusinessHandler.shared().repository.business.value.Id,
            name,
            dialCode,
            mobile,
            email,
            barcode,
            AuthHandler.shared().deviceId
        )
        startLoading()
        scope.launch {
//            BusinessConfigNetwork().createBusinessConfig(request) {
//                stopLoading()
//                if (!it?.payload?.Id.isNullOrEmpty()) {
//                    callBack(true)
//                    scope.launch {
//                        BusinessConfigHandler.shared().repository.businessConfigState.emit(it!!.payload!!)
//                    }
//                } else {
//                    callBack(false)
//                }
//            }
        }
    }

    fun updateBusinessConfig(
        callBack: (Boolean) -> Unit
    ) {
        if (repository.editBusinessConfig.value?.id?.isNotEmpty() == true) {
            val request =
                UpdateBusinessConfigRequest(
                    repository.editBusinessConfig.value!!.id,
                    AuthHandler.shared().repository.authUser.value.id,
                    BusinessHandler.shared().repository.business.value.Id,
                    currency = repository.editBusinessConfig.value?.currency,
                    symbol = repository.editBusinessConfig.value?.currencySymbol,
                )
            startLoading()
            scope.launch {
                BusinessConfigNetwork().updateBusinessConfig(request) { response ->
                    stopLoading()
                    if (!response?.payload?.id.isNullOrEmpty()) {
                        callBack(true)
                        scope.launch {
                            BusinessConfigHandler.shared().repository.businessConfigState.emit(
                                response!!.payload!!
                            )
                        }
                        AlertHandler.shared().showAlert("Success", "Updated successfully")
                    } else {
                        callBack(false)
//                        AlertHandler.shared()
//                            .showAlert(
//                                "Oops!",
//                                "Something went wrong, please try after some time"
//                            )
                    }
                }
            }
        } else {
            AlertHandler.shared().showAlert("Oops!", "No Business Config")
        }

    }
}