package common.constants

import androidx.compose.ui.graphics.Color
import feature.businessMenu.modal.BusinessMenu
import feature.businessMenu.modal.MenuType
import friendly.shared.generated.resources.Res
import friendly.shared.generated.resources.business_menu_icon
import friendly.shared.generated.resources.catalogue_menu_icon
import friendly.shared.generated.resources.dashboard_menu_icon
import friendly.shared.generated.resources.invoice_menu_icon
import friendly.shared.generated.resources.product_menu_icon
import friendly.shared.generated.resources.sale_menu_icon
import friendly.shared.generated.resources.shop_menu_icon
import locale.localization
import navigation.containers.ScreenName

class StoreMenuConfig {
    fun getMenu(): List<BusinessMenu> {
        val firstMenu: ArrayList<BusinessMenu> = arrayListOf()
        firstMenu.add(
            BusinessMenu(
                "my_business_currency",
                MenuType.MENU,
                localization().storeMenu.currencyInr,
                Res.drawable.invoice_menu_icon,
                ScreenName.MY_BUSINESS_CURRENCY,
                Color(0xFFE3F5E2)
            )
        )
        firstMenu.add(
            BusinessMenu(
                "my_business_back_account",
                MenuType.MENU,
                localization().storeMenu.account,
                Res.drawable.sale_menu_icon,
                ScreenName.MY_BUSINESS_BANK_ACCOUNT,
                Color(0xFFF5F5D5)
            )
        )

        val secondMenu: ArrayList<BusinessMenu> = arrayListOf()
        secondMenu.add(
            BusinessMenu(
                "my_business_banner",
                MenuType.MENU,
                localization().storeMenu.banner,
                Res.drawable.product_menu_icon,
                ScreenName.MY_BUSINESS_BANNER,
                Color(0xFFDEF4F8)
            )
        )
        secondMenu.add(
            BusinessMenu(
                "advertise_to_customer",
                MenuType.MENU,
                localization().storeMenu.notifyCustomer,
                Res.drawable.catalogue_menu_icon,
                ScreenName.ADVERTISE_TO_CUSTOMER,
                Color(0xFFE7E7E7)
            )
        )
        val thirdMenu: ArrayList<BusinessMenu> = arrayListOf()

        thirdMenu.add(
            BusinessMenu(
                "my_business_app",
                MenuType.MENU,
                localization().storeMenu.app,
                Res.drawable.business_menu_icon,
                ScreenName.MY_BUSINESS_APP,
                Color(0xFFBCDBFF)
            )
        )

        thirdMenu.add(
            BusinessMenu(
                "my_business_app_dashboard",
                MenuType.MENU,
                localization().storeMenu.dashboard,
                Res.drawable.dashboard_menu_icon,
                ScreenName.MY_BUSINESS_APP_DASHBOARD,
                Color(0xFFE2F1E9)
            )
        )


        val allMenuItems = listOf(
            BusinessMenu(
                "my_business_currency",
                MenuType.HEADER,
                localization().storeMenu.finance,
                Res.drawable.sale_menu_icon,
                ScreenName.MY_BUSINESS_FINANCE,
                Color(0xFFF5F5D5)
            ),
            BusinessMenu(
                "my_business_finance",
                MenuType.MENU,
                "",
                Res.drawable.shop_menu_icon,
                ScreenName.BUSINESS_SALE,
                Color(0xFFffffff),
                firstMenu
            ),
            BusinessMenu(
                "my_business_marketing",
                MenuType.HEADER,
                localization().storeMenu.marketing,
                Res.drawable.shop_menu_icon,
                ScreenName.MY_BUSINESS_MARKETING,
                Color(0xFFDEF4F8)
            ),
        )
        return allMenuItems
    }
}































