package presentation.screens.customer.store.component

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.product.ProductCategoryCard
import feature.productCategory.handler.ProductCategoryHandler
import feature.productCategory.model.ProductCategory

@Composable
fun StoreCategory(categories: State<List<ProductCategory>>) {
    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 6.dp, top = 15.dp, end = 6.dp)
        ) {
            Text(
                "Categories",
                fontWeight = FontWeight.Bold,
                fontSize = 20.sp,
                modifier = Modifier
                    .weight(0.5f)
            )
            Text(
                "View All",
                fontSize = 14.sp,
                color = Color.Blue
            )
        }
        LazyRow(modifier = Modifier.padding(start = 8.dp, end = 8.dp)) {
            items(count = categories.value.count()) { index ->
                ProductCategoryCard(category = categories.value[index], onClick = {
                    ProductCategoryHandler.shared().repository.selectedCategoryForNavigation =
                        it
//                                viewModel.navigateTo(ScreenName.PRO)
                })
            }
        }
    }
}