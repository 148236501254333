package presentation.screens.business.store.currency.component

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.RadioButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import feature.businessConfig.model.Currency
import theme.ThemeColor

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun SelectCurrencyCard(
    currency: Currency?,
    selectedCurrency: Currency?,
    onClick: (category: Currency?) -> Unit
) {
    val isSelected = selectedCurrency?.code == currency?.code
    ElevatedCard(
        modifier = Modifier.height(60.dp).fillMaxWidth().padding(8.dp),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black
        ),
        onClick = { onClick(currency) }
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = 15.dp, end = 15.dp),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            RadioButton(isSelected, { onClick(currency) })
            Text(
                modifier = Modifier.width(30.dp).padding(end = 8.dp),
                color = ThemeColor.shared().PrimaryColor,
                text = currency?.symbol ?: "", fontSize = 14.sp, textAlign = TextAlign.Start
            )
            Text(
                modifier = Modifier.width(70.dp).padding(end = 8.dp),
                text = currency?.code ?: "", fontSize = 14.sp, textAlign = TextAlign.Start
            )
            Text(
                modifier = Modifier.weight(1f).padding(end = 8.dp),
                text = currency?.name ?: "", fontSize = 14.sp, textAlign = TextAlign.Start
            )
        }
    }
}