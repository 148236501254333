package feature.invocie.repository

import feature.invoice.model.CustomerInvoice
import feature.sale.model.Sale
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class InvoiceRepository {
    val allCustomerInvoiceLiveData: MutableStateFlow<List<CustomerInvoice>> = MutableStateFlow(
        arrayListOf()
    )
    val allCustomerInvoice: StateFlow<List<CustomerInvoice>>
        get() = allCustomerInvoiceLiveData

    val unFilteredCustomerInvoiceLiveData: MutableStateFlow<List<CustomerInvoice>> =
        MutableStateFlow(
            arrayListOf()
        )
    val unFilteredCustomerInvoice: StateFlow<List<CustomerInvoice>>
        get() = unFilteredCustomerInvoiceLiveData

    val customerInvoiceLiveData: MutableStateFlow<CustomerInvoice?> =
        MutableStateFlow(null)
    val customerInvoice: StateFlow<CustomerInvoice?>
        get() = customerInvoiceLiveData

    val allSalesLiveData: MutableStateFlow<List<Sale>> = MutableStateFlow(arrayListOf())
    val allSales: StateFlow<List<Sale>>
        get() = allSalesLiveData

    val searchQueryState: MutableStateFlow<String> = MutableStateFlow("")
    val searchQuery: StateFlow<String>
        get() = searchQueryState

}