package presentation.screens.common.account.delete

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import com.friendly.shared.component.ErrorSuggestion
import common.asset.ImageAsset
import component.country.DialCodeHelper
import component.input.MobileTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.auth.handler.AuthHandler
import feature.auth.viewModel.AuthViewModel
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import theme.ThemeColor

class DeleteAccountScreen : Screen {
    @Composable
    override fun Content() {
        val viewModel = AuthViewModel()
        val navigator = LocalNavigator.current

        var mobile by remember { mutableStateOf(TextFieldValue("")) }
        var dialCode by remember { mutableStateOf(DialCodeHelper().getSelectedCountryCode()) }
        viewModel.goToHome = {
            navigator?.popAll()
        }
        viewModel.goBack = {
            navigator?.pop()
        }
        viewModel.goToRegister = {
            AuthHandler.shared().dialCode = dialCode.countryPhoneCode
            AppNavigator.shared().navigateTo(ScreenName.SIGN_UP)
        }
        viewModel.goToSetMPin = {
            AuthHandler.shared().dialCode = dialCode.countryPhoneCode
            AppNavigator.shared().navigateTo(ScreenName.SET_M_PIN)
        }
        viewModel.goToLoginWithMPin = {
            AuthHandler.shared().dialCode = dialCode.countryPhoneCode
            AppNavigator.shared().navigateTo(
                ScreenName.VERIFY_M_PIN
            )
        }
        viewModel.goToOtp = {
            AuthHandler.shared().dialCode = dialCode.countryPhoneCode
            AppNavigator.shared().navigateTo(ScreenName.OTP)
        }

        var submitButtonTitle by remember { mutableStateOf("") }
        var isValidMobile by remember { mutableStateOf(false) }

        var checkBox by remember {
            mutableStateOf(false)
        }
        val mobileErrorState = remember {
            mutableStateOf(false)
        }

        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(0.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            TopNavBar(
                localization().accountAuthentication.signIn, null,
                onBackPress = { navigator?.pop() })
            AppLoader(viewModel)
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Spacer(modifier = Modifier.height(20.dp))
                Text(
                    text = "Delete Account",
                    fontSize = 26.sp,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(start = 30.dp, end = 30.dp)
                )
                Text(
                    text = "Please verify that you own the account you are trying to delete",
                    color = ThemeColor.shared().TextColor,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(start = 30.dp, end = 30.dp, top = 12.dp)
                )
                Spacer(modifier = Modifier.height(50.dp))
                MobileTextField(
                    placeholder = "9000000000",
                    trailingIcon = ImageAsset.phone,
                    label = localization().accountAuthentication.mobileNumber,
                    errorState = mobileErrorState,
                    visualTransformation = VisualTransformation.None,
                    onChanged = { mob ->
                        mobile = mob
                        isValidMobile = (mob.text.length in 9..11)
                    },
                    onDialCodeChange = {
                        dialCode = it
                    }
                )

                Spacer(modifier = Modifier.height(10.dp))
                if (mobileErrorState.value) {
                    ErrorSuggestion("Please enter valid mobile number")
                }
                CustomDefaultBtn(
                    shapeSize = 50f,
                    btnText = "Send OTP"
                ) {
                    mobileErrorState.value = !isValidMobile
                    if (isValidMobile) {
                        viewModel.checkAccount(mobile.text, dialCode.countryPhoneCode)
                    }

                }
            }
        }
    }
}