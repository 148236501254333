package feature.productCategory.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveProductCategoryRequest(
    @SerialName("BusinessID") val businessID: String,
)

@Serializable
data class CreateProductCategoryRequest(
    @SerialName("Name") val name: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("UserID") val userId: String,
)

@Serializable
data class UpdateProductCategoryRequest(
    @SerialName("Name") val name: String,
    @SerialName("_id") val id: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("UserID") val userId: String,
)
