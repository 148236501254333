package component.product

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.Image
import feature.productCategory.model.ProductCategory
import theme.ThemeColor

@Composable
fun ProductCategoryCard(
    category: ProductCategory,
    onClick: (category: ProductCategory) -> Unit
) {
    ElevatedCard(
        modifier = Modifier.width(120.dp)
            .height(110.dp).padding(4.dp),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black
        ),
        onClick = { onClick(category) }
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
        ) {
            Box(
                modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(1f)
                    .background(
                        ThemeColor.shared().PrimaryColor,
                        shape = RoundedCornerShape(8.dp)
                    )
            ) {
                Image().content(category.Id)
            }
            Text(
                text = category.Name!!,
                fontSize = 14.sp,
                textAlign = TextAlign.Center,
                modifier = Modifier.fillMaxWidth().wrapContentHeight()
            )
        }
    }
}