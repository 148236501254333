package presentation.screens.business.sale.cart

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import appConfig.AppConfig
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.button.SecondaryButton
import component.cart.CartPaymentDetails
import component.empty.EmptyData
import component.loader.AppLoader
import component.navigation.TopNavBar
import component.product.CartItemCard
import feature.cart.viewModel.CartViewModel
import feature.customer.viewModel.CustomerViewModel
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.customer.component.CustomerCard
import presentation.screens.business.customer.select.SelectCustomerScreen
import theme.ThemeColor

class MyBusinessCart(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val viewModel = CartViewModel()
        val customerViewModel = CustomerViewModel()
        val selectedCustomer = customerViewModel.selectedCustomer.collectAsState()
        val navigator = LocalNavigator.current
        val cartItems = viewModel.cartItems.collectAsState()
        val totalAmount = viewModel.totalAmount.collectAsState()
        fun onClickProceed() {
            if (totalAmount.value > 0F) {
                viewModel.createInvoice {
                    viewModel.resetCart()
                    customerViewModel.setSelectedCustomer(null)
                    AppNavigator.shared().pop()
                    if (it) {
                        AppNavigator.shared().navigateTo(
                            ScreenName.INVOICES
                        )
                    }
                }
            }
        }
        LaunchedEffect(key1 = Unit) {
            viewModel.loadCart(navigationParam)
        }
        Column(
            modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(title = "Cart", navigationParam, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            if (cartItems.value.isEmpty()) {
                EmptyData(viewModel = viewModel)
                DefaultButton(
                    "Add Product",
                    onClick = { navigator?.pop() })
            } else {
                Box(
                    modifier = Modifier
                        .height(50.dp)
                        .padding(start = 8.dp, end = 8.dp)
                        .background(
                            ThemeColor.shared().cardBackground,
                            shape = RoundedCornerShape(10.dp)
                        ),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "${localization().product.productsTotal} ${cartItems.value.count()} ${localization().product.productsProduct}",
                        maxLines = 2,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Center,
                        color = ThemeColor.shared().PrimaryColor
                    )
                }
            }
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 400.dp),
                modifier = Modifier.weight(1f).padding(
                    start = 8.dp,
                    end = 8.dp,
                    bottom = if (cartItems.value.isNotEmpty()) 60.dp else (60.dp)
                ),
            ) {
                items(count = cartItems.value.count()) {
                    CartItemCard(cartItems.value[it], viewModel, navigationParam)
                }
            }
            Spacer(modifier = Modifier.height(20.dp))
            CartPaymentDetails(viewModel = viewModel)
            Spacer(modifier = Modifier.height(20.dp))
            if (selectedCustomer.value != null) {
                Box(modifier = Modifier.fillMaxWidth().height(136.dp).padding(bottom = 8.dp)) {
                    CustomerCard(customer = selectedCustomer.value!!) {
                        navigator?.push(SelectCustomerScreen())
                    }
                }
            } else {
                Box(modifier = Modifier.fillMaxWidth().height(50.dp).padding(bottom = 8.dp)) {
                    SecondaryButton(
                        title = "Select Customer",
                        onClick = {
                            navigator?.push(SelectCustomerScreen())
                        },
                        modifier = Modifier.height(50.dp)
                            .fillMaxWidth()
                            .padding(start = 8.dp, end = 8.dp)
                            .background(
                                color = ThemeColor.shared().cardBackground,
                                shape = RoundedCornerShape(8.dp)
                            )
                            .border(
                                1.dp,
                                ThemeColor.shared().PrimaryColor,
                                RoundedCornerShape(8.dp)
                            )
                    )
                }
            }
            DefaultButton(
                title = "Proceed ${AppConfig.shared().currencySymbol} ${totalAmount.value}",
                onClick = {
                    onClickProceed()
                },
                modifier = null
            )
        }
    }
}