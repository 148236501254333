package feature.inventory.network.size

import common.constants.Server
import feature.inventory.event.SizeEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient


class SizeNetwork {
    val json = Json {
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: SizeNetwork? = null
        fun shared(): SizeNetwork {
            if (instance != null) {
                return instance as SizeNetwork
            } else {
                return SizeNetwork()
            }
        }
    }

    suspend fun retrieve(
        request: RetrieveSizeRequest,
        onResult: (RetrieveSizeResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(SizeEvent.RETRIEVE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }
}