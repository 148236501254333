package presentation.screens.business.store

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.layout.VerticalGrid
import common.constants.StoreMenuConfig
import component.navigation.TopNavBar
import feature.businessConfig.viewModel.BusinessConfigViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.home.component.headerCard
import presentation.screens.business.store.component.StoreMenuCard

class MyBusinessStoreScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = BusinessConfigViewModel()
        val businessConfig = viewModel.businessConfig.collectAsState()
        val allMenu = StoreMenuConfig().getMenu()
        LaunchedEffect(key1 = Unit) {
            viewModel.fetchBusinessConfig()
            if (businessConfig.value?.id?.isEmpty() == true) {
                viewModel.fetchBusinessConfig()
            } else {
                viewModel.editBusinessConfig(businessConfig.value)
            }
        }
        Column {
            TopNavBar(
                localization().storeMenu.storeTitle, navigationParam, onBackPress = {
                    navigator?.pop()
                }
            )
            LazyColumn(
                modifier = Modifier
                    .fillMaxSize().padding(bottom = 60.dp)
            ) {
                item {
                    headerCard(menu = allMenu[0])
                    VerticalGrid(
                        columns = 2
                    ) {
                        allMenu[0].sub_menu.forEach { menu ->
                            StoreMenuCard(menu, businessConfig.value) { screen ->
                                navigateTo(screen)
                            }
                        }
                    }
                    headerCard(menu = allMenu[1])
                    VerticalGrid(
                        columns = 2
                    ) {
                        allMenu[1].sub_menu.forEach { menu ->
                            StoreMenuCard(menu, businessConfig.value) { screen ->
                                navigateTo(screen)
                            }
                        }
                    }
                    headerCard(menu = allMenu[2])
                    VerticalGrid(
                        columns = 2
                    ) {
                        allMenu[2].sub_menu.forEach { menu ->
                            StoreMenuCard(menu, businessConfig.value) { screen ->
                                navigateTo(screen)
                            }
                        }
                    }
                }
            }
        }

    }
}