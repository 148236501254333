package feature.businessConfig.repository


import feature.businessConfig.model.BusinessConfig
import feature.businessConfig.model.Currency
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class BusinessConfigRepository {
    val searchQueryState: MutableStateFlow<String> = MutableStateFlow("")
    val searchQuery: StateFlow<String>
        get() = searchQueryState

    val lastSyncDateState: MutableStateFlow<String?> = MutableStateFlow(null)
    val lastSyncDate: StateFlow<String?>
        get() = lastSyncDateState

    val allBusinessConfigState: MutableStateFlow<ArrayList<BusinessConfig>> =
        MutableStateFlow(arrayListOf())
    val allBusinessConfig: StateFlow<ArrayList<BusinessConfig>>
        get() = allBusinessConfigState

    val allCurrencyState: MutableStateFlow<ArrayList<Currency>> =
        MutableStateFlow(arrayListOf())
    val allCurrency: StateFlow<ArrayList<Currency>>
        get() = allCurrencyState

    val selectedCurrencyState: MutableStateFlow<Currency> =
        MutableStateFlow(Currency())
    val selectedCurrency: StateFlow<Currency>
        get() = selectedCurrencyState

    val allUnFilteredBusinessConfigState: MutableStateFlow<ArrayList<BusinessConfig>> =
        MutableStateFlow(arrayListOf())
    val allUnFilteredBusinessConfig: StateFlow<ArrayList<BusinessConfig>>
        get() = allUnFilteredBusinessConfigState

    val businessConfigState: MutableStateFlow<BusinessConfig?> = MutableStateFlow(null)
    val businessConfig: StateFlow<BusinessConfig?>
        get() = businessConfigState

    private val editBusinessConfigState: MutableStateFlow<BusinessConfig?> = MutableStateFlow(null)
    val editBusinessConfig: StateFlow<BusinessConfig?>
        get() = editBusinessConfigState

    fun updateCurrency(value: Currency?) {
        val existingConfig = businessConfig.value
        CoroutineScope(Job() + Dispatchers.Default).launch {
            existingConfig?.currency = value?.code
            existingConfig?.currencySymbol = value?.symbol
            if (value != null) {
                selectedCurrencyState.emit(value)
            }
            businessConfig.value
        }
    }

    fun selectBusinessConfig(cust: BusinessConfig) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            businessConfigState.emit(cust)
        }
    }

    fun setEditBusinessConfig(cust: BusinessConfig?) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            editBusinessConfigState.emit(cust)
        }
    }
}