package presentation.screens.business.inventory.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.inventory.model.uom.UOM
import feature.inventory.viewModel.uom.UOMViewModel
import theme.ThemeColor

@Composable
fun SelectUOMRow(selected: String?, onChange: (UOM) -> Unit = {}) {
    val viewModel = UOMViewModel()
    val allUOM = viewModel.allUOM.collectAsState()
    LaunchedEffect(key1 = Unit) {
        viewModel.loadAllUOM()
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("UOM")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allUOM.value.count()) {
                HorizontalUOMCard(
                    allUOM.value[it],
                    isSelected = selected == allUOM.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}