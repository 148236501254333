package feature.mediaFile.viewModel

import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.mediaFile.handler.MediaFileHandler
import feature.mediaFile.model.MediaFile
import feature.mediaFile.network.MediaFileNetwork
import feature.mediaFile.network.MediaFileRetrieveRequest
import feature.mediaFile.network.MediaFileUploadRequest
import feature.mediaFile.repository.MediaFileRepository
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import storage.database.table.media_file.MediaFileDatabaseTable

class MediaFileViewModel(private val repository: MediaFileRepository = MediaFileHandler.shared().repository) :
    AppViewModel() {

    private var job: Job = Job()

    val allData: StateFlow<List<MediaFile>>
        get() = repository.allLiveData

    val selectedData: StateFlow<MediaFile>
        get() = repository.selected

    val createMediaFile: StateFlow<MediaFile>
        get() = repository.createMediaFile

    val mediaFiles: StateFlow<List<MediaFile>>
        get() = repository.mediaFiles

    val mediaFileLiveData: MutableStateFlow<MediaFile> = MutableStateFlow(MediaFile())
    val mediaFile: StateFlow<MediaFile>
        get() = mediaFileLiveData

    fun loadFor(featureObjectID: String, completion: (ArrayList<MediaFile>) -> Unit) {
//        scope.launch {
//            val mediaFiles = DatabaseHandler.shared().database.mediaFileDao().itemFor(featureObjectID)
//            if(mediaFiles.isNotEmpty()){
//                completion(mediaFiles as ArrayList<MediaFile>)
//            }else{
//                retrieveFor(featureObjectID)
//            }
//        }
    }

    fun find(featureObjectID: String): String {
        var stringToReturn = ""
        allData.value.forEach { item ->
            if (item.FeatureObjectID == featureObjectID && !item.FileURL.isNullOrEmpty()) {
                stringToReturn = item.FileURL!!
            }
        }
        return stringToReturn
    }

    fun loadMedia(id: String, completion: (MediaFile?) -> Unit) {
        MediaFileDatabaseTable().retrieve(id) { data ->
            if (data != null) {
                completion(data)
            } else {
                retrieveFor(id, completion)
            }
        }
    }

    fun uploadImage(
        fileString: String,
        featureObjectID: String,
        completion: ((MediaFile?) -> Unit) = {}
    ) {
        val user = AuthHandler.shared().repository.authLiveData.value
        if (fileString.isNotBlank()) {
            val unixTime = Clock.System.now().epochSeconds
            val fileName = "${featureObjectID}_$unixTime.png"
            val business = BusinessHandler.shared().repository.business
            if (business.value.Id.isEmpty()) {
                return
            }
            startLoading()
            val request =
                MediaFileUploadRequest(
                    unixTime,
                    user.id,
                    business.value.Id,
                    featureObjectID,
                    fileName,
                    fileString
                )
            MediaFileNetwork().upload(request) {
                stopLoading()
                if (it?.payload?._id?.isNotEmpty() == true) {
                    MediaFileDatabaseTable().insert(it.payload) {
                        completion(it.payload)
                    }
                } else {
                    completion(null)
                }
            }
        }
    }

    private fun retrieveFor(id: String, completion: ((MediaFile?) -> Unit) = {}) {
        val request = MediaFileRetrieveRequest(null, id)
        MediaFileNetwork().retrieve(request) {
            MediaFileDatabaseTable().insert(it?.payload) {
                if (it?.payload?.isEmpty() == true) {
                    completion(null)
                } else {
                    completion(it?.payload?.first())
                }
            }
        }
    }

    fun retrieve(completion: (() -> Unit) = {}) {
        val business = BusinessHandler.shared().repository.business
        if (business.value.Id.isEmpty()) {
            return
        }
        val request = MediaFileRetrieveRequest(business.value.Id)
        MediaFileNetwork().retrieve(request) { response ->
            MediaFileDatabaseTable().insert(response?.payload) {
            }
        }
    }

    fun retrieveForUser(completion: (() -> Unit) = {}) {
        val business = BusinessHandler.shared().repository.business
        if (business.value.Id.isEmpty()) {
            return
        }
        val request = MediaFileRetrieveRequest(business.value.Id)
        MediaFileNetwork().retrieve(request) {
            if (!it?.payload.isNullOrEmpty()) {
                scope.launch {
                    repository.liveData.emit(it!!.payload)
                }
            }
        }
    }
//
//    fun createNew(request:JSONObject) {
//        val unixTime = System.currentTimeMillis()
//        request.put(KeyConstant.uniqueId,unixTime)
//        SocketService.shared().send(MediaFileEvent.CREATE, request)
//    }
//
//    fun insert(newData : MediaFile){
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.mediaFileDao().insert(newData)
//        }
//    }
}