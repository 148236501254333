package feature.product.model

import feature.businessConfig.handler.BusinessConfigHandler
import feature.businessConfig.model.Currency
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProductPrice(
    @SerialName("HSN") var hsn: String? = null,
    @SerialName("TaxIncluded") var taxIncluded: Boolean? = null,
    @SerialName("SGST") var SGST: Double? = null,
    @SerialName("CGST") var CGST: Double? = null,
    @SerialName("IGST") var IGST: Double? = null,
    @SerialName("CESS") var CESS: Double? = null,
    @SerialName("VAT") var VAT: Double? = null,
    @SerialName("OtherTax") var OtherTax: Double? = null,
    @SerialName("Discount") var Discount: Double? = null,
    @SerialName("MRP") var MRP: Double? = null,
    @SerialName("Price") var Price: Double? = null,
    @SerialName("CostPrice") var CostPrice: Double? = null,
    @SerialName("FinalPrice") var FinalPrice: Double? = null,
    @SerialName("Tax") var Tax: Double? = null,
    @SerialName("Currency") var currency: Currency? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
) {
    fun getCurrencySymbol(): String {
        if (!currency?.symbol.isNullOrEmpty()) {
            return currency!!.symbol
        } else {
            return BusinessConfigHandler.shared().currencySymbol
        }
    }
}

//