package presentation.screens.customer.product.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.ImageView
import theme.ThemeColor

@Composable
fun FeedbackCard() {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .height(110.dp)
            //.padding(4.dp)
            .clip(RoundedCornerShape(10.dp))
            .background(ThemeColor.shared().defaultBakground, shape = RoundedCornerShape(10.dp) ),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().defaultBakground,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().defaultBakground
        ),
    ){
        Row(modifier = Modifier
            .fillMaxWidth()) {
            Box(
                modifier = Modifier
                    .width(50.dp)
                    .height(50.dp)
                    .clip(RoundedCornerShape(20.dp))
            )
            {
                ImageView("h")
            }
            Column(modifier = Modifier
                .padding(top = 4.dp)){
                Text("Emelia sans", fontSize = 14.sp)
                Text("⭐ ⭐ ⭐ ⭐ ⭐")
            }
        }
        Text("Worth the money. Highly recommend. 10/10 for comfort and looks", fontSize = 14.sp, color = Color.Gray)
    }
}