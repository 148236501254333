package payment

import feature.alert.handler.AlertHandler
import feature.payment.viewModel.PaymentViewModel
import navigation.AppNavigator
import org.w3c.dom.Window

class PaymentHandler {
    val viewModel = PaymentViewModel()
    var window: Window? = null
    fun setup(window: Window) {
        this.window = window
        AppNavigator.shared().openRazorpay = { payment ->
//            AlertHandler.shared().showAlert("payment", "came")
            val razorpayOption = """
           {
                "key": "rzp_test_5kZh1ULTjdcmcU",
                "amount": "${payment.pendingAmount}",
                "currency": "${payment.currency}",
                "name": "Mandee",
                "description": "Test Transaction",
                "image": "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/logo%2Fmandee.jpg?alt=media&token=b3c38cc1-7c3b-4370-93bc-86c50335c47a",
                "order_id": "${payment.paymentGatewayOrderID ?: "INR"}",
                "prefill": {
                    "name": "Gaurav Kumar",
                    "email": "gaurav.kumar@example.com",
                    "contact": "9000090000"
                },
                "notes": {
                    "address": "Razorpay Corporate Office"
                },
                "theme": {
                    "color": "#3399cc"
                }
            }
        """.trimIndent()
            openRazorpayPaymentPage(options = razorpayOption)
        }
    }

    var onPayment: (Any) -> Any = {}

    private fun openRazorpayPaymentPage(options: String) {
        onPayment = { data ->
            onPaymentSuccess(data)
        }
        window.run {
            window.asDynamic().razorpayOption = options
            window.asDynamic().paymentHandler = onPayment
            js("window.openRazorPay();").execute()
            window.asDynamic().razorpayOption = options
        }
    }

    fun onPaymentSuccess(any: Any) {
        if (any is String) {
            viewModel.onRazorPaySuccess(any)
        } else if (any is JSON) {
            viewModel.onRazorPaySuccess(any.toString())
        } else {
            AlertHandler.shared().showAlert("Oops!", "${any}")
        }
    }
}