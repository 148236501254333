package presentation.screens.customer.store.component

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.Share
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.Image
import feature.business.model.Business
import feature.business.utill.AddressUtill
import theme.ThemeColor

@Composable
fun StoreCard(business: Business, onClick: (business: Business) -> Unit) {
    Card(
        modifier = Modifier
            .width(160.dp)
            .height(220.dp)
            .padding(4.dp)
            .background(ThemeColor.shared().White, shape = RoundedCornerShape(10.dp))
            .clip(shape = RoundedCornerShape(12.dp))
            .clickable {
                onClick(business)
            },
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().White,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().White
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .background(ThemeColor.shared().White, shape = RoundedCornerShape(10.dp))
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .fillMaxHeight()
                    .background(color = Color.Black).weight(0.5f)
            ) {
                Image().content(business.Id, business.BusinessTypeID ?: "")
//                AsyncImageView(Server.image(business.Id))
            }
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .fillMaxHeight().padding(8.dp)
                    .weight(0.5f)
            ) {
                Text(
                    business.Name ?: "", color = Color.Black,
                    fontSize = 16.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(top = 8.dp)
                )
                Text(
                    "${business.Address?.City}",
                    color = Color.Gray,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(bottom = 4.dp)
                )
                Text(
                    AddressUtill().getAddressToDisplayInSingleLine(business.Address),
                    color = Color.Gray,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(bottom = 4.dp)
                )
                Box {
                    IconButton(
                        onClick = {

                        },
                        modifier = Modifier
                            .width(50.dp).height(50.dp)
                    ) {
                        Icon(
                            Icons.Rounded.Share,
                            contentDescription = "Notification",
                            tint = ThemeColor.shared().White,
                        )
                    }
                }
            }
        }
    }
}
