package feature.inventory.handler

import feature.inventory.repository.ProductColorRepository
import feature.inventory.viewModel.product_color.ProductColorViewModel


class ProductColorHandler {

    var viewModel: ProductColorViewModel? = null
    val repository = ProductColorRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: ProductColorHandler? = null
        fun shared(): ProductColorHandler {
            if (instance != null) {
                return instance as ProductColorHandler
            } else {
                return ProductColorHandler()
            }
        }
    }

    fun setup(model: ProductColorViewModel) {
        viewModel = model
    }
}