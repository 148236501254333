package feature.app

import feature.address.model.Address
import feature.auth.model.FriendlyUser
import feature.business.model.Business
import feature.businessType.model.BusinessType
import feature.customer.model.Customer
import feature.employee.model.Employee
import feature.inventory.model.Inventory
import feature.order.model.Order
import feature.product.model.Product
import feature.productCategory.model.ProductCategory
import feature.productSubCategory.model.ProductSubCategory
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class NavigationParam(
    @SerialName("id") var id: String? = null,
    @SerialName("url") var url: String? = null,
    @SerialName("UniqueID") var uniqueID: String? = null,
    @SerialName("User") var user: FriendlyUser? = null,
    @SerialName("Business") var business: Business? = null,
    @SerialName("BusinessType") var businessType: BusinessType? = null,
    @SerialName("SelectedBusiness") var selectedBusiness: Business? = null,
    @SerialName("Customer") var customer: Customer? = null,
    @SerialName("Employee") var employee: Employee? = null,
    @SerialName("Product") var product: Product? = null,
    @SerialName("Order") var order: Order? = null,
    @SerialName("ProductCategory") var productCategory: ProductCategory? = null,
    @SerialName("ProductSubCategory") var productSubCategory: ProductSubCategory? = null,
    @SerialName("Inventory") var inventory: Inventory? = null,
    @SerialName("Address") var address: Address? = null,
) {
    fun update(params: NavigationParam?): NavigationParam {
        if (params != null) {
            if (params.id != null) {
                id = params.id
            }
            if (params.url != null) {
                url = params.url
            }
            if (params.uniqueID != null) {
                uniqueID = params.uniqueID
            }
            if (params.business != null) {
                business = params.business
            }
            if (params.businessType != null) {
                businessType = params.businessType
            }
            if (params.selectedBusiness != null) {
                selectedBusiness = params.selectedBusiness
            }
            if (params.customer != null) {
                customer = params.customer
            }
            if (params.employee != null) {
                employee = params.employee
            }
            if (params.product != null) {
                product = params.product
            }
            if (params.order != null) {
                order = params.order
            }
            if (params.productCategory != null) {
                productCategory = params.productCategory
            }
            if (params.productSubCategory != null) {
                productSubCategory = params.productSubCategory
            }
            if (params.inventory != null) {
                inventory = params.inventory
            }
            if (params.address != null) {
                address = params.address
            }
        }
        return this
    }
}