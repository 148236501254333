package feature.inventory.model.product_color

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProductColor(
    @SerialName("_id") var Id: String = "",
    @SerialName("ColorID") var ColorID: Long? = null,
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("BusinessID") var BusinessID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("Name") var Name: String? = null,
    @SerialName("Code") var Code: String? = null,
    @SerialName("Hex") var Hex: String? = null,
    @SerialName("R") var R: Long? = null,
    @SerialName("G") var G: Long? = null,
    @SerialName("B") var B: Long? = null,
    @SerialName("A") var A: Long? = null,
    @SerialName("Description") var Description: String? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)