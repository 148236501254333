package presentation.screens.business.homepage.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import common.asset.ImageAsset
import component.image.FriendlyIcon
import theme.ThemeColor

@Composable
fun WhySoluteCard() {
    Card(
        modifier = Modifier
            .width(350.dp)
            .height(430.dp)
            .padding(40.dp)
            .background(ThemeColor.shared().cardBackground, shape = RoundedCornerShape(4.dp)),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().cardBackground
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 20.dp
        ),
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(4.dp), horizontalAlignment = Alignment.CenterHorizontally
        ) {
            FriendlyIcon(
                size = 80,
                width = 100,
                name = ImageAsset.person
            )
            Text(
                text = "Secured",
                fontSize = 15.sp,
                color = Color.Black,
                fontWeight = FontWeight.Bold,
                fontStyle = FontStyle.Normal,
                modifier = Modifier
                    .padding(top = 30.dp)
            )
            Text(
                text = "Completely secured online , no access to anyone else but you.'Register wide range of business at one platform and enjoy the hassle free experience",
                fontSize = 12.sp,
                color = Color.Gray,
                fontWeight = FontWeight.Bold,
                fontStyle = FontStyle.Normal,
                modifier = Modifier
                    .padding(top = 15.dp)
            )
        }
    }
}