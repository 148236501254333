package feature.employee.network
import feature.employee.model.JobRole
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
@Serializable
data class FetchJobRoleResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: ArrayList<JobRole>
)