package presentation.screens.business.self_business.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.button.RadioButton
import component.image.ImageView
import feature.business.handler.BusinessHandler
import feature.business.model.Business
import feature.business.utill.BusinessUtill

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun SelfBusinessSelectCard(business: Business, onClick: (Business) -> Unit = {}) {
    Box(
        modifier = Modifier.fillMaxWidth().padding(8.dp).height(100.dp)
            .background(Color.White)
    ) {
        ElevatedCard(
            modifier = Modifier.height(100.dp).fillMaxWidth().padding(top = 8.dp, bottom = 8.dp),
            colors = CardDefaults.cardColors(
                containerColor = Color.Transparent,
                contentColor = Color.Transparent
            ),
            onClick = { onClick(business) }
        ) {
            Row(
                modifier = Modifier
                    .background(Color.White)
                    .fillMaxWidth()
                    .fillMaxHeight().padding(start = 8.dp),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                RadioButton(
                    (BusinessHandler.shared().businessId == business.Id),
                    24.dp,
                )
                Box(
                    modifier = Modifier.padding(8.dp).fillMaxHeight().width(60.dp)
                        .background(Color.Transparent),
                ) {
                    ImageView(id = business.Id)
                }
                Column {
                    Text(
                        text = business.Name!!,
                        fontSize = 17.sp,
                        textAlign = TextAlign.Start,
                        color = Color.Black,
                        fontStyle = FontStyle.Normal,
                        fontWeight = FontWeight(600)
                    )
                    Text(
                        text = BusinessUtill().getAddressToDisplayInSingleLine(business),
                        fontSize = 15.sp,
                        textAlign = TextAlign.Start,
                        color = Color.Black,
                        maxLines = 2
                    )
                }
            }
        }
    }
}