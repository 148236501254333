package component.order

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.ImageView
import engine.date.DateEngine
import feature.business.model.Business
import feature.business.utill.AddressUtill
import feature.business.viewModel.BusinessViewModel
import feature.order.model.Order

@Composable
fun OrderCard(order: Order, viewModel: BusinessViewModel, onClick: (Order) -> Unit) {
    var business: Business? by remember { mutableStateOf(null) }
    LaunchedEffect(key1 = Unit) {
        viewModel.getBusinessById(order.selectedBusinessID ?: "") { bus ->
            business = bus
        }
    }
    Column(
        modifier = Modifier
            .height(190.dp)
            .padding(4.dp)
            .fillMaxWidth()
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(Color.White, shape = RoundedCornerShape(10.dp))
                .clip(RoundedCornerShape(10.dp))
                .padding(8.dp)
                .clickable { onClick(order) },
            contentAlignment = Alignment.Center
        ) {
            Row {
                Column(
                    modifier = Modifier
                        .height(120.dp)
                        .fillMaxWidth()
                ) {
                    Row {
                        Box(
                            modifier = Modifier
                                .width(60.dp)
                                .height(60.dp)
                                .padding(4.dp)
                                .background(Color.White, shape = RoundedCornerShape(10.dp))
                                .clip(RoundedCornerShape(10.dp))
                                .clickable { onClick(order) },
                            contentAlignment = Alignment.CenterStart,
                        ) {

                            ImageView(id = order.selectedBusinessID)
                        }
                        Column {
                            Row {
                                Text(
                                    modifier = Modifier.width(220.dp).weight(1f),
                                    text = "${business?.Name}",
                                    fontSize = 16.sp,
                                    fontWeight = FontWeight(600)
                                )
                                Text(modifier = Modifier.width(90.dp), text = "${order.status}")
                            }

                            Text(
                                modifier = Modifier.width(120.dp).weight(1f),
                                text = AddressUtill().getAddressToDisplayInSingleLine(
                                    business?.Address
                                )
                            )
                            Text(
                                modifier = Modifier.width(220.dp),
                                text = DateEngine().displayableDateTime(order.updatedAt ?: "")
                            )
                            Row {
                                Text(
                                    modifier = Modifier.width(120.dp).weight(1f),
                                    text = "${order.orderItems.count()} Item"
                                )

                                Text(
                                    modifier = Modifier.width(220.dp),
                                    text = "${order.pricing?.getCurrencySymbol()} ${order.pricing?.FinalPrice}"
                                )
                            }
                            Row {
//                        Text(
//                            modifier = Modifier.width(220.dp),
//                            text = "Total MRP ${order.pricing?.MRP}"
//                        )
//                                Text(
//                                    modifier = Modifier.width(180.dp),
//                                    text = "Total Amount ${order.pricing?.FinalPrice}"
//                                )
                            }
                        }

                    }


//                    Row(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .height(60.dp)
//                            .padding(top = 16.dp)
//                    ) {
//                        SecondaryButton(
//                            title = "Rate",
//                            onClick = { },
//                        )
//                        DefaultButton(
//                            title = "Re-Order", onClick = {}, modifier = Modifier
//                                .width(50.dp)
//                                .height(30.dp)
//                        )
//                    }

                }
            }
        }
    }


}