package presentation.screens.business.store.currency

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.businessConfig.viewModel.BusinessConfigViewModel
import locale.localization
import presentation.screens.business.store.currency.component.SelectCurrencyCard

class MyBusinessStoreCurrency : Screen {
    @Composable
    override fun Content() {
        val viewModel = BusinessConfigViewModel()
        val allCurrency = viewModel.allCurrency.collectAsState()
        val selectedCurrency = viewModel.selectedCurrency.collectAsState()
        val navigator = LocalNavigator.current
        LaunchedEffect(key1 = Unit) {
            viewModel.fetchAllCurrency()
        }
        Column {
            TopNavBar(
                localization().storeMenu.currencyTitle, null, onBackPress = {
                    navigator?.pop()
                }
            )
            AppLoader(viewModel)
            LazyColumn(
                modifier = Modifier
                    .fillMaxSize().padding(bottom = 60.dp)
            ) {
                item {
                    Text(
                        modifier = Modifier.weight(1f).padding(end = 8.dp),
                        text = "${localization().storeMenu.totalCurrency} = ${allCurrency.value.count()}",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Start
                    )
                }
                items(allCurrency.value.count()) { index ->
                    SelectCurrencyCard(allCurrency.value[index], selectedCurrency.value) {
                        viewModel.updateCurrency(it)

                    }
                }
            }
        }
    }
}