package feature.payment.handler

import feature.payment.handler.PaymentHandler
import feature.payment.repository.PaymentRepository
import feature.payment.viewModel.PaymentViewModel


class PaymentHandler {

    var viewModel: PaymentViewModel? = null
    val repository = PaymentRepository()

    var deviceId = ""
    var otpFromSever = ""
    var mobile = ""
    var dialCode = ""

    init {
        instance = this
    }

    companion object {
        private var instance: PaymentHandler? = null
        fun shared(): PaymentHandler {
            if (instance != null) {
                return instance as PaymentHandler
            } else {
                return PaymentHandler()
            }
        }
    }

}