package feature.businessConfig.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FindBusinessConfigRequest(
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String,
    @SerialName("BusinessID") val businessID: String,
)

@Serializable
data class FetchBusinessConfigRequest(
    @SerialName("BusinessID") val businessID: String,
    @SerialName("UserID") val userId: String,
    @SerialName("LastSyncDate") val lastSyncDate: String?
)

@Serializable
data class FetchAllCurrencyRequest(
    @SerialName("BusinessID") val businessID: String?,
    @SerialName("UserID") val userId: String?,
    @SerialName("LastSyncDate") val lastSyncDate: String?
)

@Serializable
data class CreateBusinessConfigRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("Name") val name: String,
    @SerialName("DialCode") val dialCode: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("EmailID") val email: String,
    @SerialName("Barcode") val barCode: String,
    @SerialName("DeviceID") val deviceID: String,
)

@Serializable
data class UpdateBusinessConfigRequest(
    @SerialName("_id") val id: String,
    @SerialName("UserID") val userId: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("Currency") var currency: String? = null,
    @SerialName("CurrencySymbol") var symbol: String? = null,
)