package presentation.screens.business.homepage.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import common.asset.ImageAsset
import component.button.DefaultButton
import component.dimension.ScreenDimension
import component.image.AsyncImageView
import component.image.FriendlyIcon
import navigation.AppNavigator
import navigation.containers.ScreenName
import theme.ThemeColor

@Composable
fun WebHomeBannerSection() {
    Box(
        modifier = Modifier.height(ScreenDimension().height(0.6f))
            .width(ScreenDimension().width(1.0f))
            .background(
//                brush = Brush.horizontalGradient(
//                    ThemeColor.shared().homeBackground
//                ),
                color = ThemeColor.shared().cardBackground
            ),
    ) {
        Box(modifier = Modifier.fillMaxWidth().fillMaxHeight().zIndex(-1f)) {
            AsyncImageView("https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/website%2Fbanner%2Fgradient_background.png?alt=media&token=646b06f7-0473-477e-88a4-829e4a709fc8")
        }
        Box(modifier = Modifier.fillMaxWidth().fillMaxHeight().zIndex(1f)) {
            Row(modifier = Modifier.fillMaxWidth().fillMaxHeight()) {
                Column(
                    modifier = Modifier
                        .fillMaxHeight()
                        .weight(0.5f)
                        .fillMaxWidth().padding(start = 100.dp),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.Center

                ) {
                    Column(
                        modifier = Modifier.fillMaxSize().weight(0.5f),
                        horizontalAlignment = Alignment.Start,
                        verticalArrangement = Arrangement.Center
                    ) {
                        Row(modifier = Modifier.wrapContentHeight().wrapContentWidth()) {
                            Text(
                                "Your",
                                fontSize = 45.sp,
                                lineHeight = 50.sp,
                                color = Color.Black,
                                fontWeight = FontWeight.Bold,
                                modifier = Modifier.wrapContentWidth().wrapContentHeight()
                            )
                            Text(
                                " One-Stop          ",
                                fontSize = 45.sp,
                                lineHeight = 50.sp,
                                color = ThemeColor.shared().lightGreenText,
                                fontWeight = FontWeight.ExtraBold,
                                modifier = Modifier.wrapContentWidth().wrapContentHeight()
                            )
                        }
                        Text(
                            "Solution for Business &",
                            fontSize = 45.sp,
                            lineHeight = 50.sp,
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier.wrapContentWidth().wrapContentHeight()
                        )
                        Text(
                            "Consumer Needs       ",
                            fontSize = 45.sp,
                            lineHeight = 50.sp,
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                            modifier = Modifier.wrapContentWidth().wrapContentHeight()
                        )

                        Text(
                            "Discover the ultimate solution for seamless business",
                            color = Color.Gray,
                            fontSize = 20.sp,
                            lineHeight = 30.sp,
                            modifier = Modifier.padding(top = 8.dp).wrapContentHeight()
                        )
                        Text(
                            "management and customer engagement.                  ",
                            color = Color.Gray,
                            fontSize = 20.sp,
                            lineHeight = 30.sp,
                            modifier = Modifier.padding(top = 0.dp).wrapContentHeight()
                        )
                        SatisfiedClientsRow()
                        Box(
                            modifier = Modifier.height(70.dp).width(200.dp)
                                .padding(top = 20.dp)
                        ) {
                            DefaultButton(
                                "Get Started",
                                onClick = {
                                    AppNavigator.shared().navigateTo(
                                        ScreenName.LOGIN
                                    )
                                },
                            )
                        }
                    }
                }
                Column(
                    modifier = Modifier
                        .weight(0.5f)
                        .fillMaxHeight()
                        .fillMaxWidth(),
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    FriendlyIcon(
                        size = ScreenDimension().heightHalf().toInt(),
                        width = ScreenDimension().widthHalf().toInt(),
                        name = ImageAsset.screenshot1
                    )

                }
            }
        }
    }
}