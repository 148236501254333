package presentation.screens.common.address.list

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.address.AddressCard
import component.button.DefaultButton
import component.empty.EmptyData
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.address.viewModel.AddressViewModel
import feature.app.NavigationParam
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen

class
AddressListScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val navigator = LocalNavigator.current
        val viewModel = AddressViewModel()
        val addressList = viewModel.allAddress.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.retrieve()
        }
        Column(modifier = Modifier.fillMaxHeight()) {
            AppLoader(viewModel)
            TopNavBar(
                title = localization().address.addressTitle, navigationParam,
                onBackPress = { navigator?.pop() },
                onClickAdd = {
                    AppNavigator.shared().navigateTo(
                        ScreenName.CREATE_ADDRESS
                    )
                })
            if (addressList.value.isEmpty()) {
                EmptyData(name = "Address", viewModel = viewModel) {

                }
                DefaultButton(
                    title = localization().address.createNewAddress,
                    onClick = {
                        AppNavigator.shared().navigateTo(
                            ScreenName.CREATE_ADDRESS
                        )
                    },
                    modifier = Modifier.fillMaxWidth()
                )
            } else {
                LazyColumn(
                    contentPadding = PaddingValues(horizontal = 16.dp, vertical = 8.dp),
                    modifier = Modifier
                        .fillMaxHeight()
                        .fillMaxWidth()
                ) {
                    items(
                        items = addressList.value,
                        itemContent = {
                            AddressCard(address = it, onClick = { address ->
                                viewModel.setEditAddress(address)
                                navigateTo(
                                    ScreenName.EDIT_ADDRESS,
                                    NavigationParam(address = it)
                                )
                            })
                        })
                }
            }
        }
    }
}