package feature.payment.model

import feature.cart.model.CartItem
import feature.payment.status.PaymentStatus
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Payment(
    @SerialName("_id") var _id: String = "",
    @SerialName("PaymentID") var paymentId: Long? = null,
    @SerialName("PaymentCreatedAt") var paymentCreatedAt: Long? = null,
    @SerialName("UserID") var userId: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("SelectedBusinessID") var selectedBusinessID: String? = null,
    @SerialName("PaymentType") var paymentType: String? = null,
    @SerialName("DeviceID") var deviceId: String? = null,
    @SerialName("PaymentItems") var paymentItems: ArrayList<CartItem> = arrayListOf(),
    @SerialName("Currency") var currency: String? = null,
    @SerialName("Entity") var entity: String? = null,
    @SerialName("PaymentGatewayOrderID") var paymentGatewayOrderID: String? = null,
    @SerialName("PageUrl") var pageUrl: String? = null,
    @SerialName("PendingAmount") var pendingAmount: Float? = null,
    @SerialName("ClearedAmount") var clearedAmount: Float? = null,
    @SerialName("InstantDiscount") var instantDiscount: Double? = null,
    @SerialName("OfferID") var offerID: Double? = null,
    @SerialName("Receipt") var receipt: Double? = null,
    @SerialName("Coupon") var coupon: Double? = null,
    @SerialName("Status") var status: PaymentStatus? = null,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
)