package feature.order.network

import common.constants.Server
import feature.order.event.OrderEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class OrderNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: OrderNetwork? = null
        fun shared(): OrderNetwork {
            if (instance != null) {
                return instance as OrderNetwork
            } else {
                return OrderNetwork()
            }
        }
    }

    fun retrieve(
        request: RetrieveOrderRequest,
        onResult: (RetrieveOrderResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(OrderEvent.RETRIEVE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }

    fun create(
        request: CreateOrderRequest,
        onResult: (CreateOrderResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(OrderEvent.CREATE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }
//    fun create(request: JSONObject, onResult: (JSONObject?) -> Unit) {
//        val retrofit = buildService(OrderNetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            request.toString().toRequestBody("application/json".toMediaTypeOrNull())
//        retrofit.create(bodyRequest).enqueue(
//            ResponseHandler().getRestHandler(onResult)
//        )
//    }
//
//    fun update(request: JSONObject, onResult: (JSONObject?) -> Unit) {
//        val retrofit = buildService(OrderNetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            request.toString().toRequestBody("application/json".toMediaTypeOrNull())
//        retrofit.update(bodyRequest).enqueue(
//            ResponseHandler().getRestHandler(onResult)
//        )
//    }
//
//    fun delete(request: JSONObject, onResult: (JSONObject?) -> Unit) {
//        val retrofit = buildService(OrderNetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            request.toString().toRequestBody("application/json".toMediaTypeOrNull())
//        retrofit.delete(bodyRequest).enqueue(
//            ResponseHandler().getRestHandler(onResult)
//        )
//    }
//
//    fun retrieve(request: JSONObject, onResult: (JSONObject?) -> Unit) {
//        val retrofit = buildService(OrderNetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            request.toString().toRequestBody("application/json".toMediaTypeOrNull())
//        retrofit.retrieve(bodyRequest).enqueue(
//            ResponseHandler().getRestHandler(onResult)
//        )
//    }
}