package component.product

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.button.StepperButton
import component.image.ImageView
import feature.app.NavigationParam
import feature.cart.model.CartItem
import feature.cart.viewModel.CartViewModel
import feature.product.model.Product
import theme.ThemeColor

@Composable
fun CartItemCard(
    cartItem: CartItem,
    viewModel: CartViewModel,
    param: NavigationParam?,
    onClick: (Product) -> Unit = {}
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.dp)
            .padding(5.dp)
            .background(ThemeColor.shared().White, shape = RoundedCornerShape(10.dp)),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().White,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().White
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .height(90.dp).padding(8.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Box(
                modifier = Modifier
                    .width(70.dp)
                    .height(70.dp)
            ) {
                ImageView(cartItem.product.Id)
            }
            Column(modifier = Modifier.fillMaxHeight().fillMaxWidth().padding(start = 8.dp)) {
                Text(
                    cartItem.product.Name ?: "",
                    fontSize = 14.sp,
                    color = Color.Black,
                    modifier = Modifier.height(20.dp)
                )
                Text(
                    cartItem.product.Description ?: " ",
                    fontSize = 10.sp,
                    color = Color.Gray,
                    modifier = Modifier.height(20.dp)
                )
                Row(
                    modifier = Modifier.fillMaxWidth().height(45.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        "${cartItem.price.getCurrencySymbol()} ${cartItem.product.ProductPrice.FinalPrice}",
                        fontSize = 16.sp,
                        color = Color.Black,
                        modifier = Modifier.height(20.dp).weight(1f)
                    )
                    Row(modifier = Modifier.height(40.dp).width(120.dp)) {
                        StepperButton(
                            count = cartItem.quantity,
                            onDecrease = {
                                viewModel.removeFromCart(cartItem.product, param)
                            },
                            onIncrease = {
                                viewModel.addToCart(cartItem.product, param)
                            }
                        )
                    }
                    Text(
                        "${cartItem.price.getCurrencySymbol()} ${cartItem.price.FinalPrice}",
                        fontSize = 16.sp,
                        color = Color.Black,
                        textAlign = TextAlign.End,
                        modifier = Modifier.height(20.dp).width(100.dp)
                            .padding(start = 8.dp, end = 8.dp)
                    )
                }
            }
        }
    }
}
