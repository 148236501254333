package feature.inventory.repository

import feature.inventory.model.uom.UOM
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class UOMRepository {
    val uomLiveData: MutableStateFlow<List<UOM>> = MutableStateFlow(arrayListOf())
    val allUOM: StateFlow<List<UOM>>
        get() = uomLiveData

    val selectedUOMState: MutableStateFlow<UOM?> = MutableStateFlow(null)
    val selectedUOM: StateFlow<UOM?>
        get() = selectedUOMState

}