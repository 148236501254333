package feature.invoice.network

import feature.invoice.model.CustomerInvoice
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveInvoiceResponse(
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: ArrayList<CustomerInvoice>,
)

@Serializable
data class RetrieveSingleInvoiceResponse(
    @SerialName("Payload") val payload: CustomerInvoice?,
)