package storage.database.table.inventory.product

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.ProductTable
import feature.product.model.Product
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class ProductDatabaseTable : DatabaseTable() {
    fun insert(item: Product?) {
        val table = AppDatabase.shared().database?.productTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    item.Id,
                    item.ProductID,
                    item.UserID,
                    item.BusinessID,
                    item.CategoryID,
                    item.SubCategoryID,
                    item.Name,
                    item.Description,
                    jsonDecoder.encodeToString(item.ProductPrice),
                    item.ManageInventory,
                    item.IsDeleted,
                    item.CreatedAt,
                    item.UpdatedAt
                )
            }
        }
    }

    fun insert(items: List<Product>?, callBack: () -> Unit) {
        scope.launch {
            items?.forEach { item ->
                insert(item)
            }
            delay(delay)
            callBack()
        }
    }

    fun retrieve(id: String, callback: (List<Product>) -> Unit) {
        val table = AppDatabase.shared().database?.productTableQueries
        val itemsFetched: ArrayList<Product> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun retrieve(id: String, categoryId: String, callback: (List<Product>) -> Unit) {
        val table = AppDatabase.shared().database?.productTableQueries
        val itemsFetched: ArrayList<Product> = arrayListOf()
        scope.launch {
            val items = table?.retrieveForCategory(id, categoryId)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun retrieveSubCategory(id: String, subcategoryId: String, callback: (List<Product>) -> Unit) {
        val table = AppDatabase.shared().database?.productTableQueries
        val itemsFetched: ArrayList<Product> = arrayListOf()
        scope.launch {
            val items = table?.retrieveForSubCategory(id, subcategoryId)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun search(id: String, query: String, callback: (List<Product>) -> Unit) {
        val table = AppDatabase.shared().database?.productTableQueries
        val itemsFetched: ArrayList<Product> = arrayListOf()
        scope.launch {
            val items = table?.search(id, "%${query}%", "%${query}%", "%${query}%")?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun find(id: String, callback: (Product?) -> Unit) {
        val table = AppDatabase.shared().database?.productTableQueries
        var itemsFetched: Product? = null
        scope.launch {
            val items = table?.find(id)?.awaitAsOneOrNull()
            items?.let { item ->
                itemsFetched = convert(item)
            }
            callback(itemsFetched)
        }
    }

    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.productTableQueries
        scope.launch {
            val dateFromDb = table?.lastSyncDate(id)?.awaitAsOneOrNull()
            callback(dateFromDb?.UpdatedAt)
        }
    }

    fun convert(item: ProductTable): Product {
        return Product(
            Id = item._id,
            ProductID = item.ProductID,
            UserID = item.UserID,
            BusinessID = item.BusinessID,
            CategoryID = item.CategoryID,
            SubCategoryID = item.SubCategoryID,
            Name = item.Name,
            Description = item.Description,
            ManageInventory = item.ManageInventory,
            ProductPrice = jsonDecoder.decodeFromString(item.ProductPrice ?: "{}"),
            CreatedAt = item.CreatedAt,
            UpdatedAt = item.UpdatedAt,
        )
    }
}