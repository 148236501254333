package feature.order.handler

import feature.order.repository.OrderRepository
import feature.order.viewModel.OrderViewModel

class OrderHandler {

    var viewModel: OrderViewModel? = null
    val repository = OrderRepository()

    var deviceId = ""
    var otpFromSever = ""
    var mobile = ""
    var dialCode = ""

    init {
        instance = this
    }

    companion object {
        private var instance: OrderHandler? = null
        fun shared(): OrderHandler {
            if (instance != null) {
                return instance as OrderHandler
            } else {
                return OrderHandler()
            }
        }
    }

}