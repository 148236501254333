package component.image

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import feature.mediaFile.model.MediaFile
import feature.mediaFile.viewModel.MediaFileViewModel
import native.FilePickerView

@Composable
fun ImageView(
    id: String?,
    isEditable: Boolean = false,
    viewModel: MediaFileViewModel = MediaFileViewModel()
) {
    var media: MediaFile? by remember { mutableStateOf(null) }
    LaunchedEffect(key1 = Unit) {
        if (!id.isNullOrEmpty()) {
            viewModel.loadMedia(id) {
                media = it
            }
        }
    }
    FilePickerView(id = id, media, isEditable, viewModel)
}