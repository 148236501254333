package feature.payment.network

import feature.product.model.ProductPrice
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreatePaymentRequest(
    @SerialName("PaymentID") var paymentID: Long? = null,
    @SerialName("UserID") var userId: String? = null,
    @SerialName("MobileNumber") var mobile: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("SelectedBusinessID") var selectedBusinessID: String? = null,
    @SerialName("AddressID") var addressID: String? = null,
    @SerialName("DeviceID") var deviceId: String? = null,
    @SerialName("Pricing") var pricing: ProductPrice? = null,
    @SerialName("PaymentGatewayName") var paymentGateway: String? = null,
    @SerialName("DeliveryType") var deliveryType: String? = null,
    @SerialName("InstantDiscount") var instantDiscount: Double? = null,
    @SerialName("Coupon") var coupon: Double? = null,
)

@Serializable
data class RetrievePaymentRequest(
    @SerialName("_id") var id: String? = null,
)

@Serializable
data class FindPaymentRequest(
    @SerialName("_id") var id: String? = null,
)