package component.country

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import theme.ThemeColor

class CountryPicker {
    @Composable
    fun CountryCodeDialog(
        modifier: Modifier = Modifier,
        isOnlyFlagShow: Boolean = false,
        defaultSelectedCountry: CountryCode = DialCodeHelper().getSelectedCountryCode(),
        pickedCountry: (CountryCode) -> Unit,
        dialogSearch: Boolean = true,
        dialogRounded: Int = 12
    ) {
        var isPickCountry by remember { mutableStateOf(defaultSelectedCountry) }
        var isOpenDialog by remember { mutableStateOf(false) }
        var searchValue by remember { mutableStateOf("") }
        var countryList: List<CountryCode> = DialCodeHelper().getListOfCountries()
        LaunchedEffect(key1 = Unit) {
            pickedCountry(defaultSelectedCountry)
        }
        Card(
            colors = CardDefaults.cardColors(
                containerColor = ThemeColor.shared().cardBackground,
            ),
            modifier = modifier
                .padding(top = 0.dp, bottom = 0.dp, start = 4.dp)
                .fillMaxHeight()
                .background(ThemeColor.shared().cardBackground, shape = RoundedCornerShape(16.dp))
                .clickable { isOpenDialog = true }
        ) {
            Column(
                modifier = Modifier
                    .padding(15.dp)
                    .background(ThemeColor.shared().cardBackground)
            ) {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.background(ThemeColor.shared().cardBackground)
                ) {

//                    Image(
//                        painter = painterResource(
//                            id = getFlags(
//                                isPickCountry.countryCode
//                            )
//                        ),
//                        contentDescription = null,
//                    )
                    if (!isOnlyFlagShow) {
                        Text(
                            isPickCountry.countryPhoneCode,
                            Modifier
                                .padding(vertical = 10.dp, horizontal = 8.dp)
                                .fillMaxHeight()

                        )
                    }
                    Icon(imageVector = Icons.Default.ArrowDropDown, contentDescription = null)
                }
            }

            //Dialog
            if (isOpenDialog) {
                Dialog(
                    onDismissRequest = { isOpenDialog = false },
                ) {
                    Card(
                        Modifier
                            .fillMaxWidth()
                            .background(Color.White)
                            .fillMaxHeight(0.85f),

                        elevation = CardDefaults.cardElevation(
                            defaultElevation = 4.dp
                        ),
                        colors = CardDefaults.cardColors(
                            containerColor = Color.White,
                        ),
                        shape = RoundedCornerShape(dialogRounded.dp)

                    ) {
                        Column {
                            if (dialogSearch) {
                                searchValue = dialogSearchView()
                                countryList = DialCodeHelper().findCountryCode(searchValue)
                            }
                            LazyColumn {
                                items(
                                    count = countryList.count()
                                ) { index ->
                                    Row(
                                        Modifier
                                            .padding(
                                                horizontal = 18.dp,
                                                vertical = 18.dp
                                            )
                                            .clickable {
                                                pickedCountry(countryList[index])
                                                isPickCountry = countryList[index]
                                                isOpenDialog = false
                                            }) {
//                                        Image(
//                                            painter = painterResource(
//                                                id = getFlags(
//                                                    countryItem.countryCode
//                                                )
//                                            ), contentDescription = null
//                                        )
                                        Text(
                                            countryList[index].countryPhoneCode,
                                            Modifier.padding(horizontal = 8.dp)
                                        )
                                        Text(
                                            countryList[index].countryName,
                                            Modifier.padding(horizontal = 18.dp)
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @Composable
    private fun dialogSearchView(): String {
        var searchValue by remember { mutableStateOf("") }
        Row(
            modifier = Modifier.background(Color.White)
        ) {
            MyCustomTextField(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(60.dp)
                    .background(Color.White),

                value = searchValue,
                onValueChange = {
                    searchValue = it
                },

                fontSize = 14.sp,
                hint = "Search ...",
                textAlign = TextAlign.Start,
            )
        }
        return searchValue
    }

    @Composable
    private fun MyCustomTextField(
        modifier: Modifier = Modifier,
        value: String,
        onValueChange: (String) -> Unit,
        hint: String = "",
        fontSize: TextUnit = 16.sp,
        textAlign: TextAlign = TextAlign.Center
    ) {
        Box(
            modifier = modifier
                .background(
                    color = Color.White.copy(alpha = 0.1f)
                )
        ) {
            TextField(
                modifier = Modifier
                    .fillMaxWidth(),
                value = value,
                onValueChange = onValueChange,
                textStyle = LocalTextStyle.current.copy(
                    textAlign = textAlign,
                    fontSize = fontSize
                ),
                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                singleLine = true,
                leadingIcon = {
                    Icon(
                        Icons.Default.Search,
                        contentDescription = null,
                        tint = Color.Black.copy(0.2f)
                    )
                },
//                colors = TextFieldDefaults.textFieldColors(
//                    backgroundColor = Color.Transparent,
//                    focusedIndicatorColor = Color.Transparent,
//                    disabledIndicatorColor = Color.Transparent,
//                    unfocusedIndicatorColor = Color.Transparent
//                )
            )
            if (value.isEmpty()) {
                Text(
                    text = hint,
                    style = MaterialTheme.typography.bodyLarge,
                    color = Color.Gray,
                    modifier = Modifier.then(
                        Modifier
                            .align(Alignment.CenterStart)
                            .padding(start = 52.dp)
                    )
                )
            }
        }
    }
}
