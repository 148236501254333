package presentation.screens.common.auth


import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import component.input.CustomTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.auth.handler.AuthHandler
import feature.auth.viewModel.AuthViewModel
import kotlinx.coroutines.delay
import navigation.AppNavigator
import navigation.containers.ScreenName
import theme.ThemeColor

class OTPScreen : Screen {

    @Composable
    override fun Content() {
        val viewModel = AuthViewModel()
        val navigator = LocalNavigator.current
        val mobileNumber = viewModel.mobileNumber.collectAsState()
        var otp by remember { mutableStateOf(TextFieldValue("")) }
        var otpFromServer = viewModel.otp.collectAsState()
        var isValidOtp by remember { mutableStateOf(false) }
        val passwordErrorState = remember {
            mutableStateOf(false)
        }
        var timer by remember { mutableStateOf(120) }
        LaunchedEffect(key1 = timer) {
            if (timer > 0) {
                delay(1_000)
                timer -= 1
            }
        }
        viewModel.goToHome = {
            AppNavigator.shared().popAll()
        }
        viewModel.goBack = {
            AppNavigator.shared().pop()
        }
        viewModel.goToRegister = {
            AppNavigator.shared().navigateTo(ScreenName.SIGN_UP)
        }
        viewModel.goToSetMPin = {
            AppNavigator.shared().navigateTo(ScreenName.SET_M_PIN)
        }
        viewModel.goToLoginWithMPin = {
            AppNavigator.shared().navigateTo(
                ScreenName.VERIFY_M_PIN
            )
        }
        viewModel.goToOtp = {
            AppNavigator.shared().navigateTo(ScreenName.OTP)
        }

        fun onClickContinue() {
            isValidOtp = otp.text.length == 6
            if (isValidOtp && otpFromServer.value == otp.text && viewModel.tempAuthUser.value.name.isNullOrEmpty()) {
                viewModel.goToRegister?.let { it() }
            } else if (otpFromServer.value.length != 6) {
                viewModel.sendOTP(AuthHandler.shared().mobileNumber, AuthHandler.shared().dialCode)
            } else if (otpFromServer.value == otp.text) {
                if (viewModel.password.value.isNotEmpty()) {
                    viewModel.goToSetMPin?.let { it() }
                } else {
                    viewModel.goToSetMPin?.let { it() }
                }
            } else {

            }
        }

        fun sendOtp() {
            viewModel.sendOTP(AuthHandler.shared().mobile, AuthHandler.shared().dialCode)
        }
        Column(modifier = Modifier.fillMaxWidth().fillMaxSize()) {
            TopNavBar(title = "OTP", null, onBackPress = { navigator?.pop() })
            AppLoader(viewModel)
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(30.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {

                CustomTextField(
                    placeholder = "******",
                    trailingIcon = ImageAsset.lock,
                    label = "OTP",
                    keyboardType = KeyboardType.Phone,
                    errorState = passwordErrorState.value,
                    visualTransformation = PasswordVisualTransformation()
                ) { newPass ->
                    otp = newPass
                }
                Spacer(modifier = Modifier.fillMaxHeight(0.3f))
                CustomDefaultBtn(shapeSize = 50f, btnText = "Verify") {
                    onClickContinue()
                }
                Spacer(modifier = Modifier.fillMaxHeight(0.3f))
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .padding(bottom = 50.dp),
                    verticalArrangement = Arrangement.Bottom
                ) {
                    Text(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(top = 30.dp),
                        text = "Please wait a few minutes to receive your 6-digit code before trying again.",
                        color = ThemeColor.shared().PrimaryColor,
                    )
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(top = 30.dp),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        Text(
                            text = if (timer > 0) "Haven't received otp ?, resend in " else "Haven't received otp ? ",
                            color = ThemeColor.shared().TextColor
                        )
                        if (timer > 0) {
                            Text(
                                text = "$timer sec",
                                color = ThemeColor.shared().PrimaryColor,
                                modifier = Modifier.clickable {
                                    timer = 180
                                })

                        } else {
                            Text(
                                text = " Resend",
                                color = ThemeColor.shared().PrimaryColor,
                                modifier = Modifier.clickable {
                                    sendOtp()
                                })
                        }
                    }
                }
            }
        }
    }
}