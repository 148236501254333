package feature.businessConfig.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class BusinessConfig(
    @SerialName("_id") var id: String = "",
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("symbol_native") var symbol: String? = null,
    @SerialName("Currency") var currency: String? = null,
    @SerialName("CurrencySymbol") var currencySymbol: String? = null,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)
