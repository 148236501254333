package feature.businessType.network

import common.constants.Server
import feature.businessType.event.BusinessTypeEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class BusinessTypeNetwork {
    private val decoder = Json { ignoreUnknownKeys = true }
    fun retrieve(
        request: BusinessTypeRequest,
        onResult: (BusinessTypeResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(BusinessTypeEvent.RETRIEVE.value)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(decoder.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }
}