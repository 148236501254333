package feature.order.network

import feature.cart.model.CartItem
import feature.product.model.ProductPrice
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateOrderRequest(
    @SerialName("OrderID") var orderID: Long? = null,
    @SerialName("UserID") var userId: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("SelectedBusinessID") var selectedBusinessID: String? = null,
    @SerialName("AddressID") var addressID: String? = null,
    @SerialName("DeviceID") var deviceId: String? = null,
    @SerialName("OrderItems") var orderItems: ArrayList<CartItem> = arrayListOf(),
    @SerialName("Pricing") var pricing: ProductPrice? = null,
    @SerialName("DeliveryType") var deliveryType: String? = null,
    @SerialName("InstantDiscount") var instantDiscount: Double? = null,
    @SerialName("Coupon") var coupon: Double? = null,
)

@Serializable
data class RetrieveOrderRequest(
    @SerialName("UserID") var userId: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("SelectedBusinessID") var selectedBusinessID: String? = null,
)