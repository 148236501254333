package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.mediaFileTable.GetLatestMediaFileDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class MediaFileTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(FeatureObjectID: String?, mapper: (
    UniqueID: Long?,
    _id: String,
    UserID: String?,
    BusinessID: String?,
    FeatureObjectID: String?,
    IsDeleted: Boolean,
    FileURL: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(FeatureObjectID) { cursor ->
    mapper(
      cursor.getLong(0),
      cursor.getString(1)!!,
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getBoolean(5)!!,
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8)
    )
  }

  public fun retrieve(FeatureObjectID: String?): Query<MediaFileTable> = retrieve(FeatureObjectID) {
      UniqueID, _id, UserID, BusinessID, FeatureObjectID_, IsDeleted, FileURL, CreatedAt,
      UpdatedAt ->
    MediaFileTable(
      UniqueID,
      _id,
      UserID,
      BusinessID,
      FeatureObjectID_,
      IsDeleted,
      FileURL,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getLatestMediaFileDate(FeatureObjectID: String?,
      mapper: (UpdatedAt: String?) -> T): Query<T> = GetLatestMediaFileDateQuery(FeatureObjectID) {
      cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun getLatestMediaFileDate(FeatureObjectID: String?): Query<GetLatestMediaFileDate> =
      getLatestMediaFileDate(FeatureObjectID) { UpdatedAt ->
    GetLatestMediaFileDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    UniqueID: Long?,
    _id: String,
    UserID: String?,
    BusinessID: String?,
    FeatureObjectID: String?,
    IsDeleted: Boolean,
    FileURL: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(-1_211_291_065, """
        |INSERT OR REPLACE INTO MediaFileTable(UniqueID, _id, UserID, BusinessID, FeatureObjectID, IsDeleted,FileURL,CreatedAt,UpdatedAt)
        |    VALUES(?, ?, ?, ?, ? ,?, ?, ?,?)
        """.trimMargin(), 9) {
          bindLong(0, UniqueID)
          bindString(1, _id)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindString(4, FeatureObjectID)
          bindBoolean(5, IsDeleted)
          bindString(6, FileURL)
          bindString(7, CreatedAt)
          bindString(8, UpdatedAt)
        }.await()
    notifyQueries(-1_211_291_065) { emit ->
      emit("MediaFileTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val FeatureObjectID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("MediaFileTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("MediaFileTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT MediaFileTable.UniqueID, MediaFileTable._id, MediaFileTable.UserID, MediaFileTable.BusinessID, MediaFileTable.FeatureObjectID, MediaFileTable.IsDeleted, MediaFileTable.FileURL, MediaFileTable.CreatedAt, MediaFileTable.UpdatedAt FROM MediaFileTable WHERE FeatureObjectID ${ if (FeatureObjectID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, FeatureObjectID)
    }

    override fun toString(): String = "MediaFileTable.sq:retrieve"
  }

  private inner class GetLatestMediaFileDateQuery<out T : Any>(
    public val FeatureObjectID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("MediaFileTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("MediaFileTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM MediaFileTable WHERE FeatureObjectID ${ if (FeatureObjectID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, FeatureObjectID)
    }

    override fun toString(): String = "MediaFileTable.sq:getLatestMediaFileDate"
  }
}
