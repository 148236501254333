package com.friendly.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class MediaFileTable(
  public val UniqueID: Long?,
  public val _id: String,
  public val UserID: String?,
  public val BusinessID: String?,
  public val FeatureObjectID: String?,
  public val IsDeleted: Boolean,
  public val FileURL: String?,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
)
