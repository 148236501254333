package presentation.screens.customer.store.payment.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.Money
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.RadioButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.navigator.LocalNavigator
import navigation.containers.ScreenName
import presentation.screens.customer.store.shopbycategory.ShopByCategoryScreen

import theme.ThemeColor

@Composable
fun PaymentMethodCard() {
    val navigator = LocalNavigator.current
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.dp)
            .padding(8.dp)
            .background(ThemeColor.shared().White, shape = RoundedCornerShape(10.dp)),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().White,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().White
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .padding(4.dp)
        ) {
            Box(
                modifier = Modifier.padding(start = 8.dp, top = 20.dp).weight(0.1f).width(35.dp)
                    .height(35.dp)

            ) {
                Icon(
                    Icons.Rounded.Money,
                    contentDescription = "edit item",
                    tint = ThemeColor.shared().PrimaryColor,
                    modifier = Modifier
                        .padding(start = 8.dp, top = 8.dp)
                )
            }
            Column(
                modifier = Modifier
                    .weight(0.8f).padding(start = 16.dp)
                    .fillMaxHeight(),
                verticalArrangement = Arrangement.Center
            ) {
                Text("COD : Cash on delivery", fontSize = 16.sp)
//                Text("VISA", fontSize = 16.sp, color = Color.Gray)
            }
            Box(
                modifier = Modifier
                    .width(20.dp)
                    .height(20.dp)
                    .weight(0.1f)
                    .padding(top = 35.dp)
            ) {
                RadioButton(true, {
                    navigator?.push(ShopByCategoryScreen(screenName = ScreenName.SHOP_BY_CATEGORY))
                })
            }
        }
    }
}
