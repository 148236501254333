package presentation.screens.business.self_business.list

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.uniqueScreenKey
import cafe.adriel.voyager.navigator.LocalNavigator
import component.empty.EmptyData
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.business.viewModel.BusinessViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.onboard.select_business_type.SelectBusinessTypeScreen
import presentation.screens.business.self_business.component.SelfBusinessCard
import theme.ThemeColor

class SelfBusinessListScreen(override val screenName: ScreenName) : AppScreen {

    override val key = uniqueScreenKey

    @Composable
    override fun Content() {
        val viewModel = BusinessViewModel()
        val navigator = LocalNavigator.current
        val allBusiness = viewModel.allBusiness.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.loadBusiness()
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = localization().businessList.title, navigationParam, onBackPress = {
                navigator?.pop()
            }, onClickAdd = {
                navigator?.push(SelectBusinessTypeScreen(ScreenName.SELECT_BUSINESS_TYPE))
            })
            AppLoader(viewModel)
            if (allBusiness.value.isNotEmpty()) {
                LazyVerticalGrid(
                    columns = GridCells.Adaptive(minSize = 400.dp),
                    modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
                ) {
                    items(allBusiness.value.count()) { index ->
                        SelfBusinessCard(allBusiness.value[index]) {
                            navigateTo(ScreenName.MY_BUSINESS_PROFILE, navigationParam)
                        }
                    }
                }
            } else {
                EmptyData(viewModel = viewModel) {
                    viewModel.loadBusiness()
                }
            }
        }
    }
}