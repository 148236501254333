package navigation.screen

import androidx.compose.runtime.Composable
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import cafe.adriel.voyager.navigator.Navigator
import feature.app.AppViewModel
import feature.app.NavigationParam
import navigation.AppNavigator
import navigation.containers.ScreenName

interface AppScreen : Screen {

    val navigator: Navigator? get() = AppNavigator.shared().navigator
    
    var viewModel: AppViewModel?
        get() = AppViewModel().getViewModel(screenName)
        set(value) = (viewModel ?: AppViewModel()).setViewModel(screenName, value)

    val screenName: ScreenName

    var navigationParam: NavigationParam?
        get() = (viewModel ?: AppViewModel()).getParam(screenName)
        set(value) = (viewModel ?: AppViewModel()).setNavigationParam(screenName, value)

    @Composable
    override fun Content() {
        AppNavigator.shared().updateNavigator(LocalNavigator.current)
        AppNavigator.shared().currentScreen = this
    }

    fun navigateTo(
        name: ScreenName?,
        params: NavigationParam? = null
    ) {
        (viewModel ?: AppViewModel()).navigateTo(name, params ?: navigationParam)
    }
}