package feature.address.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateAddressRequest(
    @SerialName("BusinessID") val businessId: String? = null,
    @SerialName("UserID") val userId: String?,
    @SerialName("FeatureObjectID") val featureObjectID: String? = null,
    @SerialName("Name") val name: String? = null,
    @SerialName("UniqueID") val uniqueID: Long? = null,
    @SerialName("House") val house: String? = null,
    @SerialName("FlatNumber") val flatNumber: String? = null,
    @SerialName("ZipCode") val zipCode: Long? = null,
    @SerialName("Country") val country: String? = null,
    @SerialName("State") val state: String? = null,
    @SerialName("City") val city: String? = null,
    @SerialName("Area") val area: String? = null,
    @SerialName("LandMark") val landMark: String? = null,
    @SerialName("Floor") val floor: String? = null,
    @SerialName("MobileNumber") val mobileNumber: String? = null,
    @SerialName("DialCode") val dialCode: String? = null,
    @SerialName("Type") val type: String? = null,
    @SerialName("Location") val location: ArrayList<Double>? = null,
    @SerialName("IsPrimary") val isPrimary: Boolean? = null,
    @SerialName("IsDeleted") val isDeleted: Boolean? = null,
    @SerialName("CreatedAt") val createdAt: String? = null,
    @SerialName("UpdatedAt") val updatedAt: String? = null,
)

@Serializable
data class UpdateAddressRequest(
    @SerialName("_id") val id: String? = null,
    @SerialName("BusinessID") val businessId: String? = null,
    @SerialName("UserID") val userId: String?,
    @SerialName("FeatureObjectID") val featureObjectID: String? = null,
    @SerialName("Name") val name: String? = null,
    @SerialName("UniqueID") val uniqueID: Long? = null,
    @SerialName("House") val house: String? = null,
    @SerialName("FlatNumber") val flatNumber: String? = null,
    @SerialName("ZipCode") val zipCode: Long? = null,
    @SerialName("Country") val country: String? = null,
    @SerialName("State") val state: String? = null,
    @SerialName("City") val city: String? = null,
    @SerialName("Area") val area: String? = null,
    @SerialName("LandMark") val landMark: String? = null,
    @SerialName("Floor") val floor: String? = null,
    @SerialName("MobileNumber") val mobileNumber: String? = null,
    @SerialName("DialCode") val dialCode: String? = null,
    @SerialName("Type") val type: String? = null,
    @SerialName("Location") val location: ArrayList<Double>? = null,
    @SerialName("IsPrimary") val isPrimary: Boolean? = null,
    @SerialName("IsDeleted") val isDeleted: Boolean? = null,
    @SerialName("CreatedAt") val createdAt: String? = null,
    @SerialName("UpdatedAt") val updatedAt: String? = null,
)

@Serializable
data class RetrieveAddressRequest(
    @SerialName("BusinessID") val businessId: String?,
    @SerialName("UserID") val userId: String?,
    @SerialName("FeatureObjectID") val featureObjectID: String? = null,
)

@Serializable
data class FindZipCodeRequest(
    @SerialName("PinCode") val pinCode: String?,
    @SerialName("Country") val country: String?,
)
