package feature.mediaFile.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class MediaFileRetrieveRequest(
    @SerialName("BusinessID") val businessID: String?,
    @SerialName("FeatureObjectID") val featureObjectID: String? = null,
)

@Serializable
data class MediaFileCreateRequest(
    @SerialName("BusinessID") val businessID: String,
    @SerialName("FeatureObjectID") val featureObjectID: String? = null,
    @SerialName("FileName") val fileName: String? = null,
    @SerialName("File") val file: String? = null,
)

@Serializable
data class MediaFileUploadRequest(
    @SerialName("UniqueID") val uniqueId: Long,
    @SerialName("UserID") val userId: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("FeatureObjectID") val featureObjectID: String? = null,
    @SerialName("FileName") val fileName: String? = null,
    @SerialName("File") val file: String? = null,
)
