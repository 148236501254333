package component.image

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import io.kamel.image.KamelImage
import io.kamel.image.asyncPainterResource

@Composable
fun AsyncImageView(url: String, onFail: (() -> Unit) = {}) {
    Box {
        KamelImage(
            modifier = Modifier.background(Color.Transparent).fillMaxSize(),
            contentScale = ContentScale.FillBounds,
            resource = asyncPainterResource(data = url),
            contentDescription = "Image",
            onLoading = { progress ->
                CircularProgressIndicator(
                    progress = { progress },
                )
            },
            onFailure = { exception ->
                onFail()
            }
        )
    }
}