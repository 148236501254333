package component.image

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import feature.mediaFile.model.MediaFile
import feature.mediaFile.viewModel.MediaFileViewModel
import io.kamel.image.KamelImage
import io.kamel.image.asyncPainterResource
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.launch


class Image {
    val viewModal = MediaFileViewModel()
    val mediaFile: MutableStateFlow<MediaFile?> = MutableStateFlow(MediaFile())

    fun load(id: String) {
        viewModal.loadMedia(id) { file ->
            viewModal.scope.launch {
                if (file?.FileURL.isNullOrEmpty()) {
//                    mediaFile.emit(MediaFile(FileURL = Server.image(id)))
                } else {
                    mediaFile.emit(file)
                }
            }
        }
    }

    fun load(id: String, alternate: String) {
        viewModal.loadMedia(id) { file ->
            if (file?.FileURL.isNullOrEmpty()) {
                viewModal.loadMedia(alternate) { alternateFile ->
                    viewModal.scope.launch {
                        if (alternateFile?.FileURL.isNullOrEmpty()) {
//                            mediaFile.emit(MediaFile(FileURL = Server.image(id)))
                        } else {
                            mediaFile.emit(file)
                        }
                    }
                }
            } else {
                viewModal.scope.launch {
                    if (file?.FileURL.isNullOrEmpty()) {
//                        mediaFile.emit(MediaFile(FileURL = Server.image(id)))
                    } else {
                        mediaFile.emit(file)
                    }
                }
            }
        }
    }

    @Composable
    fun content(id: String, onFail: (() -> Unit) = {}) {
        val media = mediaFile.collectAsState()
        load(id)
        Box {
            KamelImage(
                modifier = Modifier.background(Color.Transparent).fillMaxSize(),
                contentScale = ContentScale.FillBounds,
                resource = asyncPainterResource(data = media.value?.FileURL ?: ""),
                contentDescription = "Image",
                onLoading = { progress ->
                    CircularProgressIndicator(
                        progress = { progress },
                    )
                },
                onFailure = { exception ->
                    onFail()
                }
            )
        }
    }

    @Composable
    fun content(id: String, alternate: String, onFail: (() -> Unit) = {}) {
        val media = mediaFile.collectAsState()
        load(id, alternate)
        Box {
            KamelImage(
                modifier = Modifier.background(Color.Transparent).fillMaxSize(),
                contentScale = ContentScale.FillBounds,
                resource = asyncPainterResource(data = media.value?.FileURL ?: ""),
                contentDescription = "Image",
                onLoading = { progress ->
                    CircularProgressIndicator(
                        progress = { progress },
                    )
                },
                onFailure = { exception ->
                    onFail()
                }
            )
        }
    }

}