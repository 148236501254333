package presentation.screens.business.connection.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.ImageView
import feature.app.NavigationParam
import feature.business.model.Business
import feature.business.utill.BusinessUtill
import feature.businessConnection.model.BusinessConnection
import feature.businessConnection.viewModel.BusinessConnectionViewModel

@Composable
fun BusinessConnectedCard(
    param: NavigationParam?,
    connection: BusinessConnection,
    viewModel: BusinessConnectionViewModel,
    onClick: (Business) -> Unit = {}
) {
    val business = mutableStateOf(Business())
    LaunchedEffect(key1 = Unit) {
        viewModel.loadConnectedBusiness(connection) {
            if (it != null) {
                business.value = it
            }
        }
    }

    Box(
        modifier = Modifier.fillMaxWidth().padding(8.dp).height(100.dp)
            .background(Color.Transparent)
    ) {
        ElevatedCard(
            modifier = Modifier.height(100.dp).fillMaxWidth().padding(8.dp),
            colors = CardDefaults.cardColors(
                containerColor = Color.Transparent,
                contentColor = Color.Transparent
            ),
            onClick = { onClick(business.value) }
        ) {
            Row(
                modifier = Modifier
                    .background(Color.White)
                    .fillMaxWidth()
                    .fillMaxHeight(),
                verticalAlignment = Alignment.CenterVertically,

                ) {
                Box(
                    modifier = Modifier.padding(8.dp).fillMaxHeight().width(60.dp)
                        .background(Color.Transparent),
                ) {
                    if (param?.business?.Id == connection.fromBusinessId) {
                        ImageView(id = connection.toBusinessId)
                    } else {
                        ImageView(id = connection.fromBusinessId)
                    }

                }
                Column {
                    Text(
                        text = business.value.Name ?: "",
                        fontSize = 17.sp,
                        textAlign = TextAlign.Start,
                        color = Color.Black,
                        fontStyle = FontStyle.Normal,
                        fontWeight = FontWeight(600)
                    )
                    Text(
                        text = BusinessUtill().getAddressToDisplayInSingleLine(business.value),
                        fontSize = 15.sp,
                        textAlign = TextAlign.Start,
                        color = Color.Black,
                        maxLines = 2
                    )
                }
            }
        }
    }
}
