package com.friendly.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class ProductSubCategoryTable(
  public val _id: String,
  public val ProductSubCategoryID: Long?,
  public val ProductCategoryID: String?,
  public val UserID: String?,
  public val BusinessID: String?,
  public val IsDeleted: Boolean?,
  public val Name: String?,
  public val Image: String?,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
)
