package feature.product.model

import feature.app.NavigationParam
import feature.cart.model.CartItem
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Product(
    @SerialName("_id") var Id: String = "",
    @SerialName("ProductID") var ProductID: Long = 0,
    @SerialName("UserID") var UserID: String = "",
    @SerialName("BusinessID") var BusinessID: String = "",
    @SerialName("CategoryID") var CategoryID: String? = null,
    @SerialName("SubCategoryID") var SubCategoryID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean = false,
    @SerialName("Name") var Name: String? = "",
    @SerialName("Description") var Description: String? = "",
    @SerialName("ManageInventory") var ManageInventory: Boolean = false,
    @SerialName("ProductPrice") var ProductPrice: ProductPrice = ProductPrice(),
    @SerialName("CreatedAt") var CreatedAt: String? = "",
    @SerialName("UpdatedAt") var UpdatedAt: String? = "",
    @SerialName("__v") var _v: Int? = null
) {
    fun toCartItem(param: NavigationParam?): CartItem {
        return CartItem(
            uniqueID = this.ProductID,
            _id = "",
            userID = this.UserID,
            businessID = this.BusinessID,
            selectedBusinessID = this.BusinessID,
            customerId = param?.customer?.Id,
            productId = this.Id,
            product = this,
            price = this.ProductPrice,
            quantity = 1,
            finalPrice = this.ProductPrice.FinalPrice ?: 0.0,
        )
    }

    fun toCartItem(): CartItem {
        return CartItem(
            uniqueID = this.ProductID,
            _id = "",
            userID = null,
            businessID = null,
            selectedBusinessID = null,
            customerId = null,
            productId = this.Id,
            product = this,
            price = this.ProductPrice,
            quantity = 1,
            finalPrice = this.ProductPrice.FinalPrice ?: 0.0,
        )
    }


}