package feature.inventory.network.brand

import feature.inventory.model.brand.Brand
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveBrandResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<Brand>,
)

@Serializable
data class CreateBrandResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: Brand?,
)

@Serializable
data class UpdateBrandResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: Brand?,
)