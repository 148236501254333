package feature.employee.network

import feature.auth.model.FriendlyProfile
import feature.auth.model.FriendlyUser
import feature.customer.model.Customer
import feature.employee.model.Employee
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FindUserResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: FriendlyProfile?,
)
@Serializable
data class FindEmployeeResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: Customer?,
)

@Serializable
data class FetchEmployeeResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: ArrayList<Employee>,
)

@Serializable
data class CreateEmployeeResponse(
    @SerialName("Payload") val payload: Employee,

)


@Serializable
data class UpdateEmployeeResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: Employee?,
)