package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.customerTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class CustomerTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Address: String?,
    GSTNumber: String?,
    Barcode: String?,
    BarCodeImage: String?,
    Status: String?,
    EmailID: String?,
    DialCode: String?,
    MobileNumber: String?,
    WhatsApp: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    TotalSale: Long?,
    TotalPayment: Long?,
    LastSeen: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getBoolean(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getString(19),
      cursor.getString(20),
      cursor.getString(21)
    )
  }

  public fun retrieve(BusinessID: String?): Query<CustomerTable> = retrieve(BusinessID) { _id,
      UserID, BusinessID_, IsDeleted, Name, Address, GSTNumber, Barcode, BarCodeImage, Status,
      EmailID, DialCode, MobileNumber, WhatsApp, Gender, DeviceID, FCMToken, TotalSale,
      TotalPayment, LastSeen, CreatedAt, UpdatedAt ->
    CustomerTable(
      _id,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      Address,
      GSTNumber,
      Barcode,
      BarCodeImage,
      Status,
      EmailID,
      DialCode,
      MobileNumber,
      WhatsApp,
      Gender,
      DeviceID,
      FCMToken,
      TotalSale,
      TotalPayment,
      LastSeen,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> search(
    BusinessID: String?,
    Name: String,
    Barcode: String,
    MobileNumber: String,
    mapper: (
      _id: String,
      UserID: String?,
      BusinessID: String?,
      IsDeleted: Boolean?,
      Name: String?,
      Address: String?,
      GSTNumber: String?,
      Barcode: String?,
      BarCodeImage: String?,
      Status: String?,
      EmailID: String?,
      DialCode: String?,
      MobileNumber: String?,
      WhatsApp: String?,
      Gender: Long?,
      DeviceID: String?,
      FCMToken: String?,
      TotalSale: Long?,
      TotalPayment: Long?,
      LastSeen: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = SearchQuery(BusinessID, Name, Barcode, MobileNumber) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getBoolean(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getString(19),
      cursor.getString(20),
      cursor.getString(21)
    )
  }

  public fun search(
    BusinessID: String?,
    Name: String,
    Barcode: String,
    MobileNumber: String,
  ): Query<CustomerTable> = search(BusinessID, Name, Barcode, MobileNumber) { _id, UserID,
      BusinessID_, IsDeleted, Name_, Address, GSTNumber, Barcode_, BarCodeImage, Status, EmailID,
      DialCode, MobileNumber_, WhatsApp, Gender, DeviceID, FCMToken, TotalSale, TotalPayment,
      LastSeen, CreatedAt, UpdatedAt ->
    CustomerTable(
      _id,
      UserID,
      BusinessID_,
      IsDeleted,
      Name_,
      Address,
      GSTNumber,
      Barcode_,
      BarCodeImage,
      Status,
      EmailID,
      DialCode,
      MobileNumber_,
      WhatsApp,
      Gender,
      DeviceID,
      FCMToken,
      TotalSale,
      TotalPayment,
      LastSeen,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Address: String?,
    GSTNumber: String?,
    Barcode: String?,
    BarCodeImage: String?,
    Status: String?,
    EmailID: String?,
    DialCode: String?,
    MobileNumber: String?,
    WhatsApp: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    TotalSale: Long?,
    TotalPayment: Long?,
    LastSeen: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(893_598_341, """
        |INSERT OR REPLACE INTO CustomerTable(_id, UserID, BusinessID, IsDeleted, Name, Address,GSTNumber,Barcode,BarCodeImage,Status,EmailID,DialCode,MobileNumber,WhatsApp,Gender,DeviceID,FCMToken,TotalSale,TotalPayment,LastSeen,CreatedAt,UpdatedAt)
        |                   VALUES(?, ?, ?, ?, ? ,?, ?, ?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)
        """.trimMargin(), 22) {
          bindString(0, _id)
          bindString(1, UserID)
          bindString(2, BusinessID)
          bindBoolean(3, IsDeleted)
          bindString(4, Name)
          bindString(5, Address)
          bindString(6, GSTNumber)
          bindString(7, Barcode)
          bindString(8, BarCodeImage)
          bindString(9, Status)
          bindString(10, EmailID)
          bindString(11, DialCode)
          bindString(12, MobileNumber)
          bindString(13, WhatsApp)
          bindLong(14, Gender)
          bindString(15, DeviceID)
          bindString(16, FCMToken)
          bindLong(17, TotalSale)
          bindLong(18, TotalPayment)
          bindString(19, LastSeen)
          bindString(20, CreatedAt)
          bindString(21, UpdatedAt)
        }.await()
    notifyQueries(893_598_341) { emit ->
      emit("CustomerTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CustomerTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CustomerTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CustomerTable._id, CustomerTable.UserID, CustomerTable.BusinessID, CustomerTable.IsDeleted, CustomerTable.Name, CustomerTable.Address, CustomerTable.GSTNumber, CustomerTable.Barcode, CustomerTable.BarCodeImage, CustomerTable.Status, CustomerTable.EmailID, CustomerTable.DialCode, CustomerTable.MobileNumber, CustomerTable.WhatsApp, CustomerTable.Gender, CustomerTable.DeviceID, CustomerTable.FCMToken, CustomerTable.TotalSale, CustomerTable.TotalPayment, CustomerTable.LastSeen, CustomerTable.CreatedAt, CustomerTable.UpdatedAt FROM CustomerTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "CustomerTable.sq:retrieve"
  }

  private inner class SearchQuery<out T : Any>(
    public val BusinessID: String?,
    public val Name: String,
    public val Barcode: String,
    public val MobileNumber: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CustomerTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CustomerTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CustomerTable._id, CustomerTable.UserID, CustomerTable.BusinessID, CustomerTable.IsDeleted, CustomerTable.Name, CustomerTable.Address, CustomerTable.GSTNumber, CustomerTable.Barcode, CustomerTable.BarCodeImage, CustomerTable.Status, CustomerTable.EmailID, CustomerTable.DialCode, CustomerTable.MobileNumber, CustomerTable.WhatsApp, CustomerTable.Gender, CustomerTable.DeviceID, CustomerTable.FCMToken, CustomerTable.TotalSale, CustomerTable.TotalPayment, CustomerTable.LastSeen, CustomerTable.CreatedAt, CustomerTable.UpdatedAt FROM CustomerTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND (Name LIKE ? COLLATE NOCASE OR Barcode LIKE ? OR MobileNumber LIKE ?)""",
        mapper, 4) {
      bindString(0, BusinessID)
      bindString(1, Name)
      bindString(2, Barcode)
      bindString(3, MobileNumber)
    }

    override fun toString(): String = "CustomerTable.sq:search"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CustomerTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CustomerTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM CustomerTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "CustomerTable.sq:lastSyncDate"
  }
}
