package feature.product.viewModel

import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.businessConfig.model.Currency
import feature.product.handler.ProductHandler
import feature.product.model.Product
import feature.product.model.ProductBarCode
import feature.product.model.ProductPrice
import feature.product.network.CreateProductRequest
import feature.product.network.DeleteProductRequest
import feature.product.network.ProductNetwork
import feature.product.network.RetrieveProductRequest
import feature.product.network.UpdateProductRequest
import feature.productCategory.handler.ProductCategoryHandler
import feature.productCategory.model.ProductCategory
import feature.productSubCategory.handler.ProductSubCategoryHandler
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import storage.database.table.inventory.product.ProductDatabaseTable

class ProductViewModel : AppViewModel() {
    val repository = ProductHandler.shared().repository
    val searchQuery: StateFlow<String>
        get() = repository.searchQuery
    val allProduct: StateFlow<List<Product>>
        get() = ProductHandler.shared().repository.allProduct
    val newProduct: StateFlow<Product>
        get() = ProductHandler.shared().repository.newProduct
    val editProduct: StateFlow<Product>
        get() = ProductHandler.shared().repository.editProduct


    val productFormIndex: StateFlow<Int>
        get() = ProductHandler.shared().repository.productFormIndex

    val selectedProductState: MutableStateFlow<Product?> = MutableStateFlow(null)
    val selectedProduct: StateFlow<Product?>
        get() = repository.selectedProduct

    fun setProductFormIndex(value: Int) {
        repository.setProductFormIndex(value)
    }

    override fun changeSearchQuery(value: String) {
        scope.launch {
            repository.searchQueryState.emit(value)
        }
        performSearch(value)
    }

    override fun performSearch(value: String) {
        if (value.isEmpty()) {
            loadProductsFromStorage()
        } else {
            ProductDatabaseTable().search(
                BusinessHandler.shared().businessId,
                value
            ) { data ->
                scope.launch {
                    repository.allProductState.emit(data)
                }
            }
        }
    }

    fun setSelectedProduct(value: Product) {
        scope.launch {
            repository.selectedProductState.emit(value)
        }
    }

    fun setEditProduct(value: Product?) {
        if (value != null) {
            repository.setEditProduct(value)
        }
    }

    fun editProductName(value: String) {
        repository.editProduct.value.Name = value
    }

    fun editProductSalePrice(value: String) {
        repository.editProduct.value.ProductPrice.FinalPrice = value.toDouble()
    }

    fun setNewProduct(value: Product) {
        repository.setNewProduct(value)
    }

    fun setNewProductName(value: String) {
        val existingProduct = repository.newProduct.value
        existingProduct.Name = value
        repository.setNewProduct(existingProduct)
    }

    fun setNewProductDescription(value: String) {
        repository.newProduct.value.Description = value
    }

    fun setNewProductCategory(value: ProductCategory) {
        repository.newProduct.value.CategoryID = value.Id
//        val product = repository.newProduct.value
//        product.CategoryID = value.Id
//        repository.setNewProduct(product)
    }

    fun setNewProductMrpPrice(value: String) {
        repository.newProduct.value.ProductPrice.MRP = value.toDouble()
    }

    fun setNewProductCostPricePrice(value: String) {
        repository.newProduct.value.ProductPrice.FinalPrice = value.toDouble()
    }

    fun setNewProductFinalPricePrice(value: String) {
        repository.newProduct.value.ProductPrice.FinalPrice = value.toDouble()
    }

    fun setNewProductSGST(value: String) {
        repository.newProduct.value.ProductPrice.SGST = value.toDouble()
    }

    fun setNewProductCGST(value: String) {
        repository.newProduct.value.ProductPrice.CGST = value.toDouble()
    }

    fun setNewProductIGST(value: String) {
        repository.newProduct.value.ProductPrice.IGST = value.toDouble()
    }

    fun setNewProductCESS(value: String) {
        repository.newProduct.value.ProductPrice.CESS = value.toDouble()
    }

    fun setNewProductVAT(value: String) {
        repository.newProduct.value.ProductPrice.VAT = value.toDouble()
    }

    fun setNewProductOtherTax(value: String) {
        repository.newProduct.value.ProductPrice.OtherTax = value.toDouble()
    }

    fun createProduct(
        callBack: (Boolean) -> Unit
    ) {
        val request = CreateProductRequest(
            Clock.System.now().epochSeconds,
            AuthHandler.shared().repository.authUser.value.id,
            BusinessHandler.shared().repository.business.value.Id,
            categoryID = ProductCategoryHandler.shared().repository.selectedCategory.value?.Id,
            subCategoryID = ProductSubCategoryHandler.shared().repository.selectedSubCategory.value?.Id,
            name = repository.newProduct.value.Name ?: "",
            description = repository.newProduct.value.Description ?: "",
            productPrice = repository.newProduct.value.ProductPrice
        )
        startLoading()
        scope.launch {
            ProductNetwork().create(request) {
                stopLoading()
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    ProductDatabaseTable().insert(it?.payload)
                    callBack(true)
                    scope.launch {
                        repository.newProductState.emit(it!!.payload!!)
                    }
                } else {
                    callBack(false)
                }
            }
        }
    }

    fun createNewProduct(
        isProduct: Boolean,
        name: String,
        description: String,
        categoryId: String?,
        subCategoryId: String?,
        costPrice: Double?,
        mrp: Double?,
        discount: Double?,
        salePrice: Double?,
        finalPrice: Double?,
        cgst: Double?,
        sgst: Double?,
        igst: Double?,
        currency: Currency,
        callBack: (Boolean) -> Unit
    ) {
        val productPrice = ProductPrice(
            CostPrice = costPrice,
            Price = salePrice,
            MRP = mrp,
            FinalPrice = finalPrice,
            SGST = sgst,
            CGST = cgst,
            IGST = igst,
            Discount = discount,
            currency = currency
        )
        var productType = "Product"
        if (!isProduct) {
            productType = "Service"
        }
        val request = CreateProductRequest(
            Clock.System.now().epochSeconds,
            productType = productType,
            AuthHandler.shared().userId,
            BusinessHandler.shared().businessId,
            categoryID = categoryId,
            subCategoryID = subCategoryId,
            name = name,
            description = description,
            productPrice = productPrice
        )
        startLoading()
        scope.launch {
            ProductNetwork().create(request) {
                stopLoading()
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    ProductDatabaseTable().insert(it?.payload)
                    callBack(true)
                    scope.launch {
                        repository.newProductState.emit(it!!.payload!!)
                    }
                } else {
                    callBack(false)
                }
            }
        }
    }

    fun updateProduct(
        _id: String,
        isProduct: Boolean,
        name: String,
        description: String,
        categoryId: String?,
        subCategoryId: String?,
        costPrice: Double?,
        mrp: Double?,
        discount: Double?,
        salePrice: Double?,
        finalPrice: Double?,
        cgst: Double?,
        sgst: Double?,
        igst: Double?,
        currency: Currency,
        callBack: (Boolean) -> Unit
    ) {
        val productPrice = ProductPrice(
            CostPrice = costPrice,
            Price = salePrice,
            MRP = mrp,
            FinalPrice = finalPrice,
            SGST = sgst,
            CGST = cgst,
            IGST = igst,
            Discount = discount,
            currency = currency
        )
        var productType = "Product"
        if (!isProduct) {
            productType = "Service"
        }
        var categoryIdToSend: String? = null
        var subCategoryIdToSend: String? = null
        if (categoryId.isNullOrEmpty()) {
            categoryIdToSend = null
        }
        if (subCategoryId.isNullOrEmpty()) {
            subCategoryIdToSend = null
        }
        val request = UpdateProductRequest(
            _id = _id,
            Clock.System.now().epochSeconds,
            productType = productType,
            AuthHandler.shared().userId,
            BusinessHandler.shared().businessId,
            categoryID = categoryIdToSend,
            subCategoryID = subCategoryIdToSend,
            name = name,
            description = description,
            productPrice = productPrice
        )
        startLoading()
        scope.launch {
            ProductNetwork().update(request) {
                stopLoading()
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    ProductDatabaseTable().insert(it?.payload)
                    callBack(true)
                    scope.launch {
                        repository.newProductState.emit(it!!.payload!!)
                    }
                } else {
                    callBack(false)
                }
            }
        }
    }

    fun loadProduct() {
        loadProductsFromStorage()
        ProductDatabaseTable().lastSyncDate(BusinessHandler.shared().businessId) { date ->
            val request = RetrieveProductRequest(
                BusinessHandler.shared().businessId,
                date
            )
            if (allProduct.value.isEmpty()) {
                startLoading()
            }
            ProductNetwork().retrieve(request) {
                stopLoading()
                ProductDatabaseTable().insert(it?.payload) {
                    loadProductsFromStorage()
                }
            }
        }
    }

    fun loadProductForBusiness(id: String) {
        loadProductsFromStorage()
        startLoading()
        ProductDatabaseTable().lastSyncDate(id) { date ->
            val request = RetrieveProductRequest(
                id,
                date
            )
            ProductNetwork().retrieve(request) {
                ProductDatabaseTable().insert(it?.payload) {
                    stopLoading()
                    loadProductsFromStorage(id)
                }
            }
        }
    }

    fun searchProductsForBusiness(id: String, query: String) {
        ProductDatabaseTable().search(id, query) { data ->
            scope.launch {
                repository.allProductState.emit(data)
            }
        }
    }

    private fun loadProductsFromStorage() {
        ProductDatabaseTable().retrieve(BusinessHandler.shared().businessId) { data ->
            scope.launch {
                repository.allProductState.emit(data)
            }
        }
    }

    private fun loadProductsFromStorage(id: String) {
        ProductDatabaseTable().retrieve(id) { data ->
            scope.launch {
                repository.allProductState.emit(data)
            }
        }
    }

    fun loadProductForCategory(id: String?, categoryId: String?) {
        if (id != null && categoryId != null) {
            ProductDatabaseTable().retrieve(id, categoryId) { data ->
                scope.launch {
                    repository.allProductState.emit(data)
                }
            }
        } else {
            scope.launch {
                repository.allProductState.emit(arrayListOf())
            }
        }
    }


    fun loadProductForSubCategory(id: String?, subcategoryId: String?) {
        if (id != null && subcategoryId != null) {
            ProductDatabaseTable().retrieveSubCategory(id, subcategoryId) { data ->
                scope.launch {
                    repository.allProductState.emit(data)
                }
            }
        } else {
            scope.launch {
                repository.allProductState.emit(arrayListOf())
            }
        }
    }

    fun loadProductBarCode(product: Product) {
//        repository.productBarCodeLiveData.postValue(arrayListOf())
//        scope.launch {
//            val fetchedBarCodes =
//                DatabaseHandler.shared().database.productBarCodeDao().getForProduct(product.Id)
//            if (fetchedBarCodes.isNotEmpty()) {
//                repository.productBarCodeLiveData.postValue(fetchedBarCodes as ArrayList<ProductBarCode>)
//            }
//        }
    }

    fun findBarcodeById(barcode: String, callBack: (ProductBarCode?) -> Unit) {
//        scope.launch {
//            val fetchedBarCode =
//                DatabaseHandler.shared().database.productBarCodeDao().findById(barcode)
//            callBack(fetchedBarCode)
//        }
    }

    fun findProductByBarCode(barcode: String, callBack: (Product?) -> Unit) {
//        scope.launch {
//            findBarcodeById(barcode) { barcode ->
//                if (barcode != null && !barcode.ProductID.isNullOrEmpty()) {
//                    scope.launch {
//                        val foundProduct = DatabaseHandler.shared().database.productDao()
//                            .findById(barcode.ProductID)
//                        callBack(foundProduct)
//                    }
//                }
//            }
//        }
    }

    //
//    fun updateProduct(request: JSONObject) {
//        SocketService.shared().send(SocketEvent.UPDATE_PRODUCT, request)
//    }
//
//    fun updateProductImage(product: Product, image: String) {
//        val user = FriendlyUser()
//        val request = JSONObject()
//        val business = BusinessHandler.shared().repository.business
//        request.put(KeyConstant.businessID, business.value?.Id)
//        request.put(KeyConstant.fileURL, image)
//        request.put(KeyConstant._id, product.Id)
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        request.put(KeyConstant.featureObjectID, product.Id)
//        MediaFileHandler.shared().viewModel?.createNew(request)
//    }
//
    fun deleteProduct(product: Product, callBack: (Boolean) -> Unit) {
        val request = DeleteProductRequest(
            _id = product.Id,
        )
        startLoading()
        scope.launch {
            ProductNetwork().delete(request) {
                stopLoading()
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    ProductDatabaseTable().insert(it?.payload)
                    callBack(true)
                    ProductDatabaseTable().insert(it?.payload)
                } else {
                    callBack(false)
                }
            }
        }
    }
//
//    fun removeStockQuantity(product: Product, quantity: Int, message: String) {
//        val user = FriendlyUser()
//        val request = JSONObject()
//        val business = BusinessHandler.shared().repository.business
//        request.put(KeyConstant.businessID, business.value?.Id)
//        request.put(KeyConstant.productID, product.Id)
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.quantity, quantity)
//        request.put(KeyConstant.comment, message)
//        SocketService.shared().send(SocketEvent.REMOVE_STOCK_QUANTITY, request)
//    }
//
//    fun addStockQuantity(product: Product, quantity: Int, message: String) {
//        val user = FriendlyUser()
//        val request = JSONObject()
//        val business = BusinessHandler.shared().repository.business
//        request.put(KeyConstant.businessID, business.value?.Id)
//        request.put(KeyConstant.productID, product.Id)
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.quantity, quantity)
//        request.put(KeyConstant.comment, message)
//        SocketService.shared().send(SocketEvent.ADD_STOCK_QUANTITY, request)
//    }
//
//    fun resetStockQuantity(product: Product, quantity: Int, message: String) {
//        val user = FriendlyUser()
//        val request = JSONObject()
//        val business = BusinessHandler.shared().repository.business
//        request.put(KeyConstant.businessID, business.value?.Id)
//        request.put(KeyConstant.productID, product.Id)
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.quantity, quantity)
//        request.put(KeyConstant.comment, message)
//        SocketService.shared().send(SocketEvent.RESET_STOCK_QUANTITY, request)
//    }
//
//    fun insertStock(stockEntry: ProductStock) {
//        scope.launch {
//            DatabaseHandler.shared().database.productStockDao()
//                .insert(stockEntry)
//        }
//    }
//
//    fun insertProduct(product: Product) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.productDao()
//                .insert(product)
//        }
//    }
//
//    fun createBarCode(product: Product, code: String) {
//        val unixTime = System.currentTimeMillis()
//        val user = FriendlyUser()
//        val request = JSONObject()
//        val business = BusinessHandler.shared().repository.business
//        request.put(KeyConstant.uniqueId, unixTime)
//        request.put(KeyConstant.businessID, business.value?.Id)
//        request.put(KeyConstant.productID, product.Id)
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.barcode, code)
//        SocketService.shared().send(SocketEvent.CREATE_PRODUCT_BAR_CODE, request)
//    }
//
//    fun insertProductBarCode(barCode: ProductBarCode) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.productBarCodeDao()
//                .insert(barCode)
//        }
//    }


}
