package presentation.screens.business.inventory.category.component

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.RadioButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.ImageView
import feature.productCategory.model.ProductCategory
import locale.localization
import theme.ThemeColor

@Composable
fun SelectCategoryCard(
    category: ProductCategory?,
    selectedCategory: ProductCategory?,
    onClick: (category: ProductCategory?) -> Unit
) {
    val isSelected = selectedCategory?.Id == category?.Id
    ElevatedCard(
        modifier = Modifier.height(60.dp).fillMaxWidth().padding(8.dp),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black
        ),
        onClick = { onClick(category) }
    ) {
        if (category?.Id?.isNotEmpty() == true) {
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(start = 15.dp, end = 15.dp),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                RadioButton(isSelected, { onClick(category) })
                Box(modifier = Modifier.width(40.dp).height(40.dp)) {
                    ImageView(category.Id, true)
                }
                Text(
                    modifier = Modifier.weight(1f).padding(end = 8.dp),
                    text = category.Name ?: "", fontSize = 14.sp, textAlign = TextAlign.Start
                )
            }
        } else {
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(start = 15.dp, end = 15.dp),
                verticalAlignment = Alignment.CenterVertically,
            ) { //
                Text(
                    modifier = Modifier.weight(1f).padding(end = 8.dp),
                    text = localization().categoryMenu.categorySelect,
                    fontSize = 14.sp,
                    textAlign = TextAlign.Center
                    //"Tap To Select Category"
                )
            }
        }
    }
}