package feature.employee.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class JobRole(
    @SerialName("_id") var Id: String = "",
    @SerialName("Name") var Name: String? = null,
    @SerialName("Description") var Description: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var __v: Long? = null,
)


