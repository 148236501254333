package presentation.screens.business.invoice.detail

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.loader.AppLoader
import component.navigation.TopNavBar
import engine.date.DateEngine
import feature.business.handler.BusinessHandler
import feature.business.utill.AddressUtill
import feature.invoice.viewModel.InvoiceViewModel
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.invoice.component.InvoiceBarcodeRow
import presentation.screens.business.invoice.component.InvoiceFooter
import presentation.screens.business.invoice.component.InvoiceItemCard
import presentation.screens.business.invoice.component.ShareInvoiceCard
import theme.ThemeColor

class BusinessInvoiceDetailScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = InvoiceViewModel()
        val invoice = viewModel.customerInvoice.collectAsState()
        LaunchedEffect(key1 = Unit) {
            if (navigationParam?.id?.isNotEmpty() == true) {
                viewModel.fetchSingleInvoice(navigationParam!!.id!!)
            }
        }
        Column(
            modifier = Modifier.fillMaxWidth().fillMaxHeight()
                .background(color = ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(
                BusinessHandler.shared().repository.business.value.Name ?: "Invoice Details",
                navigationParam,
                onBackPress = {
                    navigator?.pop()
                }
            )
            AppLoader(viewModel)
            LazyColumn(
                modifier = Modifier
                    .fillMaxWidth().fillMaxHeight().weight(1f).padding(8.dp)
                    .background(color = ThemeColor.shared().defaultBakground)
            ) {
                item {
                    Box(
                        modifier = Modifier
                            .height(80.dp)
                            .padding(bottom = 8.dp)
                            .background(
                                color = ThemeColor.shared().cardBackground,
                                RoundedCornerShape(10.dp)
                            )
                    ) {
                        Row(
                            modifier = Modifier
                                .padding(start = 8.dp, top = 4.dp, end = 8.dp)
                                .fillMaxWidth()
                                .fillMaxHeight()
                                .background(
                                    color = ThemeColor.shared().cardBackground,
                                    RoundedCornerShape(10.dp)
                                ),
                            verticalAlignment = Alignment.CenterVertically,

                            ) {
                            Column {
                                Text(
                                    text = "INV #${invoice.value?.invoiceNumber}",
                                    fontWeight = FontWeight(600),
                                    fontSize = 18.sp,
                                    textAlign = TextAlign.Center,
                                )
                                Text(
                                    text = "Invoice Date: ${DateEngine().displayableDateTime(invoice.value?.updatedAt ?: "")}",
                                    fontSize = 12.sp,
                                    textAlign = TextAlign.Center,
                                )
                            }
                        }
                    }
                    Box(
                        modifier = Modifier
                            .height(120.dp)
                            .padding(0.dp)
                            .background(
                                color = ThemeColor.shared().cardBackground,
                                RoundedCornerShape(10.dp)
                            )
                    ) {
                        Row(
                            modifier = Modifier
                                .padding(start = 8.dp, top = 4.dp, end = 8.dp)
                                .fillMaxWidth()
                                .fillMaxHeight()
                                .background(color = ThemeColor.shared().cardBackground),
                            verticalAlignment = Alignment.CenterVertically,
                        ) {
                            Column {
                                Text(
                                    text = "from",
                                    fontSize = 12.sp,
                                    textAlign = TextAlign.Center,
                                )
                                Text(
                                    text = BusinessHandler.shared().repository.business.value.Name
                                        ?: "",
                                    fontWeight = FontWeight(600),
                                    fontSize = 18.sp,
                                    textAlign = TextAlign.Center,
                                )
                                Text(
                                    text = "Mobile: ${BusinessHandler.shared().repository.business.value.DialCode} ${BusinessHandler.shared().repository.business.value.mobileNumber}",
                                    fontSize = 12.sp,
                                    textAlign = TextAlign.Center,
                                )
                                Spacer(modifier = Modifier.height(4.dp))
                                Text(
                                    text = "Address: ${
                                        AddressUtill().getAddressToDisplayInSingleLine(
                                            BusinessHandler.shared().repository.business.value.Address
                                        )
                                    }",
                                    fontSize = 12.sp,
                                    textAlign = TextAlign.Left,
                                )
                            }
                        }
                    }
                    Box(
                        modifier = Modifier
                            .height(120.dp)
                            .padding(4.dp)
                            .background(
                                color = ThemeColor.shared().cardBackground,
                                RoundedCornerShape(10.dp)
                            )
                    ) {
                        Row(
                            modifier = Modifier
                                .padding(start = 8.dp, top = 4.dp, end = 8.dp)
                                .fillMaxWidth()
                                .fillMaxHeight()
                                .background(color = ThemeColor.shared().White),
                            verticalAlignment = Alignment.CenterVertically,
                        ) {
                            Column {
                                Text(
                                    text = "to",
                                    fontSize = 12.sp,
                                    textAlign = TextAlign.Center,
                                )
                                Text(
                                    text = invoice.value?.customerName ?: "",
                                    fontWeight = FontWeight(600),
                                    fontSize = 18.sp,
                                    textAlign = TextAlign.Center,
                                )
                                Text(
                                    text = "Mobile: ${invoice.value?.DialCode} ${invoice.value?.mobileNumber}",
                                    fontSize = 12.sp,
                                    textAlign = TextAlign.Center,
                                )
//                                Spacer(modifier = Modifier.height(4.dp))
//                                Text(
//                                    text = "Address: ${invoice.value}",
//                                    fontSize = 12.sp,
//                                    textAlign = TextAlign.Left,
//                                )
                            }
                        }
                    }
                    Box(
                        modifier = Modifier
                            .padding(8.dp)
                            .background(
                                color = ThemeColor.shared().cardBackground,
                                RoundedCornerShape(8.dp)
                            )
                    ) {
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .fillMaxHeight()
                                .padding(start = 8.dp, top = 4.dp, end = 8.dp)
                                .background(color = ThemeColor.shared().cardBackground),
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Text(
                                text = "Detail Items",
                                fontWeight = FontWeight(600),
                                fontSize = 18.sp,
                                textAlign = TextAlign.Center,
                            )
                        }
                        Spacer(
                            modifier = Modifier.height(10.dp)
                                .fillMaxHeight()
                                .fillMaxWidth()
                                .background(color = ThemeColor.shared().White),
                        )
                        Row(
                            modifier = Modifier
                                .background(
                                    color = ThemeColor.shared().White,
                                    RoundedCornerShape(8.dp)
                                )
                                .padding(start = 8.dp, top = 4.dp, end = 8.dp)
                                .fillMaxHeight()
                                .fillMaxWidth(),
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            Text(
                                text = "ITEMS",
                                modifier = Modifier.fillMaxWidth(.45F),
                                fontSize = 12.sp,
                                textAlign = TextAlign.Left,
                            )
                            Text(
                                text = "TAX",
                                fontSize = 12.sp,
                                textAlign = TextAlign.Left,
                            )
                            Text(
                                text = "TOTAL",
                                fontSize = 12.sp,
                                textAlign = TextAlign.Left,
                            )
                        }
                    }
                }
                invoice.value?.sales?.count()?.let { allSales ->
                    items(allSales) {
                        InvoiceItemCard(invoice.value!!.sales!![it])
                    }
                }
                item {
                    InvoiceFooter(invoice.value)
                }
                item {
                    InvoiceBarcodeRow(invoice.value)
                }
            }

            ShareInvoiceCard(invoice.value)
        }
    }
}
