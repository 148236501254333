package feature.mediaFile.network

import feature.mediaFile.model.MediaFile
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MediaFileRetrieveResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<MediaFile>,
)

@Serializable
data class MediaFileCreateResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: MediaFile?,
)

@Serializable
data class MediaFileUploadResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: MediaFile?,
)