package feature.mediaFile.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MediaFile(
    @SerialName("UniqueID") var UniqueID: Long = 0,
    @SerialName("_id") var _id: String = "",
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("BusinessID") var BusinessID: String? = null,
    @SerialName("FeatureObjectID") var FeatureObjectID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean = false,
    @SerialName("FileURL") var FileURL: String? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)