package presentation.screens.business.inventory.sub_category.create

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import component.input.CustomTextField
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.productCategory.viewModel.ProductCategoryViewModel
import feature.productSubCategory.viewModel.ProductSubCategoryViewModel
import locale.localization
import presentation.screens.business.inventory.category.component.SelectCategoryCard
import presentation.screens.business.inventory.category.select.SelectCategoryScreen
import theme.ThemeColor

class CreateSubCategoryScreen : Screen {


    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = ProductSubCategoryViewModel()
        val categoryViewModel = ProductCategoryViewModel()
        val selectedCategory = categoryViewModel.selectedCategory.collectAsState()

        var name by remember {
            mutableStateOf(TextFieldValue(""))
        }
        val nameErrorState = remember { mutableStateOf(false) }
        Column(
            modifier = Modifier.fillMaxSize()
                .background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(title = localization().subCategory.subCategoryTitle, null, onBackPress = {
                navigator?.pop()
            })

            Spacer(modifier = Modifier.height(50.dp))
            SelectCategoryCard(
                selectedCategory.value,
                selectedCategory.value
            ) {
                if (it != null) {
                    categoryViewModel.selectCategory(it)
                }
                navigator?.push(SelectCategoryScreen())
            }

            CustomTextField(
                value = name.text,
                placeholder = localization().subCategory.subCategoryNamePlaceholder,
                trailingIcon = ImageAsset.person,
                label = localization().subCategory.subCategoryName,
                errorState = nameErrorState.value,
                keyboardType = KeyboardType.Email,
                visualTransformation = VisualTransformation.None
            ) { newEmail ->
                name = newEmail
            }
            Spacer(modifier = Modifier.height(10.dp))
            CustomDefaultBtn(
                shapeSize = 50f,
                btnText = localization().button.updateButton
            ) { //update button
                val nameErrorState = name.text.length < 4
                if (nameErrorState) {
                    AlertHandler.shared().showAlert(
                        localization().subCategory.subCategoryUpdateAlertTitle,
                        localization().subCategory.subCategoryUpdateAlertMessage
                    )
                } else if (categoryViewModel.selectedCategory.value?.Id?.isNotEmpty() == true) {
                    viewModel.createNewSubCategory(name.text) {
                        navigator?.pop()
                    }
                } else {
                    AlertHandler.shared().showAlert(
                        localization().subCategory.subCategoryUpdateAlertTitle,
                        localization().subCategory.subCategoryAlertSecondMessage
                    )
                }
            }
        }
    }
}