package feature.auth.network

import feature.auth.model.FriendlyUser
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import locale.Localization

@Serializable
data class AuthResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: FriendlyUser?,
)

@Serializable
data class SetPasswordResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
)

@Serializable
data class OTPResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: OTPModel?,
)

@Serializable
data class OTPModel(
    @SerialName("MobileNumber") val mobile: String?,
    @SerialName("OTP") val otp: String,
    @SerialName("DialCode") val dialCode: String?,
)

@Serializable
data class UpdateFCMTokenResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
)

@Serializable
data class LanguageResponse(
    @SerialName("status") val status: String,
//    @SerialName("Message") val message: String,
//    @SerialName("Event") val event: String?,
    @SerialName("Payload") val payload: Localization?,
)

