package feature.inventory.model

import feature.product.model.ProductPrice
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Inventory(
    @SerialName("_id") var Id: String = "",
    @SerialName("InventoryID") var InventoryID: Long = 0,
    @SerialName("ProductID") var ProductID: String = "",
    @SerialName("UserID") var UserID: String = "",
    @SerialName("BusinessID") var BusinessID: String = "",
    @SerialName("CategoryID") var CategoryID: String? = null,
    @SerialName("SubCategoryID") var SubCategoryID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean = false,
    @SerialName("Name") var Name: String? = "",
    @SerialName("Description") var Description: String? = "",
    @SerialName("Model") var Model: String? = "",
    @SerialName("Barcode") var Barcode: String? = "",
    @SerialName("BrandID") var BrandID: String? = "",
    @SerialName("UOMID") var UOMID: String? = "",
    @SerialName("ColorID") var ColorID: String? = "",
    @SerialName("Quantity") var qty: Long? = 0L,
    @SerialName("SizeID") var SizeID: String? = "",
    @SerialName("DiscountID") var DiscountID: String? = "",
    @SerialName("CoupanID") var CoupanID: String? = "",
    @SerialName("TagID") var TagID: String? = "",
    @SerialName("MaxQuantity") var MaxQuantity: Long? = 0,
    @SerialName("MinQuantity") var MinQuantity: Long? = 0,
    @SerialName("StartRange") var StartRange: Long? = 0,
    @SerialName("EndRange") var EndRange: Long? = 0,
    @SerialName("SerialLength") var SerialLength: Long? = 0,
    @SerialName("ShortCode") var ShortCode: String? = "",
    @SerialName("ProductPrice") var ProductPrice: ProductPrice = ProductPrice(),
    @SerialName("CreatedAt") var CreatedAt: String? = "",
    @SerialName("UpdatedAt") var UpdatedAt: String? = "",
    @SerialName("__v") var _v: Int? = null
)