package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class JobRoleTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(mapper: (
    _id: String,
    Name: String?,
    Description: String?,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
  ) -> T): Query<T> = Query(2_141_572_823, arrayOf("JobRoleTable"), driver, "JobRoleTable.sq",
      "retrieve",
      "SELECT JobRoleTable._id, JobRoleTable.Name, JobRoleTable.Description, JobRoleTable.IsDeleted, JobRoleTable.CreatedAt, JobRoleTable.UpdatedAt, JobRoleTable.__v FROM JobRoleTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getBoolean(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getLong(6)
    )
  }

  public fun retrieve(): Query<JobRoleTable> = retrieve { _id, Name, Description, IsDeleted,
      CreatedAt, UpdatedAt, __v ->
    JobRoleTable(
      _id,
      Name,
      Description,
      IsDeleted,
      CreatedAt,
      UpdatedAt,
      __v
    )
  }

  public fun <T : Any> getNewJobRole(mapper: (UpdatedAt: String?) -> T): Query<T> =
      Query(-695_904_690, arrayOf("JobRoleTable"), driver, "JobRoleTable.sq", "getNewJobRole",
      "SELECT UpdatedAt FROM JobRoleTable") { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun getNewJobRole(): Query<GetNewJobRole> = getNewJobRole { UpdatedAt ->
    GetNewJobRole(
      UpdatedAt
    )
  }

  public fun <T : Any> search(mapper: (
    _id: String,
    Name: String?,
    Description: String?,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
  ) -> T): Query<T> = Query(-1_668_030_717, arrayOf("JobRoleTable"), driver, "JobRoleTable.sq",
      "search",
      "SELECT JobRoleTable._id, JobRoleTable.Name, JobRoleTable.Description, JobRoleTable.IsDeleted, JobRoleTable.CreatedAt, JobRoleTable.UpdatedAt, JobRoleTable.__v FROM JobRoleTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getBoolean(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getLong(6)
    )
  }

  public fun search(): Query<JobRoleTable> = search { _id, Name, Description, IsDeleted, CreatedAt,
      UpdatedAt, __v ->
    JobRoleTable(
      _id,
      Name,
      Description,
      IsDeleted,
      CreatedAt,
      UpdatedAt,
      __v
    )
  }

  public suspend fun insert(
    _id: String,
    Name: String?,
    Description: String?,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
  ) {
    driver.execute(-1_945_486_316, """
        |INSERT OR REPLACE INTO JobRoleTable(_id, Name ,Description,IsDeleted,CreatedAt,UpdatedAt,__v)
        |    VALUES(?,?,?,?,?,?,?)
        """.trimMargin(), 7) {
          bindString(0, _id)
          bindString(1, Name)
          bindString(2, Description)
          bindBoolean(3, IsDeleted)
          bindString(4, CreatedAt)
          bindString(5, UpdatedAt)
          bindLong(6, __v)
        }.await()
    notifyQueries(-1_945_486_316) { emit ->
      emit("JobRoleTable")
    }
  }
}
