package presentation.screens.business.invoice.component

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import feature.invoice.model.CustomerInvoice
import friendly.shared.generated.resources.Res
import friendly.shared.generated.resources.message_icon
import friendly.shared.generated.resources.share_icon
import friendly.shared.generated.resources.whatsapp_icon
import native.share.Share
import org.jetbrains.compose.resources.painterResource
import theme.ThemeColor

@Composable
fun ShareInvoiceCard(invoice: CustomerInvoice?) {
    Column(
        modifier = Modifier.fillMaxWidth().height(120.dp).padding(bottom = 60.dp)
            .padding(start = 0.dp, end = 0.dp, top = 4.dp)
            .background(color = ThemeColor.shared().cardBackground)
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().fillMaxHeight().padding(start = 16.dp, end = 16.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Box(modifier = Modifier.height(60.dp).width(120.dp).clickable {
                Share().shareFeature.shareToWhatsapp?.let {
                    it(
                        "${invoice?.DialCode}${invoice?.mobileNumber}",
                        invoice?.ShareLink ?: ""
                    )
                }
            }) {
                Column(
                    modifier = Modifier.fillMaxHeight().fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Image(
                        painter = painterResource(Res.drawable.whatsapp_icon),
                        contentDescription = "",
                        modifier = Modifier.fillMaxWidth().padding(4.dp).weight(1f)
                    )
                    Text("WhatsApp", lineHeight = 20.sp)
                }
            }
            Box(modifier = Modifier.height(60.dp).width(120.dp).clickable {
                Share().shareFeature.shareToMessage?.let {
                    it(
                        "${invoice?.DialCode}${invoice?.mobileNumber}",
                        invoice?.ShareLink ?: ""
                    )
                }
            }) {
                Column(
                    modifier = Modifier.fillMaxHeight().fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Image(
                        painter = painterResource(Res.drawable.message_icon),
                        contentDescription = "",
                        modifier = Modifier.fillMaxWidth().padding(4.dp).weight(1f)
                    )
                    Text("SMS", lineHeight = 20.sp)
                }

            }
            Box(modifier = Modifier.height(60.dp).width(60.dp).clickable {
                Share().shareFeature.shareText.let {
                    it(
                        invoice?.ShareLink ?: ""
                    )
                }
            }) {
                Column(
                    modifier = Modifier.fillMaxHeight().fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Image(
                        painter = painterResource(Res.drawable.share_icon),
                        contentDescription = "",
                        modifier = Modifier.fillMaxWidth().padding(4.dp).weight(1f)
                    )
                    Text("Share", lineHeight = 20.sp)
                }
            }

        }
    }
}