package feature.businessConnection.repository

import feature.business.model.Business
import feature.businessConnection.model.BusinessConnection
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class BusinessConnectionRepository {

    val liveData: MutableStateFlow<List<BusinessConnection>> = MutableStateFlow(arrayListOf())
    val allLiveData: StateFlow<List<BusinessConnection>>
        get() = liveData

    val connectedBusinessState: MutableStateFlow<Business?> = MutableStateFlow(null)

    val connectedBusiness: StateFlow<Business?>
        get() = connectedBusinessState

    val selectedLiveData: MutableStateFlow<BusinessConnection> =
        MutableStateFlow(BusinessConnection())
    val selected: StateFlow<BusinessConnection>
        get() = selectedLiveData

    val mediaFileLiveData: MutableStateFlow<BusinessConnection> =
        MutableStateFlow(BusinessConnection())

    val offerBannerLiveData: MutableStateFlow<List<BusinessConnection>> =
        MutableStateFlow(arrayListOf())
    val mediaFiles: StateFlow<List<BusinessConnection>>
        get() = offerBannerLiveData

}