package storage

import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.FriendlyDatabase

class AppStorage {
    var driver: SqlDriver? = null
    var database: FriendlyDatabase? = null

    init {
        instance = this
    }

    companion object {
        private var instance: AppStorage? = null
        fun shared(): AppStorage {
            if (instance != null) {
                return instance as AppStorage
            } else {
                return AppStorage()
            }
        }
    }

    fun setup(driver: SqlDriver) {
        shared().driver = driver
        database = FriendlyDatabase(driver)
    }
}