package presentation.screens.business.inventory.category.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import common.asset.ImageAsset
import component.image.FriendlyIcon
import component.image.ImageView
import feature.productCategory.model.ProductCategory
import theme.ThemeColor

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ProductCategoryRow(
    category: ProductCategory,
    onClick: (category: ProductCategory) -> Unit
) {
    ElevatedCard(
        modifier = Modifier.height(60.dp).fillMaxWidth().padding(8.dp),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black
        ),
        onClick = { onClick(category) }
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = 8.dp, end = 8.dp),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Box(
                modifier = Modifier.width(40.dp).height(40.dp)
                    .background(ThemeColor.shared().PrimaryColor, shape = RoundedCornerShape(8.dp))
            ) {
                ImageView(category.Id, true)
            }
            Text(
                modifier = Modifier.weight(1f).padding(start = 8.dp, end = 8.dp),
                text = category.Name!!, fontSize = 14.sp, textAlign = TextAlign.Start
            )
            FriendlyIcon(ImageAsset.arrowRightIcon)
        }
    }
}