package feature.address.repository


import feature.address.model.Address
import feature.address.model.LocationCoordinate
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class AddressRepository {

    val allAddressState: MutableStateFlow<List<Address>> = MutableStateFlow(arrayListOf())

    val allAddress: StateFlow<List<Address>>
        get() = allAddressState

    val selectedAddressState: MutableStateFlow<Address> = MutableStateFlow(Address())
    val selectedAddress: StateFlow<Address>
        get() = selectedAddressState

    val primaryAddressState: MutableStateFlow<Address> = MutableStateFlow(Address())
    val primaryAddress: StateFlow<Address>
        get() = primaryAddressState

    val primaryAddressEditingState: MutableStateFlow<Address> = MutableStateFlow(Address())
    val primaryAddressEditing: StateFlow<Address>
        get() = primaryAddressEditingState


    val newAddressState: MutableStateFlow<Address> = MutableStateFlow(Address())
    val newAddress: StateFlow<Address>
        get() = newAddressState

    val editAddressState: MutableStateFlow<Address> = MutableStateFlow(Address())
    val editAddress: StateFlow<Address>
        get() = editAddressState

    val pickedLocationState: MutableStateFlow<LocationCoordinate?> =
        MutableStateFlow(null)
    val pickedLocation: StateFlow<LocationCoordinate?> get() = pickedLocationState
}
