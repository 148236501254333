package feature.device.network

import feature.device.model.Device
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DeviceRetrieveResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String?,
    @SerialName("Payload") val payload: List<Device>,
)

@Serializable
data class DeviceCreateResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String?,
    @SerialName("Payload") val payload: Device?,
)

@Serializable
data class DeviceUpdateResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String?,
    @SerialName("Payload") val payload: Device?,
)

@Serializable
data class DeviceDeleteResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String?,
    @SerialName("Payload") val payload: Device?,
)