package presentation.screens.business.homepage.component

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import common.asset.ImageAsset
import component.dimension.ScreenDimension
import component.image.FriendlyIcon

@Composable
fun SatisfiedClientsRow() {
    val images = ImageAsset.satisfiedClients
    Row(modifier = Modifier.wrapContentWidth().height(67.dp).padding(top = 12.dp)) {
//        Box(modifier = Modifier.height(60.dp).wrapContentWidth()) {
        Box(modifier = Modifier.height(50.dp).width(50.dp).zIndex(-1f)) {
            FriendlyIcon(
                size = ScreenDimension().heightHalf().toInt(),
                width = ScreenDimension().widthHalf().toInt(),
                name = images[0]
            )
        }
        Box(modifier = Modifier.height(50.dp).width(50.dp).zIndex(1f)) {
            FriendlyIcon(
                size = ScreenDimension().heightHalf().toInt(),
                width = ScreenDimension().widthHalf().toInt(),
                name = images[1]
            )
        }
        Box(modifier = Modifier.height(50.dp).width(50.dp).zIndex(2f)) {
            FriendlyIcon(
                size = ScreenDimension().heightHalf().toInt(),
                width = ScreenDimension().widthHalf().toInt(),
                name = images[2]
            )
        }
        Box(modifier = Modifier.height(50.dp).width(50.dp).zIndex(3f)) {
            FriendlyIcon(
                size = ScreenDimension().heightHalf().toInt(),
                width = ScreenDimension().widthHalf().toInt(),
                name = images[3]
            )
        }
        Box(modifier = Modifier.height(50.dp).width(50.dp).zIndex(4f)) {
            FriendlyIcon(
                size = ScreenDimension().heightHalf().toInt(),
                width = ScreenDimension().widthHalf().toInt(),
                name = images[4]
            )
        }
//        }
        Column(modifier = Modifier.wrapContentHeight().wrapContentWidth().padding(start = 12.dp)) {
            Text("50000+", fontWeight = FontWeight.Bold)
            Text("Satisfied Clients", fontWeight = FontWeight.Thin)
        }
    }

}