package native.locations


class LocationsFeature {
    companion object {
        private var instance: LocationsFeature? = null
        fun shared(): LocationsFeature {
            return if (instance != null) {
                instance as LocationsFeature
            } else {
                instance = LocationsFeature()
                instance as LocationsFeature
            }
        }
    }

    init {
        instance = this
    }

    var requestPermission: () -> Unit = {}
    var captureLocation: () -> Unit = {}

    var onCaptureLocation: (lat: Double, long: Double) -> Unit = { l: Double, l1: Double -> }
    var onPermissionChange: (Boolean) -> Unit = {}
}