package component.text

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import theme.ThemeColor

@Composable
fun TextRow(title: String = "", message: String = "") {
    Row(
        modifier = Modifier.fillMaxWidth().height(30.dp).background(
            color = ThemeColor.shared().cardBackground,
            shape = RoundedCornerShape(10.dp)
        )
    ) {
        TextTitle(title)
        Text(
            message,
            fontSize = 16.sp,
            fontStyle = FontStyle.Normal,
            fontWeight = FontWeight.Medium,
            textAlign = TextAlign.Center,
            color = ThemeColor.shared().subTitleColor,
            modifier = Modifier.padding(start = 8.dp)
        )
    }
}