package component.text

import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import theme.ThemeColor

@Composable
fun TextTitle(title: String = "Title") {
    Text(
        title,
        fontSize = 16.sp,
        fontStyle = FontStyle.Normal,
        fontWeight = FontWeight.Medium,
        textAlign = TextAlign.Center,
        color = ThemeColor.shared().TitleColor,
        modifier = Modifier.padding(start = 8.dp)
    )
}
