package feature.business.network

import feature.address.model.Address
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveBusinessRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String,
)

@Serializable
data class RetrieveSingleBusinessRequest(
    @SerialName("_id") val id: String? = null,
    @SerialName("Number") val number: Long? = null,
)

@Serializable
data class CreateBusinessRequest(
    @SerialName("BusinessTypeID") val businessTypeID: String,
    @SerialName("UserID") val userId: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String,
    @SerialName("EmailID") val email: String,
    @SerialName("Name") val name: String,
    @SerialName("Address") val address: Address,
)

@Serializable
data class FindBusinessRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String,
)

@Serializable
data class FindNearbyBusinessRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("Latitude") val latitude: Double,
    @SerialName("Longitude") val longitude: Double,
)