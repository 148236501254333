package feature.businessConfig.network

import feature.businessConfig.model.BusinessConfig
import feature.businessConfig.model.Currency
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FindBusinessConfigResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: BusinessConfig?,
)

@Serializable
data class FetchBusinessConfigResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: BusinessConfig?,
)

@Serializable
data class FetchAllCurrencyResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: ArrayList<Currency>,
)

@Serializable
data class CreateBusinessConfigResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: BusinessConfig?,
)

@Serializable
data class UpdateBusinessConfigResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String?,
    @SerialName("Event") val event: String?,
    @SerialName("Payload") val payload: BusinessConfig?,
)