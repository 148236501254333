package feature

import feature.auth.handler.AuthHandler
import feature.auth.viewModel.AuthViewModel

class SetupFeature {

    private val authViewModel = AuthViewModel()

    init {
        instance = this
    }

    companion object {
        private var instance: SetupFeature? = null
        fun shared(): SetupFeature {
            if (instance != null) {
                return instance as SetupFeature
            } else {
                return SetupFeature()
            }
        }
    }

    fun initModules() {
        AuthHandler.shared().checkIfLoggedIn()
        authViewModel.loadLanguage()
    }
}