package com.friendly.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class InventoryTable(
  public val _id: String,
  public val InventoryID: Long,
  public val ProductID: String,
  public val UserID: String,
  public val BusinessID: String,
  public val CategoryID: String?,
  public val SubCategoryID: String?,
  public val IsDeleted: Boolean,
  public val Name: String?,
  public val Description: String?,
  public val Model: String?,
  public val Barcode: String?,
  public val BrandID: String?,
  public val UOMID: String?,
  public val ColorID: String?,
  public val Quantity: Long?,
  public val SizeID: String?,
  public val DiscountID: String?,
  public val CoupanID: String?,
  public val TagID: String?,
  public val MaxQuantity: Long?,
  public val MinQuantity: Long?,
  public val StartRange: Long?,
  public val EndRange: Long?,
  public val SerialLength: Long?,
  public val ShortCode: String?,
  public val ProductPrice: String?,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
)
