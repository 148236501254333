package presentation.screens.business.homepage

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import common.asset.ImageAsset
import component.image.FriendlyIcon
import component.navigation.WebHomeNavBar
import feature.auth.handler.AuthHandler
import feature.business.viewModel.BusinessViewModel
import kotlinx.coroutines.delay
import native.files.FilePicker
import native.locations.Locations
import native.notifications.Notifications
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.homepage.component.WebHomeBannerSection
import presentation.screens.business.homepage.component.downloadAppRow


class BusinessHomePageScreen(override val screenName: ScreenName = ScreenName.BUSINESS_HOME) :
    AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val viewModel = BusinessViewModel()
        LaunchedEffect(key1 = Unit) {
            Notifications().pushNotifications.requestPermission()
            FilePicker().imagePicker.requestPermission()
            Locations().locations.captureLocation()
            delay(500)
            AuthHandler.shared().checkIfLoggedIn()
            viewModel.loadBusiness()
        }

        Column(modifier = Modifier.fillMaxWidth().fillMaxHeight()) {
            WebHomeNavBar()
            LazyColumn(
                modifier = Modifier
                    .fillMaxWidth().fillMaxHeight().padding(bottom = 0.dp)
            ) {
                item {
                    WebHomeBannerSection()
                }
                item {
                    downloadAppRow()
                }
                item {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(230.dp)
                            .padding(top = 20.dp)
                            .background(
                                shape = RoundedCornerShape(115.dp),
                                color = Color.LightGray
                            ),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceEvenly
                    ) {
                        Column {
                            FriendlyIcon(
                                size = 80,
                                width = 100,
                                name = ImageAsset.person
                            )
                            Text(
                                "1 Lac+",
                                color = Color.Black,
                                fontSize = 20.sp,
                                fontWeight = FontWeight.Bold
                            )
                            Text("Happy Customer", color = Color.Gray, fontSize = 15.sp)

                        }
                        Column {
                            FriendlyIcon(
                                size = 80,
                                width = 100,
                                name = ImageAsset.person
                            )
                            Text(
                                "Multi-User",
                                color = Color.Black,
                                fontSize = 20.sp,
                                fontWeight = FontWeight.Bold
                            )
                            Text("User Management Feature", color = Color.Gray, fontSize = 15.sp)

                        }
                        Column {
                            FriendlyIcon(
                                size = 80,
                                width = 100,
                                name = ImageAsset.person
                            )
                            Text(
                                "Multi-Device",
                                color = Color.Black,
                                fontSize = 20.sp,
                                fontWeight = FontWeight.Bold
                            )
                            Text(
                                "Use together on Mobile-Desktop",
                                color = Color.Gray,
                                fontSize = 15.sp
                            )

                        }
                        Column {
                            FriendlyIcon(
                                size = 80,
                                width = 100,
                                name = ImageAsset.person
                            )
                            Text(
                                "4.4/5",
                                color = Color.Black,
                                fontSize = 20.sp,
                                fontWeight = FontWeight.Bold
                            )
                            Text("on Google Play Store", color = Color.Gray, fontSize = 15.sp)

                        }
                    }
                }
                item { ServingPage() }
                item { WhySolutePage() }
                item { AffordablePlansPage() }
            }
        }
    }
}