package feature.cart.viewModel

import feature.app.AppViewModel
import feature.app.NavigationParam
import feature.auth.handler.AuthHandler
import feature.auth.model.FriendlyUser
import feature.business.handler.BusinessHandler
import feature.cart.handler.CartHandler
import feature.cart.model.CartItem
import feature.cart.network.CartNetwork
import feature.cart.network.CreateCustomerInvoiceRequest
import feature.cart.repository.CartRepository
import feature.customer.handler.CustomerHandler
import feature.invocie.handler.InvoiceHandler
import feature.product.model.Product
import feature.product.model.ProductPrice
import feature.sale.model.SaleTransaction
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import storage.database.table.cart.CartItemDatabaseTable

class CartViewModel :
    AppViewModel() {

    private val databaseTable = CartItemDatabaseTable()
    private val repository: CartRepository = CartHandler.shared().repository

    val searchQuery: StateFlow<String>
        get() = repository.searchQuery
    val cartItems: StateFlow<List<CartItem>>
        get() = repository.cartItems

    val mrp: StateFlow<Double>
        get() = repository.mrp

    val discount: StateFlow<Double>
        get() = repository.discount

    val price: StateFlow<Double>
        get() = repository.price

    val subtotal: StateFlow<Double>
        get() = repository.subtotal

    val tax: StateFlow<Double>
        get() = repository.tax

    val instantDiscount: StateFlow<Double>
        get() = repository.instantDiscount

    val totalAmount: StateFlow<Double>
        get() = repository.totalAmount

    private fun updatePricesInCart(items: List<CartItem>) {
        var totalMrp = 0.0
        var price = 0.0
        var discount = 0.0
        var totalTax = 0.0
        var finalPrice = 0.0
        var instantDiscountPrice = 0.0
        items.forEach { cartItem ->
            if (cartItem.price.Tax != null) {
                totalTax += (cartItem.price.Tax!!)
            }
            if (cartItem.price.Price != null) {
                price += (cartItem.price.Price!!)
            }
            if (cartItem.price.Discount != null) {
                discount += (cartItem.price.Discount!!)
            }
            if (cartItem.price.FinalPrice != null) {
                finalPrice += (cartItem.price.FinalPrice!!)
            }
            if (cartItem.price.MRP != null) {
                totalMrp += (cartItem.price.MRP!!)
            }
        }
        instantDiscountPrice = instantDiscount.value
        totalTax = finalPrice - price
        finalPrice = finalPrice - instantDiscountPrice
        scope.launch {
            repository.subtotalLiveData.emit(price)
            repository.discountLiveData.emit(discount)
            repository.taxLiveData.emit(totalTax)
            repository.totalAmountLiveData.emit(finalPrice)
            repository.mrpLiveData.emit(totalMrp)
            repository.priceLiveData.emit(price)
        }
    }

    override fun changeSearchQuery(value: String) {
        scope.launch {
            repository.searchQueryState.emit(value)
        }
        performSearch(value)
    }

//    override fun performSearch(value: String) {
//        if (value.isEmpty()) {
//            loadProductsFromStorage()
//        } else {
//            ProductDatabaseTable().search(
//                BusinessHandler.shared().businessId,
//                value
//            ) { data ->
//                scope.launch {
//                    repository.allProductState.emit(data)
//                }
//            }
//        }
//    }

    fun getProductQuantity(product: Product): Long {
        var quanity = 0L
        if (cartItems.value.isNotEmpty()) {
            cartItems.value.forEach {
                if (product.Id == it.productId) {
                    quanity = it.quantity
                }
            }
        }
        return quanity
    }

    fun getProductPrice(product: Product): Double {
        var price = 0.0
        if (cartItems.value.isNotEmpty()) {
            cartItems.value.forEach {
                if (product.Id == it.productId) {
                    price = it.finalPrice
                }
            }
        }
        return price
    }

    fun getItemForProduct(product: Product, callback: (CartItem?) -> Unit) {
        databaseTable.retrieveForProduct(product.Id) { data ->
            if (data.isNotEmpty()) {
                callback(data.first())
            } else {
                callback(null)
            }
        }
    }

    fun getItemForProduct(product: Product): CartItem? {
        var itemToReturn: CartItem? = null
        cartItems.value.forEach { item ->
            if (item.productId == product.Id) {
                itemToReturn = item
            }
        }
        return itemToReturn
    }

    fun loadCart(params: NavigationParam?, callBack: (List<CartItem>) -> Unit = {}) {
        if (params == null) {
            scope.launch {
                repository.cartItemState.emit(arrayListOf())
                callBack(arrayListOf())
                updatePricesInCart(arrayListOf())
            }
        } else {
            if (params.business != null && params.selectedBusiness != null && params.business!!.Id == params.selectedBusiness!!.Id) {
                databaseTable.retrieveForBusiness(
                    id = params.business!!.Id,
                    businessId = params.selectedBusiness!!.Id
                ) { data ->
                    scope.launch {
                        repository.cartItemState.emit(data)
                    }
                    callBack(data)
                    updatePricesInCart(data)
                }
            } else if (params.business != null && params.selectedBusiness != null && params.business!!.Id != params.selectedBusiness!!.Id) {
                databaseTable.retrieveForBusiness(
                    id = params.business!!.Id,
                    businessId = params.selectedBusiness!!.Id
                ) { data ->
                    scope.launch {
                        repository.cartItemState.emit(data)
                    }
                    callBack(data)
                    updatePricesInCart(data)
                }
            } else if (params.selectedBusiness == null && params.customer != null) {
                databaseTable.retrieveForCustomer(
                    id = params.business!!.Id,
                    customerId = params.customer!!.Id
                ) { data ->
                    scope.launch {
                        repository.cartItemState.emit(data)
                    }
                    callBack(data)
                    updatePricesInCart(data)
                }
            } else if (params.selectedBusiness != null && params.user != null) {
                databaseTable.retrieveForUser(
                    id = params.selectedBusiness!!.Id,
                    userId = params.user!!.id
                ) { data ->
                    scope.launch {
                        repository.cartItemState.emit(data)
                    }
                    callBack(data)
                    updatePricesInCart(data)
                }
            } else if (params.selectedBusiness != null && params.user == null) {
                databaseTable.retrieveForGuest(
                    id = params.selectedBusiness!!.Id,
                ) { data ->
                    scope.launch {
                        repository.cartItemState.emit(data)
                    }
                    callBack(data)
                    updatePricesInCart(data)
                }
            }
        }
    }

    fun resetCart() {
        CartItemDatabaseTable().deleteAll()
    }

//    fun prepareOrderDetails(customer: Customer?): JSONObject? {
//        val unixTime = Clock.System.now().epochSeconds
//        val request = JSONObject()
//        val user = FriendlyUser()
//        val business = BusinessHandler.shared().repository.business.value
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.businessID, business?.Id)
//        val invoiceId = System.currentTimeMillis()
//        val transactions = prepareTransaction(customer, invoiceId)
//
//        if (CustomerHandler.shared().repository.customer.value != null) {
//            val customerJson = gson.toJson(CustomerHandler.shared().repository.customer.value)
//            request.put(KeyConstant.customer, JSONObject(customerJson))
//        }
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        request.put(KeyConstant.orderItems, transactions.first)
//        if (transactions.first.length() == 0) {
//            return null
//        }
//        request.put(KeyConstant.orderId, unixTime)
//        request.put(KeyConstant.pricing, transactions.second)
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.mobileNumber, user.mobile)
//        request.put(KeyConstant.dialCode, user.dialCode)
//        request.put(KeyConstant.businessID, business?.Id)
//        request.put(KeyConstant.toBusinessID, business?.Id)
//        request.put(KeyConstant.business, JSONObject(gson.toJson(business)))
//        request.put(KeyConstant.instantDiscount, instantDiscount.value)
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        if (CustomerHandler.shared().repository.customer.value != null) {
//            val customerJson = gson.toJson(CustomerHandler.shared().repository.customer.value)
//            request.put(KeyConstant.customer, JSONObject(customerJson))
//        }
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        return request
//    }

    fun createInvoice(callback: (Boolean) -> Unit) {
        val request = CreateCustomerInvoiceRequest()
        val user = AuthHandler.shared().repository.authUser.value
        val business = BusinessHandler.shared().repository.business.value
        val customer = CustomerHandler.shared().repository.selectedCustomer.value
        request.businessID = business.Id
        val invoiceId = Clock.System.now().epochSeconds
        val transactions = prepareTransaction(
            invoiceId
        )
        request.invoiceID = invoiceId
        request.customer = customer
        request.deviceId = AuthHandler.shared().deviceId
        if (transactions.first.isEmpty()) {
            return
        }
        request.sales = transactions.first
        request.pricing = transactions.second
        request.userId = user.id
        request.businessID = business.Id
        request.business = business
        request.instantDiscount = instantDiscount.value
        request.deviceId = AuthHandler.shared().deviceId
        scope.launch {
            startLoading()
            CartNetwork().createCustomerInvoice(request) {
                stopLoading()
                if (it?.payload?.id?.isNotEmpty() == true) {
                    scope.launch {
                        repository.cartItemState.emit(arrayListOf())
                        InvoiceHandler.shared().repository.customerInvoiceLiveData.emit(it.payload)
                        callback(true)
                    }
                } else {
                    callback(false)
                }
            }
        }

    }


    private fun prepareTransaction(
        invoiceId: Long
    ): Pair<ArrayList<SaleTransaction>, ProductPrice> {
        val user = FriendlyUser()
        val business = BusinessHandler.shared().repository.business.value
        val customer = CustomerHandler.shared().repository.selectedCustomer.value
        val transactions: ArrayList<SaleTransaction> = arrayListOf()
        val pricingDetails = ProductPrice()
        var totalIGST = 0.0
        var totalCGST = 0.0
        var totalSGST = 0.0
        var totalVAT = 0.0
        var totalCESS = 0.0
        var totalTax = 0.0
        var totalCostPrice = 0.0
        var totalPrice = 0.0
        var totalFinalPrice = 0.0
        if (cartItems.value.isNotEmpty()) {
            cartItems.value.forEach { cartItem ->
                val productData = cartItem.product
                val transactionData = SaleTransaction()
                transactionData.invoiceID = invoiceId
                transactionData.userID = user.id
                transactionData.businessID = business.Id
                if (customer != null) {
                    transactionData.customerId = customer.Id
                    transactionData.customerName = customer.Name
                    transactionData.customerMobile = customer.MobileNumber
                    transactionData.vehicleNumber = customer.DeviceID
                }
                val quanity = cartItem.quantity
                var IGST = 0.0
                var CGST = 0.0
                var SGST = 0.0
                var VAT = 0.0
                var CESS = 0.0
                var Tax = 0.0
                var mrp = 0.0
                var finalPrice =
                    cartItem.product.ProductPrice.FinalPrice?.times(quanity)
                        ?: cartItem.product.ProductPrice.FinalPrice
                var costPrice = 0.0
                if (cartItem.product.ProductPrice.CostPrice != null) {
                    costPrice = cartItem.product.ProductPrice.CostPrice!! * quanity.toDouble()
                    totalCostPrice += costPrice
                }
                if (cartItem.product.ProductPrice.Price != null) {
                    mrp = cartItem.product.ProductPrice.Price!! * quanity.toDouble()
                    totalPrice += mrp
                }
                if (cartItem.product.ProductPrice.FinalPrice != null) {
                    finalPrice = cartItem.product.ProductPrice.FinalPrice!! * quanity.toFloat()
                    totalFinalPrice += finalPrice
                }
                if (cartItem.product.ProductPrice.IGST != null) {
                    IGST = cartItem.product.ProductPrice.IGST!! * quanity
                    totalIGST += IGST
                }
                if (cartItem.product.ProductPrice.CGST != null) {
                    CGST = cartItem.product.ProductPrice.CGST!! * quanity
                    totalCGST += CGST
                }
                if (cartItem.product.ProductPrice.SGST != null) {
                    SGST = cartItem.product.ProductPrice.SGST!! * quanity
                    totalSGST += SGST
                }
                if (cartItem.product.ProductPrice.VAT != null) {
                    VAT = cartItem.product.ProductPrice.VAT!! * quanity
                    totalVAT += VAT
                }
                if (cartItem.product.ProductPrice.CESS != null) {
                    CESS = cartItem.product.ProductPrice.CESS!! * quanity
                    totalCESS += CESS
                }
                if (cartItem.product.ProductPrice.taxIncluded != null && !cartItem.product.ProductPrice.taxIncluded!! && cartItem.product.ProductPrice.Tax != null) {
                    Tax = cartItem.product.ProductPrice.Tax!!
                    totalTax += Tax
                }
                transactionData.invoiceID = invoiceId
                transactionData.productID = cartItem.product.Id
                transactionData.productName = cartItem.product.Name
//                transactionData.product = cartItem.product
//                transactionData.mode = "CASH"
                transactionData.quantity = quanity
                transactionData.IGST = IGST
                transactionData.CGST = CGST
                transactionData.SGST = SGST
                transactionData.VAT = VAT
                transactionData.CESS = CESS
                transactionData.tax = Tax
                transactionData.price = cartItem.product.ProductPrice.Price
                transactionData.finalPrice = finalPrice
                transactionData.costPrice = cartItem.product.ProductPrice.CostPrice
//                transactionData.saleDate = now()
                transactionData.discount = cartItem.product.ProductPrice.Discount
                if (customer != null && customer.Id.isNotEmpty()) {
                    transactionData.customerName = customer.Name
                    transactionData.customerMobile = customer.MobileNumber
                    transactionData.customerId = customer.Id
                }
                transactions.add(transactionData)
            }
        }
        pricingDetails.IGST = totalIGST
        pricingDetails.CGST = totalCGST
        pricingDetails.SGST = totalSGST
        pricingDetails.VAT = totalVAT
        pricingDetails.CESS = totalCESS
        pricingDetails.Tax = totalTax
        pricingDetails.FinalPrice = totalFinalPrice
        pricingDetails.CostPrice = totalCostPrice
        pricingDetails.Price = totalPrice
        return Pair(transactions, pricingDetails)
    }

    fun addToCart(product: Product, params: NavigationParam?) {
        scope.launch {
//            repository.cartItemState.emit(arrayListOf())
            getItemForProduct(product) { item ->
                var currentCartItem = item
                if (currentCartItem != null) {
                    currentCartItem.quantity += 1
                } else {
                    currentCartItem = product.toCartItem()
                    currentCartItem.quantity = 1
                    currentCartItem.customerId = params?.customer?.Id
                }
                if (params?.business != null) {
                    currentCartItem.businessID = params.business?.Id
                }
                if (params?.selectedBusiness != null) {
                    currentCartItem.selectedBusinessID = params.selectedBusiness?.Id
                }
                if (params?.user != null) {
                    currentCartItem.userID = params.user?.id
                }
                currentCartItem.updatePricing()
                databaseTable.insert(currentCartItem) {
                    loadCart(params)
                }
            }
        }

    }

    fun removeFromCart(product: Product, params: NavigationParam?) {
        scope.launch {
//            repository.cartItemState.emit(arrayListOf())
            getItemForProduct(product) { item ->
                if (item != null) {
                    item.quantity -= 1
                    if (item.quantity == 0L) {
                        databaseTable.delete(product.Id)
                    } else if (item.quantity < 0L) {
                        databaseTable.delete(product.Id)
                    } else {
                        item.updatePricing()
                        databaseTable.insert(item) {
                            loadCart(params)
                        }
                    }
                }
                loadCart(params)
            }
        }
    }

    fun addProductToCart(product: Product?, params: NavigationParam?) {
        scope.launch {
//            repository.cartItemState.emit(arrayListOf())
            if (product != null) {
                getItemForProduct(product) { item ->
                    var currentCartItem = item
                    if (currentCartItem != null) {
                        currentCartItem.quantity += 1
                    } else {
                        currentCartItem = product.toCartItem()
                        currentCartItem.quantity = 1
                        currentCartItem.customerId = params?.customer?.Id
                    }
                    if (params?.business != null) {
                        currentCartItem.businessID = params.business?.Id
                    }
                    if (params?.selectedBusiness != null) {
                        currentCartItem.selectedBusinessID = params.selectedBusiness?.Id
                    }
                    if (params?.user != null) {
                        currentCartItem.userID = params.user?.id
                    }
                    currentCartItem.updatePricing()
                    databaseTable.insert(currentCartItem) {
                        loadCart(params)
                    }
                }
            }
        }

    }

}