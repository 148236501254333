package presentation.screens.common.auth

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import com.friendly.shared.component.DefaultBackArrow
import com.friendly.shared.component.ErrorSuggestion
import common.asset.ImageAsset
import component.input.CustomTextField
import component.loader.AppLoader
import feature.auth.handler.AuthHandler
import feature.auth.viewModel.AuthViewModel
import navigation.AppNavigator
import navigation.containers.ScreenName
import theme.ThemeColor

class SignUpScreen : Screen {


    @Composable
    override fun Content() {
        var viewModel = AuthViewModel()
        val navigator = LocalNavigator.current
        var name by remember { mutableStateOf(TextFieldValue("")) }
        val nameErrorState = remember { mutableStateOf(false) }
        val animate = remember { mutableStateOf(true) }
        fun onLickRegister() {
            viewModel.register(
                AuthHandler.shared().mobile,
                AuthHandler.shared().dialCode,
                name.text
            )
        }
        viewModel.goToHome = {
            AppNavigator.shared().popAll()
        }
        viewModel.goBack = {
            AppNavigator.shared().pop()
        }
        viewModel.goToSetMPin = {
            AppNavigator.shared().navigateTo(ScreenName.SET_M_PIN)
        }
        viewModel.goToLoginWithMPin = {
            AppNavigator.shared().navigateTo(
                ScreenName.VERIFY_M_PIN,

                )
        }
        viewModel.goToOtp = {
            AppNavigator.shared().navigateTo(ScreenName.OTP)
        }

        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(30.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            AppLoader(viewModel)
            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            )
            {
                Box(modifier = Modifier.weight(0.7f)) {
                    DefaultBackArrow {
                        navigator?.pop()
                    }
                }
                Box(modifier = Modifier.weight(1.0f)) {
                    Text(
                        text = "Sign Up",
                        color = ThemeColor.shared().TextColor,
                        fontSize = 18.sp
                    )
                }


            }
            Spacer(modifier = Modifier.height(50.dp))
            Text(text = "Create Account", fontSize = 26.sp, fontWeight = FontWeight.Bold)
            Text(
                text = "Complete your details",
                color = ThemeColor.shared().TextColor,
                textAlign = TextAlign.Center
            )
            Spacer(modifier = Modifier.height(50.dp))
            CustomTextField(
                placeholder = "First Name Last Name",
                trailingIcon = ImageAsset.person,
                label = "Name",
                errorState = nameErrorState.value,
                keyboardType = KeyboardType.Email,
                visualTransformation = VisualTransformation.None
            ) { newEmail ->
                name = newEmail
            }


            Spacer(modifier = Modifier.height(10.dp))
            if (nameErrorState.value) {
                ErrorSuggestion("Please enter valid name.")
            }
            CustomDefaultBtn(shapeSize = 50f, btnText = "Continue") {
                //email pattern
                val nameErrorState = name.text.length < 4
                if (nameErrorState) {
                    animate.value = !animate.value
                } else {
                    onLickRegister()
                }
            }
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(bottom = 50.dp),
                verticalArrangement = Arrangement.Bottom
            ) {

                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(top = 30.dp)
                        .clickable {

                        },
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        text = "By continuing you confirm that you agree",
                        color = ThemeColor.shared().TextColor
                    )
                    Row()
                    {
                        Text(
                            text = "with our ",
                            color = ThemeColor.shared().TextColor,
                        )
                        Text(
                            text = "Terms & Condition",
                            color = ThemeColor.shared().PrimaryColor,
                            modifier = Modifier.clickable {

                            })
                    }

                }
            }
        }
    }
}

