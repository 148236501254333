package presentation.screens.business.inventory.category.component

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.ImageView
import feature.productCategory.model.ProductCategory
import theme.ThemeColor

@Composable
fun HorizontalCategoryCard(
    category: ProductCategory,
    isSelected: Boolean = false,
    onSelect: (ProductCategory) -> Unit = {}
) {
    val backgroundColor: Color
    val textColor: Color
    if (isSelected) {
        backgroundColor = ThemeColor.shared().PrimaryColor
        textColor = Color.White
    } else {
        backgroundColor = ThemeColor.shared().cardBackground
        textColor = Color.Black
    }
    Row(
        modifier = Modifier.wrapContentWidth().height(50.dp)
            .padding(8.dp)
            .background(color = backgroundColor, RoundedCornerShape(20.dp))
            .border(
                width = 1.dp,
                brush = Brush.horizontalGradient(
                    listOf(
                        ThemeColor.shared().customerTheme,
                        ThemeColor.shared().businessTheme,
                    )
                ),
                shape = CircleShape
            ).clickable { onSelect(category) },
        verticalAlignment = Alignment.CenterVertically
    ) {
        Row(
            modifier = Modifier.wrapContentWidth().fillMaxHeight()
                .padding(start = 0.dp, end = 16.dp)
                .background(color = backgroundColor, RoundedCornerShape(20.dp)),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Box(
                modifier = Modifier.width(40.dp).height(40.dp)
                    .background(ThemeColor.shared().PrimaryColor, shape = RoundedCornerShape(20.dp))
                    .clip(
                        RoundedCornerShape(20.dp)
                    )
            ) {
                ImageView(category.Id, true)
            }
            Text(
                category.Name ?: "",
                fontSize = 16.sp,
                fontStyle = FontStyle.Normal,
                fontWeight = FontWeight.Medium,
                textAlign = TextAlign.Center,
                color = textColor,
                modifier = Modifier.padding(start = 8.dp)
            )
        }
    }


}