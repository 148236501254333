package feature.customer.repository


import feature.customer.model.Customer
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class CustomerRepository {
    val searchQueryState: MutableStateFlow<String> = MutableStateFlow("")
    val searchQuery: StateFlow<String>
        get() = searchQueryState

    val lastSyncDateState: MutableStateFlow<String?> = MutableStateFlow(null)
    val lastSyncDate: StateFlow<String?>
        get() = lastSyncDateState

    val allCustomerState: MutableStateFlow<ArrayList<Customer>> = MutableStateFlow(arrayListOf())
    val allCustomer: StateFlow<ArrayList<Customer>>
        get() = allCustomerState

    val allUnFilteredCustomerState: MutableStateFlow<ArrayList<Customer>> =
        MutableStateFlow(arrayListOf())
    val allUnFilteredCustomer: StateFlow<ArrayList<Customer>>
        get() = allUnFilteredCustomerState

    val customerState: MutableStateFlow<Customer?> = MutableStateFlow(null)
    val customer: StateFlow<Customer?>
        get() = customerState

    val selectedCustomerState: MutableStateFlow<Customer?> = MutableStateFlow(null)
    val selectedCustomer: StateFlow<Customer?>
        get() = selectedCustomerState

    private val editCustomerState: MutableStateFlow<Customer?> = MutableStateFlow(null)
    val editCustomer: StateFlow<Customer?>
        get() = editCustomerState

    fun selectCustomer(cust: Customer) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            customerState.emit(cust)
        }
    }

    fun setEditCustomer(cust: Customer?) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            editCustomerState.emit(cust)
        }
    }
}