package presentation.screens.customer.store.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.dimension.ScreenDimension
import component.image.ImageView
import component.input.SearchBar
import feature.app.AppViewModel
import feature.app.NavigationParam
import feature.business.utill.AddressUtill
import theme.ThemeColor

@Composable
fun StoreHeader(param: NavigationParam?, viewModel: AppViewModel, query: String) {
    Column(
        modifier = Modifier
            .width(ScreenDimension().width())
            .height(150.dp)
            .background(color = ThemeColor.shared().PrimaryColor)
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 12.dp)
        ) {
            Box(
                modifier = Modifier
                    .width(42.dp)
                    .height(42.dp)
                    .background(
                        color = Color.Black,
                        shape = RoundedCornerShape(22.dp)
                    )
            ) {
                ImageView(param?.selectedBusiness?.Id)
            }
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(start = 8.dp)
            ) {
                Text(
                    param?.selectedBusiness?.Name ?: "",
                    color = Color.White,
                    fontSize = 20.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 4.dp)
                )
                Text(
                    AddressUtill().getAddressToDisplayInSingleLine(param?.selectedBusiness?.Address),
                    color = Color.White,
                    fontSize = 14.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 4.dp)
                )
//                                Text(
//                                    "⭐ 4.5 • ⏱ 15 min",
//                                    color = Color.White,
//                                    fontSize = 14.sp,
//                                    fontWeight = FontWeight.Bold,
//                                    modifier = Modifier.padding(bottom = 50.dp)
//                                )
            }
        }
        SearchBar(
            viewModel,
            query,
            " search for items"
        )
    }
}