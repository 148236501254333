package presentation.screens.business.inventory.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.inventory.model.product_color.ProductColor
import feature.inventory.viewModel.product_color.ProductColorViewModel
import theme.ThemeColor

@Composable
fun SelectProductColorRow(selected: String?, onChange: (ProductColor) -> Unit = {}) {
    val viewModel = ProductColorViewModel()
    val allColor = viewModel.allProductColor.collectAsState()
    LaunchedEffect(key1 = Unit) {
        viewModel.loadAllColor()
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("Color")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allColor.value.count()) {
                HorizontalColorCard(
                    allColor.value[it],
                    isSelected = selected == allColor.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}