package component.address

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Home
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import feature.address.model.Address
import theme.ThemeColor

@Composable
fun AddressCard(address: Address, onClick: (Address) -> Unit) {
    Column(
        modifier = Modifier
            .height(90.dp)
            .padding(8.dp)
            .fillMaxWidth()
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(Color.White, shape = RoundedCornerShape(10.dp))
                .clip(RoundedCornerShape(10.dp))
                .clickable { onClick(address) },
            contentAlignment = Alignment.Center
        ) {
            Row {
                Box(
                    modifier = Modifier
                        .width(30.dp)
                        .fillMaxHeight()
                        .background(Color.White, shape = RoundedCornerShape(10.dp))
                        .clip(RoundedCornerShape(10.dp))
                        .clickable { onClick(address) },
                    contentAlignment = Alignment.CenterStart,
                ) {
                    Icon(
                        Icons.Default.Home,
                        contentDescription = "Home",
                        tint = ThemeColor.shared().PrimaryColor
                    )
                }

                Column(
                    modifier = Modifier
                        .fillMaxSize()
                ) {
                    Row {
                        Text(text = "House Number")
                        address.FlatNumber?.let { Text(text = it) }
                        address.House?.let { Text(text = it) }
                    }
                    Row {
                        address.Area?.let { Text(text = it) }
                        address.LandMark?.let { Text(text = it) }
                    }
                    Row {
                        address.City?.let { Text(text = it) }
                        address.State?.let { Text(text = it) }
                    }
                }
            }

        }
    }


}