package engine.pricing

import feature.product.model.Product
import feature.product.model.ProductPrice

class PricingEngine {
    fun calculatePricing(product: Product, quantity: Long): ProductPrice {
        val price = ProductPrice(
            CostPrice = product.ProductPrice.CostPrice,
            Price = product.ProductPrice.Price,
            MRP = product.ProductPrice.MRP,
            FinalPrice = product.ProductPrice.FinalPrice,
            SGST = product.ProductPrice.SGST,
            CGST = product.ProductPrice.CGST,
            IGST = product.ProductPrice.IGST,
            Discount = product.ProductPrice.Discount
        )
        val basePrice = getBasePrice(product)
        val applicableTax = getApplicableTax(product)
        price.FinalPrice = basePrice * quantity
        return price
    }

    fun getApplicableTax(product: Product) {
        val totalTax = 0.0

        if (product.ProductPrice.Tax != null) {

        }
    }

    fun getBasePrice(product: Product): Double {
        var finalPrice = product.ProductPrice.FinalPrice ?: 0.0
        if (product.ProductPrice.FinalPrice != null) {
            finalPrice = product.ProductPrice.FinalPrice!!
        }
        return finalPrice
    }
}