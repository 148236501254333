package feature.auth.model

import feature.business.model.Business

class BusinessConstants {
    var business: Business? = null
    val theme = """
        {
  "PrimaryColor": "0xFFBB86FC",
  }
    """.trimIndent()

    val selectedBusiness = ""
//    val selectedBusiness = """
//        {
//  "_id": "6364c00fb25244fcf46425f0",
//  "BusinessNumber": 96,
//  "UserID": "6364bf91b25244fcf46425ce",
//  "BusinessTypeID": "61aca7bb68b40d644a588611",
//  "IsDeleted": false,
//  "Name": "Bharat Grocery Store",
//  "Address": {
//    "UniqueID": 1696783095646,
//    "UserID": "6522da81a0dc5bddaf21d0d5",
//    "Name": "",
//    "House": "157",
//    "FlatNumber": "",
//    "ZipCode": 110025,
//    "Country": "",
//    "State": "Delhi",
//    "City": "New Delhi",
//    "Area": "Sarai Jullena",
//    "LandMark": "Near Evergreen Sweets",
//    "Floor": "",
//    "MobileNumber": "",
//    "Type": "OTHER",
//    "IsPrimary": false,
//    "IsDeleted": false,
//    "CreatedAt":"2023-09-12T03:49:41.340Z",
//    "UpdatedAt": "2023-09-12T03:49:41.340Z",
//    "_id":  "6522daf8a0dc5bddaf21d0da"
//  },
//  "DealerName": "",
//  "ProductTypes": [],
//  "GSTNumber": "",
//  "PanNumber": "",
//  "PanVerified": "false",
//  "GstVerified": "false",
//  "BusiniessVerified": "false",
//  "EmailID": "ankur@gmail.com",
//  "MobileNumber": "+4917670223162",
//  "DialCode": "",
//  "Gender": null,
//  "DeviceID": "",
//  "ProfilePicture": [],
//  "LastSeen": "2022-11-04T05:35:12.141Z",
//  "CreatedAt": "2022-11-04T05:35:12.141Z",
//  "UpdatedAt": "2022-11-04T05:35:12.141Z",
//  "__v": 0
//}
//    """.trimIndent()

}