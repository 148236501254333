package component.button

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import theme.ThemeColor

@Composable
fun RadioButton(isSelected: Boolean = false, size: Dp) {
    var color = ThemeColor.shared().PrimaryColor
    if (!isSelected) {
        color = ThemeColor.shared().lightGray
    }
    Box(
        modifier = Modifier.width(size).height(size).padding(2.dp)
            .background(color, shape = RoundedCornerShape(size / 2))
    ) {
        Box(
            modifier = Modifier.width(size - 2.dp).height(size - 2.dp).padding(2.dp)
                .background(ThemeColor.shared().White, shape = RoundedCornerShape(size - 4.dp / 2))
        ) {
            Box(
                modifier = Modifier.width(size - 8.dp).height(size - 8.dp).padding(4.dp)
                    .background(
                        color,
                        shape = RoundedCornerShape(size / 8)
                    )
            ) {

            }
        }
    }
}