package presentation.screens.business.connection

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.empty.EmptyData
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.businessConnection.viewModel.BusinessConnectionViewModel
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.connection.component.BusinessConnectedCard
import presentation.screens.business.connection.create.CreateBusinessConnectionScreen
import theme.ThemeColor

class BusinessConnectionListScreen(override val screenName: ScreenName = ScreenName.BUSINESS_DETAILS_CONNECTION) :
    AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val conectionViewModel = viewModel?.getViewModel<BusinessConnectionViewModel>(screenName)
            ?: BusinessConnectionViewModel()
        viewModel = conectionViewModel
        val allConnections = conectionViewModel.allData.collectAsState()
        val navigator = LocalNavigator.current
        LaunchedEffect(key1 = Unit) {
            conectionViewModel.retrieve()
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = "Connection", navigationParam, onClickAdd = {
                navigator?.push(CreateBusinessConnectionScreen())
            }, onBackPress = {
                AppNavigator.shared().pop()
            })
            AppLoader(viewModel)
            LazyColumn(modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)) {
                item {
                    if (allConnections.value.isEmpty()) {
                        EmptyData(viewModel = viewModel)
                        DefaultButton(
                            "Connect With Other Business",
                            onClick = { navigator?.push(CreateBusinessConnectionScreen()) })
                    }
                }
                items(allConnections.value.count()) {
                    BusinessConnectedCard(
                        navigationParam,
                        allConnections.value[it],
                        conectionViewModel
                    ) { business ->
                        navigateTo(
                            ScreenName.STORE_DETAILS_SCREEN,
                            navigationParam?.update(NavigationParam(selectedBusiness = business))
                        )
                    }
                }
            }
        }
    }
}