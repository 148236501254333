package feature.mediaFile.repository

import feature.mediaFile.model.MediaFile
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class MediaFileRepository {

    val liveData: MutableStateFlow<List<MediaFile>> = MutableStateFlow(arrayListOf())
    val allLiveData: StateFlow<List<MediaFile>>
        get() = liveData

    val selectedLiveData: MutableStateFlow<MediaFile> = MutableStateFlow(MediaFile())
    val selected: StateFlow<MediaFile>
        get() = selectedLiveData

    val createMediaFileLiveData: MutableStateFlow<MediaFile> = MutableStateFlow(MediaFile())
    val createMediaFile: StateFlow<MediaFile>
        get() = createMediaFileLiveData

    val mediaFilesLiveData: MutableStateFlow<List<MediaFile>> = MutableStateFlow(arrayListOf())
    val mediaFiles: StateFlow<List<MediaFile>>
        get() = mediaFilesLiveData

}