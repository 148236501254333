package feature.business.repository

import feature.business.model.Business
import feature.sync.BusinessAnalytics
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class BusinessRepository {
    val searchQueryState: MutableStateFlow<String> = MutableStateFlow("")
    val searchQuery: StateFlow<String>
        get() = searchQueryState

    val analyticsLiveData: MutableStateFlow<ArrayList<BusinessAnalytics>> = MutableStateFlow(
        arrayListOf()
    )
    val analytics: StateFlow<ArrayList<BusinessAnalytics>>
        get() = analyticsLiveData

    val createBusinessState: MutableStateFlow<Business> = MutableStateFlow(Business())

    val createBusiness: StateFlow<Business> get() = createBusinessState

    val addBusinessConnectionSearchResultState: MutableStateFlow<List<Business>> =
        MutableStateFlow(arrayListOf())
    val addBusinessConnectionSearchResult: StateFlow<List<Business>> get() = addBusinessConnectionSearchResultState

    val businessLiveData: MutableStateFlow<Business> = MutableStateFlow(Business())
    val business: StateFlow<Business>
        get() = businessLiveData

    val allBusinessLiveData: MutableStateFlow<List<Business>> = MutableStateFlow(arrayListOf())
    val allBusiness: StateFlow<List<Business>>
        get() = allBusinessLiveData

    ////////////////////////////////////////////////////////////////////////////////////
    //Nearby
    ////////////////////////////////////////////////////////////////////////////////////
    val nearbyBusinessSearchResultState: MutableStateFlow<List<Business>> =
        MutableStateFlow(arrayListOf())
    val nearbyBusinessSearchResult: MutableStateFlow<List<Business>>
        get() = nearbyBusinessSearchResultState

    val nearbyBusinessState: MutableStateFlow<List<Business>> = MutableStateFlow(arrayListOf())
    val nearbyBusiness: StateFlow<List<Business>>
        get() = nearbyBusinessState
}