package feature.employee.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FindEmployeeRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String,
)

@Serializable
data class FindUserRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String,
)

@Serializable
data class FetchEmployeeRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("LastSyncDate") val lastSyncDate: String?
)

@Serializable
data class FetchBusinessEmployeeRequest(
    @SerialName("BusinessID") val businessID: String,
    @SerialName("LastSyncDate") val lastSyncDate: String?
)

@Serializable
data class CreateEmployeeRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("BusinessID") val businessID: String?,
    @SerialName("EmployeeUserID") var EmployeeUserID: String?,
    @SerialName("Name") var Name: String?,
    @SerialName("Address") var Address: String?,
    @SerialName("Status") var Status: String?,
    @SerialName("EmailID") var EmailID: String?,
    @SerialName("MobileNumber") val mobile: String?,
    @SerialName("DialCode") val dialCode: String?,
    @SerialName("WhatsApp") var WhatsApp: String?,
    @SerialName("Gender") var Gender: Int?,
    @SerialName("DeviceID") var DeviceID: String?,
    @SerialName("FCMToken") var FCMToken: String?,
    @SerialName("JobTypeId") var JobTypeId: String?,
    @SerialName("JobTypeName") var JobTypeName: String?,
)

@Serializable
data class UpdateEmployeeRequest(
    @SerialName("_id") val id: String,
    @SerialName("UserID") val userId: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("Name") val name: String,
    @SerialName("DialCode") val dialCode: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("EmailID") val email: String,
    @SerialName("DeviceID") val deviceID: String,
    @SerialName("IsDeleted") var IsDeleted: Boolean?,
    @SerialName("JobTypeId") var JobTypeId: String?,
    @SerialName("JobTypeName") var JobTypeName: String?,
)