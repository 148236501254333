package feature.inventory.network.product_color

import feature.inventory.model.product_color.ProductColor
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveProductColorResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<ProductColor>,
)