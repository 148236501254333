package presentation.screens.customer.order.details

import androidx.compose.runtime.Composable
import navigation.containers.ScreenName
import navigation.screen.AppScreen

class OrderDetailScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
    }
}