package com.friendly.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class JobRoleTable(
  public val _id: String,
  public val Name: String?,
  public val Description: String?,
  public val IsDeleted: Boolean,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
  public val __v: Long?,
)
