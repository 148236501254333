package presentation.screens.business.connection.create

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import component.country.DialCodeHelper
import component.input.MobileTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.business.viewModel.BusinessViewModel
import feature.businessConnection.viewModel.BusinessConnectionViewModel
import locale.localization
import presentation.screens.business.connection.component.AddBusinessConnectionCard
import theme.ThemeColor

class CreateBusinessConnectionScreen : Screen {
    @Composable
    override fun Content() {
        val viewModel = BusinessConnectionViewModel()
        val businessViewModel = BusinessViewModel()

        val navigator = LocalNavigator.current

        val businessConnectionSearchResult =
            businessViewModel.addBusinessConnectionSearchResult.collectAsState()

        var mobile by remember { mutableStateOf(TextFieldValue("")) }
        var dialCode by remember { mutableStateOf(DialCodeHelper().getSelectedCountryCode()) }
        val mobileErrorState = remember {
            mutableStateOf(false)
        }
        var isValidMobile by remember { mutableStateOf(false) }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = "Add Connection", null, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            LazyColumn(
                modifier = Modifier.fillMaxWidth().fillMaxHeight().padding(bottom = 60.dp)
            ) {
                item {
                    Column(modifier = Modifier.height(80.dp).fillMaxWidth()) {
                        Spacer(modifier = Modifier.height(8.dp))
                        MobileTextField(
                            placeholder = "9000000000",
                            trailingIcon = ImageAsset.phone,
                            label = localization().employee.employeeMobileNumber,
                            errorState = mobileErrorState,
                            visualTransformation = VisualTransformation.None,
                            onChanged = { mob ->
                                mobile = mob
                                isValidMobile = (mob.text.length in 9..11)
                            },
                            onDialCodeChange = {
                                dialCode = it
                            }
                        )
                        Spacer(modifier = Modifier.height(8.dp))
                    }
                }
                item {
                    CustomDefaultBtn(
                        shapeSize = 50f,
                        btnText = "Search"
                    ) {
                        mobileErrorState.value = !isValidMobile
                        if (isValidMobile) {
                            businessViewModel.findBusiness(
                                dialCode.countryPhoneCode, mobile.text
                            ) { result ->

                            }
                        } else {
                            AlertHandler.shared().showAlert(
                                localization().employee.createAlertTitle,
                                localization().employee.createAlertMessage
                            )
                        }
                    }
                }
                items(businessConnectionSearchResult.value.count()) { index ->
                    AddBusinessConnectionCard(
                        businessConnectionSearchResult.value[index],
                        onClick = {
                            businessViewModel.setSelectedBusiness(it)
                            navigator?.pop()
                        }, onClickConnect = {
                            viewModel.create(it) { isSuccess ->
                                if (isSuccess) {
                                    navigator?.pop()
                                }
                            }
                        })
                }
            }
        }
    }
}