package feature.productSubCategory.repository

import feature.productSubCategory.model.ProductSubCategory
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class ProductSubCategoryRepository {
    val subCategoryLiveData: MutableStateFlow<List<ProductSubCategory>> = MutableStateFlow(
        arrayListOf()
    )
    val allSubCategory: StateFlow<List<ProductSubCategory>>
        get() = subCategoryLiveData

    val allSubCategoryForCategoryState: MutableStateFlow<List<ProductSubCategory>> =
        MutableStateFlow(
            arrayListOf()
        )
    val allSubCategoryForCategory: StateFlow<List<ProductSubCategory>>
        get() = allSubCategoryForCategoryState

    val selectedSubCategoryState: MutableStateFlow<ProductSubCategory?> = MutableStateFlow(null)
    val selectedSubCategory: StateFlow<ProductSubCategory?>
        get() = selectedSubCategoryState

    val editSubCategoryLiveData: MutableStateFlow<ProductSubCategory?> = MutableStateFlow(null)
    val editSubCategory: StateFlow<ProductSubCategory?>
        get() = editSubCategoryLiveData

    fun setEditSubCategory(value: ProductSubCategory?) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            editSubCategoryLiveData.emit(value)
        }
    }

    fun setSelectedSubCategory(value: ProductSubCategory?) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            selectedSubCategoryState.emit(value)
        }
    }
}