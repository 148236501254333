package feature.inventory.repository

import feature.inventory.model.coupon.Coupon
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class CoupanRepository {
    val coupanLiveData: MutableStateFlow<List<Coupon>> = MutableStateFlow(arrayListOf())
    val allCoupan: StateFlow<List<Coupon>>
        get() = coupanLiveData

    val selectedCoupanState: MutableStateFlow<Coupon?> = MutableStateFlow(null)
    val selectedCoupan: StateFlow<Coupon?>
        get() = selectedCoupanState

}