package feature.inventory.network.size

import feature.inventory.model.size.Size
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveSizeResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<Size>,
)