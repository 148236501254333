package feature.productSubCategory.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveProductSubCategoryRequest(
    @SerialName("BusinessID") val businessID: String,
)

@Serializable
data class CreateProductSubCategoryRequest(
    @SerialName("Name") val name: String,
    @SerialName("CategoryID") val categoryId: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("UserID") val userId: String,
)

@Serializable
data class UpdateProductSubCategoryRequest(
    @SerialName("Name") val name: String,
    @SerialName("_id") val id: String,
    @SerialName("CategoryID") val categoryId: String?,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("UserID") val userId: String,
)
