package feature.inventory.viewModel.product_color

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.inventory.handler.ProductColorHandler
import feature.inventory.model.product_color.ProductColor
import feature.inventory.network.product_color.ProductColorNetwork
import feature.inventory.network.product_color.RetrieveProductColorRequest
import feature.inventory.repository.ProductColorRepository
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.product_color.ProductColorDatabaseTable

class ProductColorViewModel(val repository: ProductColorRepository = ProductColorHandler.shared().repository) :
    AppViewModel() {

    val allProductColor: StateFlow<List<ProductColor>>
        get() = repository.productColorLiveData

    val selectedProductColor: StateFlow<ProductColor?>
        get() = repository.selectedProductColor

    fun fetchAllColor() {
        loadFromStorage()
        val request =
            RetrieveProductColorRequest(BusinessHandler.shared().repository.business.value.Id)
        scope.launch {
            ProductColorNetwork().retrieve(request) {
                stopLoading()
                ProductColorDatabaseTable().insert(it?.payload)
                loadFromStorage()
            }
        }
    }

    fun loadAllColor() {
        loadFromStorage()
        if (allProductColor.value.isEmpty()) {
            fetchAllColor()
        }
    }

    private fun loadFromStorage() {
        ProductColorDatabaseTable().retrieveAll { data ->
            scope.launch {
                repository.productColorLiveData.emit(data)
            }
        }
    }

    fun getColorById(id: String?, callback: (ProductColor?) -> Unit) {
        ProductColorDatabaseTable().find(id) { data ->
            scope.launch {
                callback(data)
            }
        }
    }
}