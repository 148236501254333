package feature.auth.viewModel


import androidx.compose.runtime.Stable
import common.constants.KeyConstant
import common.constants.UserDefaults
import component.country.CountryCode
import feature.alert.handler.AlertHandler
import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.auth.model.FriendlyUser
import feature.auth.model.Language
import feature.auth.network.AuthNetwork
import feature.auth.network.AuthRequest
import feature.auth.network.CheckAccountRequest
import feature.auth.network.LanguageRequest
import feature.auth.network.SendOTPRequest
import feature.auth.network.SetPasswordRequest
import feature.customer.handler.CustomerHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import locale.Localization

@Stable
class AuthViewModel : AppViewModel() {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    val repository = AuthHandler.shared().repository

    init {
        AuthHandler.shared().checkIfLoggedIn()
        loadLanguage()
    }

    val selectedLanguage = repository.selectedLanguage
    val tempAuthUser: StateFlow<FriendlyUser>
        get() = repository.tempAuthLiveData

    private val allAuthLiveData: MutableStateFlow<ArrayList<FriendlyUser>> =
        MutableStateFlow(arrayListOf())
    val allAuth: StateFlow<ArrayList<FriendlyUser>>
        get() = allAuthLiveData

    val authUser: StateFlow<FriendlyUser>
        get() = repository.authUser

    val isLoggedIn: StateFlow<Boolean>
        get() = repository.isLoggedIn

    val otp: StateFlow<String>
        get() = repository.otpState

    private val passwordState: MutableStateFlow<String> = MutableStateFlow("")
    val password: StateFlow<String>
        get() = passwordState

    val mobileNumber = repository.mobileNumber

    var goToRegister: (() -> Unit)? = null
    var goToOtp: (() -> Unit)? = null
    var goToSetMPin: (() -> Unit)? = null
    var goToLoginWithMPin: (() -> Unit)? = null
    var goToHome: (() -> Unit)? = null
    var goBack: (() -> Unit)? = null

    fun setMobileNumber(mobile: String) {
        scope.launch {
            repository.mobileNumberState.emit(mobile)
        }
    }

    fun loadLanguage() {
        val selectedLanguage = UserDefaults.retrieve(KeyConstant.language)
        val localizationStringFromStorage = UserDefaults.retrieve(KeyConstant.localization)
        if (selectedLanguage.isNotEmpty()) {
            val language: Language = Json.decodeFromString(selectedLanguage)
            CoroutineScope(Job() + Dispatchers.Main).launch {
                repository.selectedLanguageState.emit(language)
            }
            if (localizationStringFromStorage.isNotEmpty()) {
                val localizationFromStorage: Localization =
                    json.decodeFromString(localizationStringFromStorage)
                CoroutineScope(Job() + Dispatchers.Main).launch {
                    repository.localizationState.emit(localizationFromStorage)
                }
            } else {
                downloadLanguage(language.code)
            }
        } else {
            CoroutineScope(Job() + Dispatchers.Main).launch {
                repository.selectedLanguageState.emit(Language(code = "en", "English"))
                downloadLanguage("en")
            }
        }
    }

    fun updateLanguage(language: Language?) {
        if (language != null) {
            UserDefaults.store(KeyConstant.language, Json.encodeToString(language))
            CoroutineScope(Job() + Dispatchers.Main).launch {
                repository.selectedLanguageState.emit(language)
            }
            downloadLanguage(language.code)
        }
    }


    fun logout() {
        UserDefaults.remove(KeyConstant.userCredentials)
        UserDefaults.remove(KeyConstant.membershipDetails)
        CoroutineScope(Job() + Dispatchers.Main).launch {
            CustomerHandler.shared().repository.customerState.emit(null)
            repository.tempAuthLiveData.emit(FriendlyUser())
            repository.authLiveData.emit(FriendlyUser())
        }
        AuthHandler.shared().checkIfLoggedIn()
    }

    fun sendOTP(mobile: String, diaCode: String) {
        val request = SendOTPRequest(mobile, diaCode)
        startLoading()
        scope.launch {
            AuthNetwork.shared().sendOTP(request) {
                stopLoading()
                if (it?.payload?.otp != null && it.payload.otp.length >= 6) {
                    scope.launch {
                        repository.otpState.emit(it.payload.otp)
                    }
                } else if (it?.message != null) {
                    AlertHandler.shared().showAlert("Oops", it.message)
                }
            }
        }
    }

    fun checkAccount(mobile: String, diaCode: String) {
        startLoading()
        val request = CheckAccountRequest(mobile, diaCode)
        scope.launch {
            AuthNetwork.shared().checkAccount(request) {
                stopLoading()
                if (it?.payload?.password != null && it.payload.password?.length!! >= 6) {
                    scope.launch {
                        repository.tempAuthLiveData.emit(it.payload)
                        passwordState.emit(it.payload.password!!)
                        goToLoginWithMPin?.let { it1 -> it1() }
                    }
                } else if (it?.payload?.name.isNullOrEmpty()) {
                    sendOTP(mobile, diaCode)
                    goToOtp?.let { it1 -> it1() }
                } else {
                    scope.launch {
                        repository.tempAuthLiveData.emit(it!!.payload!!)
                    }
                    sendOTP(mobile, diaCode)
                    goToOtp?.let { it1 -> it1() }
                }
            }
        }
    }

    fun setPassword(userId: String, password: String) {
        startLoading()
        val request = SetPasswordRequest(userId, password)
        scope.launch {
            AuthNetwork.shared().setPassword(request) {
                stopLoading()
                if (it?.status == "success") {
                    val creds = tempAuthUser.value
                    creds.password = password
                    UserDefaults.storeCredentials(Json.encodeToString(creds))
                    CoroutineScope(Job() + Dispatchers.Main).launch {
                        repository.authLiveData.emit(creds)
                        goToHome?.let { it1 -> it1() }
                    }
                }
            }
        }
    }

    fun login(
        mobile: String,
        diaCode: CountryCode
    ) {
        startLoading()
        val request = AuthRequest(mobile, diaCode.countryPhoneCode, "")
        scope.launch {
            AuthNetwork.shared().login(request) {
                stopLoading()
                if (it?.payload?.id != null && it.payload.id.length >= 6) {

                    UserDefaults.storeCredentials(json.encodeToString(it.payload))
                    scope.launch {
                        repository.isLoggedInState.emit(true)
                        goToHome?.let { it1 -> it1() }
                    }
                } else {
                    goToRegister?.let { it1 -> it1() }
                }
            }
        }
    }

    fun register(
        mobile: String,
        diaCode: String,
        name: String
    ) {
        startLoading()
        val request = AuthRequest(mobile, diaCode, name)
        scope.launch {
            AuthNetwork.shared().login(request) {
                stopLoading()
                if (it?.payload?.id != null && it.payload.id.length >= 6) {
                    UserDefaults.storeCredentials(json.encodeToString(it.payload))
                    scope.launch {
                        repository.isLoggedInState.emit(true)
                        repository.tempAuthLiveData.emit(it.payload)
                        goToSetMPin?.let { it1 -> it1() }
                    }
                }
            }
        }
    }

    private fun downloadLanguage(
        code: String,
    ) {
//        startLoading()
        val request = LanguageRequest(code)
        scope.launch {
            AuthNetwork.shared().retrieveLanguage(request) {
//                stopLoading()
                scope.launch {
                    if (it?.payload != null) {
                        repository.localizationState.emit(it.payload)
                        UserDefaults.store(
                            KeyConstant.localization,
                            json.encodeToString(it.payload)
                        )
                    } else {

                    }
                }
            }
        }
    }

    fun getAllLanguageList(): ArrayList<Language> {
        var allLanguageOptions: ArrayList<Language> = arrayListOf()
        allLanguageOptions.add(Language(code = "en", "English"))
        allLanguageOptions.add(Language(code = "hi", "Hindi"))
        allLanguageOptions.add(Language(code = "bang", "Bangoli"))
        allLanguageOptions.add(Language(code = "ur", "Urdu"))
        return allLanguageOptions

    }
}