package presentation.screens.business.inventory.product.detail.component

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import feature.inventory.model.Inventory
import feature.inventory.model.uom.UOM
import feature.inventory.viewModel.uom.UOMViewModel
import theme.ThemeColor
import theme.color

@Composable
fun UOMCard(UOMID: String?,selectedInventory: Inventory?,filteredInventory:List<Inventory> ,onClick: (String?) -> Unit) {
    var uom: UOM? by remember { mutableStateOf(null) }

    fun isUoMPresentInInventory(fInv: List<Inventory>, uId: String?): Boolean {
        var isEnabledForClick = false
        fInv.forEach {item->
            if(!item.UOMID.isNullOrEmpty() && !uId.isNullOrEmpty() && item.UOMID == uId){
                isEnabledForClick = true
            }
        }
        return isEnabledForClick
    }
    val isEnabled = isUoMPresentInInventory(filteredInventory, UOMID)
    LaunchedEffect(key1 = Unit) {
        UOMViewModel().getUOMById(UOMID) { uomFromDb -> uom = uomFromDb }
    }

    if(uom != null && !uom!!.Name.isNullOrEmpty()){
        Row (modifier = Modifier.width(80.dp).height(60.dp).padding(4.dp), verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center){
            Row(
                modifier = Modifier
                    .height(if(isEnabled || filteredInventory.isEmpty()) 60.dp else 40.dp).width(if(isEnabled || filteredInventory.isEmpty()) 80.dp else 60.dp)
                    .background(color = ThemeColor.shared().cardBackground,
                        RoundedCornerShape(12.dp)
                    ).clip(RoundedCornerShape(12.dp))
                    .zIndex(1f)
                    .clickable(enabled = isEnabled || filteredInventory.isEmpty()) {
                        onClick(UOMID)
                    },
            ) {
                Text(
                    uom?.Name ?: "", fontSize = 20.sp, modifier = Modifier
                        .padding(start= 8.dp, end= 8.dp)
                )
            }
        }
    }

}