package feature.alert.handler


import feature.alert.repository.AlertRepository
import feature.alert.viewModel.AlertViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch


class AlertHandler {
    var viewModel: AlertViewModel? = null
    val repository = AlertRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: AlertHandler? = null
        fun shared(): AlertHandler {
            if (instance != null) {
                return instance as AlertHandler
            } else {
                return AlertHandler()
            }
        }
    }

    fun showAlert(title: String, message: String) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            repository.title.emit(title)
            repository.message.emit(message)
            viewModel?.showAlert()
        }
    }

    fun showSuccessAlert(title: String, message: String) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            repository.isSuccess.emit(true)
            repository.title.emit(title)
            repository.message.emit(message)
            viewModel?.showAlert()
        }
    }

    fun showAlert(title: String, message: String, onPositiveClick: () -> Unit) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            repository.title.emit(title)
            repository.message.emit(message)
            repository.onPositiveClick.emit(onPositiveClick)
            viewModel?.showAlert()
        }
    }

    fun showSuccessAlert(title: String, message: String, onPositiveClick: () -> Unit) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            repository.isSuccess.emit(true)
            repository.title.emit(title)
            repository.message.emit(message)
            repository.onPositiveClick.emit(onPositiveClick)
            viewModel?.showAlert()
        }
    }
    fun showConfirmationAlert(title: String, message: String,onNegativeClick: () -> Unit, onPositiveClick: () -> Unit) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            repository.isConfirmation.emit(true)
            repository.title.emit(title)
            repository.message.emit(message)
            repository.onPositiveClick.emit(onPositiveClick)
            repository.onNegativeClick.emit(onNegativeClick)
            viewModel?.showAlert()
        }
    }
}