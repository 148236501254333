package feature.cart.handler

import feature.cart.repository.CartRepository
import feature.cart.viewModel.CartViewModel

class CartHandler {

    var viewModel: CartViewModel? = null
    val repository = CartRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: CartHandler? = null
        fun shared(): CartHandler {
            if (instance != null) {
                return instance as CartHandler
            } else {
                return CartHandler()
            }
        }
    }

    fun setup(model: CartViewModel) {
        viewModel = model
    }


}