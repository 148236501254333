package storage.database.table.invoice

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.InvoiceTable
import feature.invoice.model.CustomerInvoice
import feature.sale.model.Sale
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class InvoiceDatabaseTable : DatabaseTable() {
    fun insert(item: CustomerInvoice?) {
        val table = AppDatabase.shared().database?.invoiceTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    item.id,
                    item.invoiceID,
                    item.ShareLink,
                    item.userID,
                    item.businessID,
                    item.customerID,
                    item.customerName,
                    item.DialCode,
                    item.mobileNumber,
                    item.isDeleted!!,
                    jsonDecoder.encodeToString(item.sales ?: arrayListOf<Sale>()),
                    item.invoiceNumber,
                    item.invoiceType,
                    item.totalPrice,
                    item.GST,
                    item.SGST,
                    item.CGST,
                    item.IGST,
                    item.CESS,
                    item.VAT,
                    item.Tax,
                    item.costPrice,
                    item.instantDiscount,
                    item.finalPrice,
                    item.pendingAmount,
                    item.clearedAmount,
                    item.invoiceDate,
                    item.createdAt,
                    item.updatedAt,
                    item.__v
                )
            }
        }
    }

    fun insert(items: List<CustomerInvoice>?, callBack: () -> Unit) {
        val table = AppDatabase.shared().database?.invoiceTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    item.id,
                    item.invoiceID,
                    item.ShareLink,
                    item.userID,
                    item.businessID,
                    item.customerID,
                    item.customerName,
                    item.DialCode,
                    item.mobileNumber,
                    item.isDeleted!!,
                    jsonDecoder.encodeToString(item.sales ?: arrayListOf<Sale>()),
                    item.invoiceNumber,
                    item.invoiceType,
                    item.totalPrice,
                    item.GST,
                    item.SGST,
                    item.CGST,
                    item.IGST,
                    item.CESS,
                    item.VAT,
                    item.Tax,
                    item.costPrice,
                    item.instantDiscount,
                    item.finalPrice,
                    item.pendingAmount,
                    item.clearedAmount,
                    item.invoiceDate,
                    item.createdAt,
                    item.updatedAt,
                    item.__v
                )
            }
            callBack()
        }
    }

    fun retrieve(id: String, callback: (ArrayList<CustomerInvoice>) -> Unit) {
        val table = AppDatabase.shared().database?.invoiceTableQueries
        val allItemsFetched: ArrayList<CustomerInvoice> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                allItemsFetched.add(convert(item))
            }
            callback(allItemsFetched)
        }
    }

    fun find(id: Long, callback: (CustomerInvoice?) -> Unit) {
        val table = AppDatabase.shared().database?.invoiceTableQueries
        var allItemsFetched: CustomerInvoice? = null
        scope.launch {
            val item = table?.find(id)?.awaitAsOneOrNull()
            item?.let {
                allItemsFetched = convert(item)
            }
            callback(allItemsFetched)
        }
    }

    fun retrieveForEmployee(
        businessId: String,
        userId: String,
        callback: (ArrayList<CustomerInvoice>) -> Unit
    ) {
        val table = AppDatabase.shared().database?.invoiceTableQueries
        val allItemsFetched: ArrayList<CustomerInvoice> = arrayListOf()
        scope.launch {
            val items = table?.getInvoiceForUser(businessId, userId)?.awaitAsList()
            items?.forEach { item ->
                allItemsFetched.add(convert(item))
            }
            callback(allItemsFetched)
        }
    }

    fun retrieveForCustomer(
        businessId: String,
        customerId: String,
        callback: (ArrayList<CustomerInvoice>) -> Unit
    ) {
        val table = AppDatabase.shared().database?.invoiceTableQueries
        val allItemsFetched: ArrayList<CustomerInvoice> = arrayListOf()
        scope.launch {
            val items = table?.getInvoiceForCustomer(businessId, customerId)?.awaitAsList()
            items?.forEach { item ->
                allItemsFetched.add(convert(item))
            }
            callback(allItemsFetched)
        }
    }


    fun convert(item: InvoiceTable): CustomerInvoice {
        return CustomerInvoice(
            id = item._id,
            invoiceID = item.InvoiceID,
            ShareLink = item.ShareLink,
            userID = item.UserID,
            customerID = item.CustomerID,
            customerName = item.CustomerName,
            DialCode = item.DialCode,
            mobileNumber = item.MobileNumber,
            isDeleted = item.IsDeleted,
            sales = jsonDecoder.decodeFromString(item.Sales ?: "{}"),
            invoiceNumber = item.InvoiceNumber,
            invoiceType = item.InvoiceType,
            totalPrice = item.TotalPrice,
            GST = item.GST,
            SGST = item.SGST,
            CGST = item.CGST,
            IGST = item.IGST,
            CESS = item.CESS,
            VAT = item.VAT,
            Tax = item.Tax,
            costPrice = item.CostPrice,
            instantDiscount = item.InstantDiscount,
            finalPrice = item.FinalPrice,
            pendingAmount = item.PendingAmount,
            clearedAmount = item.ClearedAmount,
            invoiceDate = item.InvoiceDate,
            createdAt = item.CreatedAt,
            updatedAt = item.UpdatedAt,
            __v = item.__v
        )
    }
}