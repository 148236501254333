package feature.offerBanner.viewModel

//import kotlinx.coroutines.IO
import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.offerBanner.handler.OfferBannerHandler
import feature.offerBanner.model.OfferBanner
import feature.offerBanner.network.OfferBannerNetwork
import feature.offerBanner.network.OfferBannerRetrieveRequest
import feature.offerBanner.repository.OfferBannerRepository
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class OfferBannerViewModel(private val repository: OfferBannerRepository = OfferBannerHandler.shared().repository) :
    AppViewModel() {

    val allData: StateFlow<List<OfferBanner>>
        get() = repository.allLiveData

    val mediaFile: StateFlow<OfferBanner>
        get() = repository.mediaFileLiveData

    fun find(featureObjectID: String): String {
        var stringToReturn = ""
        allData.value.forEach { item ->
            if (!item.FileURL.isNullOrEmpty()) {
                stringToReturn = item.FileURL!!
            }
        }
        return stringToReturn
    }

    fun loadMedia(id: String) {
        if (allData.value.isNotEmpty()) {
            allData.value.forEach {
                scope.launch {
                    repository.mediaFileLiveData.emit(it)
                }
            }
        } else {
            retrieveFor(id)
        }
    }

    private fun retrieveFor(id: String, completion: ((OfferBanner) -> Unit) = {}) {
        val business = BusinessHandler.shared().repository.business
        if (business.value.Id.isEmpty()) {
            return
        }
        val request = OfferBannerRetrieveRequest(business.value.Id, id)
        OfferBannerNetwork().retrieve(request) {
            if (!it?.payload.isNullOrEmpty()) {
                scope.launch {
                    repository.mediaFileLiveData.emit(it!!.payload.first())
                    completion(it.payload.first())
                }
            }
        }
    }

    fun retrieve(completion: (() -> Unit) = {}) {
        val business = BusinessHandler.shared().repository.business
        if (business.value.Id.isEmpty()) {
            return
        }
        val request = OfferBannerRetrieveRequest(business.value.Id)
        OfferBannerNetwork().retrieve(request) {
            if (!it?.payload.isNullOrEmpty()) {
                scope.launch {
                    repository.liveData.emit(it!!.payload)
                }
            }
        }
    }
}