package feature.mediaFile.network

import common.constants.Server
import feature.mediaFile.event.MediaFileEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class MediaFileNetwork {

    val json = Json {
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: MediaFileNetwork? = null
        fun shared(): MediaFileNetwork {
            if (instance != null) {
                return instance as MediaFileNetwork
            } else {
                return MediaFileNetwork()
            }
        }
    }

    fun retrieve(
        request: MediaFileRetrieveRequest,
        onResult: (MediaFileRetrieveResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(MediaFileEvent.RETRIEVE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }

    fun create(
        request: MediaFileCreateRequest,
        onResult: (MediaFileCreateResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(MediaFileEvent.CREATE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }

    fun upload(
        request: MediaFileUploadRequest,
        onResult: (MediaFileUploadResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(MediaFileEvent.UPLOAD)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }

}
