package presentation.screens.customer.store.component

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import feature.businessType.model.BusinessType
import theme.ThemeColor

@Composable
fun AllCategoryCard(businessType: BusinessType) {
    Box(
        modifier = Modifier
            .wrapContentWidth()
            .height(100.dp)
            .padding(8.dp)
            .border(BorderStroke(1.dp, color = Color.Gray), shape = RoundedCornerShape(4.dp))
            .background(
                color = ThemeColor.shared().cardBackground,
                shape = RoundedCornerShape(10.dp)
            )
    ) {
        Text(
            businessType.name ?: "", fontSize = 14.sp, modifier = Modifier
                .align(Alignment.Center).padding(start = 8.dp, end = 8.dp)
        )
    }
}