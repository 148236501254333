package feature.inventory.network.inventory

import feature.inventory.model.Inventory
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveInventoryResponse(
    @SerialName("status") val status: String?,
    @SerialName("Error") val error: String?,
    @SerialName("Message") val message: String?,
    @SerialName("Payload") val payload: List<Inventory>,
)

@Serializable
data class CreateInventoryResponse(
    @SerialName("status") val status: String?,
    @SerialName("Error") val error: String?,
    @SerialName("Message") val message: String?,
    @SerialName("Payload") val payload: Inventory?,
)

@Serializable
data class UpdateInventoryResponse(
    @SerialName("status") val status: String?,
    @SerialName("Error") val error: String?,
    @SerialName("Message") val message: String?,
    @SerialName("Payload") val payload: List<Inventory>,
)

@Serializable
data class DeleteInventoryResponse(
    @SerialName("status") val status: String?,
    @SerialName("Error") val error: String?,
    @SerialName("Message") val message: String?,
)
