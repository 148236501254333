package feature.employee.model

import kotlinx.serialization.SerialName

data class EmployeeAttendance(
    @SerialName("_id") var Id: String = "",
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("EmployeeID") var EmployeeID: String? = null,
    @SerialName("EmployeeUserID") var EmployeeUserID: String? = null,
    @SerialName("BusinessID") var BusinessID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("IsApproved") var IsApproved: Boolean? = null,
    @SerialName("IsPresent") var IsPresent: Boolean? = null,
    @SerialName("Hours") var Hours: Int? = null,
    @SerialName("Comment") var Comment: String? = null,
    @SerialName("AttendanceDate") var AttendanceDate: String? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)
