package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class EmployeeTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getNewEmployee(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = GetNewEmployeeQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun getNewEmployee(BusinessID: String?): Query<GetNewEmployee> =
      getNewEmployee(BusinessID) { UpdatedAt ->
    GetNewEmployee(
      UpdatedAt
    )
  }

  public fun <T : Any> retrieve(EmployeeUserID: String?, mapper: (
    _id: String,
    UserID: String?,
    EmployeeUserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean,
    Name: String?,
    Address: String?,
    GSTNumber: String?,
    Barcode: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    WhatsApp: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    TotalSale: Long?,
    TotalPayment: Long?,
    LastSeen: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
    JobTypeId: String?,
    JobTypeName: String?,
  ) -> T): Query<T> = RetrieveQuery(EmployeeUserID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getString(19),
      cursor.getString(20),
      cursor.getString(21),
      cursor.getLong(22),
      cursor.getString(23),
      cursor.getString(24)
    )
  }

  public fun retrieve(EmployeeUserID: String?): Query<EmployeeTable> = retrieve(EmployeeUserID) {
      _id, UserID, EmployeeUserID_, BusinessID, IsDeleted, Name, Address, GSTNumber, Barcode,
      Status, EmailID, MobileNumber, WhatsApp, DialCode, Gender, DeviceID, FCMToken, TotalSale,
      TotalPayment, LastSeen, CreatedAt, UpdatedAt, __v, JobTypeId, JobTypeName ->
    EmployeeTable(
      _id,
      UserID,
      EmployeeUserID_,
      BusinessID,
      IsDeleted,
      Name,
      Address,
      GSTNumber,
      Barcode,
      Status,
      EmailID,
      MobileNumber,
      WhatsApp,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      TotalSale,
      TotalPayment,
      LastSeen,
      CreatedAt,
      UpdatedAt,
      __v,
      JobTypeId,
      JobTypeName
    )
  }

  public fun <T : Any> retrieveBusinessId(EmployeeUserID: String?,
      mapper: (BusinessID: String?) -> T): Query<T> = RetrieveBusinessIdQuery(EmployeeUserID) {
      cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun retrieveBusinessId(EmployeeUserID: String?): Query<RetrieveBusinessId> =
      retrieveBusinessId(EmployeeUserID) { BusinessID ->
    RetrieveBusinessId(
      BusinessID
    )
  }

  public fun <T : Any> retrieveForBusiness(BusinessID: String?, mapper: (
    _id: String,
    UserID: String?,
    EmployeeUserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean,
    Name: String?,
    Address: String?,
    GSTNumber: String?,
    Barcode: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    WhatsApp: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    TotalSale: Long?,
    TotalPayment: Long?,
    LastSeen: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
    JobTypeId: String?,
    JobTypeName: String?,
  ) -> T): Query<T> = RetrieveForBusinessQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getString(19),
      cursor.getString(20),
      cursor.getString(21),
      cursor.getLong(22),
      cursor.getString(23),
      cursor.getString(24)
    )
  }

  public fun retrieveForBusiness(BusinessID: String?): Query<EmployeeTable> =
      retrieveForBusiness(BusinessID) { _id, UserID, EmployeeUserID, BusinessID_, IsDeleted, Name,
      Address, GSTNumber, Barcode, Status, EmailID, MobileNumber, WhatsApp, DialCode, Gender,
      DeviceID, FCMToken, TotalSale, TotalPayment, LastSeen, CreatedAt, UpdatedAt, __v, JobTypeId,
      JobTypeName ->
    EmployeeTable(
      _id,
      UserID,
      EmployeeUserID,
      BusinessID_,
      IsDeleted,
      Name,
      Address,
      GSTNumber,
      Barcode,
      Status,
      EmailID,
      MobileNumber,
      WhatsApp,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      TotalSale,
      TotalPayment,
      LastSeen,
      CreatedAt,
      UpdatedAt,
      __v,
      JobTypeId,
      JobTypeName
    )
  }

  public fun <T : Any> search(
    BusinessID: String?,
    Name: String,
    MobileNumber: String,
    EmployeeUserID: String,
    mapper: (
      _id: String,
      UserID: String?,
      EmployeeUserID: String?,
      BusinessID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Address: String?,
      GSTNumber: String?,
      Barcode: String?,
      Status: String?,
      EmailID: String?,
      MobileNumber: String?,
      WhatsApp: String?,
      DialCode: String?,
      Gender: Long?,
      DeviceID: String?,
      FCMToken: String?,
      TotalSale: Long?,
      TotalPayment: Long?,
      LastSeen: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
      __v: Long?,
      JobTypeId: String?,
      JobTypeName: String?,
    ) -> T,
  ): Query<T> = SearchQuery(BusinessID, Name, MobileNumber, EmployeeUserID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getString(19),
      cursor.getString(20),
      cursor.getString(21),
      cursor.getLong(22),
      cursor.getString(23),
      cursor.getString(24)
    )
  }

  public fun search(
    BusinessID: String?,
    Name: String,
    MobileNumber: String,
    EmployeeUserID: String,
  ): Query<EmployeeTable> = search(BusinessID, Name, MobileNumber, EmployeeUserID) { _id, UserID,
      EmployeeUserID_, BusinessID_, IsDeleted, Name_, Address, GSTNumber, Barcode, Status, EmailID,
      MobileNumber_, WhatsApp, DialCode, Gender, DeviceID, FCMToken, TotalSale, TotalPayment,
      LastSeen, CreatedAt, UpdatedAt, __v, JobTypeId, JobTypeName ->
    EmployeeTable(
      _id,
      UserID,
      EmployeeUserID_,
      BusinessID_,
      IsDeleted,
      Name_,
      Address,
      GSTNumber,
      Barcode,
      Status,
      EmailID,
      MobileNumber_,
      WhatsApp,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      TotalSale,
      TotalPayment,
      LastSeen,
      CreatedAt,
      UpdatedAt,
      __v,
      JobTypeId,
      JobTypeName
    )
  }

  public suspend fun insert(
    _id: String,
    UserID: String?,
    EmployeeUserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean,
    Name: String?,
    Address: String?,
    GSTNumber: String?,
    Barcode: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    WhatsApp: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    TotalSale: Long?,
    TotalPayment: Long?,
    LastSeen: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
    JobTypeId: String?,
    JobTypeName: String?,
  ) {
    driver.execute(-183_674_443, """
        |INSERT OR REPLACE INTO EmployeeTable(_id, UserID,EmployeeUserID, BusinessID, IsDeleted, Name ,Address,GSTNumber,Barcode,Status,EmailID,MobileNumber,WhatsApp,DialCode,Gender,DeviceID,FCMToken,TotalSale,TotalPayment,LastSeen,CreatedAt,UpdatedAt,__v,JobTypeId,JobTypeName)
        |    VALUES(?, ?,?, ?, ?, ? ,?, ?, ?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)
        """.trimMargin(), 25) {
          bindString(0, _id)
          bindString(1, UserID)
          bindString(2, EmployeeUserID)
          bindString(3, BusinessID)
          bindBoolean(4, IsDeleted)
          bindString(5, Name)
          bindString(6, Address)
          bindString(7, GSTNumber)
          bindString(8, Barcode)
          bindString(9, Status)
          bindString(10, EmailID)
          bindString(11, MobileNumber)
          bindString(12, WhatsApp)
          bindString(13, DialCode)
          bindLong(14, Gender)
          bindString(15, DeviceID)
          bindString(16, FCMToken)
          bindLong(17, TotalSale)
          bindLong(18, TotalPayment)
          bindString(19, LastSeen)
          bindString(20, CreatedAt)
          bindString(21, UpdatedAt)
          bindLong(22, __v)
          bindString(23, JobTypeId)
          bindString(24, JobTypeName)
        }.await()
    notifyQueries(-183_674_443) { emit ->
      emit("EmployeeTable")
    }
  }

  private inner class GetNewEmployeeQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("EmployeeTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("EmployeeTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM EmployeeTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "EmployeeTable.sq:getNewEmployee"
  }

  private inner class RetrieveQuery<out T : Any>(
    public val EmployeeUserID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("EmployeeTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("EmployeeTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT EmployeeTable._id, EmployeeTable.UserID, EmployeeTable.EmployeeUserID, EmployeeTable.BusinessID, EmployeeTable.IsDeleted, EmployeeTable.Name, EmployeeTable.Address, EmployeeTable.GSTNumber, EmployeeTable.Barcode, EmployeeTable.Status, EmployeeTable.EmailID, EmployeeTable.MobileNumber, EmployeeTable.WhatsApp, EmployeeTable.DialCode, EmployeeTable.Gender, EmployeeTable.DeviceID, EmployeeTable.FCMToken, EmployeeTable.TotalSale, EmployeeTable.TotalPayment, EmployeeTable.LastSeen, EmployeeTable.CreatedAt, EmployeeTable.UpdatedAt, EmployeeTable.__v, EmployeeTable.JobTypeId, EmployeeTable.JobTypeName FROM EmployeeTable WHERE EmployeeUserID ${ if (EmployeeUserID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, EmployeeUserID)
    }

    override fun toString(): String = "EmployeeTable.sq:retrieve"
  }

  private inner class RetrieveBusinessIdQuery<out T : Any>(
    public val EmployeeUserID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("EmployeeTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("EmployeeTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT BusinessID FROM EmployeeTable WHERE EmployeeUserID ${ if (EmployeeUserID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, EmployeeUserID)
    }

    override fun toString(): String = "EmployeeTable.sq:retrieveBusinessId"
  }

  private inner class RetrieveForBusinessQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("EmployeeTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("EmployeeTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT EmployeeTable._id, EmployeeTable.UserID, EmployeeTable.EmployeeUserID, EmployeeTable.BusinessID, EmployeeTable.IsDeleted, EmployeeTable.Name, EmployeeTable.Address, EmployeeTable.GSTNumber, EmployeeTable.Barcode, EmployeeTable.Status, EmployeeTable.EmailID, EmployeeTable.MobileNumber, EmployeeTable.WhatsApp, EmployeeTable.DialCode, EmployeeTable.Gender, EmployeeTable.DeviceID, EmployeeTable.FCMToken, EmployeeTable.TotalSale, EmployeeTable.TotalPayment, EmployeeTable.LastSeen, EmployeeTable.CreatedAt, EmployeeTable.UpdatedAt, EmployeeTable.__v, EmployeeTable.JobTypeId, EmployeeTable.JobTypeName FROM EmployeeTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "EmployeeTable.sq:retrieveForBusiness"
  }

  private inner class SearchQuery<out T : Any>(
    public val BusinessID: String?,
    public val Name: String,
    public val MobileNumber: String,
    public val EmployeeUserID: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("EmployeeTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("EmployeeTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT EmployeeTable._id, EmployeeTable.UserID, EmployeeTable.EmployeeUserID, EmployeeTable.BusinessID, EmployeeTable.IsDeleted, EmployeeTable.Name, EmployeeTable.Address, EmployeeTable.GSTNumber, EmployeeTable.Barcode, EmployeeTable.Status, EmployeeTable.EmailID, EmployeeTable.MobileNumber, EmployeeTable.WhatsApp, EmployeeTable.DialCode, EmployeeTable.Gender, EmployeeTable.DeviceID, EmployeeTable.FCMToken, EmployeeTable.TotalSale, EmployeeTable.TotalPayment, EmployeeTable.LastSeen, EmployeeTable.CreatedAt, EmployeeTable.UpdatedAt, EmployeeTable.__v, EmployeeTable.JobTypeId, EmployeeTable.JobTypeName FROM EmployeeTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND (Name LIKE ? OR MobileNumber LIKE ? OR EmployeeUserID LIKE ?)""",
        mapper, 4) {
      bindString(0, BusinessID)
      bindString(1, Name)
      bindString(2, MobileNumber)
      bindString(3, EmployeeUserID)
    }

    override fun toString(): String = "EmployeeTable.sq:search"
  }
}
