package component.button

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import theme.ThemeColor

@Composable
fun SecondaryButton(
    title: String, onClick: () -> Unit, modifier: Modifier = Modifier
        .height(50.dp)
        .width(200.dp)
        .padding(start = 8.dp, end = 8.dp)
        .background(color = ThemeColor.shared().cardBackground, shape = RoundedCornerShape(8.dp))
        .border(1.dp, ThemeColor.shared().PrimaryColor, RoundedCornerShape(8.dp))
) {
    var buttonModifier = modifier
    Button(
        onClick = onClick,
        modifier = buttonModifier,
        colors = ButtonDefaults.buttonColors(
            containerColor = ThemeColor.shared().White
        )
    ) {
        Text(
            title,
            color = ThemeColor.shared().PrimaryColor,
            modifier = Modifier.background(ThemeColor.shared().White)
        )
    }
}