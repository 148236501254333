package feature.business.utill

import feature.address.model.Address
import feature.business.model.Business

class BusinessUtill {
    fun getAddressToDisplayInSingleLine(business: Business?): String {
        var toReturn = ""
        if (!business?.Address?.House.isNullOrEmpty()) {
            toReturn = toReturn + business?.Address?.House + " "
        }
        if (!business?.Address?.LandMark.isNullOrEmpty()) {
            toReturn = toReturn + business?.Address?.LandMark + " "
        }
        if (!business?.Address?.Area.isNullOrEmpty()) {
            toReturn = toReturn + business?.Address?.Area + " "
        }
        if (!business?.Address?.City.isNullOrEmpty()) {
            toReturn = toReturn + business?.Address?.City + " "
        }
        if (!business?.Address?.State.isNullOrEmpty()) {
            toReturn = toReturn + business?.Address?.State + " "
        }
        return toReturn
    }
}

class AddressUtill {
    fun getAddressToDisplayInSingleLine(address: Address?): String {
        var toReturn = ""
        if (address?.House?.isNotEmpty() == true) {
            toReturn = toReturn + address.House + " "
        }
        if (address?.LandMark?.isNotEmpty() == true) {
            toReturn = toReturn + address.LandMark + " "
        }
        if (!address?.Area.isNullOrEmpty()) {
            toReturn = toReturn + address?.Area + " "
        }
        if (!address?.City.isNullOrEmpty()) {
            toReturn = toReturn + address?.City + " "
        }
        if (!address?.State.isNullOrEmpty()) {
            toReturn = toReturn + address?.State + " "
        }
        return toReturn
    }
}