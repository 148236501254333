package storage.database.table.cart

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.CartItemTable
import feature.cart.model.CartItem
import feature.product.model.Product
import feature.product.model.ProductPrice
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import storage.database.AppDatabase
import storage.database.table.DatabaseTable
import storage.database.table.inventory.product.ProductDatabaseTable

class CartItemDatabaseTable : DatabaseTable() {
    fun insert(item: CartItem?, callback: () -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        scope.launch {
            if (item != null) {
                try {
                    table?.insert(
                        item.uniqueID,
                        item._id,
                        item.userID,
                        item.businessID,
                        item.selectedBusinessID,
                        item.customerId,
                        item.productId,
                        jsonDecoder.encodeToString(item.product),
                        jsonDecoder.encodeToString(item.price),
                        item.quantity,
                        item.finalPrice,
                        item.createdAt,
                        item.updatedAt
                    )
                } catch (error: Exception) {
                    print(error.message)
                }
            }
            delay(delay)
            delay(delay)
            callback()
        }
    }

    fun insert(items: List<CartItem>?, callback: () -> Unit) {
        items?.forEach { item ->
            insert(item) {}
        }
        callback()
    }

    fun delete(id: String) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        scope.launch {
            try {
                table?.delete(id)
            } catch (error: Exception) {
                print(error.message)
            }
        }
    }

    fun deleteAll() {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        scope.launch {
            try {
                table?.deleteAll()
            } catch (error: Exception) {
                print(error.message)
            }
        }
    }

    fun retrieve(id: String, callback: (List<CartItem>) -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        val itemsFetched: ArrayList<CartItem> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                convert(item) { itm ->
                    itemsFetched.add(itm)
                }
            }
            delay(delay)
            callback(itemsFetched)
        }
    }

    fun retrieveForSelfBusiness(id: String, callback: (List<CartItem>) -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        val itemsFetched: ArrayList<CartItem> = arrayListOf()
        scope.launch {
            val items = table?.retrieveForBusiness(id, null)?.awaitAsList()
            items?.forEach { item ->
                convert(item) { itm ->
                    itemsFetched.add(itm)
                }
            }
            delay(delay)
            callback(itemsFetched)
        }
    }


    fun retrieveForProduct(id: String, callback: (List<CartItem>) -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        val itemsFetched: ArrayList<CartItem> = arrayListOf()
        scope.launch {
            try {
                val items = table?.retrieveForProduct(id)?.awaitAsList()
                items?.forEach { item ->
                    convert(item) { itm ->
                        itemsFetched.add(itm)
                    }
                }
                delay(delay)
                callback(itemsFetched)
            } catch (error: Exception) {

            }

        }
    }

    fun retrieveForCustomer(id: String, customerId: String, callback: (List<CartItem>) -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        val itemsFetched: ArrayList<CartItem> = arrayListOf()
        scope.launch {
            val items = table?.retrieveForCustomer(id, customerId)?.awaitAsList()
            items?.forEach { item ->
                convert(item) { itm ->
                    itemsFetched.add(itm)
                }
            }
            delay(delay)
            callback(itemsFetched)
        }
    }

    fun retrieveForBusiness(id: String, businessId: String, callback: (List<CartItem>) -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        val itemsFetched: ArrayList<CartItem> = arrayListOf()
        scope.launch {
            val items = table?.retrieveForBusiness(id, businessId)?.awaitAsList()
            items?.forEach { item ->
                convert(item) { itm ->
                    itemsFetched.add(itm)
                }
            }
            delay(delay)
            callback(itemsFetched)
        }
    }

    fun retrieveForUser(id: String, userId: String, callback: (List<CartItem>) -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        val itemsFetched: ArrayList<CartItem> = arrayListOf()
        scope.launch {
            val items = table?.retrieveForUser(id, userId)?.awaitAsList()
            items?.forEach { item ->
                convert(item) { itm ->
                    itemsFetched.add(itm)
                }
            }
            delay(delay)
            callback(itemsFetched)
        }
    }

    fun retrieveForGuest(id: String, callback: (List<CartItem>) -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        val itemsFetched: ArrayList<CartItem> = arrayListOf()
        scope.launch {
            delay(delay)
            val items = table?.retrieveForGuest(id)?.awaitAsList()
            items?.forEach { item ->
                convert(item) { itm ->
                    itemsFetched.add(itm)
                }
            }
            callback(itemsFetched)
        }
    }

    fun retrieve(callback: (List<CartItem>) -> Unit) {
        val table = AppDatabase.shared().database?.cartItemTableQueries
        val itemsFetched: ArrayList<CartItem> = arrayListOf()
        scope.launch {
            delay(delay)
            val items = table?.retrieveAll()?.awaitAsList()
            items?.forEach { item ->
                convert(item) { itm ->
                    itemsFetched.add(itm)
                }
            }
            callback(itemsFetched)
        }
    }


    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.productTableQueries
        scope.launch {
            val dateFromDb = table?.lastSyncDate(id)?.awaitAsOneOrNull()
            callback(dateFromDb?.UpdatedAt)
        }
    }


    fun convert(item: CartItemTable, callback: (CartItem) -> Unit) {
        try {
            val prd = jsonDecoder.decodeFromString<Product>((item.Product ?: Product()).toString())
            callback(
                CartItem(
                    uniqueID = item.UniqueID,
                    _id = item._id,
                    userID = item.UserID,
                    businessID = item.BusinessID,
                    selectedBusinessID = item.SelectedBusinessID,
                    customerId = item.CustomerId,
                    productId = item.ProductID ?: "",
                    product = prd,
                    price = jsonDecoder.decodeFromString(item.Price ?: ProductPrice().toString()),
                    quantity = item.Quantity,
                    finalPrice = item.FinalPrice,
                    createdAt = item.CreatedAt,
                    updatedAt = item.UpdatedAt,
                )
            )
        } catch (error: Exception) {
            ProductDatabaseTable().find(item.ProductID ?: "") { product ->
                if (product != null) {
                    callback(
                        CartItem(
                            uniqueID = item.UniqueID,
                            _id = item._id,
                            userID = item.UserID,
                            businessID = item.BusinessID,
                            selectedBusinessID = item.SelectedBusinessID,
                            customerId = item.CustomerId,
                            productId = item.ProductID ?: "",
                            product = product,
                            price = jsonDecoder.decodeFromString(
                                item.Price ?: ProductPrice().toString()
                            ),
                            quantity = item.Quantity,
                            finalPrice = item.FinalPrice,
                            createdAt = item.CreatedAt,
                            updatedAt = item.UpdatedAt,
                        )
                    )
                }
            }
        }
    }
}