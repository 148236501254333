package theme

import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.darkColorScheme
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable


// Material 3 color schemes
private val DarkColorScheme = darkColorScheme(
    primary = ThemeColor.shared().PrimaryColor,
    secondary = ThemeColor.shared().SecondaryColor,
    tertiary = ThemeColor.shared().TertiaryColor
)

private val LightColorScheme = lightColorScheme(
    primary = ThemeColor.shared().PrimaryColor,
    secondary = ThemeColor.shared().SecondaryColor,
    tertiary = ThemeColor.shared().TertiaryColor

)

@Composable
fun FriendlyTheme(
    darkTheme: Boolean = false,
    content: @Composable () -> Unit
) {
    val replyColorScheme = when {
        darkTheme -> DarkColorScheme
        else -> LightColorScheme
    }
    MaterialTheme(
        colorScheme = replyColorScheme,
        content = content
    )
}