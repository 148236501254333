package feature.auth.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SendOTPRequest(
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String
)

@Serializable
data class CheckAccountRequest(
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String
)

@Serializable
data class SetPasswordRequest(
    @SerialName("_id") val id: String,
    @SerialName("Password") val password: String,
)

@Serializable
data class AuthRequest(
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String,
    @SerialName("Name") val name: String
)

@Serializable
data class UpdateFCMTokenRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("FCMToken") val token: String,
    @SerialName("DeviceID") val deviceId: String
)

@Serializable
data class LanguageRequest(
    @SerialName("code") val code: String,
)