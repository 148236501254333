package storage.database

import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.FriendlyDatabase
import storage.AppStorage

class AppDatabase {
    val database: FriendlyDatabase? get() = AppStorage.shared().database
    val driver: SqlDriver? get() = AppStorage.shared().driver

    init {
        instance = this
    }

    companion object {
        private var instance: AppDatabase? = null
        fun shared(): AppDatabase {
            if (instance != null) {
                return instance as AppDatabase
            } else {
                instance = AppDatabase()
                return instance!!
            }
        }
    }


}