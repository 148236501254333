package feature.customer.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FindCustomerRequest(
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("DialCode") val dialCode: String,
    @SerialName("BusinessID") val businessID: String,
)

@Serializable
data class FetchCustomerRequest(
    @SerialName("BusinessID") val businessID: String,
    @SerialName("LastSyncDate") val lastSyncDate: String?
)

@Serializable
data class CreateCustomerRequest(
    @SerialName("UserID") val userId: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("Name") val name: String,
    @SerialName("DialCode") val dialCode: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("EmailID") val email: String,
    @SerialName("Barcode") val barCode: String,
    @SerialName("DeviceID") val deviceID: String,
)

@Serializable
data class UpdateCustomerRequest(
    @SerialName("_id") val id: String,
    @SerialName("UserID") val userId: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("Name") val name: String,
    @SerialName("DialCode") val dialCode: String,
    @SerialName("MobileNumber") val mobile: String,
    @SerialName("EmailID") val email: String,
    @SerialName("Barcode") val barCode: String,
    @SerialName("DeviceID") val deviceID: String,
)