package presentation.screens.business.inventory.category.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.productCategory.model.ProductCategory
import feature.productCategory.viewModel.ProductCategoryViewModel
import theme.ThemeColor

@Composable
fun SelectCategoryRow(selected: String?, onChange: (ProductCategory) -> Unit = {}) {
    val viewModel = ProductCategoryViewModel()
    val allCategory = viewModel.allCategory.collectAsState()
    LaunchedEffect(key1 = Unit) {
        viewModel.retrieve()
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("Category")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allCategory.value.count()) {
                HorizontalCategoryCard(
                    allCategory.value[it],
                    isSelected = selected == allCategory.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}