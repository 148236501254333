package component.image

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import feature.mediaFile.model.MediaFile
import feature.mediaFile.repository.MediaFileRepository
import feature.mediaFile.viewModel.MediaFileViewModel
import native.FilePickerView
import theme.ThemeColor

@Composable
fun CircularImageView(
    id: String,
    isEditable: Boolean = false,
    viewModel: MediaFileViewModel = MediaFileViewModel(MediaFileRepository())
) {
    var media: MediaFile? by remember { mutableStateOf<MediaFile?>(null) }
    LaunchedEffect(key1 = Unit) {
        viewModel.loadMedia(id) {
            media = it
        }
    }
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(180.dp)
            .background(ThemeColor.shared().White, shape = RoundedCornerShape(10.dp))
            .clip(RoundedCornerShape(40.dp))
            .padding(10.dp),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier
                .size(160.dp)
                .clip(CircleShape)
                .padding(0.dp)
                .background(ThemeColor.shared().White, shape = RoundedCornerShape(80.dp))
                .border(2.dp, ThemeColor.shared().PrimaryColor, CircleShape),

            contentAlignment = Alignment.Center
        ) {
            FilePickerView(id = id, media, isEditable, viewModel)
        }
    }
}