package feature.payment.viewModel

import feature.alert.handler.AlertHandler
import feature.app.AppViewModel
import feature.app.NavigationParam
import feature.auth.handler.AuthHandler
import feature.payment.handler.PaymentHandler
import feature.payment.model.Payment
import feature.payment.model.RazorpayPayment
import feature.payment.network.CreatePaymentRequest
import feature.payment.network.PaymentNetwork
import feature.payment.network.RetrievePaymentRequest
import feature.payment.repository.PaymentRepository

class PaymentViewModel : AppViewModel() {
    val repository: PaymentRepository = PaymentHandler.shared().repository
    val allPayments = repository.allPayment

    fun create(
        param: NavigationParam?,
        callBack: (Payment?) -> Unit
    ) {
        val request = CreatePaymentRequest(
            paymentID = timeStamp,
            userId = AuthHandler.shared().userId,
            mobile = AuthHandler.shared().repository.authUser.value.mobile,
            businessID = param?.selectedBusiness?.Id,
            selectedBusinessID = param?.selectedBusiness?.Id,
            addressID = param?.address?._id,
            deviceId = AuthHandler.shared().deviceId,
            pricing = param?.order?.pricing,
            paymentGateway = "PHONE_PE",
            deliveryType = if (param?.address?._id.isNullOrEmpty()) "TAKE_AWAY" else "DELIVERY",

            )
        startLoading()
        PaymentNetwork.shared().create(request) { response ->
            stopLoading()
            if (response?.payload?._id.isNullOrEmpty()) {
                callBack(null)
            } else {
                callBack(response!!.payload)
            }
        }
    }

    fun find(
        id: String,
        callBack: (Payment?) -> Unit
    ) {
        val request = RetrievePaymentRequest(
            id = id
        )
        startLoading()
        PaymentNetwork.shared().find(request) { response ->
            stopLoading()
            if (response?.payload?._id.isNullOrEmpty()) {
                callBack(null)
            } else {
                callBack(response!!.payload)
            }
        }
    }

    fun onRazorPaySuccess(data: String) {
        val paymentStatus: RazorpayPayment =
            jsonDecoder.decodeFromString(data)
        AlertHandler.shared().showAlert("Oops!", "${paymentStatus}")
    }


}