package storage.database.table

import appConfig.AppConfig
import graph.PlatformType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.serialization.json.Json

open class DatabaseTable {
    val jsonDecoder = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }
    var scope: CoroutineScope = CoroutineScope(Job() + Dispatchers.Default)
    val delay: Long
        get() {
            if (AppConfig.shared().platformType == PlatformType.Web) {
                return 100L
            } else {
                return 0L
            }
        }
}