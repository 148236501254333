package presentation.screens.common.account.selectLanguageScreen

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.auth.viewModel.AuthViewModel
import locale.localization

class SelectLanguageScreen : Screen {
    @Composable
    override fun Content() {
        val viewModel = AuthViewModel()
        val allLanguage = viewModel.getAllLanguageList()
        val selected = viewModel.selectedLanguage.collectAsState()
        val navigator = LocalNavigator.current
        Column {
            TopNavBar(
                localization().accounts.languageTitle, null, onBackPress = {
                    navigator?.pop()
                }
            )
            AppLoader(viewModel)
            LazyColumn(
                modifier = Modifier
                    .fillMaxSize().padding(bottom = 60.dp)
            ) {
                item {
                    Text(
                        modifier = Modifier.weight(1f).padding(end = 8.dp),
                        text = "${localization().accounts.totalLanguage} = ${allLanguage.count()}",
                        fontSize = 14.sp,
                        textAlign = TextAlign.Start
                    )
                }
                items(allLanguage.count()) { index ->
                    SelectLanguageCard(allLanguage[index], selected.value) {
                        viewModel.updateLanguage(it)
                    }
                }
            }
        }
    }
}