package storage.database.table.job_role

import app.cash.sqldelight.async.coroutines.awaitAsList
import com.friendly.common.database.JobRoleTable
import feature.employee.model.JobRole
import kotlinx.coroutines.launch
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class JobRoleDatabaseTable : DatabaseTable() {
    fun insert(item: JobRole?) {
        val table = AppDatabase.shared().database?.jobRoleTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    item.Id,
                    item.Name,
                    item.Description,
                    item.IsDeleted!!,
                    item.CreatedAt,
                    item.UpdatedAt,
                    item.__v
                )
            }
        }
    }

    fun insert(items: List<JobRole>?) {
        val table = AppDatabase.shared().database?.jobRoleTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    item.Id,
                    item.Name,
                    item.Description,
                    item.IsDeleted!!,
                    item.CreatedAt,
                    item.UpdatedAt,
                    item.__v
                )
            }
        }
    }

    fun retrieve(callback: (ArrayList<JobRole>) -> Unit) {
        val table = AppDatabase.shared().database?.jobRoleTableQueries
        val allItemsFetched: ArrayList<JobRole> = arrayListOf()
        scope.launch {
            val items = table?.retrieve()?.awaitAsList()
            items?.forEach { item ->
                allItemsFetched.add(convert(item))
            }
            callback(allItemsFetched)
        }
    }

    fun convert(item: JobRoleTable): JobRole {
        return JobRole(
            Id = item._id,
            Name = item.Name,
            IsDeleted = item.IsDeleted,
            Description = item.Description,
            CreatedAt = item.CreatedAt,
            UpdatedAt = item.UpdatedAt,
            __v = item.__v,
        )
    }
}