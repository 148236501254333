package component.navigation

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.button.DefaultButton
import friendly.shared.generated.resources.Res
import friendly.shared.generated.resources.business_logo_web
import navigation.AppNavigator
import navigation.containers.ScreenName
import org.jetbrains.compose.resources.painterResource
import theme.ThemeColor

@Composable
fun WebHomeNavBar() {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(60.dp)
            .background(color = ThemeColor.shared().cardBackground),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        Box(
            modifier = Modifier
                .wrapContentHeight()
                .wrapContentWidth()
                .padding(bottom = 8.dp)

        ) {
            Image(
                modifier = Modifier.height(100.dp).width(100.dp)
                    .padding(start = 0.dp, end = 0.dp, top = 0.dp),
                painter = painterResource(Res.drawable.business_logo_web),
                contentDescription = null
            )
        }
        Row(modifier = Modifier.wrapContentWidth().weight(1f)) {
            Text(
                "Pricing", fontSize = 20.sp, modifier = Modifier
                    .padding(start = 20.dp, bottom = 8.dp), color = ThemeColor.shared().PrimaryColor
            )

            Text(
                "Register", fontSize = 20.sp, modifier = Modifier
                    .padding(start = 20.dp, bottom = 8.dp).clickable {
                        AppNavigator.shared().navigateTo(
                            ScreenName.LOGIN
                        )
                    }, color = ThemeColor.shared().PrimaryColor
            )
            Text(
                "Login", fontSize = 20.sp, modifier = Modifier
                    .padding(start = 20.dp, bottom = 8.dp).clickable {
                        AppNavigator.shared().navigateTo(
                            ScreenName.LOGIN
                        )
                    },
                color = ThemeColor.shared().PrimaryColor
            )
        }
        Box(
            modifier = Modifier.wrapContentHeight().width(200.dp)
                .padding(top = 12.dp, bottom = 12.dp)
        ) {
            DefaultButton("Get Started", {
                AppNavigator.shared().navigateTo(
                    ScreenName.LOGIN
                )
            })
        }
    }
}