package feature.address.model

import feature.address.network.PinCode
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Address(
    @SerialName("UniqueID") var UniqueID: Long? = 0,
    @SerialName("_id") var _id: String = "",
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("FeatureObjectID") var FeatureObjectID: String? = null,
    @SerialName("Name") var Name: String? = null,
    @SerialName("ZipCode") var ZipCode: Long? = null,
    @SerialName("Country") var Country: String? = "IN",
    @SerialName("State") var State: String? = null,
    @SerialName("City") var City: String? = null,
    @SerialName("Area") var Area: String? = null,
    @SerialName("LandMark") var LandMark: String? = null,
    @SerialName("House") var House: String? = null,
    @SerialName("FlatNumber") var FlatNumber: String? = null,
    @SerialName("Floor") var Floor: String? = null,
    @SerialName("MobileNumber") var MobileNumber: String? = null,
    @SerialName("Type") var Type: String? = null,
    @SerialName("Location") var Location: LocationCoordinate? = null,
    @SerialName("IsPrimary") var IsPrimary: Boolean = false,
    @SerialName("IsDeleted") var IsDeleted: Boolean = false,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
) {
    fun usingPin(pinCode: PinCode): Address {
        State = pinCode.state
        Area = pinCode.district
        City = pinCode.city
        Area = pinCode.area
        try {
            ZipCode = pinCode.pinCode?.toLong()
        } catch (e: Exception) {

        }
        try {
            if (!pinCode.latitude.isNullOrEmpty() && !pinCode.longitude.isNullOrEmpty()) {
                Location = LocationCoordinate(
                    latitude = pinCode.latitude.toDouble(),
                    longitude = pinCode.latitude.toDouble(),
                    type = "Point",
                    coordinate = arrayListOf(
                        pinCode.latitude.toDouble(),
                        pinCode.latitude.toDouble()
                    )
                )
            }
        } catch (e: Exception) {

        }


        return this
    }
}