package feature.businessConfig.network

import common.constants.Server
import feature.alert.handler.AlertHandler
import feature.businessConfig.event.BusinessConfigEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient


class BusinessConfigNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: BusinessConfigNetwork? = null
        fun shared(): BusinessConfigNetwork {
            if (instance != null) {
                return instance as BusinessConfigNetwork
            } else {
                instance = BusinessConfigNetwork()
                return instance as BusinessConfigNetwork
            }
        }
    }

    suspend fun findBusinessConfigByMobile(
        request: FindBusinessConfigRequest,
        onResult: (FindBusinessConfigResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(BusinessConfigEvent.RETRIEVE_ALL_CURRENCY.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun createBusinessConfig(
        request: CreateBusinessConfigRequest,
        onResult: (CreateBusinessConfigResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(BusinessConfigEvent.CREATE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun updateBusinessConfig(
        request: UpdateBusinessConfigRequest,
        onResult: (UpdateBusinessConfigResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(BusinessConfigEvent.UPDATE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
            AlertHandler.shared()
                .showSuccessAlert(
                    t.message ?: "",
                    "Something went wrong, please try after some time"
                )
        }
    }

    suspend fun retrieveBusinessConfig(
        request: FetchBusinessConfigRequest,
        onResult: (FetchBusinessConfigResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(BusinessConfigEvent.RETRIEVE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
//            AlertHandler.shared().showSuccessAlert(t.message ?: "", "")
            onResult(null)
        }
    }

    suspend fun retrieveAllCurrency(
        request: FetchAllCurrencyRequest,
        onResult: (FetchAllCurrencyResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(BusinessConfigEvent.RETRIEVE_ALL_CURRENCY.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            AlertHandler.shared().showAlert(t.message ?: "", "")

            onResult(null)
        }
    }

}