package presentation.screens.business.inventory.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.inventory.model.size.Size
import feature.inventory.viewModel.size.SizeViewModel
import theme.ThemeColor

@Composable
fun SelectSizeRow(selected: String?, onChange: (Size) -> Unit = {}) {
    val viewModel = SizeViewModel()
    val allSize = viewModel.allSize.collectAsState()
    LaunchedEffect(key1 = Unit) {
        viewModel.loadAllSize()
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("Size")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allSize.value.count()) {
                HorizontalSizeCard(
                    allSize.value[it],
                    isSelected = selected == allSize.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}