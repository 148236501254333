package feature.offerBanner.network

import feature.offerBanner.model.OfferBanner
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OfferBannerRetrieveResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<OfferBanner>,
)