package theme

import androidx.compose.ui.graphics.Color

class ThemeColor {
    init {
        instance = this
    }

    companion object {
        private var instance: ThemeColor? = null
        fun shared(): ThemeColor {
            if (instance == null) {
                instance = ThemeColor()
            }
            return instance as ThemeColor
        }
    }

    var windowWidth = 0
    var windowHeight = 0
    fun applyCustomTheme() {
//        val colorScheme: ColorScheme = Json.decodeFromString(BusinessConstants().theme)
//        PrimaryColor = Color(colorScheme.primaryColor.toLong())
    }

    val Purple200: Color = Color(0xFFBB86FC)
    val Purple500: Color = Color(0xFF6200EE)
    val Purple700: Color = Color(0xFF3700B3)
    val Teal200: Color = Color(0xFF03DAC5)


    //custom color
    val Purple80: Color = Color(0xFFD0BCFF)

    var PrimaryColor: Color = Color(0xFF2158A5)
    var customerTheme: Color = Color(0xFF1F48C3)
    var businessTheme: Color = Color(0xFF154F55)
    var ecommerceTheme: Color = Color(0xFF1F48C3)

    val PrimaryLightColor: Color = Color(0xFF0631A4)
    val defaultBakground: Color = Color(0xFFF3F5F8)
    val cardBackground: Color = Color(0xFFFFFFFF)
    val SecondaryColor: Color = Color(0xFF979797)
    val TertiaryColor: Color = Color(0xFF979797)
    val TextColor: Color = Color(0xFF757575)
    val placeholderColor: Color = Color(0xFF434A54)
    val TitleColor: Color = Color(0xFF111111)
    val subTitleColor: Color = Color(0xFF63758B)
    val s = Color(0xFF0F172A)
    val White: Color = Color(0xFFFFFFFF)
    val Black: Color = Color(0xFF000000)
    val lightGray: Color = Color(0xFF949494)
    val Grey: Color = Color(0xFF63758B) //jaiz
    val SectionBackground = Color(0xFFF2D1D1) //jaiz
    val headBackground = Color(0xFFFEC7C7) //jaiz)
    val Blue = Color(0xFF0631A4)//jaiz
    val LightBlue = Color(0xFF0631A4)//jaiz
    val Dgreen = Color(0xFF27AE60)//jaiz
    val V = Color(0xFFFEC7C7) //jaiz
    val IX = Color(0xFFE0CDFC)//jaiz
    val X = Color(0xFFFCECA6)//jaiz
    val XI = Color(0xFFCBF3D6)//jaiz
    val deepBlackText = Color(0xFF0F172A)//jaiz
    val skybackground = Color(0x330631A4)//jaiz //D0D5DD
    val NotificationBg = Color(0xFFEDF3FF)//jaiz //EDF3FF


    val cardLightGrayContentBackground = Color(0xFFF5F5F5)
    val dottedLineColor = Color(0xFFD9D9D9)
    val gray: Color = Color(0xFF5A5A5A)

    val Background: Color = Color(0xFFFAFCFE)
    val InputFieldBorder: Color = Color.LightGray
    val Green: Color = Color(0xFF228b22)//ankur
    val Red: Color = Color(0xFFFF0000)//ankur
    val semiTransparent = Color.Black.copy(alpha = 0.4f)
    val iconTint: Color = Color(0xFF0631A4)//ankur
    val linkColor: Color = Color(0xFF0000EE)
    val rowBackgroundColor: Color = Color(0xFFCDEFDB)

    val homeBackground = listOf(
        Color(0xFFFFFFFF),
        Color(0xFFE0B3C8),
        Color(0xFF80ABF0),
        Color(0xFF8B8CE9),
        Color(0xFFACDDE2),
        Color(0xFFFFFFFF),
    )

    val downloadRowBackground = listOf(
        Color(0xFFE4F3EB),
        Color(0xFFE9F2F7),
    )
    val lightGreenText: Color = Color(0xFF018F8C)
}

fun String.toColorLong(): Long {
    if (this[0] == '#') { // Use a long to avoid rollovers on #ffXXXXXX
        var color = this.substring(1).toLong(16)
        if (this.length == 7) { // Set the alpha value
            color = color or -0x1000000
        } else require(this.length == 9) { "Unknown color" }
        return color
    }
    throw IllegalArgumentException("Unknown color")
}

fun String.color(): Color {
    return Color(this.toColorLong())
}
