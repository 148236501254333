package feature.businessConfig.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Currency(
    @SerialName("CurrencySymbol") var symbol: String = "",
    @SerialName("Name") var name: String? = null,
    @SerialName("DecimalDigits") var decimal_digits: Float? = null,
    @SerialName("Rounding") var rounding: Float? = null,
    @SerialName("Code") var code: String? = null,
    @SerialName("NamePlural") var name_plural: String? = null,
)