package presentation.screens.business.inventory.category.create

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import com.friendly.shared.component.ErrorSuggestion
import common.asset.ImageAsset
import component.input.CustomTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.productCategory.viewModel.ProductCategoryViewModel
import locale.localization
import theme.ThemeColor

class CreateCategoryScreen : Screen {


    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = ProductCategoryViewModel()
        var name by remember {
            mutableStateOf(TextFieldValue(""))
        }
        val nameErrorState = remember { mutableStateOf(false) }
        Column(
            modifier = Modifier.fillMaxSize()
                .background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(title = localization().categoryMenu.categoryCreate, null, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            Spacer(modifier = Modifier.height(50.dp))
            CustomTextField(
                value = name.text,
                placeholder = localization().categoryMenu.categoryNamePlaceholder,
                trailingIcon = ImageAsset.person,
                label = localization().categoryMenu.categoryName,
                errorState = nameErrorState.value,
                keyboardType = KeyboardType.Email,
                visualTransformation = VisualTransformation.None
            ) { newEmail ->
                name = newEmail
            }

            Spacer(modifier = Modifier.height(10.dp))
            if (nameErrorState.value) {
                ErrorSuggestion("Please enter valid name.")
            }
            CustomDefaultBtn(shapeSize = 50f, btnText = localization().button.saveButton) {
                val nameErrorState = name.text.length < 4
                if (nameErrorState) {

                } else {
                    viewModel.createNewCategory(name.text) {
                        navigator?.pop()
                    }
                }
            }
        }
    }
}