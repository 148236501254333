package feature.inventory.repository

import feature.inventory.model.product_color.ProductColor
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class ProductColorRepository {
    val productColorLiveData: MutableStateFlow<List<ProductColor>> = MutableStateFlow(arrayListOf())
    val allProductColor: StateFlow<List<ProductColor>>
        get() = productColorLiveData

    val selectedProductColorState: MutableStateFlow<ProductColor?> = MutableStateFlow(null)
    val selectedProductColor: StateFlow<ProductColor?>
        get() = selectedProductColorState

}