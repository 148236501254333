package feature.inventory.model.coupon

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Coupon(
    @SerialName("_id") var Id: String = "",
    @SerialName("CouponID") var CouponID: Long? = null,
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("BusinessID") var BusinessID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("Name") var Name: String? = null,
    @SerialName("Code") var Code: String? = null,
    @SerialName("Type") var Type: Long? = null,
    @SerialName("Value") var Value: Long? = null,
    @SerialName("Description") var Description: String? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)