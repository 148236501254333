package feature.cart.repository

import feature.cart.model.CartItem
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class CartRepository {
    val searchQueryState: MutableStateFlow<String> = MutableStateFlow("")
    val searchQuery: StateFlow<String>
        get() = searchQueryState
    
    val cartItemState: MutableStateFlow<List<CartItem>> = MutableStateFlow(arrayListOf())
    val cartItems: StateFlow<List<CartItem>>
        get() = cartItemState

    val mrpLiveData: MutableStateFlow<Double> = MutableStateFlow(0.0)
    val mrp: StateFlow<Double>
        get() = mrpLiveData

    val discountLiveData: MutableStateFlow<Double> = MutableStateFlow(0.0)
    val discount: StateFlow<Double>
        get() = discountLiveData

    val priceLiveData: MutableStateFlow<Double> = MutableStateFlow(0.0)
    val price: StateFlow<Double>
        get() = priceLiveData

    val subtotalLiveData: MutableStateFlow<Double> = MutableStateFlow(0.0)
    val subtotal: StateFlow<Double>
        get() = subtotalLiveData

    val taxLiveData: MutableStateFlow<Double> = MutableStateFlow(0.0)
    val tax: StateFlow<Double>
        get() = taxLiveData

    val instantDiscountLiveData: MutableStateFlow<Double> = MutableStateFlow(0.0)
    val instantDiscount: StateFlow<Double>
        get() = instantDiscountLiveData

    val totalAmountLiveData: MutableStateFlow<Double> = MutableStateFlow(0.0)
    val totalAmount: StateFlow<Double>
        get() = totalAmountLiveData


}