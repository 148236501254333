package feature.businessConnection.viewModel

import feature.alert.handler.AlertHandler
import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.business.model.Business
import feature.business.viewModel.BusinessViewModel
import feature.businessConnection.handler.BusinessConnectionHandler
import feature.businessConnection.model.BusinessConnection
import feature.businessConnection.network.BusinessConnectionCreateRequest
import feature.businessConnection.network.BusinessConnectionNetwork
import feature.businessConnection.network.BusinessConnectionRetrieveRequest
import feature.businessConnection.repository.BusinessConnectionRepository
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class BusinessConnectionViewModel :
    AppViewModel() {
    val repository: BusinessConnectionRepository =
        BusinessConnectionHandler.shared().repository

    val allData: StateFlow<List<BusinessConnection>>
        get() = repository.allLiveData

    val connectedBusiness: StateFlow<Business?>
        get() = repository.connectedBusiness

    val mediaFile: StateFlow<BusinessConnection>
        get() = repository.mediaFileLiveData


    fun retrieve() {
        val request =
            BusinessConnectionRetrieveRequest(
                BusinessHandler.shared().businessId,
                AuthHandler.shared().userId
            )
        startLoading()
        BusinessConnectionNetwork().retrieve(request) {
            stopLoading()
            if (!it?.payload.isNullOrEmpty()) {
                scope.launch {
                    repository.liveData.emit(it!!.payload)
                }
            }
        }
    }

    fun loadConnectedBusiness(
        businessConnection: BusinessConnection,
        callBack: (Business?) -> Unit
    ) {
        if (businessConnection.fromBusinessId == BusinessHandler.shared().businessId) {
            businessConnection.toBusinessId?.let {
                BusinessViewModel().getBusinessById(it) { business ->
                    scope.launch {
                        callBack(business)
                    }
                }
            }
        } else {
            businessConnection.fromBusinessId?.let {
                BusinessViewModel().getBusinessById(it) { business ->
                    scope.launch {
                        callBack(business)
                    }
                }
            }
        }
    }


    fun create(toBusiness: Business, completion: ((Boolean) -> Unit) = {}) {
        val selfBusiness = BusinessHandler.shared().repository.business
        if (selfBusiness.value.Id.isEmpty()) {
            return
        }
        if (toBusiness.Id.isEmpty()) {
            return
        }
        startLoading()
        val request = BusinessConnectionCreateRequest(
            fromUserId = AuthHandler.shared().userId,
            toUserId = toBusiness.UserID ?: "",
            fromBusinessId = selfBusiness.value.Id,
            toBusinessId = toBusiness.Id,
            name = selfBusiness.value.Name ?: ""
        )
        BusinessConnectionNetwork().create(request) {
            stopLoading()
            if (!it?.payload?._id.isNullOrEmpty()) {
                scope.launch {
                    AlertHandler.shared()
                        .showSuccessAlert("Congrats!", "Connection request sent successfully")
                    completion(true)
                }
            } else {
                completion(false)
            }
        }
    }
}