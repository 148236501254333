package feature.business.network

import common.constants.Server
import feature.business.event.BusinessEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class BusinessNetwork {
    private val decoder = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: BusinessNetwork? = null
        fun shared(): BusinessNetwork {
            if (instance != null) {
                return instance as BusinessNetwork
            } else {
                return BusinessNetwork()
            }
        }
    }


    fun retrieve(
        request: RetrieveBusinessRequest,
        onResult: (RetrieveBusinessResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(BusinessEvent.RETRIEVE.value)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(decoder.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }

    fun retrieveSingleBusiness(
        request: RetrieveSingleBusinessRequest,
        onResult: (RetrieveSingleBusinessResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(BusinessEvent.RETRIEVE_SINGLE.value)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(decoder.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                onResult(null)
            }
        }
    }

    fun create(
        request: CreateBusinessRequest,
        onResult: (CreateBusinessResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(BusinessEvent.CREATE.value)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(decoder.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
                onResult(null)
            }
        }
    }

    //
//    fun updateAddress(
//        request: JSONObject,
//        onResult: (JSONObject?) -> Unit,
//        onError: (String?) -> Unit
//    ) {
//        val retrofit = CustomerNetwork.shared().buildService(NetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            RequestBody.create("application/json".toMediaTypeOrNull(), request.toString())
//        val callBack = ApiResponseCallBack()
//        callBack.onResult = { payload ->
//            onResult(payload as JSONObject)
//        }
//        callBack.onError = { msg ->
//            onError(msg)
//        }
//        retrofit.updateAddress(bodyRequest).enqueue(callBack.callback)
//    }
//
//    fun update(request: JSONObject, onResult: (JSONObject?) -> Unit, onError: (String?) -> Unit) {
//        val retrofit = CustomerNetwork.shared().buildService(NetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            RequestBody.create("application/json".toMediaTypeOrNull(), request.toString())
//        val callBack = ApiResponseCallBack()
//        callBack.onResult = { payload ->
//            onResult(payload as JSONObject)
//        }
//        callBack.onError = { msg ->
//            onError(msg)
//        }
//        retrofit.updateBusiness(bodyRequest).enqueue(callBack.callback)
//    }
    suspend fun findBusiness(
        request: FindBusinessRequest,
        onResult: (FindBusinessResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(BusinessEvent.FIND_BUSINESS.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(decoder.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t.message)
            onResult(null)
        }
    }

    suspend fun findNearby(
        request: FindNearbyBusinessRequest,
        onResult: (FindBusinessResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(BusinessEvent.NEARBY_BUSINESS.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(decoder.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t.message)
            onResult(null)
        }
    }
}