package feature.address.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LocationCoordinate(
    @SerialName("Latitude") var latitude: Double? = null,
    @SerialName("Longitude") var longitude: Double? = null,
    @SerialName("type") var type: String = "Point",
    @SerialName("coordinates") var coordinate: ArrayList<Double> = ArrayList()
)