package feature.inventory.network.tag

import common.constants.Server
import feature.inventory.event.TagEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient


class TagNetwork {
    val json = Json {
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: TagNetwork? = null
        fun shared(): TagNetwork {
            if (instance != null) {
                return instance as TagNetwork
            } else {
                return TagNetwork()
            }
        }
    }

    suspend fun retrieve(
        request: RetrieveTagRequest,
        onResult: (RetrieveTagResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(TagEvent.RETRIEVE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }
}