package presentation.screens.business.customer.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Email
import androidx.compose.material.icons.filled.Person2
import androidx.compose.material.icons.filled.Phone
import androidx.compose.material.icons.filled.QrCodeScanner
import androidx.compose.material.icons.filled.Receipt
import androidx.compose.material3.Icon
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.visual.DottedLine
import feature.customer.model.Customer
import theme.ThemeColor

@Composable
fun CustomerCard(customer: Customer, onClick: (Customer) -> Unit = {}) {
    Surface(
        shape = RoundedCornerShape(16.dp),
        color = ThemeColor.shared().cardBackground,
        modifier = Modifier
            .height(124.dp)
            .padding(start = 8.dp, top = 8.dp, end = 8.dp),
        shadowElevation = 4.dp,
        onClick = { onClick(customer) }
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .padding(start = 8.dp, end = 8.dp, top = 8.dp),

            ) {
            Row(
                modifier = Modifier
                    .height(68.dp).fillMaxWidth()
                    .background(
                        color = ThemeColor.shared().cardLightGrayContentBackground,
                        shape = RoundedCornerShape(8.dp)
                    )
                    .padding(start = 8.dp, top = 8.dp, end = 8.dp)
            ) {
                Box(
                    modifier = Modifier
                        .height(57.dp).width(57.dp)
                        .background(
                            color = ThemeColor.shared().cardLightGrayContentBackground,
                            shape = RoundedCornerShape(8.dp)
                        )
                        .padding(start = 8.dp, top = 8.dp, end = 8.dp)
                ) {
                    Icon(
                        Icons.Default.Person2,
                        contentDescription = "Home",
                        modifier = Modifier.fillMaxSize(),
                        tint = ThemeColor.shared().lightGray
                    )
                }
                Column(Modifier.weight(0.5f).fillMaxHeight().padding(start = 8.dp)) {
                    Row(modifier = Modifier.height(28.dp)) {
                        Text(
                            text = customer.Name ?: "",
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis,
                            modifier = Modifier
                                .height(85.dp).fillMaxWidth(),
                            textAlign = TextAlign.Start,
                        )
                    }
                    Row(modifier = Modifier.height(23.dp).fillMaxWidth()) {
                        Icon(
                            Icons.Default.Email,
                            contentDescription = "Email",
                            modifier = Modifier.width(23.dp).height(23.dp).padding(top = 3.dp),
                            tint = ThemeColor.shared().lightGray
                        )
                        Text(
                            text = customer.EmailID ?: "",
                            maxLines = 2,
                            overflow = TextOverflow.Ellipsis,
                            modifier = Modifier
                                .height(20.dp).fillMaxWidth(),
                            textAlign = TextAlign.Start,
                            color = ThemeColor.shared().lightGray
                        )
                    }
                }
            }
            DottedLine()
            Row(modifier = Modifier.height(24.dp)) {
                Row(
                    modifier = Modifier.height(23.dp).weight(0.5f).fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        Icons.Default.Phone,
                        contentDescription = "Phone",
                        modifier = Modifier.width(23.dp).height(23.dp).padding(top = 3.dp),
                        tint = ThemeColor.shared().lightGray
                    )
                    Text(
                        text = (customer.DialCode ?: "") + (customer.MobileNumber ?: ""),
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Start,
                        fontSize = 12.sp,
                        color = ThemeColor.shared().lightGray
                    )
                }
                Row(
                    modifier = Modifier.height(23.dp).weight(0.5f).fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        Icons.Default.QrCodeScanner,
                        contentDescription = "Barcode",
                        modifier = Modifier.width(23.dp).height(23.dp).padding(top = 3.dp),
                        tint = ThemeColor.shared().lightGray
                    )
                    Text(
                        text = customer.barcode ?: "",
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Start,
                        fontSize = 12.sp,
                        color = ThemeColor.shared().lightGray
                    )
                }
                Row(
                    modifier = Modifier.height(23.dp).weight(0.1f).fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        Icons.Default.Receipt,
                        contentDescription = "invoice",
                        modifier = Modifier.width(23.dp).height(23.dp).padding(top = 3.dp),
                        tint = ThemeColor.shared().lightGray
                    )
                    Text(
                        text = "0",
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Start,
                        fontSize = 12.sp,
                        color = ThemeColor.shared().lightGray
                    )
                }
            }
        }
    }
}
