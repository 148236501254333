package feature.invoice.viewModel

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.invocie.handler.InvoiceHandler
import feature.invocie.repository.InvoiceRepository
import feature.invoice.model.CustomerInvoice
import feature.invoice.network.InvoiceNetwork
import feature.invoice.network.RetrieveInvoiceRequest
import feature.invoice.network.RetrieveSingleInvoiceRequest
import feature.sale.model.Sale
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.invoice.InvoiceDatabaseTable

class InvoiceViewModel : AppViewModel() {
    private val repository: InvoiceRepository = InvoiceHandler.shared().repository
    val customerInvoice: StateFlow<CustomerInvoice?>
        get() = repository.customerInvoice

    val allCustomerInvoice: StateFlow<List<CustomerInvoice>>
        get() = repository.allCustomerInvoice
    val unFilteredCustomerInvoice: StateFlow<List<CustomerInvoice>>
        get() = repository.unFilteredCustomerInvoice
    val allSalesLiveData: StateFlow<List<Sale>>
        get() = repository.allSales

    val searchQuery: StateFlow<String>
        get() = repository.searchQuery

    fun setSelectedInvoice(invoice: CustomerInvoice) {
        scope.launch {
            repository.customerInvoiceLiveData.emit(invoice)
        }
    }

    fun fetchAllInvoice() {
        loadFromStorage()
        startLoading()
        val request = RetrieveInvoiceRequest(
            BusinessHandler.shared().businessId,
            null//repository.lastSyncDate.value
        )
        scope.launch {
            InvoiceNetwork().retrieve(request) {
                stopLoading()
                scope.launch {
                    InvoiceDatabaseTable().insert(it?.payload) {
                        loadFromStorage()
                    }
                }
            }
        }
    }

    fun fetchSingleInvoice(id: String) {
        var invoiceId = 0L
        try {
            invoiceId = id.toLong()
        } catch (_: Exception) {

        }
        loadFromStorage(invoiceId)
        startLoading()
        val request = RetrieveSingleInvoiceRequest(
            invoiceId
        )
        scope.launch {
            InvoiceNetwork().retrieveSingle(request) {
                stopLoading()
                if (it?.payload?.id?.isNotEmpty() == true) {
                    scope.launch {
                        InvoiceDatabaseTable().insert(it.payload)
                        delay(500)
                        loadFromStorage(invoiceId)
                    }
                }
            }
        }
    }

    private fun loadFromStorage() {
        InvoiceDatabaseTable().retrieve(BusinessHandler.shared().businessId) { data ->
            scope.launch {
                repository.allCustomerInvoiceLiveData.emit(data)
            }
        }
    }

    private fun loadFromStorage(id: Long) {
        InvoiceDatabaseTable().find(id) { data ->
            scope.launch {
                repository.customerInvoiceLiveData.emit(data)

            }
        }
    }

    fun loadForUserId(id: String) {
        InvoiceDatabaseTable().retrieveForEmployee(
            BusinessHandler.shared().businessId,
            id
        ) { data ->
            scope.launch {
                repository.allCustomerInvoiceLiveData.emit(data)
            }
        }
    }

    fun loadForUserCustomer(id: String) {
        InvoiceDatabaseTable().retrieveForCustomer(
            BusinessHandler.shared().businessId,
            id
        ) { data ->
            scope.launch {
                repository.allCustomerInvoiceLiveData.emit(data)
            }
        }
    }

//    fun filter(invoiceNumber: Long) {
//        val newFilteredList: ArrayList<CustomerInvoice> = arrayListOf()
//        allCustomerInvoice.value?.forEach {
//            if (it.invoiceNumber == invoiceNumber) {
//                newFilteredList.add(it)
//            }
//        }
//        invoiceRepository.filteredCustomerInvoiceLiveData.postValue(newFilteredList)
//    }
//
//    fun clearAllFilters() {
//        invoiceRepository.filteredCustomerInvoiceLiveData.postValue(invoiceRepository.allCustomerInvoice.value)
//    }

//    fun fetchAllSales() {
//        val user = FriendlyUser()
//        if (customerInvoice.value != null) {
//            var request = JSONObject()
//            request.put(KeyConstant.userId, user._id)
//            request.put(
//                KeyConstant.businessID,
//                BusinessHandler.shared().repository.business.value?.Id
//            )
//            request.put(KeyConstant.salesID, JSONArray(customerInvoice.value!!.sales))
//            request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//            SocketService.shared().send(SocketEvent.RETRIVE_SALES, request)
//        }
//    }
//
//
//    fun now(): String {
//        return SimpleDateFormat("yyyy-MM-dd", Locale.getDefault()).format(Date())
//    }
//
//    fun yesterday(): String {
//        val calendar = Calendar.getInstance()
//        calendar.time = Date()
//        calendar.add(Calendar.DAY_OF_MONTH, -7)
//        return SimpleDateFormat("yyyy-MM-dd", Locale.getDefault()).format(calendar.time)
//    }
//
//    fun lastYear(): String {
//        val calendar = Calendar.getInstance()
//        calendar.time = Date()
//        calendar.add(Calendar.DAY_OF_MONTH, -365)
//        return SimpleDateFormat("yyyy-MM-dd", Locale.getDefault()).format(calendar.time.date)
//    }
//
//    fun insert(invoice: CustomerInvoice) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.customerInvoiceDao()
//                .insert(invoice)
//        }
//    }
//
//    fun insertSale(sale: Sale) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.saleDao()
//                .insert(sale)
//        }
//    }


}