package presentation.screens.customer.store.address

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import component.button.DefaultButton
import component.button.SecondaryButton
import component.empty.EmptyData
import component.input.TextInputField
import component.loader.AppLoader
import component.navigation.PopUpNavBar
import feature.address.model.Address
import feature.address.network.PinCode
import feature.address.viewModel.AddressViewModel
import feature.auth.handler.AuthHandler
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import presentation.screens.common.address.component.SelectAddressCard
import theme.ThemeColor

@Composable
fun SelectAddressModal(
    isOpen: Boolean = false,
    address: Address,
    viewModel: AddressViewModel,
    onClose: () -> Unit,
    onChange: (Address?) -> Unit
) {
    val addressList = viewModel.allAddress.collectAsState()
    val zipCode = viewModel.zipCode.collectAsState()
    val fetchedPinCode: MutableState<List<PinCode>> = remember { mutableStateOf(arrayListOf()) }
    viewModel.findZipCode(zipCode.value) { result ->
        fetchedPinCode.value = result
    }
    LaunchedEffect(key1 = Unit) {
        viewModel.retrieve()
    }
    if (isOpen && AuthHandler.shared().userId.isEmpty()) {
        Dialog(onDismissRequest = { onClose() }) {
            Column(
                modifier = Modifier.width(350.dp).height(500.dp)
                    .background(ThemeColor.shared().cardBackground, RoundedCornerShape(8.dp))
            ) {
                PopUpNavBar(
                    title = "Address",
                    onClose = { onClose() }
                )
                Column(
                    modifier = Modifier.fillMaxWidth().fillMaxHeight().padding(8.dp)
                        .background(ThemeColor.shared().cardBackground),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    TextInputField(
                        "Pin Code", "Please enter Pin Code", zipCode.value, KeyboardType.Number
                    ) {
                        viewModel.setZipCode(it)
                    }
                    LazyColumn(
                        contentPadding = PaddingValues(horizontal = 16.dp, vertical = 8.dp),
                        modifier = Modifier
                            .fillMaxHeight()
                            .fillMaxWidth().weight(1f)
                    ) {
                        items(
                            fetchedPinCode.value.count()
                        ) { index ->
                            SelectAddressCard(
                                address = Address().usingPin(fetchedPinCode.value[index]),
                                isSelected = address == Address().usingPin(fetchedPinCode.value[index]),
                                onClick = { address ->
                                    onChange(address)
                                })
                        }
                    }
                    if (zipCode.value.length >= 5) {
                        DefaultButton("Save and Continue", {

                        })
                    } else {
                        SecondaryButton("Cancel", {

                        })
                    }
                }
            }
        }
    } else if (isOpen) {
        Dialog(onDismissRequest = { onClose() }
        ) {
            Column(
                modifier = Modifier.width(300.dp).height(400.dp)
                    .background(ThemeColor.shared().cardBackground)
            ) {
                AppLoader(viewModel)
                PopUpNavBar(
                    title = localization().address.addressTitle,
                    onClose = { onClose() }
                )
                if (addressList.value.isEmpty()) {
                    EmptyData(name = "Select Address", viewModel = viewModel) {
                    }
                    DefaultButton(
                        title = localization().address.createNewAddress,
                        onClick = {
                            AppNavigator.shared().navigateTo(
                                ScreenName.CREATE_ADDRESS
                            )
                        },
                        modifier = Modifier.fillMaxWidth()
                    )
                } else {
                    LazyColumn(
                        contentPadding = PaddingValues(horizontal = 16.dp, vertical = 8.dp),
                        modifier = Modifier
                            .fillMaxHeight()
                            .fillMaxWidth()
                    ) {
                        items(
                            addressList.value.count()
                        ) { index ->
                            SelectAddressCard(
                                address = addressList.value[index],
                                isSelected = address == addressList.value[index],
                                onClick = { address ->
                                    onChange(address)
                                })
                        }
                    }
                }
            }
        }
    }
}