package presentation.screens.common.auth

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import common.constants.UserDefaults
import component.input.CustomTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.auth.handler.AuthHandler
import feature.auth.viewModel.AuthViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import theme.ThemeColor

class VerifyMPinScreen(override val screenName: ScreenName) : AppScreen {

    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = AuthViewModel()

        var password by remember { mutableStateOf(TextFieldValue("")) }
        var retypePassword by remember { mutableStateOf(TextFieldValue("")) }
        var passwordFromServer = viewModel.password.collectAsState()
        var isValidPassword by remember { mutableStateOf(false) }
        val passwordErrorState = remember {
            mutableStateOf(false)
        }
        viewModel.goToHome = {
            AppNavigator.shared().popAll()
        }
        viewModel.goBack = {
            AppNavigator.shared().pop()
        }
        viewModel.goToRegister = {
            AppNavigator.shared().navigateTo(ScreenName.SIGN_UP)
        }
        viewModel.goToSetMPin = {
            AppNavigator.shared().navigateTo(ScreenName.SET_M_PIN)
        }
        viewModel.goToLoginWithMPin = {
            AppNavigator.shared().navigateTo(
                ScreenName.VERIFY_M_PIN,

                )
        }
        viewModel.goToOtp = {
            AppNavigator.shared().navigateTo(ScreenName.OTP)
        }
        fun onClickSendOtp() {
            viewModel.sendOTP(AuthHandler.shared().mobileNumber, AuthHandler.shared().dialCode)
            viewModel.goToOtp?.let { it() }
        }

        fun onClickContinue() {
            if (password.text.length == 6 && !viewModel.tempAuthUser.value.password.isNullOrEmpty() && password.text == viewModel.tempAuthUser.value.password) {
                UserDefaults.storeCredentials(Json.encodeToString(viewModel.tempAuthUser.value))
                CoroutineScope(Job() + Dispatchers.Default).launch {
                    AuthHandler.shared().repository.isLoggedInState.emit(true)
                    viewModel.goToHome?.let { it1 -> it1() }
                }
            }
        }

        Column(modifier = Modifier.fillMaxWidth().fillMaxHeight()) {
            TopNavBar(title = localization().accountAuthentication.verifyPin, null, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(30.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                CustomTextField(
                    placeholder = "******",
                    trailingIcon = ImageAsset.lock,
                    label = localization().accountAuthentication.sixDigitPin,
                    keyboardType = KeyboardType.Phone,
                    errorState = passwordErrorState.value,
                    visualTransformation = VisualTransformation.None
                ) { newPass ->
                    password = newPass
                }
                Spacer(modifier = Modifier.fillMaxHeight(0.3f))
                CustomDefaultBtn(
                    shapeSize = 50f,
                    btnText = localization().accountAuthentication.continueButton
                ) {
                    onClickContinue()
                }
                Spacer(modifier = Modifier.fillMaxHeight(0.3f))
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .padding(bottom = 50.dp),
                    verticalArrangement = Arrangement.Bottom
                ) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(top = 30.dp),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        Text(
                            text = localization().accountAuthentication.dontRememberPin,
                            color = ThemeColor.shared().TextColor
                        )
                        Text(
                            text = localization().accountAuthentication.loginWithOTP,
                            color = ThemeColor.shared().PrimaryColor,
                            modifier = Modifier.clickable {
                                onClickSendOtp()
                            })
                    }
                }
            }
        }
    }
}