package com.friendly.common.database

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import com.friendly.common.database.shared.newInstance
import com.friendly.common.database.shared.schema
import kotlin.Unit

public interface FriendlyDatabase : SuspendingTransacter {
  public val addressTableQueries: AddressTableQueries

  public val brandTableQueries: BrandTableQueries

  public val businessTableQueries: BusinessTableQueries

  public val cartItemTableQueries: CartItemTableQueries

  public val couponTableQueries: CouponTableQueries

  public val customerTableQueries: CustomerTableQueries

  public val discountTableQueries: DiscountTableQueries

  public val employeeTableQueries: EmployeeTableQueries

  public val inventoryTableQueries: InventoryTableQueries

  public val invoiceTableQueries: InvoiceTableQueries

  public val jobRoleTableQueries: JobRoleTableQueries

  public val mediaFileTableQueries: MediaFileTableQueries

  public val productCategoryTableQueries: ProductCategoryTableQueries

  public val productColorTableQueries: ProductColorTableQueries

  public val productSubCategoryTableQueries: ProductSubCategoryTableQueries

  public val productTableQueries: ProductTableQueries

  public val sizeTableQueries: SizeTableQueries

  public val tagTableQueries: TagTableQueries

  public val uOMTableQueries: UOMTableQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = FriendlyDatabase::class.schema

    public operator fun invoke(driver: SqlDriver): FriendlyDatabase =
        FriendlyDatabase::class.newInstance(driver)
  }
}
