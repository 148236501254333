package feature.inventory.viewModel.brand

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.inventory.handler.BrandHandler
import feature.inventory.model.brand.Brand
import feature.inventory.network.brand.BrandNetwork
import feature.inventory.network.brand.RetrieveBrandRequest
import feature.inventory.repository.BrandRepository
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.brand.BrandDatabaseTable

class BrandViewModel(val repository: BrandRepository = BrandHandler.shared().repository) :
    AppViewModel() {

    val allBrand: StateFlow<List<Brand>>
        get() = repository.brandLiveData

    val selectedBrand: StateFlow<Brand?>
        get() = repository.selectedBrand

    val editBrand: StateFlow<Brand?>
        get() = repository.editBrand

    val brandsState: MutableStateFlow<List<Brand>> = MutableStateFlow(arrayListOf())

    val brands: StateFlow<List<Brand>>
        get() = brandsState

    fun fetchAllBrand() {
        loadFromStorage()
        val request =
            RetrieveBrandRequest(BusinessHandler.shared().repository.business.value.Id)
        // startLoading()
        scope.launch {
            BrandNetwork().retrieve(request) {
                stopLoading()
                BrandDatabaseTable().insert(it?.payload)
                loadFromStorage()
            }
        }
    }

    fun loadAllColor() {
        loadFromStorage()
        if (allBrand.value.isEmpty()) {
            fetchAllBrand()
        }
    }

    private fun loadFromStorage() {
        BrandDatabaseTable().retrieveAll { data ->
            scope.launch {
                repository.brandLiveData.emit(data)
            }
        }
    }
}