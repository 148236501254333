package common.constants

import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set

object UserDefaults {
    private val settings: Settings = Settings()

    fun storeCredentials(value: String) {
        settings[KeyConstant.userCredentials] = value
    }

    fun retrieveCredentials(): String {
        return settings.getString(KeyConstant.userCredentials, "")
    }

    fun storeMembership(value: String) {
        settings.putString(KeyConstant.membershipDetails, value)
    }

    fun retrieveMembership(): String {
        return settings.get(KeyConstant.membershipDetails, "")
    }

    fun store(key: String, value: String) {
        settings.putString(key, value)
    }

    fun retrieve(key: String): String {
        return settings.getString(key, "")
    }

    fun retrieveMembership(key: String): String {
        return settings.getString(key, "")
    }

    fun remove(key: String) {
        settings.remove(key)
    }


}