package component.image

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import theme.ThemeColor

@Composable
fun FriendlyIcon(
    name: String,
    tintColor: Color = ThemeColor.shared().PrimaryColor,
    size: Int = 30,
    padding: Int = 2,
    paddingLeft: Int = 2,
    paddingRight: Int = 2,
    width: Int = 30

) {
    Box(modifier = Modifier.size(size.dp).padding(padding.dp).width(width.dp)) {
        AsyncImageView(name)
    }

//    Icon(
//        painter = painterResource(name),
//        contentDescription = null,
//        tint = tintColor,
//        modifier = Modifier.size(size.dp).padding(padding.dp).width(width.dp)
//    )
}