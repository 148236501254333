package storage.database.table.inventory.product_sub_category

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.ProductSubCategoryTable
import feature.productSubCategory.model.ProductSubCategory
import kotlinx.coroutines.launch
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class ProductSubCategoryDatabaseTable : DatabaseTable() {
    fun insert(item: ProductSubCategory?) {
        val table = AppDatabase.shared().database?.productSubCategoryTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    item.Id,
                    item.CategoryID,
                    item.UserID,
                    item.BusinessID,
                    item.IsDeleted,
                    item.Name,
                    item.Image,
                    item.CreatedAt,
                    item.UpdatedAt,
                )
            }
        }
    }

    fun insert(items: List<ProductSubCategory>?) {
        val table = AppDatabase.shared().database?.productSubCategoryTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    item.Id,
                    item.CategoryID,
                    item.UserID,
                    item.BusinessID,
                    item.IsDeleted,
                    item.Name,
                    item.Image,
                    item.CreatedAt,
                    item.UpdatedAt,
                )
            }
        }
    }

    fun retrieve(id: String, callback: (List<ProductSubCategory>) -> Unit) {
        val table = AppDatabase.shared().database?.productSubCategoryTableQueries
        val itemsFetched: ArrayList<ProductSubCategory> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun retrieveFor(id: String, categoryId: String, callback: (List<ProductSubCategory>) -> Unit) {
        val table = AppDatabase.shared().database?.productSubCategoryTableQueries
        val itemsFetched: ArrayList<ProductSubCategory> = arrayListOf()
        scope.launch {
            val items = table?.retrieveForCategory(id, categoryId)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun find(id: String, callback: (ProductSubCategory?) -> Unit) {
        val table = AppDatabase.shared().database?.productSubCategoryTableQueries
        var itemsFetched: ProductSubCategory? = null
        scope.launch {
            val items = table?.find(id)?.awaitAsOneOrNull()
            items?.let { item ->
                itemsFetched = convert(item)
            }
            callback(itemsFetched)
        }
    }


    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.productSubCategoryTableQueries
        scope.launch {
            val dateFromDb = table?.lastSyncDate(id)?.awaitAsOneOrNull()
            callback(dateFromDb?.UpdatedAt)
        }
    }

    fun convert(item: ProductSubCategoryTable): ProductSubCategory {
        return ProductSubCategory(
            item._id,
            item.ProductSubCategoryID,
            item.ProductCategoryID,
            item.UserID,
            item.BusinessID,
            item.IsDeleted,
            item.Name,
            item.Image,
            item.CreatedAt,
            item.UpdatedAt,
        )
    }
}