package feature.inventory.viewModel.size

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.inventory.handler.SizeHandler
import feature.inventory.model.size.Size
import feature.inventory.network.size.RetrieveSizeRequest
import feature.inventory.network.size.SizeNetwork
import feature.inventory.repository.SizeRepository
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.size.SizeDatabaseTable

class SizeViewModel(val repository: SizeRepository = SizeHandler.shared().repository) :
    AppViewModel() {

    val allSize: StateFlow<List<Size>>
        get() = repository.sizeLiveData

    val selectedSize: StateFlow<Size?>
        get() = repository.selectedSize

    fun fetchAllSize() {
        loadFromStorage()
        val request =
            RetrieveSizeRequest(BusinessHandler.shared().repository.business.value.Id)
        // startLoading()
        scope.launch {
            SizeNetwork().retrieve(request) {
                stopLoading()
                SizeDatabaseTable().insert(it?.payload)
                loadFromStorage()
            }
        }
    }

    fun loadAllSize() {
        loadFromStorage()
        if (allSize.value.isEmpty()) {
            fetchAllSize()
        }
    }

    private fun loadFromStorage() {
        SizeDatabaseTable().retrieveAll { data ->
            scope.launch {
                repository.sizeLiveData.emit(data)
            }
        }
    }

    fun getSizeById(id: String?, callback: (Size?) -> Unit) {
        SizeDatabaseTable().find(id) { data ->
            scope.launch {
                callback(data)
            }
        }
    }
}