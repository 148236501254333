package presentation.screens.common.account

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowCircleRight
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Power
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import appConfig.AppConfig
import cafe.adriel.voyager.navigator.LocalNavigator
import common.asset.ImageAsset
import component.address.AddressRow
import component.image.CircularImageView
import component.image.FriendlyIcon
import component.navigation.TopNavBar
import component.profile.MobileNumberRow
import component.profile.ProfileRow
import feature.alert.handler.AlertHandler
import feature.auth.handler.AuthHandler
import feature.auth.viewModel.AuthViewModel
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.common.account.component.LanguageRow
import presentation.screens.common.auth.LoginScreen
import theme.ThemeColor

class AccountScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val viewModel = AuthViewModel()
        val selectedLanguage = viewModel.selectedLanguage.collectAsState()
        val user = viewModel.authUser.collectAsState()
        val isLoggedIn = viewModel.isLoggedIn.collectAsState()
        LaunchedEffect(key1 = Unit) {
            AuthHandler.shared().checkIfLoggedIn()
        }
        val navigator = LocalNavigator.current
        if (isLoggedIn.value) {
            Column(
                modifier = Modifier
                    .fillMaxSize().padding(bottom = 60.dp)
            ) {
                TopNavBar(localization().accounts.accountsTitle, navigationParam)
                LazyColumn(
                    modifier = Modifier
                        .fillMaxWidth().weight(1f)
                ) {
                    item {
//                        AppLoader(viewModel)
                        CircularImageView(id = user.value.id, true)
                    }
                    item {
                        Spacer(modifier = Modifier.height(15.dp))
                        ProfileRow(viewModel)
                        Spacer(modifier = Modifier.height(15.dp))
                        MobileNumberRow(viewModel)
                        Spacer(modifier = Modifier.height(15.dp))
                        AddressRow(onClick = {
                            AppNavigator.shared().navigateTo(
                                ScreenName.ADDRESS_LIST
                            )
                        })
                        Spacer(modifier = Modifier.height(15.dp))
                        LanguageRow(selectedLanguage.value)
                        Spacer(modifier = Modifier.height(15.dp))
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .height(60.dp)
                                .padding(start = 8.dp, end = 8.dp)
                                .background(Color(0x8DB3B0B0), shape = RoundedCornerShape(10.dp))
                                .clip(RoundedCornerShape(10.dp))
                                .clickable {
                                    viewModel.logout()
                                    AppNavigator.shared().navigateTo(ScreenName.DELETE_ACCOUNT)
                                }
                                .padding(5.dp),
                            horizontalArrangement = Arrangement.SpaceBetween,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Icon(
                                Icons.Filled.Delete,
                                contentDescription = "Delete",
                                modifier = Modifier.weight(0.05f),
                                tint = ThemeColor.shared().Red
                            )
                            Text("Delete Account", modifier = Modifier.weight(0.2f))
                            Icon(
                                Icons.Filled.Delete,
                                "Delete",
                                modifier = Modifier.weight(0.03f),
                                tint = ThemeColor.shared().Red
                            )
                        }
                        Spacer(modifier = Modifier.height(15.dp))
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .height(60.dp)
                                .padding(start = 8.dp, end = 8.dp)
                                .background(Color(0x8DB3B0B0), shape = RoundedCornerShape(10.dp))
                                .clip(RoundedCornerShape(10.dp))
                                .clickable {
                                    viewModel.logout()
                                    AlertHandler.shared().showAlert(
                                        localization().accounts.logoutAlertTitle,
                                        localization().accounts.logoutAlertMessage
                                    )
                                }
                                .padding(5.dp),
                            horizontalArrangement = Arrangement.SpaceBetween,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Icon(
                                Icons.Filled.Power,
                                contentDescription = "Home",
                                modifier = Modifier.weight(0.05f),
                                tint = ThemeColor.shared().iconTint
                            )
                            Text(localization().accounts.logout, modifier = Modifier.weight(0.2f))
                            Icon(
                                Icons.Filled.ArrowCircleRight,
                                "Language",
                                modifier = Modifier.weight(0.03f),
                                tint = ThemeColor.shared().iconTint
                            )
                        }


                    }
                }
                Text(
                    "Version ${AppConfig.shared().varsion}",
                    textAlign = TextAlign.Center,
                    modifier = Modifier.fillMaxWidth().height(30.dp)
                )
            }
        } else {
            Column {
                TopNavBar(title = localization().accounts.accountsTitle, navigationParam)
                Row(
                    modifier = Modifier
                        .height(80.dp)
                        .padding(start = 8.dp, end = 8.dp, top = 8.dp)
                        .background(Color(0x8DB3B0B0), shape = RoundedCornerShape(10.dp))
                        .clip(RoundedCornerShape(10.dp))
                        .clickable {
                            navigator?.push(LoginScreen())
                        }
                        .padding(5.dp),
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FriendlyIcon(name = ImageAsset.logoutIcon, size = 30)
                    Text(
                        localization().accountAuthentication.loginRegister,
                        modifier = Modifier.weight(0.2f)
                    )
                    Icon(
                        Icons.Filled.ArrowCircleRight,
                        "Language",
                        modifier = Modifier.weight(0.03f),
                        tint = ThemeColor.shared().iconTint
                    )
                }
                Text(
                    "Version ${AppConfig.shared().varsion}",
                    textAlign = TextAlign.Center,
                    modifier = Modifier.fillMaxWidth()
                )
            }
        }

    }
}

