package feature.notification.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Notification(
    @SerialName("UserID") var userId: String? = null,
    @SerialName("_id") var _id: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("FCMToken") var FCMToken: String? = null,
    @SerialName("EmailID") var emailID: String? = null,
    @SerialName("Title") var title: String? = null,
    @SerialName("Message") var message: String? = null,
    @SerialName("Payload") var payload: NotificationPayload? = null,
    @SerialName("NotificationType") var notificationType: String? = null,
    @SerialName("DeviceID") var deviceID: String? = null,
    @SerialName("Image") var image: String? = null,
    @SerialName("IsSent") var isSent: Boolean? = null,
    @SerialName("IsRead") var isRead: Boolean? = null,
    @SerialName("IsDeleted") var isDeleted: Boolean? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)

@Serializable
data class NotificationPayload(
    @SerialName("UniqueID") var UniqueID: Long = 0,
    @SerialName("_id") var _id: String? = null,
    @SerialName("FeatureObjectID") var featureObjectID: String? = null,
    @SerialName("FromUserID") var fromUserId: String? = null,
    @SerialName("ToUserID") var toUserId: String? = null,
    @SerialName("FromBusinessID") var fromBusinessId: String? = null,
    @SerialName("ToBusinessID") var toBusinessId: String? = null,
    @SerialName("Name") var name: String? = null,
)