package com.friendly.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class DiscountTable(
  public val _id: String,
  public val DiscountID: Long?,
  public val UserID: String?,
  public val BusinessID: String?,
  public val IsDeleted: Boolean?,
  public val Name: String?,
  public val ApplyLimit: Long?,
  public val Value: Long?,
  public val Description: String?,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
)
