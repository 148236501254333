package feature.offerBanner.handler

import feature.offerBanner.model.OfferBanner
import feature.offerBanner.repository.OfferBannerRepository
import feature.offerBanner.viewModel.OfferBannerViewModel

class OfferBannerHandler {


    var viewModel: OfferBannerViewModel? = null
    val repository = OfferBannerRepository()
    var onCreateNew: ((category: OfferBanner) -> Unit)? = null

    init {
        instance = this
    }

    companion object {
        private var instance: OfferBannerHandler? = null
        fun shared(): OfferBannerHandler {
            if (instance != null) {
                return instance as OfferBannerHandler
            } else {
                return OfferBannerHandler()
            }
        }
    }
}