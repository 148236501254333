package feature.inventory.handler

import feature.inventory.repository.SizeRepository
import feature.inventory.viewModel.size.SizeViewModel


class SizeHandler {

    var viewModel: SizeViewModel? = null
    val repository = SizeRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: SizeHandler? = null
        fun shared(): SizeHandler {
            if (instance != null) {
                return instance as SizeHandler
            } else {
                return SizeHandler()
            }
        }
    }

    fun setup(model: SizeViewModel) {
        viewModel = model
    }
}