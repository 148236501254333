package component.offers

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import component.image.AsyncImageView
import feature.offerBanner.model.OfferBanner

@Composable
fun OfferBannerCard(
    offer: OfferBanner,
    onClick: (offer: OfferBanner) -> Unit
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(300.dp)
            .background(Color.White, shape = RoundedCornerShape(10.dp))
            .clip(RoundedCornerShape(10.dp))
            .padding(8.dp)
            .clickable {
                onClick(offer)
            },
        contentAlignment = Alignment.Center
    ) {
        offer.FileURL?.let { AsyncImageView(it) }
    }


}