package component.button

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import theme.ThemeColor

@Composable
fun DefaultButton(title: String, onClick: () -> Unit, modifier: Modifier? = null) {
    var buttonModifier = modifier
    val defaultModifier = Modifier
        .fillMaxWidth()
        .height(50.dp)
        .padding(start = 8.dp, end = 8.dp)
        .background(ThemeColor.shared().PrimaryColor, RoundedCornerShape(8.dp))

    if (buttonModifier != null) {
        buttonModifier = defaultModifier
    }
    if (buttonModifier != null) {
        Button(
            onClick = onClick,
            modifier = buttonModifier,
//            colors = ButtonDefaults.buttonColors(backgroundColor = PrimaryColor)

        ) {
            Text(title)
        }
    } else {
        Button(
            onClick = onClick,
            modifier = defaultModifier,

            ) {
            Text(title)
        }
    }
}