package navigation.containers

enum class ScreenName {
    /* Authentication*/
    LOGIN,
    SIGN_UP,
    SET_M_PIN,
    VERIFY_M_PIN,
    OTP,

    /* My business*/
    BUSINESS_HOMEPAGE,
    BUSINESS_HOME,
    BUSINESS_DETAILS_CONNECTION,
    BUSINESS_INVOICE_LIST,
    BUSINESS_SALE,
    PRIVACY_POLICY,
    BUSINESS_INVENTORY_PRODUCT,
    BUSINESS_INVENTORY_STOCK,
    BUSINESS_INVENTORY_LIST,
    BUSINESS_INVENTORY_STOCK_CREATE,
    BUSINESS_INVENTORY_STOCK_UPDATE,
    BUSINESS_INVENTORY_EDIT_PRODUCT,
    BUSINESS_INVENTORY_CATEGORY,
    BUSINESS_INVENTORY_CATEGORY_DETAILS,
    BUSINESS_INVENTORY_EDIT_CATEGORY,
    BUSINESS_INVENTORY_SUB_CATEGORY,
    BUSINESS_INVENTORY_SUB_CATEGORY_DETAILS,
    BUSINESS_INVENTORY_SUB_CATEGORY_EDIT,
    MY_BUSINESS_STORE,
    MY_BUSINESS_PROFILE,
    MY_BUSINESS_EDIT,
    SELECT_BUSINESS,
    BUSINESS_DASHBOARD,
    BUSINESS_EMPLOYEE,
    BUSINESS_CUSTOMERS,
    SELF_BUSINESS_LIST,
    INVENTORY_PRODUCT,
    BUSINESS_NOTIFICATION,
    SELECT_BUSINESS_TYPE,
    CREATE_BUSINESS,
    MY_BUSINESS_CART,
    INVOICES,
    CREATE_PRODUCT,
    PRODUCT_DETAILS,
    CREATE_CUSTOMER,
    CUSTOMER_DETAILS,
    ADDRESS_LIST,
    CREATE_ADDRESS,
    EDIT_ADDRESS,
    SELECT_LANGUAGE,
    DELETE_ACCOUNT,

    /*store*/
    MY_BUSINESS_CURRENCY,
    MY_BUSINESS_BANK_ACCOUNT,
    MY_BUSINESS_BANNER,
    ADVERTISE_TO_CUSTOMER,
    MY_BUSINESS_APP,
    MY_BUSINESS_APP_DASHBOARD,
    MY_BUSINESS_FINANCE,
    MY_BUSINESS_MARKETING,
    SHOPPING_CART,

    /* Others business profile */
    OTHER_BUSINESS_DETAILS,
    OTHER_BUSINESS_PROFILE,
    STORE_DETAILS_SCREEN,
    EMPLOYEE_DETAIL_SCREEN,

    /*common*/
    ACCOUNT_SCREEN,
    SCANNER_SCREEN,
    PAYMENT_PAY_SCREEN,

    /*customer*/
    CUSTOMER_HOME,
    CUSTOMER_INVOICES,
    CUSTOMER_ORDERS,
    ORDER_PAYMENT,
    ORDER_LIST,
    ORDER_DETAIL,
    SHOP_BY_CATEGORY,
    NEAR_BY_STORES
}