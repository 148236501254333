package feature.businessType.viewModel

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.businessType.handler.BusinessTypeHandler
import feature.businessType.model.BusinessType
import feature.businessType.network.BusinessTypeNetwork
import feature.businessType.network.BusinessTypeRequest
import feature.businessType.repository.BusinessTypeRepository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class BusinessTypeViewModel :
    AppViewModel() {
    val repository: BusinessTypeRepository = BusinessTypeHandler.shared().repository
    val allBusinessType: StateFlow<List<BusinessType>>
        get() = repository.allBusinessTypeLiveData

    val businessType: StateFlow<BusinessType?>
        get() = repository.businessType

    fun retrieve(completion: (() -> Unit) = {}) {
        val business = BusinessHandler.shared().repository.business
        val request = BusinessTypeRequest(business.value.Id)
        BusinessTypeNetwork().retrieve(request) {
            if (!it?.payload.isNullOrEmpty()) {
                CoroutineScope(Job() + Dispatchers.Default).launch {
                    repository.allBusinessTypeLiveData.emit(it!!.payload)
                    completion()
                }
            } else {
                completion()
            }
        }
    }

    fun setSelectedBusinessType(type: BusinessType) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            repository.businessTypeState.emit(type)
        }
    }

    fun findById(id: String, completion: ((BusinessType) -> Unit)) {
        if (allBusinessType.value.isEmpty()) {
            retrieve {
                allBusinessType.value.forEach { type ->
                    if (type.id == id) {
                        completion(type)
                    }
                }
            }
        } else {
            allBusinessType.value.forEach { type ->
                if (type.id == id) {
                    completion(type)
                }
            }
        }
    }
}