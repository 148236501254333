package feature.inventory.repository

import feature.inventory.model.size.Size
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class SizeRepository {
    val sizeLiveData: MutableStateFlow<List<Size>> = MutableStateFlow(arrayListOf())
    val allSize: StateFlow<List<Size>>
        get() = sizeLiveData

    val selectedSizeState: MutableStateFlow<Size?> = MutableStateFlow(null)
    val selectedSize: StateFlow<Size?>
        get() = selectedSizeState

}