package presentation.screens.customer.store.nearby

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.input.SearchBar
import component.loader.AppLoader
import component.navigation.CustomerHomeTopNavigation
import feature.address.model.LocationCoordinate
import feature.address.viewModel.AddressViewModel
import feature.app.NavigationParam
import feature.business.viewModel.BusinessViewModel
import feature.businessType.viewModel.BusinessTypeViewModel
import feature.cart.viewModel.CartViewModel
import native.locations.LocationsFeature
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.customer.store.address.SelectAddressModal
import presentation.screens.customer.store.component.AllCategoryCard
import presentation.screens.customer.store.component.StoreCard
import theme.ThemeColor

class NearbyStoresScreen(override val screenName: ScreenName) : AppScreen {

    @Composable
    override fun Content() {
        super.Content()
        val viewModel = CartViewModel()
        val businessViewModel = BusinessViewModel()
        val addressViewModel = AddressViewModel()
        val allBusiness = businessViewModel.allNearbyBusiness.collectAsState()
        val businessTypeViewModel = BusinessTypeViewModel()
        val allBusinessType = businessTypeViewModel.allBusinessType.collectAsState()
        val searchQuery = businessViewModel.searchQuery.collectAsState()
        val primaryAddress = addressViewModel.primaryAddress.collectAsState()
        val primaryAddressEditing = addressViewModel.primaryAddressEditing.collectAsState()
        fun loadNearbyStores() {
            if (primaryAddress.value.Location?.coordinate?.count() == 2) {
                businessViewModel.findNearby(
                    query = searchQuery.value,
                    LocationCoordinate(
                        coordinate = arrayListOf(
                            primaryAddress.value.Location!!.coordinate[0],
                            primaryAddress.value.Location!!.coordinate[1]
                        )
                    )
                )
            }
        }
        LocationsFeature.shared().onCaptureLocation = { lat, long ->
            addressViewModel.setPickedLocation(
                LocationCoordinate(
                    coordinate = arrayListOf(
                        lat,
                        long
                    )
                )
            )
            if (lat > 0 && long > 0) {
                primaryAddress.value.Location = LocationCoordinate(
                    latitude = lat,
                    longitude = long,
                    coordinate = arrayListOf(lat, long)
                )
                addressViewModel.setPrimaryAddress(primaryAddress.value) {
                    loadNearbyStores()
                }
            }

            addressViewModel.setPrimaryAddressForCustomer()
            loadNearbyStores()
        }
        var addressModalOpen: Boolean by remember { mutableStateOf(false) }

        LaunchedEffect(key1 = Unit) {
            addressViewModel.loadPrimaryAddress { address ->
                loadNearbyStores()
                if (address?.Location?.longitude == null) {
                    addressModalOpen = true
                }
            }
            businessTypeViewModel.retrieve()
            LocationsFeature.shared().captureLocation()
        }
        Column(
            modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            AppLoader(businessViewModel)
            SelectAddressModal(
                isOpen = addressModalOpen,
                primaryAddressEditing.value,
                addressViewModel,
                onClose = {
                    addressModalOpen = false
                    loadNearbyStores()
                },
                onChange = { addr ->
                    addressViewModel.setPrimaryAddress(addr) {
                        loadNearbyStores()
                    }
                    addressModalOpen = false
                })
            CustomerHomeTopNavigation(
                address = primaryAddress.value,
                onClickCart = {
                    navigateTo(ScreenName.SHOPPING_CART, navigationParam)
                },
                refresh = {
                    loadNearbyStores()
                }, onClickAddress = {
                    addressModalOpen = true
                })
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 180.dp),
                modifier = Modifier.weight(1f).padding(
                    start = 0.dp,
                    end = 0.dp,
                    bottom = if (allBusiness.value.isNotEmpty()) 0.dp else (60.dp)
                ),
            ) {
                item(span = { GridItemSpan(maxCurrentLineSpan) }) {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(100.dp)
                            .background(color = ThemeColor.shared().PrimaryColor)
                    ) {
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(top = 25.dp)

                        ) {
                            SearchBar(
                                businessViewModel,
                                searchQuery.value,
                                "Search store here.aaa..."
                            )
                        }
                    }
                }
                item(span = { GridItemSpan(maxCurrentLineSpan) }) {
                    LazyRow(
                        modifier = Modifier.fillMaxWidth().height(40.dp)
                            .background(ThemeColor.shared().White)
                    )
                    {
                        items(allBusinessType.value.count()) {
                            AllCategoryCard(allBusinessType.value[it])
                        }
                    }
                }
                items(allBusiness.value.count()) {
                    StoreCard(allBusiness.value[it]) { business ->
                        navigateTo(
                            ScreenName.STORE_DETAILS_SCREEN,
                            NavigationParam(selectedBusiness = business)
                        )
                    }
                }
            }
        }
    }
}
