package feature.customer.network

import common.constants.Server
import feature.customer.event.CustomerEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient


class CustomerNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: CustomerNetwork? = null
        fun shared(): CustomerNetwork {
            if (instance != null) {
                return instance as CustomerNetwork
            } else {
                return CustomerNetwork()
            }
        }
    }

    suspend fun findCustomerByMobile(
        request: FindCustomerRequest,
        onResult: (FindCustomerResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(CustomerEvent.FIND_BY_MOBILE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun createCustomer(
        request: CreateCustomerRequest,
        onResult: (CreateCustomerResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(CustomerEvent.CREATE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun updateCustomer(
        request: UpdateCustomerRequest,
        onResult: (UpdateCustomerResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(CustomerEvent.UPDATE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun retrieveCustomer(
        request: FetchCustomerRequest,
        onResult: (FetchCustomerResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(CustomerEvent.RETRIEVE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }

}