package storage.database.table.business

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.BusinessTable
import feature.address.model.LocationCoordinate
import feature.business.model.Business
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import storage.database.AppDatabase
import storage.database.table.DatabaseTable
import storage.database.table.employee.EmployeeDatabaseTable

class BusinessDatabaseTable : DatabaseTable() {
    fun insert(item: Business?, callBack: () -> Unit = {}) {
        val table = AppDatabase.shared().database?.businessTableQueries
        if (item != null) {
            scope.launch {
                table?.insert(
                    item.Id,
                    item.BusinessNumber,
                    item.UserID,
                    item.BusinessTypeID,
                    item.Name,
                    jsonDecoder.encodeToString(item.Address),
                    jsonDecoder.encodeToString(item.Location),
                    item.Location?.latitude,
                    item.Location?.longitude,
                    item.DealerName,
                    jsonDecoder.encodeToString(item.ProductTypes),
                    item.GSTNumber,
                    item.panNumber,
                    item.gstVerified,
                    item.panVerified,
                    item.businessVerified,
                    item.Status,
                    item.EmailID,
                    item.mobileNumber,
                    item.DialCode,
                    item.Gender,
                    item.DeviceID,
                    item.FCMToken,
                    item.LastSeen,
                    item.IsPrimary,
                    item.IsDeleted,
                    item.CreatedAt,
                    item.UpdatedAt
                )
            }
        }
        callBack()
    }

    fun insert(items: List<Business>?, callBack: () -> Unit) {
        items?.forEach { item ->
            insert(item) {}
        }
        callBack()
    }

    fun retrieveBy(id: String, callback: (Business?) -> Unit) {
        val table = AppDatabase.shared().database?.businessTableQueries
        scope.launch {
            val item = table?.getBusinessById(
                id
            )?.awaitAsOneOrNull()
            if (item != null) {
                callback(convert(item))
            } else {
                callback(null)
            }

        }
    }

    fun retrieveBy(number: Long, callback: (Business?) -> Unit) {
        val table = AppDatabase.shared().database?.businessTableQueries
        scope.launch {
            val item = table?.getBusinessByNumber(
                number
            )?.awaitAsOneOrNull()
            if (item != null) {
                callback(convert(item))
            } else {
                callback(null)
            }

        }
    }

    fun retrieve(id: String, callback: (List<Business>) -> Unit) {
        val table = AppDatabase.shared().database?.businessTableQueries
        val allItemsFetched: ArrayList<Business> = arrayListOf()
        EmployeeDatabaseTable().retrieveBusinessIds(id) { ids ->
            scope.launch {
                val allBusiness = table?.retrieve(
                    id
                )?.awaitAsList()
                val items = table?.getMyBusiness(
                    ids
                )?.awaitAsList()
                items?.forEach { item ->
                    val business = convert(item)
                    if (business != null && !allItemsFetched.contains(business)) {
                        allItemsFetched.add(business)
                    }
                }
                allBusiness?.forEach { item ->
                    val business = convert(item)
                    if (business != null && !allItemsFetched.contains(business)) {
                        allItemsFetched.add(business)
                    }
                }
                callback(allItemsFetched)
            }
        }
    }

    fun search(query: String, callback: (List<Business>) -> Unit) {
        val table = AppDatabase.shared().database?.businessTableQueries
        val itemsFetched: ArrayList<Business> = arrayListOf()
        scope.launch {
            val items = table?.search("%${query}%", "%${query}%", "%${query}%")?.awaitAsList()
            items?.forEach { item ->
                val business = convert(item)
                if (business != null) {
                    itemsFetched.add(business)
                }
            }
            callback(itemsFetched)
        }
    }

    fun findNearby(
        query: String,
        locationCoordinate: LocationCoordinate?,
        callback: (List<Business>) -> Unit
    ) {
        val table = AppDatabase.shared().database?.businessTableQueries
        val itemsFetched: ArrayList<Business> = arrayListOf()
        scope.launch {
            delay(delay)
            delay(delay)
            delay(delay)
            var items: List<BusinessTable>? = null
            if (locationCoordinate != null && query.isNotEmpty()) {
                items = table?.findNearby(
                    "%${query}%",
                    "%${query}%",
                    "%${query}%",
                    locationCoordinate.latitude,
                    locationCoordinate.latitude,
                    locationCoordinate.longitude,
                    locationCoordinate.longitude,
                    1000.0,
                    1000.0
                )?.awaitAsList()
            } else if (locationCoordinate?.latitude != null && locationCoordinate.longitude != null) {
                items = table?.nearby(
                    locationCoordinate.latitude!! - 1,
                    locationCoordinate.latitude!! + 1,
                    locationCoordinate.longitude!! - 1,
                    locationCoordinate.longitude!! + 1,
                )?.awaitAsList()
            } else {
                items = table?.search(
                    "%${query}%",
                    "%${query}%",
                    "%${query}%",
                )?.awaitAsList()
            }
            items?.forEach { item ->
                val business = convert(item)
                if (business != null) {
                    itemsFetched.add(business)
                }
            }
            callback(itemsFetched)
        }
    }


    private fun convert(item: BusinessTable): Business? {
        try {
            return Business(
                item._id,
                item.BusinessNumber,
                item.UserID,
                item.BusinessTypeID,
                item.Name,
                jsonDecoder.decodeFromString(item.Address ?: "{}"),
                jsonDecoder.decodeFromString(item.Location ?: "{}"),
                item.DealerName,
                jsonDecoder.decodeFromString(item.ProductTypes ?: ""),
                item.GSTNumber,
                item.PanNumber,
                item.GSTVerified,
                item.PanVerified,
                item.BusinessVerified,
                item.Status,
                item.EmailID,
                item.MobileNumber,
                item.DialCode,
                item.Gender,
                item.DeviceID,
                item.FCMToken,
                item.LastSeen,
                item.IsPrimary,
                item.IsDeleted,
                item.CreatedAt,
                item.UpdatedAt,
                null
            )
        } catch (e: Exception) {
            return null
        }
    }
}