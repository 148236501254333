package feature.inventory.viewModel.inventory

import feature.app.AppViewModel
import feature.app.NavigationParam
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.businessConfig.model.Currency
import feature.inventory.handler.InventoryHandler
import feature.inventory.model.Inventory
import feature.inventory.network.inventory.CreateInventoryRequest
import feature.inventory.network.inventory.DeleteInventoryRequest
import feature.inventory.network.inventory.InventoryNetwork
import feature.inventory.network.inventory.RetrieveInventoryRequest
import feature.inventory.network.inventory.UpdateInventoryRequest
import feature.product.model.ProductPrice
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import storage.Database.table.inventory.InventoryDatabaseTable

class InventoryViewModel : AppViewModel() {
    val repository = InventoryHandler.shared().repository
    val searchQuery: StateFlow<String>
        get() = repository.searchQuery
    val allInventory: StateFlow<List<Inventory>>
        get() = repository.allInventory
    val lowestPriceProduct: StateFlow<Inventory?>
        get() = repository.lowestPriceProduct
    val highestPriceProduct: StateFlow<Inventory?>
        get() = repository.highestPriceProduct

    override fun changeSearchQuery(value: String) {
        scope.launch {
            repository.searchQueryState.emit(value)
        }
        // performSearch(value)
    }

//    override fun performSearch(value: String) {
//        InventoryDatabaseTable().search(value){filteredItems->
//            repository.allInventoryState.emit(filteredItems)
//        }
//    }

    fun searchQuery(value: String, param: NavigationParam?) {
        InventoryDatabaseTable().search(value, param) { filteredItems ->
            scope.launch {
                repository.allInventoryState.emit(filteredItems)
            }
        }
    }


    fun createInventory(
        name: String,
        description: String,
        productId: String?,
        categoryId: String?,
        subCategoryId: String?,
        model: String?,
        barcode: String?,
        brandId: String?,
        tagId: String?,
        uomId: String?,
        colorId: String?,
        qty: Long?,
        sizeId: String?,
        discountId: String?,
        coupanId: String?,
        costPrice: Double?,
        mrp: Double?,
        discount: Double?,
        salePrice: Double?,
        finalPrice: Double?,
        cgst: Double?,
        sgst: Double?,
        igst: Double?,
        currency: Currency,
        callBack: (Boolean) -> Unit
    ) {
        val productPrice = ProductPrice(
            CostPrice = costPrice,
            Price = salePrice,
            MRP = mrp,
            FinalPrice = finalPrice,
            SGST = sgst,
            CGST = cgst,
            IGST = igst,
            Discount = discount,
            currency = currency
        )

        val request = CreateInventoryRequest(
            Clock.System.now().epochSeconds,
            AuthHandler.shared().userId,
            BusinessHandler.shared().businessId,
            categoryID = categoryId,
            subCategoryID = subCategoryId,
            name,
            description,
            productID = productId,
            productPrice = productPrice,
            model,
            barcode,
            brandID = brandId,
            tagID = tagId,
            uomID = uomId,
            colorID = colorId,
            qty,
            sizeID = sizeId,
            discountID = discountId,
            coupanID = coupanId
        )
        startLoading()
        CoroutineScope(Job() + Dispatchers.Default).launch {
            InventoryNetwork().create(request) {
                stopLoading()
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    InventoryDatabaseTable().insert(it?.payload)
                    callBack(true)
                } else {
                    callBack(false)
                }
            }
        }
    }

    fun updateInventory(
        _id: String?,
        inventoryID: Long?,
        name: String,
        description: String,
        productId: String?,
        categoryId: String?,
        subCategoryId: String?,
        model: String?,
        barcode: String?,
        brandId: String?,
        tagId: String?,
        uomId: String?,
        colorId: String?,
        qty: Long?,
        sizeId: String?,
        discountId: String?,
        coupanId: String?,
        costPrice: Double?,
        mrp: Double?,
        discount: Double?,
        salePrice: Double?,
        finalPrice: Double?,
        cgst: Double?,
        sgst: Double?,
        igst: Double?,
        currency: Currency,
        callBack: (Boolean) -> Unit
    ) {
        val productPrice = ProductPrice(
            CostPrice = costPrice,
            Price = salePrice,
            MRP = mrp,
            FinalPrice = finalPrice,
            SGST = sgst,
            CGST = cgst,
            IGST = igst,
            Discount = discount,
            currency = currency
        )
        val request = UpdateInventoryRequest(
            _id = _id,
            inventoryID,
            AuthHandler.shared().userId,
            BusinessHandler.shared().businessId,
            categoryID = categoryId,
            subCategoryID = subCategoryId,
            name,
            description,
            productID = productId,
            productPrice = productPrice,
            model,
            barcode,
            brandID = brandId,
            tagID = tagId,
            uomID = uomId,
            colorID = colorId,
            qty,
            sizeID = sizeId,
            discountID = discountId,
            coupanID = coupanId
        )
        startLoading()
        CoroutineScope(Job() + Dispatchers.Default).launch {
            InventoryNetwork().update(request) {
                stopLoading()
                InventoryDatabaseTable().insert(it?.payload){
                    callBack(it?.payload?.isNotEmpty() == true)
                }
            }
        }
    }

    fun deleteInventory(Id: String?, callBack: (Boolean) -> Unit) {
        val request = DeleteInventoryRequest(
            _id = Id,
        )
        startLoading()
        scope.launch {
            InventoryNetwork().delete(request) {
                stopLoading()
                if (it?.status?.equals("SUCCESS") == true) {
                    callBack(true)
                } else {
                    callBack(false)
                }
            }
        }
    }

    fun loadInventory(param: NavigationParam?) {
        loadFromStorage(param)
        InventoryDatabaseTable().lastSyncDate(param) { date ->
            val request = RetrieveInventoryRequest(
                BusinessHandler.shared().businessId,
                param?.product?.Id,
                date
            )
            startLoading()
            InventoryNetwork().retrieve(request) {
                InventoryDatabaseTable().insert(it?.payload) {
                    stopLoading()
                    loadFromStorage(param)
                }
            }
        }
    }

    private fun loadFromStorage(param: NavigationParam?) {
        if (param?.business != null && param.product != null) {
            InventoryDatabaseTable().retrieveAll(param.business!!.Id, param.product!!.Id) { data ->
                scope.launch {
                    repository.allInventoryState.emit(data)
                    var min = 0.0
                    var max = 0.0
                    var lowestProduct: Inventory? = null
                    var heighestProduct: Inventory? = null
                    data.forEach { inv ->
                        if ((inv.ProductPrice.FinalPrice!! < min) || min == 0.0) {
                            min = inv.ProductPrice.FinalPrice!!
                            lowestProduct = inv
                        }
                        if (inv.ProductPrice.FinalPrice!! > max) {
                            max = inv.ProductPrice.FinalPrice!!
                            heighestProduct = inv
                        }
                    }
                    repository.lowestPriceProductState.emit(lowestProduct)
                    repository.highestPriceProductState.emit(heighestProduct)
                }
            }
        } else {
            scope.launch {
                repository.allInventoryState.emit(arrayListOf())
            }
        }
    }

    fun findById(
        colorId: String?,
        sizeId: String?,
        uomId: String?,
        param: NavigationParam?,
        callBack: (List<Inventory>) -> Unit
    ) {
        if (colorId != null && sizeId != null && uomId != null) {
            InventoryDatabaseTable().findByColorIdAndSizeIdAndUOMId(
                colorId,
                sizeId,
                uomId,
                param
            ) { filteredItems ->
                callBack(filteredItems)
            }
        } else if (sizeId != null && uomId != null) {
            InventoryDatabaseTable().findBySizeIdAndUOMId(sizeId, uomId, param) { filteredItems ->
                callBack(filteredItems)
            }
        } else if (colorId != null && uomId != null) {
            InventoryDatabaseTable().findByColorIdAndUOMId(colorId, uomId, param) { filteredItems ->
                callBack(filteredItems)
            }
        } else if (colorId != null && sizeId != null) {
            InventoryDatabaseTable().findByColorIdAndSizeId(
                colorId,
                sizeId,
                param
            ) { filteredItems ->
                callBack(filteredItems)
            }
        } else if (uomId != null) {
            InventoryDatabaseTable().findByUOMId(uomId, param) { filteredItems ->
                callBack(filteredItems)
            }
        } else if (sizeId != null) {
            InventoryDatabaseTable().findBySizeId(sizeId, param) { filteredItems ->
                callBack(filteredItems)
            }
        } else if (colorId != null) {
            InventoryDatabaseTable().findByColorId(colorId, param) { filteredItems ->
                callBack(filteredItems)
            }
        }else{
            if (param != null) {
                InventoryDatabaseTable().retrieveAll(param.business!!.Id, param.product!!.Id) { filteredItems ->
                    callBack(filteredItems)
                }
            }
        }
    }
}
