package feature.inventory.repository

import feature.inventory.model.discount.Discount
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class DiscountRepository {
    val discountLiveData: MutableStateFlow<List<Discount>> = MutableStateFlow(arrayListOf())
    val allDiscount: StateFlow<List<Discount>>
        get() = discountLiveData

    val selectedDiscountState: MutableStateFlow<Discount?> = MutableStateFlow(null)
    val selectedDiscount: StateFlow<Discount?>
        get() = selectedDiscountState

}