package presentation.screens.common.notification.component.contentCard

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.ImageView
import theme.ThemeColor

@Composable
fun GeneralNotificationCard() {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .height(112.dp)
            //.padding(top = 12.dp, end = 8.dp)
            .background(ThemeColor.shared().cardBackground),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().cardBackground
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
        ) {
            Box(
                modifier = Modifier
                    .width(80.dp)
                    .height(60.dp)
                    .padding(start = 35.dp, top = 15.dp)
                    .background(
                        color = ThemeColor.shared().lightGray,
                        shape = RoundedCornerShape(25.dp)
                    )
            ) {
                //   ImageView("")
            }
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    , verticalArrangement = Arrangement.SpaceEvenly
            ) {
                Text(
                    "New Employee onboarded: Welcome, Patrick to the team.",
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 8.dp)
                )
                Text(
                    "Today 2hrs ago",
                    fontSize = 12.sp,
                    color = Color.Gray,
                    modifier = Modifier.padding(start = 8.dp)
                )
            }


        }

    }}

