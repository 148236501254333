package presentation.screens.business.inventory.stock.component

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import appConfig.AppConfig
import feature.inventory.model.Inventory
import feature.inventory.model.product_color.ProductColor
import feature.inventory.model.size.Size
import feature.inventory.model.uom.UOM
import feature.inventory.viewModel.product_color.ProductColorViewModel
import feature.inventory.viewModel.size.SizeViewModel
import feature.inventory.viewModel.uom.UOMViewModel
import theme.ThemeColor

@Composable
fun InventoryCard(inventory: Inventory, onClick: (Inventory) -> Unit) {
    var color: ProductColor? by remember { mutableStateOf( null) }
    var uom: UOM? by remember { mutableStateOf(null) }
    var size: Size? by remember { mutableStateOf(null) }
    LaunchedEffect(key1 = Unit) {
        ProductColorViewModel().getColorById(inventory.ColorID) { colorFromDb -> color = colorFromDb }
        UOMViewModel().getUOMById(inventory.UOMID) { uomFromDb -> uom = uomFromDb }
        SizeViewModel().getSizeById(inventory.SizeID) { sizeFromDb -> size = sizeFromDb }
    }
    Column(
        modifier = Modifier
            .wrapContentHeight()
            .wrapContentWidth()
            .padding(start = 16.dp, end = 8.dp, bottom = 8.dp)
            .shadow(3.dp)
            .background(ThemeColor.shared().cardBackground)
            .zIndex(1f)
            .clickable { onClick(inventory) },
    ) {
        Row(
            modifier = Modifier.fillMaxWidth().wrapContentHeight(),
        ) {
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth().weight(0.2f),
            ) {
                Text(
                    text = "Color",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .background(ThemeColor.shared().rowBackgroundColor)
                )
                Text(
                    text = "${color?.Name}",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .padding(start = 8.dp)
                )
            }
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth().weight(0.2f),
            ) {
                Text(
                    text = "UoM",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .background(ThemeColor.shared().rowBackgroundColor)
                )
                Text(
                    text = "${uom?.Name}",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .padding(start = 8.dp)
                )
            }
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth().weight(0.2f),
            ) {
                Text(
                    text = "Size",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .padding(start = 0.dp)
                        .background(ThemeColor.shared().rowBackgroundColor)
                )
                Text(
                    text = "${size?.Name}",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .padding(start = 8.dp)
                )
            }
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth().weight(0.2f),
            ) {
                Text(
                    text = "Quantity",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .background(ThemeColor.shared().rowBackgroundColor)

                )
                Text(
                    text = "${inventory.qty}",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .padding(start = 8.dp)
                )
            }
            Column(
                modifier = Modifier.fillMaxHeight().fillMaxWidth().weight(0.2f),
            ) {
                Text(
                    text = "Price",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    fontSize = 16.sp,
                    fontWeight = FontWeight(400),
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .background(ThemeColor.shared().rowBackgroundColor)
                )
                Row(
                    modifier = Modifier
                        .padding(start = 8.dp)
                        .width(140.dp)
                        .height(50.dp)
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    Text(
                        text = "${AppConfig.shared().currencySymbol} ${inventory.ProductPrice.FinalPrice}",
                        fontWeight = FontWeight(600),
                        color = ThemeColor.shared().PrimaryColor
                    )
                }
            }
        }
    }
}