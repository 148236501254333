package feature.employee.network

import common.constants.Server
import feature.employee.event.EmployeeEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient


class EmployeeNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: EmployeeNetwork? = null
        fun shared(): EmployeeNetwork {
            if (instance != null) {
                return instance as EmployeeNetwork
            } else {
                return EmployeeNetwork()
            }
        }
    }

    suspend fun findUser(
        request: FindUserRequest,
        onResult: (FindUserResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(EmployeeEvent.FIND_USER.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t.message)
            onResult(null)
        }
    }

    suspend fun createEmployee(
        request: CreateEmployeeRequest,
        onResult: (CreateEmployeeResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(EmployeeEvent.CREATE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun updateEmployee(
        request: UpdateEmployeeRequest,
        onResult: (UpdateEmployeeResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(EmployeeEvent.UPDATE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            onResult(null)
        }
    }

    suspend fun retrieve(
        request: FetchBusinessEmployeeRequest,
        onResult: (FetchEmployeeResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(EmployeeEvent.RETRIEVE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }

    suspend fun retrieve(
        request: FetchEmployeeRequest,
        onResult: (FetchEmployeeResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(EmployeeEvent.RETRIEVE_FOR_USER.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }

}