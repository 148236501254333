package presentation.screens.common.notification.component.contentCard

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.Font
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import theme.ThemeColor

@Composable
fun OrderTrackingCard(){
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .height(157.dp)
            //.padding(top = 12.dp, end = 8.dp)
            .background(ThemeColor.shared().White),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().White,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().White
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
    ){
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(end = 6.dp)
        ) {
            Box(
                modifier = Modifier
                    .width(70.dp)
                    .height(55.dp)
                    .padding(start = 35.dp, top = 20.dp)
                    .background(
                        color = ThemeColor.shared().lightGray,
                    )
            ) {
                //   ImageView("")
            }
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(top = 18.dp)
                //verticalArrangement = Arrangement.SpaceEvenly
            ) {
                Text(
                    "Track your order: ",
                    fontSize = 16.sp,
                    fontWeight = FontWeight.Bold,
                    fontStyle = FontStyle.Normal,
                    modifier = Modifier.padding(start = 8.dp)
                )
                Text(
                    "Your order for cotton fabric has been successfully placed",
                    fontSize = 15.sp,
                    color = Color.Gray,
                    modifier = Modifier.padding(start = 8.dp)
                )
                Row(
                    modifier = Modifier
                        .fillMaxSize()
                        , verticalAlignment = Alignment.Bottom
                    , horizontalArrangement = Arrangement.SpaceBetween
                )
                {
                    Text("yesterday at 12:00 AM", color = Color.DarkGray, fontSize = 12.sp, modifier = Modifier.padding(start = 6.dp, end = 12.dp, top = 12.dp, bottom = 6.dp))
                    Box(
                        modifier = Modifier
                            .width(112.dp)
                            .height(35.dp)
                            .background(
                                color = ThemeColor.shared().Blue,
                                shape = RoundedCornerShape(8.dp)
                            )
                    )
                    {
                        Text(
                            "Track now",
                            fontSize = 15.sp,
                            textAlign = TextAlign.Center,
                            color = Color.White,
                            modifier = Modifier
                                .padding(start = 15.dp,top= 4.dp)
                        )

                    }
                }
            }}
    }
}