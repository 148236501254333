package feature.inventory.viewModel.uom

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.inventory.handler.UOMHandler
import feature.inventory.model.uom.UOM
import feature.inventory.network.uom.RetrieveUOMRequest
import feature.inventory.network.uom.UOMNetwork
import feature.inventory.repository.UOMRepository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.uom.UOMDatabaseTable

class UOMViewModel(val repository: UOMRepository = UOMHandler.shared().repository) :
    AppViewModel() {

    val allUOM: StateFlow<List<UOM>>
        get() = repository.uomLiveData

    val selectedUOM: StateFlow<UOM?>
        get() = repository.selectedUOM

    fun fetchAllUOM() {
        loadFromStorage()
        val request =
            RetrieveUOMRequest(BusinessHandler.shared().repository.business.value.Id)
        // startLoading()
        CoroutineScope(Job() + Dispatchers.Default).launch {
            UOMNetwork().retrieve(request) {
                UOMDatabaseTable().insert(it?.payload)
                stopLoading()
                loadFromStorage()
            }
        }
    }

    fun loadAllUOM() {
        loadFromStorage()
        if(allUOM.value.isEmpty()){
            fetchAllUOM()
        }
    }

    private fun loadFromStorage() {
        UOMDatabaseTable().retrieveAll { data ->
            scope.launch {
                repository.uomLiveData.emit(data)
            }
        }
    }

    fun getUOMById(id: String?, callback: (UOM?) -> Unit) {
        UOMDatabaseTable().find(id) { data ->
            scope.launch {
                callback(data)
            }
        }
    }
}