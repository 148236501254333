package feature.device.network

import common.constants.Server
import feature.device.event.DeviceEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class DeviceNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    fun retrieve(
        request: DeviceRetrieveRequest,
        onResult: (DeviceRetrieveResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(DeviceEvent.RETRIEVE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }

    fun create(
        request: DeviceCreateRequest,
        onResult: (DeviceCreateResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(DeviceEvent.CREATE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }

    fun update(
        request: DeviceUpdateRequest,
        onResult: (DeviceUpdateResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(DeviceEvent.CREATE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }

    fun delete(
        request: DeviceDeleteRequest,
        onResult: (DeviceDeleteResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(DeviceEvent.DELETE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }
}
