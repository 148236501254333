package storage.database.table.inventory.uom

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.UOMTable
import feature.inventory.model.uom.UOM
import kotlinx.coroutines.launch
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class UOMDatabaseTable : DatabaseTable() {
    fun insert(item: UOM?) {
        val table = AppDatabase.shared().database?.uOMTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    _id = item.Id,
                    UOMID = item.UOMID,
                    UserID = item.UserID,
                    BusinessID = item.BusinessID,
                    IsDeleted = item.IsDeleted,
                    Name = item.Name,
                    Value = item.Value,
                    Description = item.Description,
                    CreatedAt = item.CreatedAt,
                    UpdatedAt = item.UpdatedAt,
                )
            }
        }
    }

    fun insert(items: List<UOM>?) {
        val table = AppDatabase.shared().database?.uOMTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    _id = item.Id,
                    UOMID = item.UOMID,
                    UserID = item.UserID,
                    BusinessID = item.BusinessID,
                    IsDeleted = item.IsDeleted,
                    Name = item.Name,
                    Value = item.Value,
                    Description = item.Description,
                    CreatedAt = item.CreatedAt,
                    UpdatedAt = item.UpdatedAt,
                )
            }
        }
    }

    fun retrieve(id: String, callback: (List<UOM>) -> Unit) {
        val table = AppDatabase.shared().database?.uOMTableQueries
        val itemsFetched: ArrayList<UOM> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun retrieveAll(callback: (List<UOM>) -> Unit) {
        val table = AppDatabase.shared().database?.uOMTableQueries
        val itemsFetched: ArrayList<UOM> = arrayListOf()
        scope.launch {
            val items = table?.retrieveAll()?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun find(id: String?, callback: (UOM?) -> Unit) {
        val table = AppDatabase.shared().database?.uOMTableQueries
        if(id != null) {
            scope.launch {
                val items = table?.find(id)?.awaitAsOneOrNull()
                items?.let { item ->
                    val itemsFetched = convert(item)
                    callback(itemsFetched)
                }
            }
        }else{
            callback(null)
        }
    }


    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.uOMTableQueries
        scope.launch {
            val dateFromDb = table?.lastSyncDate(id)?.awaitAsOneOrNull()
            callback(dateFromDb?.UpdatedAt)
        }
    }

    fun convert(item: UOMTable): UOM {
        return UOM(
            Id = item._id,
            UOMID = item.UOMID,
            UserID = item.UserID,
            BusinessID = item.BusinessID,
            IsDeleted = item.IsDeleted,
            Name = item.Name,
            Description = item.Description,
            CreatedAt = item.CreatedAt,
            UpdatedAt = item.UpdatedAt,
        )
    }
}