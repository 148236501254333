package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.tagTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class TagTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    TagID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Value: String?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun retrieve(BusinessID: String?): Query<TagTable> = retrieve(BusinessID) { _id, TagID,
      UserID, BusinessID_, IsDeleted, Name, Value, Description, CreatedAt, UpdatedAt ->
    TagTable(
      _id,
      TagID,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveAll(mapper: (
    _id: String,
    TagID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Value: String?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = Query(-557_641_071, arrayOf("TagTable"), driver, "TagTable.sq", "retrieveAll",
      "SELECT TagTable._id, TagTable.TagID, TagTable.UserID, TagTable.BusinessID, TagTable.IsDeleted, TagTable.Name, TagTable.Value, TagTable.Description, TagTable.CreatedAt, TagTable.UpdatedAt FROM TagTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun retrieveAll(): Query<TagTable> = retrieveAll { _id, TagID, UserID, BusinessID,
      IsDeleted, Name, Value, Description, CreatedAt, UpdatedAt ->
    TagTable(
      _id,
      TagID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    TagID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Value: String?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun find(_id: String): Query<TagTable> = find(_id) { _id_, TagID, UserID, BusinessID,
      IsDeleted, Name, Value, Description, CreatedAt, UpdatedAt ->
    TagTable(
      _id_,
      TagID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    TagID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Value: String?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(-1_547_949_971, """
        |INSERT OR REPLACE INTO TagTable( _id ,TagID,UserID, BusinessID,IsDeleted,Name,Value,Description,CreatedAt,UpdatedAt)
        |    VALUES(?, ?,?, ? ,?, ?, ?,?,?,?)
        """.trimMargin(), 10) {
          bindString(0, _id)
          bindLong(1, TagID)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindBoolean(4, IsDeleted)
          bindString(5, Name)
          bindString(6, Value)
          bindString(7, Description)
          bindString(8, CreatedAt)
          bindString(9, UpdatedAt)
        }.await()
    notifyQueries(-1_547_949_971) { emit ->
      emit("TagTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("TagTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("TagTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT TagTable._id, TagTable.TagID, TagTable.UserID, TagTable.BusinessID, TagTable.IsDeleted, TagTable.Name, TagTable.Value, TagTable.Description, TagTable.CreatedAt, TagTable.UpdatedAt FROM TagTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "TagTable.sq:retrieve"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("TagTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("TagTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(163_657_837,
        """SELECT TagTable._id, TagTable.TagID, TagTable.UserID, TagTable.BusinessID, TagTable.IsDeleted, TagTable.Name, TagTable.Value, TagTable.Description, TagTable.CreatedAt, TagTable.UpdatedAt FROM TagTable WHERE _id = ?""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "TagTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("TagTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("TagTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM TagTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "TagTable.sq:lastSyncDate"
  }
}
