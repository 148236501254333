package feature.businessConnection.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class BusinessConnection(
    @SerialName("UniqueID") var UniqueID: Long = 0,
    @SerialName("FromUserID") var fromUserId: String? = null,
    @SerialName("ToUserID") var toUserId: String? = null,
    @SerialName("FromBusinessID") var fromBusinessId: String? = null,
    @SerialName("ToBusinessID") var toBusinessId: String? = null,
    @SerialName("IsSent") var isSent: Boolean? = null,
    @SerialName("IsAccepted") var isAccepted: Boolean? = null,
    @SerialName("IsRejected") var isRejected: Boolean? = null,
    @SerialName("IsDeleted") var isDeleted: Boolean? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)