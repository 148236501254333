package feature.cart.network

import feature.business.model.Business
import feature.customer.model.Customer
import feature.product.model.ProductPrice
import feature.sale.model.SaleTransaction
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateCustomerInvoiceRequest(
    @SerialName("InvoiceID") var invoiceID: Long? = null,
    @SerialName("UserID") var userId: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("SelectedBusinessID") var selectedBusinessID: String? = null,
    @SerialName("Business") var business: Business? = null,
    @SerialName("CustomerID") var customerId: String? = null,
    @SerialName("CustomerName") var customerName: String? = null,
    @SerialName("Customer") var customer: Customer? = null,
    @SerialName("DeviceID") var deviceId: String? = null,
    @SerialName("Sales") var sales: ArrayList<SaleTransaction> = arrayListOf(),
    @SerialName("Pricing") var pricing: ProductPrice? = null,
    @SerialName("InstantDiscount") var instantDiscount: Double? = null,
)

