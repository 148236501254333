package feature.order.status

enum class OrderStatus {
    PLACED,
    PENDING,
    AWAITING_CONFIRMATION,
    CONFIRMED,
    AWAITING_PAYMENT,
    AWAITING_FULFILMENT,
    AWAITING_SHIPMENT,
    AWAITING_PICKUP,
    PARTIALLY_PICKUP,
    PARTIALLY_SHIPPED,
    COMPLETED,
    SHIPPED,
    CANCELLED,
    DECLINED,
    REFUNDED,
    PARTIALLY_REFUNDED,
    DISPUTED,
    MANUAL_VERIFICATION_REQUIRED,
    FULFILLED
}