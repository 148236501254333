package presentation.screens.customer.store.payment

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import appConfig.AppConfig
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.app.NavigationParam
import feature.cart.viewModel.CartViewModel
import feature.order.viewModel.OrderViewModel
import feature.payment.viewModel.PaymentViewModel
import graph.PlatformType
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.customer.store.payment.component.PaymentMethodCard
import presentation.screens.customer.store.payment.component.ShopCard
import theme.ThemeColor

class PaymentDetailScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val cartViewModel = CartViewModel()
        val orderViewModel = OrderViewModel()
        val paymentViewModel = PaymentViewModel()
        val allItems = cartViewModel.cartItems.collectAsState()
        val navigator = LocalNavigator.current
        val business = navigationParam?.selectedBusiness
        LaunchedEffect(key1 = Unit) {

        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = "Payment", navigationParam, onBackPress = {
                navigator?.pop()
            })
            AppLoader(orderViewModel)
            LazyColumn(modifier = Modifier.fillMaxSize().padding(8.dp).weight(0.84f)) {
                item {
                    Row(
                        modifier = Modifier
                            .padding(4.dp)
                    ) {
                        Text(
                            "Your Order to",
                            fontSize = 16.sp,
                            modifier = Modifier.weight(0.9f)
                        )
                        Text(
                            "+ Add more",
                            fontSize = 14.sp,
                            color = ThemeColor.shared().PrimaryColor,
                            modifier = Modifier.clickable {
                                AppNavigator.shared().pop()
                            }
                        )
                    }
                }
                item { ShopCard(business, cartViewModel) }
                item {
                    Row(
                        modifier = Modifier
                            .padding(4.dp)
                    ) {
                        Text(
                            "Payment Method",
                            fontSize = 16.sp,
                            // fontWeight = FontWeight.Bold,
                            modifier = Modifier.weight(0.9f)
                        )
//                        Text(
//                            "+Add new card",
//                            fontSize = 14.sp,
//                            color = ThemeColor.shared().PrimaryColor,
//                            // modifier = Modifier.weight(0.1f)
//                        )
                    }
                }
//                item { PaymentMethodCard() }
//                item { PaymentMethodCard() }
                item { PaymentMethodCard() }
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .weight(0.20f)
                    .background(color = Color.Gray), verticalAlignment = Alignment.Bottom
            ) {
                Box(
                    modifier = Modifier.fillMaxSize().padding(bottom = 30.dp)
                        .background(color = Color.White)
                ) {
                    Row(
                        modifier = Modifier
                            .fillMaxSize()
                            .padding(8.dp)
                    ) {
                        Text(
                            "\uD83D\uDED2", fontSize = 35.sp, modifier = Modifier
                                .padding(start = 8.dp, top = 8.dp).weight(0.1f)
                        )

                        Column(
                            modifier = Modifier
                                .fillMaxSize()
                                .padding(top = 15.dp, bottom = 40.dp).weight(0.3f)
                        ) {
                            Row(
                                modifier = Modifier
                                    .fillMaxWidth()
                                // .height(50.dp)
                            ) {
                                Text(
                                    "${allItems.value.count()} items", fontSize = 16.sp
                                )
                                Text(
                                    "${allItems.value.first().price.getCurrencySymbol()} ${cartViewModel.totalAmount.value}",
                                    fontSize = 18.sp,
                                    color = ThemeColor.shared().PrimaryColor,
                                    modifier = Modifier
                                        .padding(start = 4.dp)
                                )
                            }
                            Text(
                                navigationParam?.selectedBusiness?.Name ?: "",
                                fontSize = 16.sp,
                                color = Color.Gray,
                                modifier = Modifier
                            )
                        }
                        Box(
                            modifier = Modifier.width(126.dp).height(55.dp).weight(0.3f)
                                .padding(top = 20.dp)
                        ) {
                            DefaultButton("Place Order", {
                                orderViewModel.create(
                                    navigationParam,
                                    cartViewModel,
                                    allItems.value
                                ) { isSuccess, order ->
                                    if (isSuccess == true && order != null) {
                                        paymentViewModel.create(
                                            navigationParam?.update(
                                                NavigationParam(order = order)
                                            )
                                        ) { payment ->
                                            if (payment != null) {
                                                AppNavigator.shared().pop()
                                                AppNavigator.shared().pop()
                                                AppNavigator.shared().pop()
                                                cartViewModel.resetCart()
                                                if (AppConfig.shared().platformType == PlatformType.Web) {
                                                    AppNavigator.shared()
                                                        .openLink("http://mandee.ai/payment_page?id=${payment._id}")
                                                } else {
                                                    navigateTo(
                                                        ScreenName.PAYMENT_PAY_SCREEN,
                                                        navigationParam?.update(NavigationParam(url = "http://mandee.ai/payment_page?id=${payment._id}"))
                                                    )
                                                }
                                            }
                                        }
//                                        navigateTo(ScreenName.ORDER_LIST, navigationParam)
                                    } else {
                                        AlertHandler.shared().showAlert(
                                            "Oops!",
                                            "Something went wrong, please try after some time"
                                        )
                                    }
                                }
                            })
                        }
                    }
                }
            }
        }
    }
}