package feature.notification.viewModel

import appConfig.AppConfig
import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.notification.handler.NotificationConnectionHandler
import feature.notification.model.Notification
import feature.notification.network.AcceptConnectionRequest
import feature.notification.network.NotificationNetwork
import feature.notification.network.NotificationRetrieveRequest
import feature.notification.repository.NotificationRepository
import graph.AppType
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class NotificationViewModel(private val repository: NotificationRepository = NotificationConnectionHandler.shared().repository) :
    AppViewModel() {

    val allData: StateFlow<List<Notification>>
        get() = repository.allLiveData

    val mediaFile: StateFlow<Notification>
        get() = repository.mediaFileLiveData

    fun retrieve() {
        startLoading()
        val business = BusinessHandler.shared().repository.business
        val request =
            NotificationRetrieveRequest(business.value.Id, AuthHandler.shared().userId)
        if (AppConfig.shared().appType == AppType.BUSINESS) {
            NotificationNetwork().retrieveForBusiness(request) {
                stopLoading()
                if (!it?.payload.isNullOrEmpty()) {
                    scope.launch {
                        repository.liveData.emit(it!!.payload)
                    }
                }
            }
        } else {
            NotificationNetwork().retrieve(request) {
                stopLoading()
                if (!it?.payload.isNullOrEmpty()) {
                    scope.launch {
                        repository.liveData.emit(it!!.payload)
                    }
                }
            }
        }
    }

    fun acceptConnectionRequest(notification: Notification) {
        startLoading()
        val request = AcceptConnectionRequest(
            userId = AuthHandler.shared().userId,
            businessId = BusinessHandler.shared().businessId,
            payload = notification
        )
        if (AppConfig.shared().appType == AppType.BUSINESS) {
            NotificationNetwork().acceptConnectionRequest(request) {
                stopLoading()
                if (!it?.payload?._id.isNullOrEmpty()) {
                    scope.launch {
                        retrieve()
                    }
                }
            }
        }
    }


}