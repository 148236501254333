package component.input

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.text.TextTitle
import theme.ThemeColor

@Composable
fun TaxInputField(
    title: String = "",
    placeholder: String,
    value: Double = 0.0,
    onChange: (Double) -> Unit
) {

    var inputValueString = ""
    if (value > 0.0) {
        try {
            inputValueString = value.toString()
        } catch (_: Exception) {

        }
    }
    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
        if (title.isNotEmpty()) {
            TextTitle(title)
        }
        Row(
            modifier = Modifier.background(
                color = ThemeColor.shared().cardBackground,
                shape = RoundedCornerShape(40.dp)
            )
                .border(
                    width = 1.dp,
                    brush = Brush.horizontalGradient(
                        listOf(
                            ThemeColor.shared().customerTheme,
                            ThemeColor.shared().businessTheme,
                        )
                    ),
                    shape = CircleShape
                ),
            verticalAlignment = Alignment.CenterVertically,

            ) {
            Text(
                "%",
                fontSize = 18.sp,
                fontStyle = FontStyle.Normal,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Center,
                color = ThemeColor.shared().PrimaryColor,
                modifier = Modifier.padding(start = 8.dp)
                    .background(color = Color.Transparent).width(40.dp)
            )
            TextField(
                value = inputValueString,
                onValueChange = {
                    try {
                        onChange(it.toDouble())
                    } catch (_: Exception) {

                    }
                },
                placeholder = { Text(placeholder) },
                colors = TextFieldDefaults.colors(
                    focusedIndicatorColor = Color.Transparent,
                    unfocusedIndicatorColor = Color.Transparent,
                    disabledIndicatorColor = Color.Transparent,
                    focusedContainerColor = ThemeColor.shared().defaultBakground,
                    unfocusedContainerColor = ThemeColor.shared().defaultBakground,
                ),
                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                modifier = Modifier.fillMaxWidth()
                    .background(
                        color = ThemeColor.shared().defaultBakground,
                        RoundedCornerShape(10.dp)
                    )

            )
        }
    }
}
