package engine.date

import common.util.toLocal
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime

class DateEngine {
    fun displayableDate(date: String): String {
        val instantInThePast: Instant = Instant.parse(date)
        var localTime = instantInThePast.toLocalDateTime(TimeZone.UTC)
        localTime = localTime.toInstant(TimeZone.currentSystemDefault()).toLocal()
        val localDate = localTime.date
        return localDate.toString()
    }

    fun displayableDateTime(date: String): String {
        if (date.isEmpty()) {
            return ""
        }
        val currentMoment: Instant = Instant.parse(date)
        val datetimeInSystemZone: LocalDateTime =
            currentMoment.toLocalDateTime(TimeZone.currentSystemDefault())
        var hour = 0
        var amPm = "AM"
        if (datetimeInSystemZone.time.hour >= 12) {
            amPm = "PM"
        }
        if (datetimeInSystemZone.time.hour == 24) {
            amPm = "AM"
        }
        if (datetimeInSystemZone.time.hour > 12) {
            hour = datetimeInSystemZone.time.hour - 12
        }
        if (datetimeInSystemZone.time.hour == 24) {
            hour = 0
        }
        return "${datetimeInSystemZone.date} at $hour : ${datetimeInSystemZone.time.minute} $amPm"
    }
}