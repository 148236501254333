package feature.productSubCategory.viewModel

import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.productCategory.handler.ProductCategoryHandler
import feature.productCategory.model.ProductCategory
import feature.productSubCategory.handler.ProductSubCategoryHandler
import feature.productSubCategory.model.ProductSubCategory
import feature.productSubCategory.network.CreateProductSubCategoryRequest
import feature.productSubCategory.network.ProductSubCategoryNetwork
import feature.productSubCategory.network.RetrieveProductSubCategoryRequest
import feature.productSubCategory.network.UpdateProductSubCategoryRequest
import feature.productSubCategory.repository.ProductSubCategoryRepository
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.product_sub_category.ProductSubCategoryDatabaseTable

class ProductSubCategoryViewModel :
    AppViewModel() {
    private val repository: ProductSubCategoryRepository =
        ProductSubCategoryHandler.shared().repository
    val allSubCategory: StateFlow<List<ProductSubCategory>>
        get() = repository.allSubCategory

    val allSubCategoryForCategory: StateFlow<List<ProductSubCategory>>
        get() = repository.allSubCategoryForCategory
    val selectedSubCategory: StateFlow<ProductSubCategory?>
        get() = repository.selectedSubCategory

    val editSubCategory: StateFlow<ProductSubCategory?>
        get() = repository.editSubCategory

    fun setEditSubCategory(value: ProductSubCategory?) {
        repository.setEditSubCategory(value)
    }

    fun setSelectedSubCategory(value: ProductSubCategory?) {
        repository.setSelectedSubCategory(value)
    }

    private fun loadFromStorage() {
        if (BusinessHandler.shared().businessId.isNotEmpty()) {
            ProductSubCategoryDatabaseTable().retrieve(BusinessHandler.shared().businessId) { data ->
                scope.launch {
                    repository.subCategoryLiveData.emit(data)
                }
            }
        }
    }

    fun loadForCategoryStorage(id: String) {
        if (BusinessHandler.shared().businessId.isNotEmpty()) {
            ProductSubCategoryDatabaseTable().retrieveFor(
                BusinessHandler.shared().businessId,
                id
            ) { data ->
                scope.launch {
                    repository.allSubCategoryForCategoryState.emit(data)
                }
            }
        }
    }


    fun fetchAll() {
        loadFromStorage()
        val request =
            RetrieveProductSubCategoryRequest(BusinessHandler.shared().repository.business.value.Id)
        startLoading()
        scope.launch {
            ProductSubCategoryNetwork().retrieve(request) {
                stopLoading()
                if (it?.payload != null && it.payload.isNotEmpty()) {
                    ProductSubCategoryDatabaseTable().insert(it.payload)
                    loadFromStorage()
                }
            }
        }
    }

    fun createNewSubCategory(name: String, completion: () -> Unit) {
        if (ProductCategoryHandler.shared().repository.selectedCategory.value?.Id?.isNotEmpty() == true) {
            val request = CreateProductSubCategoryRequest(
                name,
                ProductCategoryHandler.shared().repository.selectedCategory.value!!.Id,
                BusinessHandler.shared().repository.business.value.Id,
                AuthHandler.shared().repository.authUser.value.id
            )
            startLoading()
            scope.launch {
                ProductSubCategoryNetwork().create(request) {
                    stopLoading()
                    if (it?.payload != null && it.payload.Id.isNotEmpty()) {
                        scope.launch {
                            val allCategory: MutableList<ProductSubCategory> =
                                repository.allSubCategory.value.toMutableList()
                            allCategory.add(it.payload)
                            repository.subCategoryLiveData.emit(allCategory)
                            ProductSubCategoryDatabaseTable().insert(it.payload)
                            completion()
                        }
                    } else {
                        completion()
                    }
                }
            }
        }
    }

    fun update(name: String, id: String, category: ProductCategory?, completion: () -> Unit) {
        val request = UpdateProductSubCategoryRequest(
            name,
            id,
            category?.Id,
            BusinessHandler.shared().repository.business.value.Id,
            AuthHandler.shared().repository.authUser.value.id
        )
        startLoading()
        scope.launch {
            ProductSubCategoryNetwork().update(request) {
                stopLoading()
                if (it?.payload != null && it.payload.Id.isNotEmpty()) {
                    scope.launch {
                        val allCategory: MutableList<ProductSubCategory> =
                            repository.allSubCategory.value.toMutableList()
                        allCategory.add(it.payload)
                        repository.subCategoryLiveData.emit(allCategory)
                        ProductSubCategoryDatabaseTable().insert(it.payload)
                    }
                }
                completion()
            }
        }
    }
}