package presentation.screens.business.profile.self.component

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import appConfig.AppConfig
import component.button.StepperButton
import component.image.ImageView
import feature.app.NavigationParam
import feature.cart.model.CartItem
import feature.cart.viewModel.CartViewModel
import feature.product.model.Product
import theme.ThemeColor

@Composable
fun ItemCard(product: Product, viewModel: CartViewModel, param: NavigationParam?) {
    var cartItem: CartItem? by remember { mutableStateOf(null) }
    LaunchedEffect(key1 = Unit) {
        viewModel.getItemForProduct(product) {
            cartItem = it
        }
    }
    Card(
        modifier = Modifier
            .width(150.dp)
            .height(230.dp)
            .padding(top = 12.dp, end = 8.dp)
            .background(ThemeColor.shared().White, shape = RoundedCornerShape(10.dp)),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().White,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().White
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(8.dp)
        ) {
            Box(
                modifier = Modifier
                    .width(136.dp)
                    .height(91.dp)
            ) {
                ImageView(product.Id)
            }
            Text(
                product.Name ?: " ",
                maxLines = 1,
                fontSize = 14.sp,
                color = Color.Black,
                modifier = Modifier
            )
            Text(
                product.Description ?: " ",
                fontSize = 10.sp,
                color = Color.Gray,
                modifier = Modifier
                    .padding(bottom = 4.dp)
            )
            Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
                Text(
                    "${product.ProductPrice.FinalPrice}",
                    fontSize = 16.sp,
                    color = Color.Black,
                    modifier = Modifier
                        .padding(top = 4.dp)
                )
                if (cartItem != null) {
                    Text(
                        modifier = Modifier.padding(end = 20.dp).weight(1f),
                        text = "${AppConfig.shared().currencySymbol} ${product.ProductPrice.FinalPrice}" + " x ${
                            viewModel.getProductQuantity(
                                product
                            )
                        } = ${viewModel.getProductPrice(product)}",
                        fontWeight = FontWeight(600),
                        maxLines = 1,
                        color = ThemeColor.shared().TextColor
                    )
                    StepperButton(
                        count = viewModel.getProductQuantity(product),
                        onDecrease = {
                            viewModel.removeFromCart(product, param)
                        },
                        onIncrease = {
                            viewModel.addToCart(product, param)
                        }
                    )
                } else {
                    Text(
                        modifier = Modifier.wrapContentHeight().wrapContentWidth(),
                        text = "${AppConfig.shared().currencySymbol} ${product.ProductPrice.FinalPrice}",
                        fontWeight = FontWeight(600),
                        color = ThemeColor.shared().TextColor
                    )
                    Row(
                        modifier = Modifier.fillMaxSize(),
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Row(
                            modifier = Modifier.height(30.dp).width(100.dp).padding(end = 8.dp)
                                .background(
                                    ThemeColor.shared().PrimaryColor,
                                    shape = RoundedCornerShape(20.dp)
                                ).clickable { viewModel.addToCart(product, param) },
                            horizontalArrangement = Arrangement.Center,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Text(text = "Add", color = ThemeColor.shared().White)
                            Icon(
                                Icons.Default.Add,
                                contentDescription = "increase quantity",
                                tint = ThemeColor.shared().White
                            )
                        }
                    }
                }
            }
        }
    }
}

