package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Double
import kotlin.Long
import kotlin.String

public class InvoiceTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    InvoiceID: Long?,
    ShareLink: String?,
    UserID: String?,
    BusinessID: String?,
    CustomerID: String?,
    CustomerName: String?,
    DialCode: String?,
    MobileNumber: String?,
    IsDeleted: Boolean,
    Sales: String?,
    InvoiceNumber: Long?,
    InvoiceType: String?,
    TotalPrice: Double?,
    GST: Double?,
    SGST: Double?,
    CGST: Double?,
    IGST: Double?,
    CESS: Double?,
    VAT: Double?,
    Tax: Double?,
    CostPrice: Double?,
    InstantDiscount: Double?,
    FinalPrice: Double?,
    PendingAmount: Double?,
    ClearedAmount: Double?,
    InvoiceDate: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getString(10),
      cursor.getLong(11),
      cursor.getString(12),
      cursor.getDouble(13),
      cursor.getDouble(14),
      cursor.getDouble(15),
      cursor.getDouble(16),
      cursor.getDouble(17),
      cursor.getDouble(18),
      cursor.getDouble(19),
      cursor.getDouble(20),
      cursor.getDouble(21),
      cursor.getDouble(22),
      cursor.getDouble(23),
      cursor.getDouble(24),
      cursor.getDouble(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)
    )
  }

  public fun retrieve(BusinessID: String?): Query<InvoiceTable> = retrieve(BusinessID) { _id,
      InvoiceID, ShareLink, UserID, BusinessID_, CustomerID, CustomerName, DialCode, MobileNumber,
      IsDeleted, Sales, InvoiceNumber, InvoiceType, TotalPrice, GST, SGST, CGST, IGST, CESS, VAT,
      Tax, CostPrice, InstantDiscount, FinalPrice, PendingAmount, ClearedAmount, InvoiceDate,
      CreatedAt, UpdatedAt, __v ->
    InvoiceTable(
      _id,
      InvoiceID,
      ShareLink,
      UserID,
      BusinessID_,
      CustomerID,
      CustomerName,
      DialCode,
      MobileNumber,
      IsDeleted,
      Sales,
      InvoiceNumber,
      InvoiceType,
      TotalPrice,
      GST,
      SGST,
      CGST,
      IGST,
      CESS,
      VAT,
      Tax,
      CostPrice,
      InstantDiscount,
      FinalPrice,
      PendingAmount,
      ClearedAmount,
      InvoiceDate,
      CreatedAt,
      UpdatedAt,
      __v
    )
  }

  public fun <T : Any> find(InvoiceID: Long?, mapper: (
    _id: String,
    InvoiceID: Long?,
    ShareLink: String?,
    UserID: String?,
    BusinessID: String?,
    CustomerID: String?,
    CustomerName: String?,
    DialCode: String?,
    MobileNumber: String?,
    IsDeleted: Boolean,
    Sales: String?,
    InvoiceNumber: Long?,
    InvoiceType: String?,
    TotalPrice: Double?,
    GST: Double?,
    SGST: Double?,
    CGST: Double?,
    IGST: Double?,
    CESS: Double?,
    VAT: Double?,
    Tax: Double?,
    CostPrice: Double?,
    InstantDiscount: Double?,
    FinalPrice: Double?,
    PendingAmount: Double?,
    ClearedAmount: Double?,
    InvoiceDate: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
  ) -> T): Query<T> = FindQuery(InvoiceID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getString(10),
      cursor.getLong(11),
      cursor.getString(12),
      cursor.getDouble(13),
      cursor.getDouble(14),
      cursor.getDouble(15),
      cursor.getDouble(16),
      cursor.getDouble(17),
      cursor.getDouble(18),
      cursor.getDouble(19),
      cursor.getDouble(20),
      cursor.getDouble(21),
      cursor.getDouble(22),
      cursor.getDouble(23),
      cursor.getDouble(24),
      cursor.getDouble(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)
    )
  }

  public fun find(InvoiceID: Long?): Query<InvoiceTable> = find(InvoiceID) { _id, InvoiceID_,
      ShareLink, UserID, BusinessID, CustomerID, CustomerName, DialCode, MobileNumber, IsDeleted,
      Sales, InvoiceNumber, InvoiceType, TotalPrice, GST, SGST, CGST, IGST, CESS, VAT, Tax,
      CostPrice, InstantDiscount, FinalPrice, PendingAmount, ClearedAmount, InvoiceDate, CreatedAt,
      UpdatedAt, __v ->
    InvoiceTable(
      _id,
      InvoiceID_,
      ShareLink,
      UserID,
      BusinessID,
      CustomerID,
      CustomerName,
      DialCode,
      MobileNumber,
      IsDeleted,
      Sales,
      InvoiceNumber,
      InvoiceType,
      TotalPrice,
      GST,
      SGST,
      CGST,
      IGST,
      CESS,
      VAT,
      Tax,
      CostPrice,
      InstantDiscount,
      FinalPrice,
      PendingAmount,
      ClearedAmount,
      InvoiceDate,
      CreatedAt,
      UpdatedAt,
      __v
    )
  }

  public fun <T : Any> getInvoiceForUser(
    BusinessID: String?,
    UserID: String?,
    mapper: (
      _id: String,
      InvoiceID: Long?,
      ShareLink: String?,
      UserID: String?,
      BusinessID: String?,
      CustomerID: String?,
      CustomerName: String?,
      DialCode: String?,
      MobileNumber: String?,
      IsDeleted: Boolean,
      Sales: String?,
      InvoiceNumber: Long?,
      InvoiceType: String?,
      TotalPrice: Double?,
      GST: Double?,
      SGST: Double?,
      CGST: Double?,
      IGST: Double?,
      CESS: Double?,
      VAT: Double?,
      Tax: Double?,
      CostPrice: Double?,
      InstantDiscount: Double?,
      FinalPrice: Double?,
      PendingAmount: Double?,
      ClearedAmount: Double?,
      InvoiceDate: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
      __v: Long?,
    ) -> T,
  ): Query<T> = GetInvoiceForUserQuery(BusinessID, UserID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getString(10),
      cursor.getLong(11),
      cursor.getString(12),
      cursor.getDouble(13),
      cursor.getDouble(14),
      cursor.getDouble(15),
      cursor.getDouble(16),
      cursor.getDouble(17),
      cursor.getDouble(18),
      cursor.getDouble(19),
      cursor.getDouble(20),
      cursor.getDouble(21),
      cursor.getDouble(22),
      cursor.getDouble(23),
      cursor.getDouble(24),
      cursor.getDouble(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)
    )
  }

  public fun getInvoiceForUser(BusinessID: String?, UserID: String?): Query<InvoiceTable> =
      getInvoiceForUser(BusinessID, UserID) { _id, InvoiceID, ShareLink, UserID_, BusinessID_,
      CustomerID, CustomerName, DialCode, MobileNumber, IsDeleted, Sales, InvoiceNumber,
      InvoiceType, TotalPrice, GST, SGST, CGST, IGST, CESS, VAT, Tax, CostPrice, InstantDiscount,
      FinalPrice, PendingAmount, ClearedAmount, InvoiceDate, CreatedAt, UpdatedAt, __v ->
    InvoiceTable(
      _id,
      InvoiceID,
      ShareLink,
      UserID_,
      BusinessID_,
      CustomerID,
      CustomerName,
      DialCode,
      MobileNumber,
      IsDeleted,
      Sales,
      InvoiceNumber,
      InvoiceType,
      TotalPrice,
      GST,
      SGST,
      CGST,
      IGST,
      CESS,
      VAT,
      Tax,
      CostPrice,
      InstantDiscount,
      FinalPrice,
      PendingAmount,
      ClearedAmount,
      InvoiceDate,
      CreatedAt,
      UpdatedAt,
      __v
    )
  }

  public fun <T : Any> getInvoiceForCustomer(
    BusinessID: String?,
    CustomerID: String?,
    mapper: (
      _id: String,
      InvoiceID: Long?,
      ShareLink: String?,
      UserID: String?,
      BusinessID: String?,
      CustomerID: String?,
      CustomerName: String?,
      DialCode: String?,
      MobileNumber: String?,
      IsDeleted: Boolean,
      Sales: String?,
      InvoiceNumber: Long?,
      InvoiceType: String?,
      TotalPrice: Double?,
      GST: Double?,
      SGST: Double?,
      CGST: Double?,
      IGST: Double?,
      CESS: Double?,
      VAT: Double?,
      Tax: Double?,
      CostPrice: Double?,
      InstantDiscount: Double?,
      FinalPrice: Double?,
      PendingAmount: Double?,
      ClearedAmount: Double?,
      InvoiceDate: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
      __v: Long?,
    ) -> T,
  ): Query<T> = GetInvoiceForCustomerQuery(BusinessID, CustomerID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getString(10),
      cursor.getLong(11),
      cursor.getString(12),
      cursor.getDouble(13),
      cursor.getDouble(14),
      cursor.getDouble(15),
      cursor.getDouble(16),
      cursor.getDouble(17),
      cursor.getDouble(18),
      cursor.getDouble(19),
      cursor.getDouble(20),
      cursor.getDouble(21),
      cursor.getDouble(22),
      cursor.getDouble(23),
      cursor.getDouble(24),
      cursor.getDouble(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)
    )
  }

  public fun getInvoiceForCustomer(BusinessID: String?, CustomerID: String?): Query<InvoiceTable> =
      getInvoiceForCustomer(BusinessID, CustomerID) { _id, InvoiceID, ShareLink, UserID,
      BusinessID_, CustomerID_, CustomerName, DialCode, MobileNumber, IsDeleted, Sales,
      InvoiceNumber, InvoiceType, TotalPrice, GST, SGST, CGST, IGST, CESS, VAT, Tax, CostPrice,
      InstantDiscount, FinalPrice, PendingAmount, ClearedAmount, InvoiceDate, CreatedAt, UpdatedAt,
      __v ->
    InvoiceTable(
      _id,
      InvoiceID,
      ShareLink,
      UserID,
      BusinessID_,
      CustomerID_,
      CustomerName,
      DialCode,
      MobileNumber,
      IsDeleted,
      Sales,
      InvoiceNumber,
      InvoiceType,
      TotalPrice,
      GST,
      SGST,
      CGST,
      IGST,
      CESS,
      VAT,
      Tax,
      CostPrice,
      InstantDiscount,
      FinalPrice,
      PendingAmount,
      ClearedAmount,
      InvoiceDate,
      CreatedAt,
      UpdatedAt,
      __v
    )
  }

  public fun <T : Any> getLatestInvoiceDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = GetLatestInvoiceDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun getLatestInvoiceDate(BusinessID: String?): Query<GetLatestInvoiceDate> =
      getLatestInvoiceDate(BusinessID) { UpdatedAt ->
    GetLatestInvoiceDate(
      UpdatedAt
    )
  }

  public fun <T : Any> search(
    BusinessID: String?,
    CustomerName: String,
    MobileNumber: String,
    InvoiceNumber: String,
    mapper: (
      _id: String,
      InvoiceID: Long?,
      ShareLink: String?,
      UserID: String?,
      BusinessID: String?,
      CustomerID: String?,
      CustomerName: String?,
      DialCode: String?,
      MobileNumber: String?,
      IsDeleted: Boolean,
      Sales: String?,
      InvoiceNumber: Long?,
      InvoiceType: String?,
      TotalPrice: Double?,
      GST: Double?,
      SGST: Double?,
      CGST: Double?,
      IGST: Double?,
      CESS: Double?,
      VAT: Double?,
      Tax: Double?,
      CostPrice: Double?,
      InstantDiscount: Double?,
      FinalPrice: Double?,
      PendingAmount: Double?,
      ClearedAmount: Double?,
      InvoiceDate: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
      __v: Long?,
    ) -> T,
  ): Query<T> = SearchQuery(BusinessID, CustomerName, MobileNumber, InvoiceNumber) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getString(10),
      cursor.getLong(11),
      cursor.getString(12),
      cursor.getDouble(13),
      cursor.getDouble(14),
      cursor.getDouble(15),
      cursor.getDouble(16),
      cursor.getDouble(17),
      cursor.getDouble(18),
      cursor.getDouble(19),
      cursor.getDouble(20),
      cursor.getDouble(21),
      cursor.getDouble(22),
      cursor.getDouble(23),
      cursor.getDouble(24),
      cursor.getDouble(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28),
      cursor.getLong(29)
    )
  }

  public fun search(
    BusinessID: String?,
    CustomerName: String,
    MobileNumber: String,
    InvoiceNumber: String,
  ): Query<InvoiceTable> = search(BusinessID, CustomerName, MobileNumber, InvoiceNumber) { _id,
      InvoiceID, ShareLink, UserID, BusinessID_, CustomerID, CustomerName_, DialCode, MobileNumber_,
      IsDeleted, Sales, InvoiceNumber_, InvoiceType, TotalPrice, GST, SGST, CGST, IGST, CESS, VAT,
      Tax, CostPrice, InstantDiscount, FinalPrice, PendingAmount, ClearedAmount, InvoiceDate,
      CreatedAt, UpdatedAt, __v ->
    InvoiceTable(
      _id,
      InvoiceID,
      ShareLink,
      UserID,
      BusinessID_,
      CustomerID,
      CustomerName_,
      DialCode,
      MobileNumber_,
      IsDeleted,
      Sales,
      InvoiceNumber_,
      InvoiceType,
      TotalPrice,
      GST,
      SGST,
      CGST,
      IGST,
      CESS,
      VAT,
      Tax,
      CostPrice,
      InstantDiscount,
      FinalPrice,
      PendingAmount,
      ClearedAmount,
      InvoiceDate,
      CreatedAt,
      UpdatedAt,
      __v
    )
  }

  public suspend fun insert(
    _id: String,
    InvoiceID: Long?,
    ShareLink: String?,
    UserID: String?,
    BusinessID: String?,
    CustomerID: String?,
    CustomerName: String?,
    DialCode: String?,
    MobileNumber: String?,
    IsDeleted: Boolean,
    Sales: String?,
    InvoiceNumber: Long?,
    InvoiceType: String?,
    TotalPrice: Double?,
    GST: Double?,
    SGST: Double?,
    CGST: Double?,
    IGST: Double?,
    CESS: Double?,
    VAT: Double?,
    Tax: Double?,
    CostPrice: Double?,
    InstantDiscount: Double?,
    FinalPrice: Double?,
    PendingAmount: Double?,
    ClearedAmount: Double?,
    InvoiceDate: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
    __v: Long?,
  ) {
    driver.execute(-327_597_190, """
        |INSERT OR REPLACE INTO InvoiceTable(_id, InvoiceID,ShareLink, UserID, BusinessID, CustomerID, CustomerName,DialCode,MobileNumber,IsDeleted,Sales,InvoiceNumber,InvoiceType,TotalPrice,GST,SGST,CGST,IGST,CESS,VAT,Tax,CostPrice,InstantDiscount,FinalPrice,PendingAmount,ClearedAmount,InvoiceDate,CreatedAt,UpdatedAt,__v)
        |    VALUES(?, ?,?, ?, ?, ? ,?, ?, ?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)
        """.trimMargin(), 30) {
          bindString(0, _id)
          bindLong(1, InvoiceID)
          bindString(2, ShareLink)
          bindString(3, UserID)
          bindString(4, BusinessID)
          bindString(5, CustomerID)
          bindString(6, CustomerName)
          bindString(7, DialCode)
          bindString(8, MobileNumber)
          bindBoolean(9, IsDeleted)
          bindString(10, Sales)
          bindLong(11, InvoiceNumber)
          bindString(12, InvoiceType)
          bindDouble(13, TotalPrice)
          bindDouble(14, GST)
          bindDouble(15, SGST)
          bindDouble(16, CGST)
          bindDouble(17, IGST)
          bindDouble(18, CESS)
          bindDouble(19, VAT)
          bindDouble(20, Tax)
          bindDouble(21, CostPrice)
          bindDouble(22, InstantDiscount)
          bindDouble(23, FinalPrice)
          bindDouble(24, PendingAmount)
          bindDouble(25, ClearedAmount)
          bindString(26, InvoiceDate)
          bindString(27, CreatedAt)
          bindString(28, UpdatedAt)
          bindLong(29, __v)
        }.await()
    notifyQueries(-327_597_190) { emit ->
      emit("InvoiceTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InvoiceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InvoiceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InvoiceTable._id, InvoiceTable.InvoiceID, InvoiceTable.ShareLink, InvoiceTable.UserID, InvoiceTable.BusinessID, InvoiceTable.CustomerID, InvoiceTable.CustomerName, InvoiceTable.DialCode, InvoiceTable.MobileNumber, InvoiceTable.IsDeleted, InvoiceTable.Sales, InvoiceTable.InvoiceNumber, InvoiceTable.InvoiceType, InvoiceTable.TotalPrice, InvoiceTable.GST, InvoiceTable.SGST, InvoiceTable.CGST, InvoiceTable.IGST, InvoiceTable.CESS, InvoiceTable.VAT, InvoiceTable.Tax, InvoiceTable.CostPrice, InvoiceTable.InstantDiscount, InvoiceTable.FinalPrice, InvoiceTable.PendingAmount, InvoiceTable.ClearedAmount, InvoiceTable.InvoiceDate, InvoiceTable.CreatedAt, InvoiceTable.UpdatedAt, InvoiceTable.__v FROM InvoiceTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "InvoiceTable.sq:retrieve"
  }

  private inner class FindQuery<out T : Any>(
    public val InvoiceID: Long?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InvoiceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InvoiceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InvoiceTable._id, InvoiceTable.InvoiceID, InvoiceTable.ShareLink, InvoiceTable.UserID, InvoiceTable.BusinessID, InvoiceTable.CustomerID, InvoiceTable.CustomerName, InvoiceTable.DialCode, InvoiceTable.MobileNumber, InvoiceTable.IsDeleted, InvoiceTable.Sales, InvoiceTable.InvoiceNumber, InvoiceTable.InvoiceType, InvoiceTable.TotalPrice, InvoiceTable.GST, InvoiceTable.SGST, InvoiceTable.CGST, InvoiceTable.IGST, InvoiceTable.CESS, InvoiceTable.VAT, InvoiceTable.Tax, InvoiceTable.CostPrice, InvoiceTable.InstantDiscount, InvoiceTable.FinalPrice, InvoiceTable.PendingAmount, InvoiceTable.ClearedAmount, InvoiceTable.InvoiceDate, InvoiceTable.CreatedAt, InvoiceTable.UpdatedAt, InvoiceTable.__v FROM InvoiceTable WHERE InvoiceID ${ if (InvoiceID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT 1""",
        mapper, 1) {
      bindLong(0, InvoiceID)
    }

    override fun toString(): String = "InvoiceTable.sq:find"
  }

  private inner class GetInvoiceForUserQuery<out T : Any>(
    public val BusinessID: String?,
    public val UserID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InvoiceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InvoiceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InvoiceTable._id, InvoiceTable.InvoiceID, InvoiceTable.ShareLink, InvoiceTable.UserID, InvoiceTable.BusinessID, InvoiceTable.CustomerID, InvoiceTable.CustomerName, InvoiceTable.DialCode, InvoiceTable.MobileNumber, InvoiceTable.IsDeleted, InvoiceTable.Sales, InvoiceTable.InvoiceNumber, InvoiceTable.InvoiceType, InvoiceTable.TotalPrice, InvoiceTable.GST, InvoiceTable.SGST, InvoiceTable.CGST, InvoiceTable.IGST, InvoiceTable.CESS, InvoiceTable.VAT, InvoiceTable.Tax, InvoiceTable.CostPrice, InvoiceTable.InstantDiscount, InvoiceTable.FinalPrice, InvoiceTable.PendingAmount, InvoiceTable.ClearedAmount, InvoiceTable.InvoiceDate, InvoiceTable.CreatedAt, InvoiceTable.UpdatedAt, InvoiceTable.__v FROM InvoiceTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND UserID ${ if (UserID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, UserID)
    }

    override fun toString(): String = "InvoiceTable.sq:getInvoiceForUser"
  }

  private inner class GetInvoiceForCustomerQuery<out T : Any>(
    public val BusinessID: String?,
    public val CustomerID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InvoiceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InvoiceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InvoiceTable._id, InvoiceTable.InvoiceID, InvoiceTable.ShareLink, InvoiceTable.UserID, InvoiceTable.BusinessID, InvoiceTable.CustomerID, InvoiceTable.CustomerName, InvoiceTable.DialCode, InvoiceTable.MobileNumber, InvoiceTable.IsDeleted, InvoiceTable.Sales, InvoiceTable.InvoiceNumber, InvoiceTable.InvoiceType, InvoiceTable.TotalPrice, InvoiceTable.GST, InvoiceTable.SGST, InvoiceTable.CGST, InvoiceTable.IGST, InvoiceTable.CESS, InvoiceTable.VAT, InvoiceTable.Tax, InvoiceTable.CostPrice, InvoiceTable.InstantDiscount, InvoiceTable.FinalPrice, InvoiceTable.PendingAmount, InvoiceTable.ClearedAmount, InvoiceTable.InvoiceDate, InvoiceTable.CreatedAt, InvoiceTable.UpdatedAt, InvoiceTable.__v FROM InvoiceTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND CustomerID ${ if (CustomerID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, CustomerID)
    }

    override fun toString(): String = "InvoiceTable.sq:getInvoiceForCustomer"
  }

  private inner class GetLatestInvoiceDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InvoiceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InvoiceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM InvoiceTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "InvoiceTable.sq:getLatestInvoiceDate"
  }

  private inner class SearchQuery<out T : Any>(
    public val BusinessID: String?,
    public val CustomerName: String,
    public val MobileNumber: String,
    public val InvoiceNumber: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InvoiceTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InvoiceTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InvoiceTable._id, InvoiceTable.InvoiceID, InvoiceTable.ShareLink, InvoiceTable.UserID, InvoiceTable.BusinessID, InvoiceTable.CustomerID, InvoiceTable.CustomerName, InvoiceTable.DialCode, InvoiceTable.MobileNumber, InvoiceTable.IsDeleted, InvoiceTable.Sales, InvoiceTable.InvoiceNumber, InvoiceTable.InvoiceType, InvoiceTable.TotalPrice, InvoiceTable.GST, InvoiceTable.SGST, InvoiceTable.CGST, InvoiceTable.IGST, InvoiceTable.CESS, InvoiceTable.VAT, InvoiceTable.Tax, InvoiceTable.CostPrice, InvoiceTable.InstantDiscount, InvoiceTable.FinalPrice, InvoiceTable.PendingAmount, InvoiceTable.ClearedAmount, InvoiceTable.InvoiceDate, InvoiceTable.CreatedAt, InvoiceTable.UpdatedAt, InvoiceTable.__v FROM InvoiceTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND (CustomerName LIKE ? OR MobileNumber LIKE ? OR InvoiceNumber LIKE ?)""",
        mapper, 4) {
      bindString(0, BusinessID)
      bindString(1, CustomerName)
      bindString(2, MobileNumber)
      bindString(3, InvoiceNumber)
    }

    override fun toString(): String = "InvoiceTable.sq:search"
  }
}
