package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.inventoryTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class InventoryTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String, mapper: (
    _id: String,
    InventoryID: Long,
    ProductID: String,
    UserID: String,
    BusinessID: String,
    CategoryID: String?,
    SubCategoryID: String?,
    IsDeleted: Boolean,
    Name: String?,
    Description: String?,
    Model: String?,
    Barcode: String?,
    BrandID: String?,
    UOMID: String?,
    ColorID: String?,
    Quantity: Long?,
    SizeID: String?,
    DiscountID: String?,
    CoupanID: String?,
    TagID: String?,
    MaxQuantity: Long?,
    MinQuantity: Long?,
    StartRange: Long?,
    EndRange: Long?,
    SerialLength: Long?,
    ShortCode: String?,
    ProductPrice: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun retrieve(BusinessID: String): Query<InventoryTable> = retrieve(BusinessID) { _id,
      InventoryID, ProductID, UserID, BusinessID_, CategoryID, SubCategoryID, IsDeleted, Name,
      Description, Model, Barcode, BrandID, UOMID, ColorID, Quantity, SizeID, DiscountID, CoupanID,
      TagID, MaxQuantity, MinQuantity, StartRange, EndRange, SerialLength, ShortCode, ProductPrice,
      CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID,
      UserID,
      BusinessID_,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForProduct(
    BusinessID: String,
    ProductID: String,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForProductQuery(BusinessID, ProductID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun retrieveForProduct(BusinessID: String, ProductID: String): Query<InventoryTable> =
      retrieveForProduct(BusinessID, ProductID) { _id, InventoryID, ProductID_, UserID, BusinessID_,
      CategoryID, SubCategoryID, IsDeleted, Name, Description, Model, Barcode, BrandID, UOMID,
      ColorID, Quantity, SizeID, DiscountID, CoupanID, TagID, MaxQuantity, MinQuantity, StartRange,
      EndRange, SerialLength, ShortCode, ProductPrice, CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID_,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForCategory(
    BusinessID: String,
    CategoryID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForCategoryQuery(BusinessID, CategoryID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun retrieveForCategory(BusinessID: String, CategoryID: String?): Query<InventoryTable> =
      retrieveForCategory(BusinessID, CategoryID) { _id, InventoryID, ProductID, UserID,
      BusinessID_, CategoryID_, SubCategoryID, IsDeleted, Name, Description, Model, Barcode,
      BrandID, UOMID, ColorID, Quantity, SizeID, DiscountID, CoupanID, TagID, MaxQuantity,
      MinQuantity, StartRange, EndRange, SerialLength, ShortCode, ProductPrice, CreatedAt,
      UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID,
      UserID,
      BusinessID_,
      CategoryID_,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForSubCategory(
    BusinessID: String,
    SubCategoryID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForSubCategoryQuery(BusinessID, SubCategoryID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun retrieveForSubCategory(BusinessID: String, SubCategoryID: String?):
      Query<InventoryTable> = retrieveForSubCategory(BusinessID, SubCategoryID) { _id, InventoryID,
      ProductID, UserID, BusinessID_, CategoryID, SubCategoryID_, IsDeleted, Name, Description,
      Model, Barcode, BrandID, UOMID, ColorID, Quantity, SizeID, DiscountID, CoupanID, TagID,
      MaxQuantity, MinQuantity, StartRange, EndRange, SerialLength, ShortCode, ProductPrice,
      CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID,
      UserID,
      BusinessID_,
      CategoryID,
      SubCategoryID_,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    InventoryID: Long,
    ProductID: String,
    UserID: String,
    BusinessID: String,
    CategoryID: String?,
    SubCategoryID: String?,
    IsDeleted: Boolean,
    Name: String?,
    Description: String?,
    Model: String?,
    Barcode: String?,
    BrandID: String?,
    UOMID: String?,
    ColorID: String?,
    Quantity: Long?,
    SizeID: String?,
    DiscountID: String?,
    CoupanID: String?,
    TagID: String?,
    MaxQuantity: Long?,
    MinQuantity: Long?,
    StartRange: Long?,
    EndRange: Long?,
    SerialLength: Long?,
    ShortCode: String?,
    ProductPrice: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun find(_id: String): Query<InventoryTable> = find(_id) { _id_, InventoryID, ProductID,
      UserID, BusinessID, CategoryID, SubCategoryID, IsDeleted, Name, Description, Model, Barcode,
      BrandID, UOMID, ColorID, Quantity, SizeID, DiscountID, CoupanID, TagID, MaxQuantity,
      MinQuantity, StartRange, EndRange, SerialLength, ShortCode, ProductPrice, CreatedAt,
      UpdatedAt ->
    InventoryTable(
      _id_,
      InventoryID,
      ProductID,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String, mapper: (UpdatedAt: String?) -> T): Query<T>
      = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDateForProduct(
    BusinessID: String,
    ProductID: String,
    mapper: (UpdatedAt: String?) -> T,
  ): Query<T> = LastSyncDateForProductQuery(BusinessID, ProductID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDateForProduct(BusinessID: String, ProductID: String):
      Query<LastSyncDateForProduct> = lastSyncDateForProduct(BusinessID, ProductID) { UpdatedAt ->
    LastSyncDateForProduct(
      UpdatedAt
    )
  }

  public fun <T : Any> search(
    ProductID: String,
    Name: String,
    Description: String,
    ShortCode: String,
    ProductPrice: String,
    Name_: String,
    Name__: String,
    Name___: String,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = SearchQuery(ProductID, Name, Description, ShortCode, ProductPrice, Name_, Name__,
      Name___) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun search(
    ProductID: String,
    Name: String,
    Description: String,
    ShortCode: String,
    ProductPrice: String,
    Name_: String,
    Name__: String,
    Name___: String,
  ): Query<InventoryTable> = search(ProductID, Name, Description, ShortCode, ProductPrice, Name_,
      Name__, Name___) { _id, InventoryID, ProductID_, UserID, BusinessID, CategoryID,
      SubCategoryID, IsDeleted, Name____, Description_, Model, Barcode, BrandID, UOMID, ColorID,
      Quantity, SizeID, DiscountID, CoupanID, TagID, MaxQuantity, MinQuantity, StartRange, EndRange,
      SerialLength, ShortCode_, ProductPrice_, CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name____,
      Description_,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode_,
      ProductPrice_,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> findByColorId(
    ProductID: String,
    ColorID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = FindByColorIdQuery(ProductID, ColorID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun findByColorId(ProductID: String, ColorID: String?): Query<InventoryTable> =
      findByColorId(ProductID, ColorID) { _id, InventoryID, ProductID_, UserID, BusinessID,
      CategoryID, SubCategoryID, IsDeleted, Name, Description, Model, Barcode, BrandID, UOMID,
      ColorID_, Quantity, SizeID, DiscountID, CoupanID, TagID, MaxQuantity, MinQuantity, StartRange,
      EndRange, SerialLength, ShortCode, ProductPrice, CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID_,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> findBySizeId(
    ProductID: String,
    SizeID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = FindBySizeIdQuery(ProductID, SizeID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun findBySizeId(ProductID: String, SizeID: String?): Query<InventoryTable> =
      findBySizeId(ProductID, SizeID) { _id, InventoryID, ProductID_, UserID, BusinessID,
      CategoryID, SubCategoryID, IsDeleted, Name, Description, Model, Barcode, BrandID, UOMID,
      ColorID, Quantity, SizeID_, DiscountID, CoupanID, TagID, MaxQuantity, MinQuantity, StartRange,
      EndRange, SerialLength, ShortCode, ProductPrice, CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID,
      Quantity,
      SizeID_,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> findByUOMId(
    ProductID: String,
    UOMID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = FindByUOMIdQuery(ProductID, UOMID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun findByUOMId(ProductID: String, UOMID: String?): Query<InventoryTable> =
      findByUOMId(ProductID, UOMID) { _id, InventoryID, ProductID_, UserID, BusinessID, CategoryID,
      SubCategoryID, IsDeleted, Name, Description, Model, Barcode, BrandID, UOMID_, ColorID,
      Quantity, SizeID, DiscountID, CoupanID, TagID, MaxQuantity, MinQuantity, StartRange, EndRange,
      SerialLength, ShortCode, ProductPrice, CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID_,
      ColorID,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> findByColorIdAndSizeId(
    ProductID: String,
    ColorID: String?,
    SizeID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = FindByColorIdAndSizeIdQuery(ProductID, ColorID, SizeID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun findByColorIdAndSizeId(
    ProductID: String,
    ColorID: String?,
    SizeID: String?,
  ): Query<InventoryTable> = findByColorIdAndSizeId(ProductID, ColorID, SizeID) { _id, InventoryID,
      ProductID_, UserID, BusinessID, CategoryID, SubCategoryID, IsDeleted, Name, Description,
      Model, Barcode, BrandID, UOMID, ColorID_, Quantity, SizeID_, DiscountID, CoupanID, TagID,
      MaxQuantity, MinQuantity, StartRange, EndRange, SerialLength, ShortCode, ProductPrice,
      CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID,
      ColorID_,
      Quantity,
      SizeID_,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> findByColorIdAndUOMId(
    ProductID: String,
    ColorID: String?,
    UOMID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = FindByColorIdAndUOMIdQuery(ProductID, ColorID, UOMID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun findByColorIdAndUOMId(
    ProductID: String,
    ColorID: String?,
    UOMID: String?,
  ): Query<InventoryTable> = findByColorIdAndUOMId(ProductID, ColorID, UOMID) { _id, InventoryID,
      ProductID_, UserID, BusinessID, CategoryID, SubCategoryID, IsDeleted, Name, Description,
      Model, Barcode, BrandID, UOMID_, ColorID_, Quantity, SizeID, DiscountID, CoupanID, TagID,
      MaxQuantity, MinQuantity, StartRange, EndRange, SerialLength, ShortCode, ProductPrice,
      CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID_,
      ColorID_,
      Quantity,
      SizeID,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> findBySizeIdAndUOMId(
    ProductID: String,
    SizeID: String?,
    UOMID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = FindBySizeIdAndUOMIdQuery(ProductID, SizeID, UOMID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun findBySizeIdAndUOMId(
    ProductID: String,
    SizeID: String?,
    UOMID: String?,
  ): Query<InventoryTable> = findBySizeIdAndUOMId(ProductID, SizeID, UOMID) { _id, InventoryID,
      ProductID_, UserID, BusinessID, CategoryID, SubCategoryID, IsDeleted, Name, Description,
      Model, Barcode, BrandID, UOMID_, ColorID, Quantity, SizeID_, DiscountID, CoupanID, TagID,
      MaxQuantity, MinQuantity, StartRange, EndRange, SerialLength, ShortCode, ProductPrice,
      CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID_,
      ColorID,
      Quantity,
      SizeID_,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> findByColorIdAndSizeIdAndUOMId(
    ProductID: String,
    ColorID: String?,
    SizeID: String?,
    UOMID: String?,
    mapper: (
      _id: String,
      InventoryID: Long,
      ProductID: String,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      IsDeleted: Boolean,
      Name: String?,
      Description: String?,
      Model: String?,
      Barcode: String?,
      BrandID: String?,
      UOMID: String?,
      ColorID: String?,
      Quantity: Long?,
      SizeID: String?,
      DiscountID: String?,
      CoupanID: String?,
      TagID: String?,
      MaxQuantity: Long?,
      MinQuantity: Long?,
      StartRange: Long?,
      EndRange: Long?,
      SerialLength: Long?,
      ShortCode: String?,
      ProductPrice: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = FindByColorIdAndSizeIdAndUOMIdQuery(ProductID, ColorID, SizeID, UOMID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getBoolean(7)!!,
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getLong(21),
      cursor.getLong(22),
      cursor.getLong(23),
      cursor.getLong(24),
      cursor.getString(25),
      cursor.getString(26),
      cursor.getString(27),
      cursor.getString(28)
    )
  }

  public fun findByColorIdAndSizeIdAndUOMId(
    ProductID: String,
    ColorID: String?,
    SizeID: String?,
    UOMID: String?,
  ): Query<InventoryTable> = findByColorIdAndSizeIdAndUOMId(ProductID, ColorID, SizeID, UOMID) {
      _id, InventoryID, ProductID_, UserID, BusinessID, CategoryID, SubCategoryID, IsDeleted, Name,
      Description, Model, Barcode, BrandID, UOMID_, ColorID_, Quantity, SizeID_, DiscountID,
      CoupanID, TagID, MaxQuantity, MinQuantity, StartRange, EndRange, SerialLength, ShortCode,
      ProductPrice, CreatedAt, UpdatedAt ->
    InventoryTable(
      _id,
      InventoryID,
      ProductID_,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      IsDeleted,
      Name,
      Description,
      Model,
      Barcode,
      BrandID,
      UOMID_,
      ColorID_,
      Quantity,
      SizeID_,
      DiscountID,
      CoupanID,
      TagID,
      MaxQuantity,
      MinQuantity,
      StartRange,
      EndRange,
      SerialLength,
      ShortCode,
      ProductPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    InventoryID: Long,
    ProductID: String,
    UserID: String,
    BusinessID: String,
    CategoryID: String?,
    SubCategoryID: String?,
    IsDeleted: Boolean,
    Name: String?,
    Description: String?,
    Model: String?,
    Barcode: String?,
    BrandID: String?,
    UOMID: String?,
    ColorID: String?,
    Quantity: Long?,
    SizeID: String?,
    DiscountID: String?,
    CoupanID: String?,
    TagID: String?,
    MaxQuantity: Long?,
    MinQuantity: Long?,
    StartRange: Long?,
    EndRange: Long?,
    SerialLength: Long?,
    ShortCode: String?,
    ProductPrice: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(2_099_897_579, """
        |INSERT OR REPLACE INTO InventoryTable(_id, InventoryID, ProductID, UserID, BusinessID, CategoryID, SubCategoryID, IsDeleted, Name,Description,Model,Barcode,BrandID,UOMID,ColorID,Quantity,SizeID,DiscountID,CoupanID,TagID,MaxQuantity,MinQuantity,StartRange,EndRange,SerialLength,ShortCode,
        |    ProductPrice,CreatedAt,UpdatedAt)
        |    VALUES(?, ?, ?, ?, ? ,?, ?, ?,?,?,?,?,?, ?,?,?, ?, ?, ?, ? ,?, ?, ?,?,?,?,?,?, ?)
        """.trimMargin(), 29) {
          bindString(0, _id)
          bindLong(1, InventoryID)
          bindString(2, ProductID)
          bindString(3, UserID)
          bindString(4, BusinessID)
          bindString(5, CategoryID)
          bindString(6, SubCategoryID)
          bindBoolean(7, IsDeleted)
          bindString(8, Name)
          bindString(9, Description)
          bindString(10, Model)
          bindString(11, Barcode)
          bindString(12, BrandID)
          bindString(13, UOMID)
          bindString(14, ColorID)
          bindLong(15, Quantity)
          bindString(16, SizeID)
          bindString(17, DiscountID)
          bindString(18, CoupanID)
          bindString(19, TagID)
          bindLong(20, MaxQuantity)
          bindLong(21, MinQuantity)
          bindLong(22, StartRange)
          bindLong(23, EndRange)
          bindLong(24, SerialLength)
          bindString(25, ShortCode)
          bindString(26, ProductPrice)
          bindString(27, CreatedAt)
          bindString(28, UpdatedAt)
        }.await()
    notifyQueries(2_099_897_579) { emit ->
      emit("InventoryTable")
    }
  }

  public suspend fun deleteAll() {
    driver.execute(1_875_931_268, """DELETE FROM InventoryTable""", 0).await()
    notifyQueries(1_875_931_268) { emit ->
      emit("InventoryTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_484_874_258,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE BusinessID = ? AND IsDeleted = 0 ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "InventoryTable.sq:retrieve"
  }

  private inner class RetrieveForProductQuery<out T : Any>(
    public val BusinessID: String,
    public val ProductID: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(956_955_764,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE BusinessID = ? AND ProductID = ? AND IsDeleted = 0 ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, ProductID)
    }

    override fun toString(): String = "InventoryTable.sq:retrieveForProduct"
  }

  private inner class RetrieveForCategoryQuery<out T : Any>(
    public val BusinessID: String,
    public val CategoryID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE BusinessID = ? AND CategoryID ${ if (CategoryID == null) "IS" else "=" } ? AND IsDeleted = 0 ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, CategoryID)
    }

    override fun toString(): String = "InventoryTable.sq:retrieveForCategory"
  }

  private inner class RetrieveForSubCategoryQuery<out T : Any>(
    public val BusinessID: String,
    public val SubCategoryID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE BusinessID = ? AND SubCategoryID ${ if (SubCategoryID == null) "IS" else "=" } ? AND IsDeleted = 0 ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, SubCategoryID)
    }

    override fun toString(): String = "InventoryTable.sq:retrieveForSubCategory"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(735_050_859,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE _id = ? ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "InventoryTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_132_785_233,
        """SELECT UpdatedAt FROM InventoryTable WHERE BusinessID = ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "InventoryTable.sq:lastSyncDate"
  }

  private inner class LastSyncDateForProductQuery<out T : Any>(
    public val BusinessID: String,
    public val ProductID: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(299_724_055,
        """SELECT UpdatedAt FROM InventoryTable WHERE BusinessID = ? AND ProductID = ?  ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, ProductID)
    }

    override fun toString(): String = "InventoryTable.sq:lastSyncDateForProduct"
  }

  private inner class SearchQuery<out T : Any>(
    public val ProductID: String,
    public val Name: String,
    public val Description: String,
    public val ShortCode: String,
    public val ProductPrice: String,
    public val Name_: String,
    public val Name__: String,
    public val Name___: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", "ProductColorTable", "UOMTable", "SizeTable", listener =
          listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", "ProductColorTable", "UOMTable", "SizeTable", listener
          = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_917_614_118, """
    |SELECT it._id, it.InventoryID, it.ProductID, it.UserID, it.BusinessID, it.CategoryID, it.SubCategoryID, it.IsDeleted, it.Name, it.Description, it.Model, it.Barcode, it.BrandID, it.UOMID, it.ColorID, it.Quantity, it.SizeID, it.DiscountID, it.CoupanID, it.TagID, it.MaxQuantity, it.MinQuantity, it.StartRange, it.EndRange, it.SerialLength, it.ShortCode, it.ProductPrice, it.CreatedAt, it.UpdatedAt FROM InventoryTable it
    |    JOIN ProductColorTable ON ProductColorTable._id = it.ColorID
    |    JOIN UOMTable ON UOMTable._id = it.UOMID
    |    JOIN SizeTable ON SizeTable._id = it.SizeID
    |    WHERE it.ProductID = ? AND it.IsDeleted = 0
    |    AND (it.Name LIKE ? COLLATE NOCASE
    |        OR it.Description LIKE ? COLLATE NOCASE
    |        OR it.ShortCode LIKE ? COLLATE NOCASE
    |        OR it.ProductPrice LIKE ? COLLATE NOCASE
    |    OR  ProductColorTable.Name LIKE ?
    |    OR  UOMTable.Name LIKE ?
    |    OR  SizeTable.Name LIKE ?
    |    )
    |    ORDER BY it.UpdatedAt DESC
    """.trimMargin(), mapper, 8) {
      bindString(0, ProductID)
      bindString(1, Name)
      bindString(2, Description)
      bindString(3, ShortCode)
      bindString(4, ProductPrice)
      bindString(5, Name_)
      bindString(6, Name__)
      bindString(7, Name___)
    }

    override fun toString(): String = "InventoryTable.sq:search"
  }

  private inner class FindByColorIdQuery<out T : Any>(
    public val ProductID: String,
    public val ColorID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE ProductID = ? AND  ColorID ${ if (ColorID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, ProductID)
      bindString(1, ColorID)
    }

    override fun toString(): String = "InventoryTable.sq:findByColorId"
  }

  private inner class FindBySizeIdQuery<out T : Any>(
    public val ProductID: String,
    public val SizeID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE ProductID = ? AND SizeID ${ if (SizeID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, ProductID)
      bindString(1, SizeID)
    }

    override fun toString(): String = "InventoryTable.sq:findBySizeId"
  }

  private inner class FindByUOMIdQuery<out T : Any>(
    public val ProductID: String,
    public val UOMID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE ProductID = ? AND UOMID ${ if (UOMID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, ProductID)
      bindString(1, UOMID)
    }

    override fun toString(): String = "InventoryTable.sq:findByUOMId"
  }

  private inner class FindByColorIdAndSizeIdQuery<out T : Any>(
    public val ProductID: String,
    public val ColorID: String?,
    public val SizeID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE ProductID = ? AND ColorID ${ if (ColorID == null) "IS" else "=" } ? AND SizeID ${ if (SizeID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 3) {
      bindString(0, ProductID)
      bindString(1, ColorID)
      bindString(2, SizeID)
    }

    override fun toString(): String = "InventoryTable.sq:findByColorIdAndSizeId"
  }

  private inner class FindByColorIdAndUOMIdQuery<out T : Any>(
    public val ProductID: String,
    public val ColorID: String?,
    public val UOMID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE ProductID = ? AND ColorID ${ if (ColorID == null) "IS" else "=" } ? AND UOMID ${ if (UOMID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 3) {
      bindString(0, ProductID)
      bindString(1, ColorID)
      bindString(2, UOMID)
    }

    override fun toString(): String = "InventoryTable.sq:findByColorIdAndUOMId"
  }

  private inner class FindBySizeIdAndUOMIdQuery<out T : Any>(
    public val ProductID: String,
    public val SizeID: String?,
    public val UOMID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE ProductID = ? AND SizeID ${ if (SizeID == null) "IS" else "=" } ? AND UOMID ${ if (UOMID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 3) {
      bindString(0, ProductID)
      bindString(1, SizeID)
      bindString(2, UOMID)
    }

    override fun toString(): String = "InventoryTable.sq:findBySizeIdAndUOMId"
  }

  private inner class FindByColorIdAndSizeIdAndUOMIdQuery<out T : Any>(
    public val ProductID: String,
    public val ColorID: String?,
    public val SizeID: String?,
    public val UOMID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("InventoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("InventoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT InventoryTable._id, InventoryTable.InventoryID, InventoryTable.ProductID, InventoryTable.UserID, InventoryTable.BusinessID, InventoryTable.CategoryID, InventoryTable.SubCategoryID, InventoryTable.IsDeleted, InventoryTable.Name, InventoryTable.Description, InventoryTable.Model, InventoryTable.Barcode, InventoryTable.BrandID, InventoryTable.UOMID, InventoryTable.ColorID, InventoryTable.Quantity, InventoryTable.SizeID, InventoryTable.DiscountID, InventoryTable.CoupanID, InventoryTable.TagID, InventoryTable.MaxQuantity, InventoryTable.MinQuantity, InventoryTable.StartRange, InventoryTable.EndRange, InventoryTable.SerialLength, InventoryTable.ShortCode, InventoryTable.ProductPrice, InventoryTable.CreatedAt, InventoryTable.UpdatedAt FROM InventoryTable WHERE ProductID = ? AND ColorID ${ if (ColorID == null) "IS" else "=" } ? AND SizeID ${ if (SizeID == null) "IS" else "=" } ? AND UOMID ${ if (UOMID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 4) {
      bindString(0, ProductID)
      bindString(1, ColorID)
      bindString(2, SizeID)
      bindString(3, UOMID)
    }

    override fun toString(): String = "InventoryTable.sq:findByColorIdAndSizeIdAndUOMId"
  }
}
