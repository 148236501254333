package presentation.screens.common.notification.component.contentCard

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import theme.ThemeColor

@Composable
fun NewFeatureAlertCard() {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .height(180.dp)
            .background(ThemeColor.shared().cardBackground),//cardBackground),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,//cardBackground,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().cardBackground
        ),
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(start=18.dp,20.dp,end = 6.dp)
        ) {
            Box(
                modifier = Modifier
                    .width(40.dp)
                    .height(40.dp)
                    .background(
                        color = ThemeColor.shared().lightGray,
                        shape = RoundedCornerShape(20.dp)
                    )
            ) {
                //   ImageView("")
            }
            Column(
                modifier = Modifier
                    .fillMaxSize()
            ) {
                Text(
                    "New Feature Alert!",
                    fontSize = 16.sp,
                    fontWeight = FontWeight.Bold,
                    fontStyle = FontStyle.Normal,
                    modifier = Modifier.padding(start = 8.dp)
                )
                Text(
                    "We’re pleased to introduce the latest enhancements in our template experience.",
                    fontSize = 15.sp,
                    color = Color.Gray,
                    modifier = Modifier.padding(start = 8.dp).fillMaxSize().weight(0.7f)
                )
                Row(
                    modifier = Modifier
                        .fillMaxSize().weight(0.35f).padding(bottom = 12.dp), horizontalArrangement = Arrangement.End
                )
                {
                    Button(
                        onClick = { },
                        modifier = Modifier
                            .width(140.dp)
                            .height(36.dp),
                    ) {
                        Text("Try now")
                    }
                }

            }
        }
    }
}
