package component.country

import androidx.compose.runtime.Composable

@Composable
fun CountryPickerView(onDialCodeChange: (CountryCode) -> Unit) {
    val countryCode = CountryPicker()
    countryCode.CountryCodeDialog(
        pickedCountry = {
            onDialCodeChange(it)
//            Log.v("TAG", "country name is : ${it.countryName}")
        },
        dialogSearch = true,
        dialogRounded = 22
    )
}