package component.button

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.rounded.Remove
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import theme.ThemeColor

@Composable
fun StepperButton(count: Long, onIncrease: () -> Unit, onDecrease: () -> Unit) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .border(1.dp, ThemeColor.shared().PrimaryColor, shape = RoundedCornerShape(8.dp)),
        horizontalArrangement = Arrangement.SpaceBetween,

        ) {

        IconButton(
            onClick = {
                onDecrease()
            },
            modifier = Modifier
                .size(30.dp)
//                .border(1.dp, PrimaryColor, shape = CircleShape)

        ) {
            Icon(
                Icons.Rounded.Remove,
                contentDescription = "decrease quantity",
                tint = ThemeColor.shared().PrimaryColor
            )
        }


        Text(

            text = count.toString(),
            textAlign = TextAlign.Center,
            modifier = Modifier
                .fillMaxHeight()
                .wrapContentHeight()
        )

        IconButton(
            onClick = {
                onIncrease()
            },
            modifier = Modifier
                .size(30.dp)
//                .border(1.dp, PrimaryColor, shape = CircleShape)
        ) {
            Icon(
                Icons.Default.Add,
                contentDescription = "increase quantity",
                tint = ThemeColor.shared().PrimaryColor
            )
        }


    }
}