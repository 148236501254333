package feature.businessMenu.modal

import androidx.compose.ui.graphics.Color
import kotlinx.serialization.SerialName
import navigation.containers.ScreenName
import org.jetbrains.compose.resources.DrawableResource

enum class MenuType {
    HEADER, FOOTER, MENU
}

data class BusinessMenu(
    @SerialName("id") var id: String? = null,
    @SerialName("type") var menuType: MenuType = MenuType.MENU,
    @SerialName("title") var title: String? = null,
    @SerialName("icon") var icon: DrawableResource? = null,
    @SerialName("target_screen") var target_screen: ScreenName? = null,
    @SerialName("background_color") var background_color: Color? = null,
    @SerialName("sub_menu") var sub_menu: List<BusinessMenu> = arrayListOf(),
)