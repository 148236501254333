package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.productCategoryTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class ProductCategoryTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    ProductCategoryID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Image: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8)
    )
  }

  public fun retrieve(BusinessID: String?): Query<ProductCategoryTable> = retrieve(BusinessID) {
      _id, ProductCategoryID, UserID, BusinessID_, IsDeleted, Name, Image, CreatedAt, UpdatedAt ->
    ProductCategoryTable(
      _id,
      ProductCategoryID,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      Image,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    ProductCategoryID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Image: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8)
    )
  }

  public fun find(_id: String): Query<ProductCategoryTable> = find(_id) { _id_, ProductCategoryID,
      UserID, BusinessID, IsDeleted, Name, Image, CreatedAt, UpdatedAt ->
    ProductCategoryTable(
      _id_,
      ProductCategoryID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Image,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    ProductCategoryID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Image: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(319_842_714, """
        |INSERT OR REPLACE INTO ProductCategoryTable( _id,ProductCategoryID ,UserID, BusinessID,IsDeleted,Name,Image,CreatedAt,UpdatedAt)
        |    VALUES(?, ?,?, ? ,?, ?, ?,?,?)
        """.trimMargin(), 9) {
          bindString(0, _id)
          bindLong(1, ProductCategoryID)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindBoolean(4, IsDeleted)
          bindString(5, Name)
          bindString(6, Image)
          bindString(7, CreatedAt)
          bindString(8, UpdatedAt)
        }.await()
    notifyQueries(319_842_714) { emit ->
      emit("ProductCategoryTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductCategoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductCategoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT ProductCategoryTable._id, ProductCategoryTable.ProductCategoryID, ProductCategoryTable.UserID, ProductCategoryTable.BusinessID, ProductCategoryTable.IsDeleted, ProductCategoryTable.Name, ProductCategoryTable.Image, ProductCategoryTable.CreatedAt, ProductCategoryTable.UpdatedAt FROM ProductCategoryTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "ProductCategoryTable.sq:retrieve"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductCategoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductCategoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-39_984_934,
        """SELECT ProductCategoryTable._id, ProductCategoryTable.ProductCategoryID, ProductCategoryTable.UserID, ProductCategoryTable.BusinessID, ProductCategoryTable.IsDeleted, ProductCategoryTable.Name, ProductCategoryTable.Image, ProductCategoryTable.CreatedAt, ProductCategoryTable.UpdatedAt FROM ProductCategoryTable WHERE _id = ? LIMIT 1""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "ProductCategoryTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductCategoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductCategoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM ProductCategoryTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "ProductCategoryTable.sq:lastSyncDate"
  }
}
