package presentation.screens.business.inventory.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.inventory.model.tag.Tag
import feature.inventory.viewModel.tag.TagViewModel
import theme.ThemeColor

@Composable
fun SelectTagRow(selected: String?, onChange: (Tag) -> Unit = {}) {
    val viewModel = TagViewModel()
    val allTag = viewModel.allTag.collectAsState()
    LaunchedEffect(key1 = Unit) {
        viewModel.loadAllTag()
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("Tag")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allTag.value.count()) {
                HorizontalTagCard(
                    allTag.value[it],
                    isSelected = selected == allTag.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}