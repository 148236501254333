package feature.app

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import navigation.AppNavigator
import navigation.containers.ScreenName

open class AppViewModel {
    val jsonDecoder = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }
    private val appRepository = AppStateHandler.shared().repository
    val scope: CoroutineScope = CoroutineScope(Job() + Dispatchers.Default)
    var mainScope: CoroutineScope = CoroutineScope(Job() + Dispatchers.Main)
    var unconfirmedScope: CoroutineScope = CoroutineScope(Job() + Dispatchers.Unconfined)

    val isLoading: StateFlow<Boolean>
        get() = appRepository.isLoading

    val zipCode: StateFlow<String>
        get() = appRepository.zipCode

    fun setZipCode(value: String) {
        scope.launch {
            appRepository.zipCodeState.emit(value)
        }
    }

    private var navigationParams: StateFlow<MutableMap<ScreenName?, NavigationParam?>> =
        appRepository.navigationParam

    var viewModels: StateFlow<MutableMap<ScreenName?, AppViewModel?>> =
        appRepository.viewModels

    fun startLoading() {
        scope.launch {
            appRepository.isLoadingState.emit(true)
        }
    }

    fun stopLoading() {
        scope.launch {
            appRepository.isLoadingState.emit(false)
        }
    }

    open fun changeSearchQuery(value: String) {

    }

    open fun performSearch(value: String) {

    }

    val timeStamp: Long
        get() {
            return Clock.System.now().epochSeconds
        }


    fun setNavigationParam(name: ScreenName?, params: NavigationParam?) {
        val currentParams = navigationParams.value
        currentParams[name] = params
        scope.launch {
            appRepository.navigationParamState.emit(currentParams)
        }
    }

    fun setViewModel(
        name: ScreenName?,
        value: AppViewModel?
    ) {
        updateViewModel(name, value)
    }

    private fun updateViewModel(name: ScreenName?, model: AppViewModel?) {
        val currentViewModel = viewModels.value
        currentViewModel[name] = model
        scope.launch {
            appRepository.viewModelsState.emit(currentViewModel)
        }
    }

    inline fun <reified T> getViewModel(screen: ScreenName?): T? {
        val vm = viewModels.value[screen]
        if (vm != null && vm is T) {
            return vm
        } else {
            return null
        }
    }

    fun getParam(name: ScreenName?): NavigationParam {
        val param = navigationParams.value.get(name)
        if (param != null) {
            return param
        } else {
            setNavigationParam(name, NavigationParam())
            return NavigationParam()
        }
    }

    fun navigateTo(
        name: ScreenName?,
        params: NavigationParam? = null
    ) {
        val existingParam = getParam(name)
        existingParam.update(params)
        setNavigationParam(name, existingParam)
        AppNavigator.shared().navigateTo(name)
    }
}
