package storage.Database.table.inventory

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.InventoryTable
import feature.app.NavigationParam
import feature.inventory.model.Inventory
import feature.product.model.Product
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class InventoryDatabaseTable : DatabaseTable() {
    fun insert(item: Inventory?) {
        val table = AppDatabase.shared().database?.inventoryTableQueries

        scope.launch {
            if (item != null) {

                table?.insert(
                    _id = item.Id,
                    InventoryID = item.InventoryID,
                    ProductID = item.ProductID,
                    UserID = item.UserID,
                    BusinessID = item.BusinessID,
                    CategoryID = item.CategoryID,
                    SubCategoryID = item.SubCategoryID,
                    IsDeleted = item.IsDeleted,
                    Name = item.Name,
                    Description = item.Description,
                    Model = item.Model,
                    Barcode = item.Barcode,
                    BrandID = item.BrandID,
                    UOMID = item.UOMID,
                    ColorID = item.ColorID,
                    Quantity = item.qty,
                    SizeID = item.SizeID,
                    DiscountID = item.DiscountID,
                    CoupanID = item.CoupanID,
                    TagID = item.TagID,
                    MaxQuantity = item.MaxQuantity,
                    MinQuantity = item.MinQuantity,
                    StartRange = item.StartRange,
                    EndRange = item.EndRange,
                    SerialLength = item.SerialLength,
                    ShortCode = item.ShortCode,
                    ProductPrice = jsonDecoder.encodeToString(item?.ProductPrice),
                    CreatedAt = item.CreatedAt,
                    UpdatedAt = item.UpdatedAt
                )
            }
        }
    }

    fun insert(items: List<Inventory>?,callback: () -> Unit) {
        if(items?.isEmpty() == true){
            callback()
        }else{
            scope.launch {
                items?.forEach { item ->
                    insert(item)
                }
                callback()
            }
        }
    }

    fun retrieve(id: String, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        val itemsFetched: ArrayList<Inventory> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun retrieveAll(id: String,productId:String ,callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        val itemsFetched: ArrayList<Inventory> = arrayListOf()
        scope.launch {
            val items = table?.retrieveForProduct(id,productId)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun find(id: String, callback: (Inventory?) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: Inventory? = null
        scope.launch {
            val items = table?.find(id)?.awaitAsOneOrNull()
            items?.let { item ->
                itemsFetched = convert(item)
            }
            callback(itemsFetched)
        }
    }

    fun search(query: String, param: NavigationParam?, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: ArrayList<Inventory> = arrayListOf()
        if(param?.business?.Id != null && param.product?.Id != null){
            scope.launch {
                val items = table?.search(
                    param.product!!.Id,
                    "%${query}%",
                    "%${query}%",
                    "%${query}%",
                    "%${query}%",
                    "%${query}%",
                    "%${query}%",
                    "%${query}%",
                    )?.awaitAsList()

                items?.forEach { item ->
                    itemsFetched.add(convert(item))
                }
                callback(itemsFetched)
            }
        }
    }

    fun search(id: String, query: String, callback: (List<Product>) -> Unit) {
        val table = AppDatabase.shared().database?.productTableQueries
        val itemsFetched: ArrayList<Product> = arrayListOf()
        scope.launch {
            val items = table?.search(id, "%${query}%", "%${query}%", "%${query}%")?.awaitAsList()
//            items?.forEach { item ->
//                itemsFetched.add(convert(item))
//            }
            callback(itemsFetched)
        }
    }

    fun findByColorId(colorId: String, param: NavigationParam?, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: ArrayList<Inventory> = arrayListOf()
        if(param?.product?.Id != null){
            scope.launch {
                val items = table?.findByColorId(
                    param.product!!.Id,
                    colorId,
                )?.awaitAsList()

                items?.forEach { item ->
                    itemsFetched.add(convert(item))
                }
                callback(itemsFetched)
            }
        }
    }

    fun findBySizeId(sizeId: String, param: NavigationParam?, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: ArrayList<Inventory> = arrayListOf()
        if(param?.product?.Id != null){
            scope.launch {
                val items = table?.findBySizeId(
                    param.product!!.Id,
                    sizeId,
                )?.awaitAsList()

                items?.forEach { item ->
                    itemsFetched.add(convert(item))
                }
                callback(itemsFetched)
            }
        }
    }

    fun findByUOMId(uomId: String, param: NavigationParam?, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: ArrayList<Inventory> = arrayListOf()
        if(param?.product?.Id != null){
            scope.launch {
                val items = table?.findByUOMId(
                    param.product!!.Id,
                    uomId,
                )?.awaitAsList()

                items?.forEach { item ->
                    itemsFetched.add(convert(item))
                }
                callback(itemsFetched)
            }
        }
    }

    fun findByColorIdAndSizeId(colorId: String, sizeId: String, param: NavigationParam?, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: ArrayList<Inventory> = arrayListOf()
        if(param?.product?.Id != null){
            scope.launch {
                val items = table?.findByColorIdAndSizeId(
                    param.product!!.Id,
                    colorId,
                    sizeId,
                )?.awaitAsList()

                items?.forEach { item ->
                    itemsFetched.add(convert(item))
                }
                callback(itemsFetched)
            }
        }
    }

    fun findByColorIdAndUOMId(colorId: String, uomId: String, param: NavigationParam?, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: ArrayList<Inventory> = arrayListOf()
        if(param?.product?.Id != null){
            scope.launch {
                val items = table?.findByColorIdAndUOMId(
                    param.product!!.Id,
                    colorId,
                    uomId
                )?.awaitAsList()

                items?.forEach { item ->
                    itemsFetched.add(convert(item))
                }
                callback(itemsFetched)
            }
        }
    }

    fun findBySizeIdAndUOMId(sizeId: String, uomId: String, param: NavigationParam?, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: ArrayList<Inventory> = arrayListOf()
        if(param?.product?.Id != null){
            scope.launch {
                val items = table?.findBySizeIdAndUOMId(
                    param.product!!.Id,
                    sizeId,
                    uomId,
                )?.awaitAsList()

                items?.forEach { item ->
                    itemsFetched.add(convert(item))
                }
                callback(itemsFetched)
            }
        }
    }

    fun findByColorIdAndSizeIdAndUOMId(colorId: String, sizeId: String, uomId: String, param: NavigationParam?, callback: (List<Inventory>) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        var itemsFetched: ArrayList<Inventory> = arrayListOf()
        if(param?.product?.Id != null){
            scope.launch {
                val items = table?.findByColorIdAndSizeIdAndUOMId(
                    param.product!!.Id,
                    colorId,
                    sizeId,
                    uomId,
                )?.awaitAsList()

                items?.forEach { item ->
                    itemsFetched.add(convert(item))
                }
                callback(itemsFetched)
            }
        }
    }


    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        scope.launch {
            val dateFromDb = table?.lastSyncDate(id)?.awaitAsOneOrNull()
            callback(dateFromDb?.UpdatedAt)
        }
    }

    fun lastSyncDate(param: NavigationParam?, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.inventoryTableQueries
        if(param?.business != null && param.product != null){
            scope.launch {
                val dateFromDb = table?.lastSyncDateForProduct(param.business!!.Id,param.product!!.Id)?.awaitAsOneOrNull()
                callback(dateFromDb?.UpdatedAt)
                // table?.deleteAll()
            }
        }else{
            callback(null)
        }

    }

    fun convert(item: InventoryTable): Inventory {
        return Inventory(
            Id = item._id,
            InventoryID = item.InventoryID,
            ProductID = item.ProductID,
            UserID = item.UserID,
            BusinessID = item.BusinessID,
            CategoryID = item.CategoryID,
            SubCategoryID = item.SubCategoryID,
            IsDeleted = item.IsDeleted,
            Name = item.Name,
            Description = item.Description,
            Model = item.Model,
            Barcode = item.Barcode,
            BrandID = item.BrandID,
            UOMID = item.UOMID,
            ColorID = item.ColorID,
            SizeID = item.SizeID,
            DiscountID = item.DiscountID,
            CoupanID = item.CoupanID,
            TagID = item.TagID,
            qty = item.Quantity,
            MaxQuantity = item.MaxQuantity,
            MinQuantity = item.MinQuantity,
            StartRange = item.StartRange,
            EndRange = item.EndRange,
            SerialLength = item.SerialLength,
            ShortCode = item.ShortCode,
            ProductPrice = jsonDecoder.decodeFromString(item.ProductPrice ?: "{}"),
            CreatedAt = item.CreatedAt,
            UpdatedAt = item.UpdatedAt,
        )
    }

//    fun convert(item: Search): Inventory {
//        return Inventory(
//            Id = item._id,
//            InventoryID = item.InventoryID,
//            ProductID = item.ProductID,
//            UserID = item.UserID,
//            BusinessID = item.BusinessID,
//            CategoryID = item.CategoryID,
//            SubCategoryID = item.SubCategoryID,
//            IsDeleted = item.IsDeleted,
//            Name = item.Name,
//            Description = item.Description,
//            Model = item.Model,
//            Barcode = item.Barcode,
//            BrandID = item.BrandID,
//            UOMID = item.UOMID,
//            ColorID = item.ColorID,
//            SizeID = item.SizeID,
//            DiscountID = item.DiscountID,
//            CoupanID = item.CoupanID,
//            TagID = item.TagID,
//            qty = item.Quantity,
//            MaxQuantity = item.MaxQuantity,
//            MinQuantity = item.MinQuantity,
//            StartRange = item.StartRange,
//            EndRange = item.EndRange,
//            SerialLength = item.SerialLength,
//            ShortCode = item.ShortCode,
//            ProductPrice = jsonDecoder.decodeFromString(item.ProductPrice ?: "{}"),
//            CreatedAt = item.CreatedAt,
//            UpdatedAt = item.UpdatedAt,
//        )
//    }
}