package presentation.screens.business.inventory.sub_category.edit

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import com.friendly.shared.component.ErrorSuggestion
import common.asset.ImageAsset
import component.image.ImageView
import component.input.CustomTextField
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.productCategory.viewModel.ProductCategoryViewModel
import feature.productSubCategory.viewModel.ProductSubCategoryViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.inventory.category.component.SelectCategoryCard
import presentation.screens.business.inventory.category.select.SelectCategoryScreen
import theme.ThemeColor

class EditSubCategoryScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = ProductSubCategoryViewModel()
        val categoryViewModel = ProductCategoryViewModel()
        val selectedCategory = categoryViewModel.selectedCategory.collectAsState()
        val editSubCategory = navigationParam?.productSubCategory
        var name by remember {
            mutableStateOf(editSubCategory?.Name?.let { TextFieldValue(it) }
                ?: TextFieldValue(""))
        }
        LaunchedEffect(key1 = Unit) {
            if (selectedCategory.value == null) {
                editSubCategory?.CategoryID?.let { categoryViewModel.setSelectedCategory(it) }
            }
        }
        val nameErrorState = remember { mutableStateOf(false) }
        Column(
            modifier = Modifier.fillMaxSize()
                .background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(
                title = localization().subCategory.subCategoryUpdateTitle,
                navigationParam,
                onBackPress = {
                    navigator?.pop()
                })
            Box(modifier = Modifier.fillMaxWidth().height(200.dp)) {
                ImageView(editSubCategory?.Id ?: "", true)
            }
            Spacer(modifier = Modifier.height(50.dp))
            SelectCategoryCard(
                selectedCategory.value,
                selectedCategory.value
            ) {
                if (it != null) {
                    categoryViewModel.selectCategory(it)
                }
                navigator?.push(SelectCategoryScreen())
            }
            CustomTextField(
                value = editSubCategory?.Name ?: "",
                placeholder = localization().subCategory.subCategoryNamePlaceholder,
                trailingIcon = ImageAsset.person,
                label = localization().subCategory.subCategoryName,
                errorState = nameErrorState.value,
                keyboardType = KeyboardType.Email,
                visualTransformation = VisualTransformation.None
            ) { newEmail ->
                name = newEmail
            }

            Spacer(modifier = Modifier.height(10.dp))
            if (nameErrorState.value) {
                ErrorSuggestion("Please enter valid name.")
            }
            CustomDefaultBtn(shapeSize = 50f, btnText = localization().button.updateButton) {
                val nameErrorState = name.text.length < 4
                if (!nameErrorState) {
                    viewModel.update(
                        name.text,
                        editSubCategory!!.Id,
                        selectedCategory.value
                    ) {
                        AlertHandler.shared().showAlert(
                            "Success",
                            "Updated"
                        )
                        navigator?.pop()
                    }
                } else {
                    AlertHandler.shared().showAlert(
                        localization().subCategory.subCategoryUpdateAlertTitle,
                        localization().subCategory.subCategoryUpdateAlertNoSub
                    )
                }
            }
        }
    }
}