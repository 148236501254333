package feature.product.repository

import feature.product.model.Product
import feature.product.model.ProductBarCode
import feature.product.model.ProductStock
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class ProductRepository {
    val searchQueryState: MutableStateFlow<String> = MutableStateFlow("")
    val searchQuery: StateFlow<String>
        get() = searchQueryState
    val newProductState: MutableStateFlow<Product> = MutableStateFlow(Product())
    val newProduct: StateFlow<Product>
        get() = newProductState

    private val productFormIndexState: MutableStateFlow<Int> = MutableStateFlow(0)
    val productFormIndex: StateFlow<Int>
        get() = productFormIndexState


    val editProductState: MutableStateFlow<Product> = MutableStateFlow(Product())
    val editProduct: StateFlow<Product>
        get() = editProductState

    val allProductState: MutableStateFlow<List<Product>> = MutableStateFlow(arrayListOf())
    val allProduct: StateFlow<List<Product>>
        get() = allProductState

    val selectedProductState: MutableStateFlow<Product> = MutableStateFlow(Product())
    val selectedProduct: StateFlow<Product>
        get() = selectedProductState

    private val productStockLiveData: MutableStateFlow<ArrayList<ProductStock>> = MutableStateFlow(
        arrayListOf()
    )
    val productStock: StateFlow<ArrayList<ProductStock>>
        get() = productStockLiveData

    val productBarCodeLiveData: MutableStateFlow<ArrayList<ProductBarCode>> = MutableStateFlow(
        arrayListOf()
    )
    val productBarCode: StateFlow<ArrayList<ProductBarCode>>
        get() = productBarCodeLiveData

    fun setProductFormIndex(value: Int) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            productFormIndexState.emit(value)
        }

    }

    fun setEditProduct(value: Product?) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            if (value != null) {
                editProductState.emit(value)
            }
        }
    }

    fun setNewProduct(value: Product) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            newProductState.emit(value)
        }
    }

}