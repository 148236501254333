package presentation.screens.business.onboard.create_business_screen

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import common.asset.ImageAsset
import component.address.AddressLocationInput
import component.button.DefaultButton
import component.input.MobileTextField
import component.input.TextInputField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.address.model.Address
import feature.address.viewModel.AddressViewModel
import feature.auth.handler.AuthHandler
import feature.business.viewModel.BusinessViewModel
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.onboard.component.BusinessTypeHCard
import theme.ThemeColor

class CreateBusinessScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val viewModel = BusinessViewModel()
        val currentStep = remember { mutableStateOf(0) }
        val newBusiness = viewModel.createBusiness.collectAsState()
        val navigator = LocalNavigator.current
        fun onClickSave() {
            viewModel.createNewBusiness(navigationParam?.businessType) {
                navigator?.pop()
                navigator?.pop()
            }
        }
        LaunchedEffect(key1 = screenName) {
            viewModel.setMobileForNewBusiness(AuthHandler.shared().mobile)
            viewModel.setEmailForNewBusiness(AuthHandler.shared().email)
            newBusiness.value.Address = Address()
            newBusiness.value.Address?.let { AddressViewModel().setNewAddress(it) }
        }
        val mobileErrorState = remember {
            mutableStateOf(false)
        }
        Column(
            modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)
                .padding(bottom = 60.dp)
        ) {
            TopNavBar(title = "Create Store", null, onBackPress = {
                if (currentStep.value == 0) {
                    navigator?.pop()
                } else {
                    currentStep.value -= 1
                }
            })
            AppLoader(viewModel)
            LazyColumn(modifier = Modifier.weight(1f)) {
                item {
                    BusinessTypeHCard(navigationParam?.businessType) {
                        navigationParam?.businessType = it
                        AppNavigator.shared().pop()
                    }
                }
                item {
                    Column(
                        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(8.dp)
                    ) {
                        if (newBusiness.value.Name != null) {
                            TextInputField(
                                "",
                                "Name Of the Business/Store",
                                value = viewModel.createBusiness.value.Name!!
                            ) {
                                viewModel.setNameForNewBusiness(it)
                            }
                        } else {
                            TextInputField("", localization().businessList.nameOfTheBusiness) {
                                viewModel.setNameForNewBusiness(it)
                            }
                        }
                        TextInputField(
                            "",
                            "Email ID",
                            value = newBusiness.value.EmailID ?: AuthHandler.shared().email
                        ) {
                            viewModel.setEmailForNewBusiness(it)
                        }

                        Spacer(modifier = Modifier.height(8.dp))
                        MobileTextField(
                            mobileNumber = newBusiness.value.mobileNumber
                                ?: AuthHandler.shared().mobile,
                            placeholder = "9000000000",
                            trailingIcon = ImageAsset.phone,
                            label = localization().businessList.mobileNumber,
                            errorState = mobileErrorState,
                            visualTransformation = VisualTransformation.None,
                            onChanged = { mob ->
                                viewModel.setMobileForNewBusiness(mob.text.trim())
                            },
                            onDialCodeChange = {
                                viewModel.setDialCodeForNewBusiness(it.countryPhoneCode)
                            }
                        )
                        Spacer(modifier = Modifier.height(8.dp))

                    }
                }
                item {
                    Column(
                        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(8.dp)
                            .padding(bottom = 100.dp)
                    ) {
                        AddressLocationInput {
                            viewModel.setLocationForNewBusinessAddress(it)
                        }
                        TextInputField(
                            "",
                            localization().businessList.enterPinCode,
                            type = KeyboardType.Number
                        ) {
                            try {
                                viewModel.createBusiness.value.Address?.ZipCode = it.toLong()
                            } catch (_: Throwable) {

                            }
                        }
                        TextInputField("", localization().businessList.enterCity) {
                            viewModel.createBusiness.value.Address?.City = it
                        }

                        TextInputField("", localization().businessList.state) {
                            viewModel.createBusiness.value.Address?.State = it
                        }
                        TextInputField("", localization().businessList.localityAreaStreet) {
                            viewModel.createBusiness.value.Address?.Area = it
                        }
                        TextInputField("", localization().businessList.flatnoBuildingName) {
                            viewModel.createBusiness.value.Address?.House = it
                        }
                        TextInputField("", localization().businessList.landmark) {
                            viewModel.createBusiness.value.Address?.LandMark = it
                        }
                    }
                }
            }
            DefaultButton(
                localization().button.saveButton,
                onClick = {
                    onClickSave()
                },
                modifier = null
            )
        }
    }
}