package feature.notification.repository

import feature.notification.model.Notification
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class NotificationRepository {

    val notification: Any
        get() {
            TODO()
        }
    val liveData: MutableStateFlow<List<Notification>> = MutableStateFlow(arrayListOf())
    val allLiveData: StateFlow<List<Notification>>
        get() = liveData

    val selectedLiveData: MutableStateFlow<Notification> =
        MutableStateFlow(Notification())
    val selected: StateFlow<Notification>
        get() = selectedLiveData

    val mediaFileLiveData: MutableStateFlow<Notification> =
        MutableStateFlow(Notification())

    val offerBannerLiveData: MutableStateFlow<List<Notification>> =
        MutableStateFlow(arrayListOf())
    val mediaFiles: StateFlow<List<Notification>>
        get() = offerBannerLiveData

}