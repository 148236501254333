package presentation.screens.customer.store.payment.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.ImageView
import feature.business.model.Business
import feature.business.utill.AddressUtill
import feature.cart.viewModel.CartViewModel
import theme.ThemeColor

@Composable
fun ShopCard(business: Business?, viewModel: CartViewModel) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .height(120.dp)
            .padding(8.dp)
            .background(ThemeColor.shared().White, shape = RoundedCornerShape(10.dp)),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().White,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().White
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
        ) {
            Box(
                modifier = Modifier.padding(8.dp).width(76.dp).height(70.dp)
                    .background(color = Color.Black, shape = RoundedCornerShape(8.dp))
            ) {
                ImageView(business?.Id)
            }
            Column(
                modifier = Modifier
                    .padding(top = 4.dp)
            ) {
                Text(business?.Name ?: "", fontSize = 16.sp)
                Text(
                    AddressUtill().getAddressToDisplayInSingleLine(business?.Address),
                    fontSize = 16.sp,
                    color = Color.Gray
                )
                Text(
                    "Total - ${viewModel.cartItems.value.first().price.getCurrencySymbol()} ${viewModel.totalAmount.value}",
                    fontSize = 14.sp,
                    color = ThemeColor.shared().PrimaryColor
                )
            }
        }
//        Box(
//            modifier = Modifier
//                .width(361.dp)
//                .height(27.dp)
//                .padding(start = 8.dp)
//                .background(
//                    color = ThemeColor.shared().defaultBakground,
//                    shape = RoundedCornerShape(4.dp)
//                )
//        ) {
//            Text("Friday, 24 May 2024 at 10:00 am", fontSize = 16.sp, color = Color.Gray)
//        }
    }
}