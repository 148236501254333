package feature.order.model

import feature.cart.model.CartItem
import feature.order.status.OrderStatus
import feature.product.model.ProductPrice
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Order(
    @SerialName("_id") var _id: String = "",
    @SerialName("OrderID") var orderId: Long? = null,
    @SerialName("OrderNumber") var orderNumber: Long? = null,
    @SerialName("InvoiceNumber") var invoiceNumber: Long? = null,
    @SerialName("ShareLink") var ShareLink: String? = null,
    @SerialName("UserID") var userId: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("SelectedBusinessID") var selectedBusinessID: String? = null,
    @SerialName("AddressID") var addressID: String? = null,
    @SerialName("OrderType") var orderType: String? = null,
    @SerialName("DeviceID") var deviceId: String? = null,
    @SerialName("OrderItems") var orderItems: ArrayList<CartItem> = arrayListOf(),
    @SerialName("Pricing") var pricing: ProductPrice? = null,
    @SerialName("PendingAmount") var pendingAmount: Float? = null,
    @SerialName("ClearedAmount") var clearedAmount: Float? = null,
    @SerialName("InstantDiscount") var instantDiscount: Double? = null,
    @SerialName("Coupon") var coupon: Double? = null,
    @SerialName("Status") var status: OrderStatus? = null,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
)