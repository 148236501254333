package feature.device.model

import feature.address.model.LocationCoordinate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Device(
    @SerialName("UniqueDeviceID") var UniqueDeviceID: Long = 0,
    @SerialName("_id") var _id: String? = null,
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("BusinessID") var BusinessID: String? = null,
    @SerialName("AppType") var AppType: String? = null,
    @SerialName("DeviceID") var DeviceID: String? = null,
    @SerialName("Platform") var Platform: String? = null,
    @SerialName("OperatingSystem") var OperatingSystem: String? = null,
    @SerialName("OperatingSystemVersion") var OperatingSystemVersion: String? = null,
    @SerialName("Country") var Country: String? = null,
    @SerialName("Location") var Location: LocationCoordinate? = null,
    @SerialName("BundleID") var BundleID: String? = null,
    @SerialName("Brand") var Brand: String? = null,
    @SerialName("Model") var Model: String? = null,
    @SerialName("FCMToken") var FCMToken: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("LastSeen") var LastSeen: String? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)