package feature.customer.network

import feature.customer.model.Customer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FindCustomerResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: Customer?,
)

@Serializable
data class FetchCustomerResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("LastSyncDate") val lastSyncDate: String?,
    @SerialName("Payload") val payload: ArrayList<Customer>,
)

@Serializable
data class CreateCustomerResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: Customer?,
)

@Serializable
data class UpdateCustomerResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Event") val event: String,
    @SerialName("Payload") val payload: Customer?,
)