package feature.invoice.network

import common.constants.Server
import feature.invocie.event.InvoiceEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient

class InvoiceNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    suspend fun retrieve(
        request: RetrieveInvoiceRequest,
        onResult: (RetrieveInvoiceResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(InvoiceEvent.RETRIEVE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }

    suspend fun retrieveSingle(
        request: RetrieveSingleInvoiceRequest,
        onResult: (RetrieveSingleInvoiceResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(InvoiceEvent.RETRIVE_SINGLE_INVOICE.value)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }
}