package feature.product.network

import feature.product.model.ProductPrice
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveProductRequest(
    @SerialName("BusinessID") val businessID: String,
    @SerialName("LastSyncDate") val lastSycDate: String?,
)

@Serializable
data class  CreateProductRequest(
    @SerialName("ProductID") var productID: Long? = null,
    @SerialName("ProductType") var productType: String = "Product",
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("CategoryID") var categoryID: String? = null,
    @SerialName("SubCategoryID") var subCategoryID: String? = null,
    @SerialName("IsDeleted") var isDeleted: Boolean? = null,
    @SerialName("Name") var name: String = "",
    @SerialName("Description") var description: String = "",
    @SerialName("ManageInventory") var manageInventory: Boolean? = null,
    @SerialName("ProductPrice") var productPrice: ProductPrice? = ProductPrice(),
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)

@Serializable
data class UpdateProductRequest(
    @SerialName("_id") var _id: String? = null,
    @SerialName("ProductID") var productID: Long? = null,
    @SerialName("ProductType") var productType: String = "Product",
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("CategoryID") var categoryID: String? = null,
    @SerialName("SubCategoryID") var subCategoryID: String? = null,
    @SerialName("IsDeleted") var isDeleted: Boolean = false,
    @SerialName("Name") var name: String = "",
    @SerialName("Description") var description: String = "",
    @SerialName("ManageInventory") var manageInventory: Boolean? = null,
    @SerialName("ProductPrice") var productPrice: ProductPrice? = ProductPrice(),
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)

@Serializable
data class DeleteProductRequest(
    @SerialName("_id") var _id: String? = null
)