package feature.payment.repository

import feature.payment.model.Payment
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class PaymentRepository {
    val payment: MutableStateFlow<Payment> = MutableStateFlow<Payment>(Payment())
    val allMutablePayment: MutableStateFlow<List<Payment>> =
        MutableStateFlow(ArrayList())
    val allPayment: StateFlow<List<Payment>>
        get() = allMutablePayment

}