package navigation

import androidx.compose.ui.unit.dp
import appConfig.AppConfig
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.Navigator
import common.constants.UserDefaults
import feature.alert.handler.AlertHandler
import feature.app.NavigationParam
import feature.business.viewModel.BusinessViewModel
import feature.payment.model.Payment
import feature.payment.viewModel.PaymentViewModel
import graph.AppType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.connection.BusinessConnectionListScreen
import presentation.screens.business.customer.CustomerListScreen
import presentation.screens.business.customer.create.CreateCustomerScreen
import presentation.screens.business.customer.detail.CustomerDetailScreen
import presentation.screens.business.employee.EmployeeListScreen
import presentation.screens.business.employee.detail.EmployeeDetailScreen
import presentation.screens.business.home.BusinessHomeScreen
import presentation.screens.business.inventory.category.detail.CategoryDetailScreen
import presentation.screens.business.inventory.category.edit.EditCategoryScreen
import presentation.screens.business.inventory.category.list.CategoryListScreen
import presentation.screens.business.inventory.product.BusinessInventoryProductList
import presentation.screens.business.inventory.product.create.CreateProductScreen
import presentation.screens.business.inventory.product.detail.ProductDetailScreen
import presentation.screens.business.inventory.product.edit.EditProductScreen
import presentation.screens.business.inventory.stock.create.CreateStockScreen
import presentation.screens.business.inventory.stock.edit.UpdateStockScreen
import presentation.screens.business.inventory.stock.list.InventoryListScreen
import presentation.screens.business.inventory.stock.product_list.StockProductListScreen
import presentation.screens.business.inventory.sub_category.detail.SubCategoryDetailScreen
import presentation.screens.business.inventory.sub_category.edit.EditSubCategoryScreen
import presentation.screens.business.inventory.sub_category.list.SubCategoryListScreen
import presentation.screens.business.invoice.detail.BusinessInvoiceDetailScreen
import presentation.screens.business.invoice.list.BusinessInvoiceListScreen
import presentation.screens.business.onboard.create_business_screen.CreateBusinessScreen
import presentation.screens.business.onboard.select_business_type.SelectBusinessTypeScreen
import presentation.screens.business.sale.BusinessSaleScreen
import presentation.screens.business.sale.cart.MyBusinessCart
import presentation.screens.business.self_business.detail.SelfBusinessDetailScreen
import presentation.screens.business.self_business.edit.SelfBusinessEditScreen
import presentation.screens.business.self_business.list.SelfBusinessListScreen
import presentation.screens.business.self_business.select.SelfBusinessSelectScreen
import presentation.screens.business.store.MyBusinessStoreScreen
import presentation.screens.business.store.accoun.MyBusinessAccountsScreens
import presentation.screens.business.store.currency.MyBusinessStoreCurrency
import presentation.screens.common.account.AccountScreen
import presentation.screens.common.account.delete.DeleteAccountScreen
import presentation.screens.common.account.selectLanguageScreen.SelectLanguageScreen
import presentation.screens.common.address.create.CreateAddressScreen
import presentation.screens.common.address.edit.EditAddressScreen
import presentation.screens.common.address.list.AddressListScreen
import presentation.screens.common.auth.LoginScreen
import presentation.screens.common.auth.OTPScreen
import presentation.screens.common.auth.SetMPinScreen
import presentation.screens.common.auth.SignUpScreen
import presentation.screens.common.auth.VerifyMPinScreen
import presentation.screens.common.notification.BusinessNotificationScreen
import presentation.screens.common.policy.PrivacyPolicyScreen
import presentation.screens.common.scanner.ScannerScreen
import presentation.screens.customer.order.details.OrderDetailScreen
import presentation.screens.customer.order.list.OrderListScreen
import presentation.screens.customer.store.StoreDetailScreen
import presentation.screens.customer.store.cart.ShoppingCart
import presentation.screens.customer.store.nearby.NearbyStoresScreen
import presentation.screens.customer.store.payment.PaymentDetailScreen
import presentation.screens.customer.store.shopbycategory.ShopByCategoryScreen
import presentation.screens.payment.pay.PaymentPayScreen
import theme.ThemeColor

class AppNavigator {
    val scope = CoroutineScope(Job() + Dispatchers.Default)
    var onWindowLocationChange: ((String) -> Unit)? = null
    var openRazorpay: ((Payment) -> Unit)? = null
    private var openLink: ((String) -> Unit)? = null
    var currentScreen: AppScreen? = null

    private val currentUrl: String
        get() {
            return UserDefaults.retrieve("current_url")
        }
    private val currentScreenName: String
        get() {
            return UserDefaults.retrieve("current_screen")
        }

    private var redirectNavigations = arrayListOf("privacy_policy", "delete_account")
    var navigator: Navigator? = null
    var screenWidth = 400.dp
    var screenHeight = 600.dp
    val navigationParam: String
        get() {
            return UserDefaults.retrieve("navigation_param")
        }


    fun updateNavigator(nav: Navigator?) {
        if (nav != null) {
            navigator = nav
        }
    }

    init {
        instance = this
    }

    companion object {
        private var instance: AppNavigator? = null
        fun shared(): AppNavigator {
            return if (instance != null) {
                instance as AppNavigator
            } else {
                instance = AppNavigator()
                instance as AppNavigator
            }
        }
    }

    fun checkDeployedDomainForWeb(url: String) {
        if (url.isNotEmpty() && url.contains("business.mandee.ai")) {
            AppConfig.shared().appType = AppType.BUSINESS
            ThemeColor.shared().PrimaryColor = ThemeColor.shared().businessTheme
        } else if (url.isNotEmpty() && url.contains("mandee.ai")) {
            AppConfig.shared().appType = AppType.CUSTOMER
            ThemeColor.shared().PrimaryColor = ThemeColor.shared().customerTheme
        } else if (url.isNotEmpty() && url.contains("localhost")) {
            AppConfig.shared().appType = AppType.BUSINESS
            ThemeColor.shared().PrimaryColor = ThemeColor.shared().businessTheme
        } else {
            AppConfig.shared().appType = AppType.E_COMMERCE
            ThemeColor.shared().PrimaryColor = ThemeColor.shared().customerTheme
        }
    }

    private fun openInvoice(id: String) {
        CoroutineScope(Job() + Dispatchers.Main).launch {
            delay(300)
            currentScreen?.navigateTo(ScreenName.INVOICES, NavigationParam(id))
        }
    }

    private fun openStore(number: Long) {
        scope.launch {
            BusinessViewModel().getBusinessByNumber(number) { bus ->
                val screen = StoreDetailScreen(ScreenName.STORE_DETAILS_SCREEN)
                screen.navigationParam = NavigationParam(business = bus, selectedBusiness = bus)
                shared().navigator?.push(screen)
            }
        }
    }

    private fun openStore(id: String) {
        scope.launch {
            BusinessViewModel().getBusinessById(id) { bus ->
                val screen = StoreDetailScreen(ScreenName.STORE_DETAILS_SCREEN)
                screen.navigationParam = NavigationParam(business = bus, selectedBusiness = bus)
                shared().navigator?.push(screen)
            }
        }
    }

    fun openPaymentPage(id: String) {
        if (id.isEmpty()) {
            scope.launch {
                delay(200)
                AlertHandler.shared().showAlert("Oops!", "Payment details are missing")
            }
        } else {
            val viewModel = PaymentViewModel()
            viewModel.find(id) { payment: Payment? ->
                if (payment != null) {
                    openRazorpay!!(payment)
                }
            }
        }
    }

    fun handleLink(url: String) {
        val splitLink = url.split("/")
        var lastComponent = splitLink.last()
        var value = ""
        if (lastComponent.contains("?")) {
            val params = lastComponent.split("?")
            lastComponent = params.first()
            if (params.last().contains("=")) {
                val keyvals = params.last().split("=")
                if (keyvals.first().isNotEmpty()) {
                    UserDefaults.store("navigation_param", keyvals.first())
                    UserDefaults.store("navigation_param_value", keyvals.last())
                }
                if (keyvals.last().isNotBlank()) {
                    value = keyvals.last()
                }
            }
        }
        scope.launch {
            delay(200)
            if (lastComponent.contains("invoices") && value.isNotEmpty()) {
                openInvoice(value)
            }
            if ((lastComponent.contains("stores")) && value.isNotEmpty()) {
                try {
                    openStore(value.toLong())
                } catch (e: Exception) {
                    openStore(value)
                }
            }
            if (lastComponent == "privacy_policy") {
                shared().navigateTo(ScreenName.PRIVACY_POLICY)
            }
            if (lastComponent == "delete_account") {
                shared().navigateTo(ScreenName.DELETE_ACCOUNT)
            }
            if (lastComponent == "payment_pay") {
                openPaymentPage(id = value)
            }
        }
    }

    fun pop() {
        if (onWindowLocationChange != null && !currentUrl.contains("localhost") && redirectNavigations.contains(
                currentScreenName
            )
        ) {
            onWindowLocationChange!!("")
        } else {
            navigator?.pop()
        }
    }

    fun popAll() {
        if (onWindowLocationChange != null && !currentUrl.contains("localhost") && redirectNavigations.contains(
                currentScreenName
            )
        ) {
            onWindowLocationChange!!("")
        } else {
            navigator?.popAll()
        }
    }

    fun openLink(link: String) {
        if (openLink != null) {
            scope.launch {
                openLink(link)
            }
        }
    }

    fun navigateTo(screen: ScreenName?, param: NavigationParam? = null) {
        if (screen == null) {
            return
        }
        when (screen) {
            ScreenName.BUSINESS_HOMEPAGE -> {
                navigator?.popAll()
            }

            ScreenName.BUSINESS_SALE -> {
                shared().navigateToScreen(
                    BusinessSaleScreen(ScreenName.BUSINESS_SALE)
                )
            }

            ScreenName.BUSINESS_INVOICE_LIST -> {
                shared().navigateToScreen(
                    BusinessInvoiceListScreen()
                )
            }

            ScreenName.PRIVACY_POLICY -> {
                shared().navigator?.push(PrivacyPolicyScreen())
            }

            ScreenName.DELETE_ACCOUNT -> {
                shared().navigator?.push(DeleteAccountScreen())
            }

            ScreenName.BUSINESS_INVENTORY_PRODUCT -> {
                shared().navigateToScreen(
                    BusinessInventoryProductList(ScreenName.BUSINESS_INVENTORY_PRODUCT)
                )
            }

            ScreenName.BUSINESS_INVENTORY_STOCK -> {
                shared().navigateToScreen(
                    StockProductListScreen(ScreenName.BUSINESS_INVENTORY_STOCK)
                )
            }


            ScreenName.BUSINESS_INVENTORY_EDIT_PRODUCT -> {
                shared().navigateToScreen(
                    EditProductScreen()
                )
            }

            ScreenName.BUSINESS_INVENTORY_CATEGORY -> {
                shared().navigateToScreen(
                    CategoryListScreen()
                )
            }

            ScreenName.BUSINESS_INVENTORY_CATEGORY_DETAILS -> {
                shared().navigateToScreen(
                    CategoryDetailScreen()
                )
            }

            ScreenName.BUSINESS_INVENTORY_EDIT_CATEGORY -> {
                shared().navigateToScreen(
                    EditCategoryScreen(ScreenName.BUSINESS_INVENTORY_EDIT_CATEGORY)
                )
            }


            ScreenName.BUSINESS_INVENTORY_SUB_CATEGORY -> {
                shared().navigateToScreen(
                    SubCategoryListScreen(ScreenName.BUSINESS_INVENTORY_SUB_CATEGORY)
                )
            }

            ScreenName.MY_BUSINESS_STORE -> {
                shared().navigateToScreen(
                    MyBusinessStoreScreen(ScreenName.MY_BUSINESS_STORE)
                )
            }

            ScreenName.MY_BUSINESS_PROFILE -> {
                shared().navigateToScreen(
                    SelfBusinessDetailScreen(ScreenName.MY_BUSINESS_PROFILE)
                )
            }

            ScreenName.OTHER_BUSINESS_PROFILE -> {
//                shared().navigateToScreen(SelfBusinessDetailScreen())
            }

            ScreenName.STORE_DETAILS_SCREEN -> {
                shared().navigateToScreen(StoreDetailScreen(ScreenName.STORE_DETAILS_SCREEN))
            }

            ScreenName.SELECT_BUSINESS -> {
                shared().navigateToScreen(
                    SelfBusinessSelectScreen(ScreenName.SELECT_BUSINESS)
                )
            }

            ScreenName.BUSINESS_DASHBOARD -> {}
            ScreenName.BUSINESS_EMPLOYEE -> {
                shared().navigateToScreen(
                    EmployeeListScreen(ScreenName.BUSINESS_EMPLOYEE)
                )
            }

            ScreenName.BUSINESS_CUSTOMERS -> {
                shared().navigateToScreen(
                    CustomerListScreen(ScreenName.BUSINESS_CUSTOMERS)
                )
            }

            ScreenName.SELF_BUSINESS_LIST -> {
                shared().navigateToScreen(
                    SelfBusinessListScreen(ScreenName.SELF_BUSINESS_LIST)
                )
            }

            ScreenName.INVENTORY_PRODUCT -> {
                shared().navigateToScreen(
                    BusinessInventoryProductList(ScreenName.INVENTORY_PRODUCT)
                )
            }

            ScreenName.LOGIN -> {
                shared().navigateToScreen(
                    LoginScreen()
                )
            }

            ScreenName.SIGN_UP -> {
                shared().navigateToScreen(
                    SignUpScreen()
                )
            }

            ScreenName.SET_M_PIN -> {
                shared().navigateToScreen(SetMPinScreen())
            }

            ScreenName.VERIFY_M_PIN -> {
                shared().navigateToScreen(VerifyMPinScreen(ScreenName.VERIFY_M_PIN))
            }

            ScreenName.OTP -> {
                shared().navigateToScreen(OTPScreen())
            }

            ScreenName.BUSINESS_NOTIFICATION -> {
                shared().navigateToScreen(BusinessNotificationScreen())
            }

            ScreenName.SELECT_BUSINESS_TYPE -> {
                shared().navigateToScreen(SelectBusinessTypeScreen(ScreenName.SELECT_BUSINESS_TYPE))
            }

            ScreenName.CREATE_BUSINESS -> {
                shared().navigateToScreen(CreateBusinessScreen(ScreenName.CREATE_BUSINESS))
            }

            ScreenName.MY_BUSINESS_CART -> {
                shared().navigateToScreen(MyBusinessCart(ScreenName.MY_BUSINESS_CART))
            }

            ScreenName.INVOICES -> {
                shared().navigateToScreen(BusinessInvoiceDetailScreen(ScreenName.INVOICES))
            }

            ScreenName.CREATE_PRODUCT -> {
                shared().navigateToScreen(CreateProductScreen())
            }

            ScreenName.PRODUCT_DETAILS -> {
                shared().navigateToScreen(ProductDetailScreen())
            }

            ScreenName.CREATE_CUSTOMER -> {
                shared().navigateToScreen(CreateCustomerScreen())
            }

            ScreenName.CUSTOMER_DETAILS -> {
                shared().navigateToScreen(CustomerDetailScreen(ScreenName.CUSTOMER_DETAILS))
            }

            ScreenName.CREATE_ADDRESS -> {
                shared().navigateToScreen(CreateAddressScreen(ScreenName.CREATE_ADDRESS))
            }

            ScreenName.ADDRESS_LIST -> {
                shared().navigateToScreen(AddressListScreen(ScreenName.ADDRESS_LIST))
            }

            ScreenName.SELECT_LANGUAGE -> {
                shared().navigateToScreen(SelectLanguageScreen())
            }

            ScreenName.BUSINESS_DETAILS_CONNECTION -> {
                shared().navigateToScreen(BusinessConnectionListScreen())
            }

            ScreenName.MY_BUSINESS_CURRENCY -> {
                shared().navigateToScreen(MyBusinessStoreCurrency())
            }

            ScreenName.MY_BUSINESS_BANK_ACCOUNT -> {
                shared().navigateToScreen(MyBusinessAccountsScreens(ScreenName.MY_BUSINESS_BANK_ACCOUNT))
            }

            ScreenName.MY_BUSINESS_BANNER -> TODO()
            ScreenName.ADVERTISE_TO_CUSTOMER -> TODO()
            ScreenName.MY_BUSINESS_APP -> TODO()
            ScreenName.MY_BUSINESS_APP_DASHBOARD -> TODO()
            ScreenName.MY_BUSINESS_FINANCE -> TODO()
            ScreenName.MY_BUSINESS_MARKETING -> TODO()
            ScreenName.OTHER_BUSINESS_DETAILS -> TODO()
            ScreenName.EMPLOYEE_DETAIL_SCREEN -> {
                shared().navigateToScreen(EmployeeDetailScreen(ScreenName.EMPLOYEE_DETAIL_SCREEN))
            }

            ScreenName.ACCOUNT_SCREEN -> {
                AccountScreen(ScreenName.ACCOUNT_SCREEN)
            }

            ScreenName.CUSTOMER_HOME -> TODO()
            ScreenName.CUSTOMER_INVOICES -> TODO()
            ScreenName.CUSTOMER_ORDERS -> TODO()
            ScreenName.BUSINESS_INVENTORY_SUB_CATEGORY_DETAILS -> {
                shared().navigateToScreen(SubCategoryDetailScreen(ScreenName.BUSINESS_INVENTORY_SUB_CATEGORY_DETAILS))
            }

            ScreenName.BUSINESS_INVENTORY_SUB_CATEGORY_EDIT -> {
                shared().navigateToScreen(EditSubCategoryScreen(ScreenName.BUSINESS_INVENTORY_SUB_CATEGORY_EDIT))
            }

            ScreenName.SHOPPING_CART -> {
                shared().navigateToScreen(ShoppingCart(ScreenName.SHOPPING_CART))
            }

            ScreenName.BUSINESS_INVENTORY_LIST -> {
                shared().navigateToScreen(InventoryListScreen(ScreenName.BUSINESS_INVENTORY_LIST))
            }

            ScreenName.BUSINESS_INVENTORY_STOCK_UPDATE -> {
                shared().navigateToScreen(UpdateStockScreen(ScreenName.BUSINESS_INVENTORY_STOCK_UPDATE))
            }

            ScreenName.BUSINESS_INVENTORY_STOCK_CREATE -> {
                shared().navigateToScreen(CreateStockScreen(ScreenName.BUSINESS_INVENTORY_STOCK_CREATE))
            }

            ScreenName.ORDER_PAYMENT -> {
                shared().navigateToScreen(PaymentDetailScreen(ScreenName.ORDER_PAYMENT))
            }

            ScreenName.ORDER_LIST -> {
                shared().navigateToScreen(OrderListScreen(ScreenName.ORDER_LIST))
            }

            ScreenName.ORDER_DETAIL -> {
                shared().navigateToScreen(OrderDetailScreen(ScreenName.ORDER_DETAIL))
            }

            ScreenName.EDIT_ADDRESS -> {
                shared().navigateToScreen(EditAddressScreen(ScreenName.EDIT_ADDRESS))
            }

            ScreenName.SHOP_BY_CATEGORY -> {
                shared().navigateToScreen(ShopByCategoryScreen(ScreenName.SHOP_BY_CATEGORY))
            }

            ScreenName.MY_BUSINESS_EDIT -> {
                shared().navigateToScreen(SelfBusinessEditScreen(ScreenName.MY_BUSINESS_EDIT))
            }

            ScreenName.NEAR_BY_STORES -> {
                shared().navigateToScreen(NearbyStoresScreen(ScreenName.NEAR_BY_STORES))
            }

            ScreenName.BUSINESS_HOME -> {
                shared().navigateToScreen(BusinessHomeScreen(ScreenName.BUSINESS_HOME))
            }

            ScreenName.SCANNER_SCREEN -> {
                shared().navigateToScreen(ScannerScreen())
            }

            ScreenName.PAYMENT_PAY_SCREEN -> {
                shared().navigateToScreen(PaymentPayScreen())
            }
        }
    }

    private fun navigateToScreen(screen: Screen) {
        navigator?.push(screen)
    }

}