package feature.notification.network

import common.constants.Server
import feature.notification.event.NotificationEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class NotificationNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    fun retrieve(
        request: NotificationRetrieveRequest,
        onResult: (NotificationRetrieveResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(NotificationEvent.RETRIEVE_NOTIFICATION)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }

    fun retrieveForBusiness(
        request: NotificationRetrieveRequest,
        onResult: (NotificationRetrieveResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute =
                Server.getRestApiEndPointFor(NotificationEvent.RETRIEVE_BUSINESS_NOTIFICATION)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }

    fun acceptConnectionRequest(
        request: AcceptConnectionRequest,
        onResult: (AcceptConnectionResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute =
                Server.getRestApiEndPointFor(NotificationEvent.ACCEPT_CONNECTION_REQUEST)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }
}
