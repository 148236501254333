package presentation.screens.business.onboard.select_business_type

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.businessType.viewModel.BusinessTypeViewModel
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.onboard.component.BusinessTypeCard
import theme.ThemeColor

class SelectBusinessTypeScreen(override val screenName: ScreenName) : AppScreen {


    @Composable
    override fun Content() {
        val viewModel = BusinessTypeViewModel()
        val businessTypes = viewModel.allBusinessType.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.retrieve()
        }
        Column(
            modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)

        ) {
            TopNavBar(
                title = localization().businessList.selectBusiness,
                null,
                onBackPress = {
                    AppNavigator.shared().pop()
                })
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 180.dp),
                modifier = Modifier.weight(1f).padding(
                    start = 8.dp,
                    end = 8.dp,
                    bottom = if (businessTypes.value.isNotEmpty()) 60.dp else (60.dp)
                ),
            ) {
                items(businessTypes.value.count()) { index ->
                    BusinessTypeCard(businessType = businessTypes.value[index], onClick = {
                        navigateTo(
                            ScreenName.CREATE_BUSINESS,
                            NavigationParam(businessType = it)
                        )
                    })
                }
            }
        }
    }
}