package common.asset

class ImageAsset {
    companion object {
        const val searchIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Fsearch_icon.png?alt=media&token=7704bc1a-698a-4e1a-ba37-255eb5001124"//"search_icon.png"
        const val cartIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Fcart_icon.png?alt=media&token=ba05008c-672f-4852-97f2-ac0c755e245e"//"cart_icon.png"
        const val shopIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Fshop_icon.png?alt=media&token=99f73e16-0f00-48c8-a028-c5ef41defe3f"//"shop_icon.png"
        const val shoppingBagIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Fshop_icon.png?alt=media&token=99f73e16-0f00-48c8-a028-c5ef41defe3f"//"shopping_bag.png"
        const val receiptIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Freceipt_icon.png?alt=media&token=484c4c66-6f3f-41cc-9e6f-04e31d9903ac"//"receipt_icon.png"
        const val userIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Fprofile.png?alt=media&token=ebe3c272-56a0-4e69-9e3c-4201eb28f35d"//"profile.png"
        const val logoutIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Flog_out.png?alt=media&token=7a8154b9-2a52-452c-b55a-cdfdc0d99839"//"log_out.png"
        const val arrowRightIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Farrow_right.png?alt=media&token=f69599a3-8406-4b95-b96b-e7cde0a75b0a"//"arrow_right.png"
        const val arrowLeftIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Farrow_left.png?alt=media&token=81a971cb-cc1d-4ee5-a2eb-6cd66d46284d"//"arrow_left.png"
        const val phone =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Fphone.png?alt=media&token=ca389bca-28f0-43f3-9baa-aa0be319a2bc"//"phone.png"
        const val lock =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Flock.png?alt=media&token=67d6a567-86a4-4dfe-acef-82c2d95adb6e"//"lock.png"
        const val person =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Fperson.png?alt=media&token=c91d8660-69c2-428a-b62e-a02d0b6e56fe"//"person.png"
        const val shoppingCart =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/remote_icons%2Fshopping_cart.png?alt=media&token=a6ab826a-bfd3-4a11-9009-69b036bcbf45"//"shopping_cart.png"
        const val saleIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fchart-square.png?alt=media&token=17c08adf-8131-4c2d-890a-1d75a47b67c4"
        const val productMenuIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fproduct.png?alt=media&token=e16c58f1-406a-4c64-ac4c-0678789344e8"
        const val categoryMenuIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fcategory.png?alt=media&token=74ace1d3-236e-4d79-ae0c-4db1ac8ae486"
        const val subCategoryIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fsub_category.png?alt=media&token=6e6080d5-6f7b-4471-bcda-77f36ea46b76"
        const val employeeIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Femployee.png?alt=media&token=6f3a167c-7027-44ab-85ef-8ac063e444a3"
        const val customerIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fcustomer.png?alt=media&token=2644373f-dcb6-4305-8ca3-0c013871a436"
        const val businessIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fbriefcase.png?alt=media&token=1f6e4a1e-e309-464c-8578-9abeba19e9d2"
        const val dashboardIcon =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fdashboard.png?alt=media&token=bbf56675-0eaf-443b-b8c2-8556ac0c347b"
        const val inventory =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fshop.png?alt=media&token=c7e01d98-de93-4f4d-aad1-c1cd0992b6bb"
        const val businessCard1 =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fbusiness_card1.png?alt=media&token=a2e98098-fdb7-4bd0-8a84-4dfc766ca723"
        const val businessCard2 =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fbusiness_card2.png?alt=media&token=131ed36e-8b2c-416e-8860-94ecbd2ab204"
        const val businessCard3 =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fbusiness_card3.png?alt=media&token=184221da-0514-4fb3-9820-a86e598fff13"
        const val businessCard4 =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/icons%2Fbusiness_card4.png?alt=media&token=f3bf2dc8-bd90-4198-a72a-25af06c33b31"
        const val rupeeIcon =
            "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"

        val allBusinessCarBackground: Array<String> =
            arrayOf(businessCard1, businessCard2, businessCard3, businessCard4)

        const val screenshot1 =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/playstore%2Fhome_banner_image.png?alt=media&token=c05b764d-d11b-40e9-88a2-c894837da7e0"


        const val macosAppLink =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/apps%2FMandee.dmg.zip?alt=media&token=f97cf480-ae51-45e6-a34d-7178419f7445"

        const val appStoreButton =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/apps%2Fapp-store.png?alt=media&token=ef73508e-8edf-4337-87e3-6bedb153ca78"

        const val playStoreButton =
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/apps%2Fgoogle-play.png?alt=media&token=9cee03bd-434a-4f37-aabb-2d8305cc201b"
        val satisfiedClients = arrayListOf(
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/playstore%2Fsatisfied_clients%2Ffirst_client.png?alt=media&token=253b9b7d-1196-4310-815a-bd99ce30c287",
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/playstore%2Fsatisfied_clients%2Fsecond_client.png?alt=media&token=88e45169-26d3-4804-a688-a498a84e6ad8",
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/playstore%2Fsatisfied_clients%2Fthird_client.png?alt=media&token=df16e3a4-5e7d-4af4-9bfe-65aeb6717cff",
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/playstore%2Fsatisfied_clients%2Ffourth_client.png?alt=media&token=48a398f7-73d1-4f48-845c-c8fb6eee8351",
            "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/playstore%2Fsatisfied_clients%2Ffifth_client.png?alt=media&token=a4c5884f-39f7-4cb2-b83a-713015f42084"
        )
    }
}