package feature.payment.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RazorpayPayment(
    @SerialName("razorpay_payment_id") var _id: String? = "",
    @SerialName("razorpay_order_id") var orderId: String? = null,
    @SerialName("razorpay_signature") var signature: String? = null,
)