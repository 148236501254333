package presentation.screens.business.inventory.stock.product_list

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.empty.EmptyData
import component.input.SearchBar
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.inventory.viewModel.brand.BrandViewModel
import feature.inventory.viewModel.coupon.CoupanViewModel
import feature.inventory.viewModel.discount.DiscountViewModel
import feature.inventory.viewModel.product_color.ProductColorViewModel
import feature.inventory.viewModel.size.SizeViewModel
import feature.inventory.viewModel.tag.TagViewModel
import feature.inventory.viewModel.uom.UOMViewModel
import feature.product.viewModel.ProductViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.inventory.component.ProductCard
import presentation.screens.business.inventory.product.create.CreateProductScreen
import theme.ThemeColor

class StockProductListScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val viewModel = ProductViewModel()
        val navigator = LocalNavigator.current
        val allProducts = viewModel.allProduct.collectAsState()
        val searchQuery = viewModel.searchQuery.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.loadProduct()
            ProductColorViewModel().fetchAllColor()
            BrandViewModel().fetchAllBrand()
            CoupanViewModel().fetchAllCoupan()
            DiscountViewModel().fetchAllDiscount()
            SizeViewModel().fetchAllSize()
            TagViewModel().fetchAllTag()
            UOMViewModel().fetchAllUOM()
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(
                title = localization().stockList.stockLisTitle,
                navigationParam,
                onBackPress = {
                    navigator?.pop()
                },
                onClickAdd = {
                    navigator?.push(CreateProductScreen())
                })
            AppLoader(viewModel)
            SearchBar(
                viewModel,
                searchQuery.value,
                localization().product.productsSearchPlaceholder
            )
            if (allProducts.value.isEmpty()) {
                EmptyData(viewModel = viewModel)
                DefaultButton(
                    "Create New Product",
                    onClick = { navigator?.push(CreateProductScreen()) })
            } else {
                Box(
                    modifier = Modifier
                        .height(50.dp)
                        .padding(start = 8.dp, end = 8.dp)
                        .background(
                            ThemeColor.shared().cardBackground,
                            shape = RoundedCornerShape(10.dp)
                        ),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "${localization().product.productsTotal} ${allProducts.value.count()} ${localization().product.productsProduct}",
                        maxLines = 2,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Center,
                        color = ThemeColor.shared().PrimaryColor
                    )
                }
            }
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 400.dp),
                modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
            ) {
                items(count = allProducts.value.count()) {
                    ProductCard(allProducts.value[it]) { prd ->
                        navigateTo(
                            ScreenName.BUSINESS_INVENTORY_LIST, navigationParam?.update(
                                NavigationParam(product = prd)
                            )
                        )
                    }
                }
            }
        }
    }
}