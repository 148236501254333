package presentation.screens.customer.store.cart

import androidx.compose.animation.core.animateDpAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.cart.CartPaymentDetails
import component.empty.EmptyData
import component.loader.AppLoader
import component.navigation.TopNavBar
import component.product.CartItemCard
import feature.address.model.Address
import feature.address.viewModel.AddressViewModel
import feature.app.NavigationParam
import feature.cart.model.DeliveryOption
import feature.cart.viewModel.CartViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.customer.store.component.CartDeliveryOption
import theme.ThemeColor

class ShoppingCart(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val viewModel = CartViewModel()
        val addressViewModel = AddressViewModel()
        val navigator = LocalNavigator.current
        val cartItems = viewModel.cartItems.collectAsState()
        val totalAmount = viewModel.totalAmount.collectAsState()
        var deliveryOption: DeliveryOption by remember { mutableStateOf(DeliveryOption()) }
        var isOpen: Boolean by remember { mutableStateOf(false) }
        val paymentDetailsHeightPx by remember {
            mutableStateOf(80.dp)
        }
        val heightInDp = animateDpAsState(
            targetValue = if (isOpen) {
                0.dp
            } else paymentDetailsHeightPx,
            animationSpec = tween(
                durationMillis = 1500,
            )
        )

        fun onClickProceed(address: Address?) {
            if (totalAmount.value > 0F) {
                navigateTo(
                    ScreenName.ORDER_PAYMENT,
                    navigationParam?.update(NavigationParam(address = address))
                )
            }
        }
        LaunchedEffect(key1 = Unit) {
            viewModel.loadCart(navigationParam)
            addressViewModel.loadAllAddressForUser()
        }
        Column(
            modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(title = "Cart", navigationParam, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            if (cartItems.value.isEmpty()) {
                EmptyData(viewModel = viewModel)
                DefaultButton(
                    "Add Product",
                    onClick = { navigator?.pop() })
            } else {
                Box(
                    modifier = Modifier
                        .height(50.dp)
                        .padding(start = 8.dp, end = 8.dp)
                        .background(
                            ThemeColor.shared().cardBackground,
                            shape = RoundedCornerShape(10.dp)
                        ),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "${localization().product.productsTotal} ${cartItems.value.count()} ${localization().product.productsProduct}",
                        maxLines = 2,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Center,
                        color = ThemeColor.shared().PrimaryColor
                    )
                }
            }
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 400.dp),
                modifier = Modifier.weight(1f).padding(
                    start = 8.dp,
                    end = 8.dp,
                    bottom = if (cartItems.value.isNotEmpty()) 60.dp else (60.dp)
                ),
            ) {
                items(count = cartItems.value.count()) {
                    CartItemCard(cartItems.value[it], viewModel, navigationParam)
                }
            }
            Box(
                modifier = Modifier.fillMaxWidth().height(heightInDp.value).padding(8.dp)
            ) {
                CartPaymentDetails(viewModel = viewModel)
            }

            CartDeliveryOption(
                navigationParam,
                addressViewModel,
                totalAmount.value,
                deliveryOption,
                isOpen,
                {
                    isOpen = !isOpen
                },
                { option ->
                    deliveryOption = DeliveryOption()
                    deliveryOption = option
                }) { address ->
                onClickProceed(address)
            }
        }
    }
}