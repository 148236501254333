package feature.customer.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Customer(
    @SerialName("_id") var Id: String = "",
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("CustomerUserID") var CustomerUserID: String? = null,
    @SerialName("BusinessID") var BusinessID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("Name") var Name: String? = null,
    @SerialName("Address") var Address: String? = null,
    @SerialName("GSTNumber") var GSTNumber: String? = null,
    @SerialName("Barcode") var barcode: String? = null,
    @SerialName("BarCodeImage") var barCodeImage: String? = null,
    @SerialName("Status") var Status: String? = null,
    @SerialName("EmailID") var EmailID: String? = null,
    @SerialName("DialCode") var DialCode: String? = null,
    @SerialName("MobileNumber") var MobileNumber: String? = null,
    @SerialName("WhatsApp") var WhatsApp: String? = null,
    @SerialName("Gender") var Gender: Long? = null,
    @SerialName("DeviceID") var DeviceID: String? = null,
    @SerialName("FCMToken") var FCMToken: String? = null,
    @SerialName("ProfilePicture") var ProfilePicture: ArrayList<String> = arrayListOf(),
    @SerialName("TotalSale") var TodalSale: Long? = null,
    @SerialName("TotalPayment") var TotalPayment: Long? = null,
    @SerialName("LastSeen") var LastSeen: String? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null

)
