package storage.database.table.inventory.product_color

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.ProductColorTable
import feature.inventory.model.product_color.ProductColor
import kotlinx.coroutines.launch
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class ProductColorDatabaseTable : DatabaseTable() {
    fun insert(item: ProductColor?) {
        val table = AppDatabase.shared().database?.productColorTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    _id = item.Id,
                    ColorID = item.ColorID,
                    UserID = item.UserID,
                    BusinessID = item.BusinessID,
                    IsDeleted = item.IsDeleted,
                    Name = item.Name,
                    Code = item.Code,
                    Hex = item.Hex,
                    R = item.R,
                    G = item.G,
                    B = item.B,
                    A = item.A,
                    Description = item.Description,
                    CreatedAt = item.CreatedAt,
                    UpdatedAt = item.UpdatedAt,
                )
            }
        }
    }

    fun insert(items: List<ProductColor>?) {
        val table = AppDatabase.shared().database?.productColorTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    _id = item.Id,
                    ColorID = item.ColorID,
                    UserID = item.UserID,
                    BusinessID = item.BusinessID,
                    IsDeleted = item.IsDeleted,
                    Name = item.Name,
                    Code = item.Code,
                    Hex = item.Hex,
                    R = item.R,
                    G = item.G,
                    B = item.B,
                    A = item.A,
                    Description = item.Description,
                    CreatedAt = item.CreatedAt,
                    UpdatedAt = item.UpdatedAt,
                )
            }
        }
    }

    fun retrieve(id: String, callback: (List<ProductColor>) -> Unit) {
        val table = AppDatabase.shared().database?.productColorTableQueries
        val itemsFetched: ArrayList<ProductColor> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun retrieveAll(callback: (List<ProductColor>) -> Unit) {
        val table = AppDatabase.shared().database?.productColorTableQueries
        val itemsFetched: ArrayList<ProductColor> = arrayListOf()
        scope.launch {
            val items = table?.retrieveAll()?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun find(id: String?, callback: (ProductColor?) -> Unit) {
        val table = AppDatabase.shared().database?.productColorTableQueries
        if(id != null){
            scope.launch {
                val items = table?.find(id)?.awaitAsOneOrNull()
                items?.let { item ->
                    val itemsFetched = convert(item)
                    callback(itemsFetched)
                }
            }
        }else{
            callback(null)
        }
    }


    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.productColorTableQueries
        scope.launch {
            val dateFromDb = table?.lastSyncDate(id)?.awaitAsOneOrNull()
            callback(dateFromDb?.UpdatedAt)
        }
    }

    fun convert(item: ProductColorTable): ProductColor {
        return ProductColor(
            Id = item._id,
            ColorID = item.ColorID,
            UserID = item.UserID,
            BusinessID = item.BusinessID,
            IsDeleted = item.IsDeleted,
            Name = item.Name,
            Code = item.Code,
            Hex = item.Hex,
            R = item.R,
            G = item.G,
            B = item.B,
            A = item.A,
            Description = item.Description,
            CreatedAt = item.CreatedAt,
            UpdatedAt = item.UpdatedAt,
        )
    }
}