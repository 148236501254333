package presentation.screens.common.address.edit

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.address.AddressLocationInput
import component.button.DefaultButton
import component.input.CheckBoxInput
import component.input.TextInputField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.address.viewModel.AddressViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen

class EditAddressScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val navigator = LocalNavigator.current
        val viewModel = AddressViewModel()
        val address = remember { mutableStateOf(navigationParam?.address) }
        var isChecked by remember { mutableStateOf(address.value?.IsPrimary ?: false) }
        LaunchedEffect(key1 = Unit) {
            if (address.value?.Location?.coordinate?.isEmpty() == false) {
                viewModel.setPickedLocation(address.value?.Location)
            }
        }
        Column(modifier = Modifier.fillMaxHeight()) {
            AppLoader(viewModel)
            TopNavBar("Update Address", navigationParam, onBackPress = {
                navigator?.pop()
            })
            AddressLocationInput { loc ->
                viewModel.setPickedLocation(loc)
            }
            TextInputField(
                placeholder = localization().address.houseNumber,
                value = address.value?.House ?: "",
                onChange = {
                    viewModel.setHouseNumberEditAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.blockAreaVillageLocality,
                value = address.value?.Area ?: "",
                onChange = {
                    viewModel.setAreaForEditAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.landmark,
                value = address.value?.LandMark ?: "",
                onChange = {
                    viewModel.setLandmarkForEditAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.pincodeZipcode,
                value = address.value?.ZipCode.toString(),
                onChange = {
                    viewModel.setPinCodeForEditAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.city,
                value = address.value?.City ?: "",
                onChange = {
                    viewModel.setCityForEditAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.state,
                value = address.value?.State ?: "",
                onChange = {
                    viewModel.setStateForEditAddress(it)
                }
            )
            CheckBoxInput("Primary Address", isChecked) {
                isChecked = it
                viewModel.setStatePrimaryEditAddress(it)
            }
            DefaultButton(
                title = "Update",
                onClick = { viewModel.update() },
                modifier = Modifier.fillMaxWidth().padding(top = 8.dp)
            )
        }
    }
}