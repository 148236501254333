package presentation.screens.common.scanner

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import component.navigation.TopNavBar
import navigation.containers.ScreenName
import navigation.screen.AppScreen

class ScannerScreen(override val screenName: ScreenName = ScreenName.SCANNER_SCREEN) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
//        Scanner(onScanned = { println(it); true }, types = listOf(CodeType.QR))
        Column(
            modifier = Modifier
                .fillMaxSize()
        ) {
            TopNavBar("Privacy Policy", null, onBackPress = {
                navigator?.pop()
            })
        }
    }
}