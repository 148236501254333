package feature.productCategory.handler

import feature.productCategory.model.ProductCategory
import feature.productCategory.repository.ProductCategoryRepository
import feature.productCategory.viewModel.ProductCategoryViewModel

class ProductCategoryHandler {

    var viewModel: ProductCategoryViewModel? = null
    val repository = ProductCategoryRepository()
    var onCreateNewCategory: ((category: ProductCategory) -> Unit)? = null
    var onSelectCategory: ((category: ProductCategory) -> Unit)? = null

    init {
        instance = this
    }

    companion object {
        private var instance: ProductCategoryHandler? = null
        fun shared(): ProductCategoryHandler {
            if (instance != null) {
                return instance as ProductCategoryHandler
            } else {
                return ProductCategoryHandler()
            }
        }
    }

    fun setup(model: ProductCategoryViewModel) {
        viewModel = model
    }

}