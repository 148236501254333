package feature.offerBanner.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class OfferBannerRetrieveRequest(
    @SerialName("BusinessID") val businessID: String,
    @SerialName("FeatureObjectID") val featureObjectID: String? = null,
)