package feature.alert.viewModel


import androidx.compose.runtime.Stable
import feature.alert.handler.AlertHandler
import feature.app.AppViewModel
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch

@Stable
class AlertViewModel : AppViewModel() {

    init {
        AlertHandler.shared().viewModel = this
    }

    val defaultTime = 5
    val repository = AlertHandler.shared().repository
    val timer = repository.timer.asStateFlow()
    val alertTimer = repository.alertTimer.asStateFlow()
    val toastTimer = repository.toastTimer.asStateFlow()

    val isVisible = repository.isVisible.asStateFlow()
    val isAlertVisible = repository.isAlertVisible.asStateFlow()
    val isToastVisible = repository.isToastVisible.asStateFlow()

    val isSuccess = repository.isSuccess.asStateFlow()
    val isConfirmation = repository.isConfirmation.asStateFlow()

    val title = repository.title.asStateFlow()
    val message = repository.message.asStateFlow()

    val onPositiveClick = repository.onPositiveClick.asStateFlow()
    val onNegativeClick = repository.onNegativeClick.asStateFlow()
    fun show() {
        scope.launch {
            repository.isVisible.emit(true)
            startCountDown()
        }
    }

    fun showAlert() {
        scope.launch {
            repository.isAlertVisible.emit(true)
//            startAlertCountDown()
        }
    }

    fun showToast() {
        scope.launch {
            repository.isToastVisible.emit(true)
            startToastCountDown()
        }
    }

    fun startCountDown() {
        scope.launch {
            if (timer.value > 0) {
                delay(1_000)
                repository.timer.emit(timer.value - 1)
                startCountDown()
            } else {
                repository.isVisible.emit(false)
            }
        }
    }

    fun startAlertCountDown() {
        scope.launch {
            if (alertTimer.value > 0) {
                delay(1_000)
                repository.alertTimer.emit(alertTimer.value - 1)
                startCountDown()
            } else {
                repository.isAlertVisible.emit(false)
            }
        }
    }

    fun startToastCountDown() {
        scope.launch {
            if (toastTimer.value > 0) {
                delay(1_000)
                repository.toastTimer.emit(toastTimer.value - 1)
                startCountDown()
            } else {
                repository.isToastVisible.emit(false)
            }
        }
    }
}