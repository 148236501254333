package feature.cart.model

import feature.address.model.Address
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DeliveryOption(
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("SelectedBusinessID") var selectedBusinessID: String? = null,
    @SerialName("Address") var address: Address? = null,
    @SerialName("IsDelivery") var isDelivery: Boolean = false,
)