package storage.database.table.inventory.product_category

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.ProductCategoryTable
import feature.productCategory.model.ProductCategory
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class ProductCategoryDatabaseTable : DatabaseTable() {
    fun insert(item: ProductCategory?) {
        val table = AppDatabase.shared().database?.productCategoryTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    item.Id,
                    item.ProductCategoryID,
                    item.UserID,
                    item.BusinessID,
                    item.IsDeleted,
                    item.Name,
                    item.Image,
                    item.CreatedAt,
                    item.UpdatedAt,
                )
            }
        }
    }

    fun insert(items: List<ProductCategory>?, callback: () -> Unit) {
        val table = AppDatabase.shared().database?.productCategoryTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    item.Id,
                    item.ProductCategoryID,
                    item.UserID,
                    item.BusinessID,
                    item.IsDeleted,
                    item.Name,
                    item.Image,
                    item.CreatedAt,
                    item.UpdatedAt,
                )
            }
            delay(delay)
            callback()
        }
    }

    fun retrieve(id: String, callback: (List<ProductCategory>) -> Unit) {
        val table = AppDatabase.shared().database?.productCategoryTableQueries
        val itemsFetched: ArrayList<ProductCategory> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun find(id: String, callback: (ProductCategory?) -> Unit) {
        val table = AppDatabase.shared().database?.productCategoryTableQueries
        var itemsFetched: ProductCategory? = null
        scope.launch {
            val items = table?.find(id)?.awaitAsOneOrNull()
            items?.let { item ->
                itemsFetched = convert(item)
            }
            callback(itemsFetched)
        }
    }


    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.productCategoryTableQueries
        scope.launch {
            val dateFromDb = table?.lastSyncDate(id)?.awaitAsOneOrNull()
            callback(dateFromDb?.UpdatedAt)
        }
    }

    fun convert(item: ProductCategoryTable): ProductCategory {
        return ProductCategory(
            item._id,
            item.ProductCategoryID,
            item.UserID,
            item.BusinessID,
            item.IsDeleted,
            item.Name,
            item.Image,
            item.CreatedAt,
            item.UpdatedAt,
        )
    }
}