package feature.offerBanner.network

import common.constants.Server
import feature.offerBanner.event.OfferBannerEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class OfferBannerNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: OfferBannerNetwork? = null
        fun shared(): OfferBannerNetwork {
            if (instance != null) {
                return instance as OfferBannerNetwork
            } else {
                return OfferBannerNetwork()
            }
        }
    }

    fun retrieve(
        request: OfferBannerRetrieveRequest,
        onResult: (OfferBannerRetrieveResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(OfferBannerEvent.RETRIEVE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }
}
