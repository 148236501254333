package storage.database.table.media_file

import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.MediaFileTable
import feature.mediaFile.model.MediaFile
import kotlinx.coroutines.launch
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class MediaFileDatabaseTable : DatabaseTable() {
    fun insert(item: MediaFile?, callback: () -> Unit) {
        val table = AppDatabase.shared().database?.mediaFileTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    item.UniqueID,
                    item._id,
                    item.UserID,
                    item.BusinessID,
                    item.FeatureObjectID,
                    item.IsDeleted,
                    item.FileURL,
                    item.CreatedAt,
                    item.UpdatedAt
                )
            }
        }
    }

    fun insert(items: List<MediaFile>?, callback: () -> Unit) {
        scope.launch {
            items?.forEach { item ->
                insert(item) {}
            }
            callback()
        }
    }

    fun retrieve(id: String, callback: (MediaFile?) -> Unit) {
        val table = AppDatabase.shared().database?.mediaFileTableQueries
        var itemsFetched: MediaFile? = null
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsOneOrNull()
            items?.let { item ->
                itemsFetched = convert(item)
            }
            callback(itemsFetched)
        }
    }

    fun convert(item: MediaFileTable): MediaFile {
        return MediaFile(
            item.UniqueID!!,
            item._id,
            item.UserID,
            item.BusinessID,
            item.FeatureObjectID,
            item.IsDeleted,
            item.FileURL!!,
            item.CreatedAt!!,
            item.UpdatedAt!!,
        )
    }
}