package presentation.screens.customer.store.shopbycategory.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import theme.ThemeColor

@Composable
fun ProductsCard() {
    Card(
        modifier = Modifier
            .width(182.dp)
            .height(58.dp)
            .padding(2.dp)
            .background(ThemeColor.shared().defaultBakground),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().defaultBakground,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().defaultBakground
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 4.dp
        ),
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(4.dp)
        ) {
            Box(
                modifier = Modifier
                    .width(40.dp)
                    .height(40.dp)
                    .background(color = Color.Black, shape = RoundedCornerShape(4.dp))
            ) {
            }
            Text(
                "Groceries", fontSize = 16.sp, color = Color.Gray, modifier = Modifier
                    .padding(top = 8.dp, start = 4.dp)
            )
        }

    }
}