package feature.order.network

import feature.order.model.Order
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateOrderResponse(
    @SerialName("Payload") val payload: Order?
)

@Serializable
data class RetrieveOrderResponse(
    @SerialName("Payload") val payload: List<Order>?
)