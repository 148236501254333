package feature.address.network

import common.constants.Server
import feature.address.event.AddressEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient

class AddressNetwork {
    val json = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }

    fun create(
        request: CreateAddressRequest,
        onResult: (CreateAddressResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(AddressEvent.CREATE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                print(t)
            }
        }
    }

    fun update(
        request: UpdateAddressRequest,
        onResult: (UpdateAddressResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(AddressEvent.UPDATE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                onResult(json.decodeFromString(response.bodyAsText()))
            } catch (t: Throwable) {
                onResult(null)
                print(t)
            }
        }
    }

    fun retrieve(
        request: RetrieveAddressRequest,
        onResult: (RetrieveAddressResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(AddressEvent.RETRIEVE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                val addressRes: RetrieveAddressResponse =
                    json.decodeFromString(response.bodyAsText())
                onResult(addressRes)
            } catch (t: Throwable) {
                onResult(null)
                print(t)
            }
        }
    }

    fun findPinCode(
        request: FindZipCodeRequest,
        onResult: (FindZipCodeResponse?) -> Unit
    ) {
        CoroutineScope(Job() + Dispatchers.Default).launch {
            val apiRoute = Server.getRestApiEndPointFor(AddressEvent.FIND_PIN_CODE)
            try {
                val response = FriendlyClient.post(apiRoute) {
                    contentType(ContentType.Application.Json)
                    setBody(request)
                }
                val addressRes: FindZipCodeResponse =
                    json.decodeFromString(response.bodyAsText())
                onResult(addressRes)
            } catch (t: Throwable) {
                onResult(null)
                print(t)
            }
        }
    }


//    fun connectScoket() {
//        SocketService.shared().mSocket?.on(AddressEvent.CREATE, AddressHandler.shared().onCreate)
//        SocketService.shared().mSocket?.on(
//            AddressEvent.UPDATE,
//            AddressHandler.shared().onUpdate
//        )
//        SocketService.shared().mSocket?.on(
//            AddressEvent.DELETE,
//            AddressHandler.shared().onDelete
//        )
//        SocketService.shared().mSocket?.on(
//            AddressEvent.RETRIEVE,
//            AddressHandler.shared().onRetrieve
//        )
//    }
//
//    fun disconnectSocket() {
//        SocketService.shared().mSocket?.off(AddressEvent.CREATE)
//        SocketService.shared().mSocket?.off(AddressEvent.UPDATE)
//        SocketService.shared().mSocket?.off(AddressEvent.DELETE)
//        SocketService.shared().mSocket?.off(AddressEvent.RETRIEVE)
//    }
//
//    fun <T> buildService(service: Class<T>): T {
//        return FriendlyNetwork.shared().retrofit.create(service)
//    }
//
//    fun create(request: JSONObject, onResult: (JSONObject?) -> Unit) {
//        val retrofit = buildService(AddressNetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            request.toString().toRequestBody("application/json".toMediaTypeOrNull())
//        retrofit.create(bodyRequest).enqueue(
//            ResponseHandler().getRestHandler(onResult)
//        )
//    }
//
//    fun retrieve(request: JSONObject, onResult: (JSONObject?) -> Unit) {
//        val retrofit = buildService(AddressNetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            request.toString().toRequestBody("application/json".toMediaTypeOrNull())
//        retrofit.retrieve(bodyRequest).enqueue(
//            ResponseHandler().getRestHandler(onResult)
//        )
//    }
}
