package com.friendly.common.database

import kotlin.Double
import kotlin.Long
import kotlin.String

public data class CartItemTable(
  public val UniqueID: Long,
  public val _id: String?,
  public val UserID: String?,
  public val BusinessID: String?,
  public val SelectedBusinessID: String?,
  public val CustomerId: String?,
  public val ProductID: String?,
  public val Product: String?,
  public val Price: String?,
  public val Quantity: Long,
  public val FinalPrice: Double,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
)
