package presentation.screens.common.notification.component

import androidx.compose.runtime.Composable
import feature.notification.model.Notification
import feature.notification.viewModel.NotificationViewModel
import presentation.screens.common.notification.component.contentCard.FriendRequestNotificationCard
import presentation.screens.common.notification.component.contentCard.GeneralNotificationCard
import presentation.screens.common.notification.component.contentCard.MessageNotificationCard
import presentation.screens.common.notification.component.contentCard.NewFeatureAlertCard
import presentation.screens.common.notification.component.contentCard.OrderTrackingCard

@Composable
fun NotificationCard(notification: Notification, viewModel: NotificationViewModel) {
    if (notification.notificationType == "FRIEND_REQUEST") {
        FriendRequestNotificationCard(notification, viewModel)
    }
    if (notification.notificationType == "GENERAL_NOTIFICATION") {
        GeneralNotificationCard()
    }
    if (notification.notificationType == "NEW_FEATURE_AlERT") {
        NewFeatureAlertCard()
    }
    if (notification.notificationType == "MESSAGE") {
        MessageNotificationCard()
    }
    if (notification.notificationType == "ORDER_TRACKING") {
        OrderTrackingCard()
    }
}
