package component.image

import androidx.compose.foundation.Image

import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import appConfig.AppConfig
import feature.business.model.Business
import io.github.alexzhirkevich.qrose.rememberQrCodePainter

@Composable
fun BusinessQRCode(business: Business?) {
    Image(
        painter = rememberQrCodePainter("${AppConfig.shared().domainUrl}/stores?id=${business?.BusinessNumber}"),
        contentDescription = "Barcode for business ${business?.Name}}",
        modifier = Modifier.fillMaxWidth().fillMaxHeight()
            .padding(top = 16.dp, bottom = 16.dp)
    )
}