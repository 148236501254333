package feature.sale.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Sale(
    @SerialName("_id") var id: String = "",
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("BusinessID") var BusinessID: String? = null,
    @SerialName("ProductID") var ProductID: String? = null,
    @SerialName("BarCode") var BarCode: ArrayList<String> = arrayListOf(),
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("CustomerName") var CustomerName: String? = null,
    @SerialName("CustomerMobile") var CustomerMobile: String? = null,
    @SerialName("VehicleNumber") var VehicleNumber: String? = null,
    @SerialName("ProductName") var ProductName: String? = null,
    @SerialName("PaymentMode") var PaymentMode: String? = null,
    @SerialName("Quantity") var Quantity: Int? = null,
    @SerialName("Price") var Price: Float? = null,
    @SerialName("Discount") var Discount: Float? = null,
    @SerialName("SGST") var SGST: Float? = null,
    @SerialName("CGST") var CGST: Float? = null,
    @SerialName("IGST") var IGST: Float? = null,
    @SerialName("CESS") var CESS: Float? = null,
    @SerialName("VAT") var VAT: Float? = null,
    @SerialName("Tax") var Tax: Float? = null,
    @SerialName("FinalPrice") var FinalPrice: Float? = null,
    @SerialName("SaleDate") var SaleDate: String? = null,
    @SerialName("SaleTime") var SaleTime: Long? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null,
)
