package feature.mediaFile.handler

import feature.mediaFile.model.MediaFile
import feature.mediaFile.repository.MediaFileRepository
import feature.mediaFile.viewModel.MediaFileViewModel

class MediaFileHandler {


    var viewModel: MediaFileViewModel? = null
    val repository = MediaFileRepository()
    var onCreateNew: ((category: MediaFile) -> Unit)? = null

    init {
        instance = this
    }

    companion object {
        private var instance: MediaFileHandler? = null
        fun shared(): MediaFileHandler {
            if (instance != null) {
                return instance as MediaFileHandler
            } else {
                return MediaFileHandler()
            }
        }
    }
}