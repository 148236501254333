package feature.app

class AppStateHandler {
    val repository = AppRepository()

    companion object {
        private var instance: AppStateHandler? = null
        fun shared(): AppStateHandler {
            return if (instance != null) {
                instance as AppStateHandler
            } else {
                instance = AppStateHandler()
                instance as AppStateHandler
            }
        }
    }
}