package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.uOMTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class UOMTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    UOMID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getLong(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun retrieve(BusinessID: String?): Query<UOMTable> = retrieve(BusinessID) { _id, UOMID,
      UserID, BusinessID_, IsDeleted, Name, Value, Description, CreatedAt, UpdatedAt ->
    UOMTable(
      _id,
      UOMID,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveAll(mapper: (
    _id: String,
    UOMID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = Query(1_812_241_802, arrayOf("UOMTable"), driver, "UOMTable.sq",
      "retrieveAll",
      "SELECT UOMTable._id, UOMTable.UOMID, UOMTable.UserID, UOMTable.BusinessID, UOMTable.IsDeleted, UOMTable.Name, UOMTable.Value, UOMTable.Description, UOMTable.CreatedAt, UOMTable.UpdatedAt FROM UOMTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getLong(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun retrieveAll(): Query<UOMTable> = retrieveAll { _id, UOMID, UserID, BusinessID,
      IsDeleted, Name, Value, Description, CreatedAt, UpdatedAt ->
    UOMTable(
      _id,
      UOMID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    UOMID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getLong(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun find(_id: String): Query<UOMTable> = find(_id) { _id_, UOMID, UserID, BusinessID,
      IsDeleted, Name, Value, Description, CreatedAt, UpdatedAt ->
    UOMTable(
      _id_,
      UOMID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    UOMID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(-745_806_380, """
        |INSERT OR REPLACE INTO UOMTable( _id ,UOMID,UserID, BusinessID,IsDeleted,Name,Value,Description,CreatedAt,UpdatedAt)
        |    VALUES(?, ?,?, ? ,?, ?, ?,?,?,?)
        """.trimMargin(), 10) {
          bindString(0, _id)
          bindLong(1, UOMID)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindBoolean(4, IsDeleted)
          bindString(5, Name)
          bindLong(6, Value)
          bindString(7, Description)
          bindString(8, CreatedAt)
          bindString(9, UpdatedAt)
        }.await()
    notifyQueries(-745_806_380) { emit ->
      emit("UOMTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("UOMTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("UOMTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UOMTable._id, UOMTable.UOMID, UOMTable.UserID, UOMTable.BusinessID, UOMTable.IsDeleted, UOMTable.Name, UOMTable.Value, UOMTable.Description, UOMTable.CreatedAt, UOMTable.UpdatedAt FROM UOMTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "UOMTable.sq:retrieve"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("UOMTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("UOMTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_453_382_764,
        """SELECT UOMTable._id, UOMTable.UOMID, UOMTable.UserID, UOMTable.BusinessID, UOMTable.IsDeleted, UOMTable.Name, UOMTable.Value, UOMTable.Description, UOMTable.CreatedAt, UOMTable.UpdatedAt FROM UOMTable WHERE _id = ?""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "UOMTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("UOMTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("UOMTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM UOMTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "UOMTable.sq:lastSyncDate"
  }
}
