package feature.auth.handler


import androidx.compose.runtime.CompositionLocalContext
import common.constants.KeyConstant
import common.constants.UserDefaults
import common.util.uuid
import component.country.CountryCode
import feature.auth.model.FriendlyUser
import feature.auth.network.AuthNetwork
import feature.auth.network.UpdateFCMTokenRequest
import feature.auth.repository.AuthRepository
import feature.auth.viewModel.AuthViewModel
import feature.device.handler.DeviceHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json


class AuthHandler {
    var viewModel: AuthViewModel? = null
    val repository = AuthRepository()
    var context: CompositionLocalContext? = null
    var deviceId = ""
    var otpFromSever = ""
    var dialCode = ""
    var countryCode: CountryCode? = null
    val userId: String
        get() {
            val user = shared().repository.authLiveData.value
            if (user.id.isEmpty()) {
                val credentials: String = UserDefaults.retrieveCredentials()
                if (credentials.isNotEmpty()) {
                    val userObj: FriendlyUser = Json.decodeFromString(credentials)
                    CoroutineScope(Job() + Dispatchers.Main).launch {
                        repository.authLiveData.emit(userObj)
                        repository.isLoggedInState.emit(true)
                    }
                    return user.id
                }
            }
            return user.id
        }
    val mobile: String
        get() {
            val user = shared().repository.authLiveData.value
            if (user.mobile?.isEmpty() == true) {
                val credentials: String = UserDefaults.retrieveCredentials()
                if (credentials.isNotEmpty()) {
                    val userObj: FriendlyUser = Json.decodeFromString(credentials)
                    return userObj.mobile ?: ""
                }
            }
            return user.mobile ?: ""
        }
    val mobileNumber: String
        get() {
            val mobile = shared().repository.mobileNumber.value
            return mobile
        }
    val email: String
        get() {
            val user = shared().repository.authLiveData.value
            if (user.EmailID?.isEmpty() == true) {
                val credentials: String = UserDefaults.retrieveCredentials()
                if (credentials.isNotEmpty()) {
                    val userObj: FriendlyUser = Json.decodeFromString(credentials)
                    return userObj.EmailID ?: ""
                }
            }
            return user.EmailID ?: ""
        }
    val userMobile: String
        get() {
            val user = shared().repository.authLiveData.value
            return user.mobile ?: ""
        }

    init {
        instance = this
    }

    var onLogout: (() -> Unit)? = null

    fun logout() {
        UserDefaults.remove(KeyConstant.loginDetails)
        if (onLogout != null) {
            onLogout!!()
        }
    }

    companion object {
        private var instance: AuthHandler? = null
        fun shared(): AuthHandler {
            if (instance != null) {
                return instance as AuthHandler
            } else {
                return AuthHandler()
            }
        }
    }

    fun isLoggedIn(): Boolean {
        return repository.isLoggedInState.value
    }

    fun setup(model: AuthViewModel) {
        viewModel = model
    }

    fun checkIfLoggedIn() {
        val credentials: String = UserDefaults.retrieveCredentials()
        if (credentials.isNotEmpty()) {
            val user: FriendlyUser = Json.decodeFromString(credentials)
            CoroutineScope(Job() + Dispatchers.Main).launch {
                repository.authLiveData.emit(user)
                repository.isLoggedInState.emit(true)
            }
            shared().updateExistingTokenToServer(user.id)
        } else {
            CoroutineScope(Job() + Dispatchers.Main).launch {
                repository.authLiveData.emit(FriendlyUser())
                repository.isLoggedInState.emit(false)
            }
        }
    }

    fun updateTokenToServer(
        token: String
    ) {
        checkIfLoggedIn()
        DeviceHandler.shared().setCurrentDeviceToken(token)
    }

    private fun updateExistingTokenToServer(userId: String) {
        val token = UserDefaults.retrieve(KeyConstant.fcmToken)
        if (userId.isNotEmpty() && token.isNotEmpty()) {
            val request = UpdateFCMTokenRequest(userId, token, uuid())
            CoroutineScope(Job() + Dispatchers.Default).launch {
                AuthNetwork().updateToken(request) {
                    if (it?.status == "Success") {
                        print("FCM Updated in Server")
                        UserDefaults.remove(KeyConstant.fcmToken)
                    } else {
                        print("Couldn't update FCM Updated in Server")
                    }
                }
            }
        }
    }
}