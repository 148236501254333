package feature.address.handler

import feature.address.model.Address
import feature.address.repository.AddressRepository
import feature.address.viewModel.AddressViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job

class AddressHandler {
    var scope: CoroutineScope = CoroutineScope(Job() + Dispatchers.Default)

    var viewModel: AddressViewModel? = null
    val repository = AddressRepository()
    var onCreateResponse: ((address: Address) -> Unit)? = null
    var onUpdateResponse: ((address: Address) -> Unit)? = null
    var onDeleteResponse: ((address: Address) -> Unit)? = null

    init {
        instance = this
    }

    companion object {
        private var instance: AddressHandler? = null
        fun shared(): AddressHandler {
            return if (instance != null) {
                instance as AddressHandler
            } else {
                instance = AddressHandler()
                instance as AddressHandler
            }
        }
    }

    fun setup(model: AddressViewModel) {
        viewModel = model
    }

    fun getPrimaryAddressForCustomer(): Address? {
        var isPrimaryAddressSet = false
        repository.allAddress.value.forEach {
            if (it.IsPrimary == true) {
                return it
            }
        }
        if (!isPrimaryAddressSet && repository.primaryAddressState.value.Location?.coordinate?.isEmpty() == false) {
            return repository.primaryAddressState.value
        }
        return null
    }


}
