package feature.productCategory.repository

import feature.product.model.Product
import feature.productCategory.model.ProductCategory
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class ProductCategoryRepository {
    val categoryLiveData: MutableStateFlow<List<ProductCategory>> = MutableStateFlow(arrayListOf())
    val allCategory: StateFlow<List<ProductCategory>>
        get() = categoryLiveData

    val selectedCategoryState: MutableStateFlow<ProductCategory?> = MutableStateFlow(null)
    val selectedCategory: StateFlow<ProductCategory?>
        get() = selectedCategoryState

    val editCategoryState: MutableStateFlow<ProductCategory?> = MutableStateFlow(null)
    val editCategory: StateFlow<ProductCategory?>
        get() = editCategoryState

    var selectedCategoryForNavigation: ProductCategory? = null

    var productsMutableState: MutableStateFlow<List<Product>> =
        MutableStateFlow(ArrayList())
    val products: StateFlow<List<Product>> = productsMutableState

}