package presentation.screens.business.customer.create

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import component.country.DialCodeHelper
import component.input.CustomTextField
import component.input.MobileTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.customer.viewModel.CustomerViewModel
import locale.localization
import theme.ThemeColor

class CreateCustomerScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = CustomerViewModel()
        var name by remember { mutableStateOf(TextFieldValue("")) }
        val nameErrorState = remember { mutableStateOf(false) }
        var email by remember { mutableStateOf(TextFieldValue("")) }
        val emailErrorState = remember { mutableStateOf(false) }

        var barcode by remember { mutableStateOf(TextFieldValue("")) }
        val barcodeErrorState = remember { mutableStateOf(false) }

        var mobile by remember { mutableStateOf(TextFieldValue("")) }
        var dialCode by remember { mutableStateOf(DialCodeHelper().getSelectedCountryCode()) }
        val mobileErrorState = remember {
            mutableStateOf(false)
        }
        var isValidMobile by remember { mutableStateOf(false) }

        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = localization().customer.createCustomerTitle, null, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            Column(modifier = Modifier.fillMaxSize()) {
                CustomTextField(
                    placeholder = localization().customer.createCustomerNamePlaceholder,
                    trailingIcon = ImageAsset.person,
                    label = localization().customer.createCustomerName,
                    errorState = nameErrorState.value,
                    keyboardType = KeyboardType.Email,
                    visualTransformation = VisualTransformation.None
                ) { nameValue ->
                    name = nameValue
                }
                Spacer(modifier = Modifier.height(8.dp))
                CustomTextField(
                    placeholder = localization().customer.createCustomerEmailPlaceholder,
                    trailingIcon = ImageAsset.person,
                    label = localization().customer.createCustomerEmailId,
                    errorState = emailErrorState.value,
                    keyboardType = KeyboardType.Email,
                    visualTransformation = VisualTransformation.None
                ) { newValue ->
                    email = newValue
                }
                Spacer(modifier = Modifier.height(8.dp))
                MobileTextField(
                    placeholder = "9000000000",
                    trailingIcon = ImageAsset.phone,
                    label = localization().customer.createCustomerMobileNumber,
                    errorState = mobileErrorState,
                    visualTransformation = VisualTransformation.None,
                    onChanged = { mob ->
                        mobile = mob
                        isValidMobile = (mob.text.length in 9..11)
                    },
                    onDialCodeChange = {
                        dialCode = it
                    }
                )
                Spacer(modifier = Modifier.height(8.dp))
                CustomTextField(
                    placeholder = localization().customer.createCustomerBarcodePlaceholder,
                    trailingIcon = ImageAsset.person,
                    label = localization().customer.createCustomerBarcode,
                    errorState = barcodeErrorState.value,
                    keyboardType = KeyboardType.Email,
                    visualTransformation = VisualTransformation.None
                ) { newValue ->
                    barcode = newValue
                }
                Spacer(modifier = Modifier.height(8.dp))
                CustomDefaultBtn(
                    shapeSize = 50f,
                    btnText = localization().customer.createCustomerSaveButton
                ) {
                    mobileErrorState.value = !isValidMobile
                    if (isValidMobile) {
                        viewModel.createNewCustomer(
                            name.text,
                            dialCode.countryPhoneCode,
                            mobile.text,
                            email.text,
                            barcode.text
                        ) { result ->
                            if (result) {
                                AlertHandler.shared()
                                    .showSuccessAlert(
                                        localization().customer.createSuccessTitle,
                                        localization().customer.createSuccessMessage
                                    )
                            } else {
                                AlertHandler.shared().showAlert(
                                    localization().customer.createCustomerAlertTitle,
                                    localization().customer.createSuccessElseMessage
                                )
                            }
                        }
                    } else {
                        AlertHandler.shared().showAlert(
                            localization().customer.createCustomerAlertTitle,
                            localization().customer.createCustomerAlertMessage
                        )
                    }
                }
            }
        }
    }
}