package presentation.screens.business.homepage

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import component.image.AsyncImageView
import presentation.screens.business.homepage.component.AffordablePlanCard
import theme.ThemeColor

@Composable
fun AffordablePlansPage() {
    Column(
        modifier = Modifier
            .fillMaxWidth().fillMaxHeight().padding(top = 80.dp, bottom = 60.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        Box(modifier = Modifier.fillMaxWidth().height(1000.dp)) {
            Box(modifier = Modifier.fillMaxWidth().fillMaxHeight().zIndex(-1f)) {
                AsyncImageView("https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/website%2Fbanner%2Fgradient_background.png?alt=media&token=646b06f7-0473-477e-88a4-829e4a709fc8")
            }
            Box(modifier = Modifier.fillMaxWidth().height(1000.dp).zIndex(1f)) {
                Column(
                    modifier = Modifier.fillMaxWidth().fillMaxHeight(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        "Affordable Plans ?",
                        fontSize = 40.sp,
                        color = ThemeColor.shared().PrimaryColor,
                        fontWeight = FontWeight.Bold, modifier = Modifier.padding(top = 25.dp)
                    )
                    Text(
                        "Solute offers the various plans which easily affordable",
                        fontSize = 20.sp,
                        color = ThemeColor.shared().Grey, modifier = Modifier.padding(top = 25.dp)
                    )
                    LazyRow(
                        modifier = Modifier.fillMaxWidth()
                            .height(500.dp).padding(40.dp),
                        horizontalArrangement = Arrangement.SpaceEvenly
                    ) {
                        item {
                            AffordablePlanCard(7)
                            AffordablePlanCard(30)
                            AffordablePlanCard(90)
                        }
                    }
                    LazyRow(
                        modifier = Modifier.fillMaxWidth()
                            .height(500.dp), horizontalArrangement = Arrangement.SpaceEvenly
                    ) {
                        item {
                            AffordablePlanCard(180)
                            AffordablePlanCard(270)
                            AffordablePlanCard(365)
                        }
                    }
                }
            }
        }
    }
}


