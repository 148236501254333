package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Double
import kotlin.Long
import kotlin.String
import kotlin.collections.Collection

public class BusinessTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> laodAll(mapper: (
    _id: String,
    BusinessNumber: Long?,
    UserID: String?,
    BusinessTypeID: String?,
    Name: String?,
    Address: String?,
    Location: String?,
    Latitude: Double?,
    Longitude: Double?,
    DealerName: String?,
    ProductTypes: String?,
    GSTNumber: String?,
    PanNumber: String?,
    GSTVerified: String?,
    PanVerified: String?,
    BusinessVerified: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    LastSeen: String?,
    IsPrimary: Boolean?,
    IsDeleted: Boolean?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = Query(814_315_725, arrayOf("BusinessTable"), driver, "BusinessTable.sq",
      "laodAll",
      "SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun laodAll(): Query<BusinessTable> = laodAll { _id, BusinessNumber, UserID,
      BusinessTypeID, Name, Address, Location, Latitude, Longitude, DealerName, ProductTypes,
      GSTNumber, PanNumber, GSTVerified, PanVerified, BusinessVerified, Status, EmailID,
      MobileNumber, DialCode, Gender, DeviceID, FCMToken, LastSeen, IsPrimary, IsDeleted, CreatedAt,
      UpdatedAt ->
    BusinessTable(
      _id,
      BusinessNumber,
      UserID,
      BusinessTypeID,
      Name,
      Address,
      Location,
      Latitude,
      Longitude,
      DealerName,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getMyBusiness(_id: Collection<String>, mapper: (
    _id: String,
    BusinessNumber: Long?,
    UserID: String?,
    BusinessTypeID: String?,
    Name: String?,
    Address: String?,
    Location: String?,
    Latitude: Double?,
    Longitude: Double?,
    DealerName: String?,
    ProductTypes: String?,
    GSTNumber: String?,
    PanNumber: String?,
    GSTVerified: String?,
    PanVerified: String?,
    BusinessVerified: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    LastSeen: String?,
    IsPrimary: Boolean?,
    IsDeleted: Boolean?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = GetMyBusinessQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun getMyBusiness(_id: Collection<String>): Query<BusinessTable> = getMyBusiness(_id) {
      _id_, BusinessNumber, UserID, BusinessTypeID, Name, Address, Location, Latitude, Longitude,
      DealerName, ProductTypes, GSTNumber, PanNumber, GSTVerified, PanVerified, BusinessVerified,
      Status, EmailID, MobileNumber, DialCode, Gender, DeviceID, FCMToken, LastSeen, IsPrimary,
      IsDeleted, CreatedAt, UpdatedAt ->
    BusinessTable(
      _id_,
      BusinessNumber,
      UserID,
      BusinessTypeID,
      Name,
      Address,
      Location,
      Latitude,
      Longitude,
      DealerName,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieve(UserID: String?, mapper: (
    _id: String,
    BusinessNumber: Long?,
    UserID: String?,
    BusinessTypeID: String?,
    Name: String?,
    Address: String?,
    Location: String?,
    Latitude: Double?,
    Longitude: Double?,
    DealerName: String?,
    ProductTypes: String?,
    GSTNumber: String?,
    PanNumber: String?,
    GSTVerified: String?,
    PanVerified: String?,
    BusinessVerified: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    LastSeen: String?,
    IsPrimary: Boolean?,
    IsDeleted: Boolean?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(UserID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun retrieve(UserID: String?): Query<BusinessTable> = retrieve(UserID) { _id,
      BusinessNumber, UserID_, BusinessTypeID, Name, Address, Location, Latitude, Longitude,
      DealerName, ProductTypes, GSTNumber, PanNumber, GSTVerified, PanVerified, BusinessVerified,
      Status, EmailID, MobileNumber, DialCode, Gender, DeviceID, FCMToken, LastSeen, IsPrimary,
      IsDeleted, CreatedAt, UpdatedAt ->
    BusinessTable(
      _id,
      BusinessNumber,
      UserID_,
      BusinessTypeID,
      Name,
      Address,
      Location,
      Latitude,
      Longitude,
      DealerName,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getBusinessById(_id: String, mapper: (
    _id: String,
    BusinessNumber: Long?,
    UserID: String?,
    BusinessTypeID: String?,
    Name: String?,
    Address: String?,
    Location: String?,
    Latitude: Double?,
    Longitude: Double?,
    DealerName: String?,
    ProductTypes: String?,
    GSTNumber: String?,
    PanNumber: String?,
    GSTVerified: String?,
    PanVerified: String?,
    BusinessVerified: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    LastSeen: String?,
    IsPrimary: Boolean?,
    IsDeleted: Boolean?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = GetBusinessByIdQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun getBusinessById(_id: String): Query<BusinessTable> = getBusinessById(_id) { _id_,
      BusinessNumber, UserID, BusinessTypeID, Name, Address, Location, Latitude, Longitude,
      DealerName, ProductTypes, GSTNumber, PanNumber, GSTVerified, PanVerified, BusinessVerified,
      Status, EmailID, MobileNumber, DialCode, Gender, DeviceID, FCMToken, LastSeen, IsPrimary,
      IsDeleted, CreatedAt, UpdatedAt ->
    BusinessTable(
      _id_,
      BusinessNumber,
      UserID,
      BusinessTypeID,
      Name,
      Address,
      Location,
      Latitude,
      Longitude,
      DealerName,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getBusinessByNumber(BusinessNumber: Long?, mapper: (
    _id: String,
    BusinessNumber: Long?,
    UserID: String?,
    BusinessTypeID: String?,
    Name: String?,
    Address: String?,
    Location: String?,
    Latitude: Double?,
    Longitude: Double?,
    DealerName: String?,
    ProductTypes: String?,
    GSTNumber: String?,
    PanNumber: String?,
    GSTVerified: String?,
    PanVerified: String?,
    BusinessVerified: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    LastSeen: String?,
    IsPrimary: Boolean?,
    IsDeleted: Boolean?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = GetBusinessByNumberQuery(BusinessNumber) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun getBusinessByNumber(BusinessNumber: Long?): Query<BusinessTable> =
      getBusinessByNumber(BusinessNumber) { _id, BusinessNumber_, UserID, BusinessTypeID, Name,
      Address, Location, Latitude, Longitude, DealerName, ProductTypes, GSTNumber, PanNumber,
      GSTVerified, PanVerified, BusinessVerified, Status, EmailID, MobileNumber, DialCode, Gender,
      DeviceID, FCMToken, LastSeen, IsPrimary, IsDeleted, CreatedAt, UpdatedAt ->
    BusinessTable(
      _id,
      BusinessNumber_,
      UserID,
      BusinessTypeID,
      Name,
      Address,
      Location,
      Latitude,
      Longitude,
      DealerName,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getPrimary(UserID: String?, mapper: (
    _id: String,
    BusinessNumber: Long?,
    UserID: String?,
    BusinessTypeID: String?,
    Name: String?,
    Address: String?,
    Location: String?,
    Latitude: Double?,
    Longitude: Double?,
    DealerName: String?,
    ProductTypes: String?,
    GSTNumber: String?,
    PanNumber: String?,
    GSTVerified: String?,
    PanVerified: String?,
    BusinessVerified: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    LastSeen: String?,
    IsPrimary: Boolean?,
    IsDeleted: Boolean?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = GetPrimaryQuery(UserID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun getPrimary(UserID: String?): Query<BusinessTable> = getPrimary(UserID) { _id,
      BusinessNumber, UserID_, BusinessTypeID, Name, Address, Location, Latitude, Longitude,
      DealerName, ProductTypes, GSTNumber, PanNumber, GSTVerified, PanVerified, BusinessVerified,
      Status, EmailID, MobileNumber, DialCode, Gender, DeviceID, FCMToken, LastSeen, IsPrimary,
      IsDeleted, CreatedAt, UpdatedAt ->
    BusinessTable(
      _id,
      BusinessNumber,
      UserID_,
      BusinessTypeID,
      Name,
      Address,
      Location,
      Latitude,
      Longitude,
      DealerName,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> getLatestDate(mapper: (UpdatedAt: String?) -> T): Query<T> =
      Query(-976_927_519, arrayOf("BusinessTable"), driver, "BusinessTable.sq", "getLatestDate",
      "SELECT UpdatedAt FROM BusinessTable ORDER BY UpdatedAt DESC LIMIT  1") { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun getLatestDate(): Query<GetLatestDate> = getLatestDate { UpdatedAt ->
    GetLatestDate(
      UpdatedAt
    )
  }

  public fun <T : Any> search(
    Name: String,
    Address: String,
    DealerName: String,
    mapper: (
      _id: String,
      BusinessNumber: Long?,
      UserID: String?,
      BusinessTypeID: String?,
      Name: String?,
      Address: String?,
      Location: String?,
      Latitude: Double?,
      Longitude: Double?,
      DealerName: String?,
      ProductTypes: String?,
      GSTNumber: String?,
      PanNumber: String?,
      GSTVerified: String?,
      PanVerified: String?,
      BusinessVerified: String?,
      Status: String?,
      EmailID: String?,
      MobileNumber: String?,
      DialCode: String?,
      Gender: Long?,
      DeviceID: String?,
      FCMToken: String?,
      LastSeen: String?,
      IsPrimary: Boolean?,
      IsDeleted: Boolean?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = SearchQuery(Name, Address, DealerName) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun search(
    Name: String,
    Address: String,
    DealerName: String,
  ): Query<BusinessTable> = search(Name, Address, DealerName) { _id, BusinessNumber, UserID,
      BusinessTypeID, Name_, Address_, Location, Latitude, Longitude, DealerName_, ProductTypes,
      GSTNumber, PanNumber, GSTVerified, PanVerified, BusinessVerified, Status, EmailID,
      MobileNumber, DialCode, Gender, DeviceID, FCMToken, LastSeen, IsPrimary, IsDeleted, CreatedAt,
      UpdatedAt ->
    BusinessTable(
      _id,
      BusinessNumber,
      UserID,
      BusinessTypeID,
      Name_,
      Address_,
      Location,
      Latitude,
      Longitude,
      DealerName_,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> nearby(
    Latitude: Double?,
    Latitude_: Double?,
    Longitude: Double?,
    Longitude_: Double?,
    mapper: (
      _id: String,
      BusinessNumber: Long?,
      UserID: String?,
      BusinessTypeID: String?,
      Name: String?,
      Address: String?,
      Location: String?,
      Latitude: Double?,
      Longitude: Double?,
      DealerName: String?,
      ProductTypes: String?,
      GSTNumber: String?,
      PanNumber: String?,
      GSTVerified: String?,
      PanVerified: String?,
      BusinessVerified: String?,
      Status: String?,
      EmailID: String?,
      MobileNumber: String?,
      DialCode: String?,
      Gender: Long?,
      DeviceID: String?,
      FCMToken: String?,
      LastSeen: String?,
      IsPrimary: Boolean?,
      IsDeleted: Boolean?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = NearbyQuery(Latitude, Latitude_, Longitude, Longitude_) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun nearby(
    Latitude: Double?,
    Latitude_: Double?,
    Longitude: Double?,
    Longitude_: Double?,
  ): Query<BusinessTable> = nearby(Latitude, Latitude_, Longitude, Longitude_) { _id,
      BusinessNumber, UserID, BusinessTypeID, Name, Address, Location, Latitude__, Longitude__,
      DealerName, ProductTypes, GSTNumber, PanNumber, GSTVerified, PanVerified, BusinessVerified,
      Status, EmailID, MobileNumber, DialCode, Gender, DeviceID, FCMToken, LastSeen, IsPrimary,
      IsDeleted, CreatedAt, UpdatedAt ->
    BusinessTable(
      _id,
      BusinessNumber,
      UserID,
      BusinessTypeID,
      Name,
      Address,
      Location,
      Latitude__,
      Longitude__,
      DealerName,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> findNearby(
    Name: String,
    Address: String,
    DealerName: String,
    Latitude: Double?,
    Latitude_: Double?,
    Longitude: Double?,
    Longitude_: Double?,
    `value`: Double,
    value_: Double,
    mapper: (
      _id: String,
      BusinessNumber: Long?,
      UserID: String?,
      BusinessTypeID: String?,
      Name: String?,
      Address: String?,
      Location: String?,
      Latitude: Double?,
      Longitude: Double?,
      DealerName: String?,
      ProductTypes: String?,
      GSTNumber: String?,
      PanNumber: String?,
      GSTVerified: String?,
      PanVerified: String?,
      BusinessVerified: String?,
      Status: String?,
      EmailID: String?,
      MobileNumber: String?,
      DialCode: String?,
      Gender: Long?,
      DeviceID: String?,
      FCMToken: String?,
      LastSeen: String?,
      IsPrimary: Boolean?,
      IsDeleted: Boolean?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = FindNearbyQuery(Name, Address, DealerName, Latitude, Latitude_, Longitude,
      Longitude_, value, value_) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getDouble(7),
      cursor.getDouble(8),
      cursor.getString(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getString(14),
      cursor.getString(15),
      cursor.getString(16),
      cursor.getString(17),
      cursor.getString(18),
      cursor.getString(19),
      cursor.getLong(20),
      cursor.getString(21),
      cursor.getString(22),
      cursor.getString(23),
      cursor.getBoolean(24),
      cursor.getBoolean(25),
      cursor.getString(26),
      cursor.getString(27)
    )
  }

  public fun findNearby(
    Name: String,
    Address: String,
    DealerName: String,
    Latitude: Double?,
    Latitude_: Double?,
    Longitude: Double?,
    Longitude_: Double?,
    value_: Double,
    value__: Double,
  ): Query<BusinessTable> = findNearby(Name, Address, DealerName, Latitude, Latitude_, Longitude,
      Longitude_, value_, value__) { _id, BusinessNumber, UserID, BusinessTypeID, Name_, Address_,
      Location, Latitude__, Longitude__, DealerName_, ProductTypes, GSTNumber, PanNumber,
      GSTVerified, PanVerified, BusinessVerified, Status, EmailID, MobileNumber, DialCode, Gender,
      DeviceID, FCMToken, LastSeen, IsPrimary, IsDeleted, CreatedAt, UpdatedAt ->
    BusinessTable(
      _id,
      BusinessNumber,
      UserID,
      BusinessTypeID,
      Name_,
      Address_,
      Location,
      Latitude__,
      Longitude__,
      DealerName_,
      ProductTypes,
      GSTNumber,
      PanNumber,
      GSTVerified,
      PanVerified,
      BusinessVerified,
      Status,
      EmailID,
      MobileNumber,
      DialCode,
      Gender,
      DeviceID,
      FCMToken,
      LastSeen,
      IsPrimary,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    BusinessNumber: Long?,
    UserID: String?,
    BusinessTypeID: String?,
    Name: String?,
    Address: String?,
    Location: String?,
    Latitude: Double?,
    Longitude: Double?,
    DealerName: String?,
    ProductTypes: String?,
    GSTNumber: String?,
    PanNumber: String?,
    GSTVerified: String?,
    PanVerified: String?,
    BusinessVerified: String?,
    Status: String?,
    EmailID: String?,
    MobileNumber: String?,
    DialCode: String?,
    Gender: Long?,
    DeviceID: String?,
    FCMToken: String?,
    LastSeen: String?,
    IsPrimary: Boolean?,
    IsDeleted: Boolean?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(1_337_981_539, """
        |INSERT OR REPLACE INTO BusinessTable( _id,BusinessNumber, UserID, BusinessTypeID, Name,Address,Location,Latitude,Longitude,DealerName,ProductTypes,GSTNumber,PanNumber,GSTVerified,PanVerified,BusinessVerified,Status,EmailID,MobileNumber,DialCode,Gender,DeviceID,FCMToken,LastSeen,IsPrimary,IsDeleted,CreatedAt,UpdatedAt)
        |    VALUES(?, ?, ?,  ? ,?, ?, ?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)
        """.trimMargin(), 28) {
          bindString(0, _id)
          bindLong(1, BusinessNumber)
          bindString(2, UserID)
          bindString(3, BusinessTypeID)
          bindString(4, Name)
          bindString(5, Address)
          bindString(6, Location)
          bindDouble(7, Latitude)
          bindDouble(8, Longitude)
          bindString(9, DealerName)
          bindString(10, ProductTypes)
          bindString(11, GSTNumber)
          bindString(12, PanNumber)
          bindString(13, GSTVerified)
          bindString(14, PanVerified)
          bindString(15, BusinessVerified)
          bindString(16, Status)
          bindString(17, EmailID)
          bindString(18, MobileNumber)
          bindString(19, DialCode)
          bindLong(20, Gender)
          bindString(21, DeviceID)
          bindString(22, FCMToken)
          bindString(23, LastSeen)
          bindBoolean(24, IsPrimary)
          bindBoolean(25, IsDeleted)
          bindString(26, CreatedAt)
          bindString(27, UpdatedAt)
        }.await()
    notifyQueries(1_337_981_539) { emit ->
      emit("BusinessTable")
    }
  }

  private inner class GetMyBusinessQuery<out T : Any>(
    public val _id: Collection<String>,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BusinessTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BusinessTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> {
      val _idIndexes = createArguments(count = _id.size)
      return driver.executeQuery(null,
          """SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable WHERE _id IN $_idIndexes ORDER BY UpdatedAt DESC""",
          mapper, _id.size) {
            _id.forEachIndexed { index, _id_ ->
              bindString(index, _id_)
            }
          }
    }

    override fun toString(): String = "BusinessTable.sq:getMyBusiness"
  }

  private inner class RetrieveQuery<out T : Any>(
    public val UserID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BusinessTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BusinessTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable WHERE UserID ${ if (UserID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, UserID)
    }

    override fun toString(): String = "BusinessTable.sq:retrieve"
  }

  private inner class GetBusinessByIdQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BusinessTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BusinessTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_265_961_406,
        """SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable WHERE _id = ? LIMIT  1""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "BusinessTable.sq:getBusinessById"
  }

  private inner class GetBusinessByNumberQuery<out T : Any>(
    public val BusinessNumber: Long?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BusinessTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BusinessTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable WHERE BusinessNumber ${ if (BusinessNumber == null) "IS" else "=" } ? LIMIT  1""",
        mapper, 1) {
      bindLong(0, BusinessNumber)
    }

    override fun toString(): String = "BusinessTable.sq:getBusinessByNumber"
  }

  private inner class GetPrimaryQuery<out T : Any>(
    public val UserID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BusinessTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BusinessTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable WHERE UserID ${ if (UserID == null) "IS" else "=" } ? AND IsPrimary = 1 LIMIT 1""",
        mapper, 1) {
      bindString(0, UserID)
    }

    override fun toString(): String = "BusinessTable.sq:getPrimary"
  }

  private inner class SearchQuery<out T : Any>(
    public val Name: String,
    public val Address: String,
    public val DealerName: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BusinessTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BusinessTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_615_437_138,
        """SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable WHERE IsDeleted = 0 AND Address != "null" AND Address NOT NULL AND (Name LIKE ? COLLATE NOCASE OR Address LIKE ? COLLATE NOCASE OR DealerName LIKE ? COLLATE NOCASE) ORDER BY UpdatedAt DESC""",
        mapper, 3) {
      bindString(0, Name)
      bindString(1, Address)
      bindString(2, DealerName)
    }

    override fun toString(): String = "BusinessTable.sq:search"
  }

  private inner class NearbyQuery<out T : Any>(
    public val Latitude: Double?,
    public val Latitude_: Double?,
    public val Longitude: Double?,
    public val Longitude_: Double?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BusinessTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BusinessTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_472_291_369,
        """SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable WHERE Address NOT NULL AND Address != "null"  AND (Latitude BETWEEN ? AND ?) AND (Longitude BETWEEN ? AND ?)""",
        mapper, 4) {
      bindDouble(0, Latitude)
      bindDouble(1, Latitude_)
      bindDouble(2, Longitude)
      bindDouble(3, Longitude_)
    }

    override fun toString(): String = "BusinessTable.sq:nearby"
  }

  private inner class FindNearbyQuery<out T : Any>(
    public val Name: String,
    public val Address: String,
    public val DealerName: String,
    public val Latitude: Double?,
    public val Latitude_: Double?,
    public val Longitude: Double?,
    public val Longitude_: Double?,
    public val `value`: Double,
    public val value_: Double,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("BusinessTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("BusinessTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-468_488_126,
        """SELECT BusinessTable._id, BusinessTable.BusinessNumber, BusinessTable.UserID, BusinessTable.BusinessTypeID, BusinessTable.Name, BusinessTable.Address, BusinessTable.Location, BusinessTable.Latitude, BusinessTable.Longitude, BusinessTable.DealerName, BusinessTable.ProductTypes, BusinessTable.GSTNumber, BusinessTable.PanNumber, BusinessTable.GSTVerified, BusinessTable.PanVerified, BusinessTable.BusinessVerified, BusinessTable.Status, BusinessTable.EmailID, BusinessTable.MobileNumber, BusinessTable.DialCode, BusinessTable.Gender, BusinessTable.DeviceID, BusinessTable.FCMToken, BusinessTable.LastSeen, BusinessTable.IsPrimary, BusinessTable.IsDeleted, BusinessTable.CreatedAt, BusinessTable.UpdatedAt FROM BusinessTable WHERE IsDeleted = 0 AND Address NOT NULL AND (Name LIKE ? COLLATE NOCASE OR Address LIKE ? COLLATE NOCASE OR DealerName LIKE ? COLLATE NOCASE) AND (Latitude-?)*(Latitude-?) + (Longitude-?)*(Longitude-?) < ?*?""",
        mapper, 9) {
      bindString(0, Name)
      bindString(1, Address)
      bindString(2, DealerName)
      bindDouble(3, Latitude)
      bindDouble(4, Latitude_)
      bindDouble(5, Longitude)
      bindDouble(6, Longitude_)
      bindDouble(7, value)
      bindDouble(8, value_)
    }

    override fun toString(): String = "BusinessTable.sq:findNearby"
  }
}
