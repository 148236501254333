package feature.invoice.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveInvoiceRequest(
    @SerialName("BusinessID") val businessID: String,
    @SerialName("LastSyncDate") val lastSyncDate: String?
)

@Serializable
data class RetrieveSingleInvoiceRequest(
    @SerialName("InvoiceId") val invoiceId: Long,
)
