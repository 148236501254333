package feature.businessConnection.network

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class BusinessConnectionRetrieveRequest(
    @SerialName("BusinessID") val businessId: String?,
    @SerialName("UserID") val userId: String?,
)

@Serializable
data class BusinessConnectionCreateRequest(
    @SerialName("FromUserID") val fromUserId: String,
    @SerialName("FromBusinessID") val fromBusinessId: String,
    @SerialName("ToUserID") val toUserId: String,
    @SerialName("ToBusinessID") val toBusinessId: String,
    @SerialName("Name") val name: String,
)