package component.navigation

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.Add
import androidx.compose.material.icons.rounded.ArrowDropDown
import androidx.compose.material.icons.rounded.Edit
import androidx.compose.material.icons.rounded.Notifications
import androidx.compose.material.icons.rounded.PinDrop
import androidx.compose.material.icons.rounded.ShoppingCart
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import feature.address.model.Address
import feature.address.viewModel.AddressViewModel
import feature.business.utill.AddressUtill
import navigation.AppNavigator
import navigation.containers.ScreenName
import theme.ThemeColor

@Composable
fun CustomerHomeTopNavigation(
    address: Address? = null,
    showNotification: Boolean = true,
    onClickAdd: (() -> Unit)? = null,
    onClickEdit: (() -> Unit)? = null,
    onClickCart: (() -> Unit)? = null,
    refresh: () -> Unit = {},
    onClickAddress: () -> Unit
) {

    val viewModel = AddressViewModel()
    val selectedAddress = viewModel.primaryAddress.collectAsState()
    val addressToDisplay = AddressUtill().getAddressToDisplayInSingleLine(selectedAddress.value)
    LaunchedEffect(key1 = Unit) {
        viewModel.setPrimaryAddressForCustomer()
        viewModel.retrieve {
            viewModel.setPrimaryAddressForCustomer()
            refresh()
        }
    }
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.dp)
    ) {
        Row(
            modifier = Modifier.fillMaxHeight().fillMaxWidth()
                .background(ThemeColor.shared().PrimaryColor),

            verticalAlignment = Alignment.CenterVertically
        ) {

            Box(
                modifier = Modifier
                    .fillMaxHeight().fillMaxWidth().weight(0.85f)
            ) {
                Row(modifier = Modifier.fillMaxHeight().fillMaxWidth()) {
                    Column(
                        modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.7f)
                            .clickable { onClickAddress() }) {
                        Row(
                            modifier = Modifier.fillMaxWidth().height(50.dp),
                            horizontalArrangement = Arrangement.Start,
                        ) {
                            Box(
                                modifier = Modifier
                                    .width(50.dp).height(50.dp).padding(end = 10.dp)
                            ) {
                                IconButton(
                                    onClick = {

                                    },
                                    modifier = Modifier
                                        .width(50.dp).height(50.dp)
                                ) {
                                    Icon(
                                        Icons.Rounded.PinDrop,
                                        contentDescription = "Location",
                                        tint = ThemeColor.shared().White,
                                    )
                                }
                            }
                            Text(
                                "Home",
                                modifier = Modifier.height(50.dp).padding(top = 12.dp),
                                textAlign = TextAlign.Center,
                                fontStyle = FontStyle.Normal,
                                fontWeight = FontWeight(700),
                                color = ThemeColor.shared().White
                            )
                            Box(
                                modifier = Modifier
                                    .width(50.dp).height(50.dp).padding(end = 10.dp)
                            ) {
                                IconButton(
                                    onClick = {

                                    },
                                    modifier = Modifier
                                        .width(50.dp).height(50.dp)
                                ) {
                                    Icon(
                                        Icons.Rounded.ArrowDropDown,
                                        contentDescription = "Drop Down",
                                        tint = ThemeColor.shared().White,
                                    )
                                }
                            }
                        }
                        Text(
                            addressToDisplay,
                            modifier = Modifier.fillMaxWidth()
                                .padding(start = 50.dp),
                            textAlign = TextAlign.Start,
                            fontStyle = FontStyle.Normal,
                            fontWeight = FontWeight(500),
                            color = ThemeColor.shared().White
                        )
                    }

                    Row(
                        modifier = Modifier
                            .wrapContentWidth().height(50.dp).weight(0.25f)
                    ) {
                        if (onClickAdd != null) {
                            IconButton(
                                onClick = {
                                    onClickAdd()
                                },
                                modifier = Modifier
                                    .width(50.dp).height(50.dp)
                            ) {
                                Icon(
                                    Icons.Rounded.Add,
                                    contentDescription = "add new items",
                                    tint = ThemeColor.shared().White,
                                )
                            }
                        }
                        if (onClickEdit != null) {
                            IconButton(
                                onClick = {
                                    onClickEdit()
                                },
                                modifier = Modifier
                                    .width(50.dp).height(50.dp)
                            ) {
                                Icon(
                                    Icons.Rounded.Edit,
                                    contentDescription = "edit item",
                                    tint = ThemeColor.shared().White,
                                )
                            }
                        }
                        if (onClickCart != null) {
                            IconButton(
                                onClick = {
                                    onClickCart()
                                },
                                modifier = Modifier
                                    .width(50.dp).height(50.dp)
                            ) {
                                Icon(
                                    Icons.Rounded.ShoppingCart,
                                    contentDescription = "cart",
                                    tint = ThemeColor.shared().White,
                                )
                            }
                        }
                        if (showNotification) {
                            IconButton(
                                onClick = {
                                    AppNavigator.shared()
                                        .navigateTo(
                                            ScreenName.BUSINESS_NOTIFICATION
                                        )
                                },
                                modifier = Modifier
                                    .width(50.dp).height(50.dp)
                            ) {
                                Icon(
                                    Icons.Rounded.Notifications,
                                    contentDescription = "Notification",
                                    tint = ThemeColor.shared().White,
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}