package component.visual

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import theme.ThemeColor

@Composable
fun DottedLine() {
    Row(
        modifier = Modifier
            .height(12.dp).fillMaxWidth().padding(0.dp),
//            .background(color = ThemeColor.shared().PrimaryColor),
        verticalAlignment = Alignment.CenterVertically,

        ) {
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
        Box(
            modifier = Modifier.height(2.dp).width(16.dp).padding(start = 8.dp)
                .background(
                    color = ThemeColor.shared().dottedLineColor,
                    shape = RoundedCornerShape(2.dp)
                )
        )
    }

}