package presentation.screens.common.notification

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import component.navigation.TopNavBar
import feature.notification.viewModel.NotificationViewModel
import presentation.screens.common.notification.component.NotificationCard
import theme.ThemeColor

class BusinessNotificationScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = NotificationViewModel()
        val allNotifications = viewModel.allData.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.retrieve()
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar("Notifications", null, onBackPress = {
                navigator?.pop()
            })
            LazyColumn(
                modifier = Modifier.fillMaxWidth().fillMaxHeight().padding(bottom = 60.dp)
                    .background(
                        ThemeColor.shared().NotificationBg
                    )
            ) {
                items(allNotifications.value.count()) { index ->
                    NotificationCard(allNotifications.value[index], viewModel)
                }
            }
        }
    }
}