package component.input

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import theme.ThemeColor

@Composable
fun TextInputField(
    title: String = "",
    placeholder: String,
    value: String = "",
    type: KeyboardType = KeyboardType.Text,
    onChange: (String) -> Unit
) {
    var text by remember { mutableStateOf(value) }
    Column(
        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(top = 4.dp, bottom = 4.dp)
    ) {
        if (title.isNotEmpty()) {
            TextTitle(title)
        }
        TextField(
            value = text,
            onValueChange = {
                text = it
                onChange(it)
            },
            placeholder = { Text(placeholder) },
            colors = TextFieldDefaults.colors(
                focusedIndicatorColor = Color.Transparent,
                unfocusedIndicatorColor = Color.Transparent,
                disabledIndicatorColor = Color.Transparent,
                focusedContainerColor = ThemeColor.shared().defaultBakground,
                unfocusedContainerColor = ThemeColor.shared().defaultBakground,
            ),
            maxLines = 1,
            singleLine = true,
            keyboardOptions = KeyboardOptions.Default.copy(
                keyboardType = type,
                capitalization = KeyboardCapitalization.Words
            ),
            modifier = Modifier.fillMaxWidth()
                .background(color = ThemeColor.shared().defaultBakground, RoundedCornerShape(10.dp))
                .border(
                    width = 1.dp,
                    brush = Brush.horizontalGradient(
                        listOf(
                            ThemeColor.shared().customerTheme,
                            ThemeColor.shared().businessTheme,
                        )
                    ),
                    shape = CircleShape
                )
        )
    }
}