package feature.business.network

import feature.business.model.Business
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveBusinessResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<Business>,
)

@Serializable
data class RetrieveSingleBusinessResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: Business,
)


@Serializable
data class CreateBusinessResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: Business,
)

@Serializable
data class FindBusinessResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<Business>,
)