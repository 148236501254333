package com.friendly.common.database

import kotlin.Boolean
import kotlin.Double
import kotlin.Long
import kotlin.String

public data class InvoiceTable(
  public val _id: String,
  public val InvoiceID: Long?,
  public val ShareLink: String?,
  public val UserID: String?,
  public val BusinessID: String?,
  public val CustomerID: String?,
  public val CustomerName: String?,
  public val DialCode: String?,
  public val MobileNumber: String?,
  public val IsDeleted: Boolean,
  public val Sales: String?,
  public val InvoiceNumber: Long?,
  public val InvoiceType: String?,
  public val TotalPrice: Double?,
  public val GST: Double?,
  public val SGST: Double?,
  public val CGST: Double?,
  public val IGST: Double?,
  public val CESS: Double?,
  public val VAT: Double?,
  public val Tax: Double?,
  public val CostPrice: Double?,
  public val InstantDiscount: Double?,
  public val FinalPrice: Double?,
  public val PendingAmount: Double?,
  public val ClearedAmount: Double?,
  public val InvoiceDate: String?,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
  public val __v: Long?,
)
