package storage.database.table.inventory.tag

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.TagTable
import feature.inventory.model.tag.Tag
import kotlinx.coroutines.launch
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class TagDatabaseTable : DatabaseTable() {
    fun insert(item: Tag?) {
        val table = AppDatabase.shared().database?.tagTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    _id = item.Id,
                    TagID = item.TagID,
                    UserID = item.UserID,
                    BusinessID = item.BusinessID,
                    IsDeleted = item.IsDeleted,
                    Name = item.Name,
                    Value = item.Value,
                    Description = item.Description,
                    CreatedAt = item.CreatedAt,
                    UpdatedAt = item.UpdatedAt,
                )
            }
        }
    }

    fun insert(items: List<Tag>?) {
        val table = AppDatabase.shared().database?.tagTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    _id = item.Id,
                    TagID = item.TagID,
                    UserID = item.UserID,
                    BusinessID = item.BusinessID,
                    IsDeleted = item.IsDeleted,
                    Name = item.Name,
                    Value = item.Value,
                    Description = item.Description,
                    CreatedAt = item.CreatedAt,
                    UpdatedAt = item.UpdatedAt,
                )
            }
        }
    }

    fun retrieve(id: String, callback: (List<Tag>) -> Unit) {
        val table = AppDatabase.shared().database?.tagTableQueries
        val itemsFetched: ArrayList<Tag> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun retrieveAll(callback: (List<Tag>) -> Unit) {
        val table = AppDatabase.shared().database?.tagTableQueries
        val itemsFetched: ArrayList<Tag> = arrayListOf()
        scope.launch {
            val items = table?.retrieveAll()?.awaitAsList()
            items?.forEach { item ->
                itemsFetched.add(convert(item))
            }
            callback(itemsFetched)
        }
    }

    fun find(id: String, callback: (Tag?) -> Unit) {
        val table = AppDatabase.shared().database?.tagTableQueries
        var itemsFetched: Tag? = null
        scope.launch {
            val items = table?.find(id)?.awaitAsOneOrNull()
            items?.let { item ->
                itemsFetched = convert(item)
            }
            callback(itemsFetched)
        }
    }


    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.tagTableQueries
        scope.launch {
            val dateFromDb = table?.lastSyncDate(id)?.awaitAsOneOrNull()
            callback(dateFromDb?.UpdatedAt)
        }
    }

    fun convert(item: TagTable): Tag {
        return Tag(
            Id = item._id,
            TagID = item.TagID,
            UserID = item.UserID,
            BusinessID = item.BusinessID,
            IsDeleted = item.IsDeleted,
            Name = item.Name,
            Description = item.Description,
            CreatedAt = item.CreatedAt,
            UpdatedAt = item.UpdatedAt,
        )
    }
}