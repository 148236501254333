package feature.inventory.viewModel.tag

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.inventory.handler.TagHandler
import feature.inventory.model.tag.Tag
import feature.inventory.network.tag.RetrieveTagRequest
import feature.inventory.network.tag.TagNetwork
import feature.inventory.repository.TagRepository
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.tag.TagDatabaseTable

class TagViewModel(val repository: TagRepository = TagHandler.shared().repository) :
    AppViewModel() {

    val allTag: StateFlow<List<Tag>>
        get() = repository.tagLiveData

    val selectedTag: StateFlow<Tag?>
        get() = repository.selectedTag

    fun fetchAllTag() {
        loadFromStorage()
        val request =
            RetrieveTagRequest(BusinessHandler.shared().repository.business.value.Id)
        // startLoading()
        CoroutineScope(Job() + Dispatchers.Default).launch {
            TagNetwork().retrieve(request) {
                stopLoading()
                TagDatabaseTable().insert(it?.payload)
                loadFromStorage()
            }
        }
    }

    fun loadAllTag() {
        loadFromStorage()
        if(allTag.value.isEmpty()){
            fetchAllTag()
        }
    }

    private fun loadFromStorage() {
        TagDatabaseTable().retrieveAll { data ->
            scope.launch {
                repository.tagLiveData.emit(data)
            }
        }
    }
}