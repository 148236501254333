package feature.inventory.network.inventory

import feature.product.model.ProductPrice
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveInventoryRequest(
    @SerialName("BusinessID") val businessID: String,
    @SerialName("ProductID") val productID: String?,
    @SerialName("LastSyncDate") val lastSyncDate: String?,
)

@Serializable
data class CreateInventoryRequest(
    @SerialName("InventoryID") var InventoryID: Long? = null,
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("CategoryID") var categoryID: String? = null,
    @SerialName("SubCategoryID") var subCategoryID: String? = null,
    @SerialName("Name") var name: String = "",
    @SerialName("Description") var description: String = "",
    @SerialName("ProductID") var productID: String? = null,
    @SerialName("ProductPrice") var productPrice: ProductPrice? = ProductPrice(),
    @SerialName("Model") var model: String? = null,
    @SerialName("Barcode") var barcode: String? = null,
    @SerialName("BrandID") var brandID: String? = null,
    @SerialName("TagID") var tagID: String? = null,
    @SerialName("UOMID") var uomID: String? = null,
    @SerialName("ColorID") var colorID: String? = null,
    @SerialName("Quantity") var qty: Long? = null,
    @SerialName("SizeID") var sizeID: String? = null,
    @SerialName("DiscountID") var discountID: String? = null,
    @SerialName("CoupanID") var coupanID: String? = null,
    @SerialName("IsDeleted") var isDeleted: Boolean? = null,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)

@Serializable
data class UpdateInventoryRequest(
    @SerialName("_id") var _id: String? = null,
    @SerialName("InventoryID") var InventoryID: Long? = null,
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("CategoryID") var categoryID: String? = null,
    @SerialName("SubCategoryID") var subCategoryID: String? = null,
    @SerialName("Name") var name: String = "",
    @SerialName("Description") var description: String = "",
    @SerialName("ProductID") var productID: String? = null,
    @SerialName("ProductPrice") var productPrice: ProductPrice? = ProductPrice(),
    @SerialName("Model") var model: String? = null,
    @SerialName("Barcode") var barcode: String? = null,
    @SerialName("BrandID") var brandID: String? = null,
    @SerialName("TagID") var tagID: String? = null,
    @SerialName("UOMID") var uomID: String? = null,
    @SerialName("ColorID") var colorID: String? = null,
    @SerialName("Quantity") var qty: Long? = null,
    @SerialName("SizeID") var sizeID: String? = null,
    @SerialName("DiscountID") var discountID: String? = null,
    @SerialName("CoupanID") var coupanID: String? = null,
    @SerialName("IsDeleted") var isDeleted: Boolean? = null,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)

@Serializable
data class DeleteInventoryRequest(
    @SerialName("_id") var _id: String? = null
)