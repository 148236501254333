package feature.inventory.viewModel.coupon

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.inventory.handler.CoupanHandler
import feature.inventory.model.coupon.Coupon
import feature.inventory.network.coupon.CoupanNetwork
import feature.inventory.network.coupon.RetrieveCoupanRequest
import feature.inventory.repository.CoupanRepository
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.coupon.CouponDatabaseTable

class CoupanViewModel(val repository: CoupanRepository = CoupanHandler.shared().repository) :
    AppViewModel() {

    val allCoupan: StateFlow<List<Coupon>>
        get() = repository.coupanLiveData

    val selectedCoupan: StateFlow<Coupon?>
        get() = repository.selectedCoupan

    fun fetchAllCoupan() {
        loadFromStorage()
        val request =
            RetrieveCoupanRequest(BusinessHandler.shared().repository.business.value.Id)
        // startLoading()
        scope.launch {
            CoupanNetwork().retrieve(request) {
                stopLoading()
                CouponDatabaseTable().insert(it?.payload)
                loadFromStorage()
            }
        }
    }

    fun loadAllCoupan() {
        loadFromStorage()
        if (allCoupan.value.isEmpty()) {
            fetchAllCoupan()
        }
    }

    private fun loadFromStorage() {
        CouponDatabaseTable().retrieveAll { data ->
            scope.launch {
                repository.coupanLiveData.emit(data)
            }
        }
    }
}