package feature.inventory.viewModel.discount

import feature.app.AppViewModel
import feature.business.handler.BusinessHandler
import feature.inventory.handler.DiscountHandler
import feature.inventory.model.discount.Discount
import feature.inventory.network.discount.DiscountNetwork
import feature.inventory.network.discount.RetrieveDiscountRequest
import feature.inventory.repository.DiscountRepository
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.discount.DiscountDatabaseTable

class DiscountViewModel(val repository: DiscountRepository = DiscountHandler.shared().repository) :
    AppViewModel() {

    val allDiscount: StateFlow<List<Discount>>
        get() = repository.discountLiveData

    val selectedDiscount: StateFlow<Discount?>
        get() = repository.selectedDiscount

    fun fetchAllDiscount() {
        loadFromStorage()
        val request =
            RetrieveDiscountRequest(BusinessHandler.shared().repository.business.value.Id)
        // startLoading()
        scope.launch {
            DiscountNetwork().retrieve(request) {
                stopLoading()
                DiscountDatabaseTable().insert(it?.payload)
                loadFromStorage()
            }
        }
    }

    fun loadAllDiscount() {
        loadFromStorage()
        if (allDiscount.value.isEmpty()) {
            fetchAllDiscount()
        }
    }

    private fun loadFromStorage() {
        DiscountDatabaseTable().retrieveAll { data ->
            scope.launch {
                repository.discountLiveData.emit(data)
            }
        }
    }
}