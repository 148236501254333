package feature.business.viewModel

import common.constants.KeyConstant
import common.constants.UserDefaults
import feature.address.handler.AddressHandler
import feature.address.model.Address
import feature.address.model.LocationCoordinate
import feature.alert.handler.AlertHandler
import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.business.model.Business
import feature.business.network.BusinessNetwork
import feature.business.network.CreateBusinessRequest
import feature.business.network.FindBusinessRequest
import feature.business.network.FindNearbyBusinessRequest
import feature.business.network.RetrieveBusinessRequest
import feature.business.network.RetrieveSingleBusinessRequest
import feature.business.repository.BusinessRepository
import feature.businessConfig.viewModel.BusinessConfigViewModel
import feature.businessType.model.BusinessType
import feature.sync.BusinessAnalytics
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import storage.database.table.business.BusinessDatabaseTable

class BusinessViewModel :
    AppViewModel() {
    val json = Json { encodeDefaults = true }
    val repository: BusinessRepository = BusinessHandler.shared().repository
    val searchQuery: StateFlow<String>
        get() = repository.searchQuery
    val analytics: StateFlow<ArrayList<BusinessAnalytics>>
        get() = repository.analytics

    val selectedBusiness: StateFlow<Business>
        get() = repository.businessLiveData

    val allBusiness: StateFlow<List<Business>>
        get() = repository.allBusiness


    val createBusiness: StateFlow<Business>
        get() = repository.createBusiness

    val addBusinessConnectionSearchResult: StateFlow<List<Business>>
        get() = repository.addBusinessConnectionSearchResult

    val allNearbyBusiness: StateFlow<List<Business>>
        get() = repository.nearbyBusinessSearchResult

    override fun changeSearchQuery(value: String) {
        scope.launch {
            repository.searchQueryState.emit(value)
        }
        performSearch(value)
    }

    override fun performSearch(value: String) {
        if (value.isEmpty()) {
            loadBusiness()
        } else {
            BusinessDatabaseTable().search(
                value
            ) { data ->
                scope.launch {
                    repository.allBusinessLiveData.emit(data)
                    repository.nearbyBusinessSearchResultState.emit(data)
                    repository.nearbyBusinessState.emit(data)
                }
            }
        }
    }

    fun setLocationForNewBusinessAddress(locationCoordinate: LocationCoordinate?) {
        scope.launch {
            val businessToUpdate = createBusiness.value
            businessToUpdate.CreatedAt = "${Clock.System.now().epochSeconds.toInt()}"
            repository.createBusinessState.emit(Business())
            delay(500)
            if (businessToUpdate.Address == null) {
                businessToUpdate.Address = Address()
            }
            businessToUpdate.Address?.Location =
                locationCoordinate
            scope.launch {
                val newAddress = businessToUpdate.Address
                newAddress?.Location = locationCoordinate
                businessToUpdate.Address = newAddress
                repository.createBusinessState.emit(businessToUpdate)
            }
        }
    }

    fun setNameForNewBusiness(name: String) {
        val businessToUpdate = createBusiness.value
        businessToUpdate.Name = name
        scope.launch {
            repository.createBusinessState.emit(businessToUpdate)
        }
    }

    fun setEmailForNewBusiness(email: String) {
        val businessToUpdate = createBusiness.value
        businessToUpdate.EmailID = email
        scope.launch {
            repository.createBusinessState.emit(businessToUpdate)
        }
    }

    fun setMobileForNewBusiness(mobile: String) {
        val businessToUpdate = createBusiness.value
        businessToUpdate.mobileNumber = mobile
        scope.launch {
            repository.createBusinessState.emit(businessToUpdate)
        }
    }


    fun setDialCodeForNewBusiness(dialCode: String) {
        val businessToUpdate = createBusiness.value
        businessToUpdate.DialCode = dialCode
        scope.launch {
            repository.createBusinessState.emit(businessToUpdate)
        }
    }


    fun loadBusiness() {
        val user = AuthHandler.shared().repository.authLiveData.value
        if (user.mobile != null) {
            val request = RetrieveBusinessRequest(
                userId = user.id,
                mobile = user.mobile!!,
                dialCode = user.DialCode!!
            )
            startLoading()
            BusinessNetwork().retrieve(request) {
                stopLoading()
                if (!it?.payload.isNullOrEmpty()) {
                    BusinessDatabaseTable().insert(it?.payload) {
                        loadBusinessFromDatabase()
                        stopLoading()
                    }
                } else {
                    loadBusinessFromDatabase()
                    stopLoading()
                }
            }
        }
    }

    private fun loadBusinessFromDatabase() {
        BusinessDatabaseTable().retrieve(AuthHandler.shared().userId) { data ->
            scope.launch {
                repository.allBusinessLiveData.emit(data)
            }
        }
    }

    fun getBusinessById(id: String, callBack: (Business?) -> Unit) {
        retrieveFromDb(id, callBack)
        val user = AuthHandler.shared().repository.authLiveData.value
        if (user.mobile != null) {
            val request = RetrieveSingleBusinessRequest(
                id
            )
//            startLoading()
            BusinessNetwork().retrieveSingleBusiness(request) {
//                stopLoading()
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    BusinessDatabaseTable().insert(it?.payload) {
                        retrieveFromDb(id, callBack)
                    }
                } else {
                    AlertHandler.shared().showAlert("Oops!", "Store not found")
                }
            }
        }
    }

    fun getBusinessByNumber(number: Long, callBack: (Business?) -> Unit) {
        retrieveFromDb(number, callBack)
        val request = RetrieveSingleBusinessRequest(
            number = number
        )
        startLoading()
        BusinessNetwork().retrieveSingleBusiness(request) {
            stopLoading()
            if (!it?.payload?.Id.isNullOrEmpty()) {
                BusinessDatabaseTable().insert(it?.payload) {
                    retrieveFromDb(number, callBack)
                }
            } else {
                AlertHandler.shared().showAlert("Oops!", "Store not found")
            }
        }
    }

    private fun retrieveFromDb(id: String, callBack: (Business?) -> Unit) {
        scope.launch {
            BusinessDatabaseTable().retrieveBy(id, callBack)
        }
    }

    private fun retrieveFromDb(number: Long, callBack: (Business?) -> Unit) {
        scope.launch {
            BusinessDatabaseTable().retrieveBy(number, callBack)
        }
    }

    fun setSelectedBusiness(business: Business) {
        scope.launch {
            repository.businessLiveData.emit(business)
            delay(500)
            BusinessConfigViewModel().fetchBusinessConfig()
        }
        val businessString = json.encodeToString(business)
        UserDefaults.store(KeyConstant.selectedBusiness, businessString)
    }

    fun setSelectedStore(business: Business) {
        scope.launch {
            repository.businessLiveData.emit(business)
        }
        val businessString = json.encodeToString(business)
        UserDefaults.store(KeyConstant.selectedBusiness, businessString)
    }

    fun createNewBusiness(businessType: BusinessType?, completion: (Business?) -> Unit) {
        val userId = AuthHandler.shared().userId
        val unique = Clock.System.now()
        createBusiness.value.Address?.UserID = userId

        if (!businessType?.id.isNullOrEmpty() && !createBusiness.value.Name.isNullOrEmpty() && !createBusiness.value.EmailID.isNullOrEmpty() && createBusiness.value.Address != null) {
            startLoading()
            createBusiness.value.Location =
                LocationCoordinate(
                    coordinate = AddressHandler.shared().repository.pickedLocation.value?.coordinate
                        ?: arrayListOf(),
                )
            if (createBusiness.value.Location?.coordinate?.count() == 2) {
                createBusiness.value.Location!!.latitude =
                    createBusiness.value.Location!!.coordinate.first()
                createBusiness.value.Location!!.longitude =
                    createBusiness.value.Location!!.coordinate.last()
                createBusiness.value.Address!!.Location = createBusiness.value.Location
            }
            createBusiness.value.Address!!.UserID = userId
            createBusiness.value.Address!!.UniqueID = unique.toEpochMilliseconds()
            val request = CreateBusinessRequest(
                businessTypeID = businessType!!.id!!,
                userId = userId,
                mobile = createBusiness.value.mobileNumber ?: "",
                dialCode = createBusiness.value.DialCode!!,
                email = createBusiness.value.EmailID!!,
                address = createBusiness.value.Address!!,
                name = createBusiness.value.Name!!
            )
            BusinessNetwork().create(request) {
                stopLoading()
                if (it?.payload != null && it.payload.Id.isNotEmpty()) {
                    val allBusiness = repository.allBusiness.value.toMutableList()
                    allBusiness.add(it.payload)
                    scope.launch {
                        repository.allBusinessLiveData.emit(allBusiness)
                        completion(it.payload)
                    }
                }
            }
        } else {
            AlertHandler.shared().showAlert("Oops!", "Please select business type")
        }
    }

    fun updateAddress(
        address: Address, onSuccess: (String) -> Unit, onError: (String) -> Unit
    ) {
//        val request = JSONObject()
//        val user = FriendlyUser()
//        val unixTime = System.currentTimeMillis()
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.address, Gson().toJsonTree(address))
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        request.put(KeyConstant.uniqueId, unixTime)
//        if (selectedBusiness.value != null) {
//            request.put(
//                KeyConstant.businessID,
//                selectedBusiness.value.Id
//            )
//        }
//        Network.shared().updateAddress(request, { payload ->
//            val businessToUpdate = selectedBusiness.value
//            businessToUpdate.Address = Gson().fromJson(payload.toString(), Address::class.java)
//            businessToUpdate.let { insertDatabase(it) }
//            CoroutineScope(Job() + Dispatchers.Main).launch {
//                payload?.let { onSuccess("Updated") }
//            }
//        }, {
//            CoroutineScope(Job() + Dispatchers.Main).launch {
//                onError("Updated")
//            }
//        }
//        )
    }

    fun updateInfo(
        business: Business, onSuccess: (String) -> Unit, onError: (String) -> Unit
    ) {
//        val request = JSONObject(Gson().toJson(business))
//        Network.shared().update(request, { payload ->
//            payload?.let {
//                insertDatabase(
//                    Gson().fromJson(
//                        payload.toString(),
//                        Business::class.java
//                    )
//                )
//            }
//            CoroutineScope(Job() + Dispatchers.Main).launch {
//                payload?.let { onSuccess("Updated") }
//            }
//        }, { msg ->
//            CoroutineScope(Job() + Dispatchers.Main).launch {
//                msg?.let { onError(it) }
//            }
//        }
//        )
    }


    fun insertDatabase(business: Business) {
        scope.launch {
//            DatabaseHandler.shared().database.businessDao().insert(business)
        }
    }

    fun removeBusinessFromDatabase(id: String) {
        scope.launch {
//            DatabaseHandler.shared().database.businessDao().delete(id)
        }
    }

    fun clearAll() {
        scope.launch {
//            DatabaseHandler.shared().database.businessDao().clearAll()
        }
    }

    fun deleteBusiness(
        business: Business
    ) {
//        val request = JSONObject()
//        val user = FriendlyUser()
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant._id, business.Id)
//        if (BusinessTypeHandler.shared().repository.businessType != null) {
//            request.put(
//                KeyConstant.businessTypeID,
//                BusinessTypeHandler.shared().repository.businessType!!.Id
//            )
//        }
//        SocketService.shared().send(SocketEvent.DELETE_BUSINESS, request)
    }

    fun findBusiness(dialCode: String, mobile: String, callBack: (List<Business>) -> Unit) {
        startLoading()
        val request = FindBusinessRequest(
            "",//Userid as null
            mobile,
            dialCode
        )
        scope.launch {
            BusinessNetwork().findBusiness(request) {
                stopLoading()
                if (it?.payload?.isNotEmpty() == true) {
                    scope.launch {
                        repository.addBusinessConnectionSearchResultState.emit(it.payload)
                    }
                    callBack(it.payload)
                } else {
                    scope.launch {
                        repository.addBusinessConnectionSearchResultState.emit(arrayListOf())
                    }
                    callBack(arrayListOf())
                }
            }
        }
    }

    fun findNearby(query: String, locationCoordinate: LocationCoordinate?) {
        loadNearby(query, locationCoordinate)
        var request: FindNearbyBusinessRequest? = null
        val primaryAddress = AddressHandler.shared().getPrimaryAddressForCustomer()
        if (locationCoordinate != null) {
            request = FindNearbyBusinessRequest(
                AuthHandler.shared().userId,
                locationCoordinate.coordinate.first(),
                locationCoordinate.coordinate.last()
            )
        } else if (primaryAddress?.Location != null) {
            request = FindNearbyBusinessRequest(
                AuthHandler.shared().userId,
                primaryAddress.Location!!.coordinate.first(),
                primaryAddress.Location!!.coordinate.last()
            )
        }
        if (request != null) {
            startLoading()
            scope.launch {
                BusinessNetwork().findNearby(request) {
                    if (it?.payload?.isNotEmpty() == true) {
                        BusinessDatabaseTable().insert(it.payload) {
                            stopLoading()
                            loadNearby(query, locationCoordinate)
                        }
                    } else {
                        stopLoading()
                        loadNearby(query, locationCoordinate)
                    }
                }
            }
        }
    }

    fun loadNearby(query: String, locationCoordinate: LocationCoordinate?) {
        BusinessDatabaseTable().findNearby(query, locationCoordinate) { result ->
            scope.launch {
                repository.nearbyBusinessSearchResultState.emit(result)
                repository.nearbyBusinessState.emit(result)
            }
        }
    }
}