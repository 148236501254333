package feature.productCategory.network

import common.constants.Server
import feature.productCategory.event.ProductCategoryEvent
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient


class ProductCategoryNetwork {
    val json = Json {
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: ProductCategoryNetwork? = null
        fun shared(): ProductCategoryNetwork {
            if (instance != null) {
                return instance as ProductCategoryNetwork
            } else {
                return ProductCategoryNetwork()
            }
        }
    }

    //    fun <T> buildService(service: Class<T>): T {
//        return FriendlyNetwork.shared().retrofit.create(service)
//    }
//
//    fun create(request: JSONObject, onResult: (JSONObject?) -> Unit) {
//        val retrofit = buildService(ProductCategoryNetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            request.toString().toRequestBody("application/json".toMediaTypeOrNull())
//        retrofit.create(bodyRequest).enqueue(
//            ResponseHandler().getRestHandler(onResult)
//        )
//    }
//
    suspend fun retrieve(
        request: RetrieveProductCategoryRequest,
        onResult: (RetrieveProductCategoryResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(ProductCategoryEvent.RETRIEVE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }

    suspend fun create(
        request: CreateProductCategoryRequest,
        onResult: (CreateProductCategoryResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(ProductCategoryEvent.CREATE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }

    suspend fun update(
        request: UpdateProductCategoryRequest,
        onResult: (UpdateProductCategoryResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(ProductCategoryEvent.UPDATE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }
}