package native.share

class ShareFeature {
    companion object {
        private var instance: ShareFeature? = null
        fun shared(): ShareFeature {
            return if (instance != null) {
                instance as ShareFeature
            } else {
                instance = ShareFeature()
                instance as ShareFeature
            }
        }
    }

    init {
        instance = this
    }

    var shareText: (String) -> Unit = {}
    var shareToMessage: ((String, String) -> Unit?)? = null
    var shareToWhatsapp: ((String, String) -> Unit?)? = null
    var shareToPrint: ((String, String) -> Unit?)? = null
}