package com.friendly.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class EmployeeTable(
  public val _id: String,
  public val UserID: String?,
  public val EmployeeUserID: String?,
  public val BusinessID: String?,
  public val IsDeleted: Boolean,
  public val Name: String?,
  public val Address: String?,
  public val GSTNumber: String?,
  public val Barcode: String?,
  public val Status: String?,
  public val EmailID: String?,
  public val MobileNumber: String?,
  public val WhatsApp: String?,
  public val DialCode: String?,
  public val Gender: Long?,
  public val DeviceID: String?,
  public val FCMToken: String?,
  public val TotalSale: Long?,
  public val TotalPayment: Long?,
  public val LastSeen: String?,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
  public val __v: Long?,
  public val JobTypeId: String?,
  public val JobTypeName: String?,
)
