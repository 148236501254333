package feature.address.viewModel

import appConfig.AppConfig
import common.constants.KeyConstant
import common.constants.UserDefaults
import feature.address.handler.AddressHandler
import feature.address.model.Address
import feature.address.model.LocationCoordinate
import feature.address.network.AddressNetwork
import feature.address.network.CreateAddressRequest
import feature.address.network.FindZipCodeRequest
import feature.address.network.PinCode
import feature.address.network.RetrieveAddressRequest
import feature.address.network.UpdateAddressRequest
import feature.address.repository.AddressRepository
import feature.alert.handler.AlertHandler
import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import navigation.AppNavigator
import storage.database.table.address.AddressDatabaseTable

class AddressViewModel : AppViewModel() {
    private val repository: AddressRepository = AddressHandler.shared().repository
    val allAddress: StateFlow<List<Address>>
        get() = repository.allAddress

    val selectedAddress: StateFlow<Address>
        get() = repository.selectedAddress

    val primaryAddress: StateFlow<Address>
        get() = repository.primaryAddress

    val primaryAddressEditing: StateFlow<Address>
        get() = repository.primaryAddressEditing

    val newAddress: StateFlow<Address> get() = repository.newAddress
    val editAddress: StateFlow<Address> get() = repository.editAddress
    val pickedLocation: StateFlow<LocationCoordinate?> get() = repository.pickedLocation

    fun setNewAddress(address: Address) {
        scope.launch {
            repository.newAddressState.emit(address)
        }
    }

    fun setEditAddress(address: Address) {
        scope.launch {
            repository.editAddressState.emit(address)
        }
    }

    fun setPickedLocation(locationCoordinate: LocationCoordinate?) {
        scope.launch {
            val existingAddress = newAddress
            existingAddress.value.Location = locationCoordinate
            repository.newAddressState.emit(existingAddress.value)
            repository.pickedLocationState.emit(
                locationCoordinate
            )
        }
    }

    fun setHouseNumberForNewAddress(value: String) {
        scope.launch {
            val existingAddress = newAddress.value
            existingAddress.House = value
            repository.newAddressState.emit(existingAddress)
        }
    }

    fun setAreaForNewAddress(value: String) {
        scope.launch {
            val existingAddress = newAddress.value
            existingAddress.Area = value
            repository.newAddressState.emit(existingAddress)
        }
    }

    fun setLandmarkForNewAddress(value: String) {
        scope.launch {
            val existingAddress = newAddress.value
            existingAddress.LandMark = value
            repository.newAddressState.emit(existingAddress)
        }
    }

    fun setPinCodeForNewAddress(value: String) {
        scope.launch {
            val existingAddress = newAddress.value
            existingAddress.ZipCode = value.toLong()
            repository.newAddressState.emit(existingAddress)
        }
    }

    fun setCityForNewAddress(value: String) {
        scope.launch {
            val existingAddress = newAddress.value
            existingAddress.City = value
            repository.newAddressState.emit(existingAddress)
        }
    }

    fun setStateForNewAddress(value: String) {
        scope.launch {
            val existingAddress = newAddress.value
            existingAddress.State = value
            repository.newAddressState.emit(existingAddress)
        }
    }

    fun setPrimaryAddressForCustomer() {
        var isPrimaryAddressSet = false
        allAddress.value.forEach {
            if (it.IsPrimary == true) {
                scope.launch {
                    repository.primaryAddressState.emit(it)
                    isPrimaryAddressSet = true
                }
            }
        }
        if (!isPrimaryAddressSet && allAddress.value.isNotEmpty()) {
            scope.launch {
                repository.editAddressState.emit(allAddress.value.first())
            }
        }
    }

    fun loadPrimaryAddress(callback: (Address?) -> Unit) {
        val addressString = UserDefaults.retrieve(KeyConstant.primaryAddress)
        if (addressString.isNotEmpty()) {
            val address = jsonDecoder.decodeFromString<Address>(addressString)
            scope.launch {
                repository.primaryAddressState.emit(address)
            }
            callback(address)
        } else {
            callback(null)
        }
    }

    fun setPrimaryAddress(value: Address?, callback: () -> Unit = {}) {
        if (value != null) {
            UserDefaults.store(KeyConstant.primaryAddress, jsonDecoder.encodeToString(value))
            scope.launch {
                repository.primaryAddressState.emit(value)
                delay(50)
                callback()
            }
        }
    }

    fun setPrimaryAddressZipCode(value: String) {
        if (value.isNotEmpty()) {
            scope.launch {
                try {
                    val existingAddress = primaryAddressEditing.value
                    existingAddress.ZipCode = value.toLong()
                    repository.primaryAddressEditingState.emit(existingAddress)
                } catch (e: Exception) {

                }
            }
        }
    }

    fun create(callback: (Boolean) -> Unit = {}) {
        scope.launch {
            val request = CreateAddressRequest(
                uniqueID = timeStamp,
                name = newAddress.value.Name,
                featureObjectID = AuthHandler.shared().userId,
                userId = AuthHandler.shared().userId,
                house = newAddress.value.House,
                area = newAddress.value.Area,
                landMark = newAddress.value.LandMark,
                zipCode = newAddress.value.ZipCode?.toLong(),
                city = newAddress.value.City,
                state = newAddress.value.State,
                country = newAddress.value.Country,
                location = newAddress.value.Location?.coordinate
            )
            startLoading()
            AddressNetwork().create(request) {
                stopLoading()

                if (it?.payload?._id.isNullOrEmpty()) {
                    callback(true)
                    AlertHandler.shared()
                        .showAlert("Oops!", "Something went wrong, please try after some time")
                } else {
                    callback(false)
                    scope.launch { repository.newAddressState.emit(Address()) }
                    AppNavigator.shared().navigator?.pop()
                    AlertHandler.shared().showAlert("Congrats!", "Address created successfully")
                }
            }
        }
    }


    fun createBusinessAddress() {
        scope.launch {
            val request = CreateAddressRequest(
                uniqueID = timeStamp,
                name = newAddress.value.Name,
                featureObjectID = BusinessHandler.shared().businessId,
                businessId = BusinessHandler.shared().businessId,
                userId = AuthHandler.shared().userId,
                house = newAddress.value.House,
                area = newAddress.value.Area,
                landMark = newAddress.value.LandMark,
                zipCode = newAddress.value.ZipCode,
                city = newAddress.value.City,
                state = newAddress.value.State,
                country = newAddress.value.Country,
                location = pickedLocation.value?.coordinate
            )
            startLoading()
            AddressNetwork().create(request) {
                stopLoading()
                if (it?.payload?._id.isNullOrEmpty()) {
                    AlertHandler.shared()
                        .showAlert("Oops!", "Something went wrong, please try after some time")
                } else {
                    scope.launch { repository.newAddressState.emit(Address()) }
                    AppNavigator.shared().navigator?.pop()
                    AlertHandler.shared().showAlert("Congrats!", "Address created successfully")
                }
            }
        }
    }

    fun retrieve(callback: () -> Unit = {}) {
        loadAllAddressForUser()
        scope.launch {
            val request = RetrieveAddressRequest(
                businessId = null,
                userId = AuthHandler.shared().userId,
            )
            startLoading()
            AddressNetwork().retrieve(request) {
                stopLoading()
                scope.launch {
                    AddressDatabaseTable().insert(it?.payload)
                    loadAllAddressForUser()
                    callback()
                }
            }
        }
    }

    fun findZipCode(value: String, callback: (List<PinCode>) -> Unit = {}) {
        loadAllAddressForUser()
        scope.launch {
            val request = FindZipCodeRequest(
                country = AppConfig.shared().countryCode,
                pinCode = value
            )
            AddressNetwork().findPinCode(request) { response ->
                scope.launch {
                    if (response?.payload.isNullOrEmpty() || response?.payload?.isEmpty() == true) {
                        callback(arrayListOf())
                    } else {
                        callback(response!!.payload)
                        if (response.payload.isNotEmpty()) {
                            allAddress
                        }
                    }
                }
            }
        }
    }

    fun loadAllAddressForUser() {
        scope.launch {
            AddressDatabaseTable().retrieveForUser(AuthHandler.shared().userId) { data ->
                scope.launch {
                    repository.allAddressState.emit(data)
                }
            }
        }
    }


    fun setHouseNumberEditAddress(value: String) {
        scope.launch {
            val existingAddress = editAddress.value
            existingAddress.House = value
            repository.editAddressState.emit(existingAddress)
        }
    }

    fun setAreaForEditAddress(value: String) {
        scope.launch {
            val existingAddress = editAddress.value
            existingAddress.Area = value
            repository.editAddressState.emit(existingAddress)
        }
    }

    fun setLandmarkForEditAddress(value: String) {
        scope.launch {
            val existingAddress = editAddress.value
            existingAddress.LandMark = value
            repository.editAddressState.emit(existingAddress)
        }
    }

    fun setPinCodeForEditAddress(value: String) {
        scope.launch {
            val existingAddress = editAddress.value
            existingAddress.ZipCode = value.toLong()
            repository.editAddressState.emit(existingAddress)
        }
    }

    fun setCityForEditAddress(value: String) {
        scope.launch {
            val existingAddress = editAddress.value
            existingAddress.City = value
            repository.editAddressState.emit(existingAddress)
        }
    }

    fun setStateForEditAddress(value: String) {
        scope.launch {
            val existingAddress = editAddress.value
            existingAddress.State = value
            repository.editAddressState.emit(existingAddress)
        }
    }

    fun setStatePrimaryEditAddress(value: Boolean) {
        scope.launch {
            val existingAddress = editAddress.value
            existingAddress.IsPrimary = value
            repository.editAddressState.emit(existingAddress)
        }
    }

    fun update() {
        scope.launch {
            val request = UpdateAddressRequest(
                id = editAddress.value._id,
                uniqueID = editAddress.value.UniqueID,
                name = newAddress.value.Name,
                featureObjectID = editAddress.value.FeatureObjectID,
                userId = AuthHandler.shared().userId,
                house = editAddress.value.House,
                area = editAddress.value.Area,
                landMark = editAddress.value.LandMark,
                zipCode = editAddress.value.ZipCode,
                city = editAddress.value.City,
                state = editAddress.value.State,
                country = editAddress.value.Country,
                location = pickedLocation.value?.coordinate,
                isPrimary = editAddress.value.IsPrimary
            )
            startLoading()
            AddressNetwork().update(request) {
                stopLoading()
                if (it?.status != "Success") {
                    AlertHandler.shared()
                        .showAlert("Oops!", "Something went wrong, please try after some time")
                } else {
                    scope.launch { repository.newAddressState.emit(Address()) }
                    AppNavigator.shared().navigator?.pop()
                    AlertHandler.shared()
                        .showSuccessAlert("Done!", "Address updated successfully")
                }
            }
        }
    }
}
