package presentation.screens.business.home.component

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.button.DefaultButton
import friendly.shared.generated.resources.Res
import friendly.shared.generated.resources.support_image
import navigation.AppNavigator
import navigation.containers.ScreenName
import org.jetbrains.compose.resources.painterResource
import theme.ThemeColor

@Composable
fun NotLoggedInCard() {
    Card(
        modifier = Modifier
            .fillMaxHeight()
            .fillMaxHeight()
            .padding(16.dp)
            .background(color = ThemeColor.shared().cardBackground, RoundedCornerShape(10.dp))
            .clickable {
                AppNavigator.shared().navigateTo(ScreenName.LOGIN)
            },
    ) {
        Column {
            Image(
                painter = painterResource(Res.drawable.support_image),
                contentDescription = "",
                modifier = Modifier.fillMaxWidth().height(200.dp).padding(16.dp)
            )
            Row(
                modifier = Modifier
                    .padding(horizontal = 0.dp, vertical = 0.dp)
                    .fillMaxWidth()
                    .fillMaxHeight()
                    .background(color = ThemeColor.shared().cardBackground),
                verticalAlignment = Alignment.CenterVertically,

                ) {
//                Image(
//                    painter = painterResource(Res.drawable.sale_menu_icon),
//                    contentDescription = ""
//                )
                Column(
                    modifier = Modifier.fillMaxWidth().height(150.dp).padding(top = 30.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        modifier = Modifier.padding(bottom = 30.dp),
                        text = "Please Login to continue",
                        fontSize = 17.sp,
                        fontStyle = FontStyle.Normal,
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                        color = ThemeColor.shared().PrimaryColor
                    )
                    DefaultButton("Login", {
                        AppNavigator.shared().navigateTo(
                            ScreenName.LOGIN
                        )
                    })
                }

            }
        }
    }
}