package feature.auth.repository

import feature.auth.handler.AuthHandler
import feature.auth.model.FriendlyUser
import feature.auth.model.Language
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import locale.Localization

class AuthRepository {
    val authLiveData: MutableStateFlow<FriendlyUser> = MutableStateFlow(FriendlyUser())
    val tempAuthLiveData: MutableStateFlow<FriendlyUser> = MutableStateFlow(FriendlyUser())
    val isLoggedInState: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val isLoggedIn: StateFlow<Boolean> get() = isLoggedInState
    val otpState: MutableStateFlow<String> = MutableStateFlow("")
    val authUser: StateFlow<FriendlyUser>
        get() = AuthHandler.shared().repository.authLiveData

    val selectedLanguageState: MutableStateFlow<Language> = MutableStateFlow(Language())
    val selectedLanguage: StateFlow<Language> get() = selectedLanguageState

    val localizationState: MutableStateFlow<Localization> = MutableStateFlow(Localization())
    val localization: StateFlow<Localization> get() = localizationState

    val mobileNumberState: MutableStateFlow<String> = MutableStateFlow("")
    val mobileNumber: StateFlow<String> get() = mobileNumberState
}