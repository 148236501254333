package feature.inventory.repository

import feature.inventory.model.brand.Brand
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class BrandRepository {
    val brandLiveData: MutableStateFlow<List<Brand>> = MutableStateFlow(arrayListOf())
    val allBrand: StateFlow<List<Brand>>
        get() = brandLiveData

    val selectedBrandState: MutableStateFlow<Brand?> = MutableStateFlow(null)
    val selectedBrand: StateFlow<Brand?>
        get() = selectedBrandState

    val editBrandState: MutableStateFlow<Brand?> = MutableStateFlow(null)
    val editBrand: StateFlow<Brand?>
        get() = editBrandState

    var selectedBrandForNavigation: Brand? = null

    var brandsMutableState: MutableStateFlow<List<Brand>> =
        MutableStateFlow(ArrayList())
    val brands: StateFlow<List<Brand>> = brandsMutableState

}