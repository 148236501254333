package feature.inventory.handler

import feature.inventory.repository.DiscountRepository
import feature.inventory.viewModel.discount.DiscountViewModel


class DiscountHandler {

    var viewModel: DiscountViewModel? = null
    val repository = DiscountRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: DiscountHandler? = null
        fun shared(): DiscountHandler {
            if (instance != null) {
                return instance as DiscountHandler
            } else {
                return DiscountHandler()
            }
        }
    }

    fun setup(model: DiscountViewModel) {
        viewModel = model
    }
}