package presentation.screens.business.onboard.component

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import component.image.AsyncImageView
import feature.businessType.model.BusinessType
import theme.ThemeColor

@Composable
fun BusinessTypeCard(
    businessType: BusinessType,
    onClick: (type: BusinessType) -> Unit
) {
    Card(
        modifier = Modifier
            .height(132.dp)
            .width(179.dp)
            .padding(4.dp)
            .background(color = ThemeColor.shared().cardBackground, RoundedCornerShape(10.dp))
            .clip(RoundedCornerShape(10.dp))
            .clickable {
                onClick(businessType)
            },

        ) {
        Box(
            modifier = Modifier.fillMaxWidth().fillMaxHeight()
                .background(ThemeColor.shared().PrimaryColor)
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .fillMaxHeight().zIndex(-1f)
            ) {
                AsyncImageView(businessType.image.first())
            }
            Box(
                modifier = Modifier.fillMaxWidth().fillMaxHeight().zIndex(2f)
                    .background(color = ThemeColor.shared().semiTransparent)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().fillMaxHeight(),
                    verticalAlignment = Alignment.Bottom,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Text(
                        text = businessType.name ?: "Business",
                        fontSize = 15.sp,
                        fontWeight = FontWeight(700),
                        textAlign = TextAlign.Center,
                        color = ThemeColor.shared().White,
                        modifier = Modifier.padding(bottom = 8.dp)
                    )
                }
            }
        }
    }
}