package presentation.screens.business.store.component

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import feature.businessConfig.model.BusinessConfig
import feature.businessMenu.modal.BusinessMenu
import navigation.containers.ScreenName
import org.jetbrains.compose.resources.painterResource

@Composable
fun StoreMenuCard(menu: BusinessMenu, config: BusinessConfig?, onClick: (ScreenName?) -> Unit) {
    Card(
        modifier = Modifier
            .height(80.dp)
            .width(180.dp)
            .padding(4.dp)
            .background(color = menu.background_color!!, RoundedCornerShape(10.dp))
            .clickable {
                onClick(menu.target_screen)
            },
    ) {
        Row(
            modifier = Modifier
                .padding(horizontal = 0.dp, vertical = 0.dp)
                .fillMaxWidth()
                .fillMaxHeight()
                .background(color = menu.background_color!!),
            verticalAlignment = Alignment.CenterVertically,

            ) {
            menu.icon?.let {
                Image(
                    modifier = Modifier.height(44.dp).width(44.dp)
                        .padding(start = 8.dp, end = 8.dp),
                    painter = painterResource(it),
                    contentDescription = null
                )
            }
            Column {
                menu.title?.let {
                    Text(
                        text = it,
                        fontSize = 15.sp,
                        textAlign = TextAlign.Center,
                    )
                }
                if (!getSubtitleFor(menu, config).isNullOrEmpty()) {
                    Text(
                        text = getSubtitleFor(menu, config) ?: "",
                        fontSize = 15.sp,
                        textAlign = TextAlign.Center,
                    )
                }
            }
        }
    }
}

fun getSubtitleFor(menu: BusinessMenu, config: BusinessConfig?): String? {
    var subtitle: String? = null
    when (menu.id) {
        "my_business_currency" -> subtitle = config?.currency
        "2" -> print("x == 2")
        else -> { // Note the block
            print("x is neither 1 nor 2")
        }
    }
    return subtitle
}
