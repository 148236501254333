package presentation.screens.customer.store.shopbycategory

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.input.SearchBar
import component.loader.AppLoader
import feature.address.model.LocationCoordinate
import feature.address.viewModel.AddressViewModel
import feature.business.viewModel.BusinessViewModel
import feature.cart.viewModel.CartViewModel
import feature.product.viewModel.ProductViewModel
import native.locations.LocationsFeature
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.customer.store.shopbycategory.component.ProductsCard
import presentation.screens.customer.store.shopbycategory.component.ServicesCard
import theme.ThemeColor

class ShopByCategoryScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val viewModel = CartViewModel()
        val productViewModel = ProductViewModel()
        val businessViewModel = BusinessViewModel()
        val addressViewModel = AddressViewModel()
        val searchQuery = productViewModel.searchQuery.collectAsState()
        val pickedlocation = addressViewModel.pickedLocation.collectAsState()
        businessViewModel.findNearby(searchQuery.value, pickedlocation.value)
        LocationsFeature.shared().onCaptureLocation = { lat, long ->
            addressViewModel.setPickedLocation(
                LocationCoordinate(
                    coordinate = arrayListOf(
                        lat,
                        long
                    )
                )
            )
            businessViewModel.findNearby(
                searchQuery.value,
                LocationCoordinate(coordinate = arrayListOf(lat, long))
            )
            addressViewModel.setPrimaryAddressForCustomer()
        }
        LaunchedEffect(key1 = Unit) {
            LocationsFeature.shared().captureLocation()
            businessViewModel.findNearby(searchQuery.value, pickedlocation.value)
        }
        Column(
            modifier = Modifier.fillMaxSize()
                .background(ThemeColor.shared().defaultBakground)
        ) {
//            CustomerHomeTopNavigation((
//                    navigationParam,
//                addressViewModel,
//                isAddressModalOpen = addressModalOpen,
//                onClickCart = {
//                    navigateTo(ScreenName.SHOPPING_CART, navigationParam)
//                },
//                refresh = {
//                    businessViewModel.findNearby(pickedlocation.value)
//                }))
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(100.dp)
                    .background(color = ThemeColor.shared().PrimaryColor)
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(top = 25.dp)

                ) {
                    AppLoader(viewModel)
                    SearchBar(
                        viewModel,
                        searchQuery.value,
                        " search store here...."
                    )
                }
            }
            Column(modifier = Modifier.fillMaxWidth().height(40.dp)) {
                Row(
                    modifier = Modifier
                        .padding(8.dp, 10.dp)
                )
                {
                    Text(
                        "Products",
                        fontSize = 20.sp,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.weight(0.9f)
                    )
                    Text(
                        "View all",
                        fontSize = 14.sp,
                        color = ThemeColor.shared().PrimaryColor,
                        // modifier = Modifier.weight(0.1f)
                    )
                }
            }
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 140.dp),
                modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.2f)
            ) {
                items(6) {
                    ProductsCard()
                }

            }


            Column(modifier = Modifier.fillMaxWidth().height(40.dp)) {
                Row(
                    modifier = Modifier
                        .padding(8.dp, 10.dp)
                ) {
                    Text(
                        "Services",
                        fontSize = 20.sp,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.weight(0.9f)
                    )
                    Text(
                        "View all",
                        fontSize = 14.sp,
                        color = ThemeColor.shared().PrimaryColor,
                        // modifier = Modifier.weight(0.1f)
                    )
                }
            }
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 140.dp),
                modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.5f)
            ) {

                items(6) {
                    ServicesCard()
                }
            }


        }
    }
}
