package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.discountTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class DiscountTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    DiscountID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    ApplyLimit: Long?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getLong(6),
      cursor.getLong(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10)
    )
  }

  public fun retrieve(BusinessID: String?): Query<DiscountTable> = retrieve(BusinessID) { _id,
      DiscountID, UserID, BusinessID_, IsDeleted, Name, ApplyLimit, Value, Description, CreatedAt,
      UpdatedAt ->
    DiscountTable(
      _id,
      DiscountID,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      ApplyLimit,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveAll(mapper: (
    _id: String,
    DiscountID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    ApplyLimit: Long?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = Query(-1_458_768_548, arrayOf("DiscountTable"), driver, "DiscountTable.sq",
      "retrieveAll",
      "SELECT DiscountTable._id, DiscountTable.DiscountID, DiscountTable.UserID, DiscountTable.BusinessID, DiscountTable.IsDeleted, DiscountTable.Name, DiscountTable.ApplyLimit, DiscountTable.Value, DiscountTable.Description, DiscountTable.CreatedAt, DiscountTable.UpdatedAt FROM DiscountTable") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getLong(6),
      cursor.getLong(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10)
    )
  }

  public fun retrieveAll(): Query<DiscountTable> = retrieveAll { _id, DiscountID, UserID,
      BusinessID, IsDeleted, Name, ApplyLimit, Value, Description, CreatedAt, UpdatedAt ->
    DiscountTable(
      _id,
      DiscountID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      ApplyLimit,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    DiscountID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    ApplyLimit: Long?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getBoolean(4),
      cursor.getString(5),
      cursor.getLong(6),
      cursor.getLong(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getString(10)
    )
  }

  public fun find(_id: String): Query<DiscountTable> = find(_id) { _id_, DiscountID, UserID,
      BusinessID, IsDeleted, Name, ApplyLimit, Value, Description, CreatedAt, UpdatedAt ->
    DiscountTable(
      _id_,
      DiscountID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      ApplyLimit,
      Value,
      Description,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    DiscountID: Long?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    ApplyLimit: Long?,
    Value: Long?,
    Description: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(-1_927_515_454, """
        |INSERT OR REPLACE INTO DiscountTable( _id ,DiscountID,UserID, BusinessID,IsDeleted,Name,ApplyLimit,Value,Description,CreatedAt,UpdatedAt)
        |    VALUES(?, ?,?, ? ,?, ?, ?,?,?,?,?)
        """.trimMargin(), 11) {
          bindString(0, _id)
          bindLong(1, DiscountID)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindBoolean(4, IsDeleted)
          bindString(5, Name)
          bindLong(6, ApplyLimit)
          bindLong(7, Value)
          bindString(8, Description)
          bindString(9, CreatedAt)
          bindString(10, UpdatedAt)
        }.await()
    notifyQueries(-1_927_515_454) { emit ->
      emit("DiscountTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("DiscountTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("DiscountTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT DiscountTable._id, DiscountTable.DiscountID, DiscountTable.UserID, DiscountTable.BusinessID, DiscountTable.IsDeleted, DiscountTable.Name, DiscountTable.ApplyLimit, DiscountTable.Value, DiscountTable.Description, DiscountTable.CreatedAt, DiscountTable.UpdatedAt FROM DiscountTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "DiscountTable.sq:retrieve"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("DiscountTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("DiscountTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_508_512_770,
        """SELECT DiscountTable._id, DiscountTable.DiscountID, DiscountTable.UserID, DiscountTable.BusinessID, DiscountTable.IsDeleted, DiscountTable.Name, DiscountTable.ApplyLimit, DiscountTable.Value, DiscountTable.Description, DiscountTable.CreatedAt, DiscountTable.UpdatedAt FROM DiscountTable WHERE _id = ?""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "DiscountTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("DiscountTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("DiscountTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM DiscountTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "DiscountTable.sq:lastSyncDate"
  }
}
