package presentation.screens.customer.order.list

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.empty.EmptyData
import component.navigation.TopNavBar
import component.order.OrderCard
import feature.business.viewModel.BusinessViewModel
import feature.order.viewModel.OrderViewModel
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import theme.ThemeColor

class OrderListScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val viewModel = OrderViewModel()
        val businessViewModel = BusinessViewModel()
        val orders = viewModel.allOrders.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.loadOrders(navigationParam)
        }
        Column(
            modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)
                .padding(bottom = 60.dp)
        ) {
            TopNavBar(title = "Orders", navigationParam)
            if (orders.value.isEmpty()) {
                EmptyData("No orders found", viewModel)
            }
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 400.dp),
                modifier = Modifier.weight(1f).padding(
                    start = 0.dp,
                    end = 0.dp,
                    bottom = if (orders.value.isNotEmpty()) 0.dp else (60.dp)
                ),
            ) {
                items(orders.value.count()) { index ->
                    OrderCard(orders.value[index], businessViewModel) {

                    }
                }
            }
        }
    }
}