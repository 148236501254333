package presentation.screens.business.home.component

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import feature.businessMenu.modal.BusinessMenu
import theme.ThemeColor

@Composable
fun headerCard(menu: BusinessMenu) {
    Row(
        modifier = Modifier
            .height(32.dp)
            .padding(horizontal = 8.dp, vertical = 0.dp)
            .fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Column(modifier = Modifier.padding(start = 8.dp)) {
            Text(
                text = menu.title!!,
                color = ThemeColor.shared().PrimaryColor,
                fontSize = 24.sp,
                textAlign = TextAlign.Center,
            )
        }
    }
}