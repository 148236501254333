package component.address

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.button.DefaultButton
import feature.address.model.LocationCoordinate
import feature.address.viewModel.AddressViewModel
import native.locations.LocationsFeature
import theme.ThemeColor

@Composable
fun AddressLocationInput(
    viewModel: AddressViewModel = AddressViewModel(),
    onChange: (LocationCoordinate?) -> Unit
) {
    val pickedLocation = viewModel.pickedLocation.collectAsState()
    LocationsFeature.shared().onCaptureLocation = { lat, long ->
        val newLocation = LocationCoordinate(
            latitude = lat,
            longitude = long,
            coordinate = arrayListOf(lat, long),
            type = "Point"
        )
        viewModel.setPickedLocation(newLocation)
        onChange(
            newLocation
        )
    }
    LaunchedEffect(key1 = Unit) {
        onChange(
            pickedLocation.value
        )
    }
    Card(
        modifier = Modifier.height(70.dp).fillMaxWidth().padding(start = 8.dp, end = 8.dp)
            .background(ThemeColor.shared().cardBackground, shape = RoundedCornerShape(12.dp))
            .clickable {
                LocationsFeature.shared().captureLocation()
            }) {
        Row(
            modifier = Modifier.fillMaxSize().background(ThemeColor.shared().cardBackground),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (pickedLocation.value?.latitude != null && pickedLocation.value?.longitude != null) {
                Text(
                    "${pickedLocation.value?.latitude},${pickedLocation.value?.longitude}",
                    modifier = Modifier.fillMaxWidth().wrapContentHeight().weight(0.6F)
                        .padding(start = 16.dp, end = 16.dp)
                )
            }
            Row(
                modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.4F),
                verticalAlignment = Alignment.CenterVertically
            ) {
                DefaultButton("Locate", {
                    LocationsFeature.shared().captureLocation()
                })
            }
        }
    }
}