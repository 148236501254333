package presentation.screens.business.inventory.stock.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex
import appConfig.AppConfig
import component.image.ImageView
import feature.inventory.viewModel.inventory.InventoryViewModel
import feature.product.model.Product
import theme.ThemeColor

@Composable
fun ProductCard(product: Product?, viewModel: InventoryViewModel) {
    val lowestProduct = viewModel.lowestPriceProduct.collectAsState()
    val highestProduct = viewModel.highestPriceProduct.collectAsState()
    Row(
        modifier = Modifier
            .height(110.dp)
            .fillMaxWidth()
            .padding(start = 16.dp, end = 8.dp, bottom = 8.dp)
            .shadow(3.dp)
            .background(ThemeColor.shared().cardBackground)
            .zIndex(1f),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier.width(60.dp)
                .height(60.dp).padding(start = 8.dp)
                .background(
                    ThemeColor.shared().cardBackground,
                    shape = RoundedCornerShape(10.dp)
                )
                .clip(RoundedCornerShape(10.dp)),
            contentAlignment = Alignment.Center
        ) {
            ImageView(id = product?.Id)
        }
        Column(modifier = Modifier.fillMaxHeight().fillMaxWidth()) {
            Text(
                text = product?.Name ?: "",
                maxLines = 4,
                overflow = TextOverflow.Ellipsis,
                fontSize = 16.sp,
                fontWeight = FontWeight(400),
                modifier = Modifier
                    .fillMaxWidth()
                    .height(30.dp)
                    .padding(start = 8.dp)
            )
            Text(
                text = product?.Description ?: "",
                maxLines = 4,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(30.dp)
                    .padding(start = 8.dp)
            )
            if(lowestProduct.value?.ProductPrice?.FinalPrice != null && highestProduct.value?.ProductPrice?.FinalPrice != null) {
                Text(
                    text = "${AppConfig.shared().currencySymbol} ${lowestProduct.value?.ProductPrice?.FinalPrice} to ${highestProduct.value?.ProductPrice?.FinalPrice}",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .padding(start = 8.dp)
                )
            }else{
                Text(
                    text = "${AppConfig.shared().currencySymbol} ${product?.ProductPrice?.FinalPrice}",
                    maxLines = 4,
                    overflow = TextOverflow.Ellipsis,
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(30.dp)
                        .padding(start = 8.dp)
                )
            }
        }
    }
}