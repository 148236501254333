package navigation.tabs

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Home
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.graphics.vector.rememberVectorPainter
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.tab.Tab
import cafe.adriel.voyager.navigator.tab.TabOptions
import feature.app.NavigationParam
import feature.business.model.Business
import feature.business.viewModel.BusinessViewModel
import friendly.shared.generated.resources.Res
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import navigation.containers.ScreenName
import org.jetbrains.compose.resources.ExperimentalResourceApi
import presentation.screens.customer.store.StoreDetailScreen

object StoresDetailTab : Tab {
    override val options: TabOptions
        @Composable
        get() {
            val title = "Home"
            val icon = rememberVectorPainter(Icons.Default.Home)
            return remember {
                TabOptions(
                    index = 0u,
                    title = title,
                    icon = icon
                )
            }
        }

    @OptIn(ExperimentalResourceApi::class)
    @Composable
    override fun Content() {
        val viewModel = BusinessViewModel()
        var selectedBusiness by remember {
            mutableStateOf(Business())
        }

        fun loadNearbyStore() {
            CoroutineScope(Job() + Dispatchers.Default).launch {
                val jsonDecoder = Json {
                    encodeDefaults = true
                    ignoreUnknownKeys = true
                }
                val bytes = Res.readBytes("files/selected_business.json")
                selectedBusiness = jsonDecoder.decodeFromString(bytes.decodeToString())
                viewModel.setSelectedBusiness(business = selectedBusiness)
                viewModel.setSelectedStore(business = selectedBusiness)
            }
        }
        LaunchedEffect(Unit) {
            loadNearbyStore()
        }
        if (selectedBusiness.Id.isEmpty()) {
            loadNearbyStore()
        } else {
            val screen = StoreDetailScreen(ScreenName.STORE_DETAILS_SCREEN, hideBack = true)
            screen.navigationParam =
                NavigationParam(selectedBusiness = selectedBusiness, business = selectedBusiness)
            Navigator(screen = screen)
        }
    }
}