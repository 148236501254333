package component.offers

import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import feature.offerBanner.model.OfferBanner
import feature.productCategory.model.ProductCategory
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

@Composable
fun OfferBannerList(
    banners: List<OfferBanner>,
    onClick: (ProductCategory) -> Unit = {},
) {
    val listState = rememberLazyListState()
    val coroutineScope = rememberCoroutineScope()
    var currentIndex by remember { mutableStateOf(0) }
    var timer by remember { mutableStateOf(0) }
    LaunchedEffect(key1 = timer) {
        if (banners.isNotEmpty()) {
            if (currentIndex < banners.count()) {
                coroutineScope.launch {
                    listState.animateScrollToItem(currentIndex)
                    currentIndex += 1
                }
            } else {
                currentIndex = 0
            }
        } else {
//            viewModel.retrieve()
        }
        delay(1_000)
        timer += 1
    }

    LazyRow(state = listState) {
        items(count = banners.count()) { index ->
            OfferBannerCard(offer = banners[index], onClick = {

            })
        }
    }

}
