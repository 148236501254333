package feature.productSubCategory.network

import common.constants.Server
import feature.business.handler.BusinessHandler
import feature.productSubCategory.event.ProductSubCategoryEvent
import feature.productSubCategory.handler.ProductSubCategoryHandler
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import network.FriendlyClient


class ProductSubCategoryNetwork {
    val json = Json {
        ignoreUnknownKeys = true
    }

    init {
        instance = this
    }

    companion object {
        private var instance: ProductSubCategoryNetwork? = null
        fun shared(): ProductSubCategoryNetwork {
            if (instance != null) {
                return instance as ProductSubCategoryNetwork
            } else {
                return ProductSubCategoryNetwork()
            }
        }
    }

    //    fun <T> buildService(service: Class<T>): T {
//        return FriendlyNetwork.shared().retrofit.create(service)
//    }
//
//    fun create(request: JSONObject, onResult: (JSONObject?) -> Unit) {
//        val retrofit = buildService(ProductCategoryNetworkInterface::class.java)
//        val bodyRequest: RequestBody =
//            request.toString().toRequestBody("application/json".toMediaTypeOrNull())
//        retrofit.create(bodyRequest).enqueue(
//            ResponseHandler().getRestHandler(onResult)
//        )
//    }
//
    fun fetchAllProductSubCategory() {
        val otpRoute = Server.getRestApiEndPointFor(ProductSubCategoryEvent.RETRIEVE)
        val request =
            RetrieveProductSubCategoryRequest(BusinessHandler.shared().repository.business.value.Id)
        CoroutineScope(Job() + Dispatchers.Default).launch {
            ProductSubCategoryNetwork().retrieve(request) {
                if (it?.payload != null && it.payload.isNotEmpty()) {
                    CoroutineScope(Job() + Dispatchers.Default).launch {
                        ProductSubCategoryHandler.shared().repository.subCategoryLiveData.emit(it.payload)
                    }
                }
            }
        }
    }

    suspend fun retrieve(
        request: RetrieveProductSubCategoryRequest,
        onResult: (RetrieveProductSubCategoryResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(ProductSubCategoryEvent.RETRIEVE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }

    suspend fun create(
        request: CreateProductSubCategoryRequest,
        onResult: (CreateProductSubCategoryResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(ProductSubCategoryEvent.CREATE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }

    suspend fun update(
        request: UpdateProductSubCategoryRequest,
        onResult: (UpdateProductSubCategoryResponse?) -> Unit
    ) {
        val apiRoute = Server.getRestApiEndPointFor(ProductSubCategoryEvent.UPDATE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
            onResult(null)
        }
    }
}