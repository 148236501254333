package feature.inventory.network.brand

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveBrandRequest(
    @SerialName("BusinessID") val businessID: String,
)

@Serializable
data class CreateBrandRequest(
    @SerialName("Name") val name: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("UserID") val userId: String,
)

@Serializable
data class UpdateBrandRequest(
    @SerialName("Name") val name: String,
    @SerialName("_id") val id: String,
    @SerialName("BusinessID") val businessID: String,
    @SerialName("UserID") val userId: String,
)
