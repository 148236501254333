package presentation.screens.business.inventory.category.select

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import component.empty.EmptyData
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.productCategory.viewModel.ProductCategoryViewModel
import presentation.screens.business.inventory.category.component.SelectCategoryCard
import presentation.screens.business.inventory.category.create.CreateCategoryScreen
import theme.ThemeColor

class SelectCategoryScreen : Screen {
    val viewModel = ProductCategoryViewModel()

    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val allCategories = viewModel.allCategory.collectAsState()
        val selectedCategory = viewModel.selectedCategory.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.retrieve()
        }
        Column(
            modifier = Modifier.fillMaxSize()
                .background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(title = "Category", null, onBackPress = {
                navigator?.pop()
            }, onClickAdd = {
                navigator?.push(CreateCategoryScreen())
            })
            AppLoader(viewModel)
            if (allCategories.value.isNotEmpty()) {
                LazyVerticalGrid(
                    columns = GridCells.Adaptive(minSize = 400.dp),
                    modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
                ) {
                    items(count = allCategories.value.count()) { index ->
                        SelectCategoryCard(
                            category = allCategories.value[index],
                            selectedCategory.value,
                            onClick = {
                                viewModel.selectCategory(it)
                                navigator?.pop()
                            })
                    }
                }
            } else {
                EmptyData(viewModel = viewModel) {
                    viewModel.retrieve()
                }
            }
        }
    }
}