package feature.businessType.repository

import feature.businessType.model.BusinessType
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class BusinessTypeRepository {
    val allBusinessTypeLiveData: MutableStateFlow<List<BusinessType>> = MutableStateFlow(
        arrayListOf()
    )
    val businessTypeState: MutableStateFlow<BusinessType?> = MutableStateFlow(null)
    val businessType: StateFlow<BusinessType?>
        get() = businessTypeState
}