package presentation.screens.business.home

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.friendly.shared.component.layout.VerticalGrid
import common.constants.BusinessMenuConfig
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.auth.handler.AuthHandler
import feature.auth.viewModel.AuthViewModel
import feature.business.viewModel.BusinessViewModel
import feature.employee.viewModel.EmployeeViewModel
import kotlinx.coroutines.delay
import native.files.FilePicker
import native.locations.Locations
import native.notifications.Notifications
import navigation.AppNavigator
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.business_list.component.BusinessCard
import presentation.screens.business.home.component.NoBusinessSelectedCard
import presentation.screens.business.home.component.NotLoggedInCard
import presentation.screens.business.home.component.headerCard
import presentation.screens.business.home.component.menuCard

class BusinessHomeScreen(override val screenName: ScreenName = ScreenName.BUSINESS_HOME) :
    AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val localization = AuthHandler.shared().repository.localization.collectAsState()
        val allMenu = BusinessMenuConfig().getMenu(localization.value)
        val viewModel = BusinessViewModel()
        val selectedBusiness = viewModel.selectedBusiness.collectAsState()
        val allBusiness = viewModel.allBusiness.collectAsState()
        val authViewModel = AuthViewModel()
        val authUser = authViewModel.authUser.collectAsState()
        LaunchedEffect(key1 = Unit) {
            Notifications().pushNotifications.requestPermission()
            FilePicker().imagePicker.requestPermission()
            Locations().locations.captureLocation()
            delay(500)
            AuthHandler.shared().checkIfLoggedIn()
            viewModel.loadBusiness()
            EmployeeViewModel().retrieve()
        }

        Column {
            TopNavBar(
                "", navigationParam,
                showNotification = true
            )
            LazyColumn(
                modifier = Modifier
                    .fillMaxSize().padding(bottom = 60.dp)
            ) {
                item {
                    if (authUser.value.id.isNotEmpty()) {
                        if (selectedBusiness.value.Id.isNotEmpty()) {
                            BusinessCard(selectedBusiness.value) {
                                AppNavigator.shared().navigateTo(
                                    ScreenName.SELECT_BUSINESS
                                )
                            }
                            headerCard(menu = allMenu[0])
                            VerticalGrid(
                                columns = 2
                            )
                            {
                                allMenu[1].sub_menu.forEach { menu ->
                                    menuCard(menu) { screen ->
                                        navigateTo(
                                            screen,
                                            NavigationParam(
                                                business = selectedBusiness.value,
                                                selectedBusiness = selectedBusiness.value,
                                                user = AuthHandler.shared().repository.authUser.value
                                            )
                                        )
                                    }
                                }
                            }
                            headerCard(menu = allMenu[2])
                            VerticalGrid(
                                columns = 2
                            ) {
                                allMenu[3].sub_menu.forEach { menu ->
                                    menuCard(menu) { screen ->
                                        navigateTo(
                                            screen,
                                            NavigationParam(
                                                business = selectedBusiness.value,
                                                selectedBusiness = selectedBusiness.value,
                                                user = AuthHandler.shared().repository.authUser.value
                                            )
                                        )
                                    }
                                }
                            }
                            headerCard(menu = allMenu[4])
                            VerticalGrid(
                                columns = 2
                            ) {
                                allMenu[5].sub_menu.forEach { menu ->
                                    menuCard(menu) { screen ->
                                        navigateTo(
                                            screen,
                                            NavigationParam(
                                                business = selectedBusiness.value,
                                                selectedBusiness = selectedBusiness.value,
                                                user = AuthHandler.shared().repository.authUser.value
                                            )
                                        )
                                    }
                                }
                            }
                            headerCard(menu = allMenu[6])
                            VerticalGrid(
                                columns = 2
                            ) {
                                allMenu[6].sub_menu.forEach { menu ->
                                    menuCard(menu) { screen ->
                                        navigateTo(
                                            screen,
                                            NavigationParam(
                                                business = selectedBusiness.value,
                                                selectedBusiness = selectedBusiness.value,
                                                user = AuthHandler.shared().repository.authUser.value
                                            )
                                        )
                                    }
                                }
                            }
                        } else {
                            NoBusinessSelectedCard()
                        }
                    } else {
                        NotLoggedInCard()
                    }
                }
            }
        }
    }
}





