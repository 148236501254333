package feature.customer.viewModel

import common.constants.KeyConstant
import common.constants.UserDefaults
import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.customer.handler.CustomerHandler
import feature.customer.model.Customer
import feature.customer.network.CreateCustomerRequest
import feature.customer.network.CustomerNetwork
import feature.customer.network.FetchCustomerRequest
import feature.customer.network.FindCustomerRequest
import feature.customer.network.UpdateCustomerRequest
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import storage.database.table.customer.CustomerDatabaseTable

class CustomerViewModel : AppViewModel() {

    val json = Json {
        ignoreUnknownKeys = true
    }

    val repository = CustomerHandler.shared().repository

    val allCustomer: StateFlow<ArrayList<Customer>>
        get() = repository.allCustomer

    val customer: StateFlow<Customer?>
        get() = repository.customer

    val selectedCustomer: StateFlow<Customer?>
        get() = repository.selectedCustomer

    val editCustomer: StateFlow<Customer?>
        get() = repository.editCustomer
    val searchQuery: StateFlow<String>
        get() = repository.searchQuery

    override fun changeSearchQuery(value: String) {
        scope.launch {
            repository.searchQueryState.emit(value)
        }
        performSearch(value)
    }

    override fun performSearch(value: String) {
        scope.launch {
            CustomerDatabaseTable().search(BusinessHandler.shared().businessId, value) { items ->
                scope.launch {
                    repository.allCustomerState.emit(items)
                }
            }
        }
    }

    fun setSelectedCustomer(value: Customer?) {
        scope.launch {
            repository.selectedCustomerState.emit(value)
        }
    }

    fun editCustomer(value: Customer?) {
        repository.setEditCustomer(value)
    }

    fun editCustomerName(value: String) {
        repository.editCustomer.value?.Name = value
    }

    fun editCustomerMobile(value: String) {
        repository.editCustomer.value?.MobileNumber = value
    }

    fun editCustomerDialCode(value: String) {
        repository.editCustomer.value?.DialCode = value
    }

    fun editCustomerEmail(value: String) {
        repository.editCustomer.value?.EmailID = value
    }

    fun editCustomerBarcode(value: String) {
        repository.editCustomer.value?.barcode = value
    }

    fun loadCustomers() {
        loadCustomersFromStorage()
        startLoading()
        CustomerDatabaseTable().lastSyncDate(BusinessHandler.shared().businessId) { date ->
            val request = FetchCustomerRequest(
                BusinessHandler.shared().businessId,
                date
            )
            scope.launch {
                CustomerNetwork().retrieveCustomer(request) {
                    stopLoading()
                    if (it?.payload?.isNotEmpty() == true) {
                        scope.launch {
                            CustomerDatabaseTable().insert(it.payload)
                            loadCustomersFromStorage()
                        }
                    }
                }
            }
        }

    }

    private fun loadCustomersFromStorage() {
        CustomerDatabaseTable().retrieve(BusinessHandler.shared().businessId) { data ->
            scope.launch {
                repository.allCustomerState.emit(data)
            }
        }
    }

    fun createNewCustomer(
        name: String,
        dialCode: String,
        mobile: String,
        email: String,
        barcode: String,
        callBack: (Boolean) -> Unit
    ) {
        val request = CreateCustomerRequest(
            AuthHandler.shared().repository.authUser.value.id,
            BusinessHandler.shared().repository.business.value.Id,
            name,
            dialCode,
            mobile,
            email,
            barcode,
            AuthHandler.shared().deviceId
        )
        startLoading()
        scope.launch {
            CustomerNetwork().createCustomer(request) {
                stopLoading()
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    callBack(true)
                    scope.launch {
                        CustomerHandler.shared().repository.customerState.emit(it!!.payload!!)
                        CustomerDatabaseTable().insert(item = it.payload)
                    }
                } else {
                    callBack(false)
                }
            }
        }
    }

    fun updateCustomer(
        callBack: (Boolean) -> Unit
    ) {
        val request = editCustomer.value?.let {
            UpdateCustomerRequest(
                it.Id,
                AuthHandler.shared().repository.authUser.value.id,
                BusinessHandler.shared().repository.business.value.Id,
                editCustomer.value?.Name ?: "",
                editCustomer.value?.DialCode ?: "",
                editCustomer.value?.MobileNumber ?: "",
                editCustomer.value?.EmailID ?: "",
                editCustomer.value?.barcode ?: "",
                AuthHandler.shared().deviceId
            )
        }
        request?.let {
            startLoading()
            scope.launch {
                CustomerNetwork().updateCustomer(it) { response ->
                    stopLoading()
                    if (!response?.payload?.Id.isNullOrEmpty()) {
                        callBack(true)
                        scope.launch {
                            CustomerHandler.shared().repository.customerState.emit(response!!.payload!!)
//                            repository.lastSyncDateState.emit(null)
                        }
                    } else {
                        callBack(false)
                    }
                }
            }
        }
    }
//
//    fun updateCustomer(customer: Customer) {
//        val user = FriendlyUser()
//        var request = JSONObject()
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.businessID, BusinessHandler.shared().repository.business.value?.Id)
//        request.put(KeyConstant.name, customer.Name)
//        request.put(KeyConstant.mobileNumber, customer.MobileNumber)
//        request.put(KeyConstant.emailId, customer.EmailID)
//        request.put(KeyConstant.barcode, customer.Barcode)
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        SocketService.shared().send(SocketEvent.UPDATE_CUSTOMER, request)
//    }
//
//    fun insert(customer: Customer) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.customerDao()
//                .insert(customer)
//        }
//    }
//
//    fun getInvoiceCount(id: String, completion: (count: Int) -> Unit) {
//        if (!BusinessHandler.shared().repository.business.value?.Id.isNullOrEmpty()) {
//            scope.launch {
//                val totalInvoiceCount =
//                    DatabaseHandler.shared().database.customerDao().getInvoiceCount(id)
//                if (totalInvoiceCount != null) {
//                    completion(totalInvoiceCount)
//                }
//            }
//        }
//    }
//
//    fun getTotalInvoiceValue(id: String, completion: (count: Float) -> Unit) {
//        if (!BusinessHandler.shared().repository.business.value?.Id.isNullOrEmpty()) {
//            scope.launch {
//                val totalInvoiceValue =
//                    DatabaseHandler.shared().database.customerDao().getTotalInvoiceValue(id)
//                if (totalInvoiceValue != null) {
//                    completion(totalInvoiceValue)
//                }
//            }
//        }
//    }

    fun findCustomerByMobile(mobile: String, dialCode: String) {
        val request = FindCustomerRequest(
            "15143667976",
            dialCode,
            BusinessHandler.shared().repository.business.value.Id
        )
        scope.launch {
            CustomerNetwork().findCustomerByMobile(request) {
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    scope.launch {
                        CustomerHandler.shared().repository.customerState.emit(it!!.payload!!)
                    }
                }
            }
        }
    }

    fun loadMembership(mobile: String, dialCode: String) {
        val membershipDetails = UserDefaults.retrieveMembership(KeyConstant.membershipDetails)
        if (membershipDetails.isNotEmpty()) {
            val membership: Customer = json.decodeFromString(membershipDetails)
            scope.launch {
                CustomerHandler.shared().repository.customerState.emit(membership)
            }
        } else {
            val request = FindCustomerRequest(
                mobile,
                dialCode,
                BusinessHandler.shared().repository.business.value.Id
            )
            scope.launch {
                CustomerNetwork().findCustomerByMobile(request) {
                    if (!it?.payload?.Id.isNullOrEmpty()) {
                        scope.launch {
                            CustomerHandler.shared().repository.customerState.emit(it!!.payload!!)
                            UserDefaults.store(
                                KeyConstant.membershipDetails,
                                Json.encodeToString(it.payload!!)
                            )
                        }
                    }
                }
            }
        }
    }
}