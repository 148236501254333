package feature.business.handler


import feature.business.model.Business
import feature.business.repository.BusinessRepository


class BusinessHandler {

    //    var viewModal: BusinessViewModel? = null
    val repository = BusinessRepository()
    var onCreateBusinessResponse: ((Business) -> Unit)? = null
    val businessId: String
        get() {
            val business = shared().repository.business.value
            return business.Id
        }

    init {
        instance = this
    }

    companion object {
        private var instance: BusinessHandler? = null
        fun shared(): BusinessHandler {
            if (instance != null) {
                return instance as BusinessHandler
            } else {
                return BusinessHandler()
            }
        }
    }
}