package presentation.screens.common.account.component

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowCircleRight
import androidx.compose.material.icons.filled.Language
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import feature.auth.model.Language
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import theme.ThemeColor

@Composable
fun LanguageRow(language: Language?) {
    val navigator = LocalNavigator.current
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(60.dp)
            .padding(start = 8.dp, end = 8.dp)
            .background(Color(0x8DB3B0B0), shape = RoundedCornerShape(10.dp))
            .clip(RoundedCornerShape(10.dp))
            .clickable {
                AppNavigator.shared().navigateTo(
                    ScreenName.SELECT_LANGUAGE
                )
            }
            .padding(5.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(
            Icons.Filled.Language,
            "Language (${language?.name ?: ""}",
            modifier = Modifier.weight(0.05f),
            tint = ThemeColor.shared().iconTint
        )
        Text(
            "${localization().accounts.language} (${language?.name ?: ""})",
            modifier = Modifier.weight(0.2f)
        )
        Icon(
            Icons.Filled.ArrowCircleRight, "Language", modifier = Modifier.weight(0.03f),
            tint = ThemeColor.shared().iconTint
        )
    }
}