package presentation.screens.business.onboard.component

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Card
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.image.AsyncImageView
import feature.businessType.model.BusinessType
import theme.ThemeColor

@Composable
fun BusinessTypeHCard(
    businessType: BusinessType?,
    onClick: (type: BusinessType?) -> Unit
) {
    Card(
        modifier = Modifier
            .height(100.dp)
            .fillMaxWidth()
            .padding(4.dp)
            .background(color = ThemeColor.shared().cardBackground, RoundedCornerShape(10.dp))
            .clickable {
                onClick(businessType)
            },
    ) {
        Row(
            modifier = Modifier
                .padding(horizontal = 0.dp, vertical = 0.dp)
                .fillMaxWidth()
                .fillMaxHeight()
                .background(color = ThemeColor.shared().cardBackground, RoundedCornerShape(10.dp)),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Box(
                modifier = Modifier
                    .height(80.dp)
                    .width(80.dp).padding(0.dp)
                    .background(ThemeColor.shared().PrimaryColor, RoundedCornerShape(10.dp))
            ) {
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .fillMaxHeight().padding(12.dp)
                ) {
                    businessType?.image?.first()?.let { AsyncImageView(it) }
                }
            }

            Column(
                modifier = Modifier.fillMaxSize().weight(1f),
                verticalArrangement = Arrangement.Center
            ) {
                businessType?.name?.let {
                    Text(
                        text = it,
                        fontSize = 15.sp,
                        textAlign = TextAlign.Center,

                        )
                }
            }
        }
    }
}