package storage.database.table.customer

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.CustomerTable
import feature.customer.model.Customer
import kotlinx.coroutines.launch
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class CustomerDatabaseTable : DatabaseTable() {
    fun insert(item: Customer?) {
        val table = AppDatabase.shared().database?.customerTableQueries
        scope.launch {
            if (item != null) {
                table?.insert(
                    item.Id,
                    item.UserID,
                    item.BusinessID,
                    item.IsDeleted,
                    item.Name,
                    item.Address,
                    item.GSTNumber,
                    item.barcode,
                    item.barCodeImage,
                    item.Status,
                    item.EmailID,
                    item.DialCode,
                    item.MobileNumber,
                    item.WhatsApp,
                    item.Gender,
                    item.DeviceID,
                    item.FCMToken,
                    item.TodalSale,
                    item.TotalPayment,
                    item.LastSeen,
                    item.CreatedAt,
                    item.UpdatedAt
                )
            }
        }
    }

    fun insert(items: List<Customer>?) {
        val table = AppDatabase.shared().database?.customerTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    item.Id,
                    item.UserID,
                    item.BusinessID,
                    item.IsDeleted,
                    item.Name,
                    item.Address,
                    item.GSTNumber,
                    item.barcode,
                    item.barCodeImage,
                    item.Status,
                    item.EmailID,
                    item.DialCode,
                    item.MobileNumber,
                    item.WhatsApp,
                    item.Gender,
                    item.DeviceID,
                    item.FCMToken,
                    item.TodalSale,
                    item.TotalPayment,
                    item.LastSeen,
                    item.CreatedAt,
                    item.UpdatedAt
                )
            }
        }
    }

    fun retrieve(id: String, callback: (ArrayList<Customer>) -> Unit) {
        val table = AppDatabase.shared().database?.customerTableQueries
        val allItemsFetched: ArrayList<Customer> = arrayListOf()
        scope.launch {
            val items = table?.retrieve(
                id
            )?.awaitAsList()
            items?.forEach { item ->
                allItemsFetched.add(convert(item))
            }
            callback(allItemsFetched)
        }
    }

    fun search(id: String, query: String, callback: (ArrayList<Customer>) -> Unit) {
        val table = AppDatabase.shared().database?.customerTableQueries
        val allItemsFetched: ArrayList<Customer> = arrayListOf()
        scope.launch {
            val items = table?.search(
                id, "%${query}%", "%${query}%", "%${query}%"
            )?.awaitAsList()
            items?.forEach { item ->
                allItemsFetched.add(convert(item))
            }
            callback(allItemsFetched)
        }
    }

    fun lastSyncDate(id: String, callback: (String?) -> Unit) {
        val table = AppDatabase.shared().database?.customerTableQueries
        val allItemsFetched: ArrayList<Customer> = arrayListOf()
        scope.launch {
            val item = table?.lastSyncDate(
                id
            )?.awaitAsOneOrNull()
            callback(item?.UpdatedAt)
        }
    }

    fun convert(item: CustomerTable): Customer {
        return Customer(
            item._id,
            item.UserID,
            null,
            item.BusinessID,
            item.IsDeleted,
            item.Name,
            item.Address,
            item.GSTNumber,
            item.Barcode,
            item.BarCodeImage,
            item.Status,
            item.EmailID,
            item.DialCode,
            item.MobileNumber,
            item.WhatsApp,
            item.Gender,
            item.DeviceID,
            item.FCMToken,
            arrayListOf(),
            item.TotalSale,
            item.TotalPayment,
            item.LastSeen,
            item.CreatedAt,
            item.UpdatedAt
        )
    }
}