package component.cart

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import appConfig.AppConfig
import feature.cart.viewModel.CartViewModel
import theme.ThemeColor

@Composable
fun CartPaymentDetails(viewModel: CartViewModel) {
    val totalAmount = viewModel.totalAmount.collectAsState()
    val totalTax = viewModel.tax.collectAsState()
    Column {
        Row(
            modifier = Modifier
                .padding(start = 8.dp, end = 8.dp, bottom = 4.dp)
                .height(30.dp)
                .fillMaxWidth().zIndex(0f),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Text(
                text = "Total MRP",
                fontWeight = FontWeight(600),
                color = ThemeColor.shared().TextColor
            )
            Spacer(modifier = Modifier.height(2.dp))
            Text(
                text = "${AppConfig.shared().currencySymbol} ${totalAmount.value}",
                fontWeight = FontWeight(600),
                color = ThemeColor.shared().PrimaryColor
            )
        }
        Row(
            modifier = Modifier
                .padding(start = 8.dp, end = 8.dp, bottom = 4.dp)
                .height(30.dp)
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Text(
                text = "Total Tax",
                fontWeight = FontWeight(600),
                color = ThemeColor.shared().TextColor
            )
            Spacer(modifier = Modifier.height(2.dp))
            Text(
                text = "${AppConfig.shared().currencySymbol} ${totalTax.value}",
                fontWeight = FontWeight(600),
                color = ThemeColor.shared().PrimaryColor
            )
        }
    }

}