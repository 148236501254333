package htmp_page.privacy

import htmp_page.HtmlContent

class PrivacyPolicy {
    var allData: ArrayList<HtmlContent> = arrayListOf()
    fun getPrivacyPolicy(): ArrayList<HtmlContent> {
        allData = arrayListOf()
        allData.add(
            HtmlContent(
                h1 = "1. Terms",
                p = "By accessing the App, you are agreeing to be bound by these terms of service, all applicable laws and\n" +
                        "regulations, and agree that you are responsible for compliance with any applicable local laws.",
                br = ""
            )
        )
        allData.add(
            HtmlContent(
                p = "If you do not agree with any of these terms, you are prohibited from using or accessing this App.",
            )
        )
        allData.add(
            HtmlContent(
                p = "If you do not agree with any of these terms, you are prohibited from using or accessing this App.",
                br = ""
            )
        )
        allData.add(
            HtmlContent(
                p = "The materials contained in this App are protected by applicable copyright and trademark law.",
                br = ""
            )
        )
        allData.add(
            HtmlContent(
                p = "Note : No tolerance for nudity and objectionable content at all.\n" +
                        "If you do so we will take necessary action including suspending your account and removing all of your\n" +
                        "content.",
                br = ""
            )
        )
        ///
        allData.add(
            HtmlContent(
                h1 = "3. Disclaimer",
                p = "The materials on Mandee's App are provided on an 'as is' basis. Mandee makes no warranties, expressed or\n" +
                        "implied, and hereby disclaims and negates all other warranties including, without limitation, implied\n" +
                        "warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of\n" +
                        "intellectual property or other violation of rights.",
                br = ""
            )
        )
        allData.add(
            HtmlContent(
                p = "Further, Mandee does not warrant or make any representations concerning the accuracy, likely results,\n" +
                        "or reliability of the use of the materials on its App or otherwise relating to such materials or on any\n" +
                        "sites linked to this App.",
                br = ""
            )
        )
        ///
        allData.add(
            HtmlContent(
                h1 = "4. Limitations",
                p = "In no event shall Mandee or its suppliers be liable for any damages (including, without limitation,\n" +
                        "damages\n" +
                        "for loss of data or profit, or due to business interruption) arising out of the use or inability to use the\n" +
                        "materials on Mandee's App, even if Mandee or a Mandee authorized representative has been notified\n" +
                        "orally\n" +
                        "or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on\n" +
                        "implied\n" +
                        "warranties, or limitations of liability for consequential or incidental damages, these limitations may not\n" +
                        "apply\n" +
                        "to you.",
                br = ""
            )
        )
        ///
        allData.add(
            HtmlContent(
                h1 = "5. Accuracy of materials",
                p = "The materials appearing on Mandee's App could include technical, typographical, or photographic errors.\n" +
                        "Mandee does not warrant that any of the materials on its App are accurate, complete or current.",
                br = ""
            )
        )
        allData.add(
            HtmlContent(
                p = "Mandee may make changes to the materials contained on its App at any time without notice. However,\n" +
                        "Mandee does\n" +
                        "not make any commitment to update the materials.",
                br = ""
            )
        )
        ///
        allData.add(
            HtmlContent(
                h1 = "6. Links",
                p = "Mandee has not reviewed all of the sites linked to its App and is not responsible for the contents of any\n" +
                        "such\n" +
                        "linked site. The inclusion of any link does not imply endorsement by Mandee of the site. Use of any such\n" +
                        "linked App is at the user's own risk.",
                br = ""
            )
        )
        ///
        allData.add(
            HtmlContent(
                h1 = "7. Modifications",
                p = "Mandee may revise these terms of service for its App at any time without notice. By using this App,\n" +
                        "you\n" +
                        "are agreeing to be bound by the then current version of these terms of service.",
                br = ""
            )
        )
        ///
        allData.add(
            HtmlContent(
                h1 = "8. Governing Law",
                p = "These terms and conditions are governed by and construed in accordance with the laws of California USA\n" +
                        "and\n" +
                        "you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.",
                br = ""
            )
        )

        return allData
    }
}