package component.dimension

import androidx.compose.ui.unit.Dp
import navigation.AppNavigator

class ScreenDimension {
    fun width(percent: String) {

    }

    fun height(percent: String) {

    }

    fun width(multiplier: Float): Dp {
        return AppNavigator.shared().screenWidth * multiplier
    }

    fun height(multiplier: Float): Dp {
        return AppNavigator.shared().screenHeight * multiplier
    }

    fun widthHalf(): Float {
        return AppNavigator.shared().screenWidth.value * 0.5F
    }

    fun heightHalf(): Float {
        return AppNavigator.shared().screenHeight.value * 0.5f
    }

    fun width(): Dp {
        return AppNavigator.shared().screenWidth
    }

    fun height(): Dp {
        return AppNavigator.shared().screenHeight
    }


}