package com.friendly.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class AddressTable(
  public val UniqueID: Long?,
  public val _id: String,
  public val UserID: String?,
  public val FeatureObjectID: String?,
  public val Name: String?,
  public val ZipCode: Long?,
  public val Country: String?,
  public val State: String?,
  public val City: String?,
  public val Area: String?,
  public val LandMark: String?,
  public val House: String?,
  public val FlatNumber: String?,
  public val Floor: String?,
  public val MobileNumber: String?,
  public val Type: String?,
  public val Location: String?,
  public val IsPrimary: Boolean,
  public val IsDeleted: Boolean,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
)
