package presentation.screens.business.customer.select

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.empty.EmptyData
import component.input.SearchBar
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.customer.viewModel.CustomerViewModel
import locale.localization
import presentation.screens.business.customer.create.CreateCustomerScreen
import presentation.screens.business.customer.select.component.SelectCustomerCard
import theme.ThemeColor

class SelectCustomerScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = CustomerViewModel()
        val allCustomer = viewModel.allCustomer.collectAsState()
        val searchQuery = viewModel.searchQuery.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.loadCustomers()
            viewModel.editCustomer(null)
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = localization().customer.customersTitle, null, onBackPress = {
                navigator?.pop()
            }, onClickAdd = {
                navigator?.push(CreateCustomerScreen())
            })
            AppLoader(viewModel)
            SearchBar(
                viewModel,
                searchQuery.value,
                localization().customer.searchCustomerPlaceholder
            )
            LazyColumn(modifier = Modifier.fillMaxWidth().fillMaxHeight().padding(bottom = 60.dp)) {
                item {
                    if (allCustomer.value.isEmpty()) {
                        EmptyData(viewModel = viewModel)
                        DefaultButton(
                            localization().customer.createCustomerTitle,
                            onClick = { navigator?.push(CreateCustomerScreen()) })
                    } else {
                        Box(
                            modifier = Modifier
                                .height(50.dp)
                                .padding(start = 8.dp, end = 8.dp)
                                .background(
                                    ThemeColor.shared().cardBackground,
                                    shape = RoundedCornerShape(10.dp)
                                ),
                            contentAlignment = Alignment.Center
                        ) {
                            Text(
                                text = "${localization().customer.customerTotal} ${allCustomer.value.count()} ${localization().customer.customerCustomers}",
                                maxLines = 2,
                                overflow = TextOverflow.Ellipsis,
                                modifier = Modifier
                                    .wrapContentHeight().fillMaxWidth(),
                                textAlign = TextAlign.Center,
                                color = ThemeColor.shared().PrimaryColor
                            )
                        }
                    }
                }
                items(count = allCustomer.value.count()) { index ->
                    SelectCustomerCard(allCustomer.value[index], viewModel) {
                        viewModel.setSelectedCustomer(it)
                        navigator?.pop()
                    }
                }
                item {
                    Spacer(modifier = Modifier.height(100.dp))
                }
            }
        }
    }
}