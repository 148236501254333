package component.input

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import feature.app.AppViewModel
import theme.ThemeColor

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun SearchBar(
    viewModel: AppViewModel?,
    query: String,
    placeholder: String,
) {

    Surface(
        shape = RoundedCornerShape(16.dp),
        color = ThemeColor.shared().cardBackground,
        modifier = Modifier
            .height(70.dp)
            .fillMaxWidth().padding(8.dp),
        shadowElevation = 4.dp,
        onClick = { }
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = 15.dp, end = 15.dp),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Icon(
                imageVector = Icons.Default.Search,
                tint = ThemeColor.shared().PrimaryColor,
                contentDescription = null

            )
            TextField(
                query,
                placeholder = {
                    Text(
                        text = placeholder,
                        maxLines = 2,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Start,
                        color = ThemeColor.shared().placeholderColor
                    )
                },
                onValueChange = {
                    viewModel?.changeSearchQuery(it)
                },
                modifier = Modifier.fillMaxHeight()
                    .background(color = ThemeColor.shared().cardBackground),
                colors = TextFieldDefaults.colors(
                    focusedContainerColor = ThemeColor.shared().cardBackground,
                    unfocusedContainerColor = ThemeColor.shared().cardBackground,
                    focusedIndicatorColor = ThemeColor.shared().cardBackground,
                    unfocusedIndicatorColor = ThemeColor.shared().cardBackground
                )
            )


        }
    }
}