package component.navigation

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.Close
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import theme.ThemeColor

@Composable
fun PopUpNavBar(
    title: String = "Title",
    onClose: () -> Unit,
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(54.dp)
    ) {
        Row(
            modifier = Modifier.fillMaxHeight().fillMaxWidth()
                .background(ThemeColor.shared().PrimaryColor),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                modifier = Modifier
                    .height(50.dp).padding(top = 10.dp, start = 50.dp).weight(1f),
                text = title,
                color = ThemeColor.shared().White,
                fontSize = 18.sp,
                fontWeight = FontWeight.Bold,
                textAlign = TextAlign.Center,

                )
            IconButton(
                onClick = {
                    onClose()
                },
                modifier = Modifier
                    .width(50.dp).height(50.dp)
            ) {
                Icon(
                    Icons.Rounded.Close,
                    contentDescription = "delete item",
                    tint = ThemeColor.shared().White,
                )
            }
        }

    }
}