package native.files

class ImagePicker {
    companion object {
        private var instance: ImagePicker? = null
        fun shared(): ImagePicker {
            return if (instance != null) {
                instance as ImagePicker
            } else {
                instance = ImagePicker()
                instance as ImagePicker
            }
        }
    }

    init {
        instance = this
    }

    var pickImage: () -> Unit = {}
    var requestPermission: () -> Unit = {}
    var onPermissionChange: (Boolean) -> Unit = {}
    var onImagePicked: (String) -> Unit = {}

}