package storage.database.table.address

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import com.friendly.common.database.AddressTable
import feature.address.model.Address
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class AddressDatabaseTable : DatabaseTable() {
    fun insert(item: Address) {
        val table = AppDatabase.shared().database?.addressTableQueries
        scope.launch {
            table?.insert(
                item.UniqueID,
                item._id,
                item.UserID,
                item.FeatureObjectID,
                item.Name,
                item.ZipCode,
                item.Country,
                item.State,
                item.City,
                item.Area,
                item.LandMark,
                item.House,
                item.FlatNumber,
                item.Floor,
                item.MobileNumber,
                item.Type,
                jsonDecoder.encodeToString(item.Location),
                item.IsPrimary,
                item.IsDeleted,
                item.CreatedAt,
                item.UpdatedAt
            )
        }
    }

    fun insert(items: List<Address>?) {
        val table = AppDatabase.shared().database?.addressTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    item.UniqueID,
                    item._id,
                    item.UserID,
                    item.FeatureObjectID,
                    item.Name,
                    item.ZipCode,
                    item.Country,
                    item.State,
                    item.City,
                    item.Area,
                    item.LandMark,
                    item.House,
                    item.FlatNumber,
                    item.Floor,
                    item.MobileNumber,
                    item.Type,
                    jsonDecoder.encodeToString(item.Location),
                    item.IsPrimary,
                    item.IsDeleted,
                    item.CreatedAt,
                    item.UpdatedAt
                )
            }
        }
    }

    fun retrieveForUser(id: String, callback: (List<Address>) -> Unit) {
        val table = AppDatabase.shared().database?.addressTableQueries
        val allItemsFetched: ArrayList<Address> = arrayListOf()
        scope.launch {
            val items = table?.getAddressForUser(
                id
            )?.awaitAsList()

            items?.forEach { item ->
                allItemsFetched.add(convert(item))
            }
            callback(allItemsFetched)
        }
    }

    fun retrievePrimaryForUser(id: String, callback: (Address?) -> Unit) {
        val table = AppDatabase.shared().database?.addressTableQueries
        scope.launch {
            val item = table?.getPrimaryAddressForUser(
                id
            )?.awaitAsOneOrNull()
            if (item != null) {
                callback(convert(item))
            } else {
                callback(null)
            }
        }
    }

    fun convert(item: Address): AddressTable {
        return AddressTable(
            item.UniqueID,
            item._id,
            item.UserID,
            item.FeatureObjectID,
            item.Name,
            item.ZipCode,
            item.Country,
            item.State,
            item.City,
            item.Area,
            item.LandMark,
            item.House,
            item.FlatNumber,
            item.Floor,
            item.MobileNumber,
            item.Type,
            jsonDecoder.encodeToString(item.Location),
            item.IsPrimary,
            item.IsDeleted,
            item.CreatedAt,
            item.UpdatedAt
        )
    }

    private fun convert(item: AddressTable): Address {
        return Address(
            item.UniqueID,
            item._id,
            item.UserID,
            item.FeatureObjectID,
            item.Name,
            item.ZipCode,
            item.Country,
            item.State,
            item.City,
            item.Area,
            item.LandMark,
            item.House,
            item.FlatNumber,
            item.Floor,
            item.MobileNumber,
            item.Type,
            jsonDecoder.decodeFromString(item.Location ?: "{}"),
            item.IsPrimary,
            item.IsDeleted,
            item.CreatedAt,
            item.UpdatedAt
        )
    }

}