package feature.invoice.model

import feature.sale.model.Sale
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CustomerInvoice(
    @SerialName("_id") var id: String = "",
    @SerialName("InvoiceID") var invoiceID: Long? = null,
    @SerialName("ShareLink") var ShareLink: String? = null,
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("CustomerID") var customerID: String? = null,
    @SerialName("CustomerName") var customerName: String? = null,
    @SerialName("DialCode") var DialCode: String? = null,
    @SerialName("MobileNumber") var mobileNumber: String? = null,
    @SerialName("IsDeleted") var isDeleted: Boolean? = null,
    @SerialName("Sales") var sales: ArrayList<Sale>? = arrayListOf(),
    @SerialName("InvoiceNumber") var invoiceNumber: Long? = null,
    @SerialName("InvoiceType") var invoiceType: String? = null,
    @SerialName("TotalPrice") var totalPrice: Double? = null,
    @SerialName("GST") var GST: Double? = null,
    @SerialName("SGST") var SGST: Double? = null,
    @SerialName("CGST") var CGST: Double? = null,
    @SerialName("IGST") var IGST: Double? = null,
    @SerialName("CESS") var CESS: Double? = null,
    @SerialName("VAT") var VAT: Double? = null,
    @SerialName("Tax") var Tax: Double? = null,
    @SerialName("CostPrice") var costPrice: Double? = null,
    @SerialName("InstantDiscount") var instantDiscount: Double? = null,
    @SerialName("FinalPrice") var finalPrice: Double? = null,
    @SerialName("PendingAmount") var pendingAmount: Double? = null,
    @SerialName("ClearedAmount") var clearedAmount: Double? = null,
    @SerialName("InvoiceDate") var invoiceDate: String? = null,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
    @SerialName("__v") var __v: Long? = null,
)
