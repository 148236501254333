package feature.cart.model

import engine.pricing.PricingEngine
import feature.product.model.Product
import feature.product.model.ProductPrice
import io.ktor.util.date.getTimeMillis
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CartItem(
    @SerialName("UniqueID") var uniqueID: Long = getTimeMillis(),
    @SerialName("_id") var _id: String? = null,
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("SelectedBusinessID") var selectedBusinessID: String? = null,
    @SerialName("CustomerId") var customerId: String? = null,
    @SerialName("ProductID") var productId: String = "",
    @SerialName("Product") var product: Product,
    @SerialName("Price") var price: ProductPrice,
    @SerialName("Quantity") var quantity: Long = 1,
    @SerialName("FinalPrice") var finalPrice: Double = 0.0,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
) {
    fun updatePricing() {
        val pricing = PricingEngine().calculatePricing(product, quantity)
        if (pricing.FinalPrice != null) {
            finalPrice = pricing.FinalPrice!!
        }
        price = pricing
    }
}