package presentation.screens.business.employee.detail

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.image.ImageView
import component.loader.AppLoader
import component.navigation.TopNavBar
import component.text.TextRow
import feature.employee.viewModel.EmployeeViewModel
import feature.invoice.viewModel.InvoiceViewModel
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.employee.edit.EditEmployeeScreen
import presentation.screens.business.invoice.component.InvoiceCard
import presentation.screens.business.invoice.detail.BusinessInvoiceDetailScreen
import theme.ThemeColor

class EmployeeDetailScreen(override val screenName: ScreenName) : AppScreen {

    @Composable
    override fun Content() {
        val viewModel = EmployeeViewModel()
        val employee = navigationParam?.employee
        val navigator = LocalNavigator.current
        val selectedIndex = remember { mutableStateOf(0) }
        val invoiceViewModel = InvoiceViewModel()
        val invoices = invoiceViewModel.allCustomerInvoice.collectAsState()
        LaunchedEffect(key1 = Unit) {
            employee?.EmployeeUserID?.let { invoiceViewModel.loadForUserId(it) }
        }
        fun getSelectedColor(index: Int = 0): Color {
            if (index == selectedIndex.value) {
                return ThemeColor.shared().PrimaryColor
            } else {
                return ThemeColor.shared().lightGray
            }
        }

        fun getSelectedTextColor(index: Int = 0): Color {
            if (index == selectedIndex.value) {
                return ThemeColor.shared().White
            } else {
                return ThemeColor.shared().White
            }
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = "Employee", navigationParam, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)

            LazyColumn(modifier = Modifier.fillMaxWidth().fillMaxHeight().padding(bottom = 60.dp)) {
                item {
                    Row(modifier = Modifier.fillMaxWidth().height(200.dp)) {
                        ImageView(employee?.EmployeeUserID)
                    }
                }
                item {
                    Row(
                        modifier = Modifier.fillMaxWidth().padding(8.dp).height(50.dp).background(
                            color = ThemeColor.shared().cardBackground,
                            shape = RoundedCornerShape(250.dp)
                        ).padding(start = 0.dp, end = 9.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Row(
                            modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.3f)
                                .padding(start = 8.dp, end = 8.dp, top = 8.dp, bottom = 8.dp)
                                .background(getSelectedColor(0), shape = RoundedCornerShape(20.dp))
                                .clickable {
                                    selectedIndex.value = 0
                                },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Center
                        ) {
                            Text(
                                "Info",
                                color = getSelectedTextColor(0),
                                fontSize = 15.sp,
                                fontStyle = FontStyle.Normal,
                                fontWeight = FontWeight(700)
                            )
                        }
                        Row(
                            modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.3f)
                                .padding(start = 8.dp, end = 8.dp, top = 8.dp, bottom = 8.dp)
                                .background(getSelectedColor(1), shape = RoundedCornerShape(20.dp))
                                .clickable {
                                    selectedIndex.value = 1
                                },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Center
                        ) {
                            Text(
                                "Invoices", color = getSelectedTextColor(1),
                                fontSize = 15.sp,
                                fontStyle = FontStyle.Normal,
                                fontWeight = FontWeight(700)
                            )
                        }
                        Row(
                            modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.3f)
                                .padding(start = 8.dp, end = 8.dp, top = 8.dp, bottom = 8.dp)
                                .background(getSelectedColor(2), shape = RoundedCornerShape(20.dp))
                                .clickable {
                                    selectedIndex.value = 2
                                },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Center
                        ) {
                            Text(
                                "Attendance", color = getSelectedTextColor(2),
                                fontSize = 15.sp,
                                fontStyle = FontStyle.Normal,
                                fontWeight = FontWeight(700)
                            )
                        }
                    }
                }
                if (selectedIndex.value == 0) {
                    item {
                        Column(
                            modifier = Modifier.fillMaxWidth().fillMaxHeight().background(
                                color = ThemeColor.shared().cardBackground,
                                shape = RoundedCornerShape(8.dp)
                            ).padding(8.dp)
                        ) {
                            TextRow("Name", employee?.Name ?: "")
                            TextRow("Email", employee?.EmailID ?: "")
                            TextRow(
                                "Mobile Number",
                                employee?.DialCode + employee?.MobileNumber
                            )
                            Row(
                                modifier = Modifier.fillMaxWidth().height(50.dp)
                                    .padding(start = 24.dp, end = 24.dp)
                            ) {
//                                SecondaryButton("Delete", {})
                                DefaultButton("Edit", {
                                    viewModel.editEmployee(employee)
                                    navigator?.push(EditEmployeeScreen())
                                })
                            }
                        }
                    }
                } else if (selectedIndex.value == 1) {
                    items(invoices.value.size) {
                        InvoiceCard(invoices.value[it]) {
                            invoiceViewModel.setSelectedInvoice(it)
                            navigator?.push(BusinessInvoiceDetailScreen(ScreenName.INVOICES))
                        }
                    }
                } else if (selectedIndex.value == 2) {
                    item {
                        Row(
                            modifier = Modifier.fillMaxWidth().fillMaxHeight().weight(0.3f)
                                .padding(start = 8.dp, end = 8.dp, top = 8.dp, bottom = 8.dp)
                                .background(getSelectedColor(2), shape = RoundedCornerShape(20.dp))
                                .clickable {
                                    selectedIndex.value = 2
                                },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Center
                        ) {
                            TextRow(
                                "",
                                "Feature coming soon"
                            )
                        }
                    }
                }
            }
        }
    }
}