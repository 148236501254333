package feature.alert.repository

import kotlinx.coroutines.flow.MutableStateFlow

class AlertRepository {
    val timer: MutableStateFlow<Int> = MutableStateFlow(0)
    val alertTimer: MutableStateFlow<Int> = MutableStateFlow(0)
    val toastTimer: MutableStateFlow<Int> = MutableStateFlow(0)

    val isVisible: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val isAlertVisible: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val isToastVisible: MutableStateFlow<Boolean> = MutableStateFlow(false)

    val isSuccess: MutableStateFlow<Boolean> = MutableStateFlow(false)
    val isConfirmation: MutableStateFlow<Boolean> = MutableStateFlow(false)

    val title: MutableStateFlow<String> = MutableStateFlow("")
    val message: MutableStateFlow<String> = MutableStateFlow("")

    val onPositiveClick: MutableStateFlow<() -> Unit> = MutableStateFlow({ })
    val onNegativeClick: MutableStateFlow<() -> Unit> = MutableStateFlow({ })
}