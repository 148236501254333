package presentation.screens.business.invoice.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import appConfig.AppConfig
import component.visual.DottedLine
import feature.invoice.model.CustomerInvoice
import theme.ThemeColor

@Composable
fun InvoiceFooter(invoice: CustomerInvoice?) {
    Column(
        modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(start = 8.dp, end = 8.dp)
            .background(color = ThemeColor.shared().cardBackground)
    ) {
        Spacer(
            modifier = Modifier.height(15.dp)
                .fillMaxWidth()
                .background(color = ThemeColor.shared().White),
        )
        DottedLine()
        Row(
            modifier = Modifier
                .background(color = ThemeColor.shared().White)
                .fillMaxWidth().padding(start = 8.dp, end = 8.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Text(
                text = "Subtotal",
                modifier = Modifier.fillMaxWidth(.45F),
                fontWeight = FontWeight(500),
                fontSize = 15.sp,
                textAlign = TextAlign.Left,
            )
            Text(
                text = "${AppConfig.shared().currencySymbol} ${invoice?.totalPrice}",
                fontWeight = FontWeight(500),
                fontSize = 15.sp,
                textAlign = TextAlign.Left,
            )
        }
        Spacer(
            modifier = Modifier.height(15.dp)
                .fillMaxWidth()
                .background(color = ThemeColor.shared().White),
        )
        Row(
            modifier = Modifier
                .background(color = ThemeColor.shared().White)
                .fillMaxWidth().padding(start = 8.dp, end = 8.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Text(
                text = "Tax",
                modifier = Modifier.fillMaxWidth(.45F),
                fontWeight = FontWeight(500),
                fontSize = 15.sp,
                textAlign = TextAlign.Left,
            )
            Text(
                text = "${AppConfig.shared().currencySymbol} ${invoice?.Tax}",
                fontWeight = FontWeight(500),
                fontSize = 15.sp,
                textAlign = TextAlign.Left,
            )
        }
        DottedLine()
        Row(
            modifier = Modifier
                .background(color = ThemeColor.shared().White)
                .fillMaxWidth().padding(start = 8.dp, end = 8.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Text(
                text = "Total",
                modifier = Modifier.fillMaxWidth(.45F),
                fontWeight = FontWeight(500),
                fontSize = 18.sp,
                textAlign = TextAlign.Left,
                color = ThemeColor.shared().PrimaryColor
            )
            Text(
                text = "${AppConfig.shared().currencySymbol} ${invoice?.finalPrice}",
                fontWeight = FontWeight(500),
                fontSize = 18.sp,
                textAlign = TextAlign.Left,
                color = ThemeColor.shared().PrimaryColor
            )
        }
        DottedLine()
    }
}