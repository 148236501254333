package presentation.screens.business.inventory.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.inventory.model.coupon.Coupon
import feature.inventory.viewModel.coupon.CoupanViewModel
import theme.ThemeColor

@Composable
fun SelectCoupanRow(selected: String?, onChange: (Coupon) -> Unit = {}) {
    val viewModel = CoupanViewModel()
    val allCoupan = viewModel.allCoupan.collectAsState()
    LaunchedEffect(key1 = Unit) {
        viewModel.loadAllCoupan()
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("Coupan")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allCoupan.value.count()) {
                HorizontalCoupanCard(
                    allCoupan.value[it],
                    isSelected = selected == allCoupan.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}