package native

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AddAPhoto
import androidx.compose.material.icons.filled.Image
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import component.image.AsyncImageView
import component.nativeWebView.NativeWebView
import feature.mediaFile.model.MediaFile
import feature.mediaFile.viewModel.MediaFileViewModel
import native.files.FilePicker
import theme.ThemeColor

@Composable
fun FilePickerView(
    id: String?,
    mediaFile: MediaFile?,
    isEditable: Boolean,
    viewModel: MediaFileViewModel
) {
    var image: String? by remember { mutableStateOf(null) }
    LaunchedEffect(key1 = Unit) {
        FilePicker().imagePicker.onImagePicked = {
            if (it.isNotEmpty()) {
                image = it
                if (id?.isNotEmpty() == true) {

                }
                viewModel.uploadImage(it, id!!) { mediaFile ->
                    image = mediaFile?.FileURL
                }
            }
        }
    }
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .background(ThemeColor.shared().White)
            .clickable {
                if (isEditable) {
                    FilePicker().imagePicker.pickImage()
                }
            }
            .padding(0.dp),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize(),
//                .clip(CircleShape),
            contentAlignment = Alignment.Center
        ) {
            if (image != null) {
                NativeWebView(base64 = image)
            } else if (mediaFile?.FileURL?.isNotEmpty() == true) {
                AsyncImageView(mediaFile.FileURL!!)
            } else {
                Box(
                    modifier = Modifier
                        .fillMaxSize().padding(0.dp)
                        .clip(CircleShape),
                    contentAlignment = Alignment.Center
                ) {
                    IconButton(
                        onClick = {
                            if (isEditable) {
                                FilePicker().imagePicker.pickImage()
                            }
                        },
                        modifier = Modifier
                            .fillMaxWidth()
                            .fillMaxHeight()
                    ) {
                        if (isEditable) {
                            Icon(
                                Icons.Default.AddAPhoto,
                                contentDescription = "Add Photo",
                                modifier = Modifier.fillMaxSize(0.6F),
                                tint = ThemeColor.shared().PrimaryColor,

                                )
                        } else {
                            Icon(
                                Icons.Default.Image,
                                contentDescription = "Image not available",
                                modifier = Modifier.fillMaxSize(0.6F),
                                tint = ThemeColor.shared().PrimaryColor,
                            )
                        }
                    }
                }
            }
        }
    }
}

