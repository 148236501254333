package presentation.screens.business.employee.create

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.DropdownMenuItem
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.Email
import androidx.compose.material.icons.filled.Phone
import androidx.compose.material.icons.filled.Shield
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.Icon
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import component.button.DefaultButton
import component.country.DialCodeHelper
import component.image.ImageView
import component.input.MobileTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import component.visual.DottedLine
import feature.alert.handler.AlertHandler
import feature.employee.viewModel.EmployeeViewModel
import locale.localization
import theme.ThemeColor

class CreateEmployeeScreen : Screen {

    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = EmployeeViewModel()
        val profileResult = viewModel.profile.collectAsState()
        val jobRoleResult = viewModel.allJobRole.collectAsState()

        var mobile by remember { mutableStateOf(TextFieldValue("")) }
        var dialCode by remember { mutableStateOf(DialCodeHelper().getSelectedCountryCode()) }
        val mobileErrorState = remember {
            mutableStateOf(false)
        }
        var isValidMobile by remember { mutableStateOf(false) }

        var showJobRole by remember { mutableStateOf(false) }

        LaunchedEffect(key1 = Unit) {
            viewModel.fetchAllJobRole()
        }

        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = localization().employee.createEmployee, null, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            Column(modifier = Modifier.fillMaxSize()) {
                Spacer(modifier = Modifier.height(8.dp))
                MobileTextField(
                    placeholder = "9000000000",
                    trailingIcon = ImageAsset.phone,
                    label = localization().employee.employeeMobileNumber,
                    errorState = mobileErrorState,
                    visualTransformation = VisualTransformation.None,
                    onChanged = { mob ->
                        mobile = mob
                        isValidMobile = (mob.text.length in 9..11)
                    },
                    onDialCodeChange = {
                        dialCode = it
                    }
                )
                Spacer(modifier = Modifier.height(8.dp))
                CustomDefaultBtn(
                    shapeSize = 50f,
                    btnText = "Search"
                ) {
                    mobileErrorState.value = !isValidMobile
                    if (isValidMobile) {
                        viewModel.findUser(
                            dialCode.countryPhoneCode, mobile.text
                        ) { result ->
                            if (result != null) {

                            } else {
                                AlertHandler.shared().showAlert(
                                    localization().employee.createAlertTitle,
                                    localization().employee.createAlertMessage
                                )
                            }
                        }
                    } else {
                        AlertHandler.shared().showAlert(
                            localization().employee.createAlertTitle,
                            localization().employee.createAlertMessage
                        )
                    }
                }
                if (profileResult.value?.Id?.isNotBlank() == true) {
                    Surface(
                        shape = RoundedCornerShape(16.dp),
                        color = ThemeColor.shared().cardBackground,
                        modifier = Modifier
                            .height(194.dp)
                            .padding(start = 8.dp, top = 8.dp, end = 8.dp),
                        shadowElevation = 4.dp,
                    ) {
                        Column(
                            modifier = Modifier
                                .fillMaxWidth()
                                .fillMaxHeight()
                                .padding(start = 8.dp, end = 8.dp, top = 8.dp),

                            ) {
                            Row(
                                modifier = Modifier
                                    .height(88.dp).fillMaxWidth()
                                    .background(
                                        color = ThemeColor.shared().cardLightGrayContentBackground,
                                        shape = RoundedCornerShape(8.dp)
                                    )
                                    .padding(start = 8.dp, top = 8.dp, end = 8.dp)
                            ) {
                                Box(
                                    modifier = Modifier
                                        .height(57.dp).width(57.dp)
                                        .background(
                                            color = ThemeColor.shared().cardLightGrayContentBackground,
                                            shape = RoundedCornerShape(8.dp)
                                        )
                                        .padding(start = 8.dp, top = 8.dp, end = 8.dp)
                                ) {
                                    ImageView(profileResult.value!!.Id)
                                }
                                Column(
                                    Modifier.weight(0.5f).fillMaxHeight().padding(start = 8.dp)
                                ) {
                                    Row(modifier = Modifier.height(28.dp)) {
                                        Text(
                                            text = profileResult.value?.Name ?: "",
                                            maxLines = 1,
                                            overflow = TextOverflow.Ellipsis,
                                            modifier = Modifier
                                                .height(85.dp).fillMaxWidth(),
                                            textAlign = TextAlign.Start,
                                        )
                                    }
                                    Row(modifier = Modifier.height(23.dp).fillMaxWidth()) {
                                        Icon(
                                            Icons.Default.Email,
                                            contentDescription = "Email",
                                            modifier = Modifier.width(23.dp).height(23.dp)
                                                .padding(top = 3.dp),
                                            tint = ThemeColor.shared().lightGray
                                        )
                                        Text(
                                            text = profileResult.value?.EmailID ?: "",
                                            maxLines = 2,
                                            overflow = TextOverflow.Ellipsis,
                                            modifier = Modifier
                                                .height(20.dp).fillMaxWidth(),
                                            textAlign = TextAlign.Start,
                                            color = ThemeColor.shared().lightGray
                                        )
                                    }
                                    Row(
                                        modifier = Modifier.height(23.dp).weight(0.5f)
                                            .fillMaxWidth(),
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Icon(
                                            Icons.Default.Phone,
                                            contentDescription = "Phone",
                                            modifier = Modifier.width(23.dp).height(23.dp)
                                                .padding(top = 3.dp),
                                            tint = ThemeColor.shared().lightGray
                                        )
                                        Text(
                                            text = (profileResult.value?.DialCode
                                                ?: "") + (profileResult.value?.MobileNumber ?: ""),
                                            maxLines = 1,
                                            overflow = TextOverflow.Ellipsis,
                                            modifier = Modifier
                                                .wrapContentHeight().fillMaxWidth(),
                                            textAlign = TextAlign.Start,
                                            fontSize = 12.sp,
                                            color = ThemeColor.shared().lightGray
                                        )
                                    }
                                }
                            }
                            DottedLine()
                            Row(
                                modifier = Modifier.height(94.dp),
                                horizontalArrangement = Arrangement.SpaceBetween,
                                verticalAlignment = Alignment.CenterVertically,
                            ) {
                                Icon(
                                    Icons.Default.Shield,
                                    contentDescription = "Job Role",
                                    modifier = Modifier.width(23.dp).height(23.dp)
                                        .padding(top = 3.dp),
                                    tint = ThemeColor.shared().lightGray
                                )
                                Row(
                                    horizontalArrangement = Arrangement.SpaceBetween,
                                    verticalAlignment = Alignment.CenterVertically,
                                    modifier = Modifier.background(ThemeColor.shared().cardBackground)
                                        .fillMaxHeight().fillMaxWidth()
                                ) {
                                    Text(
                                        profileResult.value?.JobTypeName ?: "Select Job Role",
                                        color = ThemeColor.shared().Black,
                                        modifier = Modifier.clickable {
                                            showJobRole = true
                                        }
                                    )
                                    Icon(
                                        imageVector = Icons.Default.ArrowDropDown,
                                        contentDescription = null
                                    )
                                    Box(
                                        modifier = Modifier.fillMaxHeight().width(130.dp)
                                            .height(40.dp).padding(top = 15.dp)
                                    ) {
                                        DefaultButton(
                                            "Connect", {
                                                if (profileResult.value?.JobTypeName.isNullOrBlank()) {
                                                    AlertHandler.shared().showAlert(
                                                        "Alert!",
                                                        "Please select employee job role first",
                                                    )
                                                } else {
                                                    AlertHandler.shared().showConfirmationAlert(
                                                        "Confirmation!",
                                                        "Are you sure ? You want to add " + profileResult.value?.Name + " as an employee",
                                                        onNegativeClick = {
                                                            print("onNegativeClick")
                                                        },
                                                        onPositiveClick = {
                                                            print("onPositiveClick")
                                                            viewModel.addEmployee(profileResult.value) {
                                                                if (it) {
                                                                    navigator?.pop()
                                                                } else {
//                                                            show some alert
                                                                }
                                                            }
                                                        }
                                                    )
                                                }
                                            }
                                        )
                                    }
                                }
                                DropdownMenu(
                                    expanded = showJobRole,
                                    onDismissRequest = { showJobRole = false }
                                ) {
                                    jobRoleResult.value.forEach { (id, name) ->
                                        DropdownMenuItem(
                                            onClick = {
                                                showJobRole = false
                                                profileResult.value?.JobTypeName = name
                                                profileResult.value?.JobTypeId = id
                                            }
                                        ) {
                                            if (name != null) {
                                                Text(name)
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}