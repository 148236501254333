package presentation.screens.business.inventory.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import component.text.TextTitle
import feature.inventory.model.discount.Discount
import feature.inventory.viewModel.discount.DiscountViewModel
import theme.ThemeColor

@Composable
fun SelectDiscountRow(selected: String?, onChange: (Discount) -> Unit = {}) {
    val viewModel = DiscountViewModel()
    val allDiscount = viewModel.allDiscount.collectAsState()
    LaunchedEffect(key1 = Unit) {
        viewModel.loadAllDiscount()
    }
    Column(
        modifier = Modifier.fillMaxWidth().height(90.dp)
            .background(ThemeColor.shared().defaultBakground)
    ) {
        TextTitle("Discount")
        Spacer(modifier = Modifier.height(4.dp))
        LazyRow(
            modifier = Modifier.fillMaxWidth().height(50.dp)
                .background(ThemeColor.shared().defaultBakground)
        ) {
            items(allDiscount.value.count()) {
                HorizontalDiscountCard(
                    allDiscount.value[it],
                    isSelected = selected == allDiscount.value[it].Id
                ) {
                    onChange(it)
                }
            }
        }
    }

}