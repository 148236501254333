package feature.productCategory.network

import feature.productCategory.model.ProductCategory
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveProductCategoryResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<ProductCategory>,
)

@Serializable
data class CreateProductCategoryResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: ProductCategory?,
)

@Serializable
data class UpdateProductCategoryResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: ProductCategory?,
)