package feature.order.repository

import feature.order.model.Order
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class OrderRepository {
    val order: MutableStateFlow<Order> = MutableStateFlow<Order>(Order())
    val allMutableOrder: MutableStateFlow<List<Order>> =
        MutableStateFlow(ArrayList())
    val allOrder: StateFlow<List<Order>>
        get() = allMutableOrder

}