package presentation.screens.business.homepage.component

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowCircleRight
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import navigation.AppNavigator
import navigation.containers.ScreenName
import theme.ThemeColor

@Composable
fun AffordablePlanCard(days: Int) {
    var headerTitle = ""
    var pricing = ""
    var message1 = ""
    var message2 = ""
    when (days) {
        7 -> {
            headerTitle = "Starter"
            pricing = "₹ 0 "
            message1 = "7 Days Trial Plan"
            message2 = "Completely free"
        }

        30 -> {
            headerTitle = "Essential"
            pricing = "₹ 199"
            message1 = "It is monthly rental plan."
            message2 = "30 days plan."
        }

        90 -> {
            headerTitle = "Pro"
            pricing = "₹ 499"
            message1 = "It is quarterly rental plan."
            message2 = "90 days plan."
        }

        180 -> {
            headerTitle = "Advanced"
            pricing = "₹ 799"
            message1 = "It is half yearly paid plan"
            message2 = "180 days plan."
        }

        270 -> {
            headerTitle = "Premium"
            pricing = "₹ 1099"
            message1 = "It is 9 month paid plan."
            message2 = "270 days plan."
        }

        365 -> {
            headerTitle = "Ultimate"
            pricing = "₹ 1399"
            message1 = "It is yearly rental plan."
            message2 = "365 days plan."
        }
    }
    Card(
        modifier = Modifier
            .width(360.dp)
            .height(400.dp)
            .padding(top = 20.dp, start = 20.dp)
            .background(ThemeColor.shared().cardBackground, shape = RoundedCornerShape(8.dp)).clip(
                RoundedCornerShape(8.dp)
            ),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black,
            disabledContainerColor = ThemeColor.shared().cardBackground
        ),
        elevation = CardDefaults.cardElevation(
            defaultElevation = 20.dp
        ),
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = 30.dp, end = 30.dp, top = 8.dp),
        ) {
            Text(
                text = headerTitle,
                textAlign = TextAlign.Center,
                fontSize = 42.sp,
                color = Color.Black,
                fontWeight = FontWeight.Medium,
                fontStyle = FontStyle.Normal,
                modifier = Modifier
                    .padding(top = 20.dp)
                    .align(Alignment.CenterHorizontally)
            )
            Text(
                text = pricing,
                textAlign = TextAlign.Center,
                fontSize = 25.sp,
                color = Color.Gray,
                fontWeight = FontWeight.Bold,
                fontStyle = FontStyle.Normal,
                modifier = Modifier
                    .padding(top = 20.dp)
                    .align(Alignment.CenterHorizontally)
            )
            Box(
                modifier = Modifier.height(21.dp).fillMaxWidth()
                    .padding(start = 30.dp, end = 30.dp, top = 10.dp, bottom = 10.dp)
                    .background(ThemeColor.shared().lightGray)
            )
            Row(
                modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(top = 20.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Icon(
                    Icons.Filled.ArrowCircleRight,
                    contentDescription = "Add to cart",
                    tint = ThemeColor.shared().Grey
                )
                Text(
                    text = message1,
                    fontSize = 15.sp,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    fontStyle = FontStyle.Normal,
                    modifier = Modifier.padding(start = 12.dp)

                )
            }
            Row(
                modifier = Modifier.fillMaxWidth().wrapContentHeight().padding(top = 20.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Icon(
                    Icons.Filled.ArrowCircleRight,
                    contentDescription = "Add to cart",
                    tint = ThemeColor.shared().Grey
                )
                Text(
                    text = message2,
                    fontSize = 15.sp,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    fontStyle = FontStyle.Normal,
                    modifier = Modifier.padding(start = 12.dp)

                )
            }

            Row(
                modifier = Modifier.fillMaxWidth().wrapContentHeight(),
                horizontalArrangement = Arrangement.Center
            ) {
                Row(
                    modifier = Modifier
                        .padding(top = 50.dp)
                        .width(200.dp)
                        .height(50.dp)
                        .border(
                            BorderStroke(2.dp, color = ThemeColor.shared().PrimaryColor),
                            shape = RoundedCornerShape(12.dp)
                        )
                        .clip(RoundedCornerShape(12.dp))
                        .background(ThemeColor.shared().PrimaryColor)
                        .clickable {
                            AppNavigator.shared().navigateTo(
                                ScreenName.LOGIN
                            )
                        },
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                )
                {
                    Text(
                        text = " Choose this plan",
                        fontSize = 15.sp,
                        color = Color.White,
                        fontWeight = FontWeight.Bold,
                        fontStyle = FontStyle.Normal,
                        modifier = Modifier.wrapContentHeight().wrapContentWidth()
                            .padding(end = 12.dp)
                    )
                    Icon(
                        Icons.Filled.ArrowCircleRight,
                        contentDescription = "Add to cart",
                        tint = ThemeColor.shared().White
                    )
                }
            }
        }
    }
}




