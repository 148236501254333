package feature.inventory.repository

import feature.inventory.model.tag.Tag
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class TagRepository {
    val tagLiveData: MutableStateFlow<List<Tag>> = MutableStateFlow(arrayListOf())
    val alltag: StateFlow<List<Tag>>
        get() = tagLiveData

    val selectedTagState: MutableStateFlow<Tag?> = MutableStateFlow(null)
    val selectedTag: StateFlow<Tag?>
        get() = selectedTagState

}