package feature.customer.handler

import feature.customer.repository.CustomerRepository
import feature.customer.viewModel.CustomerViewModel


class CustomerHandler {
    var viewModel: CustomerViewModel? = null
    val repository = CustomerRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: CustomerHandler? = null
        fun shared(): CustomerHandler {
            if (instance != null) {
                return instance as CustomerHandler
            } else {
                return CustomerHandler()
            }
        }
    }
}