package component.input

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import component.image.FriendlyIcon
import theme.ThemeColor

@Composable
fun CustomTextField(
    value: String = "",
    placeholder: String, trailingIcon: String,
    label: String,
    keyboardType: KeyboardType,
    visualTransformation: VisualTransformation,
    errorState: Boolean = false,
    onChanged: (TextFieldValue) -> Unit
) {
    //state
    var text by remember {
        mutableStateOf(TextFieldValue(value))
    }

    OutlinedTextField(
        value = text,
        onValueChange = { newText ->
            text = newText
            onChanged(newText)
        },
        placeholder = {
            Text(text = placeholder)
        },
        label = { Text(text = label) },
        shape = RoundedCornerShape(1.dp),
        trailingIcon = {
            FriendlyIcon(name = trailingIcon)
        },
        singleLine = true,
        colors = OutlinedTextFieldDefaults.colors(
            //            trailingIconColor = if (text.text.isNotEmpty()) Gray else TextColor,
            cursorColor = ThemeColor.shared().PrimaryColor,
            focusedBorderColor = ThemeColor.shared().PrimaryColor,
            focusedLabelColor = ThemeColor.shared().PrimaryColor,
            focusedContainerColor = ThemeColor.shared().cardBackground,
            unfocusedContainerColor = ThemeColor.shared().cardBackground,
//            color = TextColor
        ),
        visualTransformation = visualTransformation,
        keyboardOptions = KeyboardOptions.Default.copy(keyboardType = keyboardType),
        isError = errorState,
        modifier = Modifier
            .fillMaxWidth().padding(start = 8.dp, end = 8.dp)
    )
}