package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.cartItemTable.LastSyncDate
import kotlin.Any
import kotlin.Double
import kotlin.Long
import kotlin.String

public class CartItemTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    UniqueID: Long,
    _id: String?,
    UserID: String?,
    BusinessID: String?,
    SelectedBusinessID: String?,
    CustomerId: String?,
    ProductID: String?,
    Product: String?,
    Price: String?,
    Quantity: Long,
    FinalPrice: Double,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieve(BusinessID: String?): Query<CartItemTable> = retrieve(BusinessID) { UniqueID,
      _id, UserID, BusinessID_, SelectedBusinessID, CustomerId, ProductID, Product, Price, Quantity,
      FinalPrice, CreatedAt, UpdatedAt ->
    CartItemTable(
      UniqueID,
      _id,
      UserID,
      BusinessID_,
      SelectedBusinessID,
      CustomerId,
      ProductID,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForProduct(ProductID: String?, mapper: (
    UniqueID: Long,
    _id: String?,
    UserID: String?,
    BusinessID: String?,
    SelectedBusinessID: String?,
    CustomerId: String?,
    ProductID: String?,
    Product: String?,
    Price: String?,
    Quantity: Long,
    FinalPrice: Double,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveForProductQuery(ProductID) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveForProduct(ProductID: String?): Query<CartItemTable> =
      retrieveForProduct(ProductID) { UniqueID, _id, UserID, BusinessID, SelectedBusinessID,
      CustomerId, ProductID_, Product, Price, Quantity, FinalPrice, CreatedAt, UpdatedAt ->
    CartItemTable(
      UniqueID,
      _id,
      UserID,
      BusinessID,
      SelectedBusinessID,
      CustomerId,
      ProductID_,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForUser(
    SelectedBusinessID: String?,
    UserID: String?,
    mapper: (
      UniqueID: Long,
      _id: String?,
      UserID: String?,
      BusinessID: String?,
      SelectedBusinessID: String?,
      CustomerId: String?,
      ProductID: String?,
      Product: String?,
      Price: String?,
      Quantity: Long,
      FinalPrice: Double,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForUserQuery(SelectedBusinessID, UserID) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveForUser(SelectedBusinessID: String?, UserID: String?): Query<CartItemTable> =
      retrieveForUser(SelectedBusinessID, UserID) { UniqueID, _id, UserID_, BusinessID,
      SelectedBusinessID_, CustomerId, ProductID, Product, Price, Quantity, FinalPrice, CreatedAt,
      UpdatedAt ->
    CartItemTable(
      UniqueID,
      _id,
      UserID_,
      BusinessID,
      SelectedBusinessID_,
      CustomerId,
      ProductID,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForGuest(SelectedBusinessID: String?, mapper: (
    UniqueID: Long,
    _id: String?,
    UserID: String?,
    BusinessID: String?,
    SelectedBusinessID: String?,
    CustomerId: String?,
    ProductID: String?,
    Product: String?,
    Price: String?,
    Quantity: Long,
    FinalPrice: Double,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveForGuestQuery(SelectedBusinessID) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveForGuest(SelectedBusinessID: String?): Query<CartItemTable> =
      retrieveForGuest(SelectedBusinessID) { UniqueID, _id, UserID, BusinessID, SelectedBusinessID_,
      CustomerId, ProductID, Product, Price, Quantity, FinalPrice, CreatedAt, UpdatedAt ->
    CartItemTable(
      UniqueID,
      _id,
      UserID,
      BusinessID,
      SelectedBusinessID_,
      CustomerId,
      ProductID,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForMyBusiness(BusinessID: String?, mapper: (
    UniqueID: Long,
    _id: String?,
    UserID: String?,
    BusinessID: String?,
    SelectedBusinessID: String,
    CustomerId: String?,
    ProductID: String?,
    Product: String?,
    Price: String?,
    Quantity: Long,
    FinalPrice: Double,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveForMyBusinessQuery(BusinessID) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4)!!,
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveForMyBusiness(BusinessID: String?): Query<RetrieveForMyBusiness> =
      retrieveForMyBusiness(BusinessID) { UniqueID, _id, UserID, BusinessID_, SelectedBusinessID,
      CustomerId, ProductID, Product, Price, Quantity, FinalPrice, CreatedAt, UpdatedAt ->
    RetrieveForMyBusiness(
      UniqueID,
      _id,
      UserID,
      BusinessID_,
      SelectedBusinessID,
      CustomerId,
      ProductID,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForBusiness(
    BusinessID: String?,
    SelectedBusinessID: String?,
    mapper: (
      UniqueID: Long,
      _id: String?,
      UserID: String?,
      BusinessID: String?,
      SelectedBusinessID: String?,
      CustomerId: String?,
      ProductID: String?,
      Product: String?,
      Price: String?,
      Quantity: Long,
      FinalPrice: Double,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForBusinessQuery(BusinessID, SelectedBusinessID) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveForBusiness(BusinessID: String?, SelectedBusinessID: String?):
      Query<CartItemTable> = retrieveForBusiness(BusinessID, SelectedBusinessID) { UniqueID, _id,
      UserID, BusinessID_, SelectedBusinessID_, CustomerId, ProductID, Product, Price, Quantity,
      FinalPrice, CreatedAt, UpdatedAt ->
    CartItemTable(
      UniqueID,
      _id,
      UserID,
      BusinessID_,
      SelectedBusinessID_,
      CustomerId,
      ProductID,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForCustomer(
    BusinessID: String?,
    CustomerId: String?,
    mapper: (
      UniqueID: Long,
      _id: String?,
      UserID: String?,
      BusinessID: String?,
      SelectedBusinessID: String?,
      CustomerId: String?,
      ProductID: String?,
      Product: String?,
      Price: String?,
      Quantity: Long,
      FinalPrice: Double,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForCustomerQuery(BusinessID, CustomerId) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveForCustomer(BusinessID: String?, CustomerId: String?): Query<CartItemTable> =
      retrieveForCustomer(BusinessID, CustomerId) { UniqueID, _id, UserID, BusinessID_,
      SelectedBusinessID, CustomerId_, ProductID, Product, Price, Quantity, FinalPrice, CreatedAt,
      UpdatedAt ->
    CartItemTable(
      UniqueID,
      _id,
      UserID,
      BusinessID_,
      SelectedBusinessID,
      CustomerId_,
      ProductID,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveAll(mapper: (
    UniqueID: Long,
    _id: String?,
    UserID: String?,
    BusinessID: String?,
    SelectedBusinessID: String?,
    CustomerId: String?,
    ProductID: String?,
    Product: String?,
    Price: String?,
    Quantity: Long,
    FinalPrice: Double,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = Query(-306_176_658, arrayOf("CartItemTable"), driver, "CartItemTable.sq",
      "retrieveAll",
      "SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable") {
      cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveAll(): Query<CartItemTable> = retrieveAll { UniqueID, _id, UserID, BusinessID,
      SelectedBusinessID, CustomerId, ProductID, Product, Price, Quantity, FinalPrice, CreatedAt,
      UpdatedAt ->
    CartItemTable(
      UniqueID,
      _id,
      UserID,
      BusinessID,
      SelectedBusinessID,
      CustomerId,
      ProductID,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String?, mapper: (
    UniqueID: Long,
    _id: String?,
    UserID: String?,
    BusinessID: String?,
    SelectedBusinessID: String?,
    CustomerId: String?,
    ProductID: String?,
    Product: String?,
    Price: String?,
    Quantity: Long,
    FinalPrice: Double,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getString(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9)!!,
      cursor.getDouble(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun find(_id: String?): Query<CartItemTable> = find(_id) { UniqueID, _id_, UserID,
      BusinessID, SelectedBusinessID, CustomerId, ProductID, Product, Price, Quantity, FinalPrice,
      CreatedAt, UpdatedAt ->
    CartItemTable(
      UniqueID,
      _id_,
      UserID,
      BusinessID,
      SelectedBusinessID,
      CustomerId,
      ProductID,
      Product,
      Price,
      Quantity,
      FinalPrice,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun delete(ProductID: String?) {
    driver.execute(null,
        """DELETE FROM CartItemTable WHERE ProductID ${ if (ProductID == null) "IS" else "=" } ?""",
        1) {
          bindString(0, ProductID)
        }.await()
    notifyQueries(235_326_050) { emit ->
      emit("CartItemTable")
    }
  }

  public suspend fun deleteAll() {
    driver.execute(1_211_794_399, """DELETE FROM CartItemTable""", 0).await()
    notifyQueries(1_211_794_399) { emit ->
      emit("CartItemTable")
    }
  }

  public suspend fun insert(
    UniqueID: Long?,
    _id: String?,
    UserID: String?,
    BusinessID: String?,
    SelectedBusinessID: String?,
    CustomerId: String?,
    ProductID: String?,
    Product: String?,
    Price: String?,
    Quantity: Long,
    FinalPrice: Double,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(386_991_984, """
        |INSERT OR REPLACE INTO CartItemTable( UniqueID,_id ,UserID, BusinessID,SelectedBusinessID,CustomerId,ProductID,Product,Price,Quantity,FinalPrice,CreatedAt,UpdatedAt)
        |    VALUES(?, ?,?, ? ,?, ?, ?,?,?,?,?,?,?)
        """.trimMargin(), 13) {
          bindLong(0, UniqueID)
          bindString(1, _id)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindString(4, SelectedBusinessID)
          bindString(5, CustomerId)
          bindString(6, ProductID)
          bindString(7, Product)
          bindString(8, Price)
          bindLong(9, Quantity)
          bindDouble(10, FinalPrice)
          bindString(11, CreatedAt)
          bindString(12, UpdatedAt)
        }.await()
    notifyQueries(386_991_984) { emit ->
      emit("CartItemTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "CartItemTable.sq:retrieve"
  }

  private inner class RetrieveForProductQuery<out T : Any>(
    public val ProductID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable WHERE ProductID ${ if (ProductID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, ProductID)
    }

    override fun toString(): String = "CartItemTable.sq:retrieveForProduct"
  }

  private inner class RetrieveForUserQuery<out T : Any>(
    public val SelectedBusinessID: String?,
    public val UserID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable WHERE SelectedBusinessID ${ if (SelectedBusinessID == null) "IS" else "=" } ? AND UserID ${ if (UserID == null) "IS" else "=" } ?""",
        mapper, 2) {
      bindString(0, SelectedBusinessID)
      bindString(1, UserID)
    }

    override fun toString(): String = "CartItemTable.sq:retrieveForUser"
  }

  private inner class RetrieveForGuestQuery<out T : Any>(
    public val SelectedBusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable WHERE SelectedBusinessID ${ if (SelectedBusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, SelectedBusinessID)
    }

    override fun toString(): String = "CartItemTable.sq:retrieveForGuest"
  }

  private inner class RetrieveForMyBusinessQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND SelectedBusinessID IS NOT NULL AND SelectedBusinessID <> ''""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "CartItemTable.sq:retrieveForMyBusiness"
  }

  private inner class RetrieveForBusinessQuery<out T : Any>(
    public val BusinessID: String?,
    public val SelectedBusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND SelectedBusinessID ${ if (SelectedBusinessID == null) "IS" else "=" } ?""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, SelectedBusinessID)
    }

    override fun toString(): String = "CartItemTable.sq:retrieveForBusiness"
  }

  private inner class RetrieveForCustomerQuery<out T : Any>(
    public val BusinessID: String?,
    public val CustomerId: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND CustomerId ${ if (CustomerId == null) "IS" else "=" } ?""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, CustomerId)
    }

    override fun toString(): String = "CartItemTable.sq:retrieveForCustomer"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT CartItemTable.UniqueID, CartItemTable._id, CartItemTable.UserID, CartItemTable.BusinessID, CartItemTable.SelectedBusinessID, CartItemTable.CustomerId, CartItemTable.ProductID, CartItemTable.Product, CartItemTable.Price, CartItemTable.Quantity, CartItemTable.FinalPrice, CartItemTable.CreatedAt, CartItemTable.UpdatedAt FROM CartItemTable WHERE _id ${ if (_id == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "CartItemTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("CartItemTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("CartItemTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM CartItemTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "CartItemTable.sq:lastSyncDate"
  }
}
