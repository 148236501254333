package feature.device.repository

import feature.device.model.Device
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class DeviceRepository {

    val liveData: MutableStateFlow<List<Device>> = MutableStateFlow(arrayListOf())
    val allLiveData: StateFlow<List<Device>>
        get() = liveData

    val currentDeviceState: MutableStateFlow<Device> = MutableStateFlow(Device())
    val currentDevice: StateFlow<Device>
        get() = currentDeviceState

    val selectedLiveData: MutableStateFlow<Device> = MutableStateFlow(Device())
    val selected: StateFlow<Device>
        get() = selectedLiveData

    val mediaFileLiveData: MutableStateFlow<Device> = MutableStateFlow(Device())

    val DeviceLiveData: MutableStateFlow<List<Device>> = MutableStateFlow(arrayListOf())
    val mediaFiles: StateFlow<List<Device>>
        get() = DeviceLiveData

}