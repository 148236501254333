package presentation.screens.customer.store.component

import androidx.compose.animation.core.animateDpAsState
import androidx.compose.animation.core.tween
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.ArrowUpward
import androidx.compose.material.icons.rounded.MyLocation
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.Icon
import androidx.compose.material3.RadioButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import appConfig.AppConfig
import component.button.DefaultButton
import component.dimension.ScreenDimension
import feature.address.model.Address
import feature.address.viewModel.AddressViewModel
import feature.app.NavigationParam
import feature.business.utill.AddressUtill
import feature.cart.model.DeliveryOption
import presentation.screens.customer.store.address.SelectAddressView
import theme.ThemeColor

@Composable
fun CartDeliveryOption(
    param: NavigationParam?,
    addressViewModel: AddressViewModel,
    totalAmount: Double,
    deliveryOption: DeliveryOption,
    isOpen: Boolean,
    onExpandCollapse: () -> Unit = {},
    onChange: (DeliveryOption) -> Unit,
    onClick: (Address?) -> Unit = {}
) {
    var isDelivery: Boolean by remember { mutableStateOf(deliveryOption.isDelivery) }

    val columnHeightPx by remember {
        mutableStateOf(ScreenDimension().height(1f) + 200.dp)
    }
    val density = LocalDensity.current
    val heightInDp = animateDpAsState(
        targetValue = if (isOpen) with(density) { columnHeightPx } else if (isDelivery) 180.dp else 130.dp,
        animationSpec = tween(
            durationMillis = 1500,
        )
    )
    ElevatedCard(
        modifier = Modifier.fillMaxWidth()
            .height(heightInDp.value),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black
        ),
        onClick = { }
    ) {
        if (isOpen) {
            SelectAddressView(param, addressViewModel, {
                onExpandCollapse()
            }) { address ->
                param?.address = address
                onExpandCollapse()
            }
        } else {
            Column(modifier = Modifier.fillMaxHeight().fillMaxWidth()) {
                if (isDelivery) {
                    Row(
                        modifier = Modifier.fillMaxWidth().height(50.dp)
                            .padding(start = 16.dp, top = 8.dp)
                    ) {
                        Icon(
                            Icons.Rounded.MyLocation,
                            contentDescription = "edit item",
                            tint = ThemeColor.shared().PrimaryColor,
                            modifier = Modifier.width(40.dp).height(40.dp)
                        )
                        Column(modifier = Modifier.fillMaxHeight().weight(1f)) {
                            Row(
                                modifier = Modifier.fillMaxWidth().wrapContentHeight()
                                    .padding(start = 16.dp)
                                    .clickable {
                                        onExpandCollapse()
                                    }) {
                                if (param?.address != null) {
                                    Text(
                                        "Delivery At",
                                        modifier = Modifier.wrapContentWidth()
                                    )
                                } else {
                                    Text("Select Address", modifier = Modifier.wrapContentWidth())
                                }
                                Icon(
                                    Icons.Rounded.ArrowUpward,
                                    contentDescription = "edit item",
                                    tint = ThemeColor.shared().PrimaryColor,
                                )
                            }
                            if (param?.address != null) {
                                Text(
                                    AddressUtill().getAddressToDisplayInSingleLine(param.address),
                                    modifier = Modifier.fillMaxWidth().padding(start = 16.dp),
                                    maxLines = 1,
                                    color = ThemeColor.shared().PrimaryColor
                                )
                            } else {
                                Text(
                                    "Tap to Select Address",
                                    maxLines = 1,
                                    color = ThemeColor.shared().PrimaryColor,
                                    modifier = Modifier.fillMaxWidth().padding(16.dp)
                                )
                            }
                        }
                    }
                }
                Row(modifier = Modifier.fillMaxWidth().height(80.dp).padding(16.dp)) {
                    Row(
                        modifier = Modifier.weight(0.5f).padding(4.dp).fillMaxHeight()
                            .fillMaxWidth()
                            .border(
                                1.dp, ThemeColor.shared().PrimaryColor,
                                RoundedCornerShape(8.dp)
                            ).clickable {
                                isDelivery = true
                                deliveryOption.isDelivery = isDelivery
                                onChange(deliveryOption)
                            },
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        RadioButton(selected = isDelivery, {})
                        Text("Delivery", color = ThemeColor.shared().PrimaryColor)
                    }
                    Row(
                        modifier = Modifier.weight(0.5f).padding(4.dp).fillMaxHeight()
                            .fillMaxWidth()
                            .border(
                                1.dp, ThemeColor.shared().PrimaryColor,
                                RoundedCornerShape(8.dp)
                            ).clickable {
                                isDelivery = false
                                deliveryOption.isDelivery = isDelivery
                                onChange(deliveryOption)
                            },
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        RadioButton(selected = !isDelivery, {})
                        Text("Take away", color = ThemeColor.shared().PrimaryColor)
                    }
                }
                DefaultButton(
                    title = "${AppConfig.shared().currencySymbol} $totalAmount Buy Now",
                    onClick = { onClick(param?.address) },
                    modifier = null
                )
            }
        }
    }
}