package feature.business.model


import feature.address.model.Address
import feature.address.model.LocationCoordinate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Business(
    @SerialName("_id") var Id: String = "",
    @SerialName("BusinessNumber") var BusinessNumber: Long? = null,
    @SerialName("UserID") var UserID: String? = null,
    @SerialName("BusinessTypeID") var BusinessTypeID: String? = null,
    @SerialName("Name") var Name: String? = null,
    @SerialName("Address") var Address: Address? = null,
    @SerialName("Location") var Location: LocationCoordinate? = null,
    @SerialName("DealerName") var DealerName: String? = null,
    @SerialName("ProductTypes") var ProductTypes: ArrayList<String> = arrayListOf(),
    @SerialName("GSTNumber") var GSTNumber: String? = null,
    @SerialName("PanNumber") var panNumber: String? = null,
    @SerialName("PanVerified") var panVerified: String? = null,
    @SerialName("GstVerified") var gstVerified: String? = null,
    @SerialName("BusinessVerified") var businessVerified: String? = null,
    @SerialName("Status") var Status: String? = null,
    @SerialName("EmailID") var EmailID: String? = null,
    @SerialName("MobileNumber") var mobileNumber: String? = null,
    @SerialName("DialCode") var DialCode: String? = null,
    @SerialName("Gender") var Gender: Long? = null,
    @SerialName("DeviceID") var DeviceID: String? = null,
    @SerialName("FCMToken") var FCMToken: String? = null,
    @SerialName("LastSeen") var LastSeen: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("IsPrimary") var IsPrimary: Boolean? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)