package feature.auth.network

import common.constants.Server
import feature.auth.event.AuthEvent
import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpTimeout
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import network.FriendlyClient

class AuthNetwork {
    val client = HttpClient {
        install(HttpTimeout) {
            this.requestTimeoutMillis = 15000
        }
    }
    val json = Json { ignoreUnknownKeys = true }

    init {
        instance = this
    }

    companion object {
        private var instance: AuthNetwork? = null
        fun shared(): AuthNetwork {
            if (instance != null) {
                return instance as AuthNetwork
            } else {
                return AuthNetwork()
            }
        }
    }


    suspend fun checkAccount(request: CheckAccountRequest, onResult: (AuthResponse?) -> Unit) {
        val otpRoute = Server.getRestApiEndPointFor(AuthEvent.CHECK_ACCOUNT)
        try {
            val response = FriendlyClient.post(otpRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(Json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }

    suspend fun setPassword(
        request: SetPasswordRequest,
        onResult: (SetPasswordResponse?) -> Unit
    ) {
        val otpRoute = Server.getRestApiEndPointFor(AuthEvent.SET_PASSWORD)
        try {
            val response = FriendlyClient.post(otpRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }

    suspend fun sendOTP(request: SendOTPRequest, onResult: (OTPResponse?) -> Unit) {
        val otpRoute = Server.getRestApiEndPointFor(AuthEvent.SEND_OTP)
        try {
            val response = FriendlyClient.post(otpRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }

    suspend fun login(request: AuthRequest, onResult: (AuthResponse?) -> Unit) {
        val otpRoute = Server.getRestApiEndPointFor(AuthEvent.AUTHENTICATE)
        try {
            val response = FriendlyClient.post(otpRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }

    }

    suspend fun register(request: AuthRequest, onResult: (AuthResponse?) -> Unit) {
        val apiRoute = Server.getRestApiEndPointFor(AuthEvent.AUTHENTICATE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }

    suspend fun updateToken(
        request: UpdateFCMTokenRequest,
        onResult: (UpdateFCMTokenResponse?) -> Unit
    ) {
        val updateTokenRoute = Server.getRestApiEndPointFor(AuthEvent.UPDATE_FCM_TOKEN)
        try {
            val response = FriendlyClient.post(updateTokenRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
        }
    }

    suspend fun retrieveLanguage(request: LanguageRequest, onResult: (LanguageResponse?) -> Unit) {
        val apiRoute = Server.getRestApiEndPointFor(AuthEvent.RETRIEVE_ALL_LANGUAGE)
        try {
            val response = FriendlyClient.post(apiRoute) {
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            onResult(json.decodeFromString(response.bodyAsText()))
        } catch (t: Throwable) {
            print(t)
//            AlertHandler.shared().showAlert(t.message ?: "", "Unable to download language")
            onResult(null)
        }
    }
}