package storage.database.table.employee

import app.cash.sqldelight.async.coroutines.awaitAsList
import com.friendly.common.database.EmployeeTable
import feature.employee.model.Employee
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import storage.database.AppDatabase
import storage.database.table.DatabaseTable

class EmployeeDatabaseTable : DatabaseTable() {
    fun insert(item: Employee) {
        val table = AppDatabase.shared().database?.employeeTableQueries
        scope.launch {
            table?.insert(
                item.Id,
                item.UserID,
                item.EmployeeUserID,
                item.BusinessID,
                item.IsDeleted!!,
                item.Name,
                jsonDecoder.encodeToString(item.Address),
                item.GSTNumber,
                item.Barcode,
                item.Status!!,
                item.EmailID,
                item.MobileNumber,
                item.WhatsApp,
                item.DialCode,
                item.Gender,
                item.DeviceID,
                item.FCMToken,
                item.TotalSale,
                item.TotalPayment,
                item.LastSeen,
                item.CreatedAt,
                item.UpdatedAt,
                item.__v,
                item.JobTypeId,
                item.JobTypeName,
            )
        }
    }

    fun insert(items: List<Employee>?) {
        val table = AppDatabase.shared().database?.employeeTableQueries
        scope.launch {
            items?.forEach { item ->
                table?.insert(
                    item.Id,
                    item.UserID,
                    item.EmployeeUserID,
                    item.BusinessID,
                    item.IsDeleted!!,
                    item.Name,
                    jsonDecoder.encodeToString(item.Address),
                    item.GSTNumber,
                    item.Barcode,
                    item.Status!!,
                    item.EmailID,
                    item.MobileNumber,
                    item.WhatsApp,
                    item.DialCode,
                    item.Gender,
                    item.DeviceID,
                    item.FCMToken,
                    item.TotalSale,
                    item.TotalPayment,
                    item.LastSeen,
                    item.CreatedAt,
                    item.UpdatedAt,
                    item.__v,
                    item.JobTypeId,
                    item.JobTypeName,
                )
            }
        }
    }

    fun retrieve(id: String, callback: (List<Employee>) -> Unit) {
        val table = AppDatabase.shared().database?.employeeTableQueries
        scope.launch {
            val allItems: ArrayList<Employee> = arrayListOf()
            val items = table?.retrieve(id)?.awaitAsList()
            items?.forEach { item ->
                allItems.add(convert(item))
            }
            callback(allItems)
        }
    }

    fun retrieveForBusiness(id: String, callback: (List<Employee>) -> Unit) {
        val table = AppDatabase.shared().database?.employeeTableQueries
        scope.launch {
            val allItems: ArrayList<Employee> = arrayListOf()
            val items = table?.retrieveForBusiness(id)?.awaitAsList()
            items?.forEach { item ->
                allItems.add(convert(item))
            }
            callback(allItems)
        }
    }

    fun retrieveBusinessIds(id: String, callback: (List<String>) -> Unit) {
        val table = AppDatabase.shared().database?.employeeTableQueries
        scope.launch {
            val allItems: ArrayList<String> = arrayListOf()
            val items = table?.retrieveBusinessId(id)?.awaitAsList()
            items?.forEach { item ->
                item.BusinessID?.let { allItems.add(it) }
            }
            callback(allItems)
        }
    }

    private fun convert(item: EmployeeTable): Employee {
        return Employee(
            Id = item._id,
            UserID = item.UserID,
            EmployeeUserID = item.EmployeeUserID,
            BusinessID = item.BusinessID,
            IsDeleted = item.IsDeleted,
            Name = item.Name,
            jsonDecoder.decodeFromString(item.Address ?: "{}"),
            GSTNumber = item.GSTNumber,
            Barcode = item.Barcode,
            Status = item.Status,
            EmailID = item.EmailID,
            MobileNumber = item.MobileNumber,
            WhatsApp = item.WhatsApp,
            DialCode = item.DialCode,
            Gender = item.Gender,
            DeviceID = item.DeviceID,
            FCMToken = item.FCMToken,
            TotalSale = item.TotalSale,
            TotalPayment = item.TotalPayment,
            LastSeen = item.LastSeen,
            CreatedAt = item.CreatedAt,
            UpdatedAt = item.UpdatedAt,
            __v = item.__v,
            JobTypeId = item.JobTypeId,
            JobTypeName = item.JobTypeName,
        )
    }

}