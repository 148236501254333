package feature.productCategory.viewModel

import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.business.handler.BusinessHandler
import feature.product.model.Product
import feature.productCategory.handler.ProductCategoryHandler
import feature.productCategory.model.ProductCategory
import feature.productCategory.network.CreateProductCategoryRequest
import feature.productCategory.network.ProductCategoryNetwork
import feature.productCategory.network.RetrieveProductCategoryRequest
import feature.productCategory.network.UpdateProductCategoryRequest
import feature.productCategory.repository.ProductCategoryRepository
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.inventory.product_category.ProductCategoryDatabaseTable

class ProductCategoryViewModel(val repository: ProductCategoryRepository = ProductCategoryHandler.shared().repository) :
    AppViewModel() {

    val allCategory: StateFlow<List<ProductCategory>>
        get() = repository.categoryLiveData

    val selectedCategory: StateFlow<ProductCategory?>
        get() = repository.selectedCategory

    val editCategory: StateFlow<ProductCategory?>
        get() = repository.editCategory

    val productsState: MutableStateFlow<List<Product>> = MutableStateFlow(arrayListOf())
    val products: StateFlow<List<Product>>
        get() = productsState

    fun selectCategory(category: ProductCategory?) {
        scope.launch {
            repository.selectedCategoryState.emit(category)
        }
//        loadProducts(category)
    }

    fun setEditCategory(category: ProductCategory) {
        scope.launch {
            repository.editCategoryState.emit(category)
        }
//        loadProducts(category)
    }

    fun setSelectedCategory(id: String) {
        ProductCategoryDatabaseTable().find(id) { data ->
            scope.launch {
                repository.selectedCategoryState.emit(data)
            }
        }
    }

    fun loadProducts(category: ProductCategory) {
//        repository.productsMutableState.value = arrayListOf()
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            val products = DatabaseHandler.shared().database.productDao()
//                .getProductsForCategory(category.Id)
//            if (products.isNotEmpty()) {
//                repository.productsMutableState.postValue(products)
//            } else {
//                repository.productsMutableState.postValue(arrayListOf())
//            }
//        }
    }

    //
//    fun loadProducts() {
//        val categoryId = repository.selectedCategoryForNavigation?.Id
//        if (!categoryId.isNullOrEmpty()) {
//            CoroutineScope(Job() + Dispatchers.IO).launch {
//                val products = DatabaseHandler.shared().database.productDao()
//                    .getProductsForCategory(categoryId)
//                if (products.isNotEmpty()) {
//                    scope.launch {
//                        repository.productsMutableState.postValue(products)
//                    }
//                }
//            }
//        }
//    }
//
//    fun loadCategory() {
//        if (!BusinessHandler.shared().repository.business.value?.Id.isNullOrEmpty()) {
//            CoroutineScope(Job() + Dispatchers.IO).launch {
//                val businessId = BusinessHandler.shared().repository.business.value!!.Id
//                repository.categoryLiveData.postValue(
//                    DatabaseHandler.shared().database.productCategoryDao()
//                        .getAllItemsForBusiness(businessId) as ArrayList<ProductCategory>?
//                )
//            }
//            fetchAllProductCategory()
//        }
//    }
//
    fun retrieve() {
        loadFromStorage()
        val request =
            RetrieveProductCategoryRequest(BusinessHandler.shared().repository.business.value.Id)
        scope.launch {
            ProductCategoryNetwork().retrieve(request) {
                ProductCategoryDatabaseTable().insert(it?.payload) {
                    stopLoading()
                    loadFromStorage()
                }
            }
        }
    }

    private fun loadFromStorageForBusiness(id: String) {
        ProductCategoryDatabaseTable().retrieve(id) { data ->
            scope.launch {
                repository.categoryLiveData.emit(data)
            }
        }
    }

    fun retrieveForBusiness(id: String) {
        loadFromStorageForBusiness(id)
        val request =
            RetrieveProductCategoryRequest(id)
        scope.launch {
            ProductCategoryNetwork().retrieve(request) {
                ProductCategoryDatabaseTable().insert(it?.payload) {
                    stopLoading()
                    loadFromStorageForBusiness(id)
                }
            }
        }
    }

    private fun loadFromStorage() {
        ProductCategoryDatabaseTable().retrieve(BusinessHandler.shared().businessId) { data ->
            scope.launch {
                repository.categoryLiveData.emit(data)
            }
        }
    }

    fun createNewCategory(name: String, completion: () -> Unit) {
        val request = CreateProductCategoryRequest(
            name,
            BusinessHandler.shared().repository.business.value.Id,
            AuthHandler.shared().repository.authUser.value.id
        )
        startLoading()
        scope.launch {
            ProductCategoryNetwork().create(request) {
                stopLoading()
                if (it?.payload != null && it.payload.Id.isNotEmpty()) {
                    scope.launch {
                        val allCategory: MutableList<ProductCategory> =
                            repository.allCategory.value.toMutableList()
                        allCategory.add(it.payload)
                        repository.categoryLiveData.emit(allCategory)
                        ProductCategoryDatabaseTable().insert(it.payload)
                        completion()
                    }
                }
            }
        }
    }

    fun updateCategory(name: String, id: String, completion: () -> Unit) {
        val request = UpdateProductCategoryRequest(
            name,
            id,
            BusinessHandler.shared().repository.business.value.Id,
            AuthHandler.shared().repository.authUser.value.id
        )
        startLoading()
        scope.launch {
            ProductCategoryNetwork().update(request) {
                stopLoading()
                if (it?.payload != null && it.payload.Id.isNotEmpty()) {
                    scope.launch {
                        val allCategory: MutableList<ProductCategory> =
                            repository.allCategory.value.toMutableList()
                        allCategory.add(it.payload)
                        repository.categoryLiveData.emit(allCategory)
                        ProductCategoryDatabaseTable().insert(it.payload)
                        completion()
                    }
                }
            }
        }
    }
//
//    fun insert(category: ProductCategory) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.productCategoryDao()
//                .insert(category)
//        }
//    }
}