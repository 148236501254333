package presentation.screens.common.address.create

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.address.AddressLocationInput
import component.button.DefaultButton
import component.input.TextInputField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.address.viewModel.AddressViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen

class CreateAddressScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val navigator = LocalNavigator.current
        val viewModel = AddressViewModel()
        Column(modifier = Modifier.fillMaxHeight()) {
            AppLoader(viewModel)
            TopNavBar(localization().address.createNewAddress, navigationParam, onBackPress = {
                navigator?.pop()
            })
            AddressLocationInput(viewModel) { loc ->
                viewModel.setPickedLocation(loc)
            }
            TextInputField(
                placeholder = localization().address.houseNumber,
                onChange = {
                    viewModel.setHouseNumberForNewAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.blockAreaVillageLocality,
                onChange = {
                    viewModel.setAreaForNewAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.landmark,
                onChange = {
                    viewModel.setLandmarkForNewAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.pincodeZipcode,
                onChange = {
                    viewModel.setPinCodeForNewAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.city,
                onChange = {
                    viewModel.setCityForNewAddress(it)
                }
            )
            TextInputField(
                placeholder = localization().address.state,
                onChange = {
                    viewModel.setStateForNewAddress(it)
                }
            )
            DefaultButton(
                title = localization().address.saveButton,
                onClick = { viewModel.create() },
                modifier = Modifier.fillMaxWidth().padding(top = 8.dp)
            )
        }
    }
}