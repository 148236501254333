package feature.auth.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class FriendlyUser(
    @SerialName("_id") var id: String = "",
    @SerialName("BusinessNumber") var businessNumber: Int? = null,
    @SerialName("UserID") var userID: String? = null,
    @SerialName("Password") var password: String? = null,
    @SerialName("RoleType") var roleType: String? = null,
    @SerialName("AppID") var appID: String? = null,
    @SerialName("BusinessTypeID") var businessTypeID: String? = null,
    @SerialName("IsDeleted") var IsDeleted: Boolean? = null,
    @SerialName("Name") var name: String? = null,
    @SerialName("Address") var Address: String? = null,
    @SerialName("DealerName") var DealerName: String? = null,
    @SerialName("ProductTypes") var ProductTypes: ArrayList<String> = arrayListOf(),
    @SerialName("GSTNumber") var GSTNumber: String? = null,
    @SerialName("Status") var Status: String? = null,
    @SerialName("EmailID") var EmailID: String? = null,
    @SerialName("MobileNumber") var mobile: String? = null,
    @SerialName("DialCode") var DialCode: String? = null,
    @SerialName("Gender") var Gender: Int? = null,
    @SerialName("DeviceID") var DeviceID: String? = null,
    @SerialName("FCMToken") var FCMToken: String? = null,
    @SerialName("ProfilePicture") var ProfilePicture: String? = null,
    @SerialName("LastSeen") var LastSeen: String? = null,
    @SerialName("CreatedAt") var CreatedAt: String? = null,
    @SerialName("UpdatedAt") var UpdatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null
)


