package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.productTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class ProductTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String, mapper: (
    _id: String,
    ProductID: Long,
    UserID: String,
    BusinessID: String,
    CategoryID: String?,
    SubCategoryID: String?,
    Name: String?,
    Description: String?,
    ProductPrice: String?,
    ManageInventory: Boolean,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getBoolean(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieve(BusinessID: String): Query<ProductTable> = retrieve(BusinessID) { _id,
      ProductID, UserID, BusinessID_, CategoryID, SubCategoryID, Name, Description, ProductPrice,
      ManageInventory, IsDeleted, CreatedAt, UpdatedAt ->
    ProductTable(
      _id,
      ProductID,
      UserID,
      BusinessID_,
      CategoryID,
      SubCategoryID,
      Name,
      Description,
      ProductPrice,
      ManageInventory,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    ProductID: Long,
    UserID: String,
    BusinessID: String,
    CategoryID: String?,
    SubCategoryID: String?,
    Name: String?,
    Description: String?,
    ProductPrice: String?,
    ManageInventory: Boolean,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getBoolean(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun find(_id: String): Query<ProductTable> = find(_id) { _id_, ProductID, UserID,
      BusinessID, CategoryID, SubCategoryID, Name, Description, ProductPrice, ManageInventory,
      IsDeleted, CreatedAt, UpdatedAt ->
    ProductTable(
      _id_,
      ProductID,
      UserID,
      BusinessID,
      CategoryID,
      SubCategoryID,
      Name,
      Description,
      ProductPrice,
      ManageInventory,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForCategory(
    BusinessID: String,
    CategoryID: String?,
    mapper: (
      _id: String,
      ProductID: Long,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      Name: String?,
      Description: String?,
      ProductPrice: String?,
      ManageInventory: Boolean,
      IsDeleted: Boolean,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForCategoryQuery(BusinessID, CategoryID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getBoolean(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveForCategory(BusinessID: String, CategoryID: String?): Query<ProductTable> =
      retrieveForCategory(BusinessID, CategoryID) { _id, ProductID, UserID, BusinessID_,
      CategoryID_, SubCategoryID, Name, Description, ProductPrice, ManageInventory, IsDeleted,
      CreatedAt, UpdatedAt ->
    ProductTable(
      _id,
      ProductID,
      UserID,
      BusinessID_,
      CategoryID_,
      SubCategoryID,
      Name,
      Description,
      ProductPrice,
      ManageInventory,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForSubCategory(
    BusinessID: String,
    SubCategoryID: String?,
    mapper: (
      _id: String,
      ProductID: Long,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      Name: String?,
      Description: String?,
      ProductPrice: String?,
      ManageInventory: Boolean,
      IsDeleted: Boolean,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForSubCategoryQuery(BusinessID, SubCategoryID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getBoolean(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun retrieveForSubCategory(BusinessID: String, SubCategoryID: String?): Query<ProductTable>
      = retrieveForSubCategory(BusinessID, SubCategoryID) { _id, ProductID, UserID, BusinessID_,
      CategoryID, SubCategoryID_, Name, Description, ProductPrice, ManageInventory, IsDeleted,
      CreatedAt, UpdatedAt ->
    ProductTable(
      _id,
      ProductID,
      UserID,
      BusinessID_,
      CategoryID,
      SubCategoryID_,
      Name,
      Description,
      ProductPrice,
      ManageInventory,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String, mapper: (UpdatedAt: String?) -> T): Query<T>
      = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public fun <T : Any> search(
    BusinessID: String,
    Name: String,
    Description: String,
    ProductPrice: String,
    mapper: (
      _id: String,
      ProductID: Long,
      UserID: String,
      BusinessID: String,
      CategoryID: String?,
      SubCategoryID: String?,
      Name: String?,
      Description: String?,
      ProductPrice: String?,
      ManageInventory: Boolean,
      IsDeleted: Boolean,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = SearchQuery(BusinessID, Name, Description, ProductPrice) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getBoolean(9)!!,
      cursor.getBoolean(10)!!,
      cursor.getString(11),
      cursor.getString(12)
    )
  }

  public fun search(
    BusinessID: String,
    Name: String,
    Description: String,
    ProductPrice: String,
  ): Query<ProductTable> = search(BusinessID, Name, Description, ProductPrice) { _id, ProductID,
      UserID, BusinessID_, CategoryID, SubCategoryID, Name_, Description_, ProductPrice_,
      ManageInventory, IsDeleted, CreatedAt, UpdatedAt ->
    ProductTable(
      _id,
      ProductID,
      UserID,
      BusinessID_,
      CategoryID,
      SubCategoryID,
      Name_,
      Description_,
      ProductPrice_,
      ManageInventory,
      IsDeleted,
      CreatedAt,
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    ProductID: Long,
    UserID: String,
    BusinessID: String,
    CategoryID: String?,
    SubCategoryID: String?,
    Name: String?,
    Description: String?,
    ProductPrice: String?,
    ManageInventory: Boolean,
    IsDeleted: Boolean,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(254_328_184, """
        |INSERT OR REPLACE INTO ProductTable(_id, ProductID, UserID, BusinessID, CategoryID, SubCategoryID,Name,Description,ProductPrice,ManageInventory,IsDeleted,CreatedAt,UpdatedAt)
        |    VALUES(?, ?, ?, ?, ? ,?, ?, ?,?,?,?,?,?)
        """.trimMargin(), 13) {
          bindString(0, _id)
          bindLong(1, ProductID)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindString(4, CategoryID)
          bindString(5, SubCategoryID)
          bindString(6, Name)
          bindString(7, Description)
          bindString(8, ProductPrice)
          bindBoolean(9, ManageInventory)
          bindBoolean(10, IsDeleted)
          bindString(11, CreatedAt)
          bindString(12, UpdatedAt)
        }.await()
    notifyQueries(254_328_184) { emit ->
      emit("ProductTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_255_569_605,
        """SELECT ProductTable._id, ProductTable.ProductID, ProductTable.UserID, ProductTable.BusinessID, ProductTable.CategoryID, ProductTable.SubCategoryID, ProductTable.Name, ProductTable.Description, ProductTable.ProductPrice, ProductTable.ManageInventory, ProductTable.IsDeleted, ProductTable.CreatedAt, ProductTable.UpdatedAt FROM ProductTable WHERE BusinessID = ? AND IsDeleted = 0 ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "ProductTable.sq:retrieve"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-388_656_072,
        """SELECT ProductTable._id, ProductTable.ProductID, ProductTable.UserID, ProductTable.BusinessID, ProductTable.CategoryID, ProductTable.SubCategoryID, ProductTable.Name, ProductTable.Description, ProductTable.ProductPrice, ProductTable.ManageInventory, ProductTable.IsDeleted, ProductTable.CreatedAt, ProductTable.UpdatedAt FROM ProductTable WHERE _id = ? AND IsDeleted = 0 ORDER BY UpdatedAt DESC""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "ProductTable.sq:find"
  }

  private inner class RetrieveForCategoryQuery<out T : Any>(
    public val BusinessID: String,
    public val CategoryID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT ProductTable._id, ProductTable.ProductID, ProductTable.UserID, ProductTable.BusinessID, ProductTable.CategoryID, ProductTable.SubCategoryID, ProductTable.Name, ProductTable.Description, ProductTable.ProductPrice, ProductTable.ManageInventory, ProductTable.IsDeleted, ProductTable.CreatedAt, ProductTable.UpdatedAt FROM ProductTable WHERE BusinessID = ? AND CategoryID ${ if (CategoryID == null) "IS" else "=" } ? AND IsDeleted = 0 ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, CategoryID)
    }

    override fun toString(): String = "ProductTable.sq:retrieveForCategory"
  }

  private inner class RetrieveForSubCategoryQuery<out T : Any>(
    public val BusinessID: String,
    public val SubCategoryID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT ProductTable._id, ProductTable.ProductID, ProductTable.UserID, ProductTable.BusinessID, ProductTable.CategoryID, ProductTable.SubCategoryID, ProductTable.Name, ProductTable.Description, ProductTable.ProductPrice, ProductTable.ManageInventory, ProductTable.IsDeleted, ProductTable.CreatedAt, ProductTable.UpdatedAt FROM ProductTable WHERE BusinessID = ? AND SubCategoryID ${ if (SubCategoryID == null) "IS" else "=" } ? AND IsDeleted = 0 ORDER BY UpdatedAt DESC""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, SubCategoryID)
    }

    override fun toString(): String = "ProductTable.sq:retrieveForSubCategory"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_137_731_870,
        """SELECT UpdatedAt FROM ProductTable WHERE BusinessID = ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "ProductTable.sq:lastSyncDate"
  }

  private inner class SearchQuery<out T : Any>(
    public val BusinessID: String,
    public val Name: String,
    public val Description: String,
    public val ProductPrice: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(531_783_783,
        """SELECT ProductTable._id, ProductTable.ProductID, ProductTable.UserID, ProductTable.BusinessID, ProductTable.CategoryID, ProductTable.SubCategoryID, ProductTable.Name, ProductTable.Description, ProductTable.ProductPrice, ProductTable.ManageInventory, ProductTable.IsDeleted, ProductTable.CreatedAt, ProductTable.UpdatedAt FROM ProductTable WHERE BusinessID = ? AND IsDeleted = 0 AND (Name LIKE ? COLLATE NOCASE OR Description LIKE ? COLLATE NOCASE OR ProductPrice LIKE ? COLLATE NOCASE) ORDER BY UpdatedAt DESC""",
        mapper, 4) {
      bindString(0, BusinessID)
      bindString(1, Name)
      bindString(2, Description)
      bindString(3, ProductPrice)
    }

    override fun toString(): String = "ProductTable.sq:search"
  }
}
