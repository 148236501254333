package feature.notification.handler

import feature.notification.repository.NotificationRepository
import feature.notification.viewModel.NotificationViewModel
import feature.offerBanner.model.OfferBanner

class NotificationConnectionHandler {


    var viewModel: NotificationViewModel? = null
    val repository = NotificationRepository()
    var onCreateNew: ((category: OfferBanner) -> Unit)? = null

    init {
        instance = this
    }

    companion object {
        private var instance: NotificationConnectionHandler? = null
        fun shared(): NotificationConnectionHandler {
            if (instance != null) {
                return instance as NotificationConnectionHandler
            } else {
                return NotificationConnectionHandler()
            }
        }
    }
}