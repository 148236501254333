package presentation.screens.business.inventory.category.list

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.empty.EmptyData
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.business.viewModel.BusinessViewModel
import feature.productCategory.viewModel.ProductCategoryViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.inventory.category.component.ProductCategoryRow
import presentation.screens.business.inventory.category.create.CreateCategoryScreen
import theme.ThemeColor

class CategoryListScreen(override val screenName: ScreenName = ScreenName.BUSINESS_INVENTORY_CATEGORY) :
    AppScreen {

    @Composable
    override fun Content() {
        super.Content()
        val businessViewModel = BusinessViewModel()
        val selectedBusiness = businessViewModel.selectedBusiness.collectAsState()
        val viewModel = ProductCategoryViewModel()
        val navigator = LocalNavigator.current
        val allCategories = viewModel.allCategory.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.retrieve()
        }
        Column(
            modifier = Modifier.fillMaxSize()
                .background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(
                title = localization().categoryMenu.categoryTitle,
                navigationParam,
                onBackPress = {
                    navigator?.pop()
                },
                onClickAdd = {
                    navigator?.push(CreateCategoryScreen())
                })
            AppLoader(viewModel)
            if (allCategories.value.isNotEmpty()) {
                LazyVerticalGrid(
                    columns = GridCells.Adaptive(minSize = 400.dp),
                    modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
                ) {
                    items(count = allCategories.value.count()) { index ->
                        ProductCategoryRow(category = allCategories.value[index], onClick = {

                            viewModel.navigateTo(
                                ScreenName.BUSINESS_INVENTORY_CATEGORY_DETAILS,
                                NavigationParam(
                                    business = selectedBusiness.value,
                                    productCategory = it
                                )
                            )
                        })
                    }
                }
            } else {
                EmptyData(viewModel = viewModel) {
                    viewModel.retrieve()
                }
            }
        }
    }
}