package feature.businessConnection.network

import feature.businessConnection.model.BusinessConnection
import feature.notification.model.Notification
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class BusinessConnectionRetrieveResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<BusinessConnection>,
)

@Serializable
data class BusinessConnectionCreateResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: Notification,
)