package presentation.screens.business.customer.edit

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import component.country.DialCodeHelper
import component.input.CustomTextField
import component.input.MobileTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.customer.viewModel.CustomerViewModel
import locale.localization
import theme.ThemeColor

class EditCustomerScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = CustomerViewModel()
        val editCustomer = viewModel.editCustomer.collectAsState()
        val nameErrorState = remember { mutableStateOf(false) }
        val emailErrorState = remember { mutableStateOf(false) }
        val barcodeErrorState = remember { mutableStateOf(false) }
        var dialCode by remember { mutableStateOf(DialCodeHelper().getSelectedCountryCode()) }
        val mobileErrorState = remember {
            mutableStateOf(false)
        }
        var isValidMobile by remember { mutableStateOf(false) }

        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(
                title = localization().customer.editCustomerTitle,
                null,
                onBackPress = {
                    navigator?.pop()
                })
            AppLoader(viewModel)
            Column(modifier = Modifier.fillMaxSize()) {
                CustomTextField(
                    value = editCustomer.value?.Name ?: "",
                    placeholder = localization().customer.editCustomerNamePlaceholder,
                    trailingIcon = ImageAsset.person,
                    label = localization().customer.editCustomerName,
                    errorState = nameErrorState.value,
                    keyboardType = KeyboardType.Email,
                    visualTransformation = VisualTransformation.None
                ) { newValue ->
                    viewModel.editCustomerName(newValue.text)
                }
                Spacer(modifier = Modifier.height(8.dp))
                CustomTextField(
                    value = editCustomer.value?.EmailID ?: "",
                    placeholder = localization().customer.createCustomerEmailPlaceholder,
                    trailingIcon = ImageAsset.person,
                    label = localization().customer.editEmailId,
                    errorState = emailErrorState.value,
                    keyboardType = KeyboardType.Email,
                    visualTransformation = VisualTransformation.None
                ) { newValue ->
                    viewModel.editCustomerEmail(newValue.text)
                }
                Spacer(modifier = Modifier.height(8.dp))
                MobileTextField(
                    mobileNumber = editCustomer.value?.MobileNumber ?: "",
                    placeholder = "9000000000",
                    trailingIcon = ImageAsset.phone,
                    label = localization().customer.editMobileNumber,
                    errorState = mobileErrorState,
                    visualTransformation = VisualTransformation.None,
                    onChanged = { mob ->
                        viewModel.editCustomerMobile(mob.text)
                    },
                    onDialCodeChange = {
                        dialCode = it
                        viewModel.editCustomerDialCode(it.countryPhoneCode)
                    }
                )
                Spacer(modifier = Modifier.height(8.dp))
                CustomTextField(
                    value = editCustomer.value?.barcode ?: "",
                    placeholder = localization().customer.editBarcodePlaceholder,
                    trailingIcon = ImageAsset.person,
                    label = localization().customer.editBarcode,
                    errorState = barcodeErrorState.value,
                    keyboardType = KeyboardType.Email,
                    visualTransformation = VisualTransformation.None
                ) { newValue ->
                    viewModel.editCustomerBarcode(newValue.text)
                }
                Spacer(modifier = Modifier.height(8.dp))
                CustomDefaultBtn(
                    shapeSize = 50f,
                    btnText = localization().customer.editContinueButton
                ) {
                    isValidMobile = (viewModel.editCustomer.value?.MobileNumber?.length in 9..11)
                    mobileErrorState.value = !isValidMobile
                    if (isValidMobile) {
                        viewModel.updateCustomer { result ->
                            if (result) {
                                AlertHandler.shared()
                                    .showSuccessAlert(
                                        localization().customer.createSuccessTitle,
                                        localization().customer.createSuccessMessage
                                    )
                            } else {
                                AlertHandler.shared().showAlert(
                                    localization().customer.createSuccessTitle,
                                    localization().customer.createSuccessElseMessage
                                )
                            }
                        }
                    } else {
                        AlertHandler.shared()
                            .showAlert(localization().customer.createEditCustomerAlertMessage, "")
                        //("Oops!", "Please enter valid input")
                    }
                }
            }
        }
    }
}