package presentation.screens.payment.pay

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import component.loader.AppLoader
import component.nativeWebView.NativeWebView
import component.navigation.TopNavBar
import feature.payment.viewModel.PaymentViewModel
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import theme.ThemeColor

class PaymentPayScreen(override val screenName: ScreenName = ScreenName.PAYMENT_PAY_SCREEN) :
    AppScreen {
    @Composable
    override fun Content() {
        super.Content()
        val viewModel = PaymentViewModel()
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = "Payment", navigationParam, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            NativeWebView(url = navigationParam?.url)
        }
    }
}