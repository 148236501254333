package navigation

import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.BottomNavigation
import androidx.compose.material3.Scaffold
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import appConfig.AppConfig
import cafe.adriel.voyager.navigator.Navigator
import cafe.adriel.voyager.navigator.tab.CurrentTab
import cafe.adriel.voyager.navigator.tab.TabNavigator
import component.alert.AlertView
import feature.SetupFeature
import feature.auth.handler.AuthHandler
import feature.auth.viewModel.AuthViewModel
import feature.device.handler.DeviceHandler
import graph.AppType
import graph.PlatformType
import navigation.containers.ScreenName
import navigation.tabs.AccountTab
import navigation.tabs.BusinessHomeTab
import navigation.tabs.OrderTab
import navigation.tabs.StoresDetailTab
import navigation.tabs.StoresTab
import presentation.screens.business.homepage.BusinessHomePageScreen
import theme.FriendlyTheme
import theme.ThemeColor

@Composable
fun AppWindow() {
    val authViewModel = AuthViewModel()
    val isloggedIn = authViewModel.isLoggedIn.collectAsState()
    AppConfig.shared().setBusiness()
    SetupFeature.shared().initModules()
    AuthHandler.shared().checkIfLoggedIn()
    LaunchedEffect(key1 = Unit) {
        DeviceHandler.shared().updateDeviceToServer()
    }
    BoxWithConstraints(Modifier.fillMaxSize(), propagateMinConstraints = true) {
        AppNavigator.shared().screenWidth = this.maxWidth
        AppNavigator.shared().screenHeight = this.maxHeight
        if (AppConfig.shared().appType == AppType.BUSINESS) {
            FriendlyTheme {
                AlertView()
                if (isloggedIn.value || AppConfig.shared().platformType == PlatformType.Mobile) {
                    TabNavigator(BusinessHomeTab) {
                        Scaffold(
                            bottomBar = {
                                BottomNavigation(backgroundColor = ThemeColor.shared().PrimaryColor) {
                                    TabNavigationItem(BusinessHomeTab)
//                                    TabNavigationItem(BusinessConnectionTab)
                                    TabNavigationItem(AccountTab)
                                }
                            },
                            content = { CurrentTab() },
                        )
                    }
                } else {
                    Navigator(BusinessHomePageScreen(screenName = ScreenName.BUSINESS_HOMEPAGE))
                }
            }
        } else if (AppConfig.shared().appType == AppType.CUSTOMER) {
            FriendlyTheme {
                AlertView()
                TabNavigator(StoresTab) {
                    Scaffold(
                        modifier = Modifier.fillMaxSize(),
                        bottomBar = {
                            BottomNavigation(backgroundColor = ThemeColor.shared().PrimaryColor) {
                                TabNavigationItem(StoresTab)
                                TabNavigationItem(OrderTab)
                                TabNavigationItem(AccountTab)
                            }
                        },
                        content = { CurrentTab() },
                    )
                }
            }
        } else {
            FriendlyTheme {
                FriendlyTheme {
                    AlertView()
                    TabNavigator(StoresDetailTab) {
                        Scaffold(
                            modifier = Modifier.fillMaxSize(),
                            bottomBar = {
                                BottomNavigation(backgroundColor = ThemeColor.shared().PrimaryColor) {
                                    TabNavigationItem(StoresDetailTab)
                                    TabNavigationItem(OrderTab)
                                    TabNavigationItem(AccountTab)
                                }
                            },
                            content = { CurrentTab() },
                        )
                    }
                }
            }
        }
    }

}