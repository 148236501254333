package presentation.screens.business.homepage.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import androidx.compose.ui.zIndex
import common.asset.ImageAsset
import component.button.DownloadAppButton
import component.image.AsyncImageView
import graph.PlatformType
import theme.ThemeColor

@Composable
fun downloadAppRow() {
    val uriHandler = LocalUriHandler.current

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(200.dp)
            .padding(top = 20.dp)
            .background(brush = Brush.horizontalGradient(
                    ThemeColor.shared().downloadRowBackground
                ),
            ),
    ) {
        DownloadAppButton(platformType = PlatformType.Android)
        DownloadAppButton(platformType = PlatformType.iOS)
        DownloadAppButton(platformType = PlatformType.Mac)
        DownloadAppButton(platformType = PlatformType.Windows)
    }
}