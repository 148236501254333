package component.input

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import component.country.CountryCode
import component.country.CountryPickerView
import component.image.FriendlyIcon
import theme.ThemeColor

@Composable
fun MobileTextField(
    mobileNumber: String = "",
    placeholder: String, trailingIcon: String, label: String,
    visualTransformation: VisualTransformation,
    errorState: MutableState<Boolean>,
    onChanged: (TextFieldValue) -> Unit,
    onDialCodeChange: (CountryCode) -> Unit,
) {
    //state
    var text by remember {
        mutableStateOf(TextFieldValue(mobileNumber))
    }

    Row(
        modifier = Modifier
            .height(70.dp)
            .padding(start = 8.dp, end = 8.dp)
            .fillMaxSize()
            .border(
                BorderStroke(1.dp, ThemeColor.shared().PrimaryColor),
                shape = RoundedCornerShape(16.dp)
            ).background(
                color = ThemeColor.shared().cardBackground,
                shape = RoundedCornerShape(16.dp)
            )
    ) {
        CountryPickerView(onDialCodeChange)
        TextField(
            value = text,
            onValueChange = { newText ->
                text = newText
                onChanged(newText)
            },
            placeholder = {
                Text(text = placeholder)
            },
            label = { Text(text = label) },
            shape = RoundedCornerShape(0.dp),

            trailingIcon = {
                FriendlyIcon(name = trailingIcon)
            },
            singleLine = true,
            colors = OutlinedTextFieldDefaults.colors(
                focusedContainerColor = ThemeColor.shared().cardBackground,
                unfocusedContainerColor = ThemeColor.shared().cardBackground,
                cursorColor = ThemeColor.shared().PrimaryColor,
                focusedBorderColor = ThemeColor.shared().PrimaryColor,
                unfocusedBorderColor = ThemeColor.shared().PrimaryColor,
                focusedTrailingIconColor = if (text.text.isNotEmpty()) ThemeColor.shared().PrimaryColor else ThemeColor.shared().TextColor,
                unfocusedTrailingIconColor = if (text.text.isNotEmpty()) ThemeColor.shared().PrimaryColor else ThemeColor.shared().TextColor,
                focusedLabelColor = ThemeColor.shared().PrimaryColor,
                focusedSupportingTextColor = ThemeColor.shared().TextColor,
            ),
            visualTransformation = visualTransformation,
            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
            isError = errorState.value,
            modifier = Modifier
                .height(70.dp)
                .fillMaxWidth().padding(end = 12.dp)

        )
    }

}