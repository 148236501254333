package com.friendly.common.database

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import com.friendly.common.database.productSubCategoryTable.LastSyncDate
import kotlin.Any
import kotlin.Boolean
import kotlin.Long
import kotlin.String

public class ProductSubCategoryTableQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> retrieve(BusinessID: String?, mapper: (
    _id: String,
    ProductSubCategoryID: Long?,
    ProductCategoryID: String?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Image: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = RetrieveQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getBoolean(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun retrieve(BusinessID: String?): Query<ProductSubCategoryTable> = retrieve(BusinessID) {
      _id, ProductSubCategoryID, ProductCategoryID, UserID, BusinessID_, IsDeleted, Name, Image,
      CreatedAt, UpdatedAt ->
    ProductSubCategoryTable(
      _id,
      ProductSubCategoryID,
      ProductCategoryID,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      Image,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> retrieveForCategory(
    BusinessID: String?,
    ProductCategoryID: String?,
    mapper: (
      _id: String,
      ProductSubCategoryID: Long?,
      ProductCategoryID: String?,
      UserID: String?,
      BusinessID: String?,
      IsDeleted: Boolean?,
      Name: String?,
      Image: String?,
      CreatedAt: String?,
      UpdatedAt: String?,
    ) -> T,
  ): Query<T> = RetrieveForCategoryQuery(BusinessID, ProductCategoryID) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getBoolean(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun retrieveForCategory(BusinessID: String?, ProductCategoryID: String?):
      Query<ProductSubCategoryTable> = retrieveForCategory(BusinessID, ProductCategoryID) { _id,
      ProductSubCategoryID, ProductCategoryID_, UserID, BusinessID_, IsDeleted, Name, Image,
      CreatedAt, UpdatedAt ->
    ProductSubCategoryTable(
      _id,
      ProductSubCategoryID,
      ProductCategoryID_,
      UserID,
      BusinessID_,
      IsDeleted,
      Name,
      Image,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> find(_id: String, mapper: (
    _id: String,
    ProductSubCategoryID: Long?,
    ProductCategoryID: String?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Image: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) -> T): Query<T> = FindQuery(_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getBoolean(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9)
    )
  }

  public fun find(_id: String): Query<ProductSubCategoryTable> = find(_id) { _id_,
      ProductSubCategoryID, ProductCategoryID, UserID, BusinessID, IsDeleted, Name, Image,
      CreatedAt, UpdatedAt ->
    ProductSubCategoryTable(
      _id_,
      ProductSubCategoryID,
      ProductCategoryID,
      UserID,
      BusinessID,
      IsDeleted,
      Name,
      Image,
      CreatedAt,
      UpdatedAt
    )
  }

  public fun <T : Any> lastSyncDate(BusinessID: String?, mapper: (UpdatedAt: String?) -> T):
      Query<T> = LastSyncDateQuery(BusinessID) { cursor ->
    mapper(
      cursor.getString(0)
    )
  }

  public fun lastSyncDate(BusinessID: String?): Query<LastSyncDate> = lastSyncDate(BusinessID) {
      UpdatedAt ->
    LastSyncDate(
      UpdatedAt
    )
  }

  public suspend fun insert(
    _id: String,
    ProductCategoryID: String?,
    UserID: String?,
    BusinessID: String?,
    IsDeleted: Boolean?,
    Name: String?,
    Image: String?,
    CreatedAt: String?,
    UpdatedAt: String?,
  ) {
    driver.execute(-1_220_624_940, """
        |INSERT OR REPLACE INTO ProductSubCategoryTable( _id, ProductCategoryID, UserID, BusinessID,IsDeleted,Name,Image,CreatedAt,UpdatedAt)
        |    VALUES(?, ?, ?,  ? ,?, ?, ?,?,?)
        """.trimMargin(), 9) {
          bindString(0, _id)
          bindString(1, ProductCategoryID)
          bindString(2, UserID)
          bindString(3, BusinessID)
          bindBoolean(4, IsDeleted)
          bindString(5, Name)
          bindString(6, Image)
          bindString(7, CreatedAt)
          bindString(8, UpdatedAt)
        }.await()
    notifyQueries(-1_220_624_940) { emit ->
      emit("ProductSubCategoryTable")
    }
  }

  private inner class RetrieveQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductSubCategoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductSubCategoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT ProductSubCategoryTable._id, ProductSubCategoryTable.ProductSubCategoryID, ProductSubCategoryTable.ProductCategoryID, ProductSubCategoryTable.UserID, ProductSubCategoryTable.BusinessID, ProductSubCategoryTable.IsDeleted, ProductSubCategoryTable.Name, ProductSubCategoryTable.Image, ProductSubCategoryTable.CreatedAt, ProductSubCategoryTable.UpdatedAt FROM ProductSubCategoryTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ?""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "ProductSubCategoryTable.sq:retrieve"
  }

  private inner class RetrieveForCategoryQuery<out T : Any>(
    public val BusinessID: String?,
    public val ProductCategoryID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductSubCategoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductSubCategoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT ProductSubCategoryTable._id, ProductSubCategoryTable.ProductSubCategoryID, ProductSubCategoryTable.ProductCategoryID, ProductSubCategoryTable.UserID, ProductSubCategoryTable.BusinessID, ProductSubCategoryTable.IsDeleted, ProductSubCategoryTable.Name, ProductSubCategoryTable.Image, ProductSubCategoryTable.CreatedAt, ProductSubCategoryTable.UpdatedAt FROM ProductSubCategoryTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? AND ProductCategoryID ${ if (ProductCategoryID == null) "IS" else "=" } ?""",
        mapper, 2) {
      bindString(0, BusinessID)
      bindString(1, ProductCategoryID)
    }

    override fun toString(): String = "ProductSubCategoryTable.sq:retrieveForCategory"
  }

  private inner class FindQuery<out T : Any>(
    public val _id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductSubCategoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductSubCategoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(199_752_596,
        """SELECT ProductSubCategoryTable._id, ProductSubCategoryTable.ProductSubCategoryID, ProductSubCategoryTable.ProductCategoryID, ProductSubCategoryTable.UserID, ProductSubCategoryTable.BusinessID, ProductSubCategoryTable.IsDeleted, ProductSubCategoryTable.Name, ProductSubCategoryTable.Image, ProductSubCategoryTable.CreatedAt, ProductSubCategoryTable.UpdatedAt FROM ProductSubCategoryTable WHERE _id = ?""",
        mapper, 1) {
      bindString(0, _id)
    }

    override fun toString(): String = "ProductSubCategoryTable.sq:find"
  }

  private inner class LastSyncDateQuery<out T : Any>(
    public val BusinessID: String?,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("ProductSubCategoryTable", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("ProductSubCategoryTable", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(null,
        """SELECT UpdatedAt FROM ProductSubCategoryTable WHERE BusinessID ${ if (BusinessID == null) "IS" else "=" } ? ORDER BY UpdatedAt DESC LIMIT  1""",
        mapper, 1) {
      bindString(0, BusinessID)
    }

    override fun toString(): String = "ProductSubCategoryTable.sq:lastSyncDate"
  }
}
