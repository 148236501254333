@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package friendly.shared.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val app_icon: DrawableResource by 
      lazy { init_app_icon() }

  public val brand_menu_icon: DrawableResource by 
      lazy { init_brand_menu_icon() }

  public val business_app_icon: DrawableResource by 
      lazy { init_business_app_icon() }

  public val business_logo_web: DrawableResource by 
      lazy { init_business_logo_web() }

  public val business_menu_icon: DrawableResource by 
      lazy { init_business_menu_icon() }

  public val catalogue_menu_icon: DrawableResource by 
      lazy { init_catalogue_menu_icon() }

  public val category_menu_icon: DrawableResource by 
      lazy { init_category_menu_icon() }

  public val customer_menu_icon: DrawableResource by 
      lazy { init_customer_menu_icon() }

  public val dashboard_menu_icon: DrawableResource by 
      lazy { init_dashboard_menu_icon() }

  public val employee_menu_icon: DrawableResource by 
      lazy { init_employee_menu_icon() }

  public val invoice_menu_icon: DrawableResource by 
      lazy { init_invoice_menu_icon() }

  public val message_icon: DrawableResource by 
      lazy { init_message_icon() }

  public val orders_menu_icon: DrawableResource by 
      lazy { init_orders_menu_icon() }

  public val partner_menu_icon: DrawableResource by 
      lazy { init_partner_menu_icon() }

  public val product_menu_icon: DrawableResource by 
      lazy { init_product_menu_icon() }

  public val quotation_menu_icon: DrawableResource by 
      lazy { init_quotation_menu_icon() }

  public val sale_menu_icon: DrawableResource by 
      lazy { init_sale_menu_icon() }

  public val share_icon: DrawableResource by 
      lazy { init_share_icon() }

  public val shop_menu_icon: DrawableResource by 
      lazy { init_shop_menu_icon() }

  public val store_menu_icon: DrawableResource by 
      lazy { init_store_menu_icon() }

  public val sub_category_menu_icon: DrawableResource by 
      lazy { init_sub_category_menu_icon() }

  public val support_image: DrawableResource by 
      lazy { init_support_image() }

  public val whatsapp_icon: DrawableResource by 
      lazy { init_whatsapp_icon() }
}

public val Res.drawable.app_icon: DrawableResource
  get() = CommonMainDrawable0.app_icon

private fun init_app_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:app_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/app_icon.png", -1, -1),
    )
)

public val Res.drawable.brand_menu_icon: DrawableResource
  get() = CommonMainDrawable0.brand_menu_icon

private fun init_brand_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:brand_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/brand_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.business_app_icon: DrawableResource
  get() = CommonMainDrawable0.business_app_icon

private fun init_business_app_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:business_app_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/business_app_icon.icns", -1, -1),
    )
)

public val Res.drawable.business_logo_web: DrawableResource
  get() = CommonMainDrawable0.business_logo_web

private fun init_business_logo_web(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:business_logo_web",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/business_logo_web.png", -1, -1),
    )
)

public val Res.drawable.business_menu_icon: DrawableResource
  get() = CommonMainDrawable0.business_menu_icon

private fun init_business_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:business_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/business_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.catalogue_menu_icon: DrawableResource
  get() = CommonMainDrawable0.catalogue_menu_icon

private fun init_catalogue_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:catalogue_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/catalogue_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.category_menu_icon: DrawableResource
  get() = CommonMainDrawable0.category_menu_icon

private fun init_category_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:category_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/category_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.customer_menu_icon: DrawableResource
  get() = CommonMainDrawable0.customer_menu_icon

private fun init_customer_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:customer_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/customer_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.dashboard_menu_icon: DrawableResource
  get() = CommonMainDrawable0.dashboard_menu_icon

private fun init_dashboard_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:dashboard_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/dashboard_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.employee_menu_icon: DrawableResource
  get() = CommonMainDrawable0.employee_menu_icon

private fun init_employee_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:employee_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/employee_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.invoice_menu_icon: DrawableResource
  get() = CommonMainDrawable0.invoice_menu_icon

private fun init_invoice_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:invoice_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/invoice_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.message_icon: DrawableResource
  get() = CommonMainDrawable0.message_icon

private fun init_message_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:message_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/message_icon.png", -1, -1),
    )
)

public val Res.drawable.orders_menu_icon: DrawableResource
  get() = CommonMainDrawable0.orders_menu_icon

private fun init_orders_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:orders_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/orders_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.partner_menu_icon: DrawableResource
  get() = CommonMainDrawable0.partner_menu_icon

private fun init_partner_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:partner_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/partner_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.product_menu_icon: DrawableResource
  get() = CommonMainDrawable0.product_menu_icon

private fun init_product_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:product_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/product_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.quotation_menu_icon: DrawableResource
  get() = CommonMainDrawable0.quotation_menu_icon

private fun init_quotation_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:quotation_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/quotation_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.sale_menu_icon: DrawableResource
  get() = CommonMainDrawable0.sale_menu_icon

private fun init_sale_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:sale_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/sale_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.share_icon: DrawableResource
  get() = CommonMainDrawable0.share_icon

private fun init_share_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:share_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/share_icon.png", -1, -1),
    )
)

public val Res.drawable.shop_menu_icon: DrawableResource
  get() = CommonMainDrawable0.shop_menu_icon

private fun init_shop_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:shop_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/shop_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.store_menu_icon: DrawableResource
  get() = CommonMainDrawable0.store_menu_icon

private fun init_store_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:store_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/store_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.sub_category_menu_icon: DrawableResource
  get() = CommonMainDrawable0.sub_category_menu_icon

private fun init_sub_category_menu_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:sub_category_menu_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/sub_category_menu_icon.png", -1, -1),
    )
)

public val Res.drawable.support_image: DrawableResource
  get() = CommonMainDrawable0.support_image

private fun init_support_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:support_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/support_image.png", -1, -1),
    )
)

public val Res.drawable.whatsapp_icon: DrawableResource
  get() = CommonMainDrawable0.whatsapp_icon

private fun init_whatsapp_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:whatsapp_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/friendly.shared.generated.resources/drawable/whatsapp_icon.png", -1, -1),
    )
)
