package presentation.screens.common.policy

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import component.html.HtmlContent
import component.navigation.TopNavBar
import htmp_page.privacy.PrivacyPolicy

class PrivacyPolicyScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val allPolicies = PrivacyPolicy().getPrivacyPolicy()
        Column(
            modifier = Modifier
                .fillMaxSize()
        ) {
            TopNavBar("Privacy Policy", null, onBackPress = {
                navigator?.pop()
            })
            LazyColumn(
                modifier = Modifier
                    .fillMaxSize()
            ) {
                items(allPolicies.count()) {
                    HtmlContent(allPolicies[it])
                }
            }
        }
    }
}