package component.button

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import component.image.AsyncImageView
import graph.PlatformType
import theme.ThemeColor
@Composable
fun DownloadAppButton(platformType: PlatformType) {
    val uriHandler = LocalUriHandler.current
    var title = ""
    var subTitle = ""
    var icon = ""
    var appLink = ""
    when(platformType){
        PlatformType.iOS-> {
            title = "Download on the"
            subTitle = "App Store"
            icon = "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/website%2Ficons%2Fapple.png?alt=media&token=270676a7-ec33-47f8-9b54-94359bf2f261"
            appLink = "https://apps.apple.com/in/app/mandee/id6499473243"
        }
        PlatformType.Android -> {
            title = "GET IT ON"
            subTitle = "Google Play"
            icon = "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/website%2Ficons%2Fandroid.png?alt=media&token=8024e04b-23eb-442c-8e8a-621ed1a9caed"
            appLink = "https://play.google.com/store/apps/details?id=business.mandee.ai"
        }
        PlatformType.Web -> {
            title = ""
            subTitle = ""
            icon = ""
            appLink = "https://business.mandee.ai"
        }
        PlatformType.Windows -> {
            title = ""
            subTitle = ""
            icon = "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/website%2Ficons%2Fwindows.png?alt=media&token=86329dc7-d2ca-42de-bc31-7d4f7d9ac151"
        }
        PlatformType.linux -> {
            title = ""
            subTitle = ""
            icon = "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/website%2Ficons%2FLinux.png?alt=media&token=72dfb217-7e28-4ce1-add5-ae356195e6da"
        }
        PlatformType.Mac -> {
            title = "Download on the"
            subTitle = "Mac App Store"
            icon = "https://firebasestorage.googleapis.com/v0/b/friendly-221119.appspot.com/o/website%2Ficons%2Fapple.png?alt=media&token=270676a7-ec33-47f8-9b54-94359bf2f261"
            appLink = "https://apps.apple.com/in/app/mandee/id6499473243"
        }
        PlatformType.Mobile -> {
            title = ""
            subTitle = ""
            icon = ""
        }
        PlatformType.Desktop -> {
            title = ""
            subTitle = ""
            icon = ""
        }
    }


    val modifier = Modifier
        .width(300.dp)
        .height(60.dp)
        .padding(start = 8.dp, end = 8.dp)
        .background(ThemeColor.shared().Black, RoundedCornerShape(8.dp)).clip(RoundedCornerShape(8.dp))
        Button(
            onClick = {
                uriHandler.openUri(appLink)
            },
            modifier = modifier,
        ) {
            Row (modifier = Modifier.fillMaxWidth().fillMaxHeight().background(ThemeColor.shared().Black)){
                Box (modifier=Modifier.width(50.dp).fillMaxHeight()){
                    AsyncImageView(icon)
                }

                Column (modifier = Modifier.weight(1f).fillMaxHeight()){
                    Text(title)
                    Text(subTitle)
                }
            }
        }

}