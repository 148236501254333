package presentation.screens.business.inventory.sub_category.component

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ElevatedCard
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import common.asset.ImageAsset
import component.image.FriendlyIcon
import feature.productSubCategory.model.ProductSubCategory
import theme.ThemeColor

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun ProductSubCategoryRow(
    subCategory: ProductSubCategory,
    onClick: (subCategory: ProductSubCategory) -> Unit
) {
    ElevatedCard(
        modifier = Modifier.height(60.dp).fillMaxWidth().padding(8.dp),
        colors = CardDefaults.cardColors(
            containerColor = ThemeColor.shared().cardBackground,
            contentColor = Color.Black
        ),
        onClick = { onClick(subCategory) }
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(start = 15.dp, end = 15.dp),
            verticalAlignment = Alignment.CenterVertically,
        ) {
//            AsyncImageView(
//                painter = painterResource(id = R.drawable.flash_icon),
//                contentDescription = "Flash Deal",
//                modifier = Modifier
//                    .background(
//                        ThemeColor.shared().PrimaryLightColor,
//                        shape = RoundedCornerShape(10.dp)
//                    )
//                    .size(50.dp)
//                    .clip(RoundedCornerShape(10.dp))
//                    .padding(10.dp)
//                    .align(Alignment.CenterHorizontally)
//            )
            Text(
                modifier = Modifier.weight(1f),
                text = subCategory.Name!!, fontSize = 14.sp, textAlign = TextAlign.Start
            )
            FriendlyIcon(ImageAsset.arrowRightIcon)
        }
    }
}