package component.alert

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.Check
import androidx.compose.material.icons.rounded.Clear
import androidx.compose.material.icons.rounded.QuestionMark
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Color.Companion.Green
import androidx.compose.ui.graphics.Color.Companion.Red
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import component.button.DefaultButton
import component.button.SecondaryButton
import feature.alert.viewModel.AlertViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import locale.localization
import theme.ThemeColor

@Composable
fun AlertView() {
    val viewModel = AlertViewModel()
    val isOpenDialog = viewModel.isAlertVisible.collectAsState()
    val isSuccess = viewModel.isSuccess.collectAsState()
    val isConfirmation = viewModel.isConfirmation.collectAsState()
    val title = viewModel.title.collectAsState()
    val message = viewModel.message.collectAsState()
    val onPositiveClick = viewModel.onPositiveClick.collectAsState()
    val onNegativeClick = viewModel.onNegativeClick.collectAsState()
    if (isOpenDialog.value) {
        var color = ThemeColor.shared().Green
        if (isSuccess.value) {
            color = ThemeColor.shared().Green
        } else if (isConfirmation.value) {
            color = ThemeColor.shared().PrimaryColor
        } else {
            color = ThemeColor.shared().Red
        }
        Dialog(
            onDismissRequest = {
                CoroutineScope(Job() + Dispatchers.Default).launch {
                    viewModel.repository.isAlertVisible.emit(false)
                }
            },
        ) {
            Card(
                Modifier
                    .fillMaxWidth(0.8f)
                    .background(Color.White, shape = RoundedCornerShape(16.dp))
                    .fillMaxHeight(0.3f),
                elevation = CardDefaults.cardElevation(
                    defaultElevation = 4.dp
                ),
                colors = CardDefaults.cardColors(
                    containerColor = Color.White,
                ),
                shape = RoundedCornerShape(12.dp)

            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(10.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Box(
                        modifier = Modifier
                            .height(40.dp)
                            .width(40.dp)
                            .padding(8.dp)
                            .border(1.dp, color, shape = CircleShape)
                    ) {
                        if (isSuccess.value) {
                            Icon(
                                Icons.Rounded.Check,
                                contentDescription = "decrease quantity",
                                tint = Green
                            )
                        } else
                            if (isConfirmation.value) {
                                Icon(
                                    Icons.Rounded.QuestionMark,
                                    contentDescription = "decrease quantity",
                                    tint = ThemeColor.shared().PrimaryColor
                                )
                            } else {
                                Icon(
                                    Icons.Rounded.Clear,
                                    contentDescription = "decrease quantity",
                                    tint = Red
                                )
                            }
                    }
                    Text(
                        text = title.value,
                        fontSize = 18.sp,
                        style = TextStyle(fontWeight = FontWeight(600))
                    )
                    Box(
                        modifier = Modifier
                            .fillMaxWidth().fillMaxHeight().weight(1f)
                            .padding(top = 20.dp)
                    ) {
                        Text(text = message.value, fontSize = 16.sp, textAlign = TextAlign.Center)
                    }
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(60.dp)
                            .padding(start = 8.dp, end = 8.dp)
                    ) {
                        if (isConfirmation.value) {
                            Box(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .height(60.dp)
                                    .padding(top = 16.dp)
                                    .weight(0.5f)
                            ) {
                                SecondaryButton(title = "Cancel", onClick = {
                                    onNegativeClick.value()
                                    CoroutineScope(Job() + Dispatchers.Default).launch {
                                        viewModel.repository.isAlertVisible.emit(false)
                                    }
                                })
                            }
                        }
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .height(60.dp)
                                .padding(top = 16.dp)
                                .weight(0.5f)
                        ) {
                            DefaultButton(title = localization().button.doneButton, onClick = {
                                onPositiveClick.value()
                                CoroutineScope(Job() + Dispatchers.Default).launch {
                                    viewModel.repository.isAlertVisible.emit(false)
                                }
                            })
                        }
                    }
                }
            }
        }
    }

}