package presentation.screens.customer.store.address

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.rounded.Close
import androidx.compose.material.icons.rounded.MyLocation
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.button.DefaultButton
import component.empty.EmptyData
import feature.address.model.Address
import feature.address.viewModel.AddressViewModel
import feature.app.NavigationParam
import locale.localization
import navigation.AppNavigator
import navigation.containers.ScreenName
import presentation.screens.common.address.component.SelectAddressCard
import theme.ThemeColor

@Composable
fun SelectAddressView(
    param: NavigationParam?,
    viewModel: AddressViewModel,
    onClose: () -> Unit,
    onClick: (Address) -> Unit
) {
    val addressList = viewModel.allAddress.collectAsState()
    Column(
        modifier = Modifier.fillMaxSize().background(ThemeColor.shared().cardBackground)
            .padding(8.dp)
    ) {
        Row(modifier = Modifier.fillMaxWidth().height(100.dp)) {
            Box(
                modifier = Modifier
                    .height(100.dp).weight(1f)
            ) {
                Icon(
                    Icons.Rounded.MyLocation,
                    contentDescription = "edit item",
                    tint = ThemeColor.shared().PrimaryColor,
                    modifier = Modifier.width(60.dp).height(60.dp)
                )
            }
            Box(
                modifier = Modifier
                    .width(100.dp)
                    .height(100.dp).clickable { onClose() }
            ) {
                Icon(
                    Icons.Rounded.Close,
                    contentDescription = "edit item",
                    tint = ThemeColor.shared().PrimaryColor,
                    modifier = Modifier.width(40.dp).height(40.dp)
                )
            }
        }

        Text("Select your address", fontSize = 20.sp, fontWeight = FontWeight.Bold)
        Column(modifier = Modifier.fillMaxHeight()) {
            if (addressList.value.isEmpty()) {
                EmptyData(name = "Address", viewModel = viewModel) {

                }
                DefaultButton(
                    title = localization().address.createNewAddress,
                    onClick = {
                        AppNavigator.shared().navigateTo(
                            ScreenName.CREATE_ADDRESS
                        )
                    },
                    modifier = Modifier.fillMaxWidth()
                )
            } else {
                LazyColumn(
                    contentPadding = PaddingValues(horizontal = 16.dp, vertical = 8.dp),
                    modifier = Modifier
                        .fillMaxHeight()
                        .fillMaxWidth()
                ) {
                    items(
                        items = addressList.value,
                        itemContent = {
                            SelectAddressCard(
                                address = it,
                                param?.address?._id == it._id,
                                onClick = { address ->
                                    onClick(address)
                                })
                        })
                    item {
                        DefaultButton(
                            title = "Create New Address",
                            onClick = {
                                AppNavigator.shared().navigateTo(ScreenName.CREATE_ADDRESS)
                            },
                            modifier = null
                        )
                    }
                }
            }

        }
    }
}