package feature.device.handler

import appConfig.AppConfig
import common.constants.KeyConstant
import common.constants.UserDefaults
import feature.address.model.LocationCoordinate
import feature.auth.handler.AuthHandler
import feature.device.model.Device
import feature.device.network.DeviceNetwork
import feature.device.network.DeviceUpdateRequest
import feature.device.repository.DeviceRepository
import feature.device.viewModel.DeviceViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class DeviceHandler {

    val jsonDecoder = Json {
        encodeDefaults = true
        ignoreUnknownKeys = true
    }
    var scope: CoroutineScope = CoroutineScope(Job() + Dispatchers.Default)

    var viewModel: DeviceViewModel? = null
    val repository = DeviceRepository()
    var onCreateNew: ((category: Device) -> Unit)? = null

    init {
        instance = this
    }

    companion object {
        private var instance: DeviceHandler? = null
        fun shared(): DeviceHandler {
            if (instance != null) {
                return instance as DeviceHandler
            } else {
                return DeviceHandler()
            }
        }
    }

    fun loadCurrentDevice() {
        val currentDeviceValue = UserDefaults.retrieve(KeyConstant.currentDevice)
        if (currentDeviceValue.isNotEmpty()) {
            scope.launch {
                repository.currentDeviceState.emit(jsonDecoder.decodeFromString(currentDeviceValue))
            }
        }
    }

    fun setCurrentDeviceLocation(value: LocationCoordinate?) {
        val currentDevice = repository.currentDevice.value
        currentDevice.Location = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun setCurrentDeviceId(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.DeviceID = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun setCurrentDeviceToken(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.FCMToken = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
            delay(1_000)
            updateDeviceToServer()
        }
    }

    fun setCurrentDeviceCountry(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.Country = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun setCurrentDevicePlatform(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.Platform = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun setCurrentDeviceOs(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.OperatingSystem = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun setCurrentDeviceBundleId(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.BundleID = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun setCurrentDeviceAppType(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.AppType = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun setCurrentDeviceBrand(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.Brand = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun setCurrentDeviceModel(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.Model = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }


    fun setCurrentDeviceOsVersion(value: String) {
        val currentDevice = repository.currentDevice.value
        currentDevice.OperatingSystemVersion = value
        UserDefaults.store(KeyConstant.currentDevice, jsonDecoder.encodeToString(currentDevice))
        scope.launch {
            repository.currentDeviceState.emit(currentDevice)
        }
    }

    fun updateDeviceToServer() {
        val currentDeviceValue = UserDefaults.retrieve(KeyConstant.currentDevice)
        if (currentDeviceValue.isNotEmpty()) {
            scope.launch {
                val currentDevice: Device = jsonDecoder.decodeFromString(currentDeviceValue)
                repository.currentDeviceState.emit(currentDevice)
                AuthHandler.shared().checkIfLoggedIn()
                delay(1_000)
                if (AuthHandler.shared().userId.isNotEmpty()) {
                    currentDevice.UserID = AuthHandler.shared().userId
                }
                currentDevice.AppType = AppConfig.shared().appType.name
                currentDevice.Platform = AppConfig.shared().platformType.name
                currentDevice.OperatingSystem = AppConfig.shared().platform.name
                val updateRequest: DeviceUpdateRequest =
                    jsonDecoder.decodeFromString(jsonDecoder.encodeToString(currentDevice))
                DeviceNetwork().update(updateRequest) {
                }
            }
        } else {
            scope.launch {
                val currentDevice: Device = repository.currentDevice.value
                AuthHandler.shared().checkIfLoggedIn()
                delay(1_000)
                if (AuthHandler.shared().userId.isNotEmpty()) {
                    currentDevice.UserID = AuthHandler.shared().userId
                }
                currentDevice.AppType = AppConfig.shared().appType.name
                currentDevice.Platform = AppConfig.shared().platformType.name
                currentDevice.OperatingSystem = AppConfig.shared().platform.name
                val updateRequest: DeviceUpdateRequest =
                    jsonDecoder.decodeFromString(jsonDecoder.encodeToString(currentDevice))
                DeviceNetwork().update(updateRequest) {
                }
            }
        }
    }
}