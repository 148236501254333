package presentation.screens.business.employee.edit

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.DropdownMenuItem
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import component.country.DialCodeHelper
import component.input.CustomTextField
import component.input.MobileTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.alert.handler.AlertHandler
import feature.employee.viewModel.EmployeeViewModel
import theme.ThemeColor

class EditEmployeeScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = EmployeeViewModel()
        val editEmployee = viewModel.editEmployee.collectAsState()
        val jobRoleResult = viewModel.allJobRole.collectAsState()
        val nameErrorState = remember { mutableStateOf(false) }
        val emailErrorState = remember { mutableStateOf(false) }
        val barcodeErrorState = remember { mutableStateOf(false) }
        var dialCode by remember { mutableStateOf(DialCodeHelper().getSelectedCountryCode()) }
        val mobileErrorState = remember {
            mutableStateOf(false)
        }
        var isValidMobile by remember { mutableStateOf(false) }
        var showJobRole by remember { mutableStateOf(false) }

        LaunchedEffect(key1 = Unit) {
            viewModel.fetchAllJobRole()
        }

        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = "Edit Employee", null, onBackPress = {
                navigator?.pop()
            })
            AppLoader(viewModel)
            Column(modifier = Modifier.fillMaxSize()) {
                CustomTextField(
                    value = editEmployee.value?.Name ?: "",
                    placeholder = "First Name Last Name",
                    trailingIcon = ImageAsset.person,
                    label = "Name",
                    errorState = nameErrorState.value,
                    keyboardType = KeyboardType.Email,
                    visualTransformation = VisualTransformation.None
                ) { newValue ->
                    viewModel.editEmployeeName(newValue.text)
                }
                Spacer(modifier = Modifier.height(8.dp))
                CustomTextField(
                    value = editEmployee.value?.EmailID ?: "",
                    placeholder = "Email ID",
                    trailingIcon = ImageAsset.person,
                    label = "Email ID",
                    errorState = emailErrorState.value,
                    keyboardType = KeyboardType.Email,
                    visualTransformation = VisualTransformation.None
                ) { newValue ->
                    viewModel.editEmployeeEmail(newValue.text)
                }
                Spacer(modifier = Modifier.height(8.dp))
                MobileTextField(
                    mobileNumber = editEmployee.value?.MobileNumber ?: "",
                    placeholder = "9000000000",
                    trailingIcon = ImageAsset.phone,
                    label = "Mobile Number",
                    errorState = mobileErrorState,
                    visualTransformation = VisualTransformation.None,
                    onChanged = { mob ->
                        viewModel.editEmployeeMobile(mob.text)
                    },
                    onDialCodeChange = {
                        dialCode = it
                        viewModel.editEmployeeDialCode(it.countryPhoneCode)
                    }
                )
                Spacer(modifier = Modifier.height(8.dp))
                Row(
                    modifier = Modifier
                        .height(54.dp)
                        .padding(start = 8.dp, end = 8.dp)
                        .fillMaxSize()
                        .border(
                            BorderStroke(1.dp, ThemeColor.shared().PrimaryColor),
                            shape = RoundedCornerShape(16.dp)
                        ).background(
                            color = ThemeColor.shared().cardBackground,
                            shape = RoundedCornerShape(16.dp)
                        ),
                    verticalAlignment = Alignment.CenterVertically

                ) {
                    Text(
                        editEmployee.value?.JobTypeName ?: "Select Job Role",
                        color = ThemeColor.shared().Black,
                        modifier = Modifier
                            .padding(start = 16.dp, end = 0.dp)
                            .clickable {
                                showJobRole = true
                            }
                    )
                    Icon(
                        imageVector = Icons.Default.ArrowDropDown,
                        contentDescription = null
                    )
                    DropdownMenu(
                        expanded = showJobRole,
                        onDismissRequest = { showJobRole = false }
                    ) {
                        jobRoleResult.value.forEach { (id, name) ->
                            DropdownMenuItem(
                                onClick = {
                                    showJobRole = false
                                    if (name != null) {
                                        viewModel.editEmployeeJobRoleType(id, name)
                                    }
                                }
                            ) {
                                if (name != null) {
                                    Text(name)
                                }
                            }
                        }
                    }
                }
                Spacer(modifier = Modifier.height(8.dp))
                CustomDefaultBtn(shapeSize = 50f, btnText = "Continue") {
                    isValidMobile = (viewModel.editEmployee.value?.MobileNumber?.length in 9..11)
                    mobileErrorState.value = !isValidMobile
                    if (isValidMobile) {
                        viewModel.updateEmployee { result ->
                            if (result) {
                                AlertHandler.shared()
                                    .showSuccessAlert("Success", "Employee Updated")
                            } else {
                                AlertHandler.shared().showAlert("Oops!", "Something went wrong")
                            }
                        }
                    } else {
                        AlertHandler.shared().showAlert("Oops!", "Please enter valid input")
                    }
                }
            }
        }
    }
}