package feature.employee.viewModel

import feature.app.AppViewModel
import feature.auth.handler.AuthHandler
import feature.auth.model.FriendlyProfile
import feature.auth.model.FriendlyUser
import feature.business.handler.BusinessHandler
import feature.employee.handler.EmployeeHandler
import feature.employee.model.Employee
import feature.employee.model.EmployeeAttendance
import feature.employee.model.JobRole
import feature.employee.network.CreateEmployeeRequest
import feature.employee.network.EmployeeNetwork
import feature.employee.network.FetchBusinessEmployeeRequest
import feature.employee.network.FetchEmployeeRequest
import feature.employee.network.FetchJobRoleRequest
import feature.employee.network.FindUserRequest
import feature.employee.network.JobRoleNetwork
import feature.employee.network.UpdateEmployeeRequest
import feature.employee.repository.EmployeeRepository
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import storage.database.table.employee.EmployeeDatabaseTable
import storage.database.table.job_role.JobRoleDatabaseTable

class EmployeeViewModel : AppViewModel() {
    val repository: EmployeeRepository = EmployeeHandler.shared().repository
    val allEmployee: StateFlow<ArrayList<Employee>>
        get() = repository.allEmployee

    val employee: StateFlow<Employee?>
        get() = repository.employee

    val newEmployee: StateFlow<Employee>
        get() = repository.newEmployee

    val editEmployee: StateFlow<Employee?>
        get() = repository.editEmployee

    val profile: StateFlow<FriendlyProfile?>
        get() = repository.profile

    //Attendance
    val allEmployeeAttendance: StateFlow<ArrayList<EmployeeAttendance>>
        get() = repository.allEmployeeAttendance

    val employeeAttendanceData: StateFlow<EmployeeAttendance>
        get() = repository.employeeAttendanceData
    val searchQuery: StateFlow<String>
        get() = repository.searchQuery

    val allJobRole: StateFlow<ArrayList<JobRole>>
        get() = repository.allJobRole

    override fun changeSearchQuery(value: String) {
        scope.launch {
            repository.searchQueryState.emit(value)
        }
        performSearch(value)
    }

    fun selectEmployee(employee: Employee?) {
        repository.selectEmployee(employee)
    }

    fun editEmployee(employee: Employee?) {
        repository.setEditEmployee(employee)
    }

    override fun performSearch(value: String) {
        val searchQueryText = value.lowercase()
        scope.launch {
            val filteredResult = repository.allEmployee.value.filter { item ->
                item.Name?.lowercase()
                    ?.contains(searchQueryText) == true || item.MobileNumber?.lowercase()
                    ?.contains(searchQueryText) == true || item.EmailID?.lowercase()?.contains(
                    searchQueryText
                ) == true
            } as ArrayList<Employee>
            repository.allEmployeeLiveData.emit(filteredResult)
        }
    }

//    fun loadAttendanceFor(employee: Employee){
//        employeeRepository.allEmployeeAttendanceLiveData.postValue(arrayListOf())
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            employeeRepository.allEmployeeAttendanceLiveData.postValue(DatabaseHandler.shared().database.employeeAttendanceDao().getAllItemsFor(employee.Id) as ArrayList<EmployeeAttendance>?)
//        }
//    }

    fun editEmployeeName(value: String) {
        repository.editEmployee.value?.Name = value
    }

    fun editEmployeeEmail(value: String) {
        repository.editEmployee.value?.EmailID = value
    }

    fun editEmployeeMobile(value: String) {
        repository.editEmployee.value?.MobileNumber = value
    }

    fun editEmployeeDialCode(value: String) {
        repository.editEmployee.value?.DialCode = value
    }

    fun editEmployeeJobRoleType(id: String, value: String) {
        repository.editEmployee.value?.JobTypeId = id
        repository.editEmployee.value?.JobTypeName = value
    }


    fun retrieveForBusiness() {
        loadFromStorage()
        startLoading()
        val request = FetchBusinessEmployeeRequest(
            BusinessHandler.shared().repository.business.value.Id,
            null//repository.lastSyncDate.value
        )
        scope.launch {
            EmployeeNetwork().retrieve(request) {
                stopLoading()
                if (it?.payload?.isNotEmpty() == true) {
                    scope.launch {
                        EmployeeDatabaseTable().insert(it.payload)
                        loadFromStorage()
                    }
                }
            }
        }
    }

    fun retrieve() {
        loadFromStorage()
        if(AuthHandler.shared().isLoggedIn()){
            startLoading()
            val request = FetchEmployeeRequest(
                AuthHandler.shared().userId,
                null//repository.lastSyncDate.value
            )
            scope.launch {
                EmployeeNetwork().retrieve(request) {
                    stopLoading()
                    if (it?.payload?.isNotEmpty() == true) {
                        scope.launch {
                            EmployeeDatabaseTable().insert(it.payload)
                            loadFromStorage()
                        }
                    }
                }
            }
        }

    }

    fun createNew(employeeProfile: FriendlyProfile) {
        val user = FriendlyUser()
//        var request = JSONObject()
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.businessID, BusinessHandler.shared().repository.business.value?.Id)
//        request.put(KeyConstant.name, employeeProfile.Name)
//        request.put(KeyConstant.mobileNumber, employeeProfile.MobileNumber)
//        request.put(KeyConstant.employeeUserID, employeeProfile.Id)
//        request.put(KeyConstant.emailId, employeeProfile.EmailID)
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        SocketService.shared().send(SocketEvent.CREATE_EMPLOYEE, request)
    }

    fun insert(employee: Employee) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.employeeDao()
//                .insert(employee)
//        }
    }

    fun findUser(dialCode: String, mobile: String, callBack: (FriendlyProfile?) -> Unit) {
        startLoading()
        val request = FindUserRequest(
            "",//Userid as null
            mobile,
            dialCode
        )
        scope.launch {
            EmployeeNetwork().findUser(request) {
                stopLoading()
                if (it?.payload?.Id?.isNotBlank() == true) {
                    scope.launch {
                        repository.profileLiveData.emit(it.payload)
                    }
                    callBack(it.payload)
                } else {
                    scope.launch {
                        repository.profileLiveData.emit(null)
                    }
                    callBack(null)
                }
            }
        }
    }

    fun addEmployee(employee: FriendlyProfile?, callBack: (Boolean) -> Unit) {
        startLoading()
        val request = CreateEmployeeRequest(
            AuthHandler.shared().repository.authUser.value.id,
            BusinessHandler.shared().repository.business.value.Id,
            employee?.Id,
            employee?.Name,
            employee?.Address,
            employee?.Status,
            employee?.EmailID,
            employee?.MobileNumber,
            employee?.DialCode,
            employee?.WhatsApp,
            employee?.Gender,
            employee?.DeviceID,
            employee?.FCMToken,
            employee?.JobTypeId,
            employee?.JobTypeName,
        )
        scope.launch {
            EmployeeNetwork().createEmployee(request) {
                stopLoading()
                if (!it?.payload?.Id.isNullOrEmpty()) {
                    callBack(true)
                    scope.launch {
                        EmployeeHandler.shared().repository.newEmployeeLiveData.emit(it!!.payload)
                    }
                } else {
                    callBack(false)
                }
            }
        }
    }

    fun updateEmployee(
        callBack: (Boolean) -> Unit
    ) {
        val request = editEmployee.value?.let {
            UpdateEmployeeRequest(
                it.Id,
                AuthHandler.shared().repository.authUser.value.id,
                BusinessHandler.shared().repository.business.value.Id,
                editEmployee.value?.Name ?: "",
                editEmployee.value?.DialCode ?: "",
                editEmployee.value?.MobileNumber ?: "",
                editEmployee.value?.EmailID ?: "",
                AuthHandler.shared().deviceId,
                editEmployee.value?.IsDeleted,
                editEmployee.value?.JobTypeId,
                editEmployee.value?.JobTypeName,
            )
        }
        request?.let {
            startLoading()
            scope.launch {
                EmployeeNetwork().updateEmployee(it) { response ->
                    stopLoading()
                    if (!response?.payload?.Id.isNullOrEmpty()) {
                        callBack(true)
                        scope.launch {
                            EmployeeHandler.shared().repository.employeeLiveData.emit(response!!.payload!!)
                        }
                    } else {
                        callBack(false)
                    }
                }
            }
        }
    }

//    fun findUser(mobile: String) {
//
//        val user = FriendlyUser()
//        var request = JSONObject()
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.businessID, BusinessHandler.shared().repository.business.value?.Id)
//        request.put(KeyConstant.mobileNumber, mobile)
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        SocketService.shared().send(SocketEvent.FIND_USER, request)
//    }

    fun createNewAttendance(
        employee: Employee,
        isPresent: Boolean,
        date: String,
        hours: Float,
        comment: String
    ) {
        val user = FriendlyUser()
//        val request = JSONObject()
//        val business = BusinessHandler.shared().repository.business.value
//        request.put(KeyConstant.userId, user._id)
//        request.put(KeyConstant.businessID, business?.Id)
//        request.put(KeyConstant.employeeUserID, employee.EmployeeUserID)
//        request.put(KeyConstant.employeeID, employee.Id)
//        request.put(KeyConstant.attendanceDate, date)
//        request.put(KeyConstant.isPresent, isPresent)
//        request.put(KeyConstant.hours, hours)
//        request.put(KeyConstant.comment, comment)
//        request.put(KeyConstant.deviceId, AuthHandler.shared().deviceId)
//        SocketService.shared().send(SocketEvent.ADD_EMPLOYEE_ATTENDACE, request)
    }

    fun insertAttendance(attendance: EmployeeAttendance) {
//        CoroutineScope(Job() + Dispatchers.IO).launch {
//            DatabaseHandler.shared().database.employeeAttendanceDao()
//                .insert(attendance)
//        }
    }

    fun isPresent(dateString: String, employee: Employee): Boolean? {
        allEmployeeAttendance.value.forEach {
            if (it.AttendanceDate?.contains(dateString) == true) {
                return it.IsPresent
            }
        }
        return null
    }

    fun fetchAllJobRole() {
        jobRoleFromStorage()
        startLoading()
        val request = FetchJobRoleRequest(
            null
        )
        scope.launch {
            JobRoleNetwork().retrieveJobRole(request) {
                stopLoading()
                if (it?.payload?.isNotEmpty() == true) {
                    scope.launch {
                        JobRoleDatabaseTable().insert(it.payload)
                        jobRoleFromStorage()
                    }
                }
            }
        }
    }

    private fun jobRoleFromStorage() {
        JobRoleDatabaseTable().retrieve { data ->
            scope.launch {
                repository.allJobRoleLiveData.emit(data)
            }
        }
    }

    private fun loadFromStorage() {
        scope.launch {
            EmployeeDatabaseTable().retrieveForBusiness(BusinessHandler.shared().businessId) { data ->
                scope.launch {
                    repository.allEmployeeLiveData.emit(data as ArrayList<Employee>)
                }
            }
        }
    }
}