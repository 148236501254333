package feature.businessType.handler

import feature.businessType.repository.BusinessTypeRepository


class BusinessTypeHandler {

    val repository = BusinessTypeRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: BusinessTypeHandler? = null
        fun shared(): BusinessTypeHandler {
            if (instance != null) {
                return instance as BusinessTypeHandler
            } else {
                return BusinessTypeHandler()
            }
        }
    }
}