package feature.businessConnection.handler

import feature.businessConnection.repository.BusinessConnectionRepository
import feature.notification.viewModel.NotificationViewModel
import feature.offerBanner.model.OfferBanner

class BusinessConnectionHandler {


    var viewModel: NotificationViewModel? = null
    val repository = BusinessConnectionRepository()
    var onCreateNew: ((category: OfferBanner) -> Unit)? = null

    init {
        instance = this
    }

    companion object {
        private var instance: BusinessConnectionHandler? = null
        fun shared(): BusinessConnectionHandler {
            if (instance != null) {
                return instance as BusinessConnectionHandler
            } else {
                return BusinessConnectionHandler()
            }
        }
    }
}