package com.friendly.common.database.shared

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import com.friendly.common.database.AddressTableQueries
import com.friendly.common.database.BrandTableQueries
import com.friendly.common.database.BusinessTableQueries
import com.friendly.common.database.CartItemTableQueries
import com.friendly.common.database.CouponTableQueries
import com.friendly.common.database.CustomerTableQueries
import com.friendly.common.database.DiscountTableQueries
import com.friendly.common.database.EmployeeTableQueries
import com.friendly.common.database.FriendlyDatabase
import com.friendly.common.database.InventoryTableQueries
import com.friendly.common.database.InvoiceTableQueries
import com.friendly.common.database.JobRoleTableQueries
import com.friendly.common.database.MediaFileTableQueries
import com.friendly.common.database.ProductCategoryTableQueries
import com.friendly.common.database.ProductColorTableQueries
import com.friendly.common.database.ProductSubCategoryTableQueries
import com.friendly.common.database.ProductTableQueries
import com.friendly.common.database.SizeTableQueries
import com.friendly.common.database.TagTableQueries
import com.friendly.common.database.UOMTableQueries
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass

internal val KClass<FriendlyDatabase>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = FriendlyDatabaseImpl.Schema

internal fun KClass<FriendlyDatabase>.newInstance(driver: SqlDriver): FriendlyDatabase =
    FriendlyDatabaseImpl(driver)

private class FriendlyDatabaseImpl(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver), FriendlyDatabase {
  override val addressTableQueries: AddressTableQueries = AddressTableQueries(driver)

  override val brandTableQueries: BrandTableQueries = BrandTableQueries(driver)

  override val businessTableQueries: BusinessTableQueries = BusinessTableQueries(driver)

  override val cartItemTableQueries: CartItemTableQueries = CartItemTableQueries(driver)

  override val couponTableQueries: CouponTableQueries = CouponTableQueries(driver)

  override val customerTableQueries: CustomerTableQueries = CustomerTableQueries(driver)

  override val discountTableQueries: DiscountTableQueries = DiscountTableQueries(driver)

  override val employeeTableQueries: EmployeeTableQueries = EmployeeTableQueries(driver)

  override val inventoryTableQueries: InventoryTableQueries = InventoryTableQueries(driver)

  override val invoiceTableQueries: InvoiceTableQueries = InvoiceTableQueries(driver)

  override val jobRoleTableQueries: JobRoleTableQueries = JobRoleTableQueries(driver)

  override val mediaFileTableQueries: MediaFileTableQueries = MediaFileTableQueries(driver)

  override val productCategoryTableQueries: ProductCategoryTableQueries =
      ProductCategoryTableQueries(driver)

  override val productColorTableQueries: ProductColorTableQueries = ProductColorTableQueries(driver)

  override val productSubCategoryTableQueries: ProductSubCategoryTableQueries =
      ProductSubCategoryTableQueries(driver)

  override val productTableQueries: ProductTableQueries = ProductTableQueries(driver)

  override val sizeTableQueries: SizeTableQueries = SizeTableQueries(driver)

  override val tagTableQueries: TagTableQueries = TagTableQueries(driver)

  override val uOMTableQueries: UOMTableQueries = UOMTableQueries(driver)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 1

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS  AddressTable(
          |        UniqueID INTEGER,
          |        _id TEXT NOT NULL PRIMARY KEY,
          |        UserID TEXT,
          |        FeatureObjectID TEXT,
          |        Name TEXT,
          |        ZipCode INTEGER ,
          |        Country TEXT,
          |        State TEXT,
          |        City TEXT,
          |        Area TEXT,
          |        LandMark TEXT,
          |        House TEXT,
          |        FlatNumber TEXT,
          |        Floor TEXT,
          |        MobileNumber TEXT,
          |        Type TEXT,
          |        Location TEXT ,
          |        IsPrimary INTEGER NOT NULL DEFAULT 0,
          |        IsDeleted INTEGER NOT NULL DEFAULT 0,
          |        CreatedAt TEXT,
          |        UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS BrandTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    BrandID INTEGER,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Description TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS BusinessTable (
          |        _id TEXT NOT NULL PRIMARY KEY,
          |        BusinessNumber INTEGER,
          |        UserID TEXT,
          |        BusinessTypeID TEXT,
          |        Name TEXT,
          |        Address TEXT,
          |        Location TEXT,
          |        Latitude REAL ,
          |        Longitude REAL,
          |        DealerName TEXT ,
          |        ProductTypes TEXT,
          |        GSTNumber TEXT,
          |        PanNumber TEXT,
          |        GSTVerified TEXT,
          |        PanVerified TEXT,
          |        BusinessVerified TEXT,
          |        Status TEXT ,
          |        EmailID TEXT ,
          |        MobileNumber TEXT ,
          |        DialCode TEXT ,
          |        Gender INTEGER ,
          |        DeviceID TEXT ,
          |        FCMToken TEXT ,
          |        LastSeen TEXT ,
          |        IsPrimary INTEGER DEFAULT 0,
          |        IsDeleted INTEGER DEFAULT 0,
          |        CreatedAt TEXT,
          |        UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS CartItemTable (
          |    UniqueID INTEGER NOT NULL PRIMARY KEY,
          |    _id TEXT,
          |    UserID TEXT ,
          |    BusinessID TEXT,
          |    SelectedBusinessID TEXT,
          |    CustomerId TEXT,
          |    ProductID TEXT,
          |    Product TEXT,
          |    Price TEXT,
          |    Quantity INTEGER NOT NULL ,
          |    FinalPrice REAL NOT NULL,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS CouponTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    CouponID INTEGER,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Code TEXT,
          |    Type INTEGER,
          |    Value INTEGER ,
          |    Description TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS CustomerTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Address TEXT,
          |    GSTNumber TEXT,
          |    Barcode TEXT,
          |    BarCodeImage TEXT,
          |    Status TEXT,
          |    EmailID TEXT,
          |    DialCode TEXT,
          |    MobileNumber TEXT,
          |    WhatsApp TEXT,
          |    Gender INTEGER,
          |    DeviceID TEXT,
          |    FCMToken TEXT,
          |    TotalSale INTEGER,
          |    TotalPayment INTEGER,
          |    LastSeen TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS DiscountTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    DiscountID INTEGER,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    ApplyLimit INTEGER ,
          |    Value INTEGER,
          |    Description TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS EmployeeTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    UserID TEXT,
          |    EmployeeUserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER NOT NULL DEFAULT 0,
          |    Name TEXT,
          |    Address TEXT ,
          |    GSTNumber TEXT ,
          |    Barcode TEXT,
          |    Status TEXT,
          |    EmailID TEXT,
          |    MobileNumber TEXT,
          |    WhatsApp TEXT,
          |    DialCode TEXT,
          |    Gender INTEGER,
          |    DeviceID TEXT,
          |    FCMToken TEXT,
          |    TotalSale INTEGER ,
          |    TotalPayment INTEGER,
          |    LastSeen TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT,
          |    __v INTEGER,
          |    JobTypeId TEXT,
          |    JobTypeName TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS InventoryTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    InventoryID INTEGER NOT NULL,
          |    ProductID TEXT NOT NULL ,
          |    UserID TEXT NOT NULL,
          |    BusinessID TEXT NOT NULL,
          |    CategoryID TEXT,
          |    SubCategoryID TEXT ,
          |    IsDeleted INTEGER NOT NULL DEFAULT 0,
          |    Name TEXT,
          |    Description TEXT,
          |    Model TEXT,
          |    Barcode TEXT,
          |    BrandID TEXT,
          |    UOMID TEXT,
          |    ColorID TEXT,
          |    Quantity INTEGER,
          |    SizeID TEXT,
          |    DiscountID TEXT,
          |    CoupanID TEXT,
          |    TagID TEXT,
          |    MaxQuantity INTEGER,
          |    MinQuantity INTEGER,
          |    StartRange INTEGER,
          |    EndRange INTEGER,
          |    SerialLength INTEGER,
          |    ShortCode TEXT,
          |    ProductPrice TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS InvoiceTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    InvoiceID INTEGER,
          |    ShareLink TEXT,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    CustomerID TEXT,
          |    CustomerName TEXT ,
          |    DialCode TEXT ,
          |    MobileNumber TEXT,
          |    IsDeleted INTEGER NOT NULL DEFAULT 0,
          |    Sales TEXT,
          |    InvoiceNumber INTEGER,
          |    InvoiceType TEXT,
          |    TotalPrice REAL,
          |    GST REAL,
          |    SGST REAL,
          |    CGST REAL,
          |    IGST REAL,
          |    CESS REAL,
          |    VAT REAL,
          |    Tax REAL,
          |    CostPrice REAL,
          |    InstantDiscount REAL ,
          |    FinalPrice REAL,
          |    PendingAmount REAL,
          |    ClearedAmount REAL,
          |    InvoiceDate TEXT ,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT,
          |    __v INTEGER
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS JobRoleTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    Name TEXT,
          |    Description TEXT,
          |    IsDeleted INTEGER NOT NULL DEFAULT 0,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT,
          |    __v INTEGER
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS MediaFileTable(
          |    UniqueID INTEGER,
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    FeatureObjectID TEXT,
          |    IsDeleted INTEGER NOT NULL DEFAULT 0,
          |    FileURL TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS ProductCategoryTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    ProductCategoryID INTEGER,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Image TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS ProductColorTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    ColorID INTEGER,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Code TEXT,
          |    Hex TEXT,
          |    R INTEGER,
          |    G INTEGER,
          |    B INTEGER,
          |    A INTEGER,
          |    Description TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS ProductSubCategoryTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    ProductSubCategoryID INTEGER ,
          |    ProductCategoryID TEXT,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Image TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS ProductTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    ProductID INTEGER NOT NULL,
          |    UserID TEXT NOT NULL,
          |    BusinessID TEXT NOT NULL,
          |    CategoryID TEXT,
          |    SubCategoryID TEXT ,
          |    Name TEXT,
          |    Description TEXT,
          |    ProductPrice TEXT,
          |    ManageInventory INTEGER NOT NULL DEFAULT 0,
          |    IsDeleted INTEGER NOT NULL DEFAULT 0,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS SizeTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    SizeID INTEGER,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Value TEXT,
          |    Description TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS TagTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    TagID INTEGER,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Value TEXT ,
          |    Description TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE IF NOT EXISTS UOMTable (
          |    _id TEXT NOT NULL PRIMARY KEY,
          |    UOMID INTEGER,
          |    UserID TEXT,
          |    BusinessID TEXT,
          |    IsDeleted INTEGER DEFAULT 0,
          |    Name TEXT,
          |    Value INTEGER ,
          |    Description TEXT,
          |    CreatedAt TEXT,
          |    UpdatedAt TEXT
          |)
          """.trimMargin(), 0).await()
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
    }
  }
}
