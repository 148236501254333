package presentation.screens.business.customer

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.empty.EmptyData
import component.input.SearchBar
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.customer.viewModel.CustomerViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.customer.component.CustomerCard
import theme.ThemeColor

class CustomerListScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = CustomerViewModel()
        val allCustomer = viewModel.allCustomer.collectAsState()
        val searchQuery = viewModel.searchQuery.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.loadCustomers()
            viewModel.editCustomer(null)
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(
                title = localization().customer.customersTitle,
                navigationParam,
                onBackPress = {
                    navigator?.pop()
                },
                onClickAdd = {
                    navigateTo(ScreenName.CREATE_CUSTOMER, null)
                })
            AppLoader(viewModel)
            SearchBar(
                viewModel,
                searchQuery.value,
                localization().customer.searchCustomerPlaceholder
            )
            if (allCustomer.value.isEmpty()) {
                EmptyData(viewModel = viewModel)
                DefaultButton(
                    localization().customer.createCustomerTitle,
                    onClick = {
                        navigateTo(
                            ScreenName.CREATE_CUSTOMER, null
                        )
                    })
            } else {
                Box(
                    modifier = Modifier
                        .height(50.dp)
                        .padding(start = 8.dp, end = 8.dp)
                        .background(
                            ThemeColor.shared().cardBackground,
                            shape = RoundedCornerShape(10.dp)
                        ),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = "${localization().customer.customerTotal} ${allCustomer.value.count()} ${localization().customer.customerCustomers}",
                        maxLines = 2,
                        overflow = TextOverflow.Ellipsis,
                        modifier = Modifier
                            .wrapContentHeight().fillMaxWidth(),
                        textAlign = TextAlign.Center,
                        color = ThemeColor.shared().PrimaryColor
                    )
                }
            }
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 400.dp),
                modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
            ) {
                items(count = allCustomer.value.count()) { index ->
                    CustomerCard(allCustomer.value[index]) {
                        navigateTo(
                            ScreenName.CUSTOMER_DETAILS,
                            NavigationParam(customer = it)
                        )
                    }
                }
                item {
                    Spacer(modifier = Modifier.height(100.dp))
                }
            }
        }
    }

}