package com.friendly.common.database

import kotlin.Boolean
import kotlin.Long
import kotlin.String

public data class ProductTable(
  public val _id: String,
  public val ProductID: Long,
  public val UserID: String,
  public val BusinessID: String,
  public val CategoryID: String?,
  public val SubCategoryID: String?,
  public val Name: String?,
  public val Description: String?,
  public val ProductPrice: String?,
  public val ManageInventory: Boolean,
  public val IsDeleted: Boolean,
  public val CreatedAt: String?,
  public val UpdatedAt: String?,
)
