package feature.inventory.network.discount

import feature.inventory.model.discount.Discount
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveDiscountResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<Discount>,
)