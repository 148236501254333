package feature.inventory.handler

import feature.inventory.repository.CoupanRepository
import feature.inventory.viewModel.coupon.CoupanViewModel


class CoupanHandler {

    var viewModel: CoupanViewModel? = null
    val repository = CoupanRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: CoupanHandler? = null
        fun shared(): CoupanHandler {
            if (instance != null) {
                return instance as CoupanHandler
            } else {
                return CoupanHandler()
            }
        }
    }

    fun setup(model: CoupanViewModel) {
        viewModel = model
    }
}