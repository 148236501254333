package feature.payment.network

import feature.payment.model.Payment
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreatePaymentResponse(
    @SerialName("Payload") val payload: Payment?
)

@Serializable
data class RetrievePaymentResponse(
    @SerialName("Payload") val payload: List<Payment>?
)

@Serializable
data class FindPaymentResponse(
    @SerialName("Payload") val payload: Payment?
)