package presentation.screens.business.self_business.detail

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.button.DefaultButton
import component.image.BusinessQRCode
import component.image.ImageView
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.business.utill.AddressUtill
import feature.cart.viewModel.CartViewModel
import feature.product.viewModel.ProductViewModel
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.profile.self.component.ItemCard
import theme.ThemeColor

class SelfBusinessDetailScreen(override val screenName: ScreenName) : AppScreen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val cartViewModel = CartViewModel()
        val business = navigationParam?.business
        val productViewModel = ProductViewModel()
        val products = productViewModel.allProduct.collectAsState()

        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar("BUSINESS PAGE", navigationParam, onBackPress = {
                navigator?.pop()
            })
            LazyColumn(
                modifier = Modifier.fillMaxWidth().fillMaxHeight()
                    .padding(bottom = 60.dp, start = 8.dp, end = 8.dp)
                    .background(ThemeColor.shared().defaultBakground)
            ) {
                item {
                    Box(modifier = Modifier.fillMaxWidth().height(200.dp)) {
                        ImageView(business?.Id, true)
                    }
                }
                item {
                    Text(
                        business?.Name ?: "",
                        fontSize = 20.sp,
                        color = ThemeColor.shared().deepBlackText,
                        fontWeight = FontWeight.Bold,
                        fontStyle = FontStyle.Normal,
                        modifier = Modifier
                            .padding(top = 12.dp)
                    )
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .height(60.dp)
                            .padding(top = 16.dp)
                    ) {
                        Column(
                            modifier = Modifier
                                .padding(end = 8.dp)
                        ) {
                            Text(
                                "⭐4.5",
                                fontSize = 16.sp,
                                color = ThemeColor.shared().deepBlackText,
                                fontWeight = FontWeight.Bold,
                                fontStyle = FontStyle.Normal
                            )
                            Text("Ratings", fontSize = 14.sp, color = ThemeColor.shared().Grey)
                        }
                        HorizontalDivider(
                            modifier = Modifier
                                .fillMaxHeight()
                                .width(2.dp),
                            color = Color.Gray
                        )
                        Column(
                            modifier = Modifier
                                .padding(start = 8.dp, end = 8.dp)
                        ) {
                            Text(
                                "200",
                                fontSize = 16.sp,
                                color = ThemeColor.shared().deepBlackText,
                                fontWeight = FontWeight.Bold,
                                fontStyle = FontStyle.Normal
                            )
                            Text("Followers", fontSize = 14.sp, color = ThemeColor.shared().Grey)
                        }
                        HorizontalDivider(
                            modifier = Modifier
                                .fillMaxHeight()
                                .width(2.dp),
                            color = Color.Gray
                        )
                        Column(
                            modifier = Modifier
                                .padding(start = 8.dp, end = 8.dp)
                                .weight(0.4f)
                        ) {
                            Text(
                                "${products.value.count()}",
                                fontSize = 16.sp,
                                color = ThemeColor.shared().deepBlackText,
                                fontWeight = FontWeight.Bold,
                                fontStyle = FontStyle.Normal
                            )
                            Text("Products", fontSize = 14.sp, color = ThemeColor.shared().Grey)
                        }
                        DefaultButton("Edit", {
                            navigateTo(
                                ScreenName.MY_BUSINESS_EDIT, navigationParam?.update(
                                    NavigationParam(business = business)
                                )
                            )
                        })

                    }
                }
                item {
                    Text(
                        "Best seller items",
                        fontSize = 20.sp,
                        color = ThemeColor.shared().deepBlackText,
                        fontWeight = FontWeight.Bold,
                        fontStyle = FontStyle.Normal,
                        modifier = Modifier
                            .padding(top = 20.dp)
                    )
                    LazyRow(modifier = Modifier.weight(1f).padding(start = 8.dp, end = 8.dp)) {
                        items(products.value.count()) { index ->
                            ItemCard(
                                products.value[index],
                                viewModel = cartViewModel,
                                navigationParam
                            )
                        }
                    }
                }
                item {
//                    Row(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .height(40.dp)
//                            .padding(top = 15.dp)
//                    ) {
//                        //Text("View Catalogue", fontSize = 16.dp, color = Color.Black)
//                        // Text("View All", fontSize = 14.dp, color = Color.Blue)
//                        Text(
//                            text = "View Catalogue",
//                            fontSize = 16.sp,
//                            color = Color.Black,
//                            modifier = Modifier
//                                .weight(0.05f)
////                        .padding(top = 45.dp, end = 55.dp)
//
//                        )
//                        Text(
//                            text = "View All",
//                            fontSize = 14.sp,
//                            color = Color.Blue,
//                            modifier = Modifier
////                        .padding(top = 45.dp, end = 55.dp)
//
//                        )
//
//                    }
//                    LazyRow(modifier = Modifier.weight(1f).padding(start = 8.dp, end = 8.dp)) {
//                        item { CatalogueCard() }
//                        item { CatalogueCard() }
//                        item { CatalogueCard() }
//                        item { CatalogueCard() }
//                    }
                    Text(
                        "Contact Details",
                        fontSize = 20.sp,
                        color = ThemeColor.shared().deepBlackText,
                        fontWeight = FontWeight.Bold,
                        fontStyle = FontStyle.Normal,
                        modifier = Modifier
                            .padding(top = 10.dp)
                    )


                    Text(
                        "\uD83D\uDCDE  ${business?.DialCode}${business?.mobileNumber}",
                        fontSize = 14.sp,
                        color = Color.Gray,
                        modifier = Modifier
                            .padding(top = 10.dp)
                    )
                    Text(
                        " ✉\uFE0F ${business?.EmailID}",
                        fontSize = 14.sp,
                        color = Color.Gray,
                        modifier = Modifier
                            .padding(bottom = 10.dp)
                    )
                    Text(
                        "Address",
                        fontSize = 20.sp,
                        color = ThemeColor.shared().deepBlackText,
                        fontWeight = FontWeight.Bold,
                        fontStyle = FontStyle.Normal,
                        modifier = Modifier
                            .padding(top = 10.dp)
                    )
                    Text(
                        AddressUtill().getAddressToDisplayInSingleLine(business?.Address),
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                    Text(
                        "Get Direction",
                        fontSize = 12.sp,
                        color = Color.Blue
                    )
                    BusinessQRCode(business = business)
                }
            }
        }
    }
}