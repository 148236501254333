package common.constants

object Server {

    private var localServer: String = "http://192.168.29.232:8080/api"
    var primaryServer: String = "https://solute-rest-v3-cg5klytkca-ew.a.run.app/api"
    private var firebaseServer: String = "https://asia-south1-mandee-ai.cloudfunctions.net/app"
    private var imageServer: String = "https://asia-south1-mandee-ai.cloudfunctions.net/app/GET_MEDIA_FILE?id="

    fun getRestApiEndPointFor(api: String): String {
        return "$primaryServer/$api"
//        return "$primaryServer/$api"
    }

    fun image(id: String): String {
        return "$imageServer$id"
    }
}
