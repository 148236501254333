package feature.inventory.network.uom

import feature.inventory.model.uom.UOM
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveUOMResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<UOM>,
)