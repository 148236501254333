package presentation.screens.common.auth

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import com.friendly.shared.component.CustomDefaultBtn
import common.asset.ImageAsset
import component.input.CustomTextField
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.auth.viewModel.AuthViewModel
import navigation.AppNavigator
import navigation.containers.ScreenName

class SetMPinScreen : Screen {


    @Composable
    override fun Content() {
        val viewModel = AuthViewModel()
        val navigator = LocalNavigator.current
        var password by remember { mutableStateOf(TextFieldValue("")) }
        var retypePassword by remember { mutableStateOf(TextFieldValue("")) }
        var passwordFromServer = viewModel.password.collectAsState()
        var isValidPassword by remember { mutableStateOf(false) }
        val passwordErrorState = remember {
            mutableStateOf(false)
        }
        viewModel.goToHome = {
            AppNavigator.shared().popAll()
        }
        viewModel.goBack = {
            AppNavigator.shared().pop()
        }
        viewModel.goToSetMPin = {
            AppNavigator.shared().navigateTo(ScreenName.SET_M_PIN)
        }
        viewModel.goToLoginWithMPin = {
            AppNavigator.shared().navigateTo(
                ScreenName.VERIFY_M_PIN
            )
        }
        viewModel.goToOtp = {
            AppNavigator.shared().navigateTo(ScreenName.OTP)
        }

        fun onClickContinue() {
            if (password.text.length == 6 && retypePassword.text.length == 6 && !viewModel.tempAuthUser.value.id.isNullOrEmpty()) {
                viewModel.setPassword(viewModel.tempAuthUser.value.id, password.text)
            }
        }

        Column(modifier = Modifier.fillMaxWidth().fillMaxHeight()) {
            TopNavBar(title = "Set MPin", null, onBackPress = {
                AppNavigator.shared().pop()
            })
            AppLoader(viewModel)
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(30.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                CustomTextField(
                    placeholder = "******",
                    trailingIcon = ImageAsset.lock,
                    label = "6 Digit MPin",
                    keyboardType = KeyboardType.Phone,
                    errorState = passwordErrorState.value,
                    visualTransformation = VisualTransformation.None
                ) { newPass ->
                    password = newPass
                }
                CustomTextField(
                    placeholder = "******",
                    trailingIcon = ImageAsset.lock,
                    label = "Re Enter Mpin",
                    keyboardType = KeyboardType.Phone,
                    errorState = passwordErrorState.value,
                    visualTransformation = VisualTransformation.None
                ) { newPass ->
                    retypePassword = newPass
                }
                Spacer(modifier = Modifier.fillMaxHeight(0.3f))
                CustomDefaultBtn(shapeSize = 50f, btnText = "Continue") {
                    onClickContinue()
                }
                Spacer(modifier = Modifier.fillMaxHeight(0.3f))
            }
        }
    }
}