package com.friendly.shared.component

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.unit.dp
import common.asset.ImageAsset
import component.image.FriendlyIcon
import theme.ThemeColor

@Composable
fun DefaultBackArrow(
    onClick: (() -> Unit)? = null
) {
    Box(
        modifier = Modifier
            .size(40.dp)
            .padding(start = 8.dp)
            .clip(CircleShape)
            .clickable {
                if (onClick != null) {
                    onClick()
                }
            },
        contentAlignment = Alignment.Center
    ) {
        FriendlyIcon(name = ImageAsset.arrowLeftIcon, tintColor = ThemeColor.shared().White)
    }
}