package presentation.screens.business.invoice.list

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.navigator.LocalNavigator
import component.input.SearchBar
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.invoice.viewModel.InvoiceViewModel
import navigation.AppNavigator
import navigation.containers.ScreenName
import presentation.screens.business.invoice.component.InvoiceCard
import theme.ThemeColor

class BusinessInvoiceListScreen : Screen {
    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = InvoiceViewModel()
        val searchQuery = viewModel.searchQuery.collectAsState()
        val allInvoice = viewModel.allCustomerInvoice.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.fetchAllInvoice()
        }
        Column(modifier = Modifier.fillMaxSize().background(ThemeColor.shared().defaultBakground)) {
            TopNavBar(title = "Invoices", null, onBackPress = {
                navigator?.pop()
            }, onClickAdd = {
                AppNavigator.shared().navigateTo(
                    ScreenName.CREATE_CUSTOMER
                )
            })
            AppLoader(viewModel)
            SearchBar(
                viewModel,
                searchQuery.value,
                "Search Invoice"
            )
            LazyVerticalGrid(
                columns = GridCells.Adaptive(minSize = 400.dp),
                modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
            ) {
                items(count = allInvoice.value.count()) { index ->
                    InvoiceCard(allInvoice.value[index]) {
                        viewModel.setSelectedInvoice(it)
                        AppNavigator.shared().navigateTo(
                            ScreenName.INVOICES
                        )
                    }
                }
            }
        }
    }
}