package feature.notification.network

import feature.notification.model.Notification
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class NotificationRetrieveRequest(
    @SerialName("BusinessID") val businessId: String?,
    @SerialName("UserID") val userId: String?,
)

@Serializable
data class NotificationCreateRequest(
    @SerialName("FromUserID") val fromUserId: String,
    @SerialName("FromBusinessID") val fromBusinessId: String,
    @SerialName("ToUserID") val toUserId: String,
    @SerialName("ToBusinessID") val toBusinessId: String,
    @SerialName("Name") val name: String,
)

@Serializable
data class AcceptConnectionRequest(
    @SerialName("BusinessID") val businessId: String?,
    @SerialName("UserID") val userId: String?,
    @SerialName("Payload") val payload: Notification,
)