package presentation.screens.business.inventory.sub_category.list

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import cafe.adriel.voyager.navigator.LocalNavigator
import component.empty.EmptyData
import component.loader.AppLoader
import component.navigation.TopNavBar
import feature.app.NavigationParam
import feature.productSubCategory.viewModel.ProductSubCategoryViewModel
import locale.localization
import navigation.containers.ScreenName
import navigation.screen.AppScreen
import presentation.screens.business.inventory.sub_category.component.ProductSubCategoryRow
import presentation.screens.business.inventory.sub_category.create.CreateSubCategoryScreen
import theme.ThemeColor

class SubCategoryListScreen(override val screenName: ScreenName) : AppScreen {


    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val viewModel = ProductSubCategoryViewModel()
        val allCategories = viewModel.allSubCategory.collectAsState()
        LaunchedEffect(key1 = Unit) {
            viewModel.fetchAll()
        }
        Column(
            modifier = Modifier.fillMaxSize()
                .background(ThemeColor.shared().defaultBakground)
        ) {
            TopNavBar(
                title = localization().subCategory.subCategoryTitle,
                navigationParam,
                onBackPress = {
                    navigator?.pop()
                },
                onClickAdd = {
                    navigator?.push(CreateSubCategoryScreen())
                })
            AppLoader(viewModel)
            if (allCategories.value.isNotEmpty()) {
                LazyVerticalGrid(
                    columns = GridCells.Adaptive(minSize = 400.dp),
                    modifier = Modifier.fillMaxSize().padding(bottom = 60.dp)
                ) {
                    items(count = allCategories.value.count()) { index ->
                        ProductSubCategoryRow(subCategory = allCategories.value[index], onClick = {
                            navigateTo(
                                ScreenName.BUSINESS_INVENTORY_SUB_CATEGORY_DETAILS,
                                navigationParam?.update(NavigationParam(productSubCategory = it))
                            )
                        })
                    }
                }
            } else {
                EmptyData(viewModel = viewModel) {
                    viewModel.fetchAll()
                }
            }
        }
    }
}