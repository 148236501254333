package feature.sale.model

import kotlinx.datetime.Clock
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SaleTransaction(
    @SerialName("_id") var _id: String = "",
    @SerialName("InvoiceID") var invoiceID: Long = Clock.System.now().epochSeconds,
    @SerialName("UserID") var userID: String? = null,
    @SerialName("BusinessID") var businessID: String? = null,
    @SerialName("CustomerID") var customerId: String? = null,
    @SerialName("ProductID") var productID: String? = null,
    @SerialName("BarCode") var barCode: ArrayList<String> = arrayListOf(),
    @SerialName("IsDeleted") var isDeleted: Boolean? = null,
    @SerialName("CustomerName") var customerName: String? = null,
    @SerialName("CustomerMobile") var customerMobile: String? = null,
    @SerialName("VehicleNumber") var vehicleNumber: String? = null,
    @SerialName("ProductName") var productName: String? = null,
    @SerialName("PaymentMode") var paymentMode: String? = null,
    @SerialName("Quantity") var quantity: Long? = null,
    @SerialName("Price") var price: Double? = null,
    @SerialName("Discount") var discount: Double? = null,
    @SerialName("SGST") var SGST: Double? = null,
    @SerialName("CGST") var CGST: Double? = null,
    @SerialName("IGST") var IGST: Double? = null,
    @SerialName("CESS") var CESS: Double? = null,
    @SerialName("VAT") var VAT: Double? = null,
    @SerialName("Tax") var tax: Double? = null,
    @SerialName("CostPrice") var costPrice: Double? = null,
    @SerialName("FinalPrice") var finalPrice: Double? = null,
    @SerialName("SaleDate") var saleDate: String? = null,
    @SerialName("SaleTime") var saleTime: Long? = null,
    @SerialName("CreatedAt") var createdAt: String? = null,
    @SerialName("UpdatedAt") var updatedAt: String? = null,
    @SerialName("__v") var _v: Int? = null,
)