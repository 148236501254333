package feature.productSubCategory.network

import feature.productSubCategory.model.ProductSubCategory
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RetrieveProductSubCategoryResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<ProductSubCategory>,
)

@Serializable
data class CreateProductSubCategoryResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: ProductSubCategory?,
)

@Serializable
data class UpdateProductSubCategoryResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: ProductSubCategory?,
)