package feature.inventory.handler

import feature.inventory.repository.TagRepository
import feature.inventory.viewModel.tag.TagViewModel


class TagHandler {

    var viewModel: TagViewModel? = null
    val repository = TagRepository()

    init {
        instance = this
    }

    companion object {
        private var instance: TagHandler? = null
        fun shared(): TagHandler {
            if (instance != null) {
                return instance as TagHandler
            } else {
                return TagHandler()
            }
        }
    }

    fun setup(model: TagViewModel) {
        viewModel = model
    }
}