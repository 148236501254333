package feature.address.network

import feature.address.model.Address
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CreateAddressResponse(
    @SerialName("status") val status: String,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: Address?,
)

@Serializable
data class UpdateAddressResponse(
    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: Address?,

    )

@Serializable
data class RetrieveAddressResponse(
//    @SerialName("status") val status: String?,
    @SerialName("Message") val message: String,
    @SerialName("Payload") val payload: List<Address>,

    )

@Serializable
data class FindZipCodeResponse(
    @SerialName("Payload") val payload: List<PinCode>,
)

@Serializable
data class PinCode(
    @SerialName("CountryCode") val countryCode: String?,
    @SerialName("PinCode") val pinCode: String?,
    @SerialName("State") val state: String?,
    @SerialName("District") val district: String?,
    @SerialName("City") val city: String?,
    @SerialName("Area") val area: String?,
    @SerialName("latitude") val latitude: String?,
    @SerialName("longitude") val longitude: String?,
)

