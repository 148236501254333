package presentation.screens.customer.store.component

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import component.button.DefaultButton
import feature.app.NavigationParam
import feature.cart.viewModel.CartViewModel

@Composable
fun CartFooter(navigationParam: NavigationParam?, viewModel: CartViewModel, onClick: () -> Unit) {
    val totalAmount = viewModel.totalAmount.collectAsState()
    val totalItems = viewModel.cartItems.collectAsState()
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(if (totalItems.value.isNotEmpty()) 150.dp else 0.dp)
            .background(color = Color.Gray), verticalAlignment = Alignment.Bottom
    ) {
        Box(
            modifier = Modifier.fillMaxSize().padding(bottom = 30.dp)
                .background(color = Color.White)
        ) {
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(8.dp)
            ) {
                Text(
                    "\uD83D\uDED2", fontSize = 35.sp, modifier = Modifier
                        .padding(start = 8.dp, top = 8.dp).weight(0.1f)
                )
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .padding(top = 15.dp, bottom = 40.dp).weight(0.3f)
                ) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                        // .height(50.dp)
                    ) {
                        Text(
                            "${totalItems.value.count()} item", fontSize = 16.sp
                        )
                        Text(
                            "₹ ${totalAmount.value}",
                            fontSize = 18.sp,
                            color = Color.Blue,
                            modifier = Modifier
                                .padding(start = 4.dp)
                        )
                    }
                    Text(
                        navigationParam?.selectedBusiness?.Name ?: "",
                        fontSize = 16.sp,
                        color = Color.Gray,
                        modifier = Modifier
                    )
                }
                Box(
                    modifier = Modifier.width(126.dp).height(55.dp).weight(0.3f)
                        .padding(top = 20.dp)
                ) {
                    DefaultButton("View Cart", { onClick() })
                }
            }
        }
    }
}