package feature.offerBanner.repository

import feature.offerBanner.model.OfferBanner
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

class OfferBannerRepository {

    val liveData: MutableStateFlow<List<OfferBanner>> = MutableStateFlow(arrayListOf())
    val allLiveData: StateFlow<List<OfferBanner>>
        get() = liveData

    val selectedLiveData: MutableStateFlow<OfferBanner> = MutableStateFlow(OfferBanner())
    val selected: StateFlow<OfferBanner>
        get() = selectedLiveData

    val mediaFileLiveData: MutableStateFlow<OfferBanner> = MutableStateFlow(OfferBanner())

    val offerBannerLiveData: MutableStateFlow<List<OfferBanner>> = MutableStateFlow(arrayListOf())
    val mediaFiles: StateFlow<List<OfferBanner>>
        get() = offerBannerLiveData

}