package component.html

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import htmp_page.HtmlContent

@Composable
fun HtmlContent(content: HtmlContent) {
    Column(modifier = Modifier.fillMaxWidth().wrapContentHeight()) {
        if (content.h1 != null) {
            Text(
                content.h1!!,
                modifier = Modifier.wrapContentHeight().fillMaxWidth(),
                fontWeight = FontWeight(700),
                fontSize = 18.sp
            )
        }
        if (content.p != null) {
            Text(
                content.p!!,
                modifier = Modifier.wrapContentHeight().fillMaxWidth().padding(top = 4.dp),
                fontWeight = FontWeight(400),
                fontSize = 14.sp
            )
        }
        if (content.br != null) {
            Text(
                content.br!!,
                modifier = Modifier.height(8.dp).fillMaxWidth(),
                fontWeight = FontWeight(400),
                fontSize = 14.sp
            )
        }
    }
}